import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllManagers(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/managers?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item, index) => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
            case "cpf":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }

         if (index < query.filter.length - 1) url += "&";
      });
   }

   return await axiosService.get(url);
}

async function getManagerById(managerId) {
   return await axiosService.get(`/managers/${managerId}`);
}

async function createManager(manager) {
   return await axiosService.post(`/managers`, manager);
}

async function updateManager(managerId, manager) {
   return await axiosService.patch(`/managers/${managerId}`, manager);
}

export default {
   getAllManagers,
   getManagerById,
   createManager,
   updateManager
};
