import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllHealthSecretaries(municipalityId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/municipalities/${municipalityId}/healthsecretaries?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function createHealthSecretarie(municipalityId, healthSecretarie) {
   return await axiosService.post(
      `/municipalities/${municipalityId}/healthsecretaries`,
      healthSecretarie
   );
}

async function updateHealthSecretarie(
   municipalityId,
   healthSecretarieId,
   healthSecretarie
) {
   return await axiosService.patch(
      `/municipalities/${municipalityId}/healthsecretaries/${healthSecretarieId}`,
      healthSecretarie
   );
}

async function getHealthSecretarieById(healthSecretarieId) {
   return await axiosService.get(`/healthsecretaries/${healthSecretarieId}`);
}

async function activeHealthSecretarie(healthSecretarieId, active) {
   return await axiosService.put(
      `/healthsecretaries/${healthSecretarieId}/active`,
      {
         active
      }
   );
}

export default {
   getAllHealthSecretaries,
   createHealthSecretarie,
   updateHealthSecretarie,
   getHealthSecretarieById,
   activeHealthSecretarie
};
