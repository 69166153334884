<template>
   <div
      class="
         d-flex
         flex-column
         justify-center
         align-center
         standard-padding-tiny
      "
   >
      <v-img
         v-if="!icon"
         :src="require('@/assets/images/empty-folder.svg')"
         max-height="90px"
         max-width="90px"
         contain
      >
      </v-img>

      <v-icon v-else color="primary" size="70">{{ icon }}</v-icon>

      <p class="body-2 text-center primary--text mt-2">
         {{ message }}
      </p>
   </div>
</template>

<script>
export default {
   name: "NoData",

   props: {
      icon: {
         type: String,
         default: null,
      },

      message: {
         type: String,
         default: "Desculpe, nada foi encontrado.",
      },
   },
};
</script>

<style scoped>
</style>