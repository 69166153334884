<template>
   <div>
      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="healthSecretaryManagerResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogHealthSecretaryManagerActiveFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeHealthSecretaryManagerActiveForm" />

            <HealthSecretaryManagerActiveForm
               @onActivedHealthSecretaryManager="
                  closeHealthSecretaryManagerActiveForm();
                  refreshHealthSecretaryManagerList();
               "
               :healthSecretaryManager="healthSecretaryManagerSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshHealthSecretaryManagerList();
               "
               :user="healthSecretaryManagerChangeTypeSelected"
               currentType="secretary_manager"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogHealthSecretaryManagerFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeHealthSecretaryManagerForm" />

            <HealthSecretaryManagerForm
               @onClickCancel="closeHealthSecretaryManagerForm"
               @onCreatedHealthSecretaryManager="
                  closeHealthSecretaryManagerForm();
                  refreshHealthSecretaryManagerList();
               "
               @onUpdatedHealthSecretaryManager="
                  closeHealthSecretaryManagerForm();
                  refreshHealthSecretaryManagerList();
               "
               @onClickResetHealthSecretaryManager="
                  onClickResetHealthSecretaryManager
               "
               @onClickRemoveHealthSecretaryManager="
                  onClickRemoveHealthSecretaryManager
               "
               @onFindDisabledHealthSecretaryManager="
                  onFindDisabledHealthSecretaryManager
               "
               @onClickChangeType="onClickChangeType"
               :healthSecretaryManager="healthSecretaryManagerSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <HealthSecretaryManagerList
         @onClickAddHealthSecretaryManager="onClickAddHealthSecretaryManager"
         @onClickViewHealthSecretaryManager="onClickViewHealthSecretaryManager"
         @onClickEditHealthSecretaryManager="onClickEditHealthSecretaryManager"
         @onClickRemoveHealthSecretaryManager="
            onClickRemoveHealthSecretaryManager
         "
         class="mt-12"
         ref="healthSecretaryManagerlist"
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import PageTitle from "../../components/base/PageTitle";
import HealthSecretaryManagerList from "../../components/healthSecretaryManager/HealthSecretaryManagerList";
import HealthSecretaryManagerActiveForm from "../../components/healthSecretaryManager/HealthSecretaryManagerActiveForm";
import HealthSecretaryManagerForm from "../../components/healthSecretaryManager/HealthSecretaryManagerForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "HealthSecretaryManager",

   components: {
      Close,
      PageTitle,
      HealthSecretaryManagerActiveForm,
      HealthSecretaryManagerList,
      HealthSecretaryManagerForm,
      ResetPasswordUsersForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         healthSecretaryManagerSelected: {},
         healthSecretaryManagerResetPasswordSelected: {},
         healthSecretaryManagerChangeTypeSelected: {},

         editMode: false,

         dialogSelectHealthSecretaryVisibility: false,
         dialogHealthSecretaryManagerFormVisibility: false,
         dialogHealthSecretaryManagerActiveFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogHealthSecretaryManagerFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      selectHealthSecretary() {
         this.dialogSelectHealthSecretaryVisibility = false;
      },

      onClickAddHealthSecretaryManager(healthSecretaryManager) {
         this.healthSecretaryManagerSelected = Object.assign(
            {},
            healthSecretaryManager
         );
         this.openHealthSecretaryManagerForm();
      },

      onClickViewHealthSecretaryManager(healthSecretaryManager) {
         this.healthSecretaryManagerSelected = Object.assign(
            {},
            healthSecretaryManager
         );
         this.openHealthSecretaryManagerForm();
      },

      onClickEditHealthSecretaryManager() {
         this.editMode = true;
      },

      onClickChangeType(healthSecretaryManager) {
         this.healthSecretaryManagerChangeTypeSelected = Object.assign(
            {},
            healthSecretaryManager
         );
         this.closeHealthSecretaryManagerForm();
         this.openChangeTypeUserForm();
      },

      onClickResetHealthSecretaryManager(healthSecretaryManager) {
         this.healthSecretaryManagerResetPasswordSelected = Object.assign(
            {},
            healthSecretaryManager
         );
         this.openResetPasswordForm();
      },

      onClickRemoveHealthSecretaryManager(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, {
               active: false
            });

            this.dialogHealthSecretaryManagerFormVisibility = false;
            this.refreshHealthSecretaryManagerList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor de secretaria foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse gestor de secretaria?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledHealthSecretaryManager(item) {
         this.healthSecretaryManagerSelected = Object.assign({}, item);

         this.closeHealthSecretaryManagerForm();
         this.openHealthSecretaryManagerActiveForm();
      },

      openHealthSecretaryManagerForm() {
         this.dialogHealthSecretaryManagerFormVisibility = true;
      },

      closeHealthSecretaryManagerForm() {
         this.dialogHealthSecretaryManagerFormVisibility = false;
      },

      openHealthSecretaryManagerActiveForm() {
         this.dialogHealthSecretaryManagerActiveFormVisibility = true;
      },

      closeHealthSecretaryManagerActiveForm() {
         this.dialogHealthSecretaryManagerActiveFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      refreshHealthSecretaryManagerList() {
         this.$refs.healthSecretaryManagerlist.refreshAllHealthSecretaryManagersByRef();
      }
   }
};
</script>

<style scoped></style>
