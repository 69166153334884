<template>
   <v-card class="standard-padding-small" outlined>
      <v-row>
         <v-col class="d-flex align-center flex-wrap" cols="12" md="6">
            <div class="d-flex align-center">
               <v-icon left>mdi-map-marker</v-icon>

               <v-skeleton-loader
                  class="rounded-pill"
                  type="image"
                  width="150px"
                  height="18px"
               ></v-skeleton-loader>
            </div>
         </v-col>
      </v-row>

      <v-skeleton-loader class="my-4" type="divider"></v-skeleton-loader>

      <div class="d-flex flex-wrap ma-n1">
         <v-skeleton-loader
            v-for="item in 9"
            :key="`ward${item}`"
            class="ma-1"
            type="image"
            width="65px"
            height="65px"
         ></v-skeleton-loader>
      </div>
   </v-card>
</template>

<script>
export default {
   name: "BedListWardSkeleton",
};
</script>

<style scoped>
.justify-list-beds-ward-statistic-chip {
   justify-content: flex-end;
}

@media (max-width: 959px) {
   .justify-list-beds-ward-statistic-chip {
      justify-content: flex-start;
   }
}
</style>