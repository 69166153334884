<template>
   <div>
      <ListSkeleton
         v-if="loadingOperatorsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (operator) => (!blockClick ? onClickViewOperator(operator) : null)
         "
         :headers="headers"
         :items="operators"
         :loading="loadingOperatorsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddOperator"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingOperatorsPartially"
               filterActor="operador"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalOperators"
               :loading="loadingOperatorsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData message="Desculpe, nenhum operador foi encontrado." />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando operadores, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-operator-health-unit-manager-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.cpf`]="{ item }">
            <p class="cpf-operator-health-unit-manager-list-data">
               {{ formatCPF(item.cpf) }}
            </p>
         </template>

         <template v-slot:[`item.email`]="{ item }">
            <p class="email-operator-health-unit-manager-list-data">
               {{ item.email }}
            </p>
         </template>

         <template v-slot:[`item.birth_date`]="{ item }">
            <p class="birth_date-operator-health-unit-manager-list-data">
               {{ formatDate(item.birth_date) }}
            </p>
         </template>

         <template v-slot:[`item.last_login`]="{ item }">
            <p class="last_login-operator-health-unit-manager-list-data">
               {{ formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm") }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item }">
            <v-icon
               @click="editOperator(item)"
               class="mx-1"
               color="black"
               small
            >
               mdi-pencil
            </v-icon>

            <v-icon
               @click="removeOperator(item)"
               class="mx-1"
               color="error"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import operatorService from "../../services/operatorService";

export default {
   name: "OperatorHealthSecretaryManagerList",

   components: {
      ListFooter,
      ListHeader,
      ListSkeleton,
      NoData,
      LoadingData,
   },

   props: {
      healthSecretary: {
         type: Object,
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "CPF",
               class: "primary--text body-2 font-weight-medium",
               value: "cpf",
            },
            {
               text: "Email",
               class: "primary--text body-2 font-weight-medium",
               value: "email",
            },
            {
               text: "Nascimento",
               class: "primary--text body-2 font-weight-medium",
               value: "birth_date",
            },
            {
               text: "Último acesso",
               class: "primary--text body-2 font-weight-medium",
               value: "last_login",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
            {
               filter: "cpf",
               text: "CPF",
               mask: "###.###.###-##",
               unmask: /[^0-9]/g,
               maxLength: 14,
            },
            {
               filter: "email",
               text: "Email",
            },
         ],

         operators: [],

         totalOperators: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingOperatorsFully: true,
         loadingOperatorsPartially: true,

         blockClick: false,
      };
   },

   watch: {
      healthSecretary: {
         immediate: true,
         async handler(healthSecretary) {
            if (healthSecretary && healthSecretary.id) {
               this.loadingOperatorsFully = true;
               await this.getAllOperators(this.query);
               this.loadingOperatorsFully = false;
            }
         },
      },
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         if (this.healthSecretary && this.healthSecretary.id)
            query.filter.push({
               attribute: "health_secretaries",
               query: this.healthSecretary.id,
            });

         return query;
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllOperators(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllOperators(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllOperators(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllOperators(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllOperators(this.query);
      },

      onClickAddOperator(operator) {
         this.$emit("onClickAddOperator", operator);
      },

      onClickViewOperator(operator) {
         this.$emit("onClickViewOperator", operator);
      },

      editOperator(operator) {
         this.$emit("onClickEditOperator", operator);
      },

      removeOperator(operator) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveOperator", operator);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllOperatorsByRef() {
         this.loadingOperatorsFully = true;
         await this.getAllOperators(this.query);
         this.loadingOperatorsFully = false;
      },

      async getAllOperators(query) {
         if (!this.healthSecretary || !this.healthSecretary.id) return;

         this.loadingOperatorsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await operatorService.getAllOperators(query);

            this.totalOperators = parseInt(response.headers["x-total-count"]);
            this.operators = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingOperatorsPartially = false;
         }
      },
   },

   // async created() {
   //    this.loadingOperatorsFully = true;
   //    await this.getAllOperators(this.query);
   //    this.loadingOperatorsFully = false;
   // },
};
</script>

<style scoped>
</style>