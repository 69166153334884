<template>
   <v-card
      @click="() => (!blockClick ? viewSurgery(surgery) : null)"
      class="standard-padding-tiny"
      color="#e9e9e9"
      flat
   >
      <div class="d-flex align-center">
         <v-icon left small>mdi-account</v-icon>
         <p class="body-2">
            {{
               surgery.patient
                  ? surgery.patient.name
                  : "Nome do paciente não encontrado"
            }}
         </p>
         <!-- <p v-if="surgery.patient" class="body-2">
            {{ surgery.patient.name }}
         </p>
         <p v-else-if="surgery.request" class="body-2">
            {{ surgery.request.patient.name }}
         </p> -->
      </div>

      <div class="d-flex align-center mt-1">
         <v-icon left small>mdi-clock</v-icon>

         <p v-if="surgery.request_created_at" class="body-2">
            {{ formatDateTime(surgery.request_created_at) }}
         </p>
         <p v-else-if="surgery.created_at" class="body-2">
            {{ formatDateTime(surgery.created_at) }}
         </p>

         <v-skeleton-loader
            v-else
            class="rounded-pill"
            type="image"
            width="30%"
            height="12px"
         ></v-skeleton-loader>
      </div>

      <div class="d-flex align-center mt-1 pr-10">
         <v-icon left small>mdi-medical-bag</v-icon>

         <div class="d-flex align-center flex-wrap">
            <p class="body-2">{{ surgery.name }}</p>
         </div>
      </div>

      <div class="d-flex align-start mt-1">
         <v-chip v-if="requestClosed" class="px-2" x-small dark>
            Solicitação finalizada
         </v-chip>

         <v-chip v-else-if="surgeryLate" color="success" class="px-2" x-small>
            Agendada atrasada
         </v-chip>

         <v-chip
            v-else-if="surgery.status === 'scheduled'"
            color="success"
            class="px-2"
            x-small
         >
            Agendada para
            {{ formatDateTime(surgery.scheduling_date, "DD/MM/YYYY") }}</v-chip
         >

         <v-chip
            v-else
            :color="generateSurgeryStatus(surgery.status).color"
            class="px-2"
            x-small
            >{{ generateSurgeryStatus(surgery.status).text }}</v-chip
         >
      </div>

      <div class="actions standard-padding-x-tiny">
         <v-btn
            v-if="!hideEditButton"
            @click="editSurgery"
            color="normal"
            class="ma-1"
            text
            depressed
            x-small
            fab
         >
            <v-icon>mdi-pencil</v-icon>
         </v-btn>

         <v-checkbox
            v-if="selectable"
            @change="select"
            v-model="selectableCurrentValue"
            class="mt-2"
         ></v-checkbox>
      </div>
   </v-card>
</template>

<script>
import snackBarUtil from "utils/snackBarUtil";
import formatUtil from "utils/formatUtil";
import util from "utils/util";
import requestSurgeryService from "services/requestSurgeryService";
import moment from "moment";

export default {
   name: "SurgeryListItem",

   props: {
      surgery: {
         type: Object
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      selectable: {
         type: Boolean,
         default: false
      },

      selectableValue: {
         type: Boolean,
         default: false
      }
   },

   data: function() {
      return {
         requestClosed: false,

         selectableCurrentValue: false,

         blockClick: false
      };
   },

   computed: {
      surgeryLate() {
         return (
            this.surgery.status === "scheduled" &&
            moment(this.surgery.scheduling_date).add({ days: 1 }) < moment()
         );
      }
   },

   watch: {
      surgery: {
         immediate: true,
         handler(surgery) {
            if (surgery && surgery.id) this.getSurgeryRequestById(surgery.id);
         }
      },

      selectableValue: {
         immediate: true,
         handler(value) {
            this.selectableCurrentValue = value;
         }
      }
   },

   methods: {
      ...util,
      ...formatUtil,

      async getSurgeryRequestById(surgeryId) {
         try {
            const response = await requestSurgeryService.getAllSurgeryRequests({
               filter: [
                  {
                     attribute: "surgery_id",
                     query: surgeryId
                  }
               ]
            });

            this.requestClosed =
               response.data.length && response.data[0].id
                  ? response.data[0].is_closed
                  : false;
            this.surgery.request = response.data[0];
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      editSurgery(surgery) {
         this.$emit("onClickEditSurgery", surgery);
      },

      viewSurgery(surgery) {
         this.$emit("onClickViewSurgery", surgery);
      },

      select(value) {
         this.blockClick = true;

         setTimeout(() => {
            if (value) this.$emit("onSelectSurgery", this.surgery);
            else this.$emit("onDeselectSurgery", this.surgery);

            this.blockClick = false;
         }, 1);
      }
   }
};
</script>

<style scoped>
.actions {
   position: absolute;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   justify-content: flex-end;
   align-items: center;
}
</style>
