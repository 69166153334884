<template>
   <div>
      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <AuditList
         @onClickViewAudit="onClickViewAudit"
         class="mt-12"
         ref="auditlist"
         hideAddButton
         hideRemoveButton
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import AuditList from "../../components/audit/AuditList";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "Audit",

   components: { PageTitle, AuditList },

   data: function () {
      return {
         auditSelected: {},

         editMode: false,

         dialogAuditFormVisibility: false,
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   watch: {
      dialogAuditFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },
   },

   methods: {
      ...responsiveUtil,

      onClickViewAudit(audit) {
         this.auditSelected = Object.assign({}, audit);
      },

      refreshAuditList() {
         this.$refs.auditlist.refreshAllAuditsByRef();
      },
   },
};
</script>

<style scoped>
</style>