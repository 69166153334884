<template>
   <div class="d-flex flex-column">
      <div class="buttons-config align-self-center">
         <v-btn
            @click="resetMessages(request.id, query, true)"
            class="mr-2"
            color="primary"
            height="28"
            width="28"
            id="reload-chat-button"
            fab
            depressed
         >
            <v-icon small>mdi-cached</v-icon>
         </v-btn>

         <v-btn
            v-if="isRegulator"
            @click="print"
            :loading="loadingPrint"
            class="mr-2"
            color="primary"
            height="28"
            width="28"
            id="print-chat-button"
            fab
            depressed
         >
            <v-icon small>mdi-printer</v-icon>
         </v-btn>
      </div>

      <div class="tabs-config">
         <v-tabs v-if="isRegulator" @change="changeTabs" centered show-arrows>
            <v-tab v-if="!request.was_created_by_patient">Solicitante</v-tab>
            <v-tab v-else>Paciente</v-tab>
            <v-tab>Executante</v-tab>
            <v-tab>Sistema</v-tab>
         </v-tabs>

         <v-tabs v-else @change="changeTabs" centered show-arrows>
            <v-tab>Regulador</v-tab>
            <v-tab>Sistema</v-tab>
         </v-tabs>
      </div>

      <LoadingData v-if="loadingMessagesFully" class="my-auto" />

      <div v-else class="d-flex flex-column flex-grow-1">
         <div
            @scroll="scrollMessageContent"
            class="messages-content d-flex flex-column flex-grow-1"
            id="messages-content-div"
         >
            <div
               v-if="loadingMessagesPartially"
               class="d-flex justify-center align-center"
            >
               <v-progress-circular
                  color="primary"
                  width="2"
                  size="22"
                  indeterminate
               ></v-progress-circular>
            </div>

            <div v-if="messages && messages.length">
               <div
                  v-for="(message, index) in messages"
                  :key="message.id"
                  :class="
                     messageOrigin(message.user_id) === 'local'
                        ? 'align-end'
                        : null
                  "
                  :id="`message-${index}`"
                  class="d-flex flex-column align-start mb-3"
                  outlined
               >
                  <div
                     v-if="messageOrigin(message.user_id) === 'local'"
                     class="d-flex justify-end align-center flex-wrap"
                  >
                     <p class="caption text--secondary">
                        {{ formatDate(message.created_at, "DD MMM[,] HH:mm") }}
                        •
                        {{ getResponsibleMessage(message) }}
                     </p>
                  </div>
                  <div v-else class="d-flex align-center flex-wrap ma-n1">
                     <p class="caption text--secondary ma-1">
                        {{ getResponsibleMessage(message) }} •
                        {{ formatDate(message.created_at, "DD MMM[,] HH:mm") }}
                     </p>

                     <v-chip
                        v-if="!message.is_read"
                        class="px-1 my-n1 ma-1"
                        color="primary"
                        x-small
                     >
                        Recente
                     </v-chip>
                  </div>

                  <v-card
                     :color="
                        messageOrigin(message.user_id) === 'local'
                           ? 'primary'
                           : 'grey lighten-2'
                     "
                     :dark="
                        messageOrigin(message.user_id) === 'local'
                           ? true
                           : false
                     "
                     class="message-box"
                     outlined
                  >
                     <p class="body-2">{{ message.text }}</p>
                  </v-card>
               </div>
            </div>

            <NoData
               v-else
               icon="mdi-forum"
               message="Nenhum histórico de mensagens."
               class="my-auto"
            />
         </div>

         <div v-if="activeChat !== 'system'" class="pb-3">
            <v-text-field
               v-model="message"
               @keyup.enter="sendMessage"
               :disabled="disable"
               :placeholder="
                  disable
                     ? 'Essa solicitação foi terminada.'
                     : 'Digite sua mensagem aqui...'
               "
               append-icon="mdi-send"
               id="message-chat-input"
               autofocus
               outlined
               dense
            >
               <template v-slot:append>
                  <div
                     v-if="loadingSendMessage"
                     class="d-flex flex-column justify-center mr-1"
                     style="height: 25px"
                  >
                     <v-progress-circular
                        color="primary"
                        width="2"
                        size="18"
                        indeterminate
                     ></v-progress-circular>
                  </div>

                  <div
                     v-if="!loadingSendMessage"
                     class="d-flex flex-column justify-center mr-1"
                     style="height: 25px"
                  >
                     <v-icon @click="sendMessage" id="send-chat-icon"
                        >mdi-send</v-icon
                     >
                  </div>
               </template>
            </v-text-field>
         </div>
      </div>
   </div>
</template>

<script>
import LoadingData from "components/base/LoadingData";
import NoData from "components/base/NoData";
import messageService from "services/messageService";
import formatUtil from "src/utils/formatUtil";
import snackBarUtil from "src/utils/snackBarUtil";
import requestPdfExport from "export/pdf/requestPdfExport";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import basePdfExport from "export/pdf/basePdfExport";
import socketIoService from "services/socketIoService";

export default {
   name: "Chat",

   components: { LoadingData, NoData },

   props: {
      request: {
         type: Object,
         default: () => ({}),
      },

      disable: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         message: "",
         messages: null,

         activeChat: null,

         totalMessages: 0,
         page: 1,
         limit: 20,

         loadingMessagesFully: true,
         loadingMessagesPartially: false,
         loadingSendMessage: false,
         loadingPrint: false,

         socketIoConnection: null,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: 20,
         };

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      isRegulator() {
         return (
            this.userStore.sub_type === "regulator" ||
            this.userStore.sub_type === "regulator_manager"
         );
      },

      messageFrom() {
         if (this.isRegulator) return "regulation";
         else {
            let userType = "executor";

            switch (this.userStore.sub_type) {
               case "solicitator":
               case "secretary_manager":
                  userType = "requester";
                  break;
            }

            return userType;
         }
      },

      messageTo() {
         return this.activeChat;
      },
   },

   watch: {
      activeChat(value) {
         if (value) this.resetMessages(this.request.id, this.query, true);
      },
   },

   methods: {
      ...formatUtil,

      changeTabs(tab) {
         if (this.isRegulator) {
            switch (tab) {
               case 0:
                  if (!this.request.was_created_by_patient)
                     this.activeChat = "requester";
                  else this.activeChat = "patient";
                  break;
               case 1:
                  this.activeChat = "executor";
                  break;
               case 2:
                  this.activeChat = "system";
                  break;
            }
         } else {
            switch (tab) {
               case 0:
                  this.activeChat = "regulation";
                  break;
               case 1:
                  this.activeChat = "system";
                  break;
            }
         }
      },

      async print() {
         this.loadingPrint = true;

         try {
            let response;

            switch (this.userStore.sub_type) {
               case "admin":
                  response = await adminService.getAdminById(
                     this.userStore.sub
                  );
                  break;
               case "manager":
                  response = await managerService.getManagerById(
                     this.userStore.sub
                  );
                  break;
               case "healthunit_manager":
                  response =
                     await healthUnitManagerService.getHealthUnitManagerById(
                        this.userStore.sub
                     );
                  break;
               case "solicitator":
               case "executor":
                  response = await operatorService.getOperatorById(
                     this.userStore.sub
                  );
                  break;
               case "regulator_manager":
                  response =
                     await regulatorManagerService.getRegulatorManagerById(
                        this.userStore.sub
                     );
                  break;
               case "regulator":
                  response = await regulatorService.getRegulatorById(
                     this.userStore.sub
                  );
                  break;
            }

            const pdf = requestPdfExport.generateMessagesRequestPDF(
               this.messages,
               this.activeChat,
               this.request,
               response.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Mensagens da solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrint = false;
         }
      },

      messageOrigin(userId) {
         if (userId === this.userStore.sub) return "local";
         return "received";
      },

      getResponsibleMessage(message) {
         if (message.user_id === this.userStore.sub) return "Você";

         const name = message.responsible.split(" ")[0];
         const surname = message.responsible.split(" ")[1];
         return `${name}${surname ? " " + surname : ""}`;
      },

      async mountMessages(requestId, query) {
         const responseMessageTo = await messageService.getAllRequestMessages(
            requestId,
            {
               page: query.page,
               limit: query.limit,
               filter: [
                  {
                     attribute: "message_from",
                     query: this.messageFrom,
                  },
                  {
                     attribute: "message_to",
                     query: this.messageTo,
                  },
               ],
            }
         );

         const responseMessageFrom = await messageService.getAllRequestMessages(
            requestId,
            {
               page: query.page,
               limit: query.limit,
               filter: [
                  {
                     attribute: "message_from",
                     query: this.messageTo,
                  },
                  {
                     attribute: "message_to",
                     query: this.messageFrom,
                  },
               ],
            }
         );

         const totalMessages =
            parseInt(responseMessageTo.headers["x-total-count"]) +
            parseInt(responseMessageFrom.headers["x-total-count"]);

         let messagesMerge = responseMessageTo.data.concat(
            responseMessageFrom.data
         );

         return {
            count: totalMessages,
            messages: messagesMerge,
         };
      },

      async appendMessages(requestId, query) {
         this.loadingMessagesPartially = true;

         try {
            const result = await this.mountMessages(requestId, query);
            this.totalMessages = result.count;

            this.messages = this.messages
               .reverse()
               .concat(Object.assign([], result.messages))
               .reverse();

            this.messages.sort(function (a, b) {
               return new Date(a.created_at) - new Date(b.created_at);
            });

            setTimeout(() => this.lockMessagesFocus(result.messages.length), 1);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMessagesPartially = false;
         }
      },

      async resetMessages(requestId, query, loadingAnimation) {
         if (loadingAnimation) this.loadingMessagesFully = true;

         query.page = 1;
         this.page = 1;

         try {
            const result = await this.mountMessages(requestId, query);
            this.totalMessages = result.count;

            result.messages.sort(function (a, b) {
               return new Date(b.created_at) - new Date(a.created_at);
            });
            this.messages = Object.assign([], result.messages).reverse();
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMessagesFully = false;
         }

         setTimeout(() => this.lastMessagesFocus(), 1);
      },

      scrollMessageContent(scroll) {
         if (
            scroll.target.scrollTop === 0 &&
            this.messages.length < this.totalMessages
         ) {
            this.page += 1;
            this.appendMessages(this.request.id, this.query);
         }
      },

      sendMessage() {
         if (this.message && this.message.trim())
            this.createRequestMessage(this.request.id, {
               text: this.message.trim(),
               message_to: this.messageTo,
            });
      },

      async createRequestMessage(requestId, message) {
         this.loadingSendMessage = true;

         if (this.isRegulator) message.message_to = this.activeChat;

         try {
            await messageService.createRequestMessage(requestId, message);
            await this.resetMessages(this.request.id, this.query, false);
            this.message = "";

            // Emite evento de nova mensagem
            this.socketIoConnection.emit("new_message", this.request);

            this.emitSendMessageEvent(message);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSendMessage = false;
         }
      },

      lastMessagesFocus() {
         /* Seta o scroll ao final das mensagens */
         if (document.getElementById("messages-content-div")) {
            var element = document.getElementById("messages-content-div");
            element.scrollTop = element.scrollHeight;
         }
      },

      lockMessagesFocus(countMessagesAppend) {
         const id = `message-${countMessagesAppend}`;
         var element = document.getElementById(id);
         element.parentNode.parentNode.scrollTop =
            element.offsetTop - element.parentNode.parentNode.offsetTop;
         document.getElementById("messages-content-div").scrollTop -= 30;
      },

      emitSendMessageEvent(message) {
         this.$emit("onSendMessage", this.request, message);
      },
   },

   created() {
      this.socketIoConnection = socketIoService.getConnection();
   },
};
</script>

<style scoped>
::v-deep::-webkit-scrollbar {
   width: 0px;
   background-color: white;
}

::v-deep::-webkit-scrollbar-thumb {
   background-color: #c2c2c2;
}

.messages-content {
   height: 0px;
   overflow-y: scroll;
   margin-bottom: 10px;
   border-radius: 5px !important;
}

.message-box {
   max-width: 70%;
   padding: 7px 10px;
   margin-top: 2px;
   display: inline-block;
}

.message-input-height {
   height: 100%;
}

.chat-active-button {
   border: none !important;
   height: 28px !important;
}

.tabs-config {
   margin: 0px -24px;
   margin-top: 10px;
   margin-bottom: 10px;
}

@media (max-width: 959px) {
   .tabs-config {
      margin: 0px -16px;
      margin-top: 5px;
      margin-bottom: 10px;
   }
}

::v-deep .v-slide-group__content {
   height: 40px;
}

.buttons-config {
   margin-top: -24px;
}

@media (max-width: 959px) {
   .buttons-config {
      margin-top: -16px;
   }
}
</style>
