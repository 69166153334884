<template>
   <div>
      <v-dialog
         v-model="dialogWardFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeWardForm" />

            <WardForm
               @onClickCancel="closeWardForm"
               @onCreatedWard="
                  closeWardForm();
                  refreshWardList();
               "
               @onUpdatedWard="
                  closeWardForm();
                  refreshWardList();
               "
               @onClickRemoveWard="onClickRemoveWard"
               @onFindDisabledWard="onFindDisabledWard"
               :ward="wardSelected"
               :editMode="editMode"
               :healthUnit="
                  operatorCurrentHealthUnitStore ||
                     healthUnitManagerCurrentHealthUnitStore
               "
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <WardList
         @onClickAddWard="onClickAddWard"
         @onClickViewWard="onClickViewWard"
         @onClickEditWard="onClickEditWard"
         @onClickRemoveWard="onClickRemoveWard"
         :healthUnit="
            operatorCurrentHealthUnitStore ||
               healthUnitManagerCurrentHealthUnitStore
         "
         :hideAddButton="
            healthUnitManagerCurrentHealthUnitStore &&
               healthUnitManagerCurrentHealthUnitStore.id &&
               !healthUnitManagerCurrentHealthUnitStore.capabilities
                  .can_have_beds
         "
         class="mt-12"
         ref="wardlist"
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import PageTitle from "../../components/base/PageTitle";
import WardList from "../../components/ward/WardList";
import WardForm from "../../components/ward/WardForm";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import wardService from "services/wardService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "Ward",

   components: { Close, PageTitle, WardList, WardForm },

   data: function() {
      return {
         wardSelected: {},

         editMode: false,

         dialogWardFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      }
   },

   watch: {
      dialogWardFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddWard(ward) {
         this.wardSelected = Object.assign({}, ward);
         this.openWardForm();
      },

      onClickViewWard(ward) {
         this.wardSelected = Object.assign({}, ward);
         this.openWardForm();
      },

      onClickEditWard() {
         this.editMode = true;
      },

      onClickRemoveWard(item) {
         const methodDeleteItem = async () => {
            await wardService.activeWard(item.id, false);

            this.dialogWardFormVisibility = false;
            this.refreshWardList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A ala foi excluída!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir essa ala?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledWard(item) {
         const methodActiveItem = async () => {
            await wardService.activeWard(item.id, true);

            this.refreshWardList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A ala foi ativada!"
            });
         };

         this.dialogWardFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "ALA JÁ EXISTENTE",
            message:
               "Uma ala desativada com esse NOME foi encontrada, deseja ativá-la?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      openWardForm() {
         this.dialogWardFormVisibility = true;
      },

      closeWardForm() {
         this.dialogWardFormVisibility = false;
      },

      refreshWardList() {
         this.$refs.wardlist.refreshAllHelthUnitWardsByRef();
      }
   },

   /* Toda vez ao exibir essa view é verificado se já existe o ID da unidade de saúde que essa pessoa deseja acessar,
   caso não exista, a tela de escolha da unidade de saúde é exibida, caso exista não será aberta a tela de escolha. */
   created() {
      switch (this.userStore.sub_type) {
         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;
      }
   }
};
</script>

<style scoped></style>
