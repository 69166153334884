<template>
   <div>
      <ListSkeleton
         v-if="loadingRegulatorManagersFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (regulatorManager) =>
               !blockClick
                  ? onClickViewRegulatorManager(regulatorManager)
                  : null
         "
         :headers="headers"
         :items="regulatorManagers"
         :loading="loadingRegulatorManagersPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddRegulatorManager"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingRegulatorManagersPartially"
               filterActor="regulador"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalRegulatorManagers"
               :loading="loadingRegulatorManagersPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhum gestor regulador foi encontrado."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando gestores reguladores, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-regulator-manager-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.cpf`]="{ item }">
            <p class="cpf-regulator-manager-list-data">
               {{ formatCPF(item.cpf) }}
            </p>
         </template>

         <template v-slot:[`item.email`]="{ item }">
            <p class="email-regulator-manager-list-data">
               {{ item.email }}
            </p>
         </template>

         <template v-slot:[`item.birth_date`]="{ item }">
            <p class="birth_date-regulator-manager-list-data">
               {{ formatDate(item.birth_date) }}
            </p>
         </template>

         <template v-slot:[`item.last_login`]="{ item }">
            <p class="last_login-regulator-manager-list-data">
               {{ formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm") }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
               @click="editRegulatorManager(item)"
               :id="`edit-item-${index}-regulator-manager-list-button`"
               class="mx-1"
               color="black"
               small
            >
               mdi-pencil
            </v-icon>
            <v-icon
               v-if="userStore.sub_type === 'admin'"
               @click="removeRegulatorManager(item)"
               :id="`remove-item-${index}-regulator-manager-list-button`"
               color="error"
               class="mx-1"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import regulatorManagerService from "../../services/regulatorManagerService";

export default {
   name: "RegulatorManagerList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "CPF",
               class: "primary--text body-2 font-weight-medium",
               value: "cpf",
            },
            {
               text: "Email",
               class: "primary--text body-2 font-weight-medium",
               value: "email",
            },
            {
               text: "Nascimento",
               class: "primary--text body-2 font-weight-medium",
               value: "birth_date",
            },
            {
               text: "Último acesso",
               class: "primary--text body-2 font-weight-medium",
               value: "last_login",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
            {
               filter: "cpf",
               text: "CPF",
               mask: "###.###.###-##",
               unmask: /[^0-9]/g,
               maxLength: 14,
            },
            {
               filter: "email",
               text: "Email",
            },
         ],

         regulatorManagers: [],

         totalRegulatorManagers: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingRegulatorManagersFully: true,
         loadingRegulatorManagersPartially: true,

         blockClick: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "active",
                  query: true,
               },
            ],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllRegulatorManagers(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllRegulatorManagers(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllRegulatorManagers(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllRegulatorManagers(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllRegulatorManagers(this.query);
      },

      onClickAddRegulatorManager(regulatorManager) {
         this.$emit("onClickAddRegulatorManager", regulatorManager);
      },

      onClickViewRegulatorManager(regulatorManager) {
         this.$emit("onClickViewRegulatorManager", regulatorManager);
      },

      editRegulatorManager(regulatorManager) {
         this.$emit("onClickEditRegulatorManager", regulatorManager);
      },

      removeRegulatorManager(regulatorManager) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveRegulatorManager", regulatorManager);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllRegulatorManagersByRef() {
         this.loadingRegulatorManagersFully = true;
         await this.getAllRegulatorManagers(this.query);
         this.loadingRegulatorManagersFully = false;
      },

      async getAllRegulatorManagers(query) {
         this.loadingRegulatorManagersPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response =
               await regulatorManagerService.getAllRegulatorManagers(query);

            this.totalRegulatorManagers = parseInt(
               response.headers["x-total-count"]
            );
            this.regulatorManagers = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRegulatorManagersPartially = false;
         }
      },
   },

   async created() {
      this.loadingRegulatorManagersFully = true;
      await this.getAllRegulatorManagers(this.query);
      this.loadingRegulatorManagersFully = false;
   },
};
</script>

<style scoped>
</style>