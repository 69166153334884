<template>
   <div>
      <FormTitle v-if="!hideTitle" icon="mdi-bed" title="LEITO" />

      <v-alert
         v-if="getMode() === 'read' && formBed.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Leito cadastrado em
         {{ formatDate(formBed.created_at, "DD/MM/YYYY [às] HH:mm") }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <v-row class="mt-0">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.code"
                     :rules="[(value) => !!value || 'Código obrigatório.']"
                     label="Código*"
                     id="code-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     label="Nome*"
                     id="name-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.type"
                     :rules="[(value) => !!value || 'Tipo obrigatório.']"
                     label="Tipo"
                  ></v-text-field>
               </v-col>
            </v-row> -->

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.description"
                     label="Descrição"
                     id="description-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <v-row class="mt-3" v-if="bed.id">
         <v-card
            :disabled="
               operatorCurrentHealthUnitStore &&
               operatorCurrentHealthUnitStore.id &&
               !operatorCurrentHealthUnitStore.capabilities.can_have_beds
            "
            flat
         >
            <v-col cols="12">
               <p class="caption mb-1">
                  Status

                  <v-progress-circular
                     v-if="loadingRequests"
                     class="ml-1"
                     color="primary"
                     size="12"
                     width="2"
                     indeterminate
                  ></v-progress-circular>
               </p>

               <v-chip-group
                  v-if="associatedRequest && !associatedRequest.length"
                  v-model="formBed.status"
                  @change="onClickUpdateStatusBed"
                  class="mt-n2"
                  id="associated_request-bed-form-label"
                  :mandatory="!!formBed.status"
                  column
               >
                  <v-chip
                     color="success"
                     class="font-weight-bold"
                     value="free"
                     id="status-free-bed-form-input"
                     small
                     filter
                     dark
                  >
                     Livre
                  </v-chip>
                  <v-chip
                     color="warning"
                     class="font-weight-bold"
                     value="occupied"
                     id="status-occupied-bed-form-input"
                     small
                     filter
                     dark
                  >
                     Ocupado
                  </v-chip>
                  <v-chip
                     color="error"
                     class="font-weight-bold"
                     value="blocked"
                     id="status-blocked-bed-form-input"
                     small
                     filter
                     dark
                  >
                     Bloqueado
                  </v-chip>
                  <v-chip
                     color="purple"
                     class="font-weight-bold"
                     value="reserved"
                     id="status-reserved-bed-form-input"
                     small
                     filter
                     dark
                  >
                     Reservado
                  </v-chip>
               </v-chip-group>

               <v-chip
                  v-else
                  :color="configByStatus.color"
                  class="font-weight-bold"
                  value="reserved"
                  id="status-reserved-chip-bed-form-input"
                  small
                  dark
               >
                  {{ configByStatus.translation }}
               </v-chip>
            </v-col>
         </v-card>
      </v-row>

      <FormActions
         :mode="'read'"
         class="mt-7"
         hideEditButton
         hideRemoveButton
      />
   </div>
</template>

<script>
import FormActions from "../../../components/base/form/FormActions";
import FormTitle from "../../../components/base/form/FormTitle";
import formatUtil from "../../../utils/formatUtil";
import bedService from "../../../services/bedService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";
import requestService from "../../../services/requestService";

export default {
   name: "BedOperatorForm",

   components: { FormActions, FormTitle },

   props: {
      bed: {
         type: Object,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         associatedRequest: null,
         loadingRequests: false,

         formBed: {},
      };
   },

   computed: {
      configByStatus() {
         let color = "black";
         let translation = "Indefinido";

         if (this.bed && this.bed.status) {
            switch (this.bed.status) {
               case "free":
                  color = "success";
                  translation = "Livre";
                  break;
               // case "waiting_reservation":
               //    color = "success";
               //    translation = "Aguardando reserva";
               //    break;
               case "blocked":
                  color = "error";
                  translation = "Bloqueado";
                  break;
               case "occupied":
                  color = "warning";
                  translation = "Ocupado";
                  break;
               case "reserved":
                  color = "purple";
                  translation = "Reservado";
                  break;
            }
         }

         return { color, translation };
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         },
      },
   },

   watch: {
      bed: {
         immediate: true,
         handler(bed) {
            if (bed.id) {
               this.setMode("read");

               this.getAllRequests({
                  filter: [
                     { attribute: "bed_id", query: this.bed.id },
                     {
                        attribute: "is_closed",
                        query: "false",
                     },
                  ],
               });
            } else this.setMode("save");

            this.associatedRequest = null;
            this.formBed = this.formatObjectToView(bed);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,

      formatObjectToView(bed) {
         let bedTemp = util.mergeObject({}, bed);

         return bedTemp;
      },

      formatObjectToSend(bed) {
         let bedTemp = util.removeBlankAttributes(bed);

         return bedTemp;
      },

      getMode() {
         return this.mode;
      },

      setMode(mode) {
         this.mode = mode;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async onClickUpdateStatusBed(status) {
         if (this.bed.status !== status)
            await this.updateStatusBed(this.bed.ward_id, this.bed.id, {
               status,
            });
      },

      async getAllRequests(query) {
         this.loadingRequests = true;

         try {
            const response = await requestService.getAllRequests(query);

            this.associatedRequest = Object.assign([], response.data);
         } finally {
            this.loadingRequests = false;
         }
      },

      async updateStatusBed(wardId, bedId, bedStatus) {
         if (!wardId || !bedId || !bedStatus) return;

         const methodUpdateStatusItem = async () => {
            await bedService.updateStatusBed(wardId, bedId, bedStatus);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O status do leito foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedBed");
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-bed",
            title: "CONFIRMAÇÃO DE ATUALIZAÇÃO DE STATUS",
            message: "Deseja realmente atualizar o status desse leito?",
            action: {
               text: "Confirmar",
               method: methodUpdateStatusItem,
               closeOnFinished: false,
            },
         });
      },
   },
};
</script>

<style scoped>
</style>