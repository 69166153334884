<template>
   <div>
      <div v-if="patient.health_entities.length">
         <PatientHealthEntityDetails
            v-for="id in patient.health_entities"
            @onDisassociateHealthEntity="disassociate"
            :key="id"
            :healthEntityId="id"
         />
      </div>
      <div v-else>
         <NoData
            message="Desculpe, nenhuma entidade de saúde associada a esse paciente foi encontrada."
         />
      </div>

      <v-expansion-panels
         v-model="healthEntityExpansionInput"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div id="associate_new_healthentity-patient-health-units-select">
                  Associar nova entidade de saúde ao Paciente
                  <v-icon right>mdi-plus</v-icon>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-form ref="form">
                  <v-btn-toggle
                     v-model="entitiesTab"
                     class="flex-wrap mb-2"
                     dense
                  >
                     <v-btn class="overline-button primary--text">
                        <v-icon class="mr-1" color="primary" small
                           >mdi-hospital-building</v-icon
                        >
                        UNIDADE DE SAÚDE
                     </v-btn>
                     <v-btn class="overline-button error--text">
                        <v-icon class="mr-1" color="error" small
                           >mdi-home-plus</v-icon
                        >
                        SECRETARIA DE SAÚDE
                     </v-btn>
                  </v-btn-toggle>

                  <div v-if="entitiesTab === 0" class="mb-3">
                     <HealthUnitInput
                        @onSelectHealthUnit="onSelectHealthUnit"
                        ref="patienthealthunitinput"
                     />
                  </div>
                  <div v-else-if="entitiesTab === 1" class="mb-3">
                     <HealthSecretarieInput
                        @onSelectHealthSecretarie="onSelectHealthSecretary"
                        ref="patienthealthsecretaryinput"
                     />
                  </div>
               </v-form>

               <div class="d-flex justify-end align-center ma-n1">
                  <v-btn
                     @click="onClickAssociate"
                     :loading="loadingSaveAssociation"
                     class="d-none d-sm-flex ma-1"
                     color="primary"
                     id="associate-patient-health-units"
                     depressed
                     small
                     >Associar
                     <v-icon right x-small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                     @click="onClickAssociate"
                     :loading="loadingSaveAssociation"
                     class="d-flex d-sm-none ma-1"
                     color="primary"
                     id="associate_small-patient-health-units"
                     fab
                     depressed
                     x-small
                  >
                     <v-icon small>mdi-plus</v-icon>
                  </v-btn>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
   </div>
</template>

<script>
import PatientHealthEntityDetails from "./PatientHealthEntityDetails";
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import NoData from "../../base/NoData";
import patientService from "../../../services/patientService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "PatientHealthEntity",

   components: {
      PatientHealthEntityDetails,
      NoData,
      HealthUnitInput,
      HealthSecretarieInput
   },

   props: {
      patient: {
         type: Object
      }
   },

   data: function() {
      return {
         loadingSaveAssociation: false,
         healthEntityExpansionInput: null,
         healthEntitySelected: null,

         entitiesTab: 0,

         healthUnitSelected: null,
         healthSecretarySelected: null
      };
   },

   watch: {
      patient: {
         immediate: true,
         handler() {
            this.resetValidation();
         }
      }
   },

   methods: {
      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
         if (this.$refs.patienthealthunitinput)
            this.$refs.patienthealthunitinput.reset();
         if (this.$refs.patienthealthsecretaryinput)
            this.$refs.patienthealthsecretaryinput.reset();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      onSelectHealthSecretary(healthSecretary) {
         this.healthSecretarySelected = Object.assign({}, healthSecretary);
      },

      disassociate(healthEntity) {
         const methodDisassociateItem = async () => {
            await patientService.disassociatePatientToHealthEntityById(
               this.patient.id,
               healthEntity.id
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O paciente foi desassociado da entidade com sucesso!"
            });

            this.$emit("onUpdatedPatient");
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE DESASSOCIAÇÃO!",
            message:
               "Deseja realmente desassociar essa entidade de saúde do paciente?",
            action: {
               text: "Confirmar",
               method: methodDisassociateItem,
               closeOnFinished: false
            }
         });
      },

      onClickAssociate() {
         if (this.entitiesTab === 0) this.associate(this.healthUnitSelected);
         else if (this.entitiesTab === 1)
            this.associate(this.healthSecretarySelected);
      },

      associate(healthEntity) {
         this.associatePatientToHealthEntityById(
            this.patient.id,
            healthEntity ? healthEntity.id : null
         );
      },

      async associatePatientToHealthEntityById(patientId, healthEntityId) {
         if (!this.executeValidation() || !patientId || !healthEntityId) return;

         this.loadingSaveAssociation = true;

         try {
            await patientService.associatePatientToHealthEntityById(
               patientId,
               healthEntityId
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message:
                  "O paciente foi associado a entidade de saúde com sucesso!"
            });

            this.$emit("onUpdatedPatient");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAssociation = false;
         }
      }
   }
};
</script>

<style scoped>
.panels-border {
   border-style: solid;
   border-width: 1px;
   border-color: rgba(0, 0, 0, 0.2);
   margin-top: 6px;
}

.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}
</style>
