<template>
   <v-main class="background">
      <div class="image-background">
         <transition name="slide" mode="out-in">
            <router-view class="content"> </router-view>
         </transition>
      </div>
      <GoTop />
   </v-main>
</template>

<script>
import GoTop from "./GoTop";

export default {
   name: "Content",

   components: { GoTop },
};
</script>

<style scoped>
.background {
   grid-area: content;
   background-color: #f4f8fc;
}

.image-background {
   height: 100%;
   background-image: linear-gradient(
         rgba(225, 238, 245, 0.8) 100%,
         rgba(228, 238, 245, 0.8) 100%
      ),
      url("~@/assets/images/fundo.png");
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
}

.content {
   height: 100%;
   margin: 0px auto;
}

@media (min-width: 1903px) {
   .content {
      width: 75%;
      padding: 70px 0px;
   }
}

@media (min-width: 1263px) and (max-width: 1903px) {
   .content {
      width: 80%;
      padding: 60px 0px;
   }
}

@media (min-width: 959px) and (max-width: 1263px) {
   .content {
      width: 85%;
      padding: 60px 0px;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .content {
      width: 85%;
      padding: 50px 0px;
   }
}

@media (max-width: 599px) {
   .content {
      width: 90%;
      padding: 50px 0px;
   }
}

@keyframes slide-in {
   from {
      transform: translateY(-30px);
      opacity: 0;
   }
   to {
      transform: translateY(0px);
      opacity: 1;
   }
}

@keyframes slide-out {
   from {
      transform: translateY(0px);
      opacity: 1;
   }
   to {
      transform: translateY(-30px);
      opacity: 0;
   }
}

.slide-enter-active {
   animation: slide-in 0.2s ease;
}

.slide-leave-active {
   animation: slide-out 0.2s ease;
}
</style>