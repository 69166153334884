import store from "../store";
import { SNACKBAR } from "utils/translateUtil";

function translateError409(error) {
   let message = "O dado que você está tentando gravar ou editar já existe.";
   if (SNACKBAR[409][error.response.data.message])
      message = SNACKBAR[409][error.response.data.message];
   else if (SNACKBAR[409][error.response.data.description])
      message = SNACKBAR[409][error.response.data.description];

   return {
      title: "DADOS DUPLICADOS",
      message: message,
      icon: "mdi-content-copy",
      color: "warning"
   };
}

function translateError400(error) {
   let message = "Verifique os dados informados e tente novamente.";
   if (SNACKBAR[400][error.response.data.message])
      message = SNACKBAR[400][error.response.data.message];
   else if (SNACKBAR[400][error.response.data.description])
      message = SNACKBAR[400][error.response.data.description];

   return {
      title: "DADOS INCORRETOS",
      message: message,
      icon: "mdi-text-box-remove-outline",
      color: "warning"
   };
}

/* Caso seja erro no token de acesso, não mostra a snackbar, só é encaminhado para tela de login. */
function error401(error) {
   if (
      error.response.data.message === "UNAUTHORIZED" &&
      error.response.data.description ===
         "Authentication failed due to access token issues." &&
      error.response.data.redirect_link === "/auth"
   )
      return;
   else
      return {
         title: "NÃO AUTENTICADO",
         message: "Usuário não está devidamente autenticado.",
         icon: "mdi-alert-octagon-outline",
         color: "warning"
      };
}

function showCustomSnackBar(configuration) {
   store.dispatch("snackBar/customSnackbar", configuration);
   store.commit("snackBar/setVisibility", true);
}

function showErrorSnackBar(error) {
   let title, message, icon, color;

   if (error.response)
      switch (error.response.status) {
         case 400:
            ({ title, message, icon, color } = translateError400(error));
            break;
         case 401:
            var result = error401(error);
            if (result) ({ title, message, icon, color } = result);
            else return;
            break;
         case 403:
            title = "NÃO AUTORIZADO";
            message =
               "Usuário não possui permissão para acessar o recurso solicitado.";
            icon = "mdi-alert-octagon-outline";
            color = "warning";
            break;
         case 404:
            title = "NÃO ENCONTRADO";
            message =
               "Recurso solicitado encontra-se indisponível ou inexistente.";
            icon = "mdi-robot-dead-outline";
            color = "warning";
            break;
         case 409:
            ({ title, message, icon, color } = translateError409(error));
            break;
         case 429:
            title = "TENTATIVAS EXCEDIDAS";
            message = "Aguarde o tempo de 1 hora e tente novamente.";
            color = "warning";
            break;
         case 500:
            title = "ERRO INTERNO DO SERVIDOR";
            message = "Ocorreu um erro durante a operação, tente novamente.";
            color = "error";
            break;
         case 502:
            title = "SERVIÇO INDISPONÍVEL";
            message =
               "Serviço solicitado encontra-se indisponível, contate o administrador.";
            color = "error";
            break;
         default:
            title = "FALHA DURANTE A OPERAÇÃO";
            message =
               "Um erro não esperado ocorreu durante a operação, tente novamente.";
            color = "error";
            break;
      }
   else {
      title = "FALHA NA CONEXÃO";
      message = "Verifique sua conexão e tente novamente.";
      icon = "mdi-wifi-off";
      color = "error";
   }

   this.showCustomSnackBar({
      title,
      message,
      icon,
      color
   });
}

export default { showCustomSnackBar, showErrorSnackBar };
