<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="neurological.continuous_sedation"
            class="mr-3"
            label="Sedação Continua"
            id="continuous_sedation-neurological-form-input"
         ></v-checkbox>
      </div>

      <v-row class="mt-1">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.gcs"
               label="Escala de Coma de Glasgow (pontos)"
               id="gcs-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.midazolam_dose"
               label="Midazolam dose"
               id="midazolam_dose-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.fentanyl_dose"
               label="Fentanil dose"
               id="fentanyl_dose-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.propofol_dose"
               label="Propofol dose"
               id="propofol_dose-neurological-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.neuromuscular_blocker"
               label="B. Neuromuscular dose"
               id="neuromuscular_blocker-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.rocuronium"
               label="Rocurônio dose"
               id="rocuronium-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.pancuronium"
               label="Pancurônio dose"
               id="pancuronium-neurological-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.cisatracurium"
               label="Cisatracúrio dose"
               id="cisatracurium-neurological-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5 mb-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="neurological.rass"
               label="RASS"
               id="rass-neurological-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "NeurologicalForm",

   props: {
      neurological: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>