<template>
   <div>
      <FormTitle
         v-if="!hideTitle"
         icon="mdi-account-hard-hat"
         title="OPERADOR"
      />

      <v-alert
         v-if="getMode() === 'read' && formOperator.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Operador cadastrado em
         {{
            formatDate(formOperator.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     :loading="loadingOperatorByCpf"
                     :readonly="!!formOperator.id"
                     id="cpf-operator-admin-form-input"
                     label="CPF*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formOperator.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     id="name-operator-admin-form-input"
                     label="Nome*"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     id="phone-operator-admin-form-input"
                     label="Telefone*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     id="birth_date-operator-admin-form-input"
                     label="Data de nascimento*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-operator-admin-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-operator-admin-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formOperator.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-operator-admin-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-operator-admin-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-operator-admin-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formOperator.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     id="state-operator-admin-form-input"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formOperator.address.complement"
                     id="complement-operator-admin-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Dados de autenticação</p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     id="email-operator-admin-form-input"
                     label="Email*"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formOperator.id" cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.password"
                     :rules="[
                        (value) => !!value || 'Senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     id="password-operator-admin-form-input"
                     label="Senha* (mínimo 6 caracteres)"
                     type="password"
                  ></v-text-field>
               </v-col>
            </v-row>

            <div v-if="!formOperator.id">
               <p class="body-1 font-weight-medium mt-10">
                  Entidade do operador
               </p>

               <!-- <HealthUnitInput
                  @onSelectHealthUnit="onSelectHealthUnit"
                  :mode="getMode()"
                  :key="operatorHealthUnitsInputKey"
                  ref="operatoradmininput"
               /> -->

               <v-btn-toggle
                  v-model="entitiesTab"
                  class="flex-wrap mt-4 mb-2"
                  dense
               >
                  <v-btn class="overline-button primary--text">
                     <v-icon class="mr-1" color="primary" small
                        >mdi-hospital-building</v-icon
                     >
                     UNIDADE DE SAÚDE
                  </v-btn>
                  <v-btn class="overline-button error--text">
                     <v-icon class="mr-1" color="error" small
                        >mdi-home-plus</v-icon
                     >
                     SECRETARIA DE SAÚDE
                  </v-btn>
               </v-btn-toggle>

               <div v-if="entitiesTab === 0" class="mb-3">
                  <HealthUnitInput
                     @onSelectHealthUnit="onSelectHealthUnit"
                     ref="operatorahealthunitinput"
                  />

                  <p class="body-1 font-weight-medium mt-6">Acessos</p>

                  <v-chip-group 
                     v-model="capabilitiesComputed" 
                     multiple 
                     column
                  >
                     <v-chip 
                        :color="capabilitiesComputed.includes('can_access_beds') ? 'primary' : 'normal'" 
                        :class="capabilitiesComputed.includes('can_access_beds') ? 'elevation-4' : 'elevation-0'"
                        value="can_access_beds" 
                        label 
                        filter 
                        large
                     >
                        <div class="d-flex justify-space-between align-center">
                           <p class="ma-0 mx-3">Acesso aos leitos</p>
                           <v-icon>mdi-bed</v-icon>
                        </div>
                     </v-chip>
                     <v-chip 
                        :color="capabilitiesComputed.includes('can_access_surgeries') ? 'primary' : 'normal'"
                        :class="capabilitiesComputed.includes('can_access_surgeries') ? 'elevation-4': 'elevation-0'" 
                        value="can_access_surgeries" 
                        label 
                        filter 
                        large
                     >
                        <div class="d-flex justify-space-between align-center">
                           <p class="ma-0 mx-3">Acesso à cirurgias</p>
                           <v-icon>mdi-box-cutter</v-icon>
                        </div>
                     </v-chip>
                  </v-chip-group>
               </div>
               <div v-else-if="entitiesTab === 1" class="mb-3">
                  <HealthSecretarieInput
                     @onSelectHealthSecretarie="onSelectHealthSecretary"
                     ref="operatorahealthsecretaryinput"
                  />
               </div>
            </div>
         </v-card>
      </v-form>

      <UsersFormActions
         @onClickChangeType="onClickChangeType(operator)"
         @onClickReset="onClickResetOperator(operator)"
         @onClickEdit="onClickEditOperator"
         @onClickRemove="onClickRemoveOperator(operator)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveOperator"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :hideChangeTypeButton="hideChangeTypeButton"
         :loadingSave="loadingSaveOperator"
         :validationErrors="validationErrors"
         class="mt-9"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import UsersFormActions from "components/users/UsersFormActions";
import HealthUnitInput from "../../components/healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import zipCodeService from "../../services/zipCodeService";
import operatorService from "../../services/operatorService";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import responsiveUtil from "../../utils/responsiveUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "utils/defaultUtil";

export default {
   name: "OperatorAdminForm",

   components: {
      FormTitle,
      UsersFormActions,
      HealthUnitInput,
      HealthSecretarieInput,
   },

   props: {
      operator: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideChangeTypeButton: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formOperator: {},

         loadingSaveOperator: false,
         loadingSearchZipCode: false,
         loadingOperatorByCpf: false,

         entitiesTab: 0,
         healthUnitSelected: null,
         healthSecretarySelected: null,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   watch: {
      operator: {
         immediate: true,
         handler(operator) {
            if (operator.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];
            this.healthUnitSelected = null;
            this.healthSecretarySelected = null;

            this.formOperator = this.formatObjectToView(operator);

            if (this.$refs.operatorahealthunitinput)
               this.$refs.operatorahealthunitinput.reset();
            if (this.$refs.operatorahealthsecretaryinput)
               this.$refs.operatorahealthsecretaryinput.reset();
            this.resetValidation();
         },
      },

      "formOperator.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;
            try {
               const response = await zipCodeService.getZipCode(newZip);
               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formOperator.address.street = response.data.logradouro;
                  this.formOperator.address.district = response.data.bairro;
                  this.formOperator.address.city = response.data.localidade;
                  this.formOperator.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formOperator.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formOperator.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getOperatorByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      },
   },

   computed: {
      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      },

      capabilitiesComputed: {
         get() {
            let result = [];
            if (this.formOperator.access_capabilities) {
               if (this.formOperator.access_capabilities.can_access_beds)
                  result.push("can_access_beds");
               if (this.formOperator.access_capabilities.can_access_surgeries)
                  result.push("can_access_surgeries");
            }

            return result;
         },

         set(value) {
            this.formOperator.access_capabilities.can_access_beds =
               value.includes("can_access_beds");
            this.formOperator.access_capabilities.can_access_surgeries =
               value.includes("can_access_surgeries");
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...responsiveUtil,

      formatObjectToView(operator) {
         let operatorTemp = util.mergeObject(
            {
               address: {},
               health_units: [],
               access_capabilities: {
                  can_access_beds: false,
                  can_access_surgeries: false
               }
            },
            operator
         );

         if (operatorTemp.birth_date)
            operatorTemp.birth_date = formatUtil.formatDate(
               operatorTemp.birth_date
            );

         if (operatorTemp.cpf)
            operatorTemp.cpf = formatUtil.formatCPF(operatorTemp.cpf);

         if (operatorTemp.phone)
            operatorTemp.phone = formatUtil.formatPhone(operatorTemp.phone);

         return operatorTemp;
      },

      formatObjectToSend(operator, removeEmptyStrings) {
         let operatorTemp = util.removeBlankAttributes(operator, {
            emptyStrings: removeEmptyStrings,
         });

         if (operatorTemp.birth_date)
            operatorTemp.birth_date = formatUtil.formatDate(
               operatorTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (operatorTemp.cpf)
            operatorTemp.cpf = operatorTemp.cpf.replace(/[^0-9]/g, "");

         if (operatorTemp.phone)
            operatorTemp.phone = operatorTemp.phone.replace(/[^0-9]/g, "");

         if (!operatorTemp.id)
            operatorTemp.capabilities = this.formatCapabilities(operatorTemp.access_capabilities);

         delete operatorTemp.access_capabilities;
         return operatorTemp;
      },

      formatCapabilities(operatorAccess) {
         const capabilities = [];

         if (operatorAccess.can_access_surgeries)
            capabilities.unshift("surgeries");
         if (operatorAccess.can_access_beds)
            capabilities.unshift("beds");

         return capabilities;
      },

      onClickChangeType(operator) {
         this.$emit("onClickChangeType", operator);
      },

      onClickResetOperator(operator) {
         this.$emit("onClickResetOperator", operator);
      },

      onClickEditOperator() {
         this.setMode("save");
      },

      onClickRemoveOperator(operator) {
         this.$emit("onClickRemoveOperator", operator);
      },

      onClickCancel() {
         this.setMode("read");
         this.formOperator = this.formatObjectToView(this.operator);
         this.$emit("onClickCancel");
      },

      onClickSaveOperator() {
         if (this.formOperator.id)
            this.updateOperator(this.formOperator.id, this.formOperator);
         else this.createOperator(this.formOperator);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      onSelectHealthSecretary(healthSecretary) {
         this.healthSecretarySelected = Object.assign({}, healthSecretary);
      },

      async getOperatorByCpf(cpf) {
         this.loadingOperatorByCpf = true;

         try {
            const response = await operatorService.getAllOperators({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "active",
                     query: false,
                  },
                  {
                     attribute: "cpf",
                     query: cpf,
                  },
               ],
            });

            if (response.data && response.data.length)
               this.$emit("onFindDisabledOperator", response.data[0]);
            else {
               const operatorResponse = await checkCpfService.getCheckCpf(cpf);

               if (operatorResponse.data && operatorResponse.data.name !== "")
                  this.formOperator.name = operatorResponse.data.name;
            }
         } catch (error) {
            if (
               error.response &&
               error.response.status === 404 &&
               error.response.data &&
               error.response.data.description ===
                  "Cpf provided does not belong to any registered user."
            ) {
               delete this.formOperator.name;
               snackBarUtil.showCustomSnackBar({
                  title: "CPF NÃO ENCONTRADO",
                  message:
                     "O CPF informado não foi encontrado na base de dados!",
                  icon: "mdi-account-off",
                  color: "info",
               });
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingOperatorByCpf = false;
         }
      },

      async createOperator(operator) {
         if (!this.executeValidation() || !operator) return;

         if (
            this.entitiesTab === 0 &&
            !(this.healthUnitSelected && this.healthUnitSelected.id)
         )
            return;
         if (
            this.entitiesTab === 1 &&
            !(this.healthSecretarySelected && this.healthSecretarySelected.id)
         )
            return;

         this.loadingSaveOperator = true;

         try {
            const objectToSend = this.formatObjectToSend(operator);

            if (this.entitiesTab === 0)
               objectToSend.healthunit_id = this.healthUnitSelected.id;
            else if (this.entitiesTab === 1)
               objectToSend.healthsecretary_id =
                  this.healthSecretarySelected.id;

            await operatorService.createOperator(objectToSend);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveOperator = false;
         }
      },

      async updateOperator(operatorId, operator) {
         if (!this.executeValidation() || !operatorId || !operator) return;

         this.loadingSaveOperator = true;

         try {
            await operatorService.updateOperator(
               operatorId,
               this.formatObjectToSend(operator, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveOperator = false;
         }
      },
   },
};
</script>

<style scoped>
.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}
</style>