import axiosService from "../services/axiosService";
import axios from "axios";
import { API_VERSION } from "src/utils/defaultUtil";

async function getAllOperators(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/operators?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item, index) => {
         switch (item.attribute) {
            case "health_units":
               url += `&${item.attribute}.id=${item.query}`;
               break
            case "active":
            case "health_secretaries":
            case "cpf":
               url += `&${item.attribute}=${item.query}`;
               break;
            default:
               url += `&${item.attribute}=*${item.query}*`;
               break;
         }

         if (index < query.filter.length - 1) url += "&";
      });
   }
   return await axiosService.get(url);
}

async function getOperatorById(operatorId) {
   return await axiosService.get(`/operators/${operatorId}`);
}

async function getOperatorByCpf(operatorCpf) {
   return await axiosService.get(`/operators/${operatorCpf}`);
}

async function createOperator(operator) {
   return await axiosService.post(`/operators`, operator);
}

async function updateOperator(operatorId, operator) {
   return await axiosService.patch(`/operators/${operatorId}`, operator);
}

async function removeOperator(operatorId, active) {
   return await axiosService.put(`/users/${operatorId}/active`, { active });
}

async function associateOperatorToHealthUnitById(operatorId, healthunitId, capabilities) {
   const temp = await axiosService.post(
      `/operators/${operatorId}/healthunits/${healthunitId}`,
      {
         capabilities
      }
   );

   return temp;
}

async function disassociateOperatorFromHealthUnitById(
   operatorId,
   healthunitId
) {
   return await axiosService.delete(
      `/operators/${operatorId}/healthunits/${healthunitId}`
   );
}

async function associateOperatorToHealthSecretarieById(
   operatorId,
   secretarieId
) {
   return await axiosService.post(
      `/operators/${operatorId}/healthsecretaries/${secretarieId}`
   );
}

async function disassociateOperatorFromHealthSecretarieById(
   operatorId,
   secretarieId
) {
   return await axiosService.delete(
      `/operators/${operatorId}/healthsecretaries/${secretarieId}`
   );
}

async function getOperatorToken(operatorId, token, role) {
   return await axios.patch(
      `${process.env.VUE_APP_API_URL ||
      "VUE_APP_API_URL"}${API_VERSION}/operators/${operatorId}/token`,
      role,
      {
         headers: {
            Authorization: `bearer ${token}`
         }
      }
   );
}

export default {
   createOperator,
   updateOperator,
   removeOperator,
   getAllOperators,
   getOperatorByCpf,
   getOperatorById,
   associateOperatorToHealthUnitById,
   disassociateOperatorFromHealthUnitById,
   associateOperatorToHealthSecretarieById,
   disassociateOperatorFromHealthSecretarieById,
   getOperatorToken
};
