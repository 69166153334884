<template>
   <div class="d-flex justify-space-between align-center">
      <div
         v-if="bed.status === 'reserved' && request.status === 'regulated'"
         class="d-flex justify-end align-center flex-grow-1"
      >
         <v-btn
            @click="confirmReservation"
            :loading="loadingAction"
            class="d-none d-sm-flex"
            color="primary"
            id="confirm_bed-bed-request-actions-button"
            depressed
            >Ciente
            <v-icon right small>mdi-bed</v-icon>
         </v-btn>
         <v-btn
            @click="confirmReservation"
            :loading="loadingAction"
            class="d-flex d-sm-none"
            color="primary"
            id="confirm_bed_small-bed-request-actions-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-bed</v-icon>
         </v-btn>
      </div>

      <div
         v-else-if="bed.status === 'reserved'"
         class="d-flex justify-end align-center flex-grow-1"
      >
         <v-btn
            @click="confirmArrival"
            :loading="loadingAction"
            class="d-none d-sm-flex"
            color="primary"
            id="confirm_delivery-bed-request-actions-button"
            depressed
            >Confirmar chegada
            <v-icon right small>mdi-truck-delivery</v-icon>
         </v-btn>
         <v-btn
            @click="confirmArrival"
            :loading="loadingAction"
            class="d-flex d-sm-none"
            color="primary"
            id="confirm_delivery_small-bed-request-actions-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-truck-delivery</v-icon>
         </v-btn>
      </div>

      <div
         v-else-if="bed.status === 'occupied'"
         class="d-flex justify-space-between flex-grow-1"
      >
         <v-btn
            @click="transferReservation"
            :loading="loadingAction"
            class="d-none d-sm-flex"
            color="primary"
            id="confirm_outcome-bed-request-actions-button"
            depressed
         >
            TRANSFERÊNCIA INTERNA
            <v-icon right small>mdi-bed</v-icon>
         </v-btn>
         <v-btn
            @click="transferReservation"
            :loading="loadingAction"
            class="d-flex d-sm-none"
            color="primary"
            id="confirm_outcome_small-bed-request-actions-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-bed</v-icon>
         </v-btn>
         <v-btn
            @click="confirmOutcome"
            :loading="loadingAction"
            class="d-none d-sm-flex"
            color="primary"
            id="confirm_outcome-bed-request-actions-button"
            depressed
            >Confirmar desfecho
            <v-icon right small>mdi-logout</v-icon>
         </v-btn>
         <v-btn
            @click="confirmOutcome"
            :loading="loadingAction"
            class="d-flex d-sm-none"
            color="primary"
            id="confirm_outcome_small-bed-request-actions-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-logout</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   name: "BedRequestActions",

   props: {
      bed: {
         type: Object,
      },

      request: {
         type: Object,
      },

      loadingAction: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      cancelReservation() {
         this.$emit("onClickCancelReservation");
      },

      confirmReservation() {
         this.$emit("onClickConfirmReservation");
      },

      confirmArrival() {
         this.$emit("onClickConfirmArrival");
      },

      confirmOutcome() {
         this.$emit("onClickConfirmOutcome");
      },

      transferReservation() {
         this.$emit("onClickTransferReservation");
      },
   },
};
</script>

<style scoped>
.form-actions-position {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

@media (max-width: 959px) {
   .form-actions-position {
      bottom: 16px;
   }
}
</style>