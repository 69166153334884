<template>
   <svg id="reg-logo" data-name="reg-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 940.79 566.47"
      xml:space="preserve" class="config">
      <g id="reg-logo" data-name="reg-logo">
         <g>
            <g>
               <path class="cls-9"
                  d="m379.31,67.9c5.46-2.52,11.02-4.78,16.99-5.84,1.88,1.85,1.62,4.27,1.63,6.56.05,31.2.04,62.4,0,93.6,0,2.15.08,4.34-1.15,6.28-2.78,2.07-5.61,2.15-8.48.19-50.36-.03-100.72-.26-151.08.06-10.09.07-19.24-5.99-18.73-18.56.66-16.41.16-32.86.16-49.29,0-15.78,4.29-20.1,19.98-20.1,2.45-1.96,5.37-1.44,8.14-1.44,34.51-.04,69.03-.12,103.54.07,6.27.03,11.53-1.2,16.48-5.32,3.54-2.94,7.07-6.74,12.51-6.23Z" />
               <path class="cls-7"
                  d="m474.24.21c45.36-.02,90.72-.53,136.07.14,28.11.42,52.4,22.51,58.56,49.78.87,3.86.22,7.95,2.02,11.63.51,1.77.56,3.58.56,5.42-.03,32.15-.04,64.3,0,96.46,0,1.65.21,3.33-.63,4.87-10.32,0-20.63,0-30.95,0-1.55-1.57-1.56-3.61-1.56-5.59.02-33.45-.23-66.91.11-100.36-1.01-18.03-14.34-30.89-32.46-30.93-41.21-.1-82.42-.01-123.63-.06-2.92,0-6.04.64-8.6-1.55-2.31-7.14-.71-14.48-1.08-21.71-.14-2.76-.51-5.7,1.61-8.07Z" />
               <path class="cls-5"
                  d="m589.97,168.45c-9.96.09-19.93.18-29.89.26-2.08,1.87-4.67,1.47-7.1,1.47-11.93.03-23.86.12-35.78-.06-7.54.19-15.07.07-22.61.05-1.96,0-3.96-.02-5.64-1.3-1.33-1.68-1.36-3.7-1.36-5.68-.05-25.82-.08-51.64,0-77.46,0-1.16.09-2.32.33-3.45.18-.88.49-1.72.91-2.51,26.15-30.5,70.5-25.16,89.96.32.23.54.38,1.09.48,1.67.5,21.32.33,42.65.12,63.97-.09,8.83,1.05,16.52,9.73,21.05.6.31.85.98.85,1.68Z" />
               <path class="cls-3"
                  d="m379.31,67.9c-5.58,2.67-10.71,6.11-15.3,10.17-2.51,2.22-5,2.78-8.11,2.77-39.09-.05-78.18-.03-117.27-.02.36-9.59-.94-19.23.84-28.77,3.4-18.32,19.16-31.87,37.8-32.07,20.75-.22,41.51-.2,62.26-.04,21.89.17,38.98,17.1,39.68,39.01.1,2.98.07,5.97.1,8.95Z" />
               <path class="cls-8"
                  d="m517.1,168.7c14.33,0,28.66,0,42.98.01,3.78,3.93,7.49,7.91,11.34,11.77,18.48,18.5,37,36.97,55.51,55.44,1.5,1.5,2.79,3.34,5.18,3.59-.24,1.75-1.72,1.86-2.97,2.13-8.92,1.97-16.25,6.57-22.4,13.21-.72.78-1.37,2.03-2.84,1.33-2.62-2.76-5.28-5.47-7.83-8.28-1.86-2.05-4.24-1.54-6.51-1.54-38.46-.03-76.91-.02-115.37-.03-.78-.89-1.35-1.92-1.37-3.09-.12-8.67-.9-17.34.46-25.98,1.9-2.98,4.99-2.17,7.68-2.17,26.41-.07,52.83-.05,79.79-.04-.8-1.53-1.98-2.36-2.94-3.31-12.32-12.33-24.68-24.62-36.97-36.99-1.68-1.69-4.07-3.09-3.76-6.07Z" />
               <path class="cls-8"
                  d="m474.21,216.47c0,9.96-.02,19.92-.02,29.88-38.49,0-76.98.06-115.47-.07-4.36-.02-7.85.6-9.82,4.9-.96,2.09-4.38,2.04-4.44,4.97-1.72.83-2.34-.75-3.13-1.59-6.02-6.37-13.13-10.83-21.73-12.82-1.35-.31-3.09-.25-3.33-2.27,1.85-.06,2.95-1.34,4.12-2.51,22.13-22.11,44.27-44.21,66.4-66.33.58-.58,1.01-1.29,1.52-1.94,2.63-.06,5.27-.12,7.9-.17,1.65-1.33,3.63-1.47,5.62-1.46,7.91.02,15.82-.2,23.72.11,1.52.06,3.07.05,4.4.96,1.61,2.7-.4,4.45-1.92,6-13.68,13.97-27.59,27.71-41.18,41.27.58-.15,1.56-.3,2.56-.3,26.73-.08,53.46-.06,80.18,0,1.64,0,3.36-.02,4.61,1.38Z" />
               <path class="cls-2"
                  d="m603.9,256.2c7.41-8.93,16.9-14.33,28.21-16.68,19.92-1.65,35.66,5.32,45.51,23.06,10.31,18.56,5.12,42.16-11.52,55.14-17.05,13.3-40.58,12.48-56.77-1.96-15.76-14.06-19.07-37.71-7.8-55.79.78-1.26,1.58-2.51,2.37-3.77Z" />
               <path class="cls-4"
                  d="m316.27,239.47c11.27,2.4,20.77,7.79,28.19,16.67,14.61,20.89,9.43,41.99-.63,54.55-12.51,15.62-34.57,20.9-52.65,12.43-19.18-8.99-29.13-29.22-24.56-49.92,4.27-19.31,22.16-33.69,42.17-33.87,2.49-.02,4.98.09,7.48.14Z" />
               <path class="cls-8"
                  d="m426.32,168.56c-10.04-.01-20.08-.02-30.12-.03.03-35.49.06-70.98.08-106.46-.05-29.41,22.88-55.45,52.16-60.94,8.59-1.61,17.19-.67,25.78-.91,0,9.92.01,19.85.02,29.77-3.93,2.72-8.37,1.3-12.54,1.32-6.13.03-11.78,1.19-17.21,4.06-7.94,4.21-12.7,10.81-15.74,19.06-1.23,3.34-.05,6.53-.32,9.79-.65,2.95-.59,5.93-.59,8.91,0,29.12.02,58.24-.02,87.36,0,2.76.5,5.68-1.52,8.09Z" />
               <path class="cls-5"
                  d="m670.81,168.49c-1.14-1.8-.9-3.82-.9-5.79,0-31.92-.02-63.84,0-95.77,0-1.76-.54-3.68.97-5.18,12.92,2.15,24.31,7.65,34.28,16.02,2.45,2.06,4.86,3.02,8.07,3.02,10.84,0,16.45,5.77,16.47,16.67.02,18.08.03,36.17,0,54.25-.02,10.84-6.08,16.84-17.03,16.85-13.95,0-27.91-.05-41.86-.08Z" />
               <path class="cls-5"
                  d="m578.12,81.13c-.05-.28-.1-.57-.14-.85,1.25-2.16,3.43-1.7,5.33-1.73,6.13-.12,12.26-.16,18.39,0,3.86.1,7-.95,9.96-3.53,6.13-5.35,12.66-10.2,20.6-12.65,2.47-.76,4.96-2.15,7.62-.55,0,35.55-.01,71.11-.02,106.66-14.35-.03-28.7-.06-43.05-.08.25-1.71,1.69-2.4,2.92-3.16,4.7-2.89,6.62-7.22,6.64-12.54.09-21.67-.01-43.35.07-65.02.01-3.75-1.35-5.42-5.23-5.31-5.63.16-11.26.08-16.9.02-2.13-.02-4.35.23-6.19-1.26Z" />
               <path class="cls-2"
                  d="m578.12,81.13c8.96-.11,17.92-.18,26.88-.37,2.46-.05,3.09,1.01,3.08,3.27-.06,23.54.06,47.09-.14,70.63-.06,7.22-4.92,11.19-11.14,13.75-2.28.02-4.55.03-6.83.05-8.57-3.14-12.05-9.27-11.94-18.35.26-22.99.09-45.98.1-68.97Z" />
               <path class="cls-9"
                  d="m459.03,168.64c-9.59.02-19.18.04-28.76.07-1.32-.05-2.63-.1-3.95-.15-.03-33.79-.06-67.58-.06-101.38,0-1.68-.71-3.85,2.02-4.33,10.09,2.68,18.84,7.89,26.81,14.46,2.16,1.78,4.35,2.68,7.19,2.64,9.26-.14,18.52-.09,27.78-.12-.31.36-.62.73-.93,1.09-4.8,2.83-10.03,1.1-15.06,1.44-2.81.24-5.63.16-8.44.13-3.34-.04-4.79,1.49-4.8,4.79-.01,4.96-.02,9.92-.02,14.88-.01,19.68.03,39.36-.04,59.03,0,2.57.54,5.37-1.73,7.45Z" />
               <path class="cls-2"
                  d="m474.21,80.96c4.98-.01,9.95-.03,14.93-.04.05,29.24.1,58.47.16,87.7-.64,11.78-5.35,17.33-15.08,17.78-1.83-1.86-1.63-4.26-1.63-6.55-.04-30.78-.04-61.55,0-92.33,0-2.3-.14-4.68,1.63-6.57Z" />
               <path class="cls-4"
                  d="m474.21,80.96c0,35.15,0,70.29,0,105.44-9.8-.34-14.52-5.87-15.17-17.76.05-27.38.2-54.76.06-82.14-.02-4.41.91-6.39,5.66-5.76,3.11.41,6.3.17,9.46.22Z" />
            </g>
            <g>
               <g>
                  <path class="cls-6"
                     d="m287.2,527.93v-135.79h26.77l54.9,76.41h1.74v-76.41h31.79v135.79h-26.58l-55.09-77.16h-1.73v77.16h-31.79Z" />
                  <path class="cls-6"
                     d="m403.9,529.43h-28.86l-.45-.63-54.1-75.78v76.41h-34.79v-138.79h29.04l54.37,75.67v-75.67h34.79v138.79Zm-27.31-3h24.31v-132.79h-28.79v76.41h-4.01l-.45-.62-54.46-75.78h-24.5v132.79h28.79v-77.16h4.01l.45.63,54.64,76.53Z" />
               </g>
               <g>
                  <path class="cls-6"
                     d="m469.89,477.4c0,15.81,8.41,25.12,23.3,25.12s23.2-9.32,23.2-25.12v-85.26h33.53v88.93c0,30.49-22.47,50.53-56.73,50.53s-56.82-20.04-56.82-50.53v-88.93h33.53v85.26Z" />
                  <path class="cls-6"
                     d="m493.18,533.1c-17.18,0-31.8-4.91-42.25-14.21-10.51-9.34-16.07-22.42-16.07-37.82v-90.43h36.53v86.76c0,15.01,7.94,23.62,21.8,23.62s21.7-8.39,21.7-23.62v-86.76h36.53v90.43c0,15.4-5.54,28.48-16.02,37.82-10.43,9.3-25.03,14.21-42.21,14.21Zm-55.32-139.46v87.43c0,29.79,21.71,49.03,55.32,49.03s55.23-19.25,55.23-49.03v-87.43h-30.53v83.76c0,16.67-9.23,26.62-24.7,26.62s-24.8-9.95-24.8-26.62v-83.76h-30.53Z" />
               </g>
               <g>
                  <path class="cls-6" d="m614.66,527.93v-108.03h-38v-27.76h109.53v27.76h-38.09v108.03h-33.44Z" />
                  <path class="cls-6"
                     d="m649.6,529.43h-36.44v-108.03h-38v-30.76h112.53v30.76h-38.09v108.03Zm-33.44-3h30.44v-108.03h38.09v-24.76h-106.53v24.76h38v108.03Z" />
               </g>
               <g>
                  <path class="cls-6"
                     d="m803.2,527.93h-90.35v-135.79h90.35v27.76h-56.82v27.1h53.44v25.41h-53.44v27.76h56.82v27.76Z" />
                  <path class="cls-6"
                     d="m804.7,529.43h-93.35v-138.79h93.35v30.76h-56.82v24.1h53.44v28.41h-53.44v24.76h56.82v30.76Zm-90.35-3h87.35v-24.76h-56.82v-30.76h53.44v-22.41h-53.44v-30.1h56.82v-24.76h-87.35v132.79Z" />
               </g>
               <g>
                  <path class="cls-6"
                     d="m860.82,489.25c.82,9.88,10.42,16.19,24.3,16.19,12.52,0,21.1-6.21,21.1-15.15,0-7.53-5.75-11.57-20.83-14.68l-17.36-3.58c-24.12-4.71-36.54-18.26-36.54-39.62,0-26.54,20.65-43.95,52.44-43.95s52.25,17.22,52.8,42.35h-30.97c-.73-9.6-9.59-16.28-21.38-16.28s-19.55,5.74-19.55,14.77c0,7.43,5.85,11.76,19.73,14.59l16.99,3.39c26.04,5.18,37.73,17.31,37.73,38.86,0,28.33-21.01,45.45-55.54,45.45s-54.36-16.19-54.9-42.35h31.97Z" />
                  <path class="cls-6"
                     d="m883.75,533.1c-34.22,0-55.84-16.79-56.4-43.81l-.03-1.53h34.88l.12,1.38c.75,9,9.7,14.81,22.81,14.81,11.54,0,19.6-5.61,19.6-13.65,0-5.66-3.28-9.84-19.63-13.21l-17.36-3.58c-24.68-4.82-37.74-19.02-37.74-41.09,0-27.61,21.17-45.44,53.94-45.44,15.48,0,28.87,4.18,38.71,12.08,9.92,7.96,15.31,18.94,15.59,31.73l.03,1.53h-33.89l-.11-1.39c-.67-8.77-8.84-14.89-19.88-14.89s-18.05,5.21-18.05,13.27c0,6.64,5.37,10.44,18.53,13.12l16.99,3.39c26.56,5.28,38.94,18.1,38.94,40.33,0,14.36-5.32,26.29-15.37,34.51-9.96,8.14-24.37,12.44-41.67,12.44Zm-53.35-42.35c1.31,24.32,21.59,39.35,53.35,39.35,33.84,0,54.04-16.43,54.04-43.95,0-20.84-11.26-32.37-36.52-37.39l-16.99-3.39c-14.68-2.98-20.94-7.79-20.94-16.06,0-9.89,8.26-16.27,21.05-16.27,12.1,0,21.23,6.62,22.71,16.28h28.07c-1.35-23.6-21.73-39.35-51.24-39.35s-50.94,16.66-50.94,42.44c0,20.74,11.89,33.57,35.33,38.14l17.37,3.58c13.22,2.72,22.03,6.51,22.03,16.15s-9.29,16.65-22.6,16.65c-14.14,0-24.01-6.3-25.63-16.19h-29.09Z" />
               </g>
            </g>
         </g>
         <g>
            <path class="cls-1"
               d="m0,425.5h17.08v15.34h1.46c2.83-9.97,13.98-17.13,26.95-17.13,2.74,0,6.3.28,8.31.66v17.97c-1.37-.47-7.22-1.13-10.51-1.13-14.8,0-25.49,10.07-25.49,24.37v61.82H0v-101.91Z" />
            <path class="cls-1"
               d="m150.15,500.5c-4.66,17.88-20.19,28.79-42.39,28.79-27.95,0-45.22-20.23-45.22-52.7s17.54-52.98,45.13-52.98,43.67,19.48,43.67,51.28v6.68h-70.53v.85c.73,19.1,11.24,31.24,27.41,31.24,12.24,0,20.55-4.61,24.48-13.17h17.45Zm-69.34-32.94h52.07c-.37-17.13-10.32-28.32-25.3-28.32s-25.58,11.29-26.77,28.32Z" />
            <path class="cls-1"
               d="m171.86,538.14h18.45c1.64,7.9,10.23,12.99,23.84,12.99,16.99,0,27.04-8.47,27.04-22.77v-19.76h-1.46c-6.12,11.1-17.18,17.31-30.79,17.31-25.4,0-41.2-20.23-41.2-50.81s15.99-51.38,41.29-51.38c13.79,0,25.85,6.96,31.43,17.97h1.46v-16.19h17.08v103.23c0,23.05-17.36,37.73-44.95,37.73-24.3,0-40.01-11.29-42.21-28.32Zm41.66-98.15c-17.45,0-27.32,13.74-27.32,35.1s9.77,35.01,27.32,35.01,28.04-13.64,28.04-35.01-10.6-35.1-28.04-35.1Z" />
         </g>
      </g>
   </svg>
</template>

<script>
export default {
   name: "RegLogo"
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}

.cls-1 {
   fill: #6f79e2;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9 {
   stroke-width: 0px;
}

.cls-2 {
   fill: #38336b;
}

.cls-3 {
   fill: #6f79e1;
}

.cls-4 {
   fill: #524797;
}

.cls-5 {
   fill: #6daafb;
}

.cls-6 {
   fill: #6dabfc;
}

.cls-7 {
   fill: #8a8ab7;
}

.cls-8 {
   fill: #b4b4d8;
}

.cls-9 {
   fill: #9cdef5;
}

</style>
