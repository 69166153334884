import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

import BoxCutterCheck from "src/assets/icons/BoxCutterCheck.vue";
import BoxCutterClose from "src/assets/icons/BoxCutterClose.vue";
import SourceHospital from "src/assets/icons/SourceHospital.vue";
import TargetHospital from "src/assets/icons/TargetHospital.vue";
import CoracaoParaibano from "src/assets/icons/CoracaoParaibano.vue";
import HospitalBed from "src/assets/icons/HospitalBed.vue";
import HospitalBedAzul from "src/assets/icons/HospitalBedAzul.vue";
import RegLogo from "src/assets/icons/RegLogo.vue";

Vue.use(Vuetify);

export default new Vuetify({
   theme: {
      options: { customProperties: true },
      themes: {
         light: {
            primary: colors.indigo.lighten1,
            black: "#494949",

            primaryLight: colors.indigo.lighten5,
            successLight: colors.green.lighten5,
            errorLight: colors.red.lighten5,
            warningLight: colors.orange.lighten5,
            infoLight: colors.blue.lighten5,
            purpleLight: colors.purple.lighten5,
            blackLight: "#DBDBDB"
         }
      }
   },

   icons: {
      values: {
         "box-cutter-check": {
            component: BoxCutterCheck
         },
         "box-cutter-close": {
            component: BoxCutterClose
         },
         "source-hospital": {
            component: SourceHospital
         },
         "target-hospital": {
            component: TargetHospital
         },
         "coracao-paraibano": {
            component: CoracaoParaibano
         },
         "hospital-bed": {
            component: HospitalBed
         },
         "hospital-bed-azul": {
            component: HospitalBedAzul
         },
         "reg-logo": {
            component: RegLogo
         }
      }
   }
});
