<template>
   <div>
      <FormTitle icon="mdi-account-cog" title="ADMINISTRADOR" />

      <v-alert
         v-if="getMode() === 'read' && formAdmin.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Administrador cadastrado em
         {{
            formatDate(formAdmin.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formAdmin.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     :loading="loadingAdminByCpf"
                     :readonly="!!formAdmin.id"
                     id="cpf-admin-form-input"
                     label="CPF*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formAdmin.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     id="name-admin-form-input"
                     label="Nome*"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formAdmin.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     id="phone-admin-form-input"
                     label="Telefone*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formAdmin.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     id="birth_date-admin-form-input"
                     label="Data de nascimento*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formAdmin.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-admin-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formAdmin.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-admin-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formAdmin.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-admin-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formAdmin.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-admin-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formAdmin.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-admin-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formAdmin.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     menu-props="auto"
                     id="state-admin-form-input"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formAdmin.address.complement"
                     id="complement-admin-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Dados de autenticação</p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formAdmin.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     id="email-admin-form-input"
                     label="Email*"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formAdmin.id" cols="12" md="6">
                  <v-text-field
                     v-model="formAdmin.password"
                     :rules="[
                        (value) => !!value || 'Senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     id="password-admin-form-input"
                     label="Senha* (mínimo 6 caracteres)"
                     type="password"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <UsersFormActions
         @onClickChangeType="onClickChangeType(admin)"
         @onClickEdit="onClickEditAdmin"
         @onClickRemove="onClickRemoveAdmin(admin)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveAdmin"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveAdmin"
         :validationErrors="validationErrors"
         hideResetPasswordButton
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import UsersFormActions from "components/users/UsersFormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import adminService from "../../services/adminService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "src/utils/defaultUtil";

export default {
   name: "AdminForm",

   components: { FormTitle, UsersFormActions },

   props: {
      admin: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formAdmin: {},

         loadingSaveAdmin: false,
         loadingSearchZipCode: false,
         loadingAdminByCpf: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   watch: {
      admin: {
         immediate: true,
         handler(admin) {
            if (admin.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formAdmin = this.formatObjectToView(admin);

            this.resetValidation();
         },
      },

      "formAdmin.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formAdmin.address.street = response.data.logradouro;
                  this.formAdmin.address.district = response.data.bairro;
                  this.formAdmin.address.city = response.data.localidade;
                  this.formAdmin.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formAdmin.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formAdmin.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getAdminByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      },
   },

   computed: {
      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(admin) {
         let adminTemp = util.mergeObject(
            {
               address: {},
            },
            admin
         );

         if (adminTemp.birth_date)
            adminTemp.birth_date = formatUtil.formatDate(adminTemp.birth_date);

         if (adminTemp.cpf) adminTemp.cpf = formatUtil.formatCPF(adminTemp.cpf);

         if (adminTemp.phone)
            adminTemp.phone = formatUtil.formatPhone(adminTemp.phone);

         return adminTemp;
      },

      formatObjectToSend(admin, removeEmptyStrings) {
         let adminTemp = util.removeBlankAttributes(admin, {
            emptyStrings: removeEmptyStrings,
         });

         if (adminTemp.birth_date)
            adminTemp.birth_date = formatUtil.formatDate(
               adminTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (adminTemp.cpf)
            adminTemp.cpf = adminTemp.cpf.replace(/[^0-9]/g, "");

         if (adminTemp.phone)
            adminTemp.phone = adminTemp.phone.replace(/[^0-9]/g, "");

         return adminTemp;
      },

      onClickChangeType(admin) {
         this.$emit("onClickChangeType", admin);
      },

      onClickEditAdmin() {
         this.setMode("save");
      },

      onClickRemoveAdmin(admin) {
         this.$emit("onClickRemoveAdmin", admin);
      },

      onClickCancel() {
         this.setMode("read");
         this.formAdmin = this.formatObjectToView(this.admin);
         this.$emit("onClickCancel");
      },

      onClickSaveAdmin() {
         if (this.formAdmin.id)
            this.updateAdmin(this.formAdmin.id, this.formAdmin);
         else this.createAdmin(this.formAdmin);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async getAdminByCpf(cpf) {
         this.loadingAdminByCpf = true;

         try {
            const response = await adminService.getAllAdmins({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "active",
                     query: false,
                  },
                  {
                     attribute: "cpf",
                     query: cpf,
                  },
               ],
            });

            if (response.data && response.data.length)
               this.$emit("onFindDisabledAdmin", response.data[0]);
            else {
               const adminResponse = await checkCpfService.getCheckCpf(cpf);

               if (adminResponse.data && adminResponse.data.name !== "")
                  this.formAdmin.name = adminResponse.data.name;
            }
         } catch (error) {
            if (
               error.response &&
               error.response.status === 404 &&
               error.response.data &&
               error.response.data.description ===
                  "Cpf provided does not belong to any registered user."
            ) {
               delete this.formAdmin.name;
               snackBarUtil.showCustomSnackBar({
                  title: "CPF NÃO ENCONTRADO",
                  message:
                     "O CPF informado não foi encontrado na base de dados!",
                  icon: "mdi-account-off",
                  color: "info",
               });
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingAdminByCpf = false;
         }
      },

      async createAdmin(admin) {
         if (!this.executeValidation() || !admin) return;

         this.loadingSaveAdmin = true;

         try {
            await adminService.createAdmin(this.formatObjectToSend(admin));

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O administrador foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedAdmin");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAdmin = false;
         }
      },

      async updateAdmin(adminId, admin) {
         if (!this.executeValidation() || !adminId || !admin) return;

         this.loadingSaveAdmin = true;

         try {
            await adminService.updateAdmin(
               adminId,
               this.formatObjectToSend(admin, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O administrador foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedAdmin");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAdmin = false;
         }
      },
   },
};
</script>

<style scoped>
/* .teste {
   position: -webkit-sticky;
   position: sticky;
   bottom: 20px;
   z-index: 999;
} */
</style>