<template>
   <div>
      <v-divider class="mb-5"></v-divider>

      <v-row>
         <v-col class="column-limit" cols="12" md="3">
            <v-select
               @input="changeLimit"
               :value="limit"
               :items="[10, 20, 50, 100]"
               :disabled="loading"
               label="limite"
               class="body-2 mb-2"
               style="max-width: 90px"
               outlined
               dense
            >
               <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content :id="`list-item-${item}`">
                        <v-list-item-title>
                           {{ item }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>

               <template v-slot:label>
                  <p class="body-2">Itens</p>
               </template>

               <template v-slot:append>
                  <v-icon id="item-list-footer-select">mdi-menu-down</v-icon>
               </template>
            </v-select>
         </v-col>

         <v-col class="column-pagination" cols="12" md="9">
            <v-skeleton-loader
               v-if="loading"
               class="rounded-pill mr-4"
               type="image"
               height="15px"
               width="120px"
            ></v-skeleton-loader>
            <p v-else class="body-2 text--secondary mr-2">
               Exibindo {{ rangeItems.min }}-{{ rangeItems.max }} de
               {{ totalItems }}
            </p>

            <v-pagination
               @input="changePage"
               :value="page"
               :total-visible="isSmallerThan(959) ? 0 : 10"
               :length="paginationLength"
               :disabled="loading"
               class="mr-n2"
               color="primaryLight"
               circle
            ></v-pagination>

            <v-text-field
               v-if="!isSmallerThan(959)"
               v-maska="'######'"
               @keyup.enter="
                  (event) => changePageFromInput(Number(event.target.value))
               "
               :value="page"
               :disabled="loading"
               label="Página"
               class="body-2 width-config mb-2 ml-2"
               dense
               outlined
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import responsiveUtil from "../../../utils/responsiveUtil";

export default {
   name: "ListFooter",

   props: {
      page: {
         type: Number,
         default: 1,
      },

      limit: {
         type: Number,
         default: 20,
      },

      totalItems: {
         type: Number,
         default: 0,
      },

      loading: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      paginationLength() {
         return Math.ceil(this.totalItems / this.limit);
      },

      rangeItems() {
         let min = 0;
         let max = 0;
         if (this.totalItems > 0) {
            min =
               (this.page - 1) * this.limit > 0
                  ? (this.page - 1) * this.limit
                  : 1;

            max =
               this.page * this.limit <= this.totalItems
                  ? this.page * this.limit
                  : this.totalItems;
         }

         return {
            min,
            max,
         };
      },
   },

   methods: {
      ...responsiveUtil,

      changePageFromInput(page) {
         let pageTemp = page;

         if (page > this.paginationLength) pageTemp = this.paginationLength;
         else if (page < 1) pageTemp = 1;

         if (this.page !== pageTemp) this.$emit("onChangePage", pageTemp);
      },

      changePage(page) {
         if (this.page !== page) this.$emit("onChangePage", page);
      },

      changeLimit(limit) {
         if (this.limit !== limit) this.$emit("onChangeLimit", limit);
      },
   },
};
</script>

<style scoped>
.column-limit {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

@media (max-width: 959px) {
   .column-limit {
      justify-content: center;
      padding-bottom: 0px;
   }
}

.column-pagination {
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

@media (max-width: 959px) {
   .column-pagination {
      justify-content: center;
   }
}

.width-config {
   max-width: 60px;
}
</style>