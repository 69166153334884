<template>
   <div>
      <ListSkeleton
         v-if="loadingPatientsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (patient) => (!blockClick ? onClickViewPatient(patient) : null)
         "
         :headers="headers"
         :items="patients"
         :loading="loadingPatientsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddPatient"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingPatientsPartially"
               filterActor="paciente"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalPatients"
               :loading="loadingPatientsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData message="Desculpe, nenhum paciente foi encontrado." />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando pacientes, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-patient-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.cpf`]="{ item }">
            <p class="cpf-patient-list-data">
               {{ formatCPF(item.cpf) }}
            </p>
         </template>

         <template v-slot:[`item.phone`]="{ item }">
            <p class="phone-patient-list-data">
               {{ formatPhone(item.phone) }}
            </p>
         </template>

         <template v-slot:[`item.birth_date`]="{ item }">
            <p class="birth_date-patient-list-data">
               {{ formatDate(item.birth_date) }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
               v-if="!hideEditButton(item)"
               @click="editPatient(item)"
               :id="`edit-item-${index}-patient-list-button`"
               class="mx-1"
               color="black"
               small
            >
               mdi-pencil
            </v-icon>

            <v-icon
               v-if="!hideRemoveButton"
               @click="removePatient(item)"
               :id="`remove-item-${index}-patient-list-button`"
               color="error"
               class="mx-1"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import patientService from "../../services/patientService";

export default {
   name: "PatientList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   props: {
      healthEntity: {
         type: Object,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "CPF",
               class: "primary--text body-2 font-weight-medium",
               value: "cpf",
            },
            {
               text: "Telefone",
               class: "primary--text body-2 font-weight-medium",
               value: "phone",
            },
            {
               text: "Nascimento",
               class: "primary--text body-2 font-weight-medium",
               value: "birth_date",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
            {
               filter: "cpf",
               text: "CPF",
               mask: "###.###.###-##",
               unmask: /[^0-9]/g,
               maxLength: 14,
            },
            {
               filter: "email",
               text: "Email",
            },
         ],

         patients: [],

         totalPatients: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingPatientsFully: true,
         loadingPatientsPartially: true,

         blockClick: false,
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "active",
                  query: true,
               },
            ],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         if (this.healthEntity && this.healthEntity.id)
            query.filter.push({
               attribute: "health_entities",
               query: this.healthEntity.id,
            });

         return query;
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllPatients(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllPatients(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllPatients(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllPatients(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllPatients(this.query);
      },

      onClickAddPatient(patient) {
         this.$emit("onClickAddPatient", patient);
      },

      onClickViewPatient(patient) {
         this.$emit("onClickViewPatient", patient);
      },

      editPatient(patient) {
         this.$emit("onClickEditPatient", patient);
      },

      /**
       * hideEditButton
       * Esconde o botão de editar para todos os usuário, EXCETO o admin,
       * caso o paciente já tenha logado na plataforma.
       * @param request objeto solicitação.
       */
      hideEditButton(request) {
         return !!request.last_login && this.userStore.sub_type !== "admin";
      },

      removePatient(patient) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemovePatient", patient);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllPatientsByRef() {
         this.loadingPatientsFully = true;
         await this.getAllPatients(this.query);
         this.loadingPatientsFully = false;
      },

      async getAllPatients(query) {
         this.loadingPatientsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await patientService.getAllPatients(query);

            this.totalPatients = parseInt(response.headers["x-total-count"]);
            this.patients = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPatientsPartially = false;
         }
      },
   },

   async created() {
      this.loadingPatientsFully = true;
      await this.getAllPatients(this.query);
      this.loadingPatientsFully = false;
   },
};
</script>

<style scoped></style>
