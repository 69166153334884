var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.operator.health_units.length)?_c('div',{attrs:{"id":"health_unit_lists"}},_vm._l((_vm.operator.health_units),function(health_unit){return _c('OperatorHealthUnitsDetails',{key:health_unit.id,ref:"health_units",refInFor:true,attrs:{"disabled":_vm.isDisabled(),"health_unit":health_unit},on:{"onDisassociateHealthUnit":_vm.disassociate,"changeCapabilities":_vm.changeCapabilities}})}),1):_c('div',[_c('NoData',{attrs:{"message":"Desculpe, nenhuma unidade de saúde associada a esse operador foi encontrada."}})],1),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"flat":""},model:{value:(_vm.healthUnitExpansionInput),callback:function ($$v) {_vm.healthUnitExpansionInput=$$v},expression:"healthUnitExpansionInput"}},[_c('v-expansion-panel',{staticClass:"panels-border"},[_c('v-expansion-panel-header',[_c('div',{attrs:{"id":"associate_new_healthunit-operator-health-units-select"}},[_vm._v(" Associar nova unidade de saúde ao Operador "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form"},[_c('HealthUnitInput',{ref:"operatorhealthunitinput",on:{"onSelectHealthUnit":_vm.onSelectHealthUnit}}),(_vm.healthUnitSelected)?_c('div',[_c('p',{staticClass:"body-1 font-weight-medium mt-2 "},[_vm._v("Acessos")]),_c('v-chip-group',{staticClass:"mb-3",attrs:{"multiple":"","column":""},model:{value:(_vm.capabilitiesComputed),callback:function ($$v) {_vm.capabilitiesComputed=$$v},expression:"capabilitiesComputed"}},[_c('v-chip',{class:_vm.capabilitiesComputed.includes('can_access_beds')
                              ? 'elevation-4'
                              : 'elevation-0',attrs:{"color":_vm.capabilitiesComputed.includes('can_access_beds')
                              ? 'primary'
                              : 'normal',"value":"can_access_beds","label":"","filter":"","medium":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"ma-0 mx-3"},[_vm._v("Acesso aos leitos")]),_c('v-icon',[_vm._v("mdi-bed")])],1)]),_c('v-chip',{class:_vm.capabilitiesComputed.includes(
                              'can_access_surgeries'
                           )
                              ? 'elevation-4'
                              : 'elevation-0',attrs:{"color":_vm.capabilitiesComputed.includes(
                              'can_access_surgeries'
                           )
                              ? 'primary'
                              : 'normal',"value":"can_access_surgeries","label":"","filter":"","medium":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"ma-0 mx-3"},[_vm._v("Acesso à cirurgias")]),_c('v-icon',[_vm._v("mdi-box-cutter")])],1)])],1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end align-center ma-n1"},[_c('v-btn',{staticClass:"d-none d-sm-flex ma-1",attrs:{"loading":_vm.loadingSaveAssociation,"color":"primary","id":"associate-operator-health-units","depressed":"","small":""},on:{"click":function($event){return _vm.associate(_vm.healthUnitSelected)}}},[_vm._v("Associar "),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"d-flex d-sm-none ma-1",attrs:{"loading":_vm.loadingSaveAssociation,"color":"primary","id":"associate_small-operator-health-units","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.associate(_vm.healthUnitSelected)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }