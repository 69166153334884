var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"700","transition":"slide-y-transition"},model:{value:(_vm.dialogRequestCancelFormVisibility),callback:function ($$v) {_vm.dialogRequestCancelFormVisibility=$$v},expression:"dialogRequestCancelFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeRequestCancelForm}}),_c('RequestCancelForm',{staticClass:"standard-padding",attrs:{"request":_vm.requestSelected},on:{"onCancelRequest":function($event){_vm.closeRequestCancelForm();
               _vm.refreshRequestOperatorList();}}})],1)],1),_c('v-dialog',{attrs:{"content-class":_vm.disableDialogScrolling ? 'dialog-overflow-hidden' : null,"max-width":"1000","transition":"slide-y-transition","persistent":"","no-click-animation":""},model:{value:(_vm.dialogRequestFormVisibility),callback:function ($$v) {_vm.dialogRequestFormVisibility=$$v},expression:"dialogRequestFormVisibility"}},[_c('v-card',{attrs:{"id":"scrollTop","tile":""}},[_c('Close',{on:{"onClickClose":_vm.closeRequestForm}}),_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.userStore.sub_type === 'admin')?_c('RequestAdminForm',{staticClass:"standard-padding fullscreen-width",attrs:{"request":_vm.requestSelected,"hideActions":_vm.requestSelected.is_archived}}):(
                  _vm.userStore.sub_type === 'regulator_manager' ||
                     _vm.userStore.sub_type === 'regulator'
               )?_c('RequestRegulatorForm',{staticClass:"standard-padding fullscreen-width",attrs:{"request":_vm.requestSelected,"user":_vm.fullUserStore,"editMode":_vm.editMode,"hideCheckButton":_vm.requestSelected.is_checked || _vm.requestSelected.is_closed,"hideEditButton":_vm.hideEditButtonRegulator,"hideReserveBedButton":_vm.hideReserveBedButtonRegulator,"hideActions":_vm.requestSelected.is_archived,"hideRemoveButton":""},on:{"onClickCancel":_vm.closeRequestForm,"onUpdatedRequest":function($event){_vm.closeRequestForm();
                  _vm.refreshRequestRegulatorList();},"onOpenHistory":function($event){return _vm.dialogScroll(true)},"onCloseHistory":function($event){return _vm.dialogScroll(false)},"onOpenChat":function($event){_vm.dialogScroll(true);
                  _vm.setRegulatorMessagesAsRead();},"onCloseChat":function($event){return _vm.dialogScroll(false)},"onSendMessage":_vm.onSendMessageRegulatorForm}}):(
                  _vm.userStore.sub_type === 'solicitator' ||
                     _vm.userStore.sub_type === 'secretary_manager'
               )?_c('RequestOperatorForm',{staticClass:"standard-padding fullscreen-width",attrs:{"request":_vm.requestSelected,"healthUnit":_vm.operatorCurrentHealthUnitStore ||
                     _vm.healthSecretaryManagerCurrentHealthUnitStore,"editMode":_vm.editMode,"hideEditButton":_vm.hideEditButtonOperator,"hideActions":_vm.requestSelected.is_archived,"hideRemoveButton":""},on:{"onClickCancel":_vm.closeRequestForm,"onCreatedRequest":function($event){_vm.closeRequestForm();
                  _vm.refreshRequestOperatorList();},"onUpdatedRequest":function($event){_vm.closeRequestForm();
                  _vm.refreshRequestOperatorList();},"onOpenChat":function($event){return _vm.dialogScroll(true)},"onCloseChat":function($event){return _vm.dialogScroll(false)},"onSendMessage":_vm.onSendMessageOperatorForm}}):_vm._e()],1)],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('v-card',{staticClass:"overflow-hidden mt-5"},[(_vm.userStore.sub_type === 'admin')?_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeAdminTab),callback:function ($$v) {_vm.activeAdminTab=$$v},expression:"activeAdminTab"}},[_c('v-tab',{attrs:{"id":"open-requests-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-file-document ")]),_vm._v(" SOLICITAÇÕES ABERTAS ")],1),_c('v-tab',{attrs:{"id":"archived-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-archive-arrow-down ")]),_vm._v(" SOLICITAÇÕES ARQUIVADAS ")],1),_c('v-tab-item',[_c('RequestAdminList',{ref:"openrequestlist",attrs:{"initialQuery":[
                  {
                     attribute: 'is_closed',
                     query: 'false'
                  },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'registered',
                  'in_progress',
                  'answered',
                  'regulated',
                  'rejected_by_executor'
               ],"hideEditButton":"","hideAddButton":"","hideRemoveButton":""},on:{"onClickViewRequest":_vm.onClickViewRequest}})],1),_c('v-tab-item',[_c('RequestAdminList',{ref:"archivedrequestlist",attrs:{"initialQuery":[
                  {
                     attribute: 'is_archived',
                     query: 'true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'answered',
                  'rejected',
                  'canceled'
               ],"hideEditButton":"","hideAddButton":"","hideRemoveButton":""},on:{"onClickViewRequest":_vm.onClickViewRequest}})],1)],1):(
            _vm.userStore.sub_type === 'regulator_manager' ||
               _vm.userStore.sub_type === 'regulator'
         )?_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeRegulatorTab),callback:function ($$v) {_vm.activeRegulatorTab=$$v},expression:"activeRegulatorTab"}},[_c('v-tab',{attrs:{"id":"all_request-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-document")]),_vm._v(" TODAS SOLICITAÇÕES ")],1),_c('v-tab',{attrs:{"id":"my_request-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account ")]),_vm._v(" MINHAS SOLICITAÇÕES ")],1),_c('v-tab',{attrs:{"id":"filed-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-folder-open ")]),_vm._v(" SOLICITAÇÕES FINALIZADAS ")],1),(_vm.userStore.sub_type === 'regulator_manager')?_c('v-tab',{attrs:{"id":"archived-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-archive-arrow-down ")]),_vm._v(" SOLICITAÇÕES ARQUIVADAS ")],1):_vm._e(),_c('v-tab-item',[_c('RequestRegulatorList',{ref:"allrequestlist",attrs:{"hideEditButton":!(_vm.fullUserStore && _vm.fullUserStore.crm),"initialQuery":[
                  { attribute: 'is_closed', query: 'false' },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'registered',
                  'in_progress',
                  'answered',
                  'regulated',
                  'rejected_by_executor'
               ],"hideAddButton":"","hideRemoveButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest}})],1),_c('v-tab-item',[_c('RequestRegulatorList',{ref:"myrequestlist",attrs:{"user":_vm.userStore,"hideEditButton":!(_vm.fullUserStore && _vm.fullUserStore.crm),"initialQuery":[
                  { attribute: 'is_closed', query: 'false' },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'registered',
                  'in_progress',
                  'answered',
                  'regulated',
                  'rejected_by_executor'
               ],"hideAddButton":"","hideRemoveButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest}})],1),_c('v-tab-item',[_c('RequestRegulatorList',{ref:"filedrequestlist",attrs:{"hideEditButton":!(_vm.fullUserStore && _vm.fullUserStore.crm),"initialQuery":[
                  {
                     attribute: 'is_closed',
                     query: 'true'
                  },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'answered',
                  'rejected',
                  'canceled'
               ],"hideAddButton":"","hideRemoveButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest}})],1),_c('v-tab-item',[_c('RequestRegulatorList',{ref:"archivedrequestlist",attrs:{"hideEditButton":!(_vm.fullUserStore && _vm.fullUserStore.crm),"initialQuery":[
                  {
                     attribute: 'is_archived',
                     query: 'true'
                  }
               ],"allowedStatusInStatusFilter":[
                  'answered',
                  'rejected',
                  'canceled'
               ],"hideAddButton":"","hideRemoveButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest}})],1)],1):(
            _vm.userStore.sub_type === 'solicitator' ||
               _vm.userStore.sub_type === 'secretary_manager'
         )?_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeOperatorTab),callback:function ($$v) {_vm.activeOperatorTab=$$v},expression:"activeOperatorTab"}},[_c('v-tab',{attrs:{"id":"request-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-hospital-building ")]),_vm._v(" SOLICITAÇÕES DA UNIDADE ")],1),_c('v-tab',{attrs:{"id":"my_request-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account ")]),_vm._v(" MINHAS SOLICITAÇÕES ")],1),_c('v-tab',{attrs:{"id":"filed-health-unit-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-folder-open ")]),_vm._v(" SOLICITAÇÕES FINALIZADAS ")],1),_c('v-tab-item',[_c('RequestOperatorList',{ref:"allhealthunitrequestlist",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore ||
                     _vm.healthSecretaryManagerCurrentHealthUnitStore,"userInfo":'all-health-unit',"operatorType":_vm.userStore.sub_type,"initialQuery":[
                  {
                     attribute: 'is_closed',
                     query: 'false'
                  },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"hideAddButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest,"onClickRemoveRequest":_vm.onClickRemoveRequest}})],1),_c('v-tab-item',[_c('RequestOperatorList',{ref:"myhealthunitrequestlist",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore ||
                     _vm.healthSecretaryManagerCurrentHealthUnitStore,"user":_vm.userStore,"operatorType":_vm.userStore.sub_type,"initialQuery":[
                  { attribute: 'is_closed', query: 'false' },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"userInfo":'my-health-unit'},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest,"onClickRemoveRequest":_vm.onClickRemoveRequest}})],1),_c('v-tab-item',[_c('RequestOperatorList',{ref:"filedhealthunitrequestlist",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore ||
                     _vm.healthSecretaryManagerCurrentHealthUnitStore,"operatorType":_vm.userStore.sub_type,"initialQuery":[
                  {
                     attribute: 'is_closed',
                     query: 'true'
                  },
                  {
                     attribute: 'is_archived',
                     query: 'ne:true'
                  }
               ],"userInfo":'filed-health-unit',"hideAddButton":""},on:{"onClickAddRequest":_vm.onClickAddRequest,"onClickViewRequest":_vm.onClickViewRequest,"onClickEditRequest":_vm.onClickEditRequest,"onClickRemoveRequest":_vm.onClickRemoveRequest}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }