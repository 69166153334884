<template>
   <div>
      <v-dialog
         v-model="selectHealthUnitVisibilityStore"
         :fullscreen="isSmallerThan(599)"
         max-width="600"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectHealthUnit
               @onSelectHealthUnit="closeDialogSelectHealthUnit"
               :user="userStore"
               class="py-6"
            />
         </v-card>
      </v-dialog>
   </div>
</template>
 
<script>
import SelectHealthUnit from "./SelectHealthUnit";
import responsiveUtil from "../../../utils/responsiveUtil";

export default {
   name: "SelectHealthUnitDialog",

   components: {
      SelectHealthUnit,
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      closeDialogSelectHealthUnit() {
         this.selectHealthUnitVisibilityStore = false;
      },
   },
};
</script>