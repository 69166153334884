<template>
   <div>
      <v-autocomplete
         v-model.lazy="patientSelected"
         :items="patientsItems"
         :search-input.sync="patientSearchFilter"
         :loading="loadingPatients"
         :rules="
            required
               ? [
                    () =>
                       !!(patientSelected && patientSelected.id) ||
                       'Paciente obrigatório.',
                 ]
               : []
         "
         :label="`Paciente${required ? '*' : ''}`"
         :item-text="checkText"
         placeholder="Digite o NOME ou CPF COMPLETO do paciente para buscar..."
         @change="selectPatient"
         @keyup.enter="getAllPatients"
         item-value="id"
         id="patient-input"
         return-object
         hide-no-data
         hide-details
         cache-items
      >
         <template v-slot:[`item`]="{ item }">
            <v-list-item-content>
               <v-list-item-title>{{ item.name }}</v-list-item-title>
               <v-list-item-subtitle>
                  {{ formatCPF(item.cpf) }}
               </v-list-item-subtitle>
               <v-list-item-subtitle>
                  {{ item.address.city }}, {{ item.address.district }},
                  {{ item.address.street }}
               </v-list-item-subtitle>
            </v-list-item-content>
         </template>
      </v-autocomplete>
   </div>
</template>

<script>
import patientService from "services/patientService";
import snackBarUtil from "utils/snackBarUtil";
import validationUtil from "utils/validationUtil";
import formatUtil from "utils/formatUtil";

export default {
   name: "PatientInput",

   props: {
      required: {
         type: Boolean,
         default: true,
      },

      initialQuery: {
         type: Array,
         default: () => [],
      },

      loadPatientsOnCreated: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         patientSelected: {},
         patientsItems: [],
         patientSearchFilter: "",
         loadingPatients: false,
         patientTimeout: null,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 40,
            filter: [],
         };

         if (this.initialQuery && this.initialQuery.length)
            this.initialQuery.forEach((item) =>
               query.filter.push({
                  attribute: item.attribute,
                  query: item.query,
               })
            );

         if (
            this.patientSearchFilter &&
            this.patientSearchFilter.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute:
                  /^\d+$/.test(this.patientSearchFilter) &&
                  this.cpfValidation(this.patientSearchFilter)
                     ? "cpf"
                     : "name",
               query: this.patientSearchFilter,
            });

         return query;
      },
   },

   watch: {
      patientSearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            this.patientsItems.find(
               (item) => item.name === newFilter || item.cpf === newFilter
            )
         )
            return;

         clearTimeout(this.patientTimeout);
         this.patientTimeout = setTimeout(() => {
            this.getAllPatients(this.query);
         }, 800);
      },
   },

   methods: {
      ...validationUtil,
      ...formatUtil,

      checkText(item) {
         if (
            this.patientSearchFilter &&
            this.patientSearchFilter.replace(/ /g, "") !== "" &&
            this.cpfValidation(this.patientSearchFilter)
         )
            return item.cpf;

         return item.name;
      },

      async getAllPatients(query) {
         this.loadingPatients = true;

         try {
            const response = await patientService.getAllPatients(query);

            this.patientsItems = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPatients = false;
         }
      },

      reset() {
         this.patientSelected = {};
      },

      selectPatient(patient) {
         if (patient) this.$emit("onSelectPatient", patient);
      },
   },

   created() {
      if (this.loadPatientsOnCreated) this.getAllPatients(this.query);
   },
};
</script>

<style scoped></style>