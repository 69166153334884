export const REQUEST = {
   id: "ID",
   created_at: "Criado em",
   updated_at: "Atualizado em",
   code: "Código",
   type: "COVID-19",
   municipality_id: "Código do Município",
   sourcehealthentity_id: "Código da Unidade de Saúde",
   status: {
      fieldTranslated: "Status",
      valuesTranslated: {
         registered: "Solicitada",
         in_progress: "Em andamento",
         queued: "Fila de espera",
         regulated: "Regulado",
         answered: "Atendida",
         rejected: "Rejeitada",
         canceled: "Cancelada"
      }
   },
   status_justification: "Justificativa do status",
   is_closed: {
      fieldTranslated: "Solicitação fechada",
      valuesTranslated: {
         true: "Sim",
         false: "Não"
      }
   },
   clinical_data: {
      patient: {
         name: "Nome",
         address: {
            zip_code: "CEP",
            street: "Rua",
            number: "Número",
            district: "Bairro",
            city: "Cidade",
            state: "Estado",
            complement: "Complemento",
            birth_city: "Cidade de nascimento"
         },
         birth_date: "Data de nascimento",
         gender: {
            fieldTranslated: "Sexo",
            valuesTranslated: {
               male: "Masculino",
               female: "Feminino"
            }
         },
         rg: "RG",
         cpf: "CPF",
         cns: "CNS",
         respiratory_rate: "Frequência respiratória",
         oxygen_saturation: "Saturação (%)",
         oxygen_equipment: "Ar/O²",
         heart_rate: "Frequência cardiaca",
         blood_pressure: "Pressão arterial",
         temperature: "Temperatura (Cº)",
         consciousness_level: "Consciência"
      },
      resource: "Tipo de leito",
      contact_phone: "Telefone para contato",
      doctor_name: "Nome do médico",
      nurse_name: "Nome do enfermeiro",
      notes: "Observações",
      flu_syndrome: {
         cough: {
            fieldTranslated: "Tosse",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         runny_nose: {
            fieldTranslated: "Coriza nasal",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         anosmia: {
            fieldTranslated: "Anosmia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         fever: {
            fieldTranslated: "Febre",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         myalgia: {
            fieldTranslated: "Mialgia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         headache: {
            fieldTranslated: "Cefaleia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         fatigue: {
            fieldTranslated: "Fadiga",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         dyspnoea: {
            fieldTranslated: "Dispineia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         asthenia: {
            fieldTranslated: "Astenia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         exposure_to_sars_cov_2: {
            fieldTranslated:
               "Exposição epidemiológica a pessoa infectada pelo SARS CoV-2",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         kinship_degree: {
            fieldTranslated: "Grau Parentesco",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         onset_time: "Tempo início de quadro sintomatico"
      },
      comorbidities: {
         obesity: {
            fieldTranslated: "Obesidade",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         sah: {
            fieldTranslated: "HAS",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         prior_ischemic_heart_disease_ef: "Cardiopatia esquemia previa - FE",
         prior_ischemic_stroke: {
            fieldTranslated: "Anosmia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         pre_existing_lung_disease: {
            fieldTranslated: "Doença pulmonar pré-existente",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         chronic_kidney_injury_stage: "Lesão renal crônica - Estágio",
         immunobiologicals: {
            fieldTranslated: "Uso de imunobiológicos",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         transplant_history: "História de transplante",
         prior_immunosuppressive: {
            fieldTranslated: "Imunossupressor prévio",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         hiv_diagnosis: {
            fieldTranslated: "Diagnóstico HIV",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         other_heart_diseases: "Outras cardiopatias",
         gestational_age: "Gestação – Idade gestacional",
         pregnancy_childbirth: "Gesta/Para",
         obstetric_comorbidities: "Comorbidade obstetricas"
      },
      usual_medications: "Medicamentos de uso habitual",
      respiratory: {
         spo2: "SPO² (%)",
         respiratory_rate: "Frequência respiratória",
         ventilation: "Ventilação",
         spontaneous: {
            fieldTranslated: "Espontânea",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         cn: "CN (lts/min)",
         venturi: "Venturi (%)",
         iot: {
            fieldTranslated: "IOT",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         o2_support: {
            fieldTranslated: "O² Suporte",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         mask_with_reservoir: {
            fieldTranslated: "Máscara com reservatório",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         vmi: "VMI",
         pcv: "PCV",
         psv: "PSV",
         vcv: "VCV",
         vt: "VT (ml/Kg)",
         delta_pressure: "Delta pressure",
         plateau_pressure: "cmH20 Platô",
         raw: "cmH20 Raw",
         cest: "Cest",
         sao2: "Sa0²",
         pao2: "Pa0²"
      },
      cardiovascular: {
         blood_pressure: "PA (mmHg)",
         axillary_temperature: "Temperatura auxiliar (Cº)",
         dva: {
            fieldTranslated: "Uso de DVA",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         noradrenaline: "Noradrenalina - Dose (mcg/kg/min)",
         adrenaline: "Adrenalina - Dose",
         vasopressin: "Vasopressina - Dose (Ul/min)",
         dobutamine: "Dobutamina - Dose (mcg/Kg/min)",
         arrhythmia: {
            fieldTranslated: "Arritmia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         fa: "FA",
         flutter: "Flutter",
         tsv: "TSV",
         tv: "TV",
         concomitant_bacterial_infection: {
            fieldTranslated: "Infecção bacteriana concomitante",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         infection_focus: "Foco",
         abdominal_infection: "Abdominal",
         skin_soft_tissue_infection: "Pele/Tecidos moles",
         blood_flow_infection: "Corrente sanguínea",
         antibiotic_therapy: {
            fieldTranslated: "Uso de antibioticoterapia",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         }
      },
      sarscov2: {
         rapid_test: {
            fieldTranslated: "Teste rápido",
            valuesTranslated: {
               positive: "Positivo",
               negative: "Negativo",
               unrealized: "Não realizou"
            }
         },
         igm: {
            fieldTranslated: "IGM",
            valuesTranslated: {
               positive: "Positivo",
               negative: "Negativo",
               unrealized: "Não realizou"
            }
         },
         igg: {
            fieldTranslated: "IGG",
            valuesTranslated: {
               positive: "Positivo",
               negative: "Negativo",
               unrealized: "Não realizou"
            }
         },
         symptoms: "Sintomas",
         rt_pcr: {
            result: {
               fieldTranslated: "RT PCR SARS CoV-2",
               valuesTranslated: {
                  detectable: "Detectável",
                  undetectable: "Não detectável",
                  waiting_result: "Aguarda resultado"
               }
            },
            date: "Data da coleta"
         },
         corticosteroid: {
            in_use: {
               fieldTranslated: "Em uso de corticostróide",
               valuesTranslated: {
                  true: "Sim",
                  false: "Não"
               }
            },
            dose: "Dose",
            start_date: "Data de início"
         },
         hydroxychloroquine: {
            in_use: {
               fieldTranslated: "Em uso de hidroxicloroquina",
               valuesTranslated: {
                  true: "Sim",
                  false: "Não"
               }
            },
            dose: "Dose",
            start_date: "Data de início"
         },
         oseltamivir: {
            in_use: {
               fieldTranslated: "Em uso de oseltamivir",
               valuesTranslated: {
                  true: "Sim",
                  false: "Não"
               }
            },
            dose: "Dose",
            start_date: "Data de início"
         },
         heparin: {
            in_use: {
               fieldTranslated: "Em uso de heparina",
               valuesTranslated: {
                  true: "Sim",
                  false: "Não"
               }
            },
            dose: "Dose",
            start_date: "Data de início"
         }
      },
      pps: "PPS",
      neurological: {
         gcs: "Escala de Coma de Glasgow (pontos)",
         continuous_sedation: {
            fieldTranslated: "Sedação Continua",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         midazolam_dose: "Midazolam dose",
         fentanyl_dose: "Fentanil dose",
         propofol_dose: "Propofol dose",
         neuromuscular_blocker: "B. Neuromuscular dose",
         rocuronium: "Rocurônio dose",
         pancuronium: "Pancurônio dose",
         cisatracurium: "Cisatracúrio dose",
         rass: "RASS"
      },
      laboratory: {
         date: "Data",
         leukocytes: "Leucócitos",
         lymphocytes: "Linfócitos",
         hb: "HB",
         ht: "HT",
         pcr: "PCR",
         lactate: "Lactato",
         dhl: "DHL",
         ferritin: "Ferretina",
         troponin: "Troponina",
         cpk: "CPK",
         d_dimer: "D-dímero",
         urea: "Ureia",
         creatinine: "Creatinina",
         ap: "AP",
         tap: "TAP",
         inr: "INR",
         tgo: "TGO",
         tgp: "TGP"
      },
      image_exams: {
         chest_tomography: "TC torax laudo",
         chest_x_ray: "ARX torax laudo"
      },
      cerh_recommendations: {
         flu_syndrome: {
            fieldTranslated: "Sindrome Gripal",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         inconclusive_criteria: "Criterios Inconclusivos"
      },
      news_fast: {
         fieldTranslated: "NEWS FAST (SES-PB)",
         valuesTranslated: {
            one: "1 ponto",
            two_yellow: "2 pontos",
            two_orange: "2 pontos",
            greater_equal_to_three: "≥3 pontos"
         }
      },
      news_modified: {
         fieldTranslated: "NEWS modificado",
         valuesTranslated: {
            zero: "0 pontos",
            one_to_four: "1-4 pontos",
            five_to_six: "5-6 pontos",
            greater_equal_to_seven_red: "≥7 pontos",
            greater_equal_to_seven_black: "≥7 pontos"
         }
      },
      uti: {
         accute_breathing_insufficiency: {
            fieldTranslated:
               "Insuficiência respiratória aguda com necessidade de ventilação mecânica",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         sepsis_septic_shock: {
            fieldTranslated:
               "Sepse/Choque séptico e infecção pulmonar pelo COVID-19",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         hemodynamic_instability: {
            fieldTranslated:
               "Instabilidade hemodinâmica ou hipertensão arterial ou sinais de má perfusão orgânica",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         },
         organic_dysfunction: {
            fieldTranslated: "Disfunção orgânica, além do pulmonar",
            valuesTranslated: {
               true: "Sim",
               false: "Não"
            }
         }
      }
   },
   doctor_opinion: {
      patient_not_fit_for_regulation: {
         fieldTranslated: "Paciente não preenche criterios para regulação",
         valuesTranslated: {
            true: "Sim",
            false: "Não"
         }
      },
      bed_type: {
         fieldTranslated: "Tipo de leito",
         valuesTranslated: {
            clinical: "Leito clínico",
            surgical: "Leito Cirúrgico",
            uti: "Leito de UTI"
         }
      },
      priority: {
         fieldTranslated: "Prioridade",
         valuesTranslated: {
            blue: "Azul",
            green: "Verde",
            yellow: "Amarela",
            orange: "Laranja",
            red: "Vermelha"
         }
      },
      justification: "Justificativa"
   },
   targethealthunit_id: "Código da Unidade de Saúde de destino",
   bed_id: "Código do Leito",
   outcome: {
      fieldTranslated: "Desfecho",
      valuesTranslated: {
         discharge_cure: "Alta Curado",
         discharge_improvement: "Alta Melhorado",
         discharge_on_request: "Alta a pedido",
         discharge_with_return: "Alta com previsão de retorno",
         discharge_evasion: "Alta por Evasão",
         discharge_other_reasons: "Alta por outros motivos",
         permanence_characteristics_disease:
            "Por características próprias da doença",
         permanence_complication: "Por intercorrência",
         permanence_sociofamily_impossibility:
            "Por impossibilidade sócio-familiar",
         permanence_other_reasons: "Outros motivos",
         transfer: "Transfêrencia",
         death_certificate_by_doctor:
            "Declaração de óbito fornecida pelo médico",
         death_certificate_by_iml: "Declaração de óbito fornecida pelo IML",
         death_certificate_by_svo: "Declaração de óbito fornecida pelo SVO"
      }
   },
   outcome_date: "Data do desfecho",
   outcome_justification: "Justificativa do desfecho"
};

export const SNACKBAR = {
   409: {
      "HealthUnit is already registered...": "Unidade de saúde já cadastrada.",
      "Ward is already registered...": "Ala já cadastrada.",
      "Bed is already registered...": "Leito já cadastrado.",
      "Request is already registered...": "Solicitação já cadastrada.",
      "A user with this cpf or email is already registered!":
         "Usuário com o CPF ou Email informado já está cadastrado.",
      "A user with this cpf, email or crm is already registered!":
         "Usuário com o CPF, Email ou CRM informado já está cadastrado.",
      "The healthunit_id is already associated with another healthunit_manager!":
         "A unidade de saúde informada já possui um gestor responsável."
   },

   400: {
      "There are active Beds associated with the Ward.":
         "Ala não pode ser exluída pois tem leitos associados."
   }
};
