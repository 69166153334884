<template>
   <div>
      <v-dialog
         v-model="dialogAnonymousRequestVisibility"
         max-width="600"
         transition="slide-y-transition"
      >
         <Close
            @onClickClose="closeAnonymousRequest"
            color="blackLight"
            dense
         />

         <v-alert
            class="ma-0 pa-6 d-flex align-center"
            prominent
            type="error"
            color="warning"
         >
            <div class="d-flex flex-column justify-center align-end mt-4">
               <p class="text-justify">
                  Você não selecionou um <strong>paciente</strong>, logo os
                  dados do paciente serão definidos como
                  <strong>"Anônimo".</strong>
               </p>

               <v-btn @click="saveRequest" :loading="loadingSaveRequest" small
                  >Continuar
                  <v-icon right small>mdi-check-circle</v-icon>
               </v-btn>
            </div>
         </v-alert>
      </v-dialog>

      <v-fade-transition>
         <div v-if="dialogChatVisibility" class="chat-overlay">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="chat-drawer"
               elevation="24"
               tile
               flat
            >
               <Close @onClickClose="closeChat" dense />

               <Chat
                  @onSendMessage="onSendMessage"
                  :request="request"
                  :disable="disableChat"
                  class="standard-padding flex-grow-1 mt-3"
               />
            </v-card>
         </div>
      </v-fade-transition>

      <v-overlay
         v-model="overlayAttachmentUploadVisibility"
         opacity=".95"
         style="z-index: 1000"
      >
         <AttachmentUploadProgress
            :key="attachmentUploadProgressKey"
            :attachments="attachmentsAdded"
            class="px-5"
         />
      </v-overlay>

      <FormTitle
         v-if="!hideTitle"
         icon="mdi-file-document"
         title="SOLICITAÇÃO DE LEITO"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="request.status === 'rejected' && request.is_closed"
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <RequestSummary :request="formRequest" />

      <v-expansion-panels v-model="patientData" flat>
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div class="d-flex align-center">
                  <p>Dados do paciente</p>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-card :disabled="getMode() === 'read'" flat>
                  <PatientInput
                     v-if="!request.id"
                     @onSelectPatient="onSelectPatient"
                     :required="false"
                     class="mb-7"
                     ref="patientinput"
                  />

                  <div v-if="requestPatient && requestPatient.id">
                     <v-chip-group class="my-n2" active-class="selected-chip">
                        <v-chip value="true" v-model="formRequest.pb_heart">
                           <div>
                              <v-icon small class="ma-1">
                                 $coracao-paraibano
                              </v-icon>
                              Coração paraíbano
                           </div>
                        </v-chip>
                     </v-chip-group>

                     <ViewPatient :patient="requestPatient" hideTitle />

                     <v-btn
                        @click="onRemovePatient"
                        class="mt-4"
                        color="error"
                        small
                        depressed
                     >
                        Remover paciente
                        <v-icon right small> mdi-delete </v-icon>
                     </v-btn>
                  </div>

                  <div v-else class="d-flex align-center">
                     <v-chip class="ma-0 mr-2" color="black" dark label>
                        <p class="body-2">Anônimo</p>

                        <v-icon right small> mdi-incognito </v-icon>
                     </v-chip>

                     <p class="body-2">
                        Essa solicitação não foi associada a um paciente.
                     </p>
                  </div>
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-expansion-panels v-model="requestData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div class="d-flex align-center">
                     <p>Sinais vitais e tipo de leito</p>
                  </div>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-card :disabled="getMode() === 'read'" flat>
                     <PatientData :patientData="formRequest.clinical_data" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>

         <v-expansion-panels v-model="regulationData" multiple flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Síndrome gripal
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <FluSyndromeForm
                        :fluSyndrome="formRequest.clinical_data.flu_syndrome"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Comorbidades
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <ComorbiditiesForm
                        :comorbidities="formRequest.clinical_data.comorbidities"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Oximetria periférica – Sistema Respiratório e Ventilação
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <RespiratoryForm
                        :respiratory="formRequest.clinical_data.respiratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Verificação de PA/FC/FR – Cardiovascular e Infecção
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <CardiovascularForm
                        :cardiovascular="
                           formRequest.clinical_data.cardiovascular
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> SARS CoV-2 </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <Sarscov2Form
                        :sarscov2="formRequest.clinical_data.sarscov2"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Desorientação, padrão neurologico, nível de consciência
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <NeurologicalForm
                        :neurological="formRequest.clinical_data.neurological"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Laboratório
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <LaboratoryForm
                        :laboratory="formRequest.clinical_data.laboratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> Imagem </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <ImageExamForm
                        :imageExam="formRequest.clinical_data.image_exams"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Recomendações do CERH
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <CerhRecommendationsForm
                        :cerhRecommendations="
                           formRequest.clinical_data.cerh_recommendations
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> NEWS FAST </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <NewsFastForm
                        :regulationData="formRequest.clinical_data"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> UTI </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <UtiForm :uti="formRequest.clinical_data.uti" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="showDoctorOpinion" class="panels-border">
               <v-expansion-panel-header>
                  Parecer médico
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <DoctorOpinionForm
                        :doctorOpinion="formRequest.doctor_opinion"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-card flat>
         <v-divider class="mt-9"></v-divider>

         <p class="body-1 font-weight-medium mt-6 mb-3">
            Anexo(s)
            <v-icon>mdi-paperclip</v-icon>
         </p>

         <RequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            :editMode="getMode() === 'save'"
            :hideAddButton="hideEditButton && hideRemoveButton && !!request.id"
            :hideRemoveButton="
               hideEditButton && hideRemoveButton && !!request.id
            "
            ref="requestattachments"
         />

         <div class="mt-4">
            <p v-if="sizeAttachmentValidation" class="caption error--text">
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Os documentos juntos não podem <strong>exceder</strong> 5 MB.
            </p>
         </div>
      </v-card>

      <RequestOperatorFormActions
         v-if="!hideActions"
         @onClickPrint="onClickPrintRequest"
         @onClickChat="onClickChatRequest"
         @onClickEdit="onClickEditRequest"
         @onClickRemove="onClickRemoveRequest"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRequest"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :unreadMessages="unreadMessages"
         :loadingSave="loadingSaveRequest"
         :loadingPrint="loadingPrintRequest"
         :validationErrors="validationErrors"
         :hideChatButton="request.is_closed || hideChatButton"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import Close from "../../../components/base/Close";
import RequestOperatorFormActions from "components/request/form/RequestOperatorFormActions";
import Chat from "components/requestBase/chat/Chat";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import AttachmentUploadProgress from "components/attachment/AttachmentUploadProgress";
import RequestAttachments from "components/requestBase/subForm/RequestAttachments";
import RequestSummary from "../../../components/request/form/subForm/RequestSummary";
import PatientData from "../../../components/request/form/subForm/PatientData";
import FluSyndromeForm from "../../../components/request/form/subForm/FluSyndromeForm";
import ComorbiditiesForm from "../../../components/request/form/subForm/ComorbiditiesForm";
import RespiratoryForm from "../../../components/request/form/subForm/RespiratoryForm";
import CardiovascularForm from "../../../components/request/form/subForm/CardiovascularForm";
import Sarscov2Form from "../../../components/request/form/subForm/Sarscov2Form";
import NeurologicalForm from "../../../components/request/form/subForm/NeurologicalForm";
import LaboratoryForm from "../../../components/request/form/subForm/LaboratoryForm";
import ImageExamForm from "../../../components/request/form/subForm/ImageExamForm";
import CerhRecommendationsForm from "../../../components/request/form/subForm/CerhRecommendationsForm";
import NewsFastForm from "../../../components/request/form/subForm/NewsFastForm";
import UtiForm from "../../../components/request/form/subForm/UtiForm";
import DoctorOpinionForm from "../../../components/request/form/subForm/DoctorOpinionForm";
import PatientInput from "components/patient/PatientInput";
import ViewPatient from "components/patient/ViewPatient";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import requestService from "../../../services/requestService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";
import attachmentService from "../../../services/attachmentService";
import responsiveUtil from "utils/responsiveUtil";
import requestPdfExport from "export/pdf/requestPdfExport";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";
import { ALLOWED_TYPES, MAXIMUM_SIZE } from "utils/defaultUtil";
import basePdfExport from "export/pdf/basePdfExport";
import socketIoService from "services/socketIoService";

export default {
   name: "RequestOperatorForm",

   components: {
      FormTitle,
      Close,
      RequestOperatorFormActions,
      Chat,
      OutcomeDetails,
      JustificationRejection,
      OriginAndDestinyDetails,
      AttachmentUploadProgress,
      RequestAttachments,
      RequestSummary,
      PatientData,
      FluSyndromeForm,
      ComorbiditiesForm,
      RespiratoryForm,
      CardiovascularForm,
      Sarscov2Form,
      NeurologicalForm,
      LaboratoryForm,
      ImageExamForm,
      CerhRecommendationsForm,
      NewsFastForm,
      UtiForm,
      DoctorOpinionForm,
      PatientInput,
      ViewPatient
   },

   props: {
      request: {
         type: Object
      },

      healthUnit: {
         type: Object
      },

      hideActions: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      hideChatButton: {
         type: Boolean,
         default: false
      },

      hideTitle: {
         type: Boolean,
         default: false
      },

      showDoctorOpinion: {
         type: Boolean,
         default: false
      },

      editMode: {
         type: Boolean,
         default: false
      },

      fullscreenParentDialog: {
         type: Boolean,
         default: false
      }
   },

   data: function() {
      return {
         socketIoConnection: null,

         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",

         formRequest: {},

         loadingSaveRequest: false,
         loadingPrintRequest: false,

         requestData: 0,
         patientData: 0,
         regulationData: [],

         sizeAttachmentValidation: false,
         overlayAttachmentUploadVisibility: false,
         dialogAnonymousRequestVisibility: false,
         attachmentUploadProgressKey: 0,
         attachmentsAdded: [],

         dialogChatVisibility: false,

         validationErrors: [],

         requestPatient: {}
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      unreadMessages() {
         if (this.request.unreadMessages) return this.request.unreadMessages;
         else return 0;
      },

      disableChat() {
         return this.request.is_closed;
      }
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.patientData = 0;
            this.requestData = 0;
            this.regulationData = [];
            this.validationErrors = [];
            this.pb_heart = false;

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();

            if (request.id && request.patient && request.patient.id)
               this.requestPatient = Object.assign({}, request.patient);
         }
      }
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,
      ...responsiveUtil,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject(
            {
               clinical_data: {
                  patient_data: {},
                  flu_syndrome: {},
                  comorbidities: {},
                  respiratory: {},
                  cardiovascular: {},
                  sarscov2: {
                     rt_pcr: {},
                     corticosteroid: {},
                     hydroxychloroquine: {},
                     oseltamivir: {},
                     heparin: {}
                  },
                  neurological: {},
                  laboratory: {},
                  image_exams: {},
                  cerh_recommendations: {},
                  uti: {}
               }
            },
            request
         );

         if (requestTemp.clinical_data.sarscov2.rt_pcr.date)
            requestTemp.clinical_data.sarscov2.rt_pcr.date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.rt_pcr.date
            );

         if (requestTemp.clinical_data.sarscov2.corticosteroid.start_date)
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.corticosteroid.start_date
            );

         if (requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date)
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date
            );

         if (requestTemp.clinical_data.sarscov2.oseltamivir.start_date)
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.oseltamivir.start_date
            );

         if (requestTemp.clinical_data.sarscov2.heparin.start_date)
            requestTemp.clinical_data.sarscov2.heparin.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.heparin.start_date
            );

         if (requestTemp.clinical_data.laboratory.date)
            requestTemp.clinical_data.laboratory.date = formatUtil.formatDate(
               requestTemp.clinical_data.laboratory.date
            );

         return requestTemp;
      },

      formatObjectToSend(request, removeEmptyStrings) {
         let requestTemp = util.removeBlankAttributes(request, {
            emptyStrings: removeEmptyStrings
         });

         /* O back não tá aceitando se o objeto for sem o atributo 'patient_data', mesmo que não tenha nenhum dado tem que enviar... */
         if (
            requestTemp.clinical_data &&
            !requestTemp.clinical_data.patient_data
         )
            requestTemp.clinical_data.patient_data = {};

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.sarscov2 &&
            requestTemp.clinical_data.sarscov2.rt_pcr &&
            requestTemp.clinical_data.sarscov2.rt_pcr.date
         )
            requestTemp.clinical_data.sarscov2.rt_pcr.date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.rt_pcr.date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.sarscov2 &&
            requestTemp.clinical_data.sarscov2.corticosteroid &&
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date
         )
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.corticosteroid.start_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.sarscov2 &&
            requestTemp.clinical_data.sarscov2.hydroxychloroquine &&
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date
         )
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.sarscov2 &&
            requestTemp.clinical_data.sarscov2.oseltamivir &&
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date
         )
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.oseltamivir.start_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.sarscov2 &&
            requestTemp.clinical_data.sarscov2.heparin &&
            requestTemp.clinical_data.sarscov2.heparin.start_date
         )
            requestTemp.clinical_data.sarscov2.heparin.start_date = formatUtil.formatDate(
               requestTemp.clinical_data.sarscov2.heparin.start_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (
            requestTemp.clinical_data &&
            requestTemp.clinical_data.laboratory &&
            requestTemp.clinical_data.laboratory.date
         )
            requestTemp.clinical_data.laboratory.date = formatUtil.formatDate(
               requestTemp.clinical_data.laboratory.date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return requestTemp;
      },

      onClickChatRequest() {
         this.openChat();
      },

      onClickEditRequest() {
         this.setMode("save");
      },

      onClickRemoveRequest() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formRequest = this.formatObjectToView(this.request);
         this.$emit("onClickCancel");
      },

      onClickSaveRequest() {
         /* 1. Se não tem `patient_id` (adicionado no cadastro) e `patient` (adicionado na edição), 
         mostra o aviso de paciente anômimo. */
         if (!this.formRequest.patient_id && !this.formRequest.patient)
            this.openAnonymousRequest();
         else this.saveRequest();
      },

      async onClickPrintRequest() {
         this.loadingPrintRequest = true;

         try {
            const userResponse = await this.getUserById(this.userStore);

            const pdf = requestPdfExport.generateRequestPDF(
               this.formRequest,
               userResponse.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrintRequest = false;
         }
      },

      async getUserById(user) {
         let response;

         switch (user.sub_type) {
            case "admin":
               response = await adminService.getAdminById(user.sub);
               break;
            case "manager":
               response = await managerService.getManagerById(user.sub);
               break;
            case "healthunit_manager":
               response = await healthUnitManagerService.getHealthUnitManagerById(
                  user.sub
               );
               break;
            case "secretary_manager":
               response = await healthSecretaryManagerService.getHealthSecretaryManagerById(
                  user.sub
               );
               break;
            case "solicitator":
            case "executor":
               response = await operatorService.getOperatorById(user.sub);
               break;
            case "regulator_manager":
               response = await regulatorManagerService.getRegulatorManagerById(
                  user.sub
               );
               break;
            case "regulator":
               response = await regulatorService.getRegulatorById(user.sub);
               break;
         }

         return response;
      },

      saveRequest() {
         this.closeAnonymousRequest();

         if (
            this.healthUnit &&
            this.healthUnit.id &&
            this.healthUnit.municipality_id
         ) {
            this.formRequest.sourcehealthentity_id = this.healthUnit.id;
            this.formRequest.municipality_id = this.healthUnit.municipality_id;

            if (this.formRequest.id)
               this.updateHealthUnitRequest(
                  this.healthUnit.id,
                  this.formRequest.id,
                  this.formRequest
               );
            else
               this.createHealthUnitRequest(
                  this.healthUnit.id,
                  this.formRequest
               );
         }
      },

      onSelectPatient(patient) {
         if (patient && patient.id) {
            this.formRequest.patient_id = patient.id;
            this.requestPatient = patient;
         }
      },

      onRemovePatient() {
         delete this.formRequest.patient_id;
         this.requestPatient = {};
         this.resetPatientValidation();
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidationAttachments() {
         this.sizeAttachmentValidation = false;

         let totalSize;

         /* Verificação se todos os arquivos são menores que 5 MB. */
         if (this.$refs.requestattachments)
            totalSize = this.$refs.requestattachments
               .getAttachments()
               .reduce(
                  (previousValue, currentValue) =>
                     previousValue + currentValue.content.size,
                  0
               );

         const validationResult = totalSize > MAXIMUM_SIZE;

         setTimeout(() => {
            this.sizeAttachmentValidation = totalSize > MAXIMUM_SIZE;
         }, 300);

         return !validationResult;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter(item => item.hasError && item.label)
               .map(item => item.label.replace(/\*/g, ""));

            const validationResult = this.$refs.form.validate();
            if (validationResult) this.validationErrors = [];

            return validationResult;
         } else return false;
      },

      resetPatientValidation() {
         this.requestPatient = {};
         if (this.$refs.patientinput) this.$refs.patientinput.reset();
      },

      resetAttachmentValidation() {
         this.sizeAttachmentValidation = false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
         this.resetPatientValidation();
         this.resetAttachmentValidation();
      },

      forceAttachmentUploadProgressRerender() {
         this.attachmentUploadProgressKey += 1;
      },

      openChat() {
         this.dialogChatVisibility = true;
         this.$emit("onOpenChat");
      },

      closeChat() {
         this.dialogChatVisibility = false;
         this.$emit("onCloseChat");
      },

      onSendMessage(request, message) {
         this.$emit("onSendMessage", request, message);
      },

      openAnonymousRequest() {
         this.dialogAnonymousRequestVisibility = true;
      },

      closeAnonymousRequest() {
         this.dialogAnonymousRequestVisibility = false;
      },

      async createHealthUnitRequest(healthUnitId, request) {
         if (
            !this.executeValidation() ||
            !this.executeValidationAttachments() ||
            !healthUnitId ||
            !request
         )
            return;

         this.loadingSaveRequest = true;

         try {
            /* 1. Salva a solicitação */
            const response = await requestService.createHealthUnitRequest(
               healthUnitId,
               this.formatObjectToSend(request)
            );

            /* 2. Salva os anexos */
            if (response.data.id) {
               this.socketIoConnection.emit("new_request", response.data);

               if (this.$refs.requestattachments && response.data) {
                  this.attachmentsAdded = this.$refs.requestattachments
                     .getAttachments()
                     .filter(item => item.raw);

                  if (this.attachmentsAdded.length) {
                     this.overlayAttachmentUploadVisibility = true;

                     for (
                        let index = 0;
                        index < this.attachmentsAdded.length;
                        index++
                     ) {
                        const item = this.attachmentsAdded[index];

                        let attachment = new FormData();
                        attachment.append(
                           "attachment",
                           item.content.originalData
                        );

                        await attachmentService
                           .createRequestAttachment(
                              response.data.id,
                              attachment,
                              event => {
                                 let progress = Math.round(
                                    (event.loaded * 97) / event.total
                                 );

                                 this.attachmentsAdded[index].upload = {
                                    progress: progress
                                 };
                                 this.forceAttachmentUploadProgressRerender();
                              }
                           )
                           .then(
                              () =>
                                 (this.attachmentsAdded[index].upload = {
                                    success: true
                                 })
                           )
                           .catch(
                              () =>
                                 (this.attachmentsAdded[index].upload = {
                                    error: true
                                 })
                           )
                           .finally(() =>
                              this.forceAttachmentUploadProgressRerender()
                           );
                     }
                  }
               }
            }

            if (this.overlayAttachmentUploadVisibility) await util.sleep(1000);

            this.overlayAttachmentUploadVisibility = false;

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi cadastrada!"
            });

            this.setMode("read");
            this.$emit("onCreatedRequest");
         } catch (error) {
            if (
               error.response &&
               error.response.status === 409 &&
               error.response.data.message ===
                  "AdultBedRequest is already registered..."
            )
               snackBarUtil.showCustomSnackBar({
                  color: "info",
                  icon: "mdi-file-document-multiple-outline",
                  title: "SOLICITAÇÃO JÁ CADASTRADA!",
                  message:
                     "Uma solicitação para esse paciente já foi cadastrada!"
               });
            else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      },

      async updateHealthUnitRequest(healthUnitId, requestId, request) {
         if (
            !this.executeValidation() ||
            !this.executeValidationAttachments() ||
            !healthUnitId ||
            !requestId ||
            !request
         )
            return;

         this.loadingSaveRequest = true;

         try {
            /* 1. Salva a solicitação */
            const response = await requestService.updateHealthUnitRequest(
               healthUnitId,
               requestId,
               this.formatObjectToSend(request, false)
            );

            /* 2. Salva os anexos */
            if (
               this.$refs.requestattachments &&
               response.data &&
               response.data.id
            ) {
               this.attachmentsAdded = this.$refs.requestattachments
                  .getAttachments()
                  .filter(item => item.raw);

               if (this.attachmentsAdded.length) {
                  this.overlayAttachmentUploadVisibility = true;

                  for (
                     let index = 0;
                     index < this.attachmentsAdded.length;
                     index++
                  ) {
                     const item = this.attachmentsAdded[index];

                     let attachment = new FormData();
                     attachment.append("attachment", item.content.originalData);

                     await attachmentService
                        .createRequestAttachment(
                           response.data.id,
                           attachment,
                           event => {
                              let progress = Math.round(
                                 (event.loaded * 97) / event.total
                              );

                              this.attachmentsAdded[index].upload = {
                                 progress: progress
                              };
                              this.forceAttachmentUploadProgressRerender();
                           }
                        )
                        .then(
                           () =>
                              (this.attachmentsAdded[index].upload = {
                                 success: true
                              })
                        )
                        .catch(
                           () =>
                              (this.attachmentsAdded[index].upload = {
                                 error: true
                              })
                        )
                        .finally(() =>
                           this.forceAttachmentUploadProgressRerender()
                        );
                  }
               }
            }

            if (this.overlayAttachmentUploadVisibility) await util.sleep(1000);

            this.overlayAttachmentUploadVisibility = false;

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi atualizada!"
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            if (
               error.response &&
               error.response.status === 409 &&
               error.response.data.message ===
                  "AdultBedRequest is already registered..."
            )
               snackBarUtil.showCustomSnackBar({
                  color: "info",
                  icon: "mdi-file-document-multiple-outline",
                  title: "SOLICITAÇÃO JÁ CADASTRADA!",
                  message:
                     "Uma solicitação para esse paciente já foi cadastrada!"
               });
            else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      }
   },
   async created() {
      switch (this.userStore.sub_type) {
         case "solicitator":
            this.socketIoConnection = socketIoService.getConnection();
            break;
      }
   }
};
</script>

<style scoped>
.chat-overlay {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}

.selected-chip {
   color: red;
}
</style>
