<template>
   <v-row>
      <v-col class="d-flex align-center flex-wrap" cols="12" md="6">
         <v-icon left>mdi-map-marker</v-icon>
         <p class="mr-4">{{ ward.name }}</p>

         <div v-if="showAddButton">
            <v-btn
               @click="addBed"
               class="d-none d-sm-flex"
               color="primary"
               :id="`add_bed_${ward.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(' ', '_')}-bed-list-ward-button`"
               depressed
               small
               >Adicionar leito
               <v-icon right small>mdi-plus</v-icon>
            </v-btn>

            <v-btn
               @click="addBed"
               class="d-flex d-sm-none"
               color="primary"
               :id="`add_bed_${ward.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(' ', '_')}_small-bed-list-ward-button`"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-plus</v-icon>
            </v-btn>
         </div>
      </v-col>

      <v-col
         class="d-flex align-center justify-list-beds-ward-statistic-chip"
         cols="12"
         md="6"
      >
         <BedListWardHeaderStatistic
            :statistic="statistics.freeBedCount"
            class="mr-2"
            color="success"
            label="Livres"
         />
         <BedListWardHeaderStatistic
            :statistic="statistics.occupiedBedCount"
            class="mr-2"
            color="warning"
            label="Ocupados"
         />
         <BedListWardHeaderStatistic
            :statistic="statistics.blockedBedCount"
            class="mr-2"
            color="error"
            label="Bloqueados"
         />
         <BedListWardHeaderStatistic
            :statistic="statistics.reservedBedCount"
            class="mr-2"
            color="purple"
            label="Reservados"
         />
         <BedListWardHeaderStatistic
            :statistic="statistics.totalOccupancyPercentage"
            color="info"
            label="Ocupação"
            percentage
         />
      </v-col>
   </v-row>
</template>

<script>
import BedListWardHeaderStatistic from "./BedListWardHeaderStatistic";

export default {
   name: "BedListWardHeader",

   components: { BedListWardHeaderStatistic },

   props: {
      ward: {
         type: Object,
      },

      statistics: {
         type: Object,
         default: () => ({
            freeBedCount: 0,
            blockedBedCount: 0,
            occupiedBedCount: 0,
            waitingReservationCount: 0,
            reservedBedCount: 0,
            totalOccupancyPercentage: 0,
         }),
      },

      showAddButton: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      addBed() {
         this.$emit("onClickAddBed", { ward_id: this.ward.id });
      },
   },
};
</script>

<style scoped>
.justify-list-beds-ward-statistic-chip {
   justify-content: flex-end;
}

@media (max-width: 959px) {
   .justify-list-beds-ward-statistic-chip {
      justify-content: flex-start;
   }
}
</style>