<template>
   <div>
      <v-dialog
         v-model="dialogAvaliableSurgeryFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeAvaliableSurgeryForm" />

            <AvaliableSurgeryForm
               @onClickCancel="closeAvaliableSurgeryForm"
               @onCreatedAvaliableSurgery="
                  closeAvaliableSurgeryForm();
                  refreshAvaliableSurgeryList();
               "
               @onUpdatedAvaliableSurgery="
                  closeAvaliableSurgeryForm();
                  refreshAvaliableSurgeryList();
               "
               @onClickRemoveAvaliableSurgery="onClickRemoveAvaliableSurgery"
               @onFindDisabledAvaliableSurgery="onFindDisabledAvaliableSurgery"
               :avaliableSurgery="avaliableSurgerySelected"
               :editMode="editMode"
               class="standard-padding"
               hideEditButton
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <AvaliableSurgeryList
         @onClickAddAvaliableSurgery="onClickAddAvaliableSurgery"
         @onClickViewAvaliableSurgery="onClickViewAvaliableSurgery"
         @onClickEditAvaliableSurgery="onClickEditAvaliableSurgery"
         @onClickRemoveAvaliableSurgery="onClickRemoveAvaliableSurgery"
         class="mt-12"
         ref="avaliableSurgerylist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import AvaliableSurgeryList from "../../components/avaliableSurgery/AvaliableSurgeryList";
import AvaliableSurgeryForm from "../../components/avaliableSurgery/AvaliableSurgeryForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import avaliableSurgeryService from "../../services/avaliableSurgeryService";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "AvaliableSurgery",

   components: { Close, PageTitle, AvaliableSurgeryList, AvaliableSurgeryForm },

   data: function() {
      return {
         avaliableSurgerySelected: {},

         editMode: false,

         dialogAvaliableSurgeryFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogAvaliableSurgeryFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddAvaliableSurgery(avaliableSurgery) {
         this.avaliableSurgerySelected = Object.assign({}, avaliableSurgery);
         this.openAvaliableSurgeryForm();
      },

      onClickViewAvaliableSurgery(avaliableSurgery) {
         this.avaliableSurgerySelected = Object.assign({}, avaliableSurgery);
         this.openAvaliableSurgeryForm();
      },

      onClickEditAvaliableSurgery() {
         this.editMode = true;
      },

      onClickRemoveAvaliableSurgery(item) {
         const methodDeleteItem = async () => {
            await avaliableSurgeryService.deleteAvaliableSurgery(item.id);

            this.dialogAvaliableSurgeryFormVisibility = false;
            this.refreshAvaliableSurgeryList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi excluída!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir essa cirurgia?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledAvaliableSurgery(item) {
         const methodActiveItem = async () => {
            await avaliableSurgeryService.activeAvaliableSurgery(item.id, true);

            this.refreshAvaliableSurgeryList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi ativada!"
            });
         };

         this.dialogAvaliableSurgeryFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "CIRURGIA JÁ EXISTENTE",
            message:
               "Um cirurgia desativado com esse CPF foi encontrado, deseja ativá-lo?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      openAvaliableSurgeryForm() {
         this.dialogAvaliableSurgeryFormVisibility = true;
      },

      closeAvaliableSurgeryForm() {
         this.dialogAvaliableSurgeryFormVisibility = false;
      },

      refreshAvaliableSurgeryList() {
         this.$refs.avaliableSurgerylist.refreshAllAvaliableSurgerysByRef();
      }
   }
};
</script>

<style scoped></style>
