export default {
   namespaced: true,

   state: {
      type: "adult"
   },

   getters: {
      getRequestType(state) {
         return state.type;
      }
   },

   mutations: {
      setRequestType(state, type) {
         state.type = type;
      }
   }
};
