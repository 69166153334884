import basePdfExport from "./basePdfExport";
import moment from "moment";
import formatUtil from "utils/formatUtil";

/**
 * Método para gerar o PDF dos agendamentos de cirurgia de um determinado dia.
 * @param {*} date: Objeto date contendo o dia dos agendamentos.
 * @param {*} surgeries: Array com as cirurgias.
 * @param {*} responsible: Objeto com o usuário responsável pela impressão.
 * @param {*} healthEntity: Objeto com a unidade de saúde responsável por realizar as cirurgias.
 * @returns Arquivo PDF (jsPDF).
 */
function generateScheduledSurgeriesPDF(
   date,
   surgeries,
   responsible,
   healthEntity
) {
   const headerPdf = basePdfExport.generatePDFHeader(
      {
         title: `Mapa de cirurgia ${formatUtil.formatDate(date)} - ${
            healthEntity.name
         }`,
         autor: responsible
      },
      "landscape"
   );

   const pdf = basePdfExport.generatePDFTableFromJSON(
      surgeries,
      [
         { value: "patient.name", text: "Paciente" },
         {
            value: "patient.birth_date",
            text: "Idade",
            computed: data => moment().diff(data, "years")
         },
         { value: "patient.municipality", text: "Origem" },
         { value: "name", text: "Cirurgia" }
      ],
      headerPdf
   );

   return pdf;
}

export default {
   generateScheduledSurgeriesPDF
};
