import { render, staticRenderFns } from "./RequestSummary.vue?vue&type=template&id=bd894db8&scoped=true&"
import script from "./RequestSummary.vue?vue&type=script&lang=js&"
export * from "./RequestSummary.vue?vue&type=script&lang=js&"
import style0 from "./RequestSummary.vue?vue&type=style&index=0&id=bd894db8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd894db8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VChipGroup,VIcon,VTooltip})
