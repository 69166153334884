var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"body-1 font-weight-medium"},[_vm._v("Confirmação de cancelamento")]),_c('p',{staticClass:"caption mt-5 mb-1"},[_vm._v("Tipo de cancelamento*")]),_c('v-chip-group',{staticClass:"my-n2",attrs:{"column":""},on:{"change":function (value) { return (_vm.invalidOutcome = !value); }},model:{value:(_vm.formRequestCancel.outcome),callback:function ($$v) {_vm.$set(_vm.formRequestCancel, "outcome", $$v)},expression:"formRequestCancel.outcome"}},[_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_cure'
               ? 'primary'
               : 'normal',"value":"discharge_cure","filter":"","small":""}},[_vm._v(" Alta Curado ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_improvement'
               ? 'primary'
               : 'normal',"value":"discharge_improvement","filter":"","small":""}},[_vm._v(" Alta Melhorado ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_on_request'
               ? 'primary'
               : 'normal',"value":"discharge_on_request","filter":"","small":""}},[_vm._v(" Alta a pedido ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_with_return'
               ? 'primary'
               : 'normal',"value":"discharge_with_return","filter":"","small":""}},[_vm._v(" Alta com previsão de retorno ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_evasion'
               ? 'primary'
               : 'normal',"value":"discharge_evasion","filter":"","small":""}},[_vm._v(" Alta por Evasão ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'discharge_other_reasons'
               ? 'primary'
               : 'normal',"value":"discharge_other_reasons","filter":"","small":""}},[_vm._v(" Alta por outros motivos ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'transfer' ? 'primary' : 'normal',"value":"transfer","filter":"","small":""}},[_vm._v(" Transfêrencia ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'death_certificate_by_doctor'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_doctor","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo médico ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'death_certificate_by_iml'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_iml","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo IML ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'death_certificate_by_svo'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_svo","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo SVO ")]),_c('v-chip',{attrs:{"color":_vm.formRequestCancel.outcome === 'others' ? 'primary' : 'normal',"value":"others","filter":"","small":""}},[_vm._v(" Outros ")])],1),_c('v-expand-transition',[(_vm.invalidOutcome)?_c('p',{staticClass:"caption error--text mt-1"},[_vm._v(" Tipo de cancelamento obrigatória. ")]):_vm._e()]),_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"outcome-height",attrs:{"rules":_vm.formRequestCancel.outcome === 'others'
                     ? [function (value) { return !!value || 'Justificativa obrigatória.'; }]
                     : [],"label":"Justificativa","rows":"4","no-resize":""},model:{value:(_vm.formRequestCancel.outcome_justification),callback:function ($$v) {_vm.$set(_vm.formRequestCancel, "outcome_justification", $$v)},expression:"formRequestCancel.outcome_justification"}})],1)],1)],1),_c('FormActions',{staticClass:"mt-6",attrs:{"loadingSave":_vm.loadingSaveCancel,"mode":'save'},on:{"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.onClickConfirmCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }