<template>
   <div>
      <v-row class="mt-0">
         <v-col cols="12">
            <v-text-field
               v-model="imageExam.chest_tomography"
               label="TC torax laudo"
               id="chest_tomography-image-exam-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12">
            <v-text-field
               v-model="imageExam.chest_x_ray"
               label="RX torax laudo"
               id="chest_x_ray-image-exam-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "ImageExamForm",

   props: {
      imageExam: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>