<template>
   <apexchart
      :options="options"
      :series="series"
      height="340"
      class="ml-n4 mb-n9"
      type="bar"
   ></apexchart>
</template>

<script>
export default {
   name: "AgeGroupChart",

   props: {
      statistics: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         options: {
            colors: ["#6F79E2FF"],

            chart: {
               id: "age-group-chart",
               toolbar: {
                  show: false,
               },
            },

            xaxis: {
               categories: [
                  "0-9",
                  "10-19",
                  "20-29",
                  "30-39",
                  "40-49",
                  "50-59",
                  "60-69",
                  "70-79",
                  "80-89",
                  "90-99",
                  "100-109",
                  "110-119",
               ],
               labels: {
                  style: {
                     cssClass: "caption",
                  },
                  rotate: -45,
                  rotateAlways: true,
               },
            },

            yaxis: {
               labels: {
                  style: {
                     cssClass: "caption",
                  },
               },
               tickAmount: 4,
               max: function (max) {
                  return Math.round(max * 1.2) || 1;
               },
            },

            plotOptions: {
               bar: {
                  dataLabels: {
                     position: "top",
                  },
                  borderRadius: 5,
                  columnWidth: "35px",
               },
            },

            dataLabels: {
               offsetY: -20,
               formatter: function (val, attrs) {
                  const total = parseInt(
                     attrs.w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                     }, 0)
                  );

                  if (total > 0) return ((val / total) * 100).toFixed(0) + "%";
                  else return 0;
               },
               style: {
                  fontSize: "10px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  colors: ["#6F79E2"],
               },
            },

            tooltip: {
               theme: "dark",
               x: {
                  show: false,
               },
               y: {
                  title: {
                     formatter: function (val, attrs) {
                        return `${val} entre ${
                           attrs.w.config.xaxis.categories[attrs.dataPointIndex]
                        }: `;
                     },
                  },
               },
               marker: {
                  show: false,
               },
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },
         },
      };
   },

   computed: {
      series() {
         let result = [
            {
               name: "Paciente(s)",
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
         ];

         if (this.statistics.length > 0)
            result[0].data = this.statistics.map((item) => item.total);

         return result;
      },
   },
};
</script>

<style>
</style>