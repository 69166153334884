import Vue from "vue";
import Vuex from "vuex";

import appBar from "./base/appBar";
import navigationDrawer from "./base/navigationDrawer";
import snackBar from "./base/snackBar";
import user from "./base/user";

import selectHealthUnit from "./selectHealthUnit/selectHealthUnit";
import request from "./request/request";
import operator from "./operator/operator";
import healthUnitManager from "./healthUnitManager/healthUnitManager";
import healthSecretaryManager from "./healthSecretaryManager/healthSecretaryManager";

import notificationCenter from "./notificationCenter/notificationCenter";

import term from "./term/term";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      appBar,
      navigationDrawer,
      snackBar,
      user,

      operator,
      healthUnitManager,
      healthSecretaryManager,

      term,
      selectHealthUnit,
      request,

      notificationCenter
   }
});
