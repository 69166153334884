<template>
   <div>
      <v-card
         class="
            standard-padding-whopping
            card-border
            d-flex
            flex-column
            justify-center
            align-stretch
         "
         elevation="3"
      >
         <div class="d-flex justify-center align-center">
            <v-icon size="150">
               $reg-logo
            </v-icon>
         </div>

         <v-form ref="form">
            <v-text-field
               v-model="formChangePassword.email"
               :rules="[
                  (value) => !!value || 'Email obrigatório.',
                  (value) => emailValidation(value) || 'Email inválido.',
               ]"
               class="mt-9 body-1 label-position"
               id="password-change-password-input"
               height="44"
               ><template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-email</v-icon>
               </template>

               <template v-slot:label>
                  <p class="body-1">Email*</p>
               </template>
            </v-text-field>

            <v-text-field
               v-model="formChangePassword.new_password"
               @click:append="showNewPassword = !showNewPassword"
               :rules="[
                  (value) => !!value || 'Nova senha obrigatória.',
                  (value) =>
                     passwordValidation(value) ||
                     'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
               ]"
               :type="showNewPassword ? 'text' : 'password'"
               :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
               class="mt-12 body-1 label-position"
               id="new-password-change-password-input"
               height="44"
               ><template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-lock</v-icon>
               </template>

               <template v-slot:label>
                  <p class="body-1">Nova senha*</p>
               </template>
            </v-text-field>

            <v-text-field
               v-model="formChangePassword.confirm_new_password"
               @click:append="
                  showConfirmationNewPassword = !showConfirmationNewPassword
               "
               :rules="[
                  (value) =>
                     !!value || 'Confirmação da nova senha obrigatória.',
                  (value) =>
                     value === formChangePassword.new_password ||
                     'Confirmação da nova senha e a nova senha são diferentes.',
               ]"
               :type="showConfirmationNewPassword ? 'text' : 'password'"
               :append-icon="
                  showConfirmationNewPassword ? 'mdi-eye' : 'mdi-eye-off'
               "
               class="mt-12 body-1 label-position"
               id="confirm-new-password-change-password-input"
               height="44"
               ><template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-lock</v-icon>
               </template>

               <template v-slot:label>
                  <p class="body-1">Confirmação da nova senha*</p>
               </template>
            </v-text-field>
         </v-form>

         <v-btn
            @click="onClickSaveChangePassword"
            :loading="loadingSaveChangePassword"
            class="mt-16 py-6"
            color="primary"
            id="change-password-button"
            large
            block
            >ALTERAR SENHA</v-btn
         >
      </v-card>
   </div>
</template>

<script>
import authService from "../../services/authService";
import snackBarUtil from "../../utils/snackBarUtil";
import validationUtil from "../../utils/validationUtil";
export default {
   name: "ChangePasswordCard",

   data: function () {
      return {
         formChangePassword: {},

         showNewPassword: false,
         showConfirmationNewPassword: false,

         loadingSaveChangePassword: false,
      };
   },

   methods: {
      ...validationUtil,

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickSaveChangePassword() {
         this.authChangePassword(
            this.formChangePassword,
            `bearer ${this.$route.query.token}`
         );
      },

      async authChangePassword(changePassword, token) {
         if (!this.executeValidation()) return;

         this.loadingSaveChangePassword = true;

         try {
            await authService.authChangePassword(changePassword, token);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Sua senha foi atualizada!",
            });

            this.$router.push({ path: "/autenticacao" });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveChangePassword = false;
         }
      },
   },
};
</script>

<style scoped>
.card-border {
   border-radius: 8px !important;
}

::v-deep .label-position > div > div > div > label {
   top: 4px !important;
}

::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}
</style>