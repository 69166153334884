<template>
    <div>
      <FormTitle icon="mdi-folder-lock-open" title="ALTERAR ACESSO" />
 
      <v-alert
         class="body-2 font-weight-bold mb-5"
         color="primary"
         icon="mdi-account"
         border="left"
         dense
         text
         >
         Selecione os tipos de acesso que o operador pode ter na unidade de saúde
      </v-alert>

      <v-card flat outlined>
         <div class="ml-2">
            <v-chip-group
               class="ml-2" 
               v-model="capabilitiesComputed" 
               multiple 
               column
            >
               <v-chip 
                  :color="capabilitiesComputed.includes('can_access_beds') ? 'primary' : 'normal'" 
                  :class="capabilitiesComputed.includes('can_access_beds') ? 'elevation-4' : 'elevation-0'"
                  value="can_access_beds" 
                  label 
                  filter 
                  large
               >
                  <div class="d-flex justify-space-between align-center">
                     <p class="ma-0 mx-1">Acesso aos leitos</p>
                     <v-icon>mdi-bed</v-icon>
                  </div>
               </v-chip>
               <v-chip 
                  :color="capabilitiesComputed.includes('can_access_surgeries') ? 'primary' : 'normal'"
                  :class="capabilitiesComputed.includes('can_access_surgeries') ? 'elevation-4': 'elevation-0'" 
                  value="can_access_surgeries" 
                  label 
                  filter 
                  large
               >
                  <div class="d-flex justify-space-between align-center">
                     <p class="ma-0 mx-1">Acesso à cirurgias</p>
                     <v-icon>mdi-box-cutter</v-icon>
                  </div>
               </v-chip>
            </v-chip-group>
         </div>
      </v-card>
 
      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickChangeCapabilities"
         :loadingSave="loadingChangeCapabilities"
         class="mt-8"
         mode="save"
      />
    </div>
 </template>
 
 <script>
import FormTitle from "components/base/form/FormTitle";
import FormActions from "components/base/form/FormActions";
import validationUtil from "utils/validationUtil";
import snackBarUtil from "utils/snackBarUtil";
import operatorService from "services/operatorService";

export default {
   name: "ChangeCapabilitiesForm",

   components: { FormTitle, FormActions },

   props: {
      health_unit: {
         type: Object,
      },

      operator: {
         type: Object
      }
   },

   data: function () {
      return {
         formPassword: {},

         access_capabilities: {
            can_access_beds: this.health_unit.capabilities.includes("beds"),
            can_access_surgeries: this.health_unit.capabilities.includes("surgeries")
         },

         loadingChangeCapabilities: false,
      };
   },

   computed: {
      capabilitiesComputed: {
         get() {
            let result = [];
            if (this.access_capabilities) {
               if (this.access_capabilities.can_access_beds)
                  result.push("can_access_beds");
               if (this.access_capabilities.can_access_surgeries)
                  result.push("can_access_surgeries");
            }

            return result;
         },

         set(value) {
            this.access_capabilities.can_access_beds =
               value.includes("can_access_beds");
            this.access_capabilities.can_access_surgeries =
               value.includes("can_access_surgeries");
         },
      },
   },

   methods: {
      ...validationUtil,

      formatCapabilities(operatorAccess) {
         const capabilities = [];

         if (operatorAccess.can_access_surgeries)
            capabilities.unshift("surgeries");
         if (operatorAccess.can_access_beds)
            capabilities.unshift("beds");

         return capabilities;
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickChangeCapabilities() {
         if (this.operator && this.health_unit && this.capabilitiesComputed)
            this.changeCapabilities(this.operator.id, this.health_unit.id, this.formatCapabilities(this.access_capabilities));
      },

      async changeCapabilities(operator, health_unit, capabilities) {
         if (!operator || !health_unit || !capabilities) return;

         this.loadingChangeCapabilities = true;

         try {
            const response = await operatorService.associateOperatorToHealthUnitById(
               operator,
               health_unit,
               capabilities
            );

            if (response) {
               snackBarUtil.showCustomSnackBar({
                  color: "success",
                  title: "SUCESSO!",
                  message: "O acesso do operador foi alterado com sucesso!",
               });

               this.$emit("onResetCapabilities");
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingChangeCapabilities = false;
         }
      },
   },
};
</script>
 
<style></style>