<template>
   <div>
      <v-row>
         <v-col cols="12" md="6">
            <v-card
               class="standard-padding-x-small d-flex justify-space-between align-center"
               height="100%"
               flat
            >
               <div class="d-flex flex-column justify-center flex-grow-1">
                  <div class="d-flex align-center">
                     <v-icon left>mdi-map-marker</v-icon>

                     <v-skeleton-loader
                        v-if="loadingHealthUnit"
                        class="rounded-pill"
                        type="image"
                        width="40%"
                        height="18px"
                     ></v-skeleton-loader>
                     <p v-else>{{ healthUnit.address.city }}</p>
                  </div>

                  <div class="d-flex align-center mt-2">
                     <v-icon left>mdi-hospital-building</v-icon>

                     <v-skeleton-loader
                        v-if="loadingHealthUnit"
                        class="rounded-pill"
                        type="image"
                        width="85%"
                        height="18px"
                     ></v-skeleton-loader>
                     <p v-else>
                        {{ healthUnit.name }}
                     </p>
                  </div>
               </div>

               <v-btn
                  @click="changeHealthUnit"
                  class="d-none d-md-flex py-7"
                  color="normal"
                  small
                  depressed
                  >Alterar <br />
                  unidade de <br />saúde
               </v-btn>

               <v-btn
                  @click="changeHealthUnit"
                  class="d-flex d-md-none"
                  color="normal"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </v-card>
         </v-col>
         <v-col cols="12" md="6">
            <HealthUnitStatisticCard
               :statistic="statistics.totalOccupancyPercentage"
               color="info"
               icon="mdi-hospital-building"
               label="Ocupação total"
               percentage
            />
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" sm="6" lg="3">
            <HealthUnitStatisticCard
               :statistic="statistics.freeBedCount"
               color="success"
               icon="mdi-bed-empty"
               label="Leitos livres"
            />
         </v-col>
         <v-col cols="12" sm="6" lg="3">
            <HealthUnitStatisticCard
               :statistic="statistics.occupiedBedCount"
               color="warning"
               icon="mdi-bed"
               label="Leitos ocupados"
            />
         </v-col>
         <v-col cols="12" sm="6" lg="3">
            <HealthUnitStatisticCard
               :statistic="statistics.blockedBedCount"
               color="error"
               icon="mdi-close"
               label="Leitos bloqueados"
            />
         </v-col>
         <v-col cols="12" sm="6" lg="3">
            <HealthUnitStatisticCard
               :statistic="statistics.reservedBedCount"
               color="purple"
               icon="mdi-exclamation"
               label="Leitos reservados"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import HealthUnitStatisticCard from "./HealthUnitStatisticCard";

export default {
   name: "HealthUnitStatistic",

   components: { HealthUnitStatisticCard },

   props: {
      healthUnit: {
         type: Object,
      },

      statistics: {
         type: Object,
         default: () => ({
            freeBedCount: 0,
            blockedBedCount: 0,
            occupiedBedCount: 0,
            waitingReservationCount: 0,
            reservedBedCount: 0,
            totalOccupancyPercentage: 0,
         }),
      },
   },

   data: function () {
      return {
         loadingHealthUnit: true,
         loadingStatistics: true,
      };
   },

   watch: {
      healthUnit: {
         immediate: true,
         handler(healthUnit) {
            if (healthUnit && healthUnit.id) this.loadingHealthUnit = false;
         },
      },
   },

   methods: {
      changeHealthUnit() {
         this.$emit("onClickChangeHealthUnit");
      },
   },
};
</script>

<style scoped>
</style>