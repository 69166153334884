var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"1000","transition":"slide-y-transition"},model:{value:(_vm.dialogWardFormVisibility),callback:function ($$v) {_vm.dialogWardFormVisibility=$$v},expression:"dialogWardFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeWardForm}}),_c('WardForm',{staticClass:"standard-padding",attrs:{"ward":_vm.wardSelected,"editMode":_vm.editMode,"healthUnit":_vm.operatorCurrentHealthUnitStore ||
                  _vm.healthUnitManagerCurrentHealthUnitStore},on:{"onClickCancel":_vm.closeWardForm,"onCreatedWard":function($event){_vm.closeWardForm();
               _vm.refreshWardList();},"onUpdatedWard":function($event){_vm.closeWardForm();
               _vm.refreshWardList();},"onClickRemoveWard":_vm.onClickRemoveWard,"onFindDisabledWard":_vm.onFindDisabledWard}})],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('WardList',{ref:"wardlist",staticClass:"mt-12",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore ||
            _vm.healthUnitManagerCurrentHealthUnitStore,"hideAddButton":_vm.healthUnitManagerCurrentHealthUnitStore &&
            _vm.healthUnitManagerCurrentHealthUnitStore.id &&
            !_vm.healthUnitManagerCurrentHealthUnitStore.capabilities
               .can_have_beds},on:{"onClickAddWard":_vm.onClickAddWard,"onClickViewWard":_vm.onClickViewWard,"onClickEditWard":_vm.onClickEditWard,"onClickRemoveWard":_vm.onClickRemoveWard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }