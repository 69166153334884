import cleanDeep from "clean-deep";
import _ from "lodash";

/* 
Função para gerar as estatísticas a partir de uma lista de leitos.

Entradas:
    - Array com objetos do tipo leito;
Saídas:
    - Quantidade de leitos livres;
    - Quantidade de leitos bloqueados;
    - Quantidade de leitos ocupados;
    - Quantidade de leitos reservados;
    - Quantidade de leitos aguardando reserva;
    - Porcentagem de ocupação total;
 */

function generateBedStatistics(beds) {
   if (Array.isArray(beds)) {
      let freeBedCount = 0;
      let blockedBedCount = 0;
      let occupiedBedCount = 0;
      let waitingReservationCount = 0;
      let reservedBedCount = 0;
      let totalOccupancyPercentage = 0;

      beds.forEach(item => {
         switch (item.status) {
            case "free":
               freeBedCount++;
               break;
            case "blocked":
               blockedBedCount++;
               break;
            case "occupied":
               occupiedBedCount++;
               break;
            // case "waiting_reservation":
            //    waitingReservationCount++;
            //    break;
            case "reserved":
               reservedBedCount++;
               break;
         }
      });

      const totalBeds =
         freeBedCount +
         blockedBedCount +
         occupiedBedCount +
         waitingReservationCount +
         reservedBedCount;

      const totalUnavailable = totalBeds - freeBedCount;

      totalOccupancyPercentage =
         parseInt(((totalUnavailable / totalBeds) * 100).toFixed(0)) || 0;

      return {
         freeBedCount,
         blockedBedCount,
         occupiedBedCount,
         waitingReservationCount,
         reservedBedCount,
         totalOccupancyPercentage
      };
   } else return null;
}

/* 
Função para ordenar os leitos por status.

Entradas:
    - Array com objetos do tipo leito;
Saídas:
    - Array de leitos ordenados no formato: livre, aguardando reserva, ocupado, bloqueado, reservado;
 */

function orderBeds(beds) {
   if (Array.isArray(beds)) {
      const status = {
         free: 1,
         waiting_reservation: 2,
         occupied: 3,
         blocked: 4,
         reserved: 5,
         undefined: 6
      };

      return beds.sort((a, b) => status[a.status] - status[b.status]);
   } else return beds;
}

/* Função para gerar números inteiros aleatórios entre um determinado intervalo. */

function generateRandomInt(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min)) + min;
}

/* Função para retornar a cor e o texto traduzido de acordo com o estado da solicitação. */
function generateState(status) {
   let result;
   switch (status) {
      case "registered":
         result = {
            color: "info",
            text: "Solicitada"
         };
         break;

      case "in_progress":
         result = {
            color: "warning",
            text: "Em andamento"
         };
         break;

      case "queued":
      case "regulated":
         result = {
            color: "primary",
            text: "Regulado"
         };
         break;

      case "answered":
         result = {
            color: "success",
            text: "Atendida"
         };
         break;

      case "rejected":
         result = {
            color: "error",
            text: "Rejeitada"
         };
         break;

      case "canceled":
         result = {
            color: "error",
            text: "Cancelada"
         };
         break;

      case "rejected_by_executor":
         result = {
            color: "error",
            text: "Rejeitada pelo executante"
         };
         break;

      default:
         break;
   }

   return result;
}

function translateSubType(subType) {
   let translatedSubType;

   switch (subType) {
      case "admin":
         translatedSubType = "Administrador";
         break;
      case "manager":
         translatedSubType = "Gestor";
         break;
      case "healthunit_manager":
         translatedSubType = "Gestor de unidade de saúde";
         break;
      case "secretary_manager":
         translatedSubType = "Gestor de secretaria de saúde";
         break;
      case "solicitator":
         translatedSubType = "Solicitante";
         break;
      case "executor":
         translatedSubType = "Executante";
         break;
      case "regulator_manager":
         translatedSubType = "Gestor regulador";
         break;
      case "operator":
         translatedSubType = "Operador";
         break;
      case "regulator":
         translatedSubType = "Regulador";
         break;
      case "patient":
         translatedSubType = "Paciente";
         break;
   }

   return translatedSubType;
}

/* Função para retornar a cor e o texto traduzido de acordo com o estado da cirurgia. */
function generateSurgeryStatus(status) {
   let translated = { color: "warning", text: "Pendente" };

   switch (status) {
      case "scheduled":
         translated.color = "success";
         translated.text = "Agendada";
         break;
      case "canceled":
         translated.color = "error";
         translated.text = "Cancelada";
         break;
      case "done":
         translated.color = "info";
         translated.text = "Feita";
         break;
   }

   return translated;
}

function removeBlankAttributes(object, options) {
   return cleanDeep(object, options);
}

function cloneObject(object) {
   return _.cloneDeep(object);
}

function arrayDiff(array1, array2) {
   return _.difference(array1, array2);
}

function objectDifference(object, base) {
   function changes(object, base) {
      return _.transform(object, function(result, value, key) {
         if (!_.isEqual(value, base[key])) {
            result[key] =
               _.isObject(value) && _.isObject(base[key])
                  ? changes(value, base[key])
                  : value;
         }
      });
   }
   return changes(object, base);
}

function mergeObject(objectTarget, objectSource) {
   return _.merge(objectTarget, objectSource);
}

function countLeavesAttributes(obj) {
   return Object.values(obj).reduce(
      (count, v) =>
         (count += typeof v === "object" ? countLeavesAttributes(v) : 1),
      0
   );
}

function transformSubAttributesInAttributes(object) {
   let result = {};

   function goToSubAttributes(attribute) {
      Object.keys(attribute).forEach(key => {
         if (typeof attribute[key] === "object")
            return goToSubAttributes(attribute[key]);
         result[key] = attribute[key];
      });
   }

   goToSubAttributes(object);

   return result;
}

function copyValueClipboard(id) {
   let testingCodeToCopy = document.querySelector(`#${id}`);
   testingCodeToCopy.setAttribute("type", "text");
   testingCodeToCopy.select();
   document.execCommand("copy");
   testingCodeToCopy.setAttribute("type", "hidden");
   window.getSelection().removeAllRanges();
}

function getFileContents(file) {
   return new Promise((resolve, reject) => {
      let contents = "";
      const reader = new FileReader();
      reader.onloadend = function(e) {
         contents = e.target.result;
         resolve(contents);
      };
      reader.onerror = function(e) {
         reject(e);
      };
      reader.readAsDataURL(file);
   });
}

function sleep(ms) {
   return new Promise(resolve => setTimeout(resolve, ms));
}

function sortRequestsByStatus(requests) {
   const status = {
      answered: 1,
      canceled: 2,
      in_progress: 3,
      regulated: 4,
      rejected: 5,
      rejected_by_executor: 6,
      registered: 7
   };

   return requests.sort((a, b) => status[a.status] - status[b.status]);
}

export default {
   generateBedStatistics,
   orderBeds,
   generateRandomInt,
   generateState,
   generateSurgeryStatus,
   removeBlankAttributes,
   cloneObject,
   arrayDiff,
   objectDifference,
   mergeObject,
   countLeavesAttributes,
   transformSubAttributesInAttributes,
   copyValueClipboard,
   getFileContents,
   sleep,
   sortRequestsByStatus,
   translateSubType
};
