var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingRequestsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit,"hideAddButton":_vm.hideAddButton}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.requests,"loading":_vm.loadingRequestsPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (request) { return (!_vm.blockClick ? _vm.onClickViewRequest(request) : null); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('RequestListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"date":_vm.date,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingRequestsPartially,"hideAddButton":_vm.hideAddButton,"userInfo":_vm.userInfo,"filterActor":"solicitação","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeDate":_vm.onChangeDate,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddRequest}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalRequests,"loading":_vm.loadingRequestsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhuma solicitação foi encontrada."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando solicitações, aguarde por favor."}})],1)]},proxy:true},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"code-request-regulator-list-data"},[_vm._v(" "+_vm._s(item.code)+" ")]),(item.pb_heart)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","size":"17","right":""}},'v-icon',attrs,false),on),[_vm._v(" $coracao-paraibano ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Participa do "),_c('br'),_vm._v(" coração paraíbano ")])]):_vm._e()],1)]}},{key:"item.patient.name",fn:function(ref){
var item = ref.item;
return [(item.patient && item.patient.name)?_c('p',{class:("name-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" "+_vm._s(item.patient.name)+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('p',{class:("name-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" Anônimo ")]),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-incognito ")])],1)]}},{key:"item.patient.birth_date",fn:function(ref){
var item = ref.item;
return [(item.patient && item.patient.birth_date)?_c('p',{class:("birth-date-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" "+_vm._s(_vm.formatDate(item.patient.birth_date))+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('p',{class:("birth-date-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" Anônimo ")]),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-incognito ")])],1)]}},{key:"item.clinical_data.doctor_name",fn:function(ref){
var item = ref.item;
return [_c('p',{class:("doctor_nurse-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" "+_vm._s(item.clinical_data.doctor_name || item.clinical_data.nurse_name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',{class:("created_at-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"font-weight-medium",class:((_vm.generateState(item.status).color) + "--text status-" + _vm.userInfo + "-request-operator-list-data")},[_vm._v(" "+_vm._s(_vm.generateState(item.status).text)+" ")]),(
                  (item.status === 'in_progress' ||
                     item.status === 'regulated') &&
                  item.doctor_opinion &&
                  item.doctor_opinion.priority
               )?_c('v-chip',{staticClass:"ml-1 px-1",class:("priority-" + _vm.userInfo + "-request-operator-list-data"),attrs:{"color":_vm.translatePriority(item.doctor_opinion.priority).color,"x-small":""}},[_vm._v(_vm._s(_vm.translatePriority(item.doctor_opinion.priority) .translatedPriority))]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
               var item = ref.item;
return [_c('div',{key:_vm.messageNotificationKey,staticClass:"d-flex justify-center align-center flex-wrap mx-n1"},[(item.totalMessages)?_c('v-card',{staticClass:"mx-1",attrs:{"color":"transparent","height":"20px","width":"20px","flat":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-message-notification",attrs:{"color":"orange","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-chat ")])]}}],null,true)},[(!item.unreadMessages)?_c('p',{staticClass:"text-center"},[_vm._v(" Chat iniciado ")]):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.unreadMessages)+" "+_vm._s(item.unreadMessages > 1 ? "mensagens" : "mensagem")+" ")])]),_c('div',{staticClass:"icon-content-notification"},[(!item.unreadMessages)?_c('v-icon',{attrs:{"size":"11","dark":""}},[_vm._v(" mdi-check ")]):_c('p',{staticClass:"count-message-label"},[_vm._v(" "+_vm._s(item.unreadMessages)+" ")])],1)],1):_vm._e(),(!_vm.hideEditButtonOperator(item.status))?_c('v-icon',{staticClass:"mx-1",class:("edit-" + _vm.userInfo + "-request-operator-list-data"),attrs:{"color":"black","small":""},on:{"click":function($event){return _vm.editRequest(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.hideRemoveButton && _vm.cancelRequest(item))?_c('v-icon',{staticClass:"mx-1",class:("delete-" + _vm.userInfo + "-request-operator-list-data"),attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.removeRequest(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }