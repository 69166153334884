import formatUtil from "utils/formatUtil";
import util from "utils/util";
import basePdfExport, {
   SECTION_ROW_CONFIG,
   TABLE_STYLE_DEFAULT
} from "./basePdfExport";

/**
 * Método para gerar o PDF das mensagens trocadas no chat de uma solicitação.
 * @param {*} messages: Array com as mensagens do chat.
 * @param {*} chatType: Tipo do usuário do chat.
 * @param {*} request: Objeto requisição.
 * @param {*} responsible: Objeto com o usuário responsável pela impressão.
 * @returns Arquivo PDF (jsPDF).
 */
function generateMessagesRequestPDF(messages, chatType, request, responsible) {
   let chatTypeTranslated =
      chatType === "requester" ? "Solicitante" : "Executante";

   const pdf = basePdfExport.generatePDFHeader({
      title: `Impressão do Chat (${chatTypeTranslated}) da Solicitação`,
      autor: responsible
   });

   let contentBody = [
      [
         "Código da solicitação",
         {
            content: request.code,
            colSpan: 1,
            styles: { fontSize: 12, fontStyle: "bold" }
         }
      ]
   ];

   if (!messages.length) {
      contentBody.push([
         {
            content: `Essa solicitação não apresenta nenhuma mensagem com o ${chatTypeTranslated}.`,
            colSpan: 2,
            styles: { fontSize: 10, halign: "center", minCellHeight: 15 }
         }
      ]);
   } else
      messages.forEach((item, index) => {
         contentBody.push([
            {
               ...SECTION_ROW_CONFIG,
               content: `${index + 1}º Mensagem`,
               colSpan: 2
            }
         ]);
         contentBody.push([
            "Data",
            formatUtil.formatDate(item.created_at, "DD/MM/YYYY [às] HH:mm")
         ]);

         let responsibleType = "Regulador";
         if (item.user_type === "operator")
            responsibleType = chatTypeTranslated;

         contentBody.push([
            "Responsável",
            `${item.responsible} - ${responsibleType}`
         ]);
         contentBody.push([
            "Mensagem",
            {
               styles: { fontStyle: "bold" },
               content: item.text,
               colSpan: 1
            }
         ]);
      });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: contentBody,
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   return pdf;
}

function translateUserType(user) {
   const type = user.type === "operator" ? user.role : user.type;

   let result = "";

   switch (type) {
      case "regulator_manager":
         result = "Gestor regulador";
         break;
      case "regulator":
         result = "Regulador";
         break;
      case "requester":
         result = "Solicitante";
         break;
      case "executor":
         result = "Executante";
         break;
   }

   return result;
}

/**
 * Método para gerar o PDF do histórico de uma solicitação.
 * @param {*} history: Array com os eventos de histórico do chat.
 * @param {*} request: Objeto requisição.
 * @param {*} responsible: Objeto com o usuário responsável pela impressão.
 * @returns Arquivo PDF (jsPDF).
 */
function generateHistoryRequestPDF(history, request, responsible) {
   const pdf = basePdfExport.generatePDFHeader({
      title: "Impressão do Histórico da Solicitação",
      autor: responsible
   });

   let contentBody = [
      [
         "Código da solicitação",
         {
            content: request.code,
            colSpan: 1,
            styles: { fontSize: 12, fontStyle: "bold" }
         }
      ]
   ];

   const historyTemp = util.cloneObject(history);

   historyTemp.reverse().forEach((item, index) => {
      contentBody.push([
         {
            ...SECTION_ROW_CONFIG,
            content: `${index + 1}º Ação - ${item.title}`,
            colSpan: 2
         }
      ]);
      contentBody.push([
         "Data",
         formatUtil.formatDate(item.date, "DD/MM/YYYY [às] HH:mm")
      ]);
      contentBody.push([
         "Responsável",
         `${item.user.name} - ${translateUserType(item.user)}`
      ]);
      contentBody.push(["Descrição", `${item.user.name}, ${item.text}`]);

      if (item.content && item.content.length)
         contentBody.push([
            "Conteúdo",
            {
               content: item.content
                  .map(item => `${item.field}: ${item.value}`)
                  .join("\n"),
               colSpan: 1,
               styles: { fontSize: 9, font: "Courier" }
            }
         ]);
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: contentBody,
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   return pdf;
}

function formatEmptyCell(data) {
   if (
      (data.cell.raw &&
         !data.cell.raw.content &&
         data.cell.text[0] === "[object Object]") ||
      !data.cell.text[0]
   ) {
      data.cell.styles = {
         ...data.cell.styles,
         fontStyle: "italic",
         textColor: "#777777",
         fontSize: 8
      };
      data.cell.text[0] = "Não informado";
      data.cell.isEmpty = true;
   }
}

function formatBooleanCell(data) {
   const booleanString = data.cell.text[0];

   if (booleanString === "" || booleanString === "false")
      data.cell.text[0] = "Não";
   else if (booleanString === "true") data.cell.text[0] = "Sim";
}

function formatPositive(data) {
   const text = data.cell.text[0];
   if (text === "positive") data.cell.text[0] = "Positivo";
   else if (text === "negative") data.cell.text[0] = "Negativo";
   else if (text === "unrealized") data.cell.text[0] = "Não realizou";
}

function formatRTPCRResults(data) {
   const text = data.cell.text[0];
   if (text === "undetectable") data.cell.text[0] = "Não detectável";
   else if (text === "detectable") data.cell.text[0] = "Detectável";
   else if (text === "waiting_result")
      data.cell.text[0] = "Esperando resultado";
}

function formatNEWSFAST(data) {
   const text = data.cell.text[0];
   if (text === "one") data.cell.text[0] = "1 ponto - Azul";
   else if (text === "two_yellow") data.cell.text[0] = "2 pontos - Amarelo";
   else if (text === "two_orange") data.cell.text[0] = "2 pontos - Laranja";
   else if (text === "greater_equal_to_three")
      data.cell.text[0] = "3 pontos ou mais - Vermelho";
}

function formatNEWSFASTModified(data) {
   const text = data.cell.text[0];
   if (text === "zero") data.cell.text[0] = "0 pontos - Branco";
   else if (text === "one_to_four") data.cell.text[0] = "1-4 pontos - Amarelo";
   else if (text === "five_to_six") data.cell.text[0] = "5-6 pontos - Laranja";
   else if (text === "greater_equal_to_seven_red")
      data.cell.text[0] = "7 ou mais pontos - Vermelho";
   else if (text === "greater_equal_to_seven_black")
      data.cell.text[0] = "7 ou mais pontos - Preto";
}

function formatPriorities(data) {
   const text = data.cell.text[0];
   if (text === "blue") data.cell.text[0] = "Azul";
   else if (text === "green") data.cell.text[0] = "Verde";
   else if (text === "yellow") data.cell.text[0] = "Amarelo";
   else if (text === "orange") data.cell.text[0] = "Laranja";
   else if (text === "red") data.cell.text[0] = "Vermelho";
}

function formatBedType(data) {
   const text = data.cell.text[0];
   if (text === "clinical") data.cell.text[0] = "Leito clínico";
   else if (text === "surgical") data.cell.text[0] = "Leito cirúrgico";
   else if (text === "uti") data.cell.text[0] = "Leito UTI";
}

function formatOutcome(data) {
   const text = data.cell.text[0];
   if (text === "discharge_cure") data.cell.text[0] = "Alta curado";
   else if (text === "discharge_improvement")
      data.cell.text[0] = "Alta melhorado";
   else if (text === "discharge_on_request")
      data.cell.text[0] = "Alta a pedido";
   else if (text === "discharge_with_return")
      data.cell.text[0] = "Alta com previsão de retorno";
   else if (text === "discharge_evasion") data.cell.text[0] = "Alta por evasão";
   else if (text === "discharge_other_reasons")
      data.cell.text[0] = "Alta por outro movitos";
   else if (text === "permanence_characteristics_disease")
      data.cell.text[0] = "Permanência por características da própria doença";
   else if (text === "permanence_complication")
      data.cell.text[0] = "Permanência por intercorrência";
   else if (text === "permanence_sociofamily_impossibility")
      data.cell.text[0] = "Permanência por impossibilidade sócio-familiar";
   else if (text === "permanence_other_reasons")
      data.cell.text[0] = "Permanência outros motivos";
   else if (text === "transfer") data.cell.text[0] = "Transfêrencia";
   else if (text === "death_certificate_by_doctor")
      data.cell.text[0] = "Declaração de óbito fornecida pelo médico";
   else if (text === "death_certificate_by_iml")
      data.cell.text[0] = "Declaração de óbito fornecida pelo IML";
   else if (text === "death_certificate_by_svo")
      data.cell.text[0] = "Declaração de óbito fornecida pelo SVO";
}

function appendGeneralDataInPDF(pdf, request) {
   const translatedStatus = status => {
      switch (status) {
         case "registered":
            return "Solicitada";
         case "in_progress":
            return "Em andamento";
         case "queued":
         case "regulated":
            return "Regulado";
         case "answered":
            return "Atendida";
         case "rejected":
         case "rejected_by_executor":
            return "Rejeitada";
         case "canceled":
            return "Cancelada";
      }
   };

   let contentBody = [
      [
         {
            ...SECTION_ROW_CONFIG,
            content: "Dados gerais",
            colSpan: 2
         }
      ],
      [
         "Cadastrada em",
         formatUtil.formatDate(request.created_at, "DD/MM/YYYY [às] HH:mm")
      ],
      ["Código", request.code],
      ["Unidade de saúde de origem", request.source_health_entity.name],
      ["Status", translatedStatus(request.status)]
   ];

   if (request.target_health_unit && request.target_health_unit.name)
      contentBody.splice(4, 0, [
         "Unidade de saúde de destino",
         request.target_health_unit.name
      ]);

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: contentBody,
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendOutcomeInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Desfecho",
               colSpan: 2
            }
         ],
         ["Desfecho por", request.outcome],
         ["Justificativa", request.outcome_justification],
         ["Data", formatUtil.formatDate(request.outcome_date)]
      ],
      didParseCell: function(data) {
         formatOutcome(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendJustificationRejectionInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Rejeitada",
               colSpan: 2
            }
         ],
         [
            "Rejeitada pelo",
            request.status === "rejected" ? "Regulador" : "Executante"
         ],
         [
            "Justificativa",
            request.status === "rejected"
               ? request.doctor_opinion.justification
               : request.status_justification
         ]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendPatientInPDF(pdf, request) {
   const translatedGender = gender => {
      switch (gender) {
         case "male":
            return "Masculino";
         case "female":
            return "Feminino";
      }
   };

   if (request.patient && request.patient.id)
      pdf.autoTable({
         ...TABLE_STYLE_DEFAULT,
         body: [
            [
               {
                  ...SECTION_ROW_CONFIG,
                  content: "Paciente",
                  colSpan: 4
               }
            ],

            [
               "Nome",
               request.patient.name,
               "Sexo",
               translatedGender(request.patient.gender)
            ],

            [
               "Data de nascimento",
               formatUtil.formatDate(request.patient.birth_date, "DD/MM/YYYY"),
               "RG",
               request.patient.rg
            ],

            ["CPF", request.patient.cpf, "CNS", request.patient.sus_card],

            [
               "CEP",
               request.patient.address.zip_code,
               "Endereço",
               `${request.patient.address.street}, ${request.patient.address.number}`
            ],

            [
               "Bairro",
               request.patient.address.district,
               "Cidade",
               request.patient.address.city
            ],

            [
               "Estado",
               request.patient.address.state,
               "Cidade natal",
               request.patient.address.birth_city
            ],

            [
               "Complemento",
               {
                  content: request.patient.address.complement,
                  colSpan: 3
               }
            ]
         ],
         didParseCell: function(data) {
            formatEmptyCell(data);
         },
         columnStyles: {
            0: { valign: "middle", cellWidth: 36 },
            1: { valign: "middle", cellWidth: 56 }
         },
         startY: pdf.lastAutoTable.finalY
      });
   else
      pdf.autoTable({
         ...TABLE_STYLE_DEFAULT,
         body: [
            [
               {
                  ...SECTION_ROW_CONFIG,
                  content: "Paciente",
                  colSpan: 2
               }
            ],

            ["Nome", "Anônimo"]
         ],
         didParseCell: function(data) {
            formatEmptyCell(data);
         },
         columnStyles: {
            0: { valign: "middle", cellWidth: 36 },
            1: { valign: "middle" }
         },
         startY: pdf.lastAutoTable.finalY
      });
}

function appendPatientDataInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Sinais vitais e tipo de leito",
               colSpan: 4
            }
         ],

         [
            "Saturação (%)",
            request.clinical_data.patient_data.oxygen_saturation,
            "Frequencia respiratória",
            request.clinical_data.patient_data.respiratory_rate
         ],

         [
            "Ar/O²",
            request.clinical_data.patient_data.oxygen_equipment,
            "Frequência cardíaca",
            request.clinical_data.patient_data.heart_rate
         ],

         [
            "Pressão arterial",
            request.clinical_data.patient_data.blood_pressure,
            "Temperatura (Cº)",
            request.clinical_data.patient_data.temperature
         ],

         [
            "Consciência",

            {
               content: request.clinical_data.patient_data.consciousness_level,
               colSpan: 3
            }
         ],

         [
            "Tipo de leito",
            {
               content: request.clinical_data.resource,
               colSpan: 3
            }
         ],
         [
            "Nome do médico",
            {
               content: request.clinical_data.doctor_name,
               colSpan: 3
            }
         ],
         [
            "Nome do enfermeiro",
            {
               content: request.clinical_data.nurse_name,
               colSpan: 3
            }
         ],
         [
            "Número de contato",
            {
               content: request.clinical_data.contact_phone,
               colSpan: 3
            }
         ],
         [
            "Observações",
            {
               content: request.clinical_data.notes,
               colSpan: 3
            }
         ]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle", cellWidth: 56 },
         2: { valign: "middle", cellWidth: 40 }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendFluSyndromeInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Síndrome gripal",
               colSpan: 6
            }
         ],
         [
            "Tosse",
            request.clinical_data.flu_syndrome.cough,
            "Coriza nasal",
            request.clinical_data.flu_syndrome.runny_nose,
            "Anosmia",
            request.clinical_data.flu_syndrome.anosmia
         ],
         [
            "Febre",
            request.clinical_data.flu_syndrome.fever,
            "Mialgia",
            request.clinical_data.flu_syndrome.myalgia,
            "Cefaleia",
            request.clinical_data.flu_syndrome.headache
         ],
         [
            "Fadiga",
            request.clinical_data.flu_syndrome.fatigue,
            "Dispneia",
            request.clinical_data.flu_syndrome.dyspnoea,
            "Astenia",
            request.clinical_data.flu_syndrome.asthenia
         ],
         [
            "Grau de parentesco",
            request.clinical_data.flu_syndrome.kinship_degree,
            {
               content:
                  "Exposição epidemiológica a pessoa infectada pelo SARS CoV-2",
               colSpan: 3
            },
            request.clinical_data.flu_syndrome.exposure_to_sars_cov_2
         ],
         [
            {
               content: "Tempo início de quadro sintomatico",
               colSpan: 2
            },
            {
               content: request.clinical_data.flu_syndrome.onset_time,
               colSpan: 4
            }
         ]
      ],
      didParseCell: function(data) {
         formatBooleanCell(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" },
         2: { valign: "middle", cellWidth: 36 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 36 },
         5: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendComorbiditiesInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Comorbidades",
               colSpan: 6
            }
         ],
         [
            "Obesidade",
            request.clinical_data.comorbidities.obesity,
            "HAS",
            request.clinical_data.comorbidities.sah,
            "Anosmia",
            request.clinical_data.flu_syndrome.anosmia
         ],
         [
            "Doença pulmonar pré-existente",
            request.clinical_data.comorbidities.pre_existing_lung_disease,
            "Uso de imunobiológicos",
            request.clinical_data.comorbidities.immunobiologicals,
            "Imunossupressor prévio",
            request.clinical_data.comorbidities.prior_immunosuppressive
         ],
         ["Diagnóstico HIV", request.clinical_data.comorbidities.hiv_diagnosis]
      ],
      didParseCell: function(data) {
         formatBooleanCell(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle", cellWidth: 25 },
         2: { valign: "middle", cellWidth: 36 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 36 },
         5: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            "Cardiopatira esquemia prévia - FE",
            request.clinical_data.comorbidities.prior_ischemic_heart_disease_ef
         ],
         [
            "Lesão renal crônica - Estágio",
            request.clinical_data.comorbidities.chronic_kidney_injury_stage
         ],
         [
            "Gestação - Idade gestacional",
            request.clinical_data.comorbidities.gestational_age
         ],
         [
            "Gesta/Parto",
            request.clinical_data.comorbidities.pregnancy_childbirth
         ],
         [
            "Histórico de transplante",
            request.clinical_data.comorbidities.transplant_history
         ],
         [
            "Comorbidades obstétricas",
            request.clinical_data.comorbidities.obstetric_comorbidities
         ],
         [
            "Outras cardiopatias",
            request.clinical_data.comorbidities.other_heart_diseases
         ]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 61 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendUsualMedicationsInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Medicamentos usuais",
               colSpan: 1
            }
         ],
         [request.clinical_data.usual_medications]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendRespiratoryStatusInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content:
                  "Oximetria periférica – Sistema Respiratório e Ventilação",
               colSpan: 8
            }
         ],
         [
            "Espontânea",
            request.clinical_data.respiratory.spontaneous,
            "IOT",
            request.clinical_data.respiratory.iot,
            "O² Suporte",
            request.clinical_data.respiratory.o2_support,

            "Máscara c/ reservatório",
            request.clinical_data.respiratory.mask_with_reservoir
         ],
         [
            "SPO²(%)",
            request.clinical_data.respiratory.spo2,
            "FR (IRPM)",
            request.clinical_data.respiratory.respiratory_rate,
            "Ventilação",
            request.clinical_data.respiratory.ventilation,
            "CN (lts/min)",
            request.clinical_data.respiratory.cn
         ],
         [
            "Venturi(%)",
            request.clinical_data.respiratory.venturi,
            "VMI",
            request.clinical_data.respiratory.vmi,
            "PCV",
            request.clinical_data.respiratory.pcv,
            "PSV",
            request.clinical_data.respiratory.psv
         ],
         [
            "VCV",
            request.clinical_data.respiratory.vcv,
            "VT (ml/Kg)",
            request.clinical_data.respiratory.vt,
            "Delta pressure",
            request.clinical_data.respiratory.delta_pressure,
            "cmH20 Platô",
            request.clinical_data.respiratory.plateau_pressure
         ],
         [
            "cmH20 Raw",
            request.clinical_data.respiratory.raw,
            "Cest",
            request.clinical_data.respiratory.cest,
            "SaO²",
            request.clinical_data.respiratory.sao2,
            "PaO²",
            request.clinical_data.respiratory.pao2
         ]
      ],
      didParseCell: function(data) {
         if (data.row.index === 1) formatBooleanCell(data);
         else formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 23 },
         1: { valign: "middle", cellWidth: 23 },
         2: { valign: "middle", cellWidth: 23 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 23 },
         5: { valign: "middle" },
         6: { valign: "middle", cellWidth: 23 },
         7: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendCardiovascularInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Verificação de PA/FC/FR – Cardiovascular e Infecção",
               colSpan: 4
            }
         ],

         [
            "DVA",
            request.clinical_data.cardiovascular.dva,
            "Arritmia",
            request.clinical_data.cardiovascular.arrhythmia
         ],
         [
            "Infecção bacteriana concomitante",
            request.clinical_data.cardiovascular
               .concomitant_bacterial_infection,
            "Terapia com antibióticos",
            request.clinical_data.cardiovascular.antibiotic_therapy
         ]
      ],
      didParseCell: function(data) {
         formatBooleanCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" },
         2: { valign: "middle", cellWidth: 46 },
         3: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         ["PA (mmHg)", request.clinical_data.cardiovascular.blood_pressure],
         [
            "Temperatura auxiliar (Cº)",
            request.clinical_data.cardiovascular.axillary_temperature
         ],
         [
            "Noradrenalina - Dose (mcg/kg/min)",
            request.clinical_data.cardiovascular.noradrenaline
         ],
         ["Adrenalina - Dose", request.clinical_data.cardiovascular.adrenaline],
         [
            "Vasopressina - Dose (Ul/min)",
            request.clinical_data.cardiovascular.vasopressin
         ],
         [
            "Dobutamina - Dose (mcg/Kg/min",
            request.clinical_data.cardiovascular.dobutamine
         ],
         ["FA", request.clinical_data.cardiovascular.fa],
         ["Flutter", request.clinical_data.cardiovascular.flutter],
         ["TSV", request.clinical_data.cardiovascular.tsv],
         ["TV", request.clinical_data.cardiovascular.tv],
         ["Foco", request.clinical_data.cardiovascular.infection_focus],
         [
            "Abnominal",
            request.clinical_data.cardiovascular.abdominal_infection
         ],
         [
            "Pele/Tecidos moles",
            request.clinical_data.cardiovascular.skin_soft_tissue_infection
         ],
         [
            "Corrente sanguínea",
            request.clinical_data.cardiovascular.blood_flow_infection
         ]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendSarscov2InPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "SARS CoV-2",
               colSpan: 6
            }
         ],
         [
            "Teste rápido",
            request.clinical_data.sarscov2.rapid_test,
            "IGM",
            request.clinical_data.sarscov2.igm,
            "IGG",
            request.clinical_data.sarscov2.igg
         ],
         [
            "Sintomas",
            {
               content: request.clinical_data.sarscov2.symptoms,
               colSpan: 5
            }
         ],
         [
            "RT PCR SARS CoV-2",
            {
               content: request.clinical_data.sarscov2.rt_pcr.result,
               colSpan: 5
            }
         ],
         [
            "Data da coleta",
            {
               content: request.clinical_data.sarscov2.rt_pcr.date,
               colSpan: 5
            }
         ]
      ],
      didParseCell: function(data) {
         if (data.row.index === 3) formatRTPCRResults(data);
         else formatPositive(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle", cellWidth: 32 },
         2: { valign: "middle", cellWidth: 17 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 17 },
         5: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            "Em uso de corticosteróide",
            request.clinical_data.sarscov2.corticosteroid.in_use,
            "Dose",
            request.clinical_data.sarscov2.corticosteroid.dose,
            "Data de início",
            request.clinical_data.sarscov2.corticosteroid.start_date
         ]
      ],
      didParseCell: function(data) {
         if (data.column.index === 1) formatBooleanCell(data);
         else formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle", cellWidth: 32 },
         2: { valign: "middle", cellWidth: 17 },
         3: { valign: "middle", cellWidth: 33 },
         4: { valign: "middle", cellWidth: 30 },
         5: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            "Em uso de Hidroxicloroquina",
            request.clinical_data.sarscov2.hydroxychloroquine.in_use,
            "Data de início",
            request.clinical_data.sarscov2.hydroxychloroquine.start_date
         ],
         [
            "Em uso Oseltamivir",
            request.clinical_data.sarscov2.oseltamivir.in_use,
            "Data de início",
            request.clinical_data.sarscov2.oseltamivir.start_date
         ],
         [
            "Em uso Heparina",
            request.clinical_data.sarscov2.heparin.in_use,
            "Data de início",
            request.clinical_data.sarscov2.heparin.start_date
         ]
      ],
      didParseCell: function(data) {
         if (data.column.index === 1) formatBooleanCell(data);
         else formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle", cellWidth: 82 },
         2: { valign: "middle", cellWidth: 30 },
         3: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendNeurologicalInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content:
                  "Desorientação, padrão neurologico, nível de consciência",
               colSpan: 6
            }
         ],
         [
            "Sedação contínua",
            request.clinical_data.neurological.continuous_sedation,
            "Escala de Coma de Glasgow (pontos)",
            request.clinical_data.neurological.gcs,
            "Midazolam dose",
            request.clinical_data.neurological.midazolam_dose
         ],

         [
            "Fentanil dose",
            request.clinical_data.neurological.fentanyl_dose,
            "Propofol dose",
            request.clinical_data.neurological.propofol_dose,
            "Bloqueador neuromuscular dose",
            request.clinical_data.neurological.neuromuscular_blocker
         ],
         [
            "Rocurônio dose",
            request.clinical_data.neurological.rocuronium,

            "Pancurônio dose",
            request.clinical_data.neurological.pancuronium,
            "Cisatracúrio dose",
            request.clinical_data.neurological.cisatracurium
         ]
      ],
      didParseCell: function(data) {
         if (data.row.index === 1 && data.column.index === 1)
            formatBooleanCell(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" },
         2: { valign: "middle", cellWidth: 36 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 36 },
         5: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendLaboratoryInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Laboratório",
               colSpan: 8
            }
         ],
         [
            "Data",
            {
               content: request.clinical_data.laboratory.date,
               colSpan: 7
            }
         ],
         [
            "Leucócitos",
            request.clinical_data.laboratory.leukocytes,
            "Linfócitos",
            request.clinical_data.laboratory.lymphocytes,
            "Hb",
            request.clinical_data.laboratory.hb,
            "Ht",
            request.clinical_data.laboratory.ht
         ],
         [
            "PCR",
            request.clinical_data.laboratory.pcr,
            "Lactato",
            request.clinical_data.laboratory.lactate,
            "DHL",
            request.clinical_data.laboratory.dhl,
            "Ferritina",
            request.clinical_data.laboratory.ferritin
         ],
         [
            "Troponina",
            request.clinical_data.laboratory.troponin,
            "CPK",
            request.clinical_data.laboratory.cpk,
            "D-dímero",
            request.clinical_data.laboratory.d_dimer,
            "Ureia",
            request.clinical_data.laboratory.urea
         ],
         [
            "Creatinina",
            request.clinical_data.laboratory.creatinine,
            "AP",
            request.clinical_data.laboratory.ap,
            "TAP",
            request.clinical_data.laboratory.tap,
            "INR",
            request.clinical_data.laboratory.inr
         ],
         [
            "TGO",
            request.clinical_data.laboratory.tgo,
            "TGP",
            request.clinical_data.laboratory.tgp,
            {
               content: " ",
               colSpan: 4
            }
         ]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 23 },
         1: { valign: "middle", cellWidth: 23 },
         2: { valign: "middle", cellWidth: 22 },
         3: { valign: "middle" },
         4: { valign: "middle", cellWidth: 22 },
         5: { valign: "middle" },
         6: { valign: "middle", cellWidth: 22 },
         7: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendImagesInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Imagens",
               colSpan: 2
            }
         ],
         ["TC tórax laudo", request.clinical_data.image_exams.chest_tomography],
         ["RX tórax laudo", request.clinical_data.image_exams.chest_x_ray]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendCERHRecomendationsInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Recomendações do CERH",
               colSpan: 2
            }
         ],
         [
            "Síndrome gripal",
            request.clinical_data.cerh_recommendations.flu_syndrome
         ],
         [
            "Critérios inconclusivos",
            request.clinical_data.cerh_recommendations.inconclusive_criteria
         ]
      ],
      didParseCell: function(data) {
         if (data.row.index === 1 && data.column.index === 1)
            formatBooleanCell(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendNEWSFASTInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "NEWS FAST",
               colSpan: 2
            }
         ],
         ["NEWS FAST (SES-PB)", request.clinical_data.news_fast],
         ["NEWS modificado", request.clinical_data.news_modified]
      ],
      didParseCell: function(data) {
         formatNEWSFAST(data);
         formatNEWSFASTModified(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendUTIInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "UTI",
               colSpan: 2
            }
         ],
         [
            "Insuficiência respiratória aguda",
            request.clinical_data.uti.accute_breathing_insufficiency
         ],
         [
            "Sepse choque séptico",
            request.clinical_data.uti.sepsis_septic_shock
         ],
         [
            "Instabilidade hemodinâmica",
            request.clinical_data.uti.hemodynamic_instability
         ],
         ["Disfunção orgânica", request.clinical_data.uti.organic_dysfunction]
      ],
      didParseCell: function(data) {
         formatBooleanCell(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendDoctorOpinionInPDF(pdf, request) {
   /* Não tem parecer médico */
   if (!request.doctor_opinion || !request.doctor_opinion.justification) return;

   let contentBody = [
      [
         {
            ...SECTION_ROW_CONFIG,
            content: "Parecer médico",
            colSpan: 2
         }
      ],
      [
         "Paciente não preenche critérios para regulação",
         request.doctor_opinion.patient_not_fit_for_regulation
      ],
      ["Justificativa", request.doctor_opinion.justification]
   ];

   if (!request.doctor_opinion.patient_not_fit_for_regulation)
      contentBody.splice(
         2,
         0,
         ["Prioridade", request.doctor_opinion.priority],
         ["Tipo de leito", request.doctor_opinion.bed_type]
      );

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: contentBody,
      didParseCell: function(data) {
         if (data.row.index === 1 && data.column.index === 1)
            formatBooleanCell(data);
         formatBedType(data);
         formatPriorities(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendSurgeryRequestDoctorOpinionInPDF(pdf, request) {
   /* Não tem parecer médico */
   if (!request.doctor_opinion || !request.doctor_opinion.justification) return;

   let contentBody = [
      [
         {
            ...SECTION_ROW_CONFIG,
            content: "Parecer médico",
            colSpan: 2
         }
      ],
      [
         "Paciente não preenche critérios para regulação",
         request.doctor_opinion.patient_not_fit_for_regulation
      ],
      ["Justificativa", request.doctor_opinion.justification]
   ];

   if (!request.doctor_opinion.patient_not_fit_for_regulation)
      contentBody.splice(2, 0, ["Prioridade", request.doctor_opinion.priority]);

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: contentBody,
      didParseCell: function(data) {
         if (data.row.index === 1 && data.column.index === 1)
            formatBooleanCell(data);
         formatBedType(data);
         formatPriorities(data);
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 46 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

function appendSurgeryInPDF(pdf, request) {
   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               ...SECTION_ROW_CONFIG,
               content: "Cirurgia",
               colSpan: 2
            }
         ],

         ["Cirurgia", request.surgeryName || "-"],
         ["Código SUS", request.doctor_opinion.sus_code || "-"]
      ],
      didParseCell: function(data) {
         formatEmptyCell(data);
      },
      columnStyles: {
         0: { valign: "middle", cellWidth: 36 },
         1: { valign: "middle" }
      },
      startY: pdf.lastAutoTable.finalY
   });
}

/**
 * Método para gerar o PDF de uma solicitação.
 * @param {*} request: Objeto requisição.
 * @param {*} responsible: Objeto com o usuário responsável pela impressão.
 * @returns Arquivo PDF (jsPDF).
 */
function generateRequestPDF(request, responsible) {
   const pdf = basePdfExport.generatePDFHeader({
      title: "Impressão de Solicitação de Leito",
      autor: responsible
   });

   appendGeneralDataInPDF(pdf, request);
   if (request.status === "answered" && !!request.outcome)
      appendOutcomeInPDF(pdf, request);
   if (
      request.status === "rejected" ||
      request.status === "rejected_by_executor"
   )
      appendJustificationRejectionInPDF(pdf, request);
   appendPatientInPDF(pdf, request);
   appendPatientDataInPDF(pdf, request);
   appendFluSyndromeInPDF(pdf, request);
   appendComorbiditiesInPDF(pdf, request);
   appendUsualMedicationsInPDF(pdf, request);
   appendRespiratoryStatusInPDF(pdf, request);
   appendCardiovascularInPDF(pdf, request);
   appendSarscov2InPDF(pdf, request);
   appendNeurologicalInPDF(pdf, request);
   appendLaboratoryInPDF(pdf, request);
   appendImagesInPDF(pdf, request);
   appendCERHRecomendationsInPDF(pdf, request);
   appendNEWSFASTInPDF(pdf, request);
   appendUTIInPDF(pdf, request);
   appendDoctorOpinionInPDF(pdf, request);

   return pdf;
}

/**
 * Método para gerar o PDF de uma solicitação de cirurgia.
 * @param {*} request: Objeto requisição.
 * @param {*} responsible: Objeto com o usuário responsável pela impressão.
 * @returns Arquivo PDF (jsPDF).
 */
function generateSurgeryRequestPDF(request, responsible) {
   const pdf = basePdfExport.generatePDFHeader({
      title: "Impressão de Solicitação de Cirurgia",
      autor: responsible
   });

   appendGeneralDataInPDF(pdf, request);
   if (request.status === "answered" && !!request.outcome)
      appendOutcomeInPDF(pdf, request);
   if (
      request.status === "rejected" ||
      request.status === "rejected_by_executor"
   )
      appendJustificationRejectionInPDF(pdf, request);
   appendPatientInPDF(pdf, request);
   appendSurgeryInPDF(pdf, request);
   appendSurgeryRequestDoctorOpinionInPDF(pdf, request);

   return pdf;
}

export default {
   generateMessagesRequestPDF,
   generateHistoryRequestPDF,
   generateRequestPDF,
   generateSurgeryRequestPDF
};
