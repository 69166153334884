<template>
   <div>
      <v-dialog
         v-model="dialogSurgeryTypeFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryTypeForm" />

            <SurgeryTypeForm
               @onClickCancel="closeSurgeryTypeForm"
               @onCreatedSurgeryType="
                  closeSurgeryTypeForm();
                  refreshSurgeryTypeList();
               "
               @onUpdatedSurgeryType="
                  closeSurgeryTypeForm();
                  refreshSurgeryTypeList();
               "
               @onClickRemoveSurgeryType="onClickRemoveSurgeryType"
               @onFindDisabledSurgeryType="onFindDisabledSurgeryType"
               :surgeryType="surgeryTypeSelected"
               :editMode="editMode"
               :healthUnit="healthUnitManagerCurrentHealthUnitStore"
               :hideEditButton="
                  healthUnitManagerCurrentHealthUnitStore &&
                     healthUnitManagerCurrentHealthUnitStore.id &&
                     !healthUnitManagerCurrentHealthUnitStore.capabilities
                        .can_offer_surgeries
               "
               class="standard-padding"
               hideRemoveButton
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <SurgeryTypeList
         @onClickAddSurgeryType="onClickAddSurgeryType"
         @onClickViewSurgeryType="onClickViewSurgeryType"
         @onClickEditSurgeryType="onClickEditSurgeryType"
         @onClickRemoveSurgeryType="onClickRemoveSurgeryType"
         :healthUnit="healthUnitManagerCurrentHealthUnitStore"
         :hideAddButton="
            healthUnitManagerCurrentHealthUnitStore &&
               healthUnitManagerCurrentHealthUnitStore.id &&
               !healthUnitManagerCurrentHealthUnitStore.capabilities
                  .can_offer_surgeries
         "
         :hideEditButton="
            healthUnitManagerCurrentHealthUnitStore &&
               healthUnitManagerCurrentHealthUnitStore.id &&
               !healthUnitManagerCurrentHealthUnitStore.capabilities
                  .can_offer_surgeries
         "
         class="mt-12"
         ref="surgeryTypelist"
         hideRemoveButton
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import PageTitle from "../../components/base/PageTitle";
import SurgeryTypeList from "../../components/surgeryType/SurgeryTypeList";
import SurgeryTypeForm from "../../components/surgeryType/SurgeryTypeForm";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import surgeryTypeService from "services/surgeryTypeService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "SurgeryType",

   components: { Close, PageTitle, SurgeryTypeList, SurgeryTypeForm },

   data: function() {
      return {
         surgeryTypeSelected: {},

         editMode: false,

         dialogSurgeryTypeFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      }
   },

   watch: {
      dialogSurgeryTypeFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddSurgeryType(surgeryType) {
         this.surgeryTypeSelected = Object.assign({}, surgeryType);
         this.openSurgeryTypeForm();
      },

      onClickViewSurgeryType(surgeryType) {
         this.surgeryTypeSelected = Object.assign({}, surgeryType);
         this.openSurgeryTypeForm();
      },

      onClickEditSurgeryType() {
         this.editMode = true;
      },

      onClickRemoveSurgeryType(item) {
         const methodDeleteItem = async () => {
            await surgeryTypeService.activeSurgeryType(item.id, false);

            this.dialogSurgeryTypeFormVisibility = false;
            this.refreshSurgeryTypeList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi excluída!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir essa cirurgia?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledSurgeryType(item) {
         const methodActiveItem = async () => {
            await surgeryTypeService.activeSurgeryType(item.id, true);

            this.refreshSurgeryTypeList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi ativada!"
            });
         };

         this.dialogSurgeryTypeFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "CIRURGIA JÁ EXISTENTE",
            message:
               "Uma cirurgia desativada com esse NOME foi encontrada, deseja ativá-la?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      openSurgeryTypeForm() {
         this.dialogSurgeryTypeFormVisibility = true;
      },

      closeSurgeryTypeForm() {
         this.dialogSurgeryTypeFormVisibility = false;
      },

      refreshSurgeryTypeList() {
         this.$refs.surgeryTypelist.refreshAllHelthUnitSurgeryTypesByRef();
      }
   },

   /* Toda vez ao exibir essa view é verificado se já existe o ID da unidade de saúde que essa pessoa deseja acessar,
   caso não exista, a tela de escolha da unidade de saúde é exibida, caso exista não será aberta a tela de escolha. */
   created() {
      switch (this.userStore.sub_type) {
         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;
      }
   }
};
</script>

<style scoped></style>
