/* Variáveis referentes a rotas. */
export const PUBLIC_ROUTES = [
   "/autenticacao",
   "/esqueci-minha-senha",
   "/password-reset",
   "/page404"
];

export const PROTECTED_ROUTES = [
   {
      text: "Controle de leitos",
      icon: "mdi-bed",
      link: "/painel-controle-leitos"
   },
   {
      text: "Controle de cirurgias",
      icon: "mdi-medical-bag",
      link: "/painel-controle-cirurgias"
   },
   {
      text: "Solicitações de leitos",
      icon: "mdi-file-document",
      link: "/solicitacoes"
   },
   {
      text: "Solicitações de cirurgias",
      icon: "mdi-medical-bag",
      link: "/solicitacoes-cirurgia"
   },
   {
      text: "Pacientes",
      icon: "mdi-account",
      link: "/pacientes"
   },
   {
      text: "Cirurgias",
      icon: "mdi-box-cutter",
      link: "/cirurgias"
   },
   {
      text: "Cirurgias aceitas",
      icon: "$box-cutter-check",
      link: "/cirurgias-aceitas"
   },
   {
      text: "Administradores",
      icon: "mdi-account-cog",
      link: "/administradores"
   },
   {
      text: "Gestores",
      icon: "mdi-account-check",
      link: "/gestores"
   },
   {
      text: "Gestores unidades de saúde",
      icon: "mdi-account-tie",
      link: "/gestores-unidades-saude"
   },
   {
      text: "Gestores de secretarias",
      icon: "mdi-account-plus",
      link: "/gestores-secretarias"
   },
   {
      text: "Operadores",
      icon: "mdi-account-hard-hat",
      link: "/operadores"
   },
   {
      text: "Gestores reguladores",
      icon: "mdi-doctor",
      link: "/gestores-reguladores"
   },
   {
      text: "Reguladores",
      icon: "mdi-account-details",
      link: "/reguladores"
   },
   {
      text: "Municípios",
      icon: "mdi-map",
      link: "/municipios"
   },
   {
      text: "Unidades de saúde",
      icon: "mdi-hospital-building",
      link: "/unidades-saude"
   },
   {
      text: "Secretaria de saúde",
      icon: "mdi-home-plus",
      link: "/secretaria-saude"
   },
   {
      text: "Auditoria",
      icon: "mdi-playlist-check",
      link: "/auditoria"
   },
   {
      text: "Estatísticas de leitos",
      icon: "mdi-chart-areaspline",
      link: "/estatisticas-leitos"
   },
   {
      text: "Estatísticas de cirurgias",
      icon: "mdi-chart-bar",
      link: "/estatisticas-cirurgias"
   },
   {
      text: "Alas",
      icon: "mdi-map-marker",
      link: "/alas"
   },
   {
      text: "Auditoria",
      icon: "mdi-text-search",
      link: "/auditoria"
   },
   {
      text: "Minha unidade de saúde",
      icon: "mdi-hospital-building",
      link: "/minha-unidade-saude"
   },
   {
      text: "Minha conta",
      icon: "mdi-cogs",
      link: "/minha-conta"
   }
];

/* Variável referente a versão da API. */
export const API_VERSION = "/v1";

/* 
Variável referente a váriável de ambiente NODE_ENV, necessário pois o Vue 
só aceita variáveis de ambiente com iniciais VUE_APP.
*/
export const NODE_ENV = process.env.VUE_APP_NODE_ENV || "VUE_APP_NODE_ENV";

/* Variáveis referentes ao anexos permitidos no sistema. */
export const ALLOWED_TYPES = ["application/pdf", "image/png", "image/jpeg"];
export const MAXIMUM_SIZE = 5000000;

/* Variável referente ao delay para efetuar buscas em campos de texto. */
export const SEARCH_DELAY = 800;
