<template>
   <div
      class="d-flex flex-column justify-center align-center standard-padding-tiny"
   >
      <v-progress-circular
         class="mb-4"
         width="3"
         :size="50"
         color="primary"
         indeterminate
      ></v-progress-circular>

      <p class="body-2 text-center primary--text mt-1">
         {{ message }}
      </p>
   </div>
</template>

<script>
export default {
   name: "LoadingData",

   props: {
      message: {
         type: String,
         default: "Carregando, aguarde por favor.",
      },
   },
};
</script>

<style scoped>
</style>