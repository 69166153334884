<template>
   <div>
      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="patientResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogPatientFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closePatientForm" />

            <!-- Visualizaçao do usuário Administrador. -->
            <div v-if="userStore.sub_type === 'admin'">
               <v-tabs v-model="activeTab" centered show-arrows>
                  <v-tab id="pacient-button">
                     <v-icon small left> mdi-account </v-icon>
                     PACIENTE
                  </v-tab>
                  <v-tab
                     v-if="patientSelected.id"
                     id="health_entities-pacient-button"
                  >
                     <v-icon small left> mdi-home-city </v-icon>
                     ENTIDADES DO PACIENTE
                  </v-tab>
               </v-tabs>

               <div v-if="activeTab === 0">
                  <PatientAdminForm
                     v-if="userStore.sub_type === 'admin'"
                     @onClickCancel="closePatientForm"
                     @onCreatedPatient="
                        closePatientForm();
                        refreshPatientList();
                     "
                     @onUpdatedPatient="
                        closePatientForm();
                        refreshPatientList();
                     "
                     @onClickResetPatient="onClickResetPatient"
                     @onClickRemovePatient="onClickRemovePatient"
                     @onFindDisabledPatient="onFindDisabledPatient"
                     @onFindExistentPatient="onFindExistentPatient"
                     :patient="patientSelected"
                     :editMode="editMode"
                     class="standard-padding"
                     hideTitle
                     hideRemoveButton
                  />
               </div>
               <div v-else-if="activeTab === 1 && patientSelected.id">
                  <PatientHealthEntity
                     @onUpdatedPatient="
                        closePatientForm();
                        refreshPatientList();
                     "
                     :patient="patientSelected"
                     class="standard-padding"
                  />
               </div>
            </div>

            <!-- Visualizaçao do usuário Operador e Gestor de secretaria. -->
            <PatientForm
               v-else
               @onClickCancel="closePatientForm"
               @onCreatedPatient="
                  closePatientForm();
                  refreshPatientList();
               "
               @onUpdatedPatient="
                  closePatientForm();
                  refreshPatientList();
               "
               @onClickResetPatient="onClickResetPatient"
               @onClickRemovePatient="onClickRemovePatient"
               @onFindDisabledPatient="onFindDisabledPatient"
               @onFindExistentPatient="onFindExistentPatient"
               :patient="patientSelected"
               :healthEntity="
                  operatorCurrentHealthUnitStore ||
                     healthSecretaryManagerCurrentHealthUnitStore
               "
               :hideRemoveButton="
                  !['solicitator', 'secretary_manager'].includes(
                     userStore.sub_type
                  )
               "
               :hideEditButton="hideEditButtonOperator"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <PatientList
         @onClickAddPatient="onClickAddPatient"
         @onClickViewPatient="onClickViewPatient"
         @onClickEditPatient="onClickEditPatient"
         @onClickRemovePatient="onClickRemovePatient"
         :hideRemoveButton="
            !['solicitator', 'secretary_manager'].includes(userStore.sub_type)
         "
         class="mt-12"
         ref="patientlist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import PatientList from "../../components/patient/PatientList";
import PatientAdminForm from "../../components/patient/PatientAdminForm";
import PatientForm from "../../components/patient/PatientForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import PatientHealthEntity from "components/patient/associationHealthEntityForm/PatientHealthEntity";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import patientService from "../../services/patientService";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "Patient",

   components: {
      Close,
      PageTitle,
      PatientList,
      PatientAdminForm,
      PatientForm,
      ResetPasswordUsersForm,
      PatientHealthEntity
   },

   data: function() {
      return {
         patientSelected: {},
         patientResetPasswordSelected: {},

         editMode: false,

         dialogPatientFormVisibility: false,
         dialogResetPasswordFormVisibility: false,

         activeTab: 0
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthSecretaryManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         }
      },

      hideEditButtonOperator() {
         return !!this.patientSelected.last_login;
      }
   },

   watch: {
      dialogPatientFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddPatient(patient) {
         this.patientSelected = Object.assign({}, patient);
         this.openPatientForm();
      },

      onClickViewPatient(patient) {
         this.patientSelected = Object.assign({}, patient);
         this.openPatientForm();
      },

      onClickEditPatient() {
         this.editMode = true;
      },

      onClickResetPatient(patient) {
         this.patientResetPasswordSelected = Object.assign({}, patient);
         this.openResetPasswordForm();
      },

      onClickRemovePatient(item) {
         if (
            this.userStore.sub_type === "solicitator" &&
            !(
               this.operatorCurrentHealthUnitStore &&
               this.operatorCurrentHealthUnitStore.id
            )
         )
            return;
         if (
            this.userStore.sub_type === "secretary_manager" &&
            !(
               this.healthSecretaryManagerCurrentHealthUnitStore &&
               this.healthSecretaryManagerCurrentHealthUnitStore.id
            )
         )
            return;

         const methodDeleteItem = async () => {
            if (this.userStore.sub_type === "solicitator")
               await patientService.disassociatePatientToHealthEntityById(
                  item.id,
                  this.operatorCurrentHealthUnitStore.id
               );
            else if (this.userStore.sub_type === "secretary_manager")
               await patientService.disassociatePatientToHealthEntityById(
                  item.id,
                  this.healthSecretaryManagerCurrentHealthUnitStore.id
               );

            this.dialogPatientFormVisibility = false;

            this.refreshPatientList();
            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O paciente foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse paciente?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledPatient(item) {
         const methodActiveItem = async () => {
            await patientService.activePatient(item.id, true);

            this.refreshPatientList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O paciente foi ativado!"
            });
         };

         this.dialogPatientFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "PACIENTE JÁ EXISTENTE",
            message:
               "Um paciente desativado com esse CPF foi encontrado, deseja ativá-lo?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      onFindExistentPatient(item) {
         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "PACIENTE JÁ EXISTENTE",
            message:
               "Um paciente com esse CPF foi encontrado, deseja visualizá-lo?",
            action: {
               text: "Visualizar",
               method: () => (this.patientSelected = Object.assign({}, item))
            }
         });
      },

      openPatientForm() {
         if (this.userStore.sub_type === "admin") this.activeTab = 0;
         this.dialogPatientFormVisibility = true;
      },

      closePatientForm() {
         this.dialogPatientFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      refreshPatientList() {
         this.$refs.patientlist.refreshAllPatientsByRef();
      }
   }
};
</script>

<style scoped></style>
