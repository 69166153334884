<template>
   <v-navigation-drawer
      v-model="navigationDrawerVisibilityStore"
      app
      temporary
      width="300"
   >
      <router-link
         class="d-flex flex-column justify-center align-center"
         :to="userRoutes.length ? userRoutes[0] : '/'"
      >
         <v-icon size="150">
            $reg-logo
         </v-icon>
      </router-link>

      <p v-if="userStore" class="body-2 text-center mb-7">
         Acessando como <br />
         <strong>{{ translateSubType(userStore.sub_type) }}</strong>
      </p>

      <v-divider></v-divider>

      <v-list class="pa-0">
         <v-list-item
            v-for="(item, index) in menuRouteList"
            :key="item.text"
            :to="item.link"
            :id="`drawer-${index}-button`"
            color="primary"
         >
            <v-icon v-text="item.icon" class="py-0 pr-5 pl-2"></v-icon>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
         </v-list-item>
      </v-list>
   </v-navigation-drawer>
</template>

<script>
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "NavigationDrawer",

   props: {
      userRoutes: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         menuRouteList: [],
      };
   },

   computed: {
      navigationDrawerVisibilityStore: {
         get() {
            return this.$store.getters["navigationDrawer/getVisibility"];
         },

         set(value) {
            this.$store.commit("navigationDrawer/setVisibility", value);
         },
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   watch: {
      userRoutes: {
         immediate: true,
         handler(userRoutes) {
            this.menuRouteList = [];

            userRoutes.forEach((userRoute) => {
               const match = PROTECTED_ROUTES.find(
                  (route) => userRoute === route.link
               );

               if (match)
                  if (
                     !this.menuRouteList.find(
                        (item) => item.link === match.link
                     )
                  )
                     this.menuRouteList.push(match);
            });
         },
      },
   },

   methods: {
      translateSubType(subType) {
         let translatedSubType;

         switch (subType) {
            case "admin":
               translatedSubType = "Administrador";
               break;
            case "manager":
               translatedSubType = "Gestor";
               break;
            case "healthunit_manager":
               translatedSubType = "Gestor de unidade de saúde";
               break;
            case "secretary_manager":
               translatedSubType = "Gestor de secretaria de saúde";
               break;
            case "solicitator":
               translatedSubType = "Solicitante";
               break;
            case "executor":
               translatedSubType = "Executante";
               break;
            case "regulator_manager":
               translatedSubType = "Gestor regulador";
               break;
            case "regulator":
               translatedSubType = "Regulador";
               break;
         }

         return translatedSubType;
      },
   },
};
</script>

<style scoped>
</style>