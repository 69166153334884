<template>
   <div>
      <FormTitle v-if="!hideTitle" icon="mdi-bed" title="LEITO" />

      <v-alert
         v-if="getMode() === 'read' && formBed.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Leito cadastrado em
         {{ formatDate(formBed.created_at, "DD/MM/YYYY [às] HH:mm") }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <v-row class="mt-0">
               <v-col cols="12">
                  <v-text-field
                     @change="onChangeBedCode"
                     v-model="formBed.code"
                     :rules="[(value) => !!value || 'Código obrigatório.']"
                     :loading="loadingBedByCode"
                     label="Código*"
                     id="code-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     label="Nome*"
                     id="name-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.type"
                     :rules="[(value) => !!value || 'Tipo obrigatório.']"
                     label="Tipo"
                  ></v-text-field>
               </v-col>
            </v-row> -->

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-text-field
                     v-model="formBed.description"
                     label="Descrição"
                     id="description-bed-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <v-row class="mt-3" v-if="bed.id">
         <v-col cols="12">
            <p class="caption mb-1">Status</p>

            <v-chip
               :color="configByStatus.color"
               class="font-weight-bold"
               value="reserved"
               small
               dark
            >
               {{ configByStatus.translation }}
            </v-chip>
         </v-col>
      </v-row>

      <FormActions
         @onClickEdit="onClickEditBed"
         @onClickRemove="onClickRemoveBed(bed)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveBed"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveBed"
         class="mt-7"
      />
   </div>
</template>

<script>
import FormActions from "../../../components/base/form/FormActions";
import FormTitle from "../../../components/base/form/FormTitle";
import formatUtil from "../../../utils/formatUtil";
import bedService from "../../../services/bedService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";

export default {
   name: "BedHealthUnitManagerForm",

   components: { FormActions, FormTitle },

   props: {
      bed: {
         type: Object,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formBed: {},

         loadingSaveBed: false,
         loadingBedByCode: false,
      };
   },

   computed: {
      configByStatus() {
         let color = "black";
         let translation = "Indefinido";

         if (this.bed && this.bed.status) {
            switch (this.bed.status) {
               case "free":
                  color = "success";
                  translation = "Livre";
                  break;
               // case "waiting_reservation":
               //    color = "success";
               //    translation = "Aguardando reserva";
               //    break;
               case "blocked":
                  color = "error";
                  translation = "Bloqueado";
                  break;
               case "occupied":
                  color = "warning";
                  translation = "Ocupado";
                  break;
               case "reserved":
                  color = "purple";
                  translation = "Reservado";
                  break;
            }
         }

         return { color, translation };
      },
   },

   watch: {
      bed: {
         immediate: true,
         handler(bed) {
            if (bed.id) this.setMode("read");
            else this.setMode("save");

            this.formBed = this.formatObjectToView(bed);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,

      formatObjectToView(bed) {
         let bedTemp = util.mergeObject({}, bed);

         return bedTemp;
      },

      formatObjectToSend(bed) {
         let bedTemp = util.removeBlankAttributes(bed);

         return bedTemp;
      },

      onClickEditBed() {
         this.setMode("save");
      },

      onClickRemoveBed(bed) {
         this.$emit("onClickRemoveBed", bed);
      },

      onClickCancel() {
         this.setMode("read");
         this.formBed = this.formatObjectToView(this.bed);
         this.$emit("onClickCancel");
      },

      onClickSaveBed() {
         if (this.formBed.id)
            this.updateBed(this.formBed.ward_id, this.formBed.id, this.formBed);
         else this.createBed(this.formBed.ward_id, this.formBed);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onChangeBedCode() {
         this.getBedByName(this.formBed.ward_id, this.formBed.code);
      },

      async getBedByName(wardId, name) {
         this.loadingBedByCode = true;

         try {
            const response = await bedService.getAllBeds(wardId, {
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "active",
                     query: false,
                  },
                  {
                     attribute: "name",
                     query: name,
                  },
               ],
            });

            if (
               response.data &&
               response.data.length &&
               response.data[0].code.toUpperCase() ===
                  this.formBed.code.toUpperCase()
            )
               this.$emit("onFindDisabledBed", response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingBedByCode = false;
         }
      },

      async createBed(wardId, bed) {
         if (!this.executeValidation() || !wardId || !bed) return;

         this.loadingSaveBed = true;

         try {
            await bedService.createBed(wardId, this.formatObjectToSend(bed));

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O leito foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedBed");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveBed = false;
         }
      },

      async updateBed(wardId, bedId, bed) {
         if (!this.executeValidation() || !wardId || !bedId || !bed) return;

         this.loadingSaveBed = true;

         try {
            await bedService.updateBed(
               wardId,
               bedId,
               this.formatObjectToSend(bed)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O leito foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedBed");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveBed = false;
         }
      },
   },
};
</script>

<style scoped>
</style>