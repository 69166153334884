<template>
   <div>
      <FormTitle icon="mdi-bed" title="RESERVAR LEITO" />

      <v-form ref="form" v-if="!healthUnitTransfer">
         <HealthUnitInput
            ref="healthunitinput"
            @onSelectHealthUnit="onSelectHealthUnit"
            @onSelectMunicipality="onSelectMunicipality"
            :itemDisable="{ id: request.source_health_entity.id }"
         />
      </v-form>

      <BedReserveList
         @onClickViewBed="onClickViewBed"
         :healthUnit="healthUnit"
         :request="request"
         :alertHelp="alertHelp"
      />
   </div>
</template>

<script>
import HealthUnitInput from "../../healthUnit/healthUnitInput/HealthUnitInput";
import FormTitle from "../../../components/base/form/FormTitle";
import BedReserveList from "../../bed/bedReserve/BedReserveList";

export default {
   name: "BedReserveForm",

   components: {
      FormTitle,
      BedReserveList,
      HealthUnitInput,
   },

   props: {
      request: {
         type: Object,
      },

      healthUnitTransfer: {
         type: Object,
      },

      alertHelp: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         healthUnit: null,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler() {
            if (this.$refs.healthunitinput) this.$refs.healthunitinput.reset();
            this.resetValidation();
            this.healthUnit = null;
         },
      },

      healthUnitTransfer: {
         immediate: true,
         handler(healthUnitTransfer) {
            if (healthUnitTransfer && healthUnitTransfer.id)
               this.healthUnit = healthUnitTransfer;
         },
      },
   },

   methods: {
      onSelectHealthUnit(healthUnit) {
         this.healthUnit = healthUnit;
      },

      onSelectMunicipality() {
         this.healthUnit = null;
      },

      onClickViewBed(bed) {
         let healthUnit = this.healthUnit;
         if (this.healthUnitTransfer)
            healthUnit = this.request.source_health_entity;

         this.$emit("onClickViewBed", { bed, healthUnit: healthUnit });
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },
   },
};
</script>

<style scoped></style>