<template>
   <div>
      <v-dialog
         v-model="dialogChangePasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangePasswordForm" />

            <MyAccountChangePasswordForm
               @onClickCancel="closeChangePasswordForm"
               @onUpdatedChangePassword="closeChangePasswordForm"
               :user="myAccount"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <v-card
         :disabled="loadingMyAccount"
         :loading="loadingMyAccount"
         class="mt-12"
      >
         <MyAccountForm
            @onUpdatedMyAccount="getMyAccount(userStore.sub)"
            @onClickChangePassword="openChangePasswordForm"
            :myAccount="myAccount"
            class="standard-padding"
         />
      </v-card>
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import MyAccountForm from "../../components/myAccount/MyAccountForm";
import MyAccountChangePasswordForm from "../../components/myAccount/MyAccountChangePasswordForm";
import adminService from "../../services/adminService";
import healthUnitManagerService from "../../services/healthUnitManagerService";
import managerService from "../../services/managerService";
import operatorService from "../../services/operatorService";
import regulatorManagerService from "../../services/regulatorManagerService";
import regulatorService from "../../services/regulatorService";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";

export default {
   name: "MyAccount",

   components: { PageTitle, Close, MyAccountForm, MyAccountChangePasswordForm },

   data: function () {
      return {
         loadingMyAccount: false,

         myAccount: {},

         dialogChangePasswordFormVisibility: false,
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      closeChangePasswordForm() {
         this.dialogChangePasswordFormVisibility = false;
      },

      openChangePasswordForm() {
         this.myAccount = Object.assign({}, this.myAccount);
         this.dialogChangePasswordFormVisibility = true;
      },

      async getMyAccount(userId) {
         this.loadingMyAccount = true;

         try {
            let response;

            switch (this.userStore.sub_type) {
               case "admin":
                  response = await adminService.getAdminById(userId);
                  break;
               case "manager":
                  response = await managerService.getManagerById(userId);
                  break;
               case "healthunit_manager":
                  response =
                     await healthUnitManagerService.getHealthUnitManagerById(
                        userId
                     );
                  break;
               case "secretary_manager":
                  response =
                     await healthSecretaryManagerService.getHealthSecretaryManagerById(
                        userId
                     );
                  break;
               case "solicitator":
               case "executor":
                  response = await operatorService.getOperatorById(userId);
                  break;
               case "regulator_manager":
                  response =
                     await regulatorManagerService.getRegulatorManagerById(
                        userId
                     );
                  break;
               case "regulator":
                  response = await regulatorService.getRegulatorById(userId);
                  break;
            }

            this.myAccount = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMyAccount = false;
         }
      },
   },

   created() {
      this.getMyAccount(this.userStore.sub);
   },
};
</script>

<style scoped>
</style>