<template>
   <v-card :disabled="disableItem" flat>
      <div class="d-flex align-center">
         <v-icon size="21" left>mdi-hospital-building</v-icon>

         <v-tooltip v-if="!loadingHealthUnit" top>
            <template v-slot:activator="{ on, attrs }">
               <p v-bind="attrs" v-on="on" class="mt-1 wrap-healthunit-name">
                  {{ healthUnit.name }}
               </p>
            </template>

            <span>{{ healthUnit.name }}</span>
         </v-tooltip>

         <v-skeleton-loader
            v-else
            class="rounded-pill"
            type="image"
            width="150"
            height="12px"
         ></v-skeleton-loader>
      </div>

      <v-card
         @click="reserveSurgery(surgery)"
         class="standard-padding-tiny mt-2"
         outlined
         flat
      >
         <div class="d-flex align-center">
            <v-icon size="20" left>mdi-medical-bag</v-icon>

            <p class="caption surgery-label">{{ surgery.name }}</p>
         </div>

         <div class="d-flex align-center mt-2">
            <v-icon size="20" left>mdi-check-circle</v-icon>

            <v-chip
               :color="colorByTotal(surgery.total - (surgery.answered || 0))"
               class="px-2"
               small
            >
               {{ surgery.total - (surgery.answered || 0) }}
            </v-chip>
         </div>
      </v-card>
   </v-card>
</template>

<script>
import healthUnitService from "services/healthUnitService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "SurgeryTypeReserveListCard",

   data: function () {
      return {
         healthUnit: {},
         loadingHealthUnit: false,
      };
   },

   props: {
      surgery: {
         type: Object,
      },

      disableItem: {
         type: Boolean,
      },
   },

   watch: {
      surgery: {
         immediate: true,
         handler(surgery) {
            if (surgery && surgery.healthunit_id)
               this.getHealthUnitById(surgery.healthunit_id);
         },
      },
   },

   methods: {
      async getHealthUnitById(healthUnitId) {
         this.loadingHealthUnit = true;

         try {
            const response = await healthUnitService.getHealthUnitById(
               healthUnitId
            );

            this.healthUnit = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthUnit = false;
         }
      },

      colorByTotal(total) {
         let color;

         if (total >= 300) color = "info";
         else if (total >= 100) color = "success";
         else if (total >= 50) color = "amber";
         else if (total >= 10) color = "warning";
         else color = "error";

         return color;
      },

      reserveSurgery(surgery) {
         this.$emit("onReserveSurgery", surgery);
      },
   },
};
</script>

<style scoped>
.wrap-healthunit-name {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>