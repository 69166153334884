<template>
   <div>
      <ListSkeleton
         v-if="loadingMunicipalitiesFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (municipality) =>
               !blockClick ? onClickViewMunicipality(municipality) : null
         "
         :headers="headers"
         :items="municipalitys"
         :loading="loadingMunicipalitiesPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddMunicipality"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingMunicipalitiesPartially"
               :hideAddButton="hideAddButton"
               filterActor="município"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalMunicipalitys"
               :loading="loadingMunicipalitiesPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData message="Desculpe, nenhum município foi encontrado." />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando municípios, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-municipality-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.macro_region`]="{ item }">
            <p class="macro_region-municipality-list-data">
               {{ translateHealthRegion(item.macro_region) }}
            </p>
         </template>

         <template v-slot:[`item.health_region`]="{ item }">
            <p class="health_region-municipality-list-data">
               {{ translateHealthRegion(item.health_region) }}
            </p>
         </template>

         <template v-slot:[`item.created_at`]="{ item }">
            <p class="created_at-municipality-list-data">
               {{ formatDate(item.created_at) }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
               @click="editMunicipality(item)"
               :id="`edit-item-${index}-municipality-list-button`"
               class="mx-1"
               color="black"
               small
            >
               mdi-pencil
            </v-icon>

            <v-icon
               v-if="!hideRemoveButton"
               @click="removeMunicipality(item)"
               :id="`remove-item-${index}-municipality-list-button`"
               color="error"
               class="mx-1"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import municipalityService from "../../services/municipalityService";

export default {
   name: "MunicipalityList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   props: {
      hideAddButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "Macro região",
               class: "primary--text body-2 font-weight-medium",
               value: "macro_region",
            },
            {
               text: "Região de saúde",
               class: "primary--text body-2 font-weight-medium",
               value: "health_region",
            },
            {
               text: "Cadastrado em",
               class: "primary--text body-2 font-weight-medium",
               value: "created_at",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
            {
               filter: "macro_region",
               text: "Macro região",
               mask: "#",
               unmask: /[^0-9]/g,
               maxLength: 1,
            },
            {
               filter: "health_region",
               text: "Região de saúde",
               mask: "##",
               unmask: /[^0-9]/g,
               maxLength: 2,
            },
         ],

         municipalitys: [],

         totalMunicipalitys: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingMunicipalitiesFully: true,
         loadingMunicipalitiesPartially: true,

         blockClick: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter = {
               attribute: this.filter,
               query: this.filterBy,
            };

         return query;
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllMunicipalitys(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllMunicipalitys(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllMunicipalitys(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllMunicipalitys(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllMunicipalitys(this.query);
      },

      onClickAddMunicipality(municipality) {
         this.$emit("onClickAddMunicipality", municipality);
      },

      onClickViewMunicipality(municipality) {
         this.$emit("onClickViewMunicipality", municipality);
      },

      editMunicipality(municipality) {
         this.$emit("onClickEditMunicipality", municipality);
      },

      removeMunicipality(municipality) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveMunicipality", municipality);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllMunicipalitysByRef() {
         this.loadingMunicipalitiesFully = true;
         await this.getAllMunicipalitys(this.query);
         this.loadingMunicipalitiesFully = false;
      },

      async getAllMunicipalitys(query) {
         this.loadingMunicipalitiesPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await municipalityService.getAllMunicipalities(
               query
            );

            this.totalMunicipalitys = parseInt(
               response.headers["x-total-count"]
            );
            this.municipalitys = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMunicipalitiesPartially = false;
         }
      },

      translateHealthRegion(healthRegion) {
         return [
            { text: "1º", value: "first" },
            { text: "2º", value: "second" },
            { text: "3º", value: "third" },
            { text: "4º", value: "fourth" },
            { text: "5º", value: "fifth" },
            { text: "6º", value: "sixth" },
            { text: "7º", value: "seventh" },
            { text: "8º", value: "eighth" },
            { text: "9º", value: "ninth" },
            { text: "10º", value: "tenth" },
            { text: "11º", value: "eleventh" },
            { text: "12º", value: "twelfth" },
            { text: "13º", value: "thirteenth" },
            { text: "14º", value: "fourteenth" },
            { text: "15º", value: "fifteenth" },
            { text: "16º", value: "sixteenth" },
         ].find((item) => item.value === healthRegion).text;
      },
   },

   async created() {
      this.loadingMunicipalitiesFully = true;
      await this.getAllMunicipalitys(this.query);
      this.loadingMunicipalitiesFully = false;
   },
};
</script>

<style scoped>
</style>