<template>
   <div>
      <div v-if="!hideTitle" class="d-flex align-center mb-3">
         <p class="body-2 font-weight-medium">Dados do paciente</p>
      </div>

      <v-row>
         <v-col cols="12" md="6">
            <p class="caption text--secondary">Nome</p>
            <p class="body-2">{{ patient.name }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">CPF</p>
            <p class="body-2">{{ formatCPF(patient.cpf) }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Cartão do SUS</p>
            <p class="body-2">{{ patient.sus_card }}</p>
         </v-col>
      </v-row>

      <v-row class="mt-n2">
         <v-col cols="12" md="6">
            <p class="caption text--secondary">Email</p>
            <p class="body-2">{{ patient.email || "-" }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Telefone</p>
            <p class="body-2">{{ formatPhone(patient.phone) }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Data de nascimento</p>
            <p class="body-2">{{ formatDate(patient.birth_date) }}</p>
         </v-col>
      </v-row>

      <v-row class="mt-n2">
         <v-col cols="12" md="2">
            <p class="caption text--secondary">CEP</p>
            <p class="body-2">{{ patient.address.zip_code }}</p>
         </v-col>
         <v-col cols="12" md="4">
            <p class="caption text--secondary">Rua</p>
            <p class="body-2">{{ patient.address.street }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Número</p>
            <p class="body-2">{{ patient.address.number }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Bairro</p>
            <p class="body-2">{{ patient.address.district }}</p>
         </v-col>
      </v-row>

      <v-row class="mt-n2">
         <v-col cols="12" md="6">
            <p class="caption text--secondary">Cidade</p>
            <p class="body-2">{{ patient.address.city }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Estado</p>
            <p class="body-2">{{ patient.address.state }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Complemento</p>
            <p class="body-2">{{ patient.address.complement || "-" }}</p>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import formatUtil from "utils/formatUtil";

export default {
   name: "ViewPatient",

   props: {
      patient: {
         type: Object,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      ...formatUtil,
   },
};
</script>

<style>
</style>