import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllPatients(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/patients?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
            case "cpf":
            case "health_entities":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function getPatientById(patientId) {
   return await axiosService.get(`/patients/${patientId}`);
}

async function getPatientByCpf(patientCpf) {
   return await axiosService.get(`/patients/${patientCpf}`);
}

async function createPatient(patient) {
   return await axiosService.post(`/patients`, patient);
}

async function updatePatient(patientId, patient) {
   return await axiosService.patch(`/patients/${patientId}`, patient);
}

async function activePatient(patientId, active) {
   return await axiosService.put(`/users/${patientId}/active`, { active });
}

async function associatePatientToHealthEntityById(patientId, healthEntityId) {
   return await axiosService.post(
      `/patients/${patientId}/healthentities/${healthEntityId}`
   );
}

async function disassociatePatientToHealthEntityById(
   patientId,
   healthEntityId
) {
   return await axiosService.delete(
      `/patients/${patientId}/healthentities/${healthEntityId}`
   );
}

export default {
   getAllPatients,
   getPatientById,
   getPatientByCpf,
   createPatient,
   updatePatient,
   activePatient,
   associatePatientToHealthEntityById,
   disassociatePatientToHealthEntityById
};
