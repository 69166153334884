var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingSurgeryTypesFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit,"hideAddButton":_vm.hideAddButton}}):_c('v-data-table',{staticClass:"standard-padding-data-list",class:_vm.listElevation,attrs:{"headers":_vm.headers,"items":_vm.surgeryTypes,"loading":_vm.loadingSurgeryTypesPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (surgeryType) { return !_vm.blockClick ? _vm.onClickViewSurgeryType(surgeryType) : null; }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingSurgeryTypesPartially,"hideAddButton":_vm.hideAddButton,"filterActor":"cirurgia","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddSurgeryType}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalSurgeryTypes,"loading":_vm.loadingSurgeryTypesPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhuma cirurgia foi encontrada."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando cirurgias, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"name-surgeryType-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.colorByTotal(item.total - item.answered),"small":""}},[_vm._v(" "+_vm._s(item.total - item.answered))])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"created_at-surgeryType-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(!_vm.hideEditButton)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"black","id":("edit-item-" + index + "-surgeryType-list-button"),"small":""},on:{"click":function($event){return _vm.editSurgeryType(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.hideRemoveButton)?_c('v-icon',{staticClass:"mx-1",attrs:{"id":("remove-item-" + index + "-surgeryType-list-button"),"color":"error","small":""},on:{"click":function($event){return _vm.removeSurgeryType(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }