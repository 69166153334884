<template>
   <div class="d-flex align-center div-wrap">
      <v-row no-gutters>
         <v-col cols="12" sm="5" md="4">
            <v-select
               v-model="userTypeSelected"
               @change="onChangeUserType"
               :items="userTypes"
               label="Tipo de usuário*"
               id="user-type-form-input"
               class="width-user-type"
            >
               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content
                        :id="`item-${attrs.id.split('-')[3]}`"
                     >
                        <v-list-item-title>
                           {{ item.text }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </v-select>
         </v-col>

         <v-col cols="12" sm="7" md="8">
            <v-autocomplete
               v-model="userSelected"
               @change="selectUser"
               :items="userItems"
               :search-input.sync="userSearchFilter"
               :loading="loadingUsers"
               item-text="name"
               item-value="id"
               label="Usuário*"
               return-object
            >
               <template v-slot:no-data>
                  <div class="px-5 py-2">
                     Desculpe, nenhum usuário encontrado.
                  </div>
               </template>

               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content>
                        <v-list-item-title
                           :id="`select-healthunit-item-${
                              attrs.id.split('-')[3]
                           }`"
                        >
                           {{ item.name }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </v-autocomplete>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import snackBarUtil from "utils/snackBarUtil";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import operatorService from "services/operatorService";

export default {
   name: "UserInput",

   data: function () {
      return {
         userSelected: null,
         userSearchFilter: "",
         userItems: [],
         userTimeout: null,
         loadingUsers: false,

         userTypeSelected: null,
         userTypes: [
            { text: "Administrador", value: "admin" },
            { text: "Gestor", value: "manager" },
            { text: "Gestor de unidade de saúde", value: "healthunit_manager" },
            { text: "Gestor de secretaria", value: "secretary_manager" },
            { text: "Gestor regulador", value: "regulator_manager" },
            { text: "Regulador", value: "regulator" },
            { text: "Operador", value: "solicitator" },
         ],
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 20,
            filter: [],
         };

         if (
            this.userSearchFilter &&
            this.userSearchFilter.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: "name",
               query: this.userSearchFilter,
            });

         return query;
      },
   },

   watch: {
      userSearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            this.userItems.find((item) => item.name === newFilter)
         )
            return;

         clearTimeout(this.userTimeout);
         this.userTimeout = setTimeout(() => {
            this.getAllUsers(this.query);
         }, 800);
      },
   },

   methods: {
      selectUser(user) {
         this.$emit("onSelectUser", user);
      },

      onChangeUserType() {
         this.userItems = [];
         this.getAllUsers();
      },

      async getAllUsers(query) {
         this.loadingUsers = true;

         try {
            let response;

            switch (this.userTypeSelected) {
               case "admin":
                  response = await adminService.getAllAdmins(query);
                  break;
               case "manager":
                  response = await managerService.getAllManagers(query);
                  break;
               case "healthunit_manager":
                  response =
                     await healthUnitManagerService.getAllHealthUnitManagers(
                        query
                     );
                  break;
               case "secretary_manager":
                  response =
                     await healthSecretaryManagerService.getAllHealthSecretaryManagers(
                        query
                     );
                  break;
               case "solicitator":
               case "executor":
                  response = await operatorService.getAllOperators(query);
                  break;
               case "regulator_manager":
                  response =
                     await regulatorManagerService.getAllRegulatorManagers(
                        query
                     );
                  break;
               case "regulator":
                  response = await regulatorService.getAllRegulators(query);
                  break;
            }

            this.userItems = this.userItems.concat(
               Object.assign([], response.data)
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingUsers = false;
         }
      },
   },
};
</script>

<style scoped>
.width-user-type {
   margin-right: 12px;
}

@media (max-width: 599px) {
   .width-user-type {
      margin-right: 0px;
   }
}
</style>