import { io } from "socket.io-client";

let singletonInstanceSocketIo = null;

/* Setar URL do Socket.io baseado na variável de ambiente VUE_APP_API_URL */
let hostname = process.env.VUE_APP_API_URL || "VUE_APP_API_URL";
if (hostname !== "")
   hostname = hostname.substring(hostname.lastIndexOf("/") + 1);

function createConnection(token) {
   if (!singletonInstanceSocketIo) {
      const options = {
         query: { token: token },
         path: `/v1/requestsocket`,
         transports: ["websocket"],
         rejectUnauthorized: false
      };
      singletonInstanceSocketIo = io(`wss://${hostname}`, options);
   }
   return singletonInstanceSocketIo;
}

function getConnection() {
   return singletonInstanceSocketIo;
}

function destroyConnection() {
   if (!singletonInstanceSocketIo) return;

   singletonInstanceSocketIo.disconnect();
   singletonInstanceSocketIo = null;
}

export default {
   createConnection,
   getConnection,
   destroyConnection
};
