import FileSaver from "file-saver";
import _ from "lodash";
import formatUtil from "utils/formatUtil";

/**
 * Método para gerar um CSV a partir de dados.
 * @param {*} data: Array com os objetos em JSON.
 * @param {*} headers: Array com o nome das colunas.
 * @returns String em formato CSV.
 */
function generateCSV(data, headers) {
   let headContent = [];
   if (headers && headers.length)
      headContent = [headers.map(item => item.text)];
   else if (data.length)
      for (let [key] of Object.entries(data[0])) headContent.push(key);
   headContent = headContent.join(",");

   /* Constrói o corpo da tabela. */
   let bodyContent = [];
   data.forEach(itemData => {
      const finalItem = [];

      if (headers && headers.length)
         headers.forEach(itemHead => {
            if (itemHead.computed && typeof itemHead.computed === "function")
               finalItem.push(
                  itemHead.computed(_.get(itemData, itemHead.value))
               );
            else finalItem.push(_.get(itemData, itemHead.value));
         });
      else
         for (let [, value] of Object.entries(itemData)) finalItem.push(value);

      bodyContent.push(finalItem.join(","));
   });

   return [headContent, ...bodyContent].join("\r\n");
}

/**
 * Método para salvar localmente o CSV.
 * @param {*} csv: String CSV que vai ser salvo.
 * @param {*} fileName: String com o nome do arquivo que será salvo.
 */
function saveCSV(csv, fileName) {
   const blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
   FileSaver.saveAs(
      blob,
      `${fileName ||
         formatUtil.formatDate(
            new Date(),
            "DD[_]MM[_]YYYY[_]HH[_]mm[_]ss"
         )}.csv`
   );
}

export default {
   generateCSV,
   saveCSV
};
