<template>
   <v-tooltip open-delay="300" transition="slide-y-transition" top>
      <template v-slot:activator="{ on, attrs }">
         <v-card
            v-bind="attrs"
            v-on="on"
            @click="viewBed(bed)"
            :id="`bed-${bed.id}-reserve-button`"
            color="successLight"
            class="
               d-flex
               flex-column
               justify-center
               align-center
               overflow-hidden
               ma-1
            "
            height="65px"
            width="65px"
            flat
         >
            <v-icon color="success" size="38">mdi-bed-empty</v-icon>

            <p class="bed-description" :class="`success--text`">
               {{ bed.code }}
            </p>
         </v-card>
      </template>

      <span>
         <BedTooltipDetails :bed="bed" />
      </span>
   </v-tooltip>
</template>

<script>
import BedTooltipDetails from "../list/BedTooltipDetails";
import formatUtil from "../../../utils/formatUtil";

export default {
   name: "BedReserveListWardBedDetailsCard",

   components: { BedTooltipDetails },

   props: {
      bed: {
         type: Object,
      },
   },

   methods: {
      ...formatUtil,

      viewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },
   },
};
</script>

<style scoped>
.bed-description {
   width: 50px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
   font-size: 0.7rem;
   margin-bottom: 5px !important;
}
</style>