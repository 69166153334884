<template>
   <div class="d-flex justify-center align-center margin-config mb-5">
      <v-icon
         v-text="icon"
         class="d-none d-md-flex"
         color="primary"
         left
         large
      ></v-icon>
      <v-icon class="d-flex d-md-none" color="primary" left>{{ icon }}</v-icon>

      <p
         class="ma-0 primary--text title font-weight-bold"
         :id="`${title
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replaceAll(' ', '-')}-form-title`"
      >
         {{ title }}
      </p>
   </div>
</template>

<script>
export default {
   props: {
      icon: {
         type: String,
         default: "mdi-account",
      },

      title: {
         type: String,
         default: "TITLE",
      },
   },
};
</script>

<style scoped>
.margin-config {
   margin-top: -9px !important;
}

@media (max-width: 959px) {
   .margin-config {
      margin-top: -2px !important;
   }
}
</style>