<template>
   <div>
      <v-dialog
         v-model="dialogSurgeryNotAvaliableVisibility"
         max-width="600"
         transition="slide-y-transition"
         persistent
      >
         <v-alert
            class="ma-0 pa-6 d-flex align-center"
            prominent
            type="error"
            color="warning"
         >
            <div class="d-flex flex-column justify-center align-end">
               <p class="text-justify">
                  Este procedimento cirúrgico não é executado através do
                  <strong>Programa Opera Paraíba</strong>, no momento.
                  Orientamos que seja encaminhado via Secretaria Municipal de
                  Saúde para suas referências, conforme Programação Pactuada
                  Integrada (PPI).
               </p>

               <v-btn @click="agreeWarning" small
                  >Estou ciente
                  <v-icon right small>mdi-check-circle</v-icon>
               </v-btn>
            </div>
         </v-alert>
      </v-dialog>

      <v-fade-transition>
         <div v-if="dialogChatVisibility" class="chat-overlay">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="chat-drawer"
               elevation="24"
               tile
               flat
            >
               <Close @onClickClose="closeChat" dense />

               <Chat
                  @onSendMessage="onSendMessage"
                  :request="request"
                  :disable="disableChat"
                  class="standard-padding flex-grow-1 mt-3"
               />
            </v-card>
         </div>
      </v-fade-transition>

      <v-overlay
         v-model="overlayAttachmentUploadVisibility"
         opacity=".95"
         style="z-index: 1000"
      >
         <AttachmentUploadProgress
            :key="attachmentUploadProgressKey"
            :attachments="attachmentsAdded"
            class="px-5"
         />
      </v-overlay>

      <FormTitle
         v-if="!hideTitle"
         icon="mdi-medical-bag"
         title="SOLICITAÇÃO DE CIRURGIA"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="
            request.status === 'rejected' ||
               request.status === 'rejected_by_executor'
         "
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <v-form v-if="!request.id" ref="form" :readonly="getMode() === 'read'">
         <PatientInput
            v-if="healthUnit && healthUnit.id"
            @onSelectPatient="onSelectPatient"
            ref="patientinput"
         />
      </v-form>

      <RequestSummary class="mb-n3" :request="formRequest" />

      <v-expand-transition>
         <v-card
            v-if="requestPatient && requestPatient.id"
            class="mt-5 standard-padding"
            outlined
         >
            <ViewPatient :patient="requestPatient" />
         </v-card>
      </v-expand-transition>

      <v-form v-if="!request.id" ref="form2">
         <SurgerieInput
            @onSelectSurgerie="onSelectSurgery"
            class="mt-7"
            ref="surgerytypeinput"
            showAvaliableSurgeries
         />
      </v-form>

      <v-expand-transition>
         <v-card
            v-if="requestSurgery && requestSurgery.sus_code"
            class="mt-4 standard-padding"
            outlined
         >
            <ViewSurgery :surgery="requestSurgery" />
         </v-card>
      </v-expand-transition>

      <v-expansion-panels
         v-if="request.status === 'answered' && request.surgery"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               Agendamento da cirurgia
            </v-expansion-panel-header>

            <v-expansion-panel-content class="mt-n1">
               <v-card flat>
                  <ViewSchedule :schedule="request.surgery" />
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-card flat>
         <p class="body-1 font-weight-medium mt-6 mb-3">
            Documentos(s)
            <v-icon>mdi-paperclip</v-icon>
         </p>

         <RequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            :editMode="getMode() === 'save'"
            :hideAddButton="hideEditButton && hideRemoveButton && !!request.id"
            :hideRemoveButton="
               hideEditButton && hideRemoveButton && !!request.id
            "
            ref="requestattachments"
         />

         <div class="mt-4">
            <p v-if="sizeAttachmentValidation" class="caption error--text">
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Os documentos juntos não podem <strong>exceder</strong> 5 MB.
            </p>
         </div>
      </v-card>

      <SurgeryRequestOperatorFormActions
         v-if="!hideActions"
         @onClickPrint="onClickPrintRequest"
         @onClickChat="onClickChatRequest"
         @onClickEdit="onClickEditRequest"
         @onClickRemove="onClickRemoveRequest"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRequest"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :unreadMessages="unreadMessages"
         :loadingSave="loadingSaveRequest"
         :loadingPrint="loadingPrintRequest"
         :validationErrors="validationErrors"
         :hideChatButton="hideChatButton"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import Close from "../../../components/base/Close";
import SurgeryRequestOperatorFormActions from "components/surgeryRequest/form/SurgeryRequestOperatorFormActions";
import Chat from "components/requestBase/chat/Chat";
import AttachmentUploadProgress from "components/attachment/AttachmentUploadProgress";
import RequestAttachments from "components/requestBase/subForm/RequestAttachments";
import ViewPatient from "components/patient/ViewPatient";
import ViewSurgery from "components/surgeryRequest/form/subForm/ViewSurgery";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import PatientInput from "components/patient/PatientInput";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import RequestSummary from "components/surgeryRequest/form/subForm/RequestSummary";
import SurgerieInput from "components/surgerie/SurgerieInput.vue";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import requestSurgeryService from "../../../services/requestSurgeryService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";
import attachmentService from "../../../services/attachmentService";
import requestPdfExport from "export/pdf/requestPdfExport";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";
import { ALLOWED_TYPES, MAXIMUM_SIZE } from "utils/defaultUtil";
import ViewSchedule from "./subForm/ViewSchedule.vue";
import basePdfExport from "export/pdf/basePdfExport";
import socketIoService from "../../../services/socketIoService";

export default {
   name: "SurgeryRequestOperatorForm",

   components: {
      FormTitle,
      Close,
      SurgeryRequestOperatorFormActions,
      Chat,
      AttachmentUploadProgress,
      RequestAttachments,
      ViewPatient,
      ViewSurgery,
      OriginAndDestinyDetails,
      PatientInput,
      RequestSummary,
      SurgerieInput,
      OutcomeDetails,
      JustificationRejection,
      ViewSchedule
   },

   props: {
      request: {
         type: Object
      },

      healthUnit: {
         type: Object
      },

      hideActions: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      hideChatButton: {
         type: Boolean,
         default: false
      },

      hideTitle: {
         type: Boolean,
         default: false
      },

      showDoctorOpinion: {
         type: Boolean,
         default: false
      },

      editMode: {
         type: Boolean,
         default: false
      },

      fullscreenParentDialog: {
         type: Boolean,
         default: false
      }
   },

   data: function() {
      return {
         socketIoConnection: null,

         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",

         formRequest: {},

         loadingSaveRequest: false,
         loadingPrintRequest: false,

         sizeAttachmentValidation: false,
         overlayAttachmentUploadVisibility: false,
         dialogSurgeryNotAvaliableVisibility: false,
         attachmentUploadProgressKey: 0,
         attachmentsAdded: [],

         dialogChatVisibility: false,

         validationErrors: [],

         requestPatient: {},
         requestSurgery: {}
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      unreadMessages() {
         if (this.request.unreadMessages) return this.request.unreadMessages;
         else return 0;
      },

      disableChat() {
         return this.request.is_closed;
      },

      surgerySusCodeComputed() {
         return this.request &&
            this.request.doctor_opinion &&
            this.request.doctor_opinion.sus_code
            ? this.request.doctor_opinion.sus_code
            : null;
      }
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.dialogChatVisibility = false;
            if (this.$refs.patientinput) this.$refs.patientinput.reset();
            if (this.$refs.surgerytypeinput)
               this.$refs.surgerytypeinput.reset();
            this.validationErrors = [];
            this.requestPatient = {};
            this.requestSurgery = {};

            if (request.id) {
               this.requestPatient = Object.assign({}, request.patient);
               this.requestSurgery = Object.assign({}, request.surgery);
            }

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();
         }
      }
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject({}, request);

         if (requestTemp.patient && requestTemp.patient.phone)
            requestTemp.patient.phone = formatUtil.formatPhone(
               requestTemp.patient.phone
            );

         if (requestTemp.patient && requestTemp.patient.cpf)
            requestTemp.patient.cpf = formatUtil.formatCPF(
               requestTemp.patient.cpf
            );

         if (requestTemp.patient && requestTemp.patient.birth_date)
            requestTemp.patient.birth_date = formatUtil.formatDate(
               requestTemp.patient.birth_date
            );

         return requestTemp;
      },

      formatObjectToSend(request, removeEmptyStrings) {
         let requestTemp = util.removeBlankAttributes(request, {
            emptyStrings: removeEmptyStrings
         });

         if (requestTemp.patient && requestTemp.patient.phone)
            requestTemp.patient.phone = requestTemp.patient.phone.replace(
               /[^0-9]/g,
               ""
            );

         if (requestTemp.patient && requestTemp.patient.cpf)
            requestTemp.patient.cpf = requestTemp.patient.cpf.replace(
               /[^0-9]/g,
               ""
            );

         if (requestTemp.patient && requestTemp.patient.birth_date)
            requestTemp.patient.birth_date = formatUtil.formatDate(
               requestTemp.patient.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return requestTemp;
      },

      onClickChatRequest() {
         this.openChat();
      },

      onClickEditRequest() {
         this.setMode("save");
      },

      onClickRemoveRequest() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formRequest = this.formatObjectToView(this.request);
         this.$emit("onClickCancel");
      },

      onClickSaveRequest() {
         if (
            this.healthUnit &&
            this.healthUnit.id &&
            this.healthUnit.municipality_id
         ) {
            this.formRequest.sourcehealthentity_id = this.healthUnit.id;
            this.formRequest.municipality_id = this.healthUnit.municipality_id;

            if (this.formRequest.id)
               this.updateHealthUnitRequest(
                  this.healthUnit.id,
                  this.formRequest.id,
                  this.formRequest
               );
            else
               this.createHealthUnitRequest(
                  this.healthUnit.id,
                  this.formRequest
               );
         }
      },

      async onClickPrintRequest() {
         this.loadingPrintRequest = true;

         try {
            const userResponse = await this.getUserById(this.userStore);

            const pdf = requestPdfExport.generateSurgeryRequestPDF(
               this.formRequest,
               userResponse.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrintRequest = false;
         }
      },

      async getUserById(user) {
         let response;

         switch (user.sub_type) {
            case "admin":
               response = await adminService.getAdminById(user.sub);
               break;
            case "manager":
               response = await managerService.getManagerById(user.sub);
               break;
            case "healthunit_manager":
               response = await healthUnitManagerService.getHealthUnitManagerById(
                  user.sub
               );
               break;
            case "secretary_manager":
               response = await healthSecretaryManagerService.getHealthSecretaryManagerById(
                  user.sub
               );
               break;
            case "solicitator":
            case "executor":
               response = await operatorService.getOperatorById(user.sub);
               break;
            case "regulator_manager":
               response = await regulatorManagerService.getRegulatorManagerById(
                  user.sub
               );
               break;
            case "regulator":
               response = await regulatorService.getRegulatorById(user.sub);
               break;
         }

         return response;
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidationAttachments() {
         this.sizeAttachmentValidation = false;

         let totalSize;

         /* Verificação se todos os arquivos são menores que 5 MB. */
         if (this.$refs.requestattachments)
            totalSize = this.$refs.requestattachments
               .getAttachments()
               .reduce(
                  (previousValue, currentValue) =>
                     previousValue + currentValue.content.size,
                  0
               );

         const validationResult = totalSize > MAXIMUM_SIZE;

         setTimeout(() => {
            this.sizeAttachmentValidation = totalSize > MAXIMUM_SIZE;
         }, 300);

         return !validationResult;
      },

      executeValidation() {
         if (this.$refs.form && this.$refs.form2) {
            this.validationErrors = this.$refs.form.inputs
               .filter(item => item.hasError && item.label)
               .map(item => item.label.replace(/\*/g, ""));

            this.validationErrors = this.$refs.form2.inputs
               .filter(item => item.hasError && item.label)
               .map(item => item.label.replace(/\*/g, ""))
               .concat(this.validationErrors);

            const validationResult1 = this.$refs.form.validate();
            const validationResult2 = this.$refs.form2.validate();

            if (validationResult1 && validationResult2)
               this.validationErrors = [];

            return validationResult1 && validationResult2;
         } else return false;
      },

      resetAttachmentValidation() {
         this.sizeAttachmentValidation = false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
         this.resetAttachmentValidation();
      },

      forceAttachmentUploadProgressRerender() {
         this.attachmentUploadProgressKey += 1;
      },

      openChat() {
         this.dialogChatVisibility = true;
         this.$emit("onOpenChat");
      },

      closeChat() {
         this.dialogChatVisibility = false;
         this.$emit("onCloseChat");
      },

      onSendMessage(request, message) {
         this.$emit("onSendMessage", request, message);
      },

      openDialogSurgeryNotAvaliable() {
         this.dialogSurgeryNotAvaliableVisibility = true;
      },

      closeDialogSurgeryNotAvaliable() {
         this.dialogSurgeryNotAvaliableVisibility = false;
      },

      onSelectPatient(patient) {
         this.formRequest.patient_id = patient.id;
         this.requestPatient = patient;
      },

      onSelectSurgery(surgery) {
         if (this.formRequest.doctor_opinion)
            this.formRequest.doctor_opinion.sus_code = surgery.sus_code;
         else {
            this.formRequest = Object.assign(this.formRequest, {
               doctor_opinion: {
                  sus_code: surgery.sus_code
               }
            });
         }

         this.formRequest;

         this.requestSurgery = surgery;
      },

      async createHealthUnitRequest(healthUnitId, request) {
         if (
            !this.executeValidation() ||
            !this.executeValidationAttachments() ||
            !healthUnitId ||
            !request
         )
            return;

         this.loadingSaveRequest = true;
         try {
            /* 1. Salva a solicitação */
            const response = await requestSurgeryService.createHealthUnitSurgeryRequest(
               healthUnitId,
               this.formatObjectToSend(request)
            );

            /* 2. Salva os anexos */
            if (response.data && response.data.id) {
               this.socketIoConnection.emit("new_request", response.data);

               if (this.$refs.requestattachments) {
                  {
                     this.attachmentsAdded = this.$refs.requestattachments
                        .getAttachments()
                        .filter(item => item.raw);

                     if (this.attachmentsAdded.length) {
                        this.overlayAttachmentUploadVisibility = true;

                        for (
                           let index = 0;
                           index < this.attachmentsAdded.length;
                           index++
                        ) {
                           const item = this.attachmentsAdded[index];

                           let attachment = new FormData();
                           attachment.append(
                              "attachment",
                              item.content.originalData
                           );

                           await attachmentService
                              .createRequestAttachment(
                                 response.data.id,
                                 attachment,
                                 event => {
                                    let progress = Math.round(
                                       (event.loaded * 97) / event.total
                                    );

                                    this.attachmentsAdded[index].upload = {
                                       progress: progress
                                    };
                                    this.forceAttachmentUploadProgressRerender();
                                 }
                              )
                              .then(
                                 () =>
                                    (this.attachmentsAdded[index].upload = {
                                       success: true
                                    })
                              )
                              .catch(
                                 () =>
                                    (this.attachmentsAdded[index].upload = {
                                       error: true
                                    })
                              )
                              .finally(() =>
                                 this.forceAttachmentUploadProgressRerender()
                              );
                        }
                     }
                  }
               }
            }

            if (this.overlayAttachmentUploadVisibility) await util.sleep(1000);

            this.overlayAttachmentUploadVisibility = false;

            if (response && response.data && !response.data.is_available)
               this.openDialogSurgeryNotAvaliable();
            else {
               snackBarUtil.showCustomSnackBar({
                  color: "success",
                  title: "SUCESSO!",
                  message: "A solicitação foi cadastrada!"
               });

               this.setMode("read");
               this.$emit("onCreatedRequest");
            }
         } catch (error) {
            if (
               error.response &&
               error.response.status === 400 &&
               error.response.data.message ===
                  "SurgeryRequest cannot be registered!"
            )
               snackBarUtil.showCustomSnackBar({
                  color: "info",
                  icon: "mdi-file-document-multiple-outline",
                  title: "SOLICITAÇÃO JÁ CADASTRADA!",
                  message:
                     "Uma solicitação para esse paciente com essa cirurgia já foi cadastrada!"
               });
            else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      },

      async updateHealthUnitRequest(healthUnitId, requestId, request) {
         if (
            !this.executeValidationAttachments() ||
            !healthUnitId ||
            !requestId ||
            !request
         )
            return;

         this.loadingSaveRequest = true;

         try {
            /* 1. Salva os anexos */
            if (this.$refs.requestattachments && requestId) {
               this.attachmentsAdded = this.$refs.requestattachments
                  .getAttachments()
                  .filter(item => item.raw);

               if (this.attachmentsAdded.length) {
                  this.overlayAttachmentUploadVisibility = true;

                  for (
                     let index = 0;
                     index < this.attachmentsAdded.length;
                     index++
                  ) {
                     const item = this.attachmentsAdded[index];

                     let attachment = new FormData();
                     attachment.append("attachment", item.content.originalData);

                     await attachmentService
                        .createRequestAttachment(
                           requestId,
                           attachment,
                           event => {
                              let progress = Math.round(
                                 (event.loaded * 97) / event.total
                              );

                              this.attachmentsAdded[index].upload = {
                                 progress: progress
                              };
                              this.forceAttachmentUploadProgressRerender();
                           }
                        )
                        .then(
                           () =>
                              (this.attachmentsAdded[index].upload = {
                                 success: true
                              })
                        )
                        .catch(
                           () =>
                              (this.attachmentsAdded[index].upload = {
                                 error: true
                              })
                        )
                        .finally(() =>
                           this.forceAttachmentUploadProgressRerender()
                        );
                  }
               }
            }

            if (this.overlayAttachmentUploadVisibility) await util.sleep(1000);

            this.overlayAttachmentUploadVisibility = false;

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi atualizada!"
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      },

      agreeWarning() {
         this.closeDialogSurgeryNotAvaliable();
         snackBarUtil.showCustomSnackBar({
            color: "success",
            title: "SUCESSO!",
            message: "A solicitação foi cadastrada!"
         });
         this.setMode("read");
         this.$emit("onCreatedRequest");
      }
   },
   async created() {
      switch (this.userStore.sub_type) {
         case "solicitator":
            this.socketIoConnection = socketIoService.getConnection();
            break;
      }
   }
};
</script>

<style scoped>
.chat-overlay {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}
</style>
