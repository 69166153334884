import axiosService from "./axiosService";

/* 
   healthUnitId: String com o ID da unidade de saúde;
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Array com objeto ou objetos compostos por dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getAllHealthUnitSurgeryRequests(healthUnitId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/healthentities/${healthUnitId}/surgeryrequests?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      if (!query.filter.some(item => !item.attribute || !item.query)) {
         query.filter.forEach((item, index) => {
            url += `&${item.attribute}=`;
            switch (item.attribute) {
               case "bed_id":
               case "responsibles":
               case "status":
               case "is_closed":
               case "sourcehealthentity_id":
               case "municipality_id":
               case "is_available":
               case "patient_id":
               case "was_created_by_patient":
               case "is_checked":
               case "is_archived":
               case "created_at":
                  url += `${item.query}`;
                  break;
               default:
                  url += `*${item.query}*`;
                  break;
            }

            if (index < query.filter.length - 1) url += "&";
         });
      }
   }

   return await axiosService.get(url);
}

async function createHealthUnitSurgeryRequest(healthUnitId, request) {
   return await axiosService.post(
      `/healthentities/${healthUnitId}/surgeryrequests`,
      request
   );
}

async function updateHealthUnitSurgeryRequest(
   healthUnitId,
   requestId,
   request
) {
   return await axiosService.patch(
      `/healthentities/${healthUnitId}/surgeryrequests/${requestId}`,
      request
   );
}

async function updateHealthUnitSurgeryRequestStatus(
   healthUnitId,
   requestId,
   status
) {
   return await axiosService.put(
      `/healthentities/${healthUnitId}/surgeryrequests/${requestId}/status`,
      status
   );
}

async function updateHealthUnitSurgeryRequestOutcome(
   healthUnitId,
   requestId,
   outcome
) {
   return await axiosService.put(
      `/healthentities/${healthUnitId}/surgeryrequests/${requestId}/outcome`,
      outcome
   );
}

/* 
   healthUnitId: String com o ID da unidade de saúde;
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getAllSurgeryRequests(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/surgeryrequests?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      if (!query.filter.some(item => !item.attribute || !item.query)) {
         query.filter.forEach(item => {
            url += `&${item.attribute}=`;
            switch (item.attribute) {
               case "bed_id":
               case "responsibles":
               case "status":
               case "is_closed":
               case "surgery_id":
               case "sourcehealthentity_id":
               case "municipality_id":
               case "is_available":
               case "patient_id":
               case "was_created_by_patient":
               case "is_checked":
               case "is_archived":
               case "created_at":
                  url += `${item.query}`;
                  break;
               default:
                  url += `*${item.query}*`;
                  break;
            }
         });
      }
   }

   return await axiosService.get(url);
}

async function updateSurgeryRequest(requestId, request) {
   return await axiosService.patch(`/surgeryrequests/${requestId}`, request);
}

async function updateSurgeryRequestStatus(requestId, request) {
   return await axiosService.put(
      `/surgeryrequests/${requestId}/status`,
      request
   );
}

async function checkSurgeryRequest(requestId) {
   return await axiosService.post(`/requests/${requestId}/check`);
}

export default {
   getAllHealthUnitSurgeryRequests,
   createHealthUnitSurgeryRequest,
   updateHealthUnitSurgeryRequest,

   getAllSurgeryRequests,
   updateSurgeryRequest,

   updateHealthUnitSurgeryRequestStatus,
   updateHealthUnitSurgeryRequestOutcome,
   updateSurgeryRequestStatus,

   checkSurgeryRequest
};
