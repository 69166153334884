var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.filter === 'municipality_id')?_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"800","transition":"slide-y-transition","persistent":""},model:{value:(_vm.dialogSelectMunicipalityVisibility),callback:function ($$v) {_vm.dialogSelectMunicipalityVisibility=$$v},expression:"dialogSelectMunicipalityVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599)}},[_c('SelectMunicipality',{staticClass:"standard-padding",attrs:{"alertMessage":"Selecione o município que deseja visualizar as solicitações.","loadMunicipalitiesOnCreated":false},on:{"onSelectMunicipality":_vm.onSelectMunicipality}})],1)],1):_vm._e(),_c('div',[(_vm.date)?_c('v-alert',{staticClass:"mb-5",attrs:{"color":"primary","type":"info","icon":"mdi-calendar","border":"left","dense":"","text":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center ma-n2"},[_c('p',{staticClass:"ma-2 body-2"},[_vm._v(" Você está visualizando as solicitações do dia "),_c('strong',[_vm._v(_vm._s(_vm.date .map(function (item) { return _vm.formatDate(item, "MMMM[/]YY"); }) .join(" até ")))]),_vm._v(". ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"ma-2"},[_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"color":"primary","id":"change-filter-date-request","x-small":"","text":""}},'v-btn',attrs,false),on),[_vm._v("Alterar data ")]),_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex d-md-none",attrs:{"color":"primary","id":"change-filter-date-request","fab":"","text":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1)],1)]}}],null,false,2532356081)},[_c('v-date-picker',{attrs:{"value":_vm.date,"type":"month","locale":"pt-br","multiple":"","range":"","no-title":""},on:{"input":_vm.onChangeDate}})],1)],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"slide-y-transition","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex d-md-none",attrs:{"color":"primary","id":_vm.userInfo
                           ? ("filter-" + _vm.userInfo + "-secondary-list-header-button")
                           : 'filter-secondary-list-header-button',"fab":"","depressed":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"color":"primary","id":_vm.userInfo
                           ? ("filter-" + _vm.userInfo + "-primary-list-header-button")
                           : 'filter-primary-list-header-button',"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" FILTRO "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filterMenuVisibility),callback:function ($$v) {_vm.filterMenuVisibility=$$v},expression:"filterMenuVisibility"}},[_c('v-card',{staticClass:"standard-padding-x-small",attrs:{"disabled":_vm.loading}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary","small":"","left":""}},[_vm._v("mdi-filter")]),_c('p',{staticClass:"caption font-weight-bold primary--text"},[_vm._v(" Filtrar por ")])],1),_c('v-chip-group',{attrs:{"value":_vm.filter,"mandatory":"","column":""},on:{"change":_vm.changeFilter}},_vm._l((_vm.filters),function(item,index){return _c('v-chip',{key:item.filter,attrs:{"color":_vm.filter === item.filter ? 'primary' : null,"value":item.filter,"id":("filter-item" + index + "-list-header-chip"),"filter":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),_c('div',{staticClass:"d-flex align-center mt-1"},[_c('v-icon',{attrs:{"color":"primary","small":"","left":""}},[_vm._v("mdi-sort")]),_c('p',{staticClass:"caption font-weight-bold primary--text"},[_vm._v(" Listar por ")])],1),_c('v-chip-group',{staticClass:"pa-0",attrs:{"value":_vm.sort,"mandatory":"","column":""},on:{"change":_vm.changeSort}},_vm._l((_vm.sorts),function(item,index){return _c('v-chip',{key:item.sort,attrs:{"color":_vm.sort === item.sort ? 'primary' : null,"value":item.sort,"id":("sort-item" + index + "-list-header-chip"),"filter":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1),(_vm.filter === 'patient_id')?_c('div',{staticClass:"d-flex align-center ml-4"},[_c('PatientInput',{ref:"patientinput",staticClass:"search-bar-inputs body-2",attrs:{"required":false,"loadPatientsOnCreated":""},on:{"onSelectPatient":_vm.onSelectPatient}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.clearFilterPatient();
                           _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,988417641)},[_c('p',[_vm._v("Limpar filtro")])])],1):(_vm.filter === 'sourcehealthentity_id')?_c('div',{staticClass:"d-flex align-center ml-4"},[_c('HealthUnitInput',{ref:"healthunitinput",staticClass:"search-bar-inputs body-2",attrs:{"required":false,"dense":""},on:{"onSelectHealthUnit":_vm.onSelectHealthUnit}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.clearFilterHealthUnit();
                           _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('p',[_vm._v("Limpar filtro")])])],1):(_vm.filter === 'municipality_id')?_c('div',{staticClass:"d-flex align-center ml-4"},[_c('v-text-field',{staticClass:"search-bar-inputs body-2",attrs:{"placeholder":"Selecione o município...","append-icon":"mdi-map-marker","readonly":""},on:{"click":_vm.openSelectMunicipality},model:{value:(_vm.municipalitySelected),callback:function ($$v) {_vm.municipalitySelected=$$v},expression:"municipalitySelected"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.clearFilterMunicipality();
                           _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('p',[_vm._v("Limpar filtro")])])],1):(_vm.filter === 'status&is_checked')?_c('div',{staticClass:"d-flex align-center mr-n1 my-n1 ml-3 flex-wrap"},[_c('v-select',{staticClass:"search-bar-status body-2 ma-1",attrs:{"items":_vm.statusByCheckedItems,"placeholder":"Estado","return-object":""},on:{"change":_vm.statusByCheckedOnSelectStatus},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                           var item = ref.item;
return [_c('p',{staticClass:"label-selection font-weight-bold",class:((item.color) + "--text")},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
                           var item = ref.item;
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{class:((item.color) + "--text")},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}]),model:{value:(_vm.statusByCheckedSelected),callback:function ($$v) {_vm.statusByCheckedSelected=$$v},expression:"statusByCheckedSelected"}}),_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.statusByCheckedIsCheckComputed.color},on:{"click":function($event){return _vm.statusByCheckedOnSelectIsChecked(_vm.statusByCheckedIsCheck)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.statusByCheckedIsCheckComputed.icon)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.statusByCheckedIsCheckComputed.text)+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.statusByCheckedClearFilter();
                           _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('p',[_vm._v("Limpar filtro")])])],1):_c('div',{staticClass:"d-flex align-center ma-n1 w-100"},[_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.filterMask),expression:"filterMask"}],staticClass:"search-bar body-2",attrs:{"placeholder":("Digite o(a) " + _vm.filterText + " do " + _vm.filterActor + "..."),"append-icon":"mdi-magnify","maxlength":_vm.filterMaxLength,"id":_vm.userInfo
                        ? ("filter-" + _vm.userInfo + "-by-list-header-input")
                        : 'filter-by-list-header-input'},on:{"click:append":function($event){_vm.filterBy && _vm.filterBy !== '' ? _vm.searchItems() : null}},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}}),_c('v-tooltip',{attrs:{"value":_vm.filterBy !== '' ? true : false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.filterBy = null;
                           _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('p',[_vm._v("Limpar filtro")])])],1)],1),(!_vm.hideAddButton)?_c('div',[_c('v-btn',{staticClass:"d-flex d-md-none",attrs:{"color":"primary","id":"add-secondary-list-header-button","fab":"","depressed":"","x-small":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"color":"primary","id":"add-primary-list-header-button","depressed":""},on:{"click":_vm.add}},[_vm._v(" ADICIONAR "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }