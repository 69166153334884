<template>
   <div class="standard-padding">
      <LoadingData v-if="loadingAudits" style="height: 100vh" />

      <div v-else>
         <span class="timeline-line"></span>

         <v-timeline class="timeline-config" dense>
            <v-timeline-item
               :small="isSmallerThan(959)"
               class="mt-13"
               icon="mdi-check"
               color="grey"
               fill-dot
            >
               <div class="d-flex align-center">
                  <p class="grey--text font-weight-medium ml-n3 mr-3">
                     Hoje, {{ formatDate(new Date(), "DD/MM/YYYY") }}
                  </p>

                  <v-btn
                     @click="print"
                     :loading="loadingPrint"
                     class="shadow-floating-primary-button d-none d-sm-flex ma-1"
                     color="primary"
                     id="print-history-button"
                     depressed
                     dark
                     small
                     >Imprimir
                     <v-icon right small>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn
                     @click="print"
                     :loading="loadingPrint"
                     class="shadow-floating-primary-button d-flex d-sm-none ma-1"
                     color="primary"
                     id="print_small-history-button"
                     fab
                     dark
                     depressed
                     x-small
                  >
                     <v-icon small>mdi-printer</v-icon>
                  </v-btn>
               </div>
            </v-timeline-item>

            <NoData
               v-if="!events.length"
               class="mt-5 ml-15"
               message="Nenhum histórico encontrado..."
            />

            <v-timeline-item
               v-else
               v-for="(item, index) in events"
               :small="isSmallerThan(959)"
               :key="index"
               :icon="item.icon"
               :color="item.color"
               class="mt-2"
               fill-dot
               right
            >
               <div class="ml-n3" flat>
                  <p class="font-weight-medium" :class="`${item.color}--text`">
                     {{ item.title }}
                  </p>

                  <p class="body-2 mt-2">
                     <strong>{{ translateUserType(item.user.type) }}</strong>
                     {{ item.user.name }}, {{ item.text }}
                  </p>

                  <div
                     v-if="item.content && item.content.length"
                     class="event-content standard-padding-tiny mt-2 mb-1"
                  >
                     <div
                        v-for="(content, index) in item.content"
                        :key="index"
                        class="d-flex align-center"
                     >
                        <p class="caption mr-1">
                           <strong>{{ content.field }}: </strong>
                           {{ content.value }}
                        </p>
                     </div>
                  </div>

                  <p class="caption font-weight-medium text--secondary mt-2">
                     {{ formatDate(item.date, "DD/MM/YYYY [às] HH:mm") }}
                  </p>
               </div>
            </v-timeline-item>
         </v-timeline>
      </div>
   </div>
</template>

<script>
import NoData from "components/base/NoData";
import LoadingData from "components/base/LoadingData";
import formatUtil from "utils/formatUtil";
import responsiveUtil from "utils/responsiveUtil";
import auditService from "services/auditService";
import snackBarUtil from "utils/snackBarUtil";
import util from "utils/util";
import requestPdfExport from "export/pdf/requestPdfExport";
import { REQUEST } from "utils/translateUtil";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import basePdfExport from "export/pdf/basePdfExport";

export default {
   name: "History",

   components: { LoadingData, NoData },

   props: {
      request: {
         type: Object,
         default: () => ({}),
      },
   },

   data: function () {
      return {
         events: [],
         rawAudit: [],

         loadingAudits: false,
         loadingPrint: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 100,
            sort: "-created_at",
            filter: [],
         };

         query.filter.push(
            {
               attribute: "request.operator",
               query: "POST,PATCH",
            },
            {
               attribute: "request.path",
               query: this.request.id,
            },
            {
               attribute: "success",
               query: "yes",
            }
         );

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...formatUtil,
      ...responsiveUtil,

      async print() {
         this.loadingPrint = true;

         try {
            let response;
            switch (this.userStore.sub_type) {
               case "admin":
                  response = await adminService.getAdminById(
                     this.userStore.sub
                  );
                  break;
               case "manager":
                  response = await managerService.getManagerById(
                     this.userStore.sub
                  );
                  break;
               case "healthunit_manager":
                  response =
                     await healthUnitManagerService.getHealthUnitManagerById(
                        this.userStore.sub
                     );
                  break;
               case "solicitator":
               case "executor":
                  response = await operatorService.getOperatorById(
                     this.userStore.sub
                  );
                  break;
               case "regulator_manager":
                  response =
                     await regulatorManagerService.getRegulatorManagerById(
                        this.userStore.sub
                     );
                  break;
               case "regulator":
                  response = await regulatorService.getRegulatorById(
                     this.userStore.sub
                  );
                  break;
            }

            const pdf = requestPdfExport.generateHistoryRequestPDF(
               this.events,
               this.request,
               response.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Histórico da solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrint = false;
         }
      },

      async getRequestPost() {
         const queryGetRequestPost = {
            page: 1,
            limit: 1,
            filter: [
               {
                  attribute: "request.operator",
                  query: "POST",
               },
               {
                  attribute: "response.body.id",
                  query: this.request.id,
               },
               {
                  attribute: "success",
                  query: "yes",
               },
            ],
         };

         const response = await auditService.getAllAudits(queryGetRequestPost);
         return response.data;
      },

      async getRequestEvents() {
         const queryGetRequestEvents = {
            page: 1,
            limit: 100,
            sort: "-created_at",
            filter: [
               {
                  attribute: "request.operator",
                  query: "POST,PATCH,PUT,DELETE",
               },
               {
                  attribute: "request.path",
                  query: this.request.id,
               },
               {
                  attribute: "success",
                  query: "yes",
               },
            ],
         };

         const response = await auditService.getAllAudits(
            queryGetRequestEvents
         );
         return response.data;
      },

      translateUserType(type) {
         let result = "";

         switch (type) {
            case "regulator_manager":
               result = "Gestor regulador";
               break;
            case "regulator":
               result = "Regulador";
               break;
            case "requester":
               result = "Solicitante";
               break;
            case "executor":
               result = "Executante";
               break;
         }

         return result;
      },

      generateStatusEvent(event) {
         let color = "light-green";
         let title = "reserva do leito";
         let text = "a reserva do leito solicitado para o paciente.";
         let content;

         if (event.request.body.status === "answered") {
            title = `Confirmou ${title}`;
            text = `confirmou ${text}`;
         } else if (event.request.body.status === "rejected") {
            color = "red";
            title = `Rejeitou ${title}`;
            text = `rejeitou ${text}`;

            if (event.request.body.status_justification)
               content = this.mountEventContent(event.request.body, REQUEST);
         }

         return {
            color: color,
            icon: "mdi-bed",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: title,
            text: text,
            content: content,
            date: event.date,
         };
      },

      generateBedReserveEvent(event) {
         return {
            color: "blue",
            icon: "mdi-bed",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: "Reservou leito",
            text: "realizou a reserva do leito.",
            date: event.date,
         };
      },

      generateOutcomeEvent(event) {
         return {
            color: "black",
            icon: "mdi-logout",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: "Efetuou desfecho",
            text: "efetuou o desfecho do paciente.",
            content: this.mountEventContent(event.request.body, REQUEST),
            date: event.date,
         };
      },

      generateAttachmentsEvent(event) {
         let title = "um anexo";
         let text = "um anexo a solicitação.";

         if (event.request.operator === "POST") {
            title = `Adicionou ${title}`;
            text = `adicionou ${text}`;
         } else {
            title = `Removeu ${title}`;
            text = `removeu ${text}`;
         }

         return {
            color: "deep-purple",
            icon: "mdi-paperclip",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: title,
            text: text,
            date: event.date,
         };
      },

      generateAddEvent(event) {
         return {
            color: "primary",
            icon: "mdi-plus",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: "Adicionou a solicitação",
            text: "adicionou a solicitação.",
            date: event.date,
         };
      },

      generateEditEvent(event) {
         return {
            color: "orange",
            icon: "mdi-pencil",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: "Editou a solicitação",
            text: "editou a solicitação.",
            content: this.mountEventContent(event.difference, REQUEST),
            date: event.date,
         };
      },

      generateDoctorOpinionEvent(event) {
         const doctorOpinionInrequest = {
            doctor_opinion: { ...event.request.body.doctor_opinion },
         };

         return {
            color: "teal",
            icon: "mdi-doctor",
            user: {
               name: event.user.name,
               type:
                  event.user.type === "operator"
                     ? event.user.role
                     : event.user.type,
            },
            title: "Adicionou/Editou o parecer",
            text: "adicionou ou editou o parecer médico.",
            content: this.mountEventContent(doctorOpinionInrequest, REQUEST),
            date: event.date,
         };
      },

      mountEventContent(object, translations) {
         let translatedContent = [];

         function goToLeaves(attribute, translations) {
            Object.keys(attribute).forEach((key) => {
               if (typeof attribute[key] === "object")
                  return goToLeaves(attribute[key], translations[key] || {});

               if (translations[key]) {
                  if (translations[key].valuesTranslated)
                     translatedContent.push({
                        field: translations[key].fieldTranslated,
                        value: translations[key].valuesTranslated[
                           attribute[key]
                        ],
                     });
                  else
                     translatedContent.push({
                        field: translations[key],
                        value: attribute[key],
                     });
               }
            });
         }

         goToLeaves(object, translations);

         return translatedContent;
      },

      mountTimeline(audits) {
         let result = [];
         let changeRequestData = [];

         audits.forEach((item) => {
            if (item.request.path.includes("status")) {
               result.push(this.generateStatusEvent(item));
            } else if (item.request.path.includes("outcome")) {
               result.push(this.generateOutcomeEvent(item));
            } else if (item.request.path.includes("attachments")) {
               result.push(this.generateAttachmentsEvent(item));
            } else if (item.request.path.includes("healthunits")) {
               if (item.request.operator === "POST")
                  result.push(this.generateAddEvent(item));
               changeRequestData.push(item);
            } else {
               if (item.request.body && item.request.body.bed_id)
                  result.push(this.generateBedReserveEvent(item));
               else if (item.request.body && item.request.body.doctor_opinion)
                  result.push(this.generateDoctorOpinionEvent(item));
            }
         });

         /* Ordena os PATCHs para que a diferença entre eles possa ser calculada */
         changeRequestData.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
         });

         for (let index = 0; index < changeRequestData.length - 1; index++) {
            if (
               !(
                  changeRequestData[index + 1].response.body &&
                  changeRequestData[index].response.body
               )
            )
               continue;

            /* Calcula a diferença entre os bodys dos PATCHs */
            const difference = util.objectDifference(
               changeRequestData[index + 1].response.body,
               changeRequestData[index].response.body
            );

            /* Filtra os dados que realmente tem importância a nível de auditoria,
            com isso remove atributos como updated_at, operators, etc. */
            const finalDifference = {
               clinical_data: { ...difference.clinical_data },
            };

            /* Caso tenha algum atributo, acrescenta um evento de edição ao array final de ações */
            if (util.countLeavesAttributes(finalDifference) > 0) {
               changeRequestData[index + 1].difference = finalDifference;
               result.push(
                  this.generateEditEvent(changeRequestData[index + 1])
               );
            }
         }

         /* Ordena o array de ações final */
         result.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
         });

         return result;
      },
   },

   async created() {
      if (this.request && this.request.id) {
         this.loadingAudits = true;

         try {
            this.rawAudit = [];
            this.events = [];

            const requestPost = await this.getRequestPost();
            this.rawAudit = this.rawAudit.concat(requestPost);

            const requestEvents = await this.getRequestEvents();
            this.rawAudit = this.rawAudit.concat(requestEvents);

            this.events = this.mountTimeline(this.rawAudit);
         } catch (error) {
            this.rawAudit = [];
            this.events = [];
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingAudits = false;
         }
      }
   },
};
</script>

<style scoped>
.timeline-line {
   position: absolute;
   margin-left: 27px;
   height: 100vh;
   width: 2px;
   background: rgba(0, 0, 0, 0.15);
}

@media (max-width: 959px) {
   .timeline-line {
      margin-left: 12px;
   }
}

.timeline-config {
   height: 100vh;
   margin-left: -20px;
   color: rgba(0, 0, 0, 0.87);
   overflow-y: scroll;
}

@media (max-width: 959px) {
   .timeline-config {
      margin-left: -35px;
   }
}

.event-content {
   display: inline-block;
   background: #efefef;
   border-radius: 4px;
   max-width: 600px;
}

.code-container {
   background-color: transparent !important;
   display: block;
   white-space: pre-wrap;
   padding: 0px;
}

::v-deep::-webkit-scrollbar {
   width: 0px;
   background-color: white;
}

::v-deep::-webkit-scrollbar-thumb {
   background-color: #c2c2c2;
}

::v-deep .theme--dark.v-timeline::before {
   background: transparent !important;
}

::v-deep .theme--light.v-timeline::before {
   background: transparent !important;
}

::v-deep .theme--dark.v-timeline .v-timeline-item__dot {
   background: white;
   box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

::v-deep .v-timeline-item__divider {
   pointer-events: none !important;
}

/* ::v-deep .v-icon {
   font-size: 14px !important;
} */
</style>