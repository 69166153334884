<template>
   <div>
      <v-dialog
         v-if="filter === 'municipality_id'"
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               @onSelectMunicipality="onSelectMunicipality"
               class="standard-padding"
               alertMessage="Selecione o município que deseja visualizar as solicitações."
               :loadMunicipalitiesOnCreated="false"
            />
         </v-card>
      </v-dialog>

      <div>
         <v-alert
            v-if="date"
            class="mb-5"
            color="primary"
            type="info"
            icon="mdi-calendar"
            border="left"
            dense
            text
         >
            <div class="d-flex justify-space-between align-center ma-n2">
               <p class="ma-2 body-2">
                  Você está visualizando as solicitações do dia
                  <strong>{{
                     date
                        .map((item) => formatDate(item, "MMMM[/]YY"))
                        .join(" até ")
                  }}</strong
                  >.
               </p>

               <v-menu :close-on-content-click="false" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                     <div class="ma-2">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-none d-md-flex"
                           color="primary"
                           id="change-filter-date-request"
                           x-small
                           text
                           >Alterar data
                        </v-btn>

                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-flex d-md-none"
                           color="primary"
                           id="change-filter-date-request"
                           fab
                           text
                           x-small
                        >
                           <v-icon>mdi-calendar-edit</v-icon>
                        </v-btn>
                     </div>
                  </template>

                  <v-date-picker
                     @input="onChangeDate"
                     :value="date"
                     type="month"
                     locale="pt-br"
                     multiple
                     range
                     no-title
                  >
                  </v-date-picker>
               </v-menu>
            </div>
         </v-alert>

         <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center flex-grow-1">
               <v-menu
                  v-model="filterMenuVisibility"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  close-on-click
                  offset-y
               >
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex d-md-none"
                        color="primary"
                        :id="
                           userInfo
                              ? `filter-${userInfo}-secondary-list-header-button`
                              : 'filter-secondary-list-header-button'
                        "
                        fab
                        depressed
                        x-small
                     >
                        <v-icon>mdi-filter</v-icon>
                     </v-btn>

                     <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="d-none d-md-flex"
                        color="primary"
                        :id="
                           userInfo
                              ? `filter-${userInfo}-primary-list-header-button`
                              : 'filter-primary-list-header-button'
                        "
                        outlined
                     >
                        FILTRO
                        <v-icon right>mdi-filter</v-icon>
                     </v-btn>
                  </template>

                  <v-card :disabled="loading" class="standard-padding-x-small">
                     <div class="d-flex align-center">
                        <v-icon color="primary" small left>mdi-filter</v-icon>

                        <p class="caption font-weight-bold primary--text">
                           Filtrar por
                        </p>
                     </div>

                     <v-chip-group
                        @change="changeFilter"
                        :value="filter"
                        mandatory
                        column
                     >
                        <v-chip
                           v-for="(item, index) in filters"
                           :key="item.filter"
                           :color="filter === item.filter ? 'primary' : null"
                           :value="item.filter"
                           :id="`filter-item${index}-list-header-chip`"
                           filter
                           small
                        >
                           {{ item.text }}
                        </v-chip>
                     </v-chip-group>

                     <div class="d-flex align-center mt-1">
                        <v-icon color="primary" small left>mdi-sort</v-icon>

                        <p class="caption font-weight-bold primary--text">
                           Listar por
                        </p>
                     </div>

                     <v-chip-group
                        @change="changeSort"
                        :value="sort"
                        class="pa-0"
                        mandatory
                        column
                     >
                        <v-chip
                           v-for="(item, index) in sorts"
                           :key="item.sort"
                           :color="sort === item.sort ? 'primary' : null"
                           :value="item.sort"
                           :id="`sort-item${index}-list-header-chip`"
                           filter
                           small
                        >
                           {{ item.text }}
                        </v-chip>
                     </v-chip-group>
                  </v-card>
               </v-menu>

               <!-- Filtragem por paciente. -->
               <div
                  v-if="filter === 'patient_id'"
                  class="d-flex align-center ml-4"
               >
                  <PatientInput
                     @onSelectPatient="onSelectPatient"
                     :required="false"
                     class="search-bar-inputs body-2"
                     loadPatientsOnCreated
                     ref="patientinput"
                  />

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              clearFilterPatient();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem por unidade de saúde . -->
               <div
                  v-else-if="filter === 'sourcehealthentity_id'"
                  class="d-flex align-center ml-4"
               >
                  <HealthUnitInput
                     @onSelectHealthUnit="onSelectHealthUnit"
                     :required="false"
                     class="search-bar-inputs body-2"
                     ref="healthunitinput"
                     dense
                  />

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              clearFilterHealthUnit();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem por municípios. -->
               <div
                  v-else-if="filter === 'municipality_id'"
                  class="d-flex align-center ml-4"
               >
                  <v-text-field
                     v-model="municipalitySelected"
                     @click="openSelectMunicipality"
                     class="search-bar-inputs body-2"
                     placeholder="Selecione o município..."
                     append-icon="mdi-map-marker"
                     readonly
                  ></v-text-field>

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              clearFilterMunicipality();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem por status. -->
               <div
                  v-else-if="filter === 'status&is_checked'"
                  class="d-flex align-center mr-n1 my-n1 ml-3 flex-wrap"
               >
                  <v-select
                     v-model="statusByCheckedSelected"
                     @change="statusByCheckedOnSelectStatus"
                     :items="statusByCheckedItems"
                     class="search-bar-status body-2 ma-1"
                     placeholder="Estado"
                     return-object
                  >
                     <template v-slot:selection="{ item }">
                        <p
                           class="label-selection font-weight-bold"
                           :class="`${item.color}--text`"
                        >
                           {{ item.text }}
                        </p>
                     </template>

                     <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                           <v-list-item-content>
                              <v-list-item-title :class="`${item.color}--text`">
                                 {{ item.text }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-select>

                  <v-chip
                     @click="
                        statusByCheckedOnSelectIsChecked(statusByCheckedIsCheck)
                     "
                     :color="statusByCheckedIsCheckComputed.color"
                     class="ma-1"
                  >
                     <v-icon left small>
                        {{ statusByCheckedIsCheckComputed.icon }}
                     </v-icon>

                     <p>
                        {{ statusByCheckedIsCheckComputed.text }}
                     </p>
                  </v-chip>

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              statusByCheckedClearFilter();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <div class="d-flex align-center ma-n1 w-100" v-else>
                  <v-text-field
                     v-model="filterBy"
                     v-maska="filterMask"
                     @click:append="
                        filterBy && filterBy !== '' ? searchItems() : null
                     "
                     :placeholder="`Digite o(a) ${filterText} do ${filterActor}...`"
                     class="search-bar body-2"
                     append-icon="mdi-magnify"
                     :maxlength="filterMaxLength"
                     :id="
                        userInfo
                           ? `filter-${userInfo}-by-list-header-input`
                           : 'filter-by-list-header-input'
                     "
                  >
                  </v-text-field>

                  <v-tooltip :value="filterBy !== '' ? true : false" top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              filterBy = null;
                              clearFilter();
                           "
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>
            </div>

            <div v-if="!hideAddButton">
               <v-btn
                  @click="add"
                  class="d-flex d-md-none"
                  color="primary"
                  id="add-secondary-list-header-button"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-plus</v-icon>
               </v-btn>
               <v-btn
                  @click="add"
                  class="d-none d-md-flex"
                  color="primary"
                  id="add-primary-list-header-button"
                  depressed
               >
                  ADICIONAR
                  <v-icon right>mdi-plus</v-icon>
               </v-btn>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import SelectMunicipality from "components/municipality/selectMunicipality/SelectMunicipality";
import PatientInput from "components/patient/PatientInput";
import responsiveUtil from "utils/responsiveUtil";
import formatUtil from "utils/formatUtil";

const SEARCH_DELAY = 800;

export default {
   name: "RequestListHeader",

   components: { HealthUnitInput, SelectMunicipality, PatientInput },

   props: {
      filterActor: {
         type: String,
         default: "default",
      },

      date: {
         type: Array,
      },

      filter: {
         type: String,
         default: "name",
      },

      filters: {
         type: Array,
         default: () => [],
      },

      sort: {
         type: String,
         default: "-created_at",
      },

      sorts: {
         type: Array,
         default: () => [],
      },

      loading: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      userInfo: {
         type: String,
         default: null,
      },

      initialFilterBy: {
         type: String,
         default: null,
      },

      allowedStatusInStatusFilter: {
         type: Array,
         default: () => [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected",
            "rejected_by_executor",
            "canceled",
         ],
      },
   },

   data: function () {
      return {
         filterBy: this.initialFilterBy ? this.initialFilterBy : "",

         filterTimeout: null,

         filterMenuVisibility: false,
         dialogSelectMunicipalityVisibility: false,

         municipalitySelected: "",

         /* Filtro de status. */
         statusByCheckedIsCheck: "all",
         statusByCheckedSelected: {},
      };
   },

   computed: {
      filterText() {
         return this.filters
            .find((item) => item.filter === this.filter)
            .text.toLowerCase();
      },

      filterMask() {
         let mask = {
            mask: "Z*",
            tokens: {
               Z: { pattern: /[0-9a-zA-Z!@#$%^&*()_+-={}çãáéúíóâêîôû ]/ },
            },
         };

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask) mask = filterItem.mask;

         return mask;
      },

      filterMaxLength() {
         let length = -1;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.maxLength) length = filterItem.maxLength;

         return length;
      },

      statusByCheckedIsCheckComputed() {
         let result = {
            icon: "mdi-check-underline-circle",
            color: "primary",
            text: "Validadas e não validadas",
         };

         switch (this.statusByCheckedIsCheck) {
            case "checked":
               result.icon = "mdi-check-circle";
               result.color = "success";
               result.text = "Validadas";
               break;

            case "unchecked":
               result.icon = "mdi-help-circle";
               result.color = "normal";
               result.text = "Não validadas";
               break;
         }

         return result;
      },

      statusByCheckedItems() {
         let status = [
            {
               value: "registered",
               text: "Solicitada",
               color: "info",
            },
            {
               value: "in_progress",
               text: "Em andamento",
               color: "warning",
            },
            {
               value: "regulated",
               text: "Regulado",
               color: "primary",
            },
            {
               value: "answered",
               text: "Atendida",
               color: "success",
            },
            {
               value: "rejected",
               text: "Rejeitada",
               color: "error",
            },
            {
               value: "rejected_by_executor",
               text: "Rejeitada pelo executante",
               color: "error",
            },
            {
               value: "canceled",
               text: "Cancelada",
               color: "error",
            },
         ];

         return status.filter((item) =>
            this.allowedStatusInStatusFilter.includes(item.value)
         );
      },
   },

   watch: {
      filterBy(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.filterTimeout);
         this.filterTimeout = setTimeout(this.searchItems, SEARCH_DELAY);
      },

      filter(value, oldValue) {
         if (oldValue === "sourcehealthentity_id") this.clearFilterHealthUnit();
         else if (oldValue === "municipality_id")
            this.clearFilterMunicipality();
         else if (oldValue === "status&is_checked")
            this.statusByCheckedClearFilter();
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      closeFilterMenu() {
         this.filterMenuVisibility = false;
      },

      changeFilter(filter) {
         this.filterBy = null;
         this.searchItems();

         this.closeFilterMenu();
         this.$emit("onChangeFilter", filter);
      },

      changeSort(sort) {
         this.$emit("onChangeSort", sort);
         this.closeFilterMenu();
      },

      clearFilter() {
         this.$emit("onClearFilter");
      },

      onChangeDate(date) {
         this.$emit("onChangeDate", date.sort());
      },

      add() {
         this.$emit("onClickAdd");
      },

      /* Filtro padrão. */
      searchItems() {
         let filterByTemp = this.filterBy;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask && filterItem.unmask && filterByTemp)
            filterByTemp = filterByTemp.replace(filterItem.unmask, "");

         this.$emit("onSearchItems", filterByTemp);
      },

      /* Filtro pelo nome do paciente. */
      onSelectPatient(patient) {
         this.$emit("onSearchItems", patient.id);
      },

      clearFilterPatient() {
         if (this.$refs.patientinput) this.$refs.patientinput.reset();
      },

      /* Filtro pela unidade de saúde. */
      onSelectHealthUnit(healthUnit) {
         this.$emit("onSearchItems", healthUnit.id);
      },

      clearFilterHealthUnit() {
         if (this.$refs.healthunitinput) this.$refs.healthunitinput.reset();
      },

      /* Filtro pelo município. */
      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      onSelectMunicipality(municipality) {
         if (municipality && municipality.id) {
            this.municipalitySelected = municipality.name;
            this.$emit("onSearchItems", municipality.id);
         }
         this.closeSelectMunicipality();
      },

      clearFilterMunicipality() {
         this.municipalitySelected = "";
      },

      /* Filtro de status. */
      statusByCheckedOnSelectStatus() {
         this.statusByCheckedSearchEventEmit(
            this.statusByCheckedSelected,
            this.statusByCheckedIsCheck
         );
      },

      statusByCheckedOnSelectIsChecked(value) {
         switch (value) {
            case "all":
               this.statusByCheckedIsCheck = "checked";
               break;
            case "checked":
               this.statusByCheckedIsCheck = "unchecked";
               break;
            case "unchecked":
               this.statusByCheckedIsCheck = "all";
               break;
         }

         this.statusByCheckedSearchEventEmit(
            this.statusByCheckedSelected,
            this.statusByCheckedIsCheck
         );
      },

      statusByCheckedSearchEventEmit(status, isChecked) {
         let filter = [];

         if (status && status.text && status.value) {
            /* Particularidade do status rejected e rejected_by_executor. */
            if (status.value.includes("rejected")) {
               filter.push({
                  text: "status",
                  value: "rejected",
               });

               if (status.value === "rejected_by_executor")
                  filter.push({
                     text: "is_closed",
                     value: "false",
                  });
               else
                  filter.push({
                     text: "is_closed",
                     value: "true",
                  });
            } else
               filter.push({
                  text: "status",
                  value: status.value,
               });
         }

         switch (isChecked) {
            case "checked":
               filter.push({
                  text: "is_checked",
                  value: "true",
               });
               break;

            case "unchecked":
               filter.push({
                  text: "is_checked",
                  value: "false",
               });
               break;
         }

         if (filter.length) this.$emit("onSearchItems", filter);
         else this.clearFilter();
      },

      statusByCheckedClearFilter() {
         this.statusByCheckedSelected = {};
         this.statusByCheckedIsCheck = "all";
      },
   },
};
</script>

<style scoped>
.search-bar {
   max-width: 500px;
   margin-left: 16px;
}

.search-bar-inputs {
   min-width: 500px !important;
}

@media (max-width: 959px) {
   .search-bar-inputs {
      min-width: 20px !important;
   }
}

.search-bar-status {
   min-width: 300px !important;
}

@media (max-width: 959px) {
   .search-bar-status {
      min-width: 20px !important;
   }
}
</style>