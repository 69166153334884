export default {
   namespaced: true,

   state: {
      visibility: true,

      menuVisibility: true,
   },

   getters: {
      getVisibility(state) {
         return state.visibility;
      },

      getMenuVisibility(state) {
         return state.menuVisibility;
      },
   },

   mutations: {
      setVisibility(state, visibility) {
         state.visibility = visibility;
      },

      setMenuVisibility(state, menuVisibility) {
         state.menuVisibility = menuVisibility;
      },
   },
};
