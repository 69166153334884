<template>
   <div>
      <div class="d-flex align-center flex-wrap ma-n3">
         <v-card class="circle ma-3" height="60" width="60" flat>
            <v-icon large dark>mdi-bed</v-icon>
         </v-card>

         <div class="d-flex align-center">
            <div class="ma-3">
               <p class="caption white--text">Total de regulações</p>
               <p class="display-1 white--text">
                  {{ statistics.total_requests || 0 }}
               </p>
            </div>

            <div class="ma-3">
               <p class="caption white--text">Anônimas</p>
               <p class="display-1 white--text">
                  {{ statistics.total_requests_anonymous || 0 }}
               </p>
            </div>

            <div class="ma-3">
               <p class="caption white--text">Último mês</p>
               <p class="display-1 white--text">
                  {{ regulationsLastMonth || 0 }}
               </p>
            </div>
         </div>
      </div>

      <apexchart
         :options="options"
         :series="series"
         :key="chartInputKey"
         type="bar"
         class="mt-5 ml-n4 mb-n9"
         height="280"
      ></apexchart>
   </div>
</template>

<script>
import moment from "moment";

export default {
   name: "RegulationChart",

   props: {
      statistics: {
         type: Object,
         default: () => ({}),
      },
   },

   data: function () {
      return {
         options: {
            colors: ["#FFFFFFFF"],

            chart: {
               id: "regulation-chart",
               toolbar: {
                  show: false,
               },
            },

            xaxis: {
               categories: [],
               labels: {
                  style: {
                     cssClass: "caption",
                     colors: "#fff",
                  },
                  rotate: -45,
                  rotateAlways: true,
               },
            },

            yaxis: {
               labels: {
                  style: {
                     cssClass: "caption",
                     colors: "#fff",
                  },
               },
               tickAmount: 4,
               max: function (max) {
                  return Math.round(max * 1.25) || 1;
               },
            },

            plotOptions: {
               bar: {
                  dataLabels: {
                     position: "top",
                  },
                  borderRadius: 3,
                  columnWidth: "20px",
               },
            },

            dataLabels: {
               offsetY: -25,
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "regular",
                  colors: ["#FFFFFFFF"],
               },
            },

            tooltip: {
               theme: "dark",
               x: {
                  show: false,
               },
               marker: {
                  show: false,
               },
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },
         },

         chartInputKey: 0,
      };
   },

   watch: {
      statistics: {
         immediate: true,
         handler(statistics) {
            if (
               this.statistics.data_set &&
               this.statistics.data_set.length > 0
            ) {
               this.options.xaxis.categories = statistics.data_set.map(
                  (item) => {
                     let date = moment(item.start);

                     let monthName = date.format("MMMM").substring(0, 3);

                     return `${
                        monthName.charAt(0).toUpperCase() + monthName.slice(1)
                     } ${date.format("YY")}`;
                  }
               );

               this.forceChartInputRerender();
            }
         },
      },
   },

   computed: {
      series() {
         let result = [
            {
               name: "Regulaçõe(s)",
               data: [],
            },
         ];

         if (this.statistics.data_set && this.statistics.data_set.length > 0)
            result[0].data = this.statistics.data_set.map((item) => item.total);

         return result;
      },

      regulationsLastMonth() {
         if (this.statistics.data_set && this.statistics.data_set.length > 0)
            return this.statistics.data_set[this.statistics.data_set.length - 1]
               .total;
         else return 0;
      },
   },

   methods: {
      forceChartInputRerender() {
         this.chartInputKey += 1;
      },
   },
};
</script>

<style scoped>
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(255, 255, 255, 0.4);
}
</style>