<template>
   <div>
      <div
         v-if="series[0] === 0 && series[1] === 0"
         class="d-flex flex-column justify-center align-center my-8"
      >
         <v-icon class="mb-3" color="primary" large>mdi-help-circle</v-icon>

         <p class="body-2 primary--text">
            Nenhum dado sobre o perfil de gênero.
         </p>
      </div>

      <apexchart
         v-else
         :options="options"
         :series="series"
         type="donut"
         height="340"
      ></apexchart>
   </div>
</template>

<script>
export default {
   name: "GenderProfileChart",

   props: {
      statistics: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         options: {
            colors: ["#6F79E2", "#E998FF"],

            chart: {
               id: "gender-profile-chart",
               toolbar: {
                  show: false,
               },
            },

            plotOptions: {
               pie: {
                  expandOnClick: false,
                  donut: {
                     size: "40%",
                  },
               },
            },

            labels: ["Masculino", "Feminino"],

            dataLabels: {
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "regular",
                  colors: ["#FFFFFFFF"],
               },
               dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: "#000",
                  opacity: 0.15,
               },
            },

            tooltip: {
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },

            legend: {
               show: true,
               position: "bottom",
            },
         },
      };
   },

   computed: {
      series() {
         let result = [0, 0];

         if (
            this.statistics.length > 0 &&
            this.statistics.some((item) => item.total > 0)
         )
            result = this.statistics.map((item) => item.total);

         return result;
      },
   },
};
</script>

<style>
</style>