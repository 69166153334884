export default {
   namespaced: true,

   state: {
      visibility: false
   },

   getters: {
      getVisibility(state) {
         return state.visibility;
      }
   },

   mutations: {
      setVisibility(state, visibility) {
         state.visibility = visibility;
      }
   }
};
