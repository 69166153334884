var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingAdminsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.admins,"loading":_vm.loadingAdminsPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (admin) { return (!_vm.blockClick ? _vm.onClickViewAdmin(admin) : null); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingAdminsPartially,"filterActor":"administrador","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddAdmin}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalAdmins,"loading":_vm.loadingAdminsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhum administrador foi encontrado."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando administradores, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"name-admin-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"cpf-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatCPF(item.cpf))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"email-admin-list-data"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.birth_date",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"birth_date-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.birth_date))+" ")])]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"last_login-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm"))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"ma-n1"},[_c('v-icon',{staticClass:"mx-1",attrs:{"id":("edit-item-" + index + "-admin-list-button"),"color":"black","small":""},on:{"click":function($event){return _vm.editAdmin(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mx-1",attrs:{"id":("remove-item-" + index + "-admin-list-button"),"color":"error","small":""},on:{"click":function($event){return _vm.removeAdmin(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }