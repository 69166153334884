<template>
   <div class="d-flex justify-space-between align-center">
      <v-dialog
         v-if="filter === 'municipality_id&sus_code'"
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeSelectMunicipality" dense />
            <SelectMunicipality
               @onSelectMunicipality="surgeryByMunicipalityOnSelectMunicipality"
               :loadMunicipalitiesOnCreated="false"
               class="standard-padding"
               alertMessage="Selecione o município que deseja visualizar as solicitações."
               ref="surgerybymunicipalityselectmunicipality"
               multiple
            />
         </v-card>
      </v-dialog>

      <div class="d-flex align-center flex-grow-1">
         <v-menu
            v-model="filterMenuVisibility"
            :close-on-content-click="false"
            transition="slide-y-transition"
            close-on-click
            offset-y
         >
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex d-md-none"
                  color="primary"
                  :id="
                     userInfo
                        ? `filter-${userInfo}-secondary-list-header-button`
                        : 'filter-secondary-list-header-button'
                  "
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-filter</v-icon>
               </v-btn>

               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="d-none d-md-flex"
                  color="primary"
                  :id="
                     userInfo
                        ? `filter-${userInfo}-primary-list-header-button`
                        : 'filter-primary-list-header-button'
                  "
                  outlined
               >
                  FILTRO
                  <v-icon right>mdi-filter</v-icon>
               </v-btn>
            </template>

            <v-card :disabled="loading" class="standard-padding-x-small">
               <div class="d-flex align-center">
                  <v-icon color="primary" small left>mdi-filter</v-icon>

                  <p class="caption font-weight-bold primary--text">
                     Filtrar por
                  </p>
               </div>

               <v-chip-group
                  @change="changeFilter"
                  :value="filter"
                  mandatory
                  column
               >
                  <v-chip
                     v-for="(item, index) in filters"
                     :key="item.filter"
                     :color="filter === item.filter ? 'primary' : null"
                     :value="item.filter"
                     :id="`filter-item${index}-list-header-chip`"
                     filter
                     small
                  >
                     {{ item.text }}
                  </v-chip>
               </v-chip-group>

               <div class="d-flex align-center mt-1">
                  <v-icon color="primary" small left>mdi-sort</v-icon>

                  <p class="caption font-weight-bold primary--text">
                     Listar por
                  </p>
               </div>

               <v-chip-group
                  @change="changeSort"
                  :value="sort"
                  class="pa-0"
                  mandatory
                  column
               >
                  <v-chip
                     v-for="(item, index) in sorts"
                     :key="item.sort"
                     :color="sort === item.sort ? 'primary' : null"
                     :value="item.sort"
                     :id="`sort-item${index}-list-header-chip`"
                     filter
                     small
                  >
                     {{ item.text }}
                  </v-chip>
               </v-chip-group>
            </v-card>
         </v-menu>

         <div v-if="filter === 'scheduling_date'" class="d-flex align-center">
            <FilterByScheduleDate
               @onSelectedDate="onSelectedDate"
               :healthUnitId="healthUnit.id"
               class="search-bar-inputs body-2 mx-4"
            />
         </div>

         <div v-else-if="filter === 'status'" class="d-flex align-center ml-4">
            <v-select
               @change="onSelectStatus"
               :items="statusItems"
               class="search-bar-inputs body-2"
               placeholder="Estado"
               return-object
            >
               <template v-slot:selection="{ item }">
                  <p
                     class="label-selection font-weight-bold"
                     :class="`${item.color}--text`"
                  >
                     {{ item.text }}
                  </p>
               </template>

               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content
                        :id="`item-${attrs.id.split('-')[3]}`"
                     >
                        <v-list-item-title :class="`${item.color}--text`">
                           {{ item.text }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </v-select>
         </div>

         <div
            v-else-if="filter === 'patient_id'"
            class="d-flex align-center ml-4"
         >
            <PatientInput
               @onSelectPatient="onSelectPatient"
               :required="false"
               class="search-bar-inputs body-2"
               loadPatientsOnCreated
               ref="patientinput"
            />
         </div>
         <div
            v-else-if="filter === 'municipality_id&sus_code'"
            class="d-flex align-center ml-4"
         >
            <v-chip
               @click="openSelectMunicipality"
               color="primary"
               class="ma-0 mr-1 select-municipality"
               small
            >
               <p
                  v-if="
                     !surgeryByMunicipalityMunicipalitiesSelected ||
                     !surgeryByMunicipalityMunicipalitiesSelected.length
                  "
               >
                  Selecione o <br />
                  município
               </p>
               <p v-else>
                  <strong>
                     {{ surgeryByMunicipalityMunicipalitiesSelected.length }}
                  </strong>
                  município(s) <br />
                  selecionado(s)
               </p>
            </v-chip>

            <SurgerieInput
               @onSelectSurgerie="surgeryByMunicipalityOnSelectSurgerie"
               :required="false"
               class="search-bar-inputs body-2 ml-3"
               ref="surgerybymunicipalitysurgerieinput"
            />
         </div>

         <v-text-field
            v-else
            v-model="filterBy"
            v-maska="filterMask"
            @click:append="filterBy && filterBy !== '' ? searchItems() : null"
            :placeholder="`Digite o(a) ${filterText} do(a) ${filterActor}...`"
            class="search-bar body-2"
            append-icon="mdi-magnify"
            :maxlength="filterMaxLength"
            :id="
               userInfo
                  ? `filter-${userInfo}-by-list-header-input`
                  : 'filter-by-list-header-input'
            "
         ></v-text-field>

         <v-tooltip :value="filterBy !== '' ? true : false" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="
                     filterBy = null;
                     clearFilter();
                  "
                  height="36px"
                  width="36px"
                  icon
               >
                  <v-icon>mdi-delete</v-icon>
               </v-btn>
            </template>
            <p>Limpar filtro</p>
         </v-tooltip>
      </div>

      <div v-if="!hideAddButton">
         <v-btn
            @click="add"
            class="d-flex d-md-none"
            color="primary"
            id="add-secondary-list-header-button"
            fab
            depressed
            x-small
         >
            <v-icon>mdi-plus</v-icon>
         </v-btn>
         <v-btn
            @click="add"
            class="d-none d-md-flex"
            color="primary"
            id="add-primary-list-header-button"
            depressed
         >
            ADICIONAR
            <v-icon right>mdi-plus</v-icon>
         </v-btn>
      </div>

      <div class="d-flex align-center ma-n1">
         <!-- Botao de exportar o mapa de cirurgias. -->
         <div
            v-if="filter === 'scheduling_date' && scheduledDataSelected"
            class="ma-1"
         >
            <v-btn
               @click="print(scheduledDataSelected)"
               :loading="loadingPrint"
               class="d-flex d-md-none"
               color="primary"
               id="add-secondary-list-header-button"
               fab
               depressed
               x-small
            >
               <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
               @click="print(scheduledDataSelected)"
               :loading="loadingPrint"
               class="d-none d-md-flex"
               color="primary"
               id="add-primary-list-header-button"
               depressed
            >
               EXPORTAR
               <v-icon right>mdi-printer</v-icon>
            </v-btn>
         </div>

         <!-- 
            Botao de agendar mutirão, ao clicar vai tornar os items da listagem selecionáveis.
            Só vai ser exibido quando a prop "batchScheduleSelectedMode" for false.
         -->
         
         <div v-if="filter !== 'scheduling_date'">
            <div v-if="!batchScheduleSelectedMode" class="ma-1">
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                     <div v-bind="attrs" v-on="on">
                        <v-btn
                           @click="batchSchedule"
                           class="d-flex d-md-none"
                           color="primary"
                           fab
                           depressed
                           x-small
                        >
                           <v-icon>mdi-calendar-multiple-check</v-icon>
                        </v-btn>
                        <v-btn
                           @click="batchSchedule"
                           class="d-none d-md-flex"
                           color="primary"
                           depressed
                        >
                           <v-chip color="info" class="mr-1 pa-1" x-small>
                              Novo
                           </v-chip>

                           AGENDAR MUTIRÃO
                           <v-icon right>mdi-calendar-multiple-check</v-icon>
                        </v-btn>
                     </div>
                  </template>

                  <p class="text-center">
                     Selecione várias cirurgias pendentes <br />
                     para agendar todas de uma vez.
                  </p>
               </v-tooltip>
            </div>
            <!-- 
               Botoes de ações quando o modo de seleção dos items é exibido, contém o botão de agendar, que vai
               realizar o agendamento de todos os items selecionados e o botão de cancelar, esse vai cancelar todos
               os items selecionados e cancelar o processo de agendamento de mutirão. Só será exibido quando a prop 
               "batchScheduleSelectedMode" for true.
            -->
            <div v-else class="d-flex align-center ma-n1">
               <div class="ma-1">
                  <v-btn
                     @click="cancelBatchSchedule"
                     class="d-flex d-md-none"
                     color="error"
                     fab
                     text
                     x-small
                  >
                     <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn
                     @click="cancelBatchSchedule"
                     class="d-none d-md-flex"
                     color="error"
                     text
                  >
                     CANCELAR
                  </v-btn>
               </div>

               <div class="ma-1">
                  <v-btn
                     @click="saveBatchSchedule"
                     :disabled="selectionEmpty"
                     class="d-flex d-md-none"
                     color="success"
                     fab
                     depressed
                     x-small
                  >
                     <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn
                     @click="saveBatchSchedule"
                     :disabled="selectionEmpty"
                     class="d-none d-md-flex"
                     color="success"
                     depressed
                  >
                     AGENDAR
                  </v-btn>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import formatUtil from "utils/formatUtil";
import Close from "../../../../components/base/Close";
import FilterByScheduleDate from "./FilterByScheduleDate";
import PatientInput from "components/patient/PatientInput";
import SelectMunicipality from "../../../../components/municipality/selectMunicipality/SelectMunicipality";
import SurgerieInput from "components/surgerie/SurgerieInput";
import responsiveUtil from "../../../../utils/responsiveUtil";

const SEARCH_DELAY = 800;

export default {
   name: "SurgeryListHeader",

   components: {
      FilterByScheduleDate,
      PatientInput,
      SelectMunicipality,
      SurgerieInput,
      Close,
   },

   props: {
      filterActor: {
         type: String,
         default: "default",
      },

      filter: {
         type: String,
         default: "name",
      },

      filters: {
         type: Array,
         default: () => [],
      },

      sort: {
         type: String,
         default: "-created_at",
      },

      sorts: {
         type: Array,
         default: () => [],
      },

      loading: {
         type: Boolean,
         default: false,
      },

      loadingPrint: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      userInfo: {
         type: String,
         default: null,
      },

      healthUnit: {
         type: Object,
      },

      batchScheduleSelectedMode: {
         type: Boolean,
         default: false,
      },

      selectionEmpty: {
         type: Boolean,
         default: true,
      },
   },

   data: function () {
      return {
         filterBy: "",

         surgeryByMunicipalityMunicipalitiesSelected: [],
         surgeryByMunicipalitySurgerySelected: {},

         filterTimeout: null,

         dialogSelectMunicipalityVisibility: false,

         filterMenuVisibility: false,

         statusItems: [
            {
               value: "pending",
               text: "Pendente",
               color: "warning",
            },
            {
               value: "scheduled",
               text: "Agendada",
               color: "success",
            },
            {
               value: "scheduled_late",
               text: "Agendada atrasada",
               color: "error",
            },
            {
               value: "done",
               text: "Feita",
               color: "info",
            },
         ],

         scheduledDataSelected: null,
      };
   },

   computed: {
      filterText() {
         return this.filters
            .find((item) => item.filter === this.filter)
            .text.toLowerCase();
      },

      filterMask() {
         let mask = {
            mask: "Z*",
            tokens: {
               Z: { pattern: /[0-9a-zA-Z!@#$%^&*()_+-={}çãáéúíóâêîôû ]/ },
            },
         };

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask) mask = filterItem.mask;

         return mask;
      },

      filterMaxLength() {
         let length = -1;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.maxLength) length = filterItem.maxLength;

         return length;
      },
   },

   watch: {
      filterBy(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.filterTimeout);
         this.filterTimeout = setTimeout(this.searchItems, SEARCH_DELAY);
      },

      filter() {
         this.clearFilter();
      },
   },

   methods: {
      ...responsiveUtil,

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      closeFilterMenu() {
         this.filterMenuVisibility = false;
      },

      surgeryByMunicipalityOnSelectMunicipality(municipalities) {
         if (municipalities.length) {
            this.surgeryByMunicipalityMunicipalitiesSelected = municipalities;

            this.surgeryByMunicipalitySearchEventEmit(
               this.surgeryByMunicipalityMunicipalitiesSelected,
               this.surgeryByMunicipalitySurgerySelected
            );
         }

         this.closeSelectMunicipality();
      },

      surgeryByMunicipalityOnSelectSurgerie(surgerie) {
         if (surgerie && surgerie.name && surgerie.sus_code) {
            this.surgeryByMunicipalitySurgerySelected = surgerie;

            this.surgeryByMunicipalitySearchEventEmit(
               this.surgeryByMunicipalityMunicipalitiesSelected,
               this.surgeryByMunicipalitySurgerySelected
            );
         }
      },

      surgeryByMunicipalitySearchEventEmit(municipalities, surgerie) {
         let filter = [];

         if (municipalities && municipalities.length)
            filter.push({
               text: "municipality_id",
               value: municipalities.map((item) => item.id).join(","),
            });
         if (surgerie && surgerie.sus_code)
            filter.push({
               text: "sus_code",
               value: surgerie.sus_code,
            });

         this.$emit("onSearchItems", filter);
      },

      changeFilter(filter) {
         if (this.filterBy && this.filterBy !== "") {
            this.filterBy = null;
            this.searchItems();
         }

         this.closeFilterMenu();
         this.$emit("onChangeFilter", filter);
      },

      changeSort(sort) {
         this.$emit("onChangeSort", sort);
         this.closeFilterMenu();
      },

      clearFilter() {
         if(this.filter === 'municipality_id&sus_code') this.surgeryByMunicipalityClearFilter();
         if(this.filter === 'patient_id') this.clearFilterPatient();

         this.scheduledDataSelected = null;
         this.$emit("onClearFilter");
      },

      clearFilterPatient() {
         if (this.$refs.patientinput) this.$refs.patientinput.reset();
      },

      surgeryByMunicipalityClearFilter() {
         this.surgeryByMunicipalityMunicipalitiesSelected = [];
         this.surgeryByMunicipalitySurgerySelected = {};
         if (this.$refs.surgerybymunicipalitysurgerieinput)
            this.$refs.surgerybymunicipalitysurgerieinput.reset();
         if (this.$refs.surgerybymunicipalityselectmunicipality)
            this.$refs.surgerybymunicipalityselectmunicipality.reset();
      },

      onSelectPatient(patient) {
         this.$emit("onSearchItems", patient.id);
      },

      searchItems() {
         let filterByTemp = this.filterBy;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask && filterItem.unmask && filterByTemp)
            filterByTemp = filterByTemp.replace(filterItem.unmask, "");

         this.$emit("onSearchItems", filterByTemp);
      },

      add() {
         this.$emit("onClickAdd");
      },

      onSelectedDate(date) {
         this.scheduledDataSelected = date;
         this.$emit("onSearchItems", date);
      },

      onSelectStatus(data) {
         let filter = [];
         let statusTemp = data.value;

         if (statusTemp.includes("scheduled")) {
            if (statusTemp === "scheduled_late") {
               filter.push({
                  text: "scheduling_date",
                  value: `lt:${formatUtil.formatDate(
                     new Date(),
                     "YYYY-MM-DD"
                  )}`,
               });

               statusTemp = "scheduled";
            } else
               filter.push({
                  text: "scheduling_date",
                  value: `gte:${formatUtil.formatDate(
                     new Date(),
                     "YYYY-MM-DD"
                  )}`,
               });
         }

         filter.push({
            text: "status",
            value: statusTemp,
         });

         this.$emit("onSearchItems", filter);
      },

      print(date) {
         this.$emit("onClickPrint", date);
      },

      batchSchedule() {
         this.$emit("onClickBatchSchedule");
      },

      cancelBatchSchedule() {
         this.$emit("onClickCancelBatchSchedule");
      },

      saveBatchSchedule() {
         this.$emit("onClickSaveBatchSchedule");
      },
   },
};
</script>

<style scoped>
.search-bar {
   max-width: 500px;
   margin: 0px 16px;
}

.search-bar-inputs {
   min-width: 500px !important;
}

@media (max-width: 1259px) {
   .search-bar-inputs {
      min-width: 100px !important;
   }
}

@media (max-width: 959px) {
   .search-bar-inputs {
      min-width: 20px !important;
   }
}

.select-municipality {
   font-size: 0.55rem !important;
   line-height: 10px;
   text-align: center;
}
</style>
