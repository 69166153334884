<template>
   <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center flex-grow-1">
         <v-menu
            v-model="filterMenuVisibility"
            :close-on-content-click="false"
            transition="slide-y-transition"
            close-on-click
            offset-y
         >
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex d-md-none"
                  color="primary"
                  :id="
                     userInfo
                        ? `filter-${userInfo}-secondary-list-header-button`
                        : 'filter-secondary-list-header-button'
                  "
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-filter</v-icon>
               </v-btn>

               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="d-none d-md-flex"
                  color="primary"
                  :id="
                     userInfo
                        ? `filter-${userInfo}-primary-list-header-button`
                        : 'filter-primary-list-header-button'
                  "
                  outlined
               >
                  FILTRO
                  <v-icon right>mdi-filter</v-icon>
               </v-btn>
            </template>

            <v-card :disabled="loading" class="standard-padding-x-small">
               <div class="d-flex align-center">
                  <v-icon color="primary" small left>mdi-filter</v-icon>

                  <p class="caption font-weight-bold primary--text">
                     Filtrar por
                  </p>
               </div>

               <v-chip-group
                  @change="changeFilter"
                  :value="filter"
                  mandatory
                  column
               >
                  <v-chip
                     v-for="(item, index) in filters"
                     :key="item.filter"
                     :color="filter === item.filter ? 'primary' : null"
                     :value="item.filter"
                     :id="`filter-item${index}-list-header-chip`"
                     filter
                     small
                  >
                     {{ item.text }}
                  </v-chip>
               </v-chip-group>

               <div class="d-flex align-center mt-1">
                  <v-icon color="primary" small left>mdi-sort</v-icon>

                  <p class="caption font-weight-bold primary--text">
                     Listar por
                  </p>
               </div>

               <v-chip-group
                  @change="changeSort"
                  :value="sort"
                  class="pa-0"
                  mandatory
                  column
               >
                  <v-chip
                     v-for="(item, index) in sorts"
                     :key="item.sort"
                     :color="sort === item.sort ? 'primary' : null"
                     :value="item.sort"
                     :id="`sort-item${index}-list-header-chip`"
                     filter
                     small
                  >
                     {{ item.text }}
                  </v-chip>
               </v-chip-group>
            </v-card>
         </v-menu>

         <HealthUnitInput
            v-if="filter === 'health_units'"
            ref="healthUnitInput"
            @onSelectHealthUnit="onSelectHealthUnit"
            :required="false"
            class="search-bar-patient"
            dense
         />

         <HealthSecretarieInput
            ref="healthSecretaryInput"
            v-else-if="filter === 'health_secretaries'"
            @onSelectHealthSecretarie="onSelectHealthSecretarie"
            :required="false"
            class="search-bar-patient"
            dense
         />

         <v-text-field
            v-else
            v-model="filterBy"
            v-maska="filterMask"
            @click:append="filterBy && filterBy !== '' ? searchItems() : null"
            :placeholder="`Digite o(a) ${filterText} do(a) ${filterActor}...`"
            class="search-bar body-2"
            append-icon="mdi-magnify"
            :maxlength="filterMaxLength"
            :id="
               userInfo
                  ? `filter-${userInfo}-by-list-header-input`
                  : 'filter-by-list-header-input'
            "
         ></v-text-field>

         <v-tooltip :value="filterBy !== '' ? true : false" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="
                     filterBy = null;
                     clearFilter();
                  "
                  height="36px"
                  width="36px"
                  icon
               >
                  <v-icon>mdi-delete</v-icon>
               </v-btn>
            </template>
            <p>Limpar filtro</p>
         </v-tooltip>
      </div>

      <div v-if="!hideAddButton">
         <v-btn
            @click="add"
            class="d-flex d-md-none"
            color="primary"
            id="add-secondary-list-header-button"
            fab
            depressed
            x-small
         >
            <v-icon>mdi-plus</v-icon>
         </v-btn>
         <v-btn
            @click="add"
            class="d-none d-md-flex"
            color="primary"
            id="add-primary-list-header-button"
            depressed
         >
            ADICIONAR
            <v-icon right>mdi-plus</v-icon>
         </v-btn>
      </div>
   </div>
</template>
 
 <script>
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput.vue";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput.vue";

const SEARCH_DELAY = 800;

export default {
   name: "OperatorAdminListHeader",

   components: { HealthUnitInput, HealthSecretarieInput },

   props: {
      filterActor: {
         type: String,
         default: "default",
      },

      filter: {
         type: String,
         default: "name",
      },

      filters: {
         type: Array,
         default: () => [],
      },

      sort: {
         type: String,
         default: "-created_at",
      },

      sorts: {
         type: Array,
         default: () => [],
      },

      loading: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      userInfo: {
         type: String,
         default: null,
      },
   },

   data: function () {
      return {
         filterBy: "",

         filterTimeout: null,

         filterMenuVisibility: false,
      };
   },

   computed: {
      filterText() {
         return this.filters
            .find((item) => item.filter === this.filter)
            .text.toLowerCase();
      },

      filterMask() {
         let mask = {
            mask: "Z*",
            tokens: {
               Z: { pattern: /[0-9a-zA-Z!@#$%^&*()_+-={}çãáéúíóâêîôû ]/ },
            },
         };

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask) mask = filterItem.mask;

         return mask;
      },

      filterMaxLength() {
         let length = -1;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.maxLength) length = filterItem.maxLength;

         return length;
      },
   },

   watch: {
      filterBy(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.filterTimeout);
         this.filterTimeout = setTimeout(this.searchItems, SEARCH_DELAY);
      },
   },

   methods: {
      closeFilterMenu() {
         this.filterMenuVisibility = false;
      },

      changeFilter(filter) {
         this.filterBy = null;
         this.searchItems();

         this.closeFilterMenu();
         this.$emit("onChangeFilter", filter);
      },

      changeSort(sort) {
         this.$emit("onChangeSort", sort);
         this.closeFilterMenu();
      },

      clearFilter() {
         if(this.filter === 'health_secretaries' && this.$refs.healthSecretaryInput) this.$refs.healthSecretaryInput.reset();
         if(this.filter === 'health_units' && this.$refs.healthUnitInput) this.$refs.healthUnitInput.reset();
      
         this.$emit("onClearFilter");
      },

      searchItems() {
         let filterByTemp = this.filterBy;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask && filterItem.unmask && filterByTemp)
            filterByTemp = filterByTemp.replace(filterItem.unmask, "");

         this.$emit("onSearchItems", filterByTemp);
      },

      add() {
         this.$emit("onClickAdd");
      },

      onSelectUser(user) {
         this.$emit("onSearchItems", user.id);
      },

      onSelectHealthUnit(healthUnit) {
         this.$emit("onSearchItems", healthUnit.id);
      },

      onSelectHealthSecretarie(healthSecretaryUnit) {
         this.$emit("onSearchItems", healthSecretaryUnit.id);
      },
   },
};
</script>
 
 <style scoped>
.search-bar {
   max-width: 500px;
   margin: 0px 16px;
}

.search-bar-patient {
   min-width: 500px !important;
   margin: 0px 16px;
}

@media (max-width: 959px) {
   .search-bar-patient {
      min-width: 20px !important;
      margin: 0px 16px;
   }
}
</style>