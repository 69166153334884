<template>
   <div>
      <ListSkeleton
         v-if="loadingAuditsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="(audit) => (!blockClick ? onClickViewAudit(audit) : null)"
         :headers="headers"
         :items="audits"
         :loading="loadingAuditsPartially"
         :items-per-page="limit"
         :expanded.sync="expanded"
         item-key="id"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         single-expand
         show-expand
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <AuditListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onChangeDate="onChangeDate"
               :sort="sort"
               :date="date"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingAuditsPartially"
               :hideAddButton="hideAddButton"
               filterActor="auditoria"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalAudits"
               :loading="loadingAuditsPartially"
            />
         </template>

         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0 ma-0">
               <v-card
                  class="standard-padding cursor-config"
                  width="100%"
                  tile
                  flat
               >
                  <v-row>
                     <v-col cols="12" md="6">
                        <p class="grey--text lighten-3 mb-1">
                           Conteúdo da requisição
                        </p>
                        <div class="json-container standard-padding-tiny">
                           <code class="code-container body-audit-list-data">{{
                              item.request.body ? item.request.body : "{}"
                           }}</code>
                        </div>
                     </v-col>

                     <v-col cols="12" md="6">
                        <p class="grey--text lighten-3 mb-1">
                           Conteúdo da resposta
                        </p>
                        <div class="json-container standard-padding-tiny">
                           <code class="code-container body-audit-list-data">{{
                              item.response.body ? item.response.body : "{}"
                           }}</code>
                        </div>
                     </v-col>
                  </v-row>

                  <div class="mt-4">
                     <p class="grey--text lighten-3">Dispositivo</p>
                     <p class="user_agent-audit-list-data">
                        {{ item.request.user_agent }}
                     </p>
                  </div>
               </v-card>
            </td>
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData message="Desculpe, nenhum registro foi encontrado." />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData message="Carregando registro, aguarde por favor." />
            </div>
         </template>

         <template v-slot:[`item.id`]="{ item }">
            <p class="id-audit-list">
               {{ item.id }}
            </p>
         </template>

         <template v-slot:[`item.user.type`]="{ item }">
            <p :class="['user-type-audit-list']">
               {{ item.user.type }}
            </p>
         </template>

         <template v-slot:[`item.request.operator`]="{ item }">
            <p :class="['operator-action-audit-list']">
               {{ item.request.operator }}
            </p>
         </template>

         <template v-slot:[`item.request.path`]="{ item }">
            <p class="path-wrap request-audit-list-data">
               {{ item.request.path }}
            </p>
         </template>

         <template v-slot:[`item.date`]="{ item }">
            <p class="date-audit-list-data">
               {{ formatDate(item.date, "DD/MM/YYYY [às] HH:mm") }}
            </p>
         </template>

         <template v-slot:[`item.response.code`]="{ item }">
            <v-chip
               :color="getColor(item.response.code)"
               class="font-weight-bold"
               dark
               small
            >
               <span class="code-audit-list">
                  {{ item.response.code }}
               </span>
            </v-chip>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import AuditListHeader from "../../components/audit/AuditListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import moment from "moment";
import auditService from "../../services/auditService";

export default {
   name: "AuditList",

   components: {
      ListSkeleton,
      ListFooter,
      AuditListHeader,
      NoData,
      LoadingData,
   },

   props: {
      hideAddButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Registro",
               class: "primary--text body-2 font-weight-medium",
               value: "id",
            },
            {
               text: "Tipo de Usuário",
               class: "primary--text body-2 font-weight-medium",
               value: "user.type",
            },
            {
               text: "Ação",
               class: "primary--text body-2 font-weight-medium",
               value: "request.operator",
            },
            {
               text: "Recurso",
               class: "primary--text body-2 font-weight-medium",
               value: "request.path",
            },
            {
               text: "Data",
               class: "primary--text body-2 font-weight-medium",
               value: "date",
            },
            {
               text: "Resposta",
               class: "primary--text body-2 font-weight-medium",
               value: "response.code",
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
         ],

         filters: [
            {
               filter: "_id",
               text: "Registro",
            },
            {
               filter: "user.id",
               text: "Usuário responsável",
            },
            {
               filter: "success&request.resource&request.operator",
               text: "Recurso x operação x sucesso",
            },
         ],

         audits: [],

         expanded: [],

         totalAudits: 0,
         page: 1,
         limit: 20,
         date: null,
         sort: "-created_at",
         filter: "_id",
         filterBy: "",

         loadingAuditsFully: true,
         loadingAuditsPartially: true,

         blockClick: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "date",
                  query: `gte:${this.getRangeDateFromDate.gte}`,
               },
               {
                  attribute: "date",
                  query: `lte:${this.getRangeDateFromDate.lte}`,
               },
            ],
         };

         /* Caso do filtro múltiplo, de resource, operador e sucesso. */
         if (
            ["success&request.resource&request.operator"].includes(
               this.filter
            ) &&
            this.filterBy &&
            this.filterBy.length
         ) {
            this.filterBy.forEach((item) =>
               query.filter.push({
                  attribute: item.attribute,
                  query: item.value,
               })
            );
         } else if (
            this.filter &&
            typeof this.filterBy === "string" &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         return query;
      },
      getRangeDateFromDate() {
         const getGte = (date) =>
            formatUtil.formatDate(moment(date).utc(), "YYYY-MM-DD[T]HH:mm:ss");
         const getLte = (date) =>
            formatUtil.formatDate(moment(date).utc(), "YYYY-MM-DD[T]HH:mm:ss");
         return {
            gte: getGte(this.date[0]),
            lte: getLte(this.date[this.date.length - 1]),
         };
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllAudits(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllAudits(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllAudits(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllAudits(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllAudits(this.query);
      },

      onChangeDate(date) {
         this.date = date;
         this.page = 1;
         this.getAllAudits(this.query);
      },

      onClickViewAudit(audit) {
         if (!this.expanded.find((item) => item.id === audit.id))
            this.expanded = [audit];
         else this.expanded = [];

         this.$emit("onClickViewAudit", audit);
      },

      async refreshAllAuditsByRef() {
         this.loadingAuditsFully = true;
         await this.getAllAudits(this.query);
         this.loadingAuditsFully = false;
      },

      async getAllAudits(query) {
         this.loadingAuditsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await auditService.getAllAudits(query);

            this.totalAudits = parseInt(response.headers["x-total-count"]);
            this.audits = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingAuditsPartially = false;
         }
      },

      getColor(code) {
         if (code >= 500) return "red";
         else if (code >= 400) return "orange";
         else if (code >= 300) return "#FBC02D";
         else if (code >= 200) return "green";
         else return "blue";
      },
   },

   async created() {
      this.date = [
         formatUtil.formatDate(
            moment().subtract(1, "hour"),
            "YYYY-MM-DD[T]HH:mm:ss"
         ),
         formatUtil.formatDate(moment(), "YYYY-MM-DD[T]HH:mm:ss"),
      ];

      this.loadingAuditsFully = true;
      await this.getAllAudits(this.query);
      this.loadingAuditsFully = false;
   },
};
</script>

<style scoped>
.json-container {
   background: #efefef;
   border-radius: 4px;
   max-width: 45vw;
}

@media (max-width: 959px) {
   .json-container {
      max-width: 80vw;
   }
}

.cursor-config {
   cursor: default !important;
}

.path-wrap {
   word-wrap: break-word !important;
   width: 100%;
}

@media (max-width: 959px) {
   .path-wrap {
      word-wrap: break-word !important;
      width: 60vw;
   }
}

.code-container {
   background-color: transparent !important;
   display: block;
   white-space: pre-wrap;
   padding: 0px;
}
</style>
