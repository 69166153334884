<template>
   <div>
      <FormTitle icon="mdi-doctor" title="GESTOR REGULADOR" />

      <v-alert
         v-if="getMode() === 'read' && formRegulatorManager.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Gestor regulador cadastrado em
         {{
            formatDate(
               formRegulatorManager.created_at,
               "DD/MM/YYYY [às] HH:mm"
            )
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card class="mb-3" :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulatorManager.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     :loading="loadingRegulatorManagerByCpf"
                     :readonly="!!formRegulatorManager.id"
                     id="cpf-regulator-manager-form-input"
                     label="CPF*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formRegulatorManager.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     id="name-regulator-manager-form-input"
                     label="Nome*"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulatorManager.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     id="phone-regulator-manager-form-input"
                     label="Telefone*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulatorManager.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     id="birth_date-regulator-manager-form-input"
                     label="Data de nascimento*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulatorManager.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-regulator-manager-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulatorManager.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-regulator-manager-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formRegulatorManager.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-regulator-manager-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulatorManager.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-regulator-manager-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulatorManager.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-regulator-manager-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formRegulatorManager.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     id="state-regulator-manager-form-input"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formRegulatorManager.address.complement"
                     id="complement-regulator-manager-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <div v-if="getMode() === 'save' || formRegulatorManager.crm">
               <p class="body-1 font-weight-medium mt-10">Dados médico</p>

               <v-row class="my-0">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formRegulatorManager.crm"
                        id="crm-regulator-manager-form-input"
                        label="CRM (opcional caso seja médico)"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </div>

            <p class="body-1 font-weight-medium mt-8">Dados de autenticação</p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulatorManager.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     id="email-regulator-manager-form-input"
                     label="Email*"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formRegulatorManager.id" cols="12" md="6">
                  <v-text-field
                     v-model="formRegulatorManager.password"
                     :rules="[
                        (value) => !!value || 'Senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     id="password-regulator-manager-form-input"
                     label="Senha* (mínimo 6 caracteres)"
                     type="password"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <UsersFormActions
         @onClickChangeType="onClickChangeType(regulatorManager)"
         @onClickReset="onClickResetRegulatorManager(regulatorManager)"
         @onClickEdit="onClickEditRegulatorManager"
         @onClickRemove="onClickRemoveRegulatorManager(regulatorManager)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRegulatorManager"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveRegulatorManager"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import UsersFormActions from "components/users/UsersFormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import regulatorManagerService from "../../services/regulatorManagerService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "utils/defaultUtil";

export default {
   name: "RegulatorManagerForm",

   components: { FormTitle, UsersFormActions },

   props: {
      regulatorManager: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formRegulatorManager: {},

         regulatorManagerMedic: true,

         loadingSaveRegulatorManager: false,
         loadingSearchZipCode: false,
         loadingRegulatorManagerByCpf: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   watch: {
      regulatorManager: {
         immediate: true,
         handler(regulatorManager) {
            if (regulatorManager.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formRegulatorManager =
               this.formatObjectToView(regulatorManager);

            this.resetValidation();
         },
      },

      "formRegulatorManager.address.zip_code": async function handler(
         newZip,
         oldZip
      ) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formRegulatorManager.address.street =
                     response.data.logradouro;
                  this.formRegulatorManager.address.district =
                     response.data.bairro;
                  this.formRegulatorManager.address.city =
                     response.data.localidade;
                  this.formRegulatorManager.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formRegulatorManager.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formRegulatorManager.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getRegulatorManagerByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      },
   },

   computed: {
      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(regulatorManager) {
         let regulatorManagerTemp = util.mergeObject(
            {
               address: {},
            },
            regulatorManager
         );

         if (regulatorManagerTemp.birth_date)
            regulatorManagerTemp.birth_date = formatUtil.formatDate(
               regulatorManagerTemp.birth_date
            );

         if (regulatorManagerTemp.cpf)
            regulatorManagerTemp.cpf = formatUtil.formatCPF(
               regulatorManagerTemp.cpf
            );

         if (regulatorManagerTemp.phone)
            regulatorManagerTemp.phone = formatUtil.formatPhone(
               regulatorManagerTemp.phone
            );

         return regulatorManagerTemp;
      },

      formatObjectToSend(regulatorManager, removeEmptyStrings) {
         let regulatorManagerTemp = util.removeBlankAttributes(
            regulatorManager,
            {
               emptyStrings: removeEmptyStrings,
            }
         );

         if (regulatorManagerTemp.birth_date)
            regulatorManagerTemp.birth_date = formatUtil.formatDate(
               regulatorManagerTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (regulatorManagerTemp.cpf)
            regulatorManagerTemp.cpf = regulatorManagerTemp.cpf.replace(
               /[^0-9]/g,
               ""
            );

         if (regulatorManagerTemp.phone)
            regulatorManagerTemp.phone = regulatorManagerTemp.phone.replace(
               /[^0-9]/g,
               ""
            );

         return regulatorManagerTemp;
      },

      onClickChangeType(regulatorManager) {
         this.$emit("onClickChangeType", regulatorManager);
      },

      onClickResetRegulatorManager(regulatorManager) {
         this.$emit("onClickResetRegulatorManager", regulatorManager);
      },

      onClickEditRegulatorManager() {
         this.setMode("save");
      },

      onClickRemoveRegulatorManager(regulatorManager) {
         this.$emit("onClickRemoveRegulatorManager", regulatorManager);
      },

      onClickCancel() {
         this.setMode("read");
         this.formRegulatorManager = this.formatObjectToView(
            this.regulatorManager
         );
         this.$emit("onClickCancel");
      },

      onClickSaveRegulatorManager() {
         if (this.formRegulatorManager.id)
            this.updateRegulatorManager(
               this.formRegulatorManager.id,
               this.formRegulatorManager
            );
         else this.createRegulatorManager(this.formRegulatorManager);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async getRegulatorManagerByCpf(cpf) {
         this.loadingRegulatorManagerByCpf = true;

         try {
            const response =
               await regulatorManagerService.getAllRegulatorManagers({
                  page: 1,
                  limit: 1,
                  filter: [
                     {
                        attribute: "active",
                        query: false,
                     },
                     {
                        attribute: "cpf",
                        query: cpf,
                     },
                  ],
               });

            if (response.data && response.data.length)
               this.$emit("onFindDisabledRegulatorManager", response.data[0]);
            else {
               const regulatorManagerResponse =
                  await checkCpfService.getCheckCpf(cpf);

               if (
                  regulatorManagerResponse.data &&
                  regulatorManagerResponse.data.name !== ""
               )
                  this.formRegulatorManager.name =
                     regulatorManagerResponse.data.name;
            }
         } catch (error) {
            if (
               error.response &&
               error.response.status === 404 &&
               error.response.data &&
               error.response.data.description ===
                  "Cpf provided does not belong to any registered user."
            ) {
               delete this.formRegulatorManager.name;
               snackBarUtil.showCustomSnackBar({
                  title: "CPF NÃO ENCONTRADO",
                  message:
                     "O CPF informado não foi encontrado na base de dados!",
                  icon: "mdi-account-off",
                  color: "info",
               });
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRegulatorManagerByCpf = false;
         }
      },

      async createRegulatorManager(regulatorManager) {
         if (!this.executeValidation() || !regulatorManager) return;

         this.loadingSaveRegulatorManager = true;

         try {
            await regulatorManagerService.createRegulatorManager(
               this.formatObjectToSend(regulatorManager)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor regulador foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedRegulatorManager");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRegulatorManager = false;
         }
      },

      async updateRegulatorManager(regulatorManagerId, regulatorManager) {
         if (
            !this.executeValidation() ||
            !regulatorManagerId ||
            !regulatorManager
         )
            return;

         this.loadingSaveRegulatorManager = true;

         try {
            await regulatorManagerService.updateRegulatorManager(
               regulatorManagerId,
               this.formatObjectToSend(regulatorManager, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor regulador foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRegulatorManager");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRegulatorManager = false;
         }
      },
   },
};
</script>

<style scoped>
</style>