<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="comorbidities.obesity"
            class="mr-3"
            label="Obesidade"
            id="obesity-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.sah"
            class="mr-3"
            label="HAS"
            id="sah-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.prior_ischemic_stroke"
            class="mr-3"
            label="Anosmia"
            id="prior_ischemic_stroke-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.pre_existing_lung_disease"
            class="mr-3"
            label="Doença pulmonar pré-existente"
            id="pre_existing_lung_disease-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.immunobiologicals"
            class="mr-3"
            label="Uso de imunobiológicos"
            id="immunobiologicals-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.prior_immunosuppressive"
            class="mr-3"
            label="Imunossupressor prévio"
            id="immunosuppressive-comorbidities-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="comorbidities.hiv_diagnosis"
            class="mr-3"
            label="Diagnóstico HIV"
            id="hiv_diagnosis-comorbidities-form-input"
         ></v-checkbox>
      </div>

      <v-row class="mt-1">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="comorbidities.prior_ischemic_heart_disease_ef"
               label="Cardiopatia esquemia previa - FE"
               id="prior_ischemic_heart_disease_ef-comorbidities-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="comorbidities.chronic_kidney_injury_stage"
               label="Lesão renal crônica - Estágio"
               id="chronic_kidney_injury_stage-comorbidities-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="comorbidities.gestational_age"
               label="Gestação – Idade gestacional"
               id="gestational_age-comorbidities-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="comorbidities.pregnancy_childbirth"
               label="Gesta/Para"
               id="pregnancy_childbirth-comorbidities-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="6">
            <v-text-field
               v-model="comorbidities.transplant_history"
               label="História de transplante"
               id="transplant_history-comorbidities-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="6">
            <v-text-field
               v-model="comorbidities.obstetric_comorbidities"
               label="Comorbidade obstetricas"
               id="obstetric_comorbidities-comorbidities-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5 mb-0">
         <v-col cols="12">
            <v-text-field
               v-model="comorbidities.other_heart_diseases"
               label="Outras cardiopatias"
               id="other_heart_diseases-comorbidities-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "ComorbiditiesForm",

   props: {
      comorbidities: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>