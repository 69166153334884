<template>
   <div>
      <v-fade-transition>
         <div v-if="dialogHistoryVisibility" class="overlay-config">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="history-overlay"
               tile
               flat
            >
               <Close class="align-self-end" @onClickClose="closeHistory" />

               <History class="mt-n5 mb-n5" :request="request" />
            </v-card>
         </div>
      </v-fade-transition>

      <v-fade-transition>
         <div v-if="dialogChatVisibility" class="overlay-config">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="chat-drawer"
               elevation="24"
               tile
               flat
            >
               <Close @onClickClose="closeChat" dense />

               <Chat
                  @onSendMessage="onSendMessage"
                  :request="request"
                  :disable="disableChat"
                  class="standard-padding flex-grow-1 mt-3"
               />
            </v-card>
         </div>
      </v-fade-transition>

      <v-dialog
         v-model="dialogBedReserveFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1100"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeBedReserveForm" />

            <BedReserveForm
               @onClickViewBed="onClickViewBed"
               :request="request"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <FormTitle
         v-if="!hideTitle"
         icon="mdi-file-document"
         title="SOLICITAÇÃO DE LEITO"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="
            request.status === 'rejected' ||
            request.status === 'rejected_by_executor'
         "
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <RequestSummary :request="formRequest" />

      <v-expansion-panels v-model="patientData" flat>
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div class="d-flex align-center">
                  <p>Dados do paciente</p>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-card :disabled="getMode() === 'read'" flat>
                  <div v-if="requestPatient && requestPatient.id">
                     <ViewPatient
                        :patient="requestPatient"
                        class="mt-n5"
                        hideTitle
                     />
                  </div>

                  <div v-else class="d-flex align-center">
                     <v-chip class="ma-0 mr-2" color="black" dark label>
                        <p class="body-2">Anônimo</p>

                        <v-icon right small> mdi-incognito </v-icon>
                     </v-chip>

                     <p class="body-2">
                        Essa solicitação não foi associada a um paciente.
                     </p>
                  </div>
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form readonly>
         <v-expansion-panels v-model="requestData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div class="d-flex align-center">
                     <p>Sinais vitais e tipo de leito</p>
                  </div>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-card disabled flat>
                     <PatientData :patientData="formRequest.clinical_data" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>

         <v-expansion-panels multiple flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Síndrome gripal
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <FluSyndromeForm
                        :fluSyndrome="formRequest.clinical_data.flu_syndrome"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Comorbidades
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <ComorbiditiesForm
                        :comorbidities="formRequest.clinical_data.comorbidities"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Oximetria periférica – Sistema Respiratório e Ventilação
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <RespiratoryForm
                        :respiratory="formRequest.clinical_data.respiratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Verificação de PA/FC/FR – Cardiovascular e Infecção
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <CardiovascularForm
                        :cardiovascular="
                           formRequest.clinical_data.cardiovascular
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> SARS CoV-2 </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <Sarscov2Form
                        :sarscov2="formRequest.clinical_data.sarscov2"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Desorientação, padrão neurologico, nível de consciência
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <NeurologicalForm
                        :neurological="formRequest.clinical_data.neurological"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Laboratório
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <LaboratoryForm
                        :laboratory="formRequest.clinical_data.laboratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> Imagem </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <ImageExamForm
                        :imageExam="formRequest.clinical_data.image_exams"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Recomendações do CERH
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <CerhRecommendationsForm
                        :cerhRecommendations="
                           formRequest.clinical_data.cerh_recommendations
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> NEWS FAST </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <NewsFastForm
                        :regulationData="formRequest.clinical_data"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> UTI </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <UtiForm :uti="formRequest.clinical_data.uti" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-form
         :readonly="getMode() === 'read' || !user || (user && !user.crm)"
         ref="form"
         id="doctor-opinion"
      >
         <v-expansion-panels v-model="regulationData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Parecer médico
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-alert
                     v-if="!user || (user && !user.crm)"
                     class="caption"
                     icon="mdi-alert"
                     type="warning"
                     border="left"
                     dense
                     text
                     >Somente regulador médico pode alterar o
                     <strong>"Parecer médico"</strong>.
                  </v-alert>

                  <v-card
                     :disabled="
                        getMode() === 'read' || !user || (user && !user.crm)
                     "
                     flat
                  >
                     <DoctorOpinionForm
                        :doctorOpinion="formRequest.doctor_opinion"
                        ref="doctoropinion"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-card flat>
         <v-divider class="mt-9"></v-divider>

         <p class="body-1 font-weight-medium mt-6 mb-3">
            Anexo(s)
            <v-icon>mdi-paperclip</v-icon>
         </p>

         <RequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            hideRemoveButton
            hideAddButton
         />
      </v-card>

      <RequestRegulatorFormActions
         v-if="!hideActions"
         @onClickHistory="onClickHistoryRequest"
         @onClickPrint="onClickPrintRequest"
         @onClickChat="onClickChatRequest"
         @onClickEdit="onClickEditRequest"
         @onClickReserveBed="onClickReserveBed"
         @onClickRemove="onClickRemoveRequest"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRequest"
         @onClickCheck="onClickCheck"
         :mode="getMode()"
         :hideCheckButton="hideCheckButton"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :hideReserveBedButton="hideReserveBedButton"
         :unreadMessages="unreadMessages"
         :loadingSave="loadingSaveRequest"
         :loadingCheck="loadingCheckRequest"
         :hideChatButton="request.is_closed"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import Close from "../../../components/base/Close";
import History from "components/requestBase/history/History";
import Chat from "components/requestBase/chat/Chat";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import RequestAttachments from "components/requestBase/subForm/RequestAttachments";
import RequestRegulatorFormActions from "../../../components/request/form/RequestRegulatorFormActions";
import RequestSummary from "../../../components/request/form/subForm/RequestSummary";
import PatientData from "../../../components/request/form/subForm/PatientData";
import FluSyndromeForm from "../../../components/request/form/subForm/FluSyndromeForm";
import ComorbiditiesForm from "../../../components/request/form/subForm/ComorbiditiesForm";
import RespiratoryForm from "../../../components/request/form/subForm/RespiratoryForm";
import CardiovascularForm from "../../../components/request/form/subForm/CardiovascularForm";
import Sarscov2Form from "../../../components/request/form/subForm/Sarscov2Form";
import NeurologicalForm from "../../../components/request/form/subForm/NeurologicalForm";
import LaboratoryForm from "../../../components/request/form/subForm/LaboratoryForm";
import ImageExamForm from "../../../components/request/form/subForm/ImageExamForm";
import CerhRecommendationsForm from "../../../components/request/form/subForm/CerhRecommendationsForm";
import NewsFastForm from "../../../components/request/form/subForm/NewsFastForm";
import UtiForm from "../../../components/request/form/subForm/UtiForm";
import DoctorOpinionForm from "../../../components/request/form/subForm/DoctorOpinionForm";
import BedReserveForm from "../../../components/bed/bedReserve/BedReserveForm";
import ViewPatient from "components/patient/ViewPatient";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import requestService from "../../../services/requestService";
import snackBarUtil from "../../../utils/snackBarUtil";
import responsiveUtil from "../../../utils/responsiveUtil";
import util from "../../../utils/util";
import requestPdfExport from "export/pdf/requestPdfExport";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import { ALLOWED_TYPES, MAXIMUM_SIZE } from "utils/defaultUtil";
import basePdfExport from "export/pdf/basePdfExport";

export default {
   name: "RequestRegulatorForm",

   components: {
      Close,
      FormTitle,
      History,
      Chat,
      OutcomeDetails,
      JustificationRejection,
      OriginAndDestinyDetails,
      RequestAttachments,
      RequestRegulatorFormActions,
      RequestSummary,
      PatientData,
      FluSyndromeForm,
      ComorbiditiesForm,
      RespiratoryForm,
      CardiovascularForm,
      Sarscov2Form,
      NeurologicalForm,
      LaboratoryForm,
      ImageExamForm,
      CerhRecommendationsForm,
      NewsFastForm,
      UtiForm,
      DoctorOpinionForm,
      BedReserveForm,
      ViewPatient,
   },

   props: {
      request: {
         type: Object,
      },

      /* Pode ser um regulator ou um regulatorManager */
      user: {
         type: Object,
      },

      hideActions: {
         type: Boolean,
         default: false,
      },

      hideCheckButton: {
         type: Boolean,
         default: false,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideReserveBedButton: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },

      fullscreenParentDialog: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",
         formRequest: {},

         loadingSaveRequest: false,
         loadingCheckRequest: false,

         patientData: 0,
         requestData: 0,
         regulationData: [],

         dialogBedReserveFormVisibility: false,
         dialogChatVisibility: false,

         dialogHistoryVisibility: false,

         requestPatient: {},
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      unreadMessages() {
         if (this.request.unreadMessages) return this.request.unreadMessages;
         else return 0;
      },

      disableChat() {
         return this.request.is_closed;
      },
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.patientData = 0;

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();

            if (request.id && request.patient && request.patient.id) {
               this.requestPatient = Object.assign({}, request.patient);
            }
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,
      ...responsiveUtil,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject(
            {
               clinical_data: {
                  patient_data: {},
                  flu_syndrome: {},
                  comorbidities: {},
                  respiratory: {},
                  cardiovascular: {},
                  sarscov2: {
                     rt_pcr: {},
                     corticosteroid: {},
                     hydroxychloroquine: {},
                     oseltamivir: {},
                     heparin: {},
                  },
                  neurological: {},
                  laboratory: {},
                  image_exams: {},
                  cerh_recommendations: {},
                  uti: {},
               },
               doctor_opinion: {
                  patient_not_fit_for_regulation: false,
               },
            },
            request
         );

         if (requestTemp.clinical_data.sarscov2.rt_pcr.date)
            requestTemp.clinical_data.sarscov2.rt_pcr.date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.rt_pcr.date
               );

         if (requestTemp.clinical_data.sarscov2.corticosteroid.start_date)
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.corticosteroid.start_date
               );

         if (requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date)
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.hydroxychloroquine
                     .start_date
               );

         if (requestTemp.clinical_data.sarscov2.oseltamivir.start_date)
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.oseltamivir.start_date
               );

         if (requestTemp.clinical_data.sarscov2.heparin.start_date)
            requestTemp.clinical_data.sarscov2.heparin.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.heparin.start_date
               );

         if (requestTemp.clinical_data.laboratory.date)
            requestTemp.clinical_data.laboratory.date = formatUtil.formatDate(
               requestTemp.clinical_data.laboratory.date
            );

         return requestTemp;
      },

      formatObjectToSend(request) {
         let requestTemp = util.cloneObject(request);

         if (requestTemp.clinical_data.sarscov2.rt_pcr.date)
            requestTemp.clinical_data.sarscov2.rt_pcr.date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.rt_pcr.date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );

         if (requestTemp.clinical_data.sarscov2.corticosteroid.start_date)
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.corticosteroid.start_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );

         if (requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date)
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.hydroxychloroquine
                     .start_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );

         if (requestTemp.clinical_data.sarscov2.oseltamivir.start_date)
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.oseltamivir.start_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );

         if (requestTemp.clinical_data.sarscov2.heparin.start_date)
            requestTemp.clinical_data.sarscov2.heparin.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.heparin.start_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );

         if (requestTemp.clinical_data.laboratory.date)
            requestTemp.clinical_data.laboratory.date = formatUtil.formatDate(
               requestTemp.clinical_data.laboratory.date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return util.removeBlankAttributes(requestTemp);
      },

      onClickChatRequest() {
         this.openChat();
      },

      onClickHistoryRequest() {
         this.openHistory();
      },

      async getUserById(user) {
         let response;

         switch (user.sub_type) {
            case "admin":
               response = await adminService.getAdminById(user.sub);
               break;
            case "manager":
               response = await managerService.getManagerById(user.sub);
               break;
            case "healthunit_manager":
               response =
                  await healthUnitManagerService.getHealthUnitManagerById(
                     user.sub
                  );
               break;
            case "solicitator":
            case "executor":
               response = await operatorService.getOperatorById(user.sub);
               break;
            case "regulator_manager":
               response = await regulatorManagerService.getRegulatorManagerById(
                  user.sub
               );
               break;
            case "regulator":
               response = await regulatorService.getRegulatorById(user.sub);
               break;
         }

         return response;
      },

      async onClickPrintRequest() {
         this.loadingPrint = true;

         try {
            const userResponse = await this.getUserById(this.userStore);

            const pdf = requestPdfExport.generateRequestPDF(
               this.formRequest,
               userResponse.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrint = false;
         }
      },

      onClickEditRequest() {
         this.setMode("save");
      },

      onClickReserveBed() {
         this.openBedReserveForm();
      },

      onClickRemoveRequest() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formRequest = this.formatObjectToView(this.request);
         this.$emit("onClickCancel");
      },

      onClickSaveRequest() {
         if (this.formRequest.id)
            this.updateRequest(this.formRequest.id, this.formRequest);
      },

      onClickCheck() {
         if (this.formRequest.id) this.checkRequest(this.formRequest.id);
      },

      onClickViewBed(reserveData) {
         const methodUpdateItem = async () => {
            this.formRequest.bed_id = reserveData.bed.id;

            /* Remove o atributo doctor_opinion, evitando uma resposta 401 não autorizada */
            const requestWithoutDoctorOpinion = util.cloneObject(
               this.formRequest
            );
            delete requestWithoutDoctorOpinion.doctor_opinion;

            if (this.request.id)
               await this.updateRequest(
                  this.formRequest.id,
                  requestWithoutDoctorOpinion
               );
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-bed",
            title: "CONFIRMAÇÃO DE RESERVA!",
            message: "Deseja realmente reservar esse leito?",
            action: {
               text: "Confirmar",
               method: methodUpdateItem,
               closeOnFinished: false,
            },
         });
      },

      setMode(mode) {
         this.mode = mode;

         if (mode === "read") {
            this.requestData = 0;
            this.regulationData = null;
         } else {
            this.requestData = null;
            this.regulationData = 0;

            var formDoctorOpinion = document.getElementById("doctor-opinion");
            if (formDoctorOpinion)
               formDoctorOpinion.scrollIntoView({ behavior: "smooth" });
         }
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         let justificationValidation = true;
         let doctorOpinionValidation = true;

         if (this.$refs.form)
            justificationValidation = this.$refs.form.validate();
         if (this.$refs.doctoropinion)
            doctorOpinionValidation = this.$refs.doctoropinion.validate();

         return justificationValidation && doctorOpinionValidation;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();

         if (this.$refs.doctoropinion)
            this.$refs.doctoropinion.resetValidation();

         this.requestPatient = {};
      },

      openBedReserveForm() {
         this.dialogBedReserveFormVisibility = true;
      },

      closeBedReserveForm() {
         this.dialogBedReserveFormVisibility = false;
      },

      openHistory() {
         this.dialogHistoryVisibility = true;
         this.$emit("onOpenHistory");
      },

      closeHistory() {
         this.dialogHistoryVisibility = false;
         this.$emit("onCloseHistory");
      },

      openChat() {
         this.dialogChatVisibility = true;
         this.$emit("onOpenChat");
      },

      closeChat() {
         this.dialogChatVisibility = false;
         this.$emit("onCloseChat");
      },

      onSendMessage(request, message) {
         this.$emit("onSendMessage", request, message);
      },

      async updateRequest(requestId, request) {
         if (!this.executeValidation() || !requestId || !request) return;

         this.loadingSaveRequest = true;

         try {
            await requestService.updateRequest(
               requestId,
               this.formatObjectToSend(request)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      },

      async checkRequest(requestId) {
         if (!requestId) return;

         this.loadingCheckRequest = true;

         try {
            await requestService.checkRequest(requestId);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi validada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingCheckRequest = false;
         }
      },
   },
};
</script>

<style scoped>
.overlay-config {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.history-overlay {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 1000 !important;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}
</style>
