<template>
   <div class="close-position" :class="dense ? 'spacing-small' : 'spacing'">
      <v-btn
         @click="$emit('onClickClose')"
         :width="dense ? 26 : 35"
         :height="dense ? 26 : 35"
         :color="color"
         class="close-button"
         id="close-button"
         fab
         depressed
         text
      >
         <v-icon :color="color" :size="dense ? 20 : 24">mdi-close</v-icon>
      </v-btn>
   </div>
</template>

<script>
export default {
   name: "Close",

   props: {
      color: {
         type: String,
         default: "primary",
      },

      dense: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
.close-position {
   position: sticky;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   z-index: 999;
   pointer-events: none;
}

.spacing {
   top: 15px;
   padding-right: 15px;
   margin-bottom: -35px;
}

@media (max-width: 959px) {
   .spacing {
      top: 12px;
      padding-right: 9px;
   }
}

.spacing-small {
   top: 8px;
   padding-right: 10px;
   margin-bottom: -26px;
}

@media (max-width: 959px) {
   .spacing-small {
      top: 7px;
      padding-right: 6px;
   }
}

.close-button {
   pointer-events: auto;
}
</style>