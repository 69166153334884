<template>
   <div>
      <p class="body-1 font-weight-medium mt-5">Confirmação de desfecho</p>

      <p class="caption mt-5 mb-1">Tipo de desfecho*</p>

      <v-chip-group
         v-model="formOutcome.outcome"
         @change="(value) => (invalidOutcome = !value)"
         class="my-n2"
         column
      >
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_cure' ? 'primary' : 'normal'
            "
            value="discharge_cure"
            id="discharge_cure-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta Curado
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_improvement'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_improvement"
            id="discharge_improvement-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta Melhorado
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_on_request'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_on_request"
            id="discharge_on_request-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta a pedido
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_with_return'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_with_return"
            id="discharge_with_return-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta com previsão de retorno
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_evasion'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_evasion"
            id="discharge_evasion-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta por Evasão
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'discharge_other_reasons'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_other_reasons"
            id="discharge_other_reasons-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Alta por outros motivos
         </v-chip>
         <!-- <v-chip
            :color="
               formOutcome.outcome === 'permanence_characteristics_disease'
                  ? 'primary'
                  : 'normal'
            "
            value="permanence_characteristics_disease"
            id="permanence_characteristics_disease-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Por características próprias da doença
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'permanence_complication'
                  ? 'primary'
                  : 'normal'
            "
            value="permanence_complication"
            id="permanence_complication-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Por intercorrência
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'permanence_sociofamily_impossibility'
                  ? 'primary'
                  : 'normal'
            "
            value="permanence_sociofamily_impossibility"
            id="permanence_sociofamily_impossibility-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Por impossibilidade sócio-familiar
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'permanence_other_reasons'
                  ? 'primary'
                  : 'normal'
            "
            value="permanence_other_reasons"
            id="permanence_other_reasons-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Outros motivos
         </v-chip> -->
         <v-chip
            :color="formOutcome.outcome === 'transfer' ? 'primary' : 'normal'"
            value="transfer"
            id="transfer-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Transfêrencia
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'death_certificate_by_doctor'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_doctor"
            id="death_certificate_by_doctor-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Declaração de óbito fornecida pelo médico
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'death_certificate_by_iml'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_iml"
            id="death_certificate_by_iml-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Declaração de óbito fornecida pelo IML
         </v-chip>
         <v-chip
            :color="
               formOutcome.outcome === 'death_certificate_by_svo'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_svo"
            id="death_certificate_by_svo-bed-request-confirm-outcome-form-button"
            filter
            small
         >
            Declaração de óbito fornecida pelo SVO
         </v-chip>
      </v-chip-group>

      <v-expand-transition>
         <p v-if="invalidOutcome" class="caption error--text mt-1">
            Tipo de desfecho obrigatória.
         </p>
      </v-expand-transition>

      <v-row class="mt-2">
         <v-col cols="12">
            <v-textarea
               v-model="formOutcome.outcome_justification"
               class="outcome-height"
               label="Justificativa"
               id="outcome_justification-bed-request-confirm-outcome-form-input"
               rows="4"
               no-resize
            ></v-textarea>
         </v-col>
      </v-row>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickConfirmOutcome"
         :loadingSave="loadingSaveOutcome"
         :mode="'save'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormActions from "../../../components/base/form/FormActions";
import requestService from "../../../services/requestService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";

export default {
   name: "BedRequestConfirmOutcomeForm",

   components: { FormActions },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         formOutcome: {},

         loadingSaveOutcome: false,

         invalidOutcome: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler() {
            this.formOutcome = {};

            this.resetValidation();
         },
      },
   },

   methods: {
      formatObjectToSend(outcome) {
         let outcomeTemp = util.removeBlankAttributes(outcome);

         return outcomeTemp;
      },

      executeValidation() {
         this.invalidOutcome = !this.formOutcome.outcome;
         return !this.invalidOutcome;
      },

      resetValidation() {
         this.invalidOutcome = false;
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickConfirmOutcome() {
         if (
            this.request &&
            this.request.id &&
            this.request.source_health_entity &&
            this.request.source_health_entity.id
         )
            this.updateHealthUnitRequestOutcome(
               this.request.source_health_entity.id,
               this.request.id,
               this.formOutcome
            );
      },

      async updateHealthUnitRequestOutcome(healthUnitId, requestId, outcome) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !requestId ||
            !outcome
         )
            return;

         this.loadingSaveOutcome = true;

         try {
            await requestService.updateHealthUnitRequestOutcome(
               healthUnitId,
               requestId,
               this.formatObjectToSend(outcome)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O desfecho do paciente foi confirmado com sucesso!",
            });

            this.$emit("onConfirmOutcome");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveOutcome = false;
         }
      },
   },
};
</script>

<style scoped>
.outcome-height {
   height: 100%;
}
</style>