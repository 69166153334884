<template>
   <v-app-bar color="primary" height="64px" app>
      <div class="d-flex justify-space-between flex-grow-1">
         <div>
            <v-btn
               v-if="appBarMenuVisibilityStore"
               @click="setNavigationDrawerVisibilityStore"
               id="menu-button"
               icon
               dark
            >
               <v-icon>mdi-menu</v-icon>
            </v-btn>
         </div>

         <div class="d-flex align-center">
            <NotificationCenter
               v-if="
                  ['regulator_manager', 'regulator'].includes(
                     userStore.sub_type
                  )
               "
            />

            <v-menu offset-y transition="slide-y-transition">
               <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" id="logout-button" icon dark>
                     <v-icon>mdi-account-circle</v-icon>
                  </v-btn>
               </template>

               <v-list>
                  <div v-if="currentHealthUnit">
                     <div class="d-flex flex-column align-center px-4 py-4">
                        <v-icon large>mdi-hospital-building</v-icon>

                        <p
                           class="healthunit-name font-weight-medium text-center my-2"
                        >
                           {{ currentHealthUnit.name }}
                        </p>

                        <v-btn
                           @click="changeHealthUnit"
                           class="text--secondary"
                           rounded
                           x-small
                           depressed
                           >Alterar</v-btn
                        >
                     </div>

                     <v-divider></v-divider>
                  </div>

                  <v-list-item
                     id="my-account-app-bar-button"
                     color="primary"
                     to="/minha-conta"
                  >
                     <v-icon left>mdi-cogs</v-icon>
                     <v-list-item-title class="body-2"
                        >Minha conta</v-list-item-title
                     >
                  </v-list-item>

                  <v-list-item color="primary" @click="logout">
                     <v-icon left>mdi-exit-to-app</v-icon>
                     <v-list-item-title class="body-2">Sair</v-list-item-title>
                  </v-list-item>
               </v-list>
            </v-menu>
         </div>
      </div>

      <div class="div-logo">
         <v-icon size="150" class="shadow-svg">
            $hospital-bed
         </v-icon>
      </div>
   </v-app-bar>
</template>

<script>
import userService from "../../services/userService";
import NotificationCenter from "./NotificationCenter.vue";

export default {
   name: "AppBar",

   components: { NotificationCenter },

   computed: {
      appBarMenuVisibilityStore: {
         get() {
            return this.$store.getters["appBar/getMenuVisibility"];
         },

         set(value) {
            this.$store.commit("appBar/setMenuVisibility", value);
         },
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         },
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         },
      },

      healthSecretaryManagerCurrentHealthSecretaryStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         },
      },

      currentHealthUnit() {
         return (
            this.operatorCurrentHealthUnitStore ||
            this.healthUnitManagerCurrentHealthUnitStore ||
            this.healthSecretaryManagerCurrentHealthSecretaryStore
         );
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         },
      },
   },

   methods: {
      setNavigationDrawerVisibilityStore() {
         this.$store.commit("navigationDrawer/setVisibility");
      },

      logout() {
         userService.removeUserSession();

         if (this.$route.path !== "/autenticacao")
            this.$router.push({ path: "/autenticacao" });
      },

      changeHealthUnit() {
         this.selectHealthUnitVisibilityStore = true;
      },
   },
};
</script>

<style scoped>
.div-logo {
   pointer-events: none;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   width: 98%;
}

.healthunit-name {
   width: 210px;
   white-space: normal;
   word-break: break-word;

   font-size: 0.8rem !important;
}

.shadow-svg {
   filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
}
</style>