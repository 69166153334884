<template>
   <div>
      <BedReserveListWardSkeleton v-if="loadingBeds" />

      <v-card
         v-else
         class="d-flex flex-column justify-center standard-padding-small"
         outlined
      >
         <div class="d-flex align-center flex-wrap">
            <v-icon left>mdi-map-marker</v-icon>
            <p class="mr-4">{{ ward.name }}</p>
         </div>

         <v-divider class="my-4"></v-divider>

         <div v-if="wardBeds.length" class="d-flex align-center flex-wrap">
            <BedReserveListWardBedCard
               v-for="bed in wardBeds"
               @onClickViewBed="onClickViewBed"
               :key="bed.id"
               :bed="bed"
               class="ma-1"
            />
         </div>
         <div v-else>
            <NoData
               icon="mdi-block-helper"
               message="Essa ala não tem nenhum leito livre."
            />
         </div>
      </v-card>
   </div>
</template>

<script>
import BedReserveListWardBedCard from "../bedReserve/BedReserveListWardBedCard";
import BedReserveListWardSkeleton from "../bedReserve/BedReserveListWardSkeleton";
import NoData from "../../base/NoData";
import bedService from "../../../services/bedService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "BedReserveListWardCard",

   components: {
      BedReserveListWardBedCard,
      BedReserveListWardSkeleton,
      NoData,
   },

   props: {
      ward: {
         type: Object,
      },
   },

   data: function () {
      return {
         loadingBeds: true,

         wardBeds: [],
      };
   },

   watch: {
      ward: {
         immediate: true,
         handler(ward) {
            if (ward && ward.id) this.getAllBeds(ward.id);
         },
      },
   },

   methods: {
      async getAllBeds(wardId) {
         this.loadingBeds = true;

         try {
            const response = await bedService.getAllBeds(wardId, {
               page: 1,
               limit: 100,
               filter: [
                  {
                     attribute: "active",
                     query: true,
                  },
               ],
            });

            this.wardBeds = Object.assign([], response.data).filter(
               (item) => item.status === "free"
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingBeds = false;
         }
      },

      onClickViewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },
   },
};
</script>

<style scoped>
</style>