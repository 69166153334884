<template>
   <div>
      <v-dialog
         :value="loadingStatistics"
         transition="fade-transition"
         overlay-color="black"
         fullscreen
      >
         <div class="div-loading">
            <v-progress-circular
               indeterminate
               color="white"
               size="80"
               width="8"
            ></v-progress-circular>
         </div>
      </v-dialog>

      <v-dialog
         v-model="dialogStatisticFilterVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeStatisticFilter" />

            <SurgeryStatisticFilter
               @onApplyFilter="onApplyFilter"
               class="standard-padding"
               ref="statisticfilter"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogStatisticRelatoryExportVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeStatisticRelatoryExport" />

            <SurgeryStatisticExport
               @onClickWaitingSurgeryList="onClickWaitingSurgeryList"
               :data="statistics"
               ref="surgerystatisticexport"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-alert
         class="mt-12 mb-8"
         color="primary"
         type="info"
         border="left"
         text
      >
         <div class="d-flex justify-space-between align-center">
            <p id="filter-statistic-content-text">
               Você está visualizando dados da(o)
               <strong>{{ healthEntityFilterText }}</strong
               >, de
               <strong>
                  {{ filter.rangeDate.startDate }}
               </strong>
               até
               <strong> {{ filter.rangeDate.endDate }}. </strong>
            </p>
         </div>
      </v-alert>

      <div class="mb-3">
         <div class="d-flex align-center  ma-n1">
            <div class="ma-1">
               <v-btn
                  @click="clearFilter"
                  id="clear-primary-filter-button"
                  class="d-none d-md-flex"
                  color="primary"
                  small
                  text
                  >Limpar filtro
               </v-btn>

               <v-btn
                  @click="clearFilter"
                  id="clear-secondary-filter-button"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  text
                  x-small
               >
                  <v-icon>mdi-filter-off</v-icon>
               </v-btn>
            </div>

            <div class="ma-1">
               <v-btn
                  @click="openStatisticFilter"
                  id="add-primary-filter-button"
                  class="d-none d-md-flex"
                  color="primary"
                  small
                  depressed
                  >Filtrar
                  <v-icon small right> mdi-filter </v-icon>
               </v-btn>

               <v-btn
                  @click="openStatisticFilter"
                  id="add-secondary-filter-button"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-filter</v-icon>
               </v-btn>
            </div>

            <div class="ma-1">
               <v-btn
                  @click="openStatisticRelatoryExport"
                  id="export-primary-export-button"
                  class="d-none d-md-flex"
                  color="primary"
                  small
                  depressed
               >
                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-chip
                           v-bind="attrs"
                           v-on="on"
                           color="info"
                           class="mr-1 pa-1"
                           x-small
                        >
                           Novo
                        </v-chip>
                     </template>

                     <p class="text-center">
                        Novos status para <br />
                        cirurgias rejeitadas
                     </p>
                  </v-tooltip>
                  Exportar
                  <v-icon small right> mdi-download </v-icon>
               </v-btn>

               <v-btn
                  @click="openStatisticRelatoryExport"
                  id="export-secondary-filter-button"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-download</v-icon>
               </v-btn>
            </div>
         </div>
      </div>

      <v-row>
         <v-col cols="12" lg="6">
            <v-card class="standard-padding" height="100%" color="primary">
               <RegulationChart :statistics="statistics.date_profile" />
            </v-card>
         </v-col>

         <v-col cols="12" lg="6">
            <v-card class="standard-padding" height="100%">
               <p class="title text--secondary mt-n1">Faixa etária</p>

               <AgeGroupChart :statistics="statistics.age_profile" />
            </v-card>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" md="6">
            <v-card class="standard-padding" height="100%">
               <p class="title text--secondary mt-n1">Nível de atenção</p>

               <AttentionLevelChart
                  :statistics="statistics.attention_level_profile"
               />
            </v-card>
         </v-col>

         <v-col cols="12" md="6">
            <v-card class="standard-padding" height="100%">
               <p class="title text--secondary mt-n1">Perfil de gênero</p>

               <GenderProfileChart :statistics="statistics.gender_profile" />
            </v-card>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12">
            <v-card class="standard-padding" height="100%">
               <p class="title text--secondary mt-n1">Cirurgias</p>

               <SurgeriesChart :statistics="statistics.surgeries" />
            </v-card>
         </v-col>
      </v-row>

      <v-card class="standard-padding mt-6" height="100%">
         <p class="title text--secondary mt-n1">Regulações por municípios</p>

         <v-row>
            <v-col class="d-flex flex-column justify-center" cols="12" md="8">
               <MapParaibaChart
                  :statistics="statistics.source_municipalities"
               />
            </v-col>
            <v-col cols="12" md="4">
               <RegulationByMunicipalityChart
                  :statistics="statistics.source_municipalities"
               />
            </v-col>
         </v-row>
      </v-card>
   </div>
</template>

<script>
import Close from "components/base/Close";
import SurgeryStatisticFilter from "components/surgeryStatistic/SurgeryStatisticFilter";
import RegulationChart from "components/statistic/charts/RegulationChart";
import AgeGroupChart from "components/statistic/charts/AgeGroupChart";
import AttentionLevelChart from "components/statistic/charts/AttentionLevelChart";
import GenderProfileChart from "components/statistic/charts/GenderProfileChart";
import SurgeriesChart from "components/surgeryStatistic/charts/SurgeriesChart";
import MapParaibaChart from "components/statistic/charts/MapParaibaChart";
import RegulationByMunicipalityChart from "components/statistic/charts/RegulationByMunicipalityChart";
import SurgeryStatisticExport from "./SurgeryStatisticExport";
import responsiveUtil from "utils/responsiveUtil";
import formatUtil from "utils/formatUtil";
import moment from "moment";
import snackBarUtil from "utils/snackBarUtil";
import statisticsService from "services/statisticsService";

export default {
   name: "StatisticContent",

   components: {
      Close,
      SurgeryStatisticFilter,
      RegulationChart,
      AgeGroupChart,
      AttentionLevelChart,
      GenderProfileChart,
      SurgeriesChart,
      MapParaibaChart,
      RegulationByMunicipalityChart,
      SurgeryStatisticExport
   },

   data: function() {
      return {
         dialogStatisticFilterVisibility: false,
         dialogStatisticRelatoryExportVisibility: false,
         loadingStatistics: false,

         statistics: {},

         filter: {
            healthEntity: null,
            rangeDate: {
               endDate: null,
               startDate: null
            }
         }
      };
   },

   computed: {
      healthEntityFilterText() {
         if (this.filter.healthEntity && this.filter.healthEntity.id)
            return this.filter.healthEntity.name;
         else return "Paraíba";
      }
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      closeStatisticFilter() {
         this.dialogStatisticFilterVisibility = false;
      },

      openStatisticFilter() {
         this.dialogStatisticFilterVisibility = true;
      },

      closeStatisticRelatoryExport() {
         this.dialogStatisticRelatoryExportVisibility = false;
      },

      openStatisticRelatoryExport() {
         this.dialogStatisticRelatoryExportVisibility = true;
      },

      clearFilter() {
         if (this.$refs.statisticfilter) this.$refs.statisticfilter.resetAll();
      },

      mountQueryFromFilter(filter) {
         let query = { filter: [] };

         /* Adiciona o filtro de cirurgias. */
         if (filter.surgeriesSelected && filter.surgeriesSelected.length) {
            query.filter.push({
               attribute: "sus_code",
               query: filter.surgeriesSelected
                  .map(item => item.sus_code)
                  .join(",")
            });
         }

         /* Adiciona o filtro de municipios. */
         if (filter.municipality && filter.municipality.length) {
            query.filter.push({
               attribute: "municipality_id",
               query: filter.municipality.map(item => item.id).join(",")
            });
         }

         /* Adiciona o filtro de cirurgias aceitas. */
         if (filter.acceptedSurgeries && filter.acceptedSurgeries.length === 1)
            filter.acceptedSurgeries.forEach(item => {
               query.filter.push({
                  attribute: "is_available",
                  query: item.toString()
               });
            });

         /* Adiciona o filtro de estado de solicitação. */
         if (filter.requestStatus && filter.requestStatus.length) {
            let requestStatusTemp = Object.assign([], filter.requestStatus);

            /* Resolve o problema do status rejected e rejected_by_executor, já que
            ambos precisam de um filtro a mais, o 'is_closed'. */
            if (requestStatusTemp.some(item => item.includes("rejected"))) {
               if (requestStatusTemp.includes("rejected_by_executor")) {
                  requestStatusTemp[
                     requestStatusTemp.indexOf("rejected_by_executor")
                  ] = "rejected";

                  query.filter.push({
                     attribute: "is_closed",
                     query: "false"
                  });
               } else if (requestStatusTemp.includes("rejected"))
                  query.filter.push({
                     attribute: "is_closed",
                     query: "true"
                  });
            }

            if (
               requestStatusTemp.includes("registered") ||
               requestStatusTemp.includes("in_progress") ||
               requestStatusTemp.includes("regulated") ||
               requestStatusTemp.includes("answered")
            ) {
               if (
                  !requestStatusTemp.includes("rejected") &&
                  this.filter.statusOpenOrClosed === "open"
               ) {
                  query.filter.push({
                     attribute: "is_closed",
                     query: "false"
                  });
               }
            }

            if (
               requestStatusTemp.includes("answered") ||
               requestStatusTemp.includes("canceled")
            ) {
               if (
                  !requestStatusTemp.includes("rejected") &&
                  this.filter.statusOpenOrClosed === "closed"
               ) {
                  query.filter.push({
                     attribute: "is_closed",
                     query: "true"
                  });
               }
            }

            query.filter.push({
               attribute: "status",
               query: requestStatusTemp.join(",")
            });
         }

         return query;
      },

      onApplyFilter(filter) {
         this.filter = filter;

         if (this.filter.healthEntity && this.filter.healthEntity.id)
            this.getHealthEntitySurgeryRequestSummary();
         else this.getSurgeryRequestSummary();

         this.closeStatisticFilter();
      },

      async getHealthEntitySurgeryRequestSummary() {
         this.loadingStatistics = true;

         /* 1. Formata a data de ínicio do filtro. */
         let startDateTemp = formatUtil.formatDate(
            this.filter.rangeDate.startDate,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
         );

         /* 2. Formata a data final do filtro. */
         let endDateTemp = formatUtil.formatDate(
            this.filter.rangeDate.endDate,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
         );

         try {
            const response = await statisticsService.getHealthUnitSurgeryRequestSummary(
               this.filter.healthEntity.id,
               this.filter.healthEntityMode,
               startDateTemp,
               endDateTemp,
               this.mountQueryFromFilter(this.filter)
            );

            this.statistics = Object.assign({}, response.data);

            this.statistics.surgeries.sort((a, b) => b.total - a.total);
            this.statistics.source_municipalities.sort(
               (a, b) => b.total - a.total
            );
            if (!this.statistics.data_frame) this.statistics.data_frame = [];
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingStatistics = false;
         }
      },

      async getSurgeryRequestSummary() {
         this.loadingStatistics = true;

         /* 1. Formata a data de ínicio do filtro. */
         let startDateTemp = formatUtil.formatDate(
            this.filter.rangeDate.startDate,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
         );

         /* 2. Formata a data final do filtro. */
         let endDateTemp = formatUtil.formatDate(
            this.filter.rangeDate.endDate,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
         );

         try {
            const response = await statisticsService.getSurgeryRequestSummary(
               startDateTemp,
               endDateTemp,
               this.mountQueryFromFilter(this.filter)
            );

            this.statistics = Object.assign({}, response.data);

            this.statistics.surgeries.sort((a, b) => b.total - a.total);
            this.statistics.source_municipalities.sort(
               (a, b) => b.total - a.total
            );
            if (!this.statistics.data_frame) this.statistics.data_frame = [];
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingStatistics = false;
         }
      },

      async onClickWaitingSurgeryList(filesType) {
         this.filter = {
            acceptedSurgeries: [false],
            rangeDate: {
               endDate: moment().format("DD/MM/YYYY"),
               startDate: moment()
                  .subtract({ months: 2 })
                  .format("DD/MM/YYYY")
            }
         };
         await this.getSurgeryRequestSummary();

         this.$refs.surgerystatisticexport.clickItem(
            {
               value: "data_frame",
               text: "Lista de cirurgias em espera"
            },
            filesType
         );
      }
   },

   created() {
      /* Inicia o range de datas. */
      const endDateOriginal = moment();
      this.filter.rangeDate.endDate = formatUtil.formatDate(endDateOriginal);
      this.filter.rangeDate.startDate = formatUtil.formatDate(
         moment(endDateOriginal).subtract(1, "days")
      );

      this.getSurgeryRequestSummary();
   }
};
</script>

<style scoped>
.div-loading {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   background-color: rgba(92, 107, 192, 0.7);
}
</style>
