<template>
   <div>
      <BedListWardSkeleton v-if="loadingBeds" />

      <v-card v-else class="standard-padding-small">
         <BedListWardHeader
            @onClickAddBed="onClickAddBed"
            :ward="ward"
            :statistics="statistics"
            :showAddButton="showAddButton"
         />

         <v-divider class="my-4"></v-divider>

         <div v-if="wardBeds.length" class="d-flex flex-wrap ma-n1">
            <BedListWardBedCard
               v-for="(bed, index) in wardBeds"
               @onClickViewBed="onClickViewBed"
               :key="index"
               :bed="bed"
               class="ma-1"
            />
         </div>
         <div v-else>
            <NoData message="Desculpe, nenhum leito foi encontrado." />
         </div>
      </v-card>
   </div>
</template>

<script>
import BedListWardHeader from "./BedListWardHeader";
import BedListWardBedCard from "./BedListWardBedCard";
import BedListWardSkeleton from "./BedListWardSkeleton";
import NoData from "../../base/NoData";
import bedService from "../../../services/bedService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";

export default {
   name: "BedListWardCard",

   components: {
      BedListWardHeader,
      BedListWardBedCard,
      BedListWardSkeleton,
      NoData,
   },

   props: {
      ward: {
         type: Object,
      },

      showAddButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingBeds: true,

         statistics: {},
         wardBeds: [],
      };
   },

   watch: {
      ward: {
         immediate: true,
         handler(ward) {
            if (ward && ward.id) this.getAllBeds(ward.id);
         },
      },
   },

   methods: {
      async getAllBeds(wardId) {
         this.loadingBeds = true;

         try {
            const response = await bedService.getAllBeds(wardId, {
               page: 1,
               limit: 100,
               filter: [
                  {
                     attribute: "active",
                     query: true,
                  },
               ],
            });
            const beds = Object.assign([], response.data);
            //FIXME01:
            /* Isso aqui no futuro vai ser removido, já que o próprio component HealthUnitStatistic
            terá uma rota específica para ele. */
            this.$emit("onGetAllBedsWard", beds);

            this.statistics = util.generateBedStatistics(beds);
            this.wardBeds = util.orderBeds(beds);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingBeds = false;
         }
      },

      onClickAddBed(bed) {
         this.$emit("onClickAddBed", bed);
      },

      onClickViewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },
   },
};
</script>

<style scoped>
</style>