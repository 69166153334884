<template>
   <div>
      <div id="health_unit_lists" v-if="operator.health_units.length">
         <OperatorHealthUnitsDetails
            v-for="health_unit in operator.health_units"
            @onDisassociateHealthUnit="disassociate"
            @changeCapabilities="changeCapabilities"
            ref="health_units"
            :disabled="isDisabled()"
            :key="health_unit.id"
            :health_unit="health_unit"
         />
      </div>
      <div v-else>
         <NoData
            message="Desculpe, nenhuma unidade de saúde associada a esse operador foi encontrada."
         />
      </div>

      <v-expansion-panels v-model="healthUnitExpansionInput" class="mt-2" flat>
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div id="associate_new_healthunit-operator-health-units-select">
                  Associar nova unidade de saúde ao Operador
                  <v-icon right>mdi-plus</v-icon>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-form ref="form">
                  <HealthUnitInput
                     @onSelectHealthUnit="onSelectHealthUnit"
                     ref="operatorhealthunitinput"
                  />

                  <div v-if="healthUnitSelected">
                     <p class="body-1 font-weight-medium mt-2 ">Acessos</p>

                     <v-chip-group
                        class="mb-3"
                        v-model="capabilitiesComputed"
                        multiple
                        column
                     >
                        <v-chip
                           :color="
                              capabilitiesComputed.includes('can_access_beds')
                                 ? 'primary'
                                 : 'normal'
                           "
                           :class="
                              capabilitiesComputed.includes('can_access_beds')
                                 ? 'elevation-4'
                                 : 'elevation-0'
                           "
                           value="can_access_beds"
                           label
                           filter
                           medium
                        >
                           <div
                              class="d-flex justify-space-between align-center"
                           >
                              <p class="ma-0 mx-3">Acesso aos leitos</p>
                              <v-icon>mdi-bed</v-icon>
                           </div>
                        </v-chip>
                        <v-chip
                           :color="
                              capabilitiesComputed.includes(
                                 'can_access_surgeries'
                              )
                                 ? 'primary'
                                 : 'normal'
                           "
                           :class="
                              capabilitiesComputed.includes(
                                 'can_access_surgeries'
                              )
                                 ? 'elevation-4'
                                 : 'elevation-0'
                           "
                           value="can_access_surgeries"
                           label
                           filter
                           medium
                        >
                           <div
                              class="d-flex justify-space-between align-center"
                           >
                              <p class="ma-0 mx-3">Acesso à cirurgias</p>
                              <v-icon>mdi-box-cutter</v-icon>
                           </div>
                        </v-chip>
                     </v-chip-group>
                  </div>
               </v-form>

               <div class="d-flex justify-end align-center ma-n1">
                  <v-btn
                     @click="associate(healthUnitSelected)"
                     :loading="loadingSaveAssociation"
                     class="d-none d-sm-flex ma-1"
                     color="primary"
                     id="associate-operator-health-units"
                     depressed
                     small
                     >Associar
                     <v-icon right x-small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                     @click="associate(healthUnitSelected)"
                     :loading="loadingSaveAssociation"
                     class="d-flex d-sm-none ma-1"
                     color="primary"
                     id="associate_small-operator-health-units"
                     fab
                     depressed
                     x-small
                  >
                     <v-icon small>mdi-plus</v-icon>
                  </v-btn>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
   </div>
</template>

<script>
import OperatorHealthUnitsDetails from "./OperatorHealthUnitsDetails";
import HealthUnitInput from "../../healthUnit/healthUnitInput/HealthUnitInput";
import NoData from "../../base/NoData";
import operatorService from "../../../services/operatorService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "OperatorHealthUnits",

   components: {
      OperatorHealthUnitsDetails,
      HealthUnitInput,
      NoData
   },

   props: {
      operator: {
         type: Object
      }
   },

   data: function() {
      return {
         loadingHealthUnits: false,

         loadingSaveAssociation: false,

         healthUnitSelected: null,

         healthUnits: [],

         healthUnitExpansionInput: null,

         access_capabilities: {
            can_access_beds: true,
            can_access_surgeries: true
         }
      };
   },

   watch: {
      operator: {
         immediate: true,
         handler() {
            this.healthUnitExpansionInput = null;

            if (this.$refs.operatorhealthunitinput)
               this.$refs.operatorhealthunitinput.reset();
            this.resetValidation();
         }
      }
   },

   computed: {
      capabilitiesComputed: {
         get() {
            let result = [];
            if (this.access_capabilities) {
               if (this.access_capabilities.can_access_beds)
                  result.push("can_access_beds");
               if (this.access_capabilities.can_access_surgeries)
                  result.push("can_access_surgeries");
            }

            return result;
         },

         set(value) {
            this.access_capabilities.can_access_beds = value.includes(
               "can_access_beds"
            );
            this.access_capabilities.can_access_surgeries = value.includes(
               "can_access_surgeries"
            );
         }
      }
   },

   methods: {
      isDisabled() {
         return this.$store.getters["snackBar/getVisibility"];
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = healthUnit;
      },

      formatCapabilities(operatorAccess) {
         const capabilities = [];

         if (operatorAccess.can_access_surgeries)
            capabilities.unshift("surgeries");
         if (operatorAccess.can_access_beds) capabilities.unshift("beds");

         return capabilities;
      },

      disassociate(healthUnit) {
         const methodDisassociateItem = async () => {
            await operatorService.disassociateOperatorFromHealthUnitById(
               this.operator.id,
               healthUnit.id
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi desassociado ao hospital com sucesso!"
            });

            this.$emit("onUpdatedOperator");
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE DESASSOCIAÇÃO!",
            message:
               "Deseja realmente desassociar essa unidade de saúde do operador?",
            action: {
               text: "Confirmar",
               method: methodDisassociateItem,
               closeOnFinished: false
            }
         });
      },

      associate(healthUnit) {
         this.associateOperatorToHealthUnitById(
            this.operator.id,
            healthUnit ? healthUnit.id : null,
            this.access_capabilities
               ? this.formatCapabilities(this.access_capabilities)
               : null
         );
      },

      async associateOperatorToHealthUnitById(
         operatorId,
         healthUnitId,
         capabilities
      ) {
         if (
            !this.executeValidation() ||
            !operatorId ||
            !healthUnitId ||
            !capabilities
         )
            return;

         this.loadingSaveAssociation = true;

         try {
            await operatorService.associateOperatorToHealthUnitById(
               operatorId,
               healthUnitId,
               capabilities
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi associado ao hospital com sucesso!"
            });

            this.$emit("onUpdatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAssociation = false;
         }
      },

      async changeCapabilities(health_unit) {
         if (health_unit.id) {
            const methodChangeCapabilitiesItem = async () => {
               try {
                  await operatorService.associateOperatorToHealthUnitById(
                     this.operator.id,
                     health_unit.id,
                     health_unit.capabilities
                  );

                  snackBarUtil.showCustomSnackBar({
                     color: "success",
                     title: "SUCESSO!",
                     message: "O acesso foi alterado com sucesso!",
                     timeout: 1000
                  });

                  this.$emit("onUpdatedOperator");
               } catch (error) {
                  snackBarUtil.showErrorSnackBar(error);
               } finally {
                  this.loadingSaveAssociation = false;
               }

               this.$emit("onUpdatedOperator");
            };

            snackBarUtil.showCustomSnackBar({
               color: "warning",
               title: "CONFIRMAÇÃO DE ALTERAÇÃO!",
               message: "Deseja alterar o acesso deste operador?",
               action: {
                  text: "Confirmar",
                  method: methodChangeCapabilitiesItem,
                  closeOnFinished: false
               }
            });
         }
      }
   }
};
</script>

<style scoped>
.panels-border {
   border-style: solid;
   border-width: 1px;
   border-color: rgba(0, 0, 0, 0.2);
   margin-top: 6px;
}
</style>
