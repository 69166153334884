<template>
   <div>
      <ListSkeleton
         v-if="loadingSurgeryRequestsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
         :hideAddButton="hideAddButton"
      />

      <v-data-table
         v-else
         @click:row="
            request => (!blockClick ? onClickViewRequest(request) : null)
         "
         :headers="headers"
         :items="requests"
         :loading="loadingSurgeryRequestsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <SurgeryRequestListHeader
               @onChangeSort="onChangeSort"
               @onChangeDate="onChangeDate"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddRequest"
               :sort="sort"
               :sorts="sorts"
               :date="date"
               :filter="filter"
               :filters="filters"
               :loading="loadingSurgeryRequestsPartially"
               :hideAddButton="hideAddButton"
               :userInfo="userInfo"
               filterActor="solicitação"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalSurgeryRequests"
               :loading="loadingSurgeryRequestsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhuma solicitação foi encontrada."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando solicitações, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.code`]="{ item }">
            <div class="d-flex align-center">
               <p :class="`code-regulator-request-operator-list-data`">
                  {{ item.code }}
               </p>

               <v-tooltip v-if="item.was_created_by_patient" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        size="17"
                        right
                     >
                        mdi-cellphone-text
                     </v-icon>
                  </template>

                  <p class="text-center">
                     Cadastrado pelo <br />
                     OperaPB
                  </p>
               </v-tooltip>
            </div>
         </template>

         <template v-slot:[`item.created_at`]="{ item }">
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <p
                     :class="
                        `request_created_at-${userInfo}-request-operator-list-data`
                     "
                     v-bind="attrs"
                     v-on="on"
                  >
                     {{ formatDateTime(item.created_at, "DD/MM [às] HH:mm") }}
                  </p>
               </template>

               <p class="text-center">
                  {{ formatDateTime(item.created_at) }} <br />
                  {{ relativeDate(item.created_at) }}
               </p>
            </v-tooltip>
         </template>

         <template v-slot:[`item.patient.name`]="{ item }">
            <p :class="`name-${userInfo}-request-operator-list-data`">
               {{ item.patient.name }}
            </p>
         </template>

         <template v-slot:[`item.surgery.name`]="{ item }">
            <p v-if="item.surgery && item.surgery.name">
               {{ item.surgery.name }}
            </p>

            <p v-else>Sem cirurgia</p>
         </template>

         <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex align-center">
               <p
                  :class="
                     `${
                        generateState(item.status).color
                     }--text status-${userInfo}-request-operator-list-data`
                  "
                  class="font-weight-medium"
               >
                  {{ generateState(item.status).text }}
               </p>
            </div>
         </template>

         <template v-slot:[`item.actions`]="{ item }">
            <div
               :key="messageNotificationKey"
               class="d-flex justify-center align-center flex-wrap mx-n1"
            >
               <v-card
                  v-if="item.totalMessages"
                  color="transparent"
                  height="20px"
                  width="20px"
                  class="mx-1"
                  flat
               >
                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-icon
                           v-bind="attrs"
                           v-on="on"
                           class="icon-message-notification"
                           color="orange"
                           size="20"
                        >
                           mdi-chat
                        </v-icon>
                     </template>

                     <p v-if="!item.unreadMessages" class="text-center">
                        Chat iniciado
                     </p>
                     <p v-else class="text-center">
                        {{ item.unreadMessages }}
                        {{ item.unreadMessages > 1 ? "mensagens" : "mensagem" }}
                     </p>
                  </v-tooltip>

                  <div class="icon-content-notification">
                     <v-icon v-if="!item.unreadMessages" size="11" dark>
                        mdi-check
                     </v-icon>
                     <p v-else class="count-message-label">
                        {{ item.unreadMessages }}
                     </p>
                  </div>
               </v-card>

               <v-icon
                  v-if="!hideEditButtonOperator(item.status)"
                  @click="editRequest(item)"
                  class="mx-1"
                  :class="`edit-${userInfo}-request-operator-list-data`"
                  color="black"
                  small
               >
                  mdi-pencil
               </v-icon>

               <v-icon
                  v-if="
                     !hideRemoveButton &&
                        cancelRequest(item) &&
                        requestCreatedByPatient(item)
                  "
                  @click="removeRequest(item)"
                  class="mx-1"
                  :class="`delete-${userInfo}-request-operator-list-data`"
                  color="error"
                  small
               >
                  mdi-delete
               </v-icon>
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../../components/base/list/ListFooter";
import NoData from "../../../components/base/NoData";
import LoadingData from "../../../components/base/LoadingData";
import SurgeryRequestListHeader from "./SurgeryRequestListHeader";
import responsiveUtil from "../../../utils/responsiveUtil";
import snackBarUtil from "../../../utils/snackBarUtil";
import formatUtil from "../../../utils/formatUtil";
import requestSurgeryService from "../../../services/requestSurgeryService";
import util from "../../../utils/util";
import messageService from "services/messageService";
import moment from "moment";

export default {
   name: "SurgeryRequestOperatorList",

   components: {
      ListSkeleton,
      ListFooter,
      NoData,
      LoadingData,
      SurgeryRequestListHeader
   },

   props: {
      healthUnit: {
         type: Object
      },

      user: {
         type: Object
      },

      operatorType: {
         type: String,
         default: ""
      },

      hideAddButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      userInfo: {
         type: String,
         default: null
      },

      initialQuery: {
         type: Array,
         default: () => []
      }
   },

   data: function() {
      return {
         headers: [
            {
               text: "Registro",
               class: "primary--text body-2 font-weight-medium",
               value: "code",
               width: "12%"
            },
            {
               text: "Cadastrado em",
               class: "primary--text body-2 font-weight-medium",
               value: "created_at",
               width: "131px"
            },
            {
               text: "Paciente",
               class: "primary--text body-2 font-weight-medium",
               value: "patient.name"
            },
            {
               text: "Cirurgia",
               class: "primary--text body-2 font-weight-medium",
               value: "surgery.name"
            },
            {
               text: "Estado",
               class: "primary--text body-2 font-weight-medium",
               value: "status"
            },
            {
               text: "Ações/Alertas",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
               width: "10%"
            }
         ],

         sorts: [
            {
               sort: "status",
               text: "Estado"
            },
            {
               sort: "-created_at",
               text: "Últimas cadastradas"
            },
            {
               sort: "created_at",
               text: "Primeiras cadastradas"
            },
            {
               sort: "-updated_at",
               text: "Últimas atualizadas"
            }
         ],

         requests: [],

         totalSurgeryRequests: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "code",
         filterBy: "",
         date: [formatUtil.formatDate(moment(), "YYYY-MM")],

         loadingSurgeryRequestsFully: true,
         loadingSurgeryRequestsPartially: true,

         blockClick: false,

         messageNotificationKey: 0
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "created_at",
                  query: `gte:${this.getRangeDateFromDate.gte}`
               },
               {
                  attribute: "created_at",
                  query: `lte:${this.getRangeDateFromDate.lte}`
               }
            ]
         };

         if (this.initialQuery && this.initialQuery.length)
            this.initialQuery.forEach(item =>
               query.filter.push({
                  attribute: item.attribute,
                  query: item.query
               })
            );

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy
            });

         if (this.user && this.user.sub)
            query.filter.push({
               attribute: "responsibles",
               query: this.user.sub
            });

         return query;
      },

      getRangeDateFromDate() {
         const getGte = date =>
            formatUtil.formatDate(
               moment(date).startOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );
         const getLte = date =>
            formatUtil.formatDate(
               moment(date).endOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );

         return {
            gte: getGte(this.date[0]),
            lte: getLte(this.date[this.date.length - 1])
         };
      },

      /* Filtros são computados pois dependendo da unidade de saúde selecionada, exibe ou não o filtro de "Cadastrado pelo OperaPB". */
      filters() {
         let result = [
            {
               filter: "code",
               text: "Registro",
               mask: "#################",
               unmask: /[^0-9]/g,
               maxLength: 17
            },
            {
               filter: "patient_id",
               text: "Nome do paciente"
            }
         ];

         const healthEntity =
            this.operatorCurrentHealthUnitStore ||
            this.healthSecretaryManagerCurrentHealthUnitStore;

         if (healthEntity.capabilities)
            result.push({
               filter: "was_created_by_patient",
               text: "Cadastrado pelo OperaPB"
            });

         return result;
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthSecretaryManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         }
      }
   },

   watch: {
      healthUnit: {
         immediate: true,
         async handler(healthUnit) {
            this.filter = "code";

            if (healthUnit && healthUnit.id)
               this.refreshAllHealthUnitSurgeryRequestsByRef();
         }
      }
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,
      ...util,

      onChangePage(page) {
         this.page = page;
         this.getAllHealthUnitSurgeryRequests(this.healthUnit.id, this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllHealthUnitSurgeryRequests(this.healthUnit.id, this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onChangeDate(date) {
         this.date = date;
         this.page = 1;
         this.getAllHealthUnitSurgeryRequests(this.healthUnit.id, this.query);
      },

      async onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         await this.getAllHealthUnitSurgeryRequests(
            this.healthUnit.id,
            this.query
         );
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllHealthUnitSurgeryRequests(this.healthUnit.id, this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllHealthUnitSurgeryRequests(this.healthUnit.id, this.query);
      },

      onClickAddRequest(request) {
         this.$emit("onClickAddRequest", request);
      },

      onClickViewRequest(request) {
         this.$emit("onClickViewRequest", request);
      },

      editRequest(request) {
         this.$emit("onClickEditRequest", request);
      },

      removeRequest(request) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveRequest", request);
            this.blockClick = false;
         }, 1);
      },

      hideEditButtonOperator(status) {
         return !["registered", "in_progress"].includes(status);
      },

      forceMessageNotificationRerender() {
         this.messageNotificationKey += 1;
      },

      translatePriority(priority) {
         let translatedPriority = "Vermelha";
         let color = "error";

         switch (priority) {
            case "blue":
               translatedPriority = "Azul";
               color = "info";
               break;
            case "green":
               translatedPriority = "Verde";
               color = "success";
               break;
            case "yellow":
               translatedPriority = "Amarela";
               color = "amber";
               break;
            case "orange":
               translatedPriority = "Laranja";
               color = "warning";
               break;
         }

         return { translatedPriority, color };
      },

      /**
       * incrementRequestTotalMessagesByRef
       * Método para incrementar o total de mensagens de uma solicitação, exibindo se o chat já foi usado ou não
       * na listagem.
       * @param request objeto solicitação que teve a mensagem adicionada.
       */
      async incrementRequestTotalMessagesByRef(request) {
         const requestTemp = this.requests.find(item => item.id === request.id);

         if (
            requestTemp.totalMessages !== undefined &&
            requestTemp.totalMessages !== null &&
            Number.isInteger(requestTemp.totalMessages)
         ) {
            requestTemp.totalMessages += 1;
            this.forceMessageNotificationRerender();
         }
      },

      /**
       * refreshAllHealthUnitSurgeryRequestsByRef
       * Método para que o componente pai consiga atualizar as solicitações via referência.
       * @param loadingPartially muda o tipo de animação de loading das solicitações.
       */
      async refreshAllHealthUnitSurgeryRequestsByRef(loadingPartially) {
         if (loadingPartially)
            await await this.getAllHealthUnitSurgeryRequests(
               this.healthUnit.id,
               this.query
            );
         else {
            this.loadingSurgeryRequestsFully = true;
            await this.getAllHealthUnitSurgeryRequests(
               this.healthUnit.id,
               this.query
            );
            this.loadingSurgeryRequestsFully = false;
         }
      },

      async getAllHealthUnitSurgeryRequests(healthUnitId, query) {
         this.loadingSurgeryRequestsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await requestSurgeryService.getAllHealthUnitSurgeryRequests(
               healthUnitId,
               query
            );

            this.totalSurgeryRequests = parseInt(
               response.headers["x-total-count"]
            );

            /* Alterar status 'visualmente' da solicitação de "Rejeitada" para "Em andamento" */
            const requestsModifiedStatus = response.data.map(item => {
               let result = item;

               if (item.status === "rejected" && !item.is_closed)
                  item.status = "in_progress";

               return result;
            });

            /* Verificar se tem mensagens que o OPERADOR enviou para o QUALQUER USUÁRIO. */
            requestsModifiedStatus.forEach(item => {
               messageService
                  .getRequestMessageInfoById(item.id)
                  .then(response => {
                     if (item.totalMessages)
                        item.totalMessages +=
                           response.data.total_messages_received;
                     else
                        item.totalMessages =
                           response.data.total_messages_received;

                     this.forceMessageNotificationRerender();
                  });
            });

            /* Verificar se tem mensagens enviadas de QUALQUER USUÁRIO para o OPERADOR. */
            requestsModifiedStatus.forEach(item => {
               messageService
                  .getRequestMessageInfoById(item.id, "requester")
                  .then(response => {
                     if (item.totalMessages)
                        item.totalMessages +=
                           response.data.total_messages_received;
                     else
                        item.totalMessages =
                           response.data.total_messages_received;

                     item.unreadMessages = response.data.total_unread_messages;

                     this.forceMessageNotificationRerender();
                  });
            });

            this.requests = Object.assign([], requestsModifiedStatus);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeryRequestsPartially = false;
         }
      },

      cancelRequest(request) {
         if (!request.is_closed) {
            return [
               "registered",
               "in_progress",
               "regulated",
               "answered",
               "rejected"
            ].includes(request.status);
         }

         return false;
      },

      requestCreatedByPatient(request) {
         return !request.was_created_by_patient;
      }
   }
};
</script>

<style scoped>
.unread-messages-text {
   color: white;
   font-weight: 400;
   font-size: 0.7rem;
}

.icon-message-notification {
   display: inherit !important;
}

.icon-content-notification {
   pointer-events: none;

   position: absolute !important;
   top: 0px;
   left: 0px;

   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
}

.count-message-label {
   color: white;
   font-size: 0.65rem !important;
   font-weight: 500;
}
</style>
