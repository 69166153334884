<template>
   <div>
      <div v-if="!healthUnit">
         <div class="standard-padding">
            <div class="d-flex justify-center">
               <v-icon size="150" class="shadow-svg">
                  $hospital-bed-azul
               </v-icon>   
            </div>

            <p class="primary--text text-center mt-2">
               Selecione o <strong>município </strong> e o
               <strong>hospital</strong> para visualizar os leitos livres.
            </p>
         </div>
      </div>
      <div v-else-if="loadingWards">
         <BedReserveListWardSkeleton
            v-for="item in 3"
            :key="item"
            class="mb-6"
         />
      </div>
      <div v-else-if="healthunitWards.length">
         <v-alert
            v-if="!alertHelp"
            class="caption"
            color="warning"
            type="error"
            border="left"
            dense
            text
         >
            Você está visualizando leitos do tipo <b>{{ translateBedType }}</b
            >.
         </v-alert>

         <v-alert
            v-else
            class="caption"
            color="primary"
            type="info"
            border="left"
            dense
            text
         >
            Você está visualizando leitos da unidade de saúde
            <b>{{ healthUnit.name }}</b
            >. Selecione o leito que deseja realizar a transferência.
         </v-alert>

         <BedReserveListWardCard
            v-for="(ward, index) in healthunitWards"
            @onClickViewBed="onClickViewBed"
            :key="index"
            :ward="ward"
            class="mb-6"
         />
      </div>
      <div v-else class="standard-padding-small">
         <NoData message="Desculpe, nenhum leito foi encontrado." />
      </div>
   </div>
</template>

<script>
import BedReserveListWardSkeleton from "../bedReserve/BedReserveListWardSkeleton";
import BedReserveListWardCard from "../../bed/bedReserve/BedReserveListWardCard";
import NoData from "../../base/NoData";
import wardService from "../../../services/wardService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "BedReserveList",

   components: { BedReserveListWardCard, BedReserveListWardSkeleton, NoData },

   props: {
      healthUnit: {
         type: Object,
      },

      request: {
         type: Object,
      },

      alertHelp: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingWards: true,

         healthunitWards: [],
      };
   },

   watch: {
      healthUnit: {
         immediate: true,
         handler(healthUnit) {
            if (healthUnit && healthUnit.id)
               this.getAllHealthUnitWards(healthUnit.id);
            else this.healthunitWards = [];
         },
      },
   },

   computed: {
      translateBedType() {
         let translatedBedType = "Clínico";

         if (
            this.request &&
            this.request.doctor_opinion &&
            this.request.doctor_opinion.bed_type
         )
            switch (this.request) {
               case "surgical":
                  translatedBedType = "Cirurgico";
                  break;
               case "uti":
                  translatedBedType = "UTI";
                  break;
               default:
                  break;
            }

         return translatedBedType;
      },
   },

   methods: {
      refreshAllHealthUnitWardsByRef() {
         if (this.healthUnit && this.healthUnit.id)
            this.getAllHealthUnitWards(this.healthUnit.id);
      },

      async getAllHealthUnitWards(healthUnitId) {
         this.loadingWards = true;

         try {
            const response = await wardService.getAllHealthUnitWards(
               healthUnitId,
               {
                  page: 1,
                  limit: 100,
                  filter: [
                     {
                        attribute: "active",
                        query: true,
                     },
                     {
                        attribute: "type",
                        query: this.request.doctor_opinion.bed_type,
                     },
                  ],
               }
            );

            this.healthunitWards = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingWards = false;
         }
      },

      onClickViewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },
   },
};
</script>

<style>
.shadow-svg {
   width: max-content;
   filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
}
</style>