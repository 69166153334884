<template>
   <div>
      <v-dialog v-model="dialogTimePickerVisibility" width="290px">
         <v-time-picker
            v-if="showStartPickerTime"
            v-model="start"
            :max="end"
            format="24hr"
            scrollable
         >
            <v-btn
               @click="emitReturn"
               class="ml-auto mb-2"
               color="primary"
               depressed
               small
            >
               Salvar
               <v-icon right small>mdi-check</v-icon>
            </v-btn>
         </v-time-picker>

         <v-time-picker
            v-else
            v-model="end"
            :min="start"
            format="24hr"
            scrollable
         >
            <v-btn
               @click="emitReturn"
               class="ml-auto mb-2"
               color="primary"
               depressed
               small
            >
               Salvar
               <v-icon right small>mdi-check</v-icon>
            </v-btn>
         </v-time-picker>
      </v-dialog>

      <div>
         <v-text-field
            v-model="start"
            @click="openTimePicker('start')"
            label="Hora inicial"
            readonly
         ></v-text-field>

         <v-text-field
            v-model="end"
            @click="openTimePicker('end')"
            class="pt-6"
            label="Hora final"
            readonly
         ></v-text-field>
      </div>
   </div>
</template>

<script>
import validationUtil from "utils/validationUtil";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "DisplayHoursFilter",

   components: {},

   props: {
      rangeHour: {
         type: Array,
      },
   },

   data: function () {
      return {
         dialogTimePickerVisibility: false,
         showStartPickerTime: true,

         start: null,
         end: null,
      };
   },

   methods: {
      ...validationUtil,
      ...responsiveUtil,

      openTimePicker(type) {
         this.showStartPickerTime = type === "start";
         this.dialogTimePickerVisibility = true;
      },

      closeTimePicker() {
         this.dialogTimePickerVisibility = false;
      },

      emitReturn() {
         if (this.start && this.end) {
            this.closeTimePicker();
            this.$emit("onChangeHour", {
               rangeHour: {
                  start: this.start,
                  end: this.end,
               },
            });
         }
      },
   },

   created() {
      this.start = this.rangeHour[0];
      this.end = this.rangeHour[1];
   },
};
</script>

<style>
.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.15);
}
.card-panel {
   padding: 5px 10px;
   margin-left: 8px;
}

.subtext-panel {
   font-size: 0.73rem;
   line-height: 120%;
   text-align: center;
}
.div-buttons-apply {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

.clock-style {
   transform: scale(0.5);
}
</style>
