<template>
   <div>
      <div class="d-flex align-center mb-8">
         <p class="title">Salvando o(s) anexo(s)...</p>
         <v-icon right>mdi-paperclip</v-icon>
      </div>

      <v-card
         v-for="(attachment, index) in attachments"
         :key="index"
         color="transparent"
         class="d-flex justify-space-between align-center mb-4"
         flat
      >
         <p class="mr-3 label-attachment-name">
            {{ attachment.content.name }}
         </p>

         <div>
            <v-icon
               v-if="
                  attachment.upload &&
                  (attachment.upload.success ||
                     attachment.upload.progress === 100)
               "
               color="success"
               size="45"
            >
               mdi-check-circle
            </v-icon>

            <v-icon
               v-else-if="attachment.upload && attachment.upload.error"
               color="error"
               size="45"
            >
               mdi-alert-circle
            </v-icon>

            <v-progress-circular
               v-else
               :value="attachment.upload ? attachment.upload.progress : 0"
               :rotate="-90"
               :size="40"
               color="primary lighten-1"
            >
               <p class="caption white--text font-weight-bold">
                  {{ attachment.upload ? attachment.upload.progress : 0 }}
               </p>
            </v-progress-circular>
         </div>
      </v-card>
   </div>
</template>

<script>
export default {
   name: "AttachmentUploadProgress",

   props: {
      attachments: {
         typeof: Array,
         default: [],
      },
   },
};
</script>

<style scoped>
.label-attachment-name {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   max-width: 50vw;
}
</style>