<template>
   <div>
      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <SurgeryStatisticContent />
   </div>
</template>

<script>
import SurgeryStatisticContent from "components/surgeryStatistic/SurgeryStatisticContent";
import PageTitle from "../../components/base/PageTitle";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "SurgeryStatistic",

   components: { PageTitle, SurgeryStatisticContent },

   data: function () {
      return {};
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },
   },
};
</script>

<style scoped>
</style>