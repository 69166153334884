<template>
   <div>
      <v-dialog
         v-model="dialogSurgeryRequestCancelFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryRequestCancelForm" />

            <SurgeryRequestCancelForm
               @onCancelRequest="
                  closeSurgeryRequestCancelForm();
                  refreshRequestOperatorList();
               "
               :request="requestSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogRequestFormVisibility"
         :content-class="
            disableDialogScrolling ? 'dialog-overflow-hidden' : null
         "
         max-width="1000"
         transition="slide-y-transition"
         persistent
         no-click-animation
      >
         <v-card id="scrollTop" tile>
            <Close @onClickClose="closeRequestForm" />

            <div class="d-flex justify-center align-center">
               <!-- Formulário exibido para o Administrador -->
               <SurgeryRequestAdminForm
                  v-if="userStore.sub_type === 'admin'"
                  @onUpdatedRequest="
                     closeRequestForm();
                     refreshRequestAdminList();
                  "
                  :request="requestSelected"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
               />

               <!-- Formulário exibido para Regulador e Gestor Regulador -->
               <SurgeryRequestRegulatorForm
                  v-else-if="
                     userStore.sub_type === 'regulator_manager' ||
                        userStore.sub_type === 'regulator'
                  "
                  @onClickCancel="closeRequestForm"
                  @onUpdatedRequest="
                     closeRequestForm();
                     refreshRequestRegulatorList();
                  "
                  @onOpenHistory="dialogScroll(true)"
                  @onCloseHistory="dialogScroll(false)"
                  @onOpenChat="
                     dialogScroll(true);
                     setRegulatorMessagesAsRead();
                  "
                  @onCloseChat="dialogScroll(false)"
                  @onSendMessage="onSendMessageRegulatorForm"
                  @onCloseRequest="
                     closeRequestForm();
                     refreshRequestRegulatorList();
                  "
                  :request="requestSelected"
                  :user="fullUserStore"
                  :editMode="editMode"
                  :hideCheckButton="
                     requestSelected.is_checked || requestSelected.is_closed
                  "
                  :hideEditButton="hideEditButtonRegulator"
                  :hideReserveBedButton="hideReserveBedButtonRegulator"
                  :hideCloseButton="hideCloseRequestButtonRegulator"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
                  hideRemoveButton
               />

               <!-- Formulário exibido para Operador Solicitante e Gestor de secretaria -->
               <SurgeryRequestOperatorForm
                  v-else-if="
                     userStore.sub_type === 'solicitator' ||
                        userStore.sub_type === 'secretary_manager'
                  "
                  @onClickCancel="closeRequestForm"
                  @onCreatedRequest="
                     closeRequestForm();
                     refreshRequestOperatorList();
                  "
                  @onUpdatedRequest="
                     closeRequestForm();
                     refreshRequestOperatorList();
                  "
                  @onOpenChat="dialogScroll(true)"
                  @onCloseChat="dialogScroll(false)"
                  @onSendMessage="onSendMessageOperatorForm"
                  :request="requestSelected"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :editMode="editMode"
                  :hideEditButton="hideEditButtonOperator"
                  :hideChatButton="hideChatButtonOperator"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
                  hideRemoveButton
               />
            </div>
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <!-- <v-row class="mt-5">
         <v-col cols="12" md="2">
            <v-card height="100%">
               <v-list rounded>
                  <v-list-item-group color="primary">
                     <v-list-item>
                        <v-list-item-content>
                           <v-list-item-title>Adulto</v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>

                     <v-list-item>
                        <v-list-item-content>
                           <v-list-item-title>Pedriatria</v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </v-card>
         </v-col>

         <v-col cols="12" md="10">
            <v-card class="overflow-hidden">
               <v-tabs
                  v-if="userStore.sub_type === 'admin'"
                  v-model="activeAdminTab"
                  centered
                  show-arrows
               >
                  <v-tab id="surgery-avaliables-button">
                     <v-icon small left>$box-cutter-check</v-icon>
                     CIRURGIAS ACEITAS
                  </v-tab>

                  <v-tab id="surgery-not-avaliables-button">
                     <v-icon small left>$box-cutter-close</v-icon>
                     CIRURGIAS NÃO ACEITAS
                  </v-tab>

                  <v-tab id="filed-button">
                     <v-icon small left> mdi-folder-open </v-icon>
                     SOLICITAÇÕES FINALIZADAS
                  </v-tab>

                  <v-tab id="archived-button">
                     <v-icon small left> mdi-archive-arrow-down </v-icon>
                     SOLICITAÇÕES ARQUIVADAS
                  </v-tab>

                  <v-tab-item>
                     <SurgeryRequestAdminList
                        @onClickViewRequest="onClickViewRequestRegulator"
                        :initialQuery="[
                           {
                              attribute: 'is_available',
                              query: 'true',
                           },
                           {
                              attribute: 'is_closed',
                              query: 'false',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'registered',
                           'in_progress',
                           'answered',
                           'regulated',
                           'rejected_by_executor',
                        ]"
                        hideEditButton
                        hideAddButton
                        hideRemoveButton
                        ref="availablesurgeriesrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestAdminList
                        @onClickViewRequest="onClickViewRequestRegulator"
                        :initialQuery="[
                           {
                              attribute: 'is_available',
                              query: 'false',
                           },
                           {
                              attribute: 'is_closed',
                              query: 'false',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'registered',
                           'in_progress',
                           'answered',
                           'regulated',
                           'rejected_by_executor',
                        ]"
                        hideEditButton
                        hideAddButton
                        hideRemoveButton
                        ref="unavailablesurgeriesrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestAdminList
                        @onClickViewRequest="onClickViewRequestRegulator"
                        :initialQuery="[
                           {
                              attribute: 'is_closed',
                              query: 'true',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'answered',
                           'rejected',
                           'canceled',
                        ]"
                        hideEditButton
                        hideAddButton
                        hideRemoveButton
                        ref="filedrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestAdminList
                        @onClickViewRequest="onClickViewRequestRegulator"
                        :initialQuery="[
                           {
                              attribute: 'is_archived',
                              query: 'true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'answered',
                           'rejected',
                           'canceled',
                        ]"
                        hideEditButton
                        hideAddButton
                        hideRemoveButton
                        ref="archivedrequestlist"
                     />
                  </v-tab-item>
               </v-tabs>

               <v-tabs
                  v-else-if="
                     userStore.sub_type === 'regulator_manager' ||
                     userStore.sub_type === 'regulator'
                  "
                  v-model="activeRegulatorTab"
                  centered
                  show-arrows
               >
                  <v-tab id="surgery-avaliables-health-unit-button">
                     <v-icon small left>$box-cutter-check</v-icon>
                     CIRURGIAS ACEITAS
                  </v-tab>

                  <v-tab id="surgery-not-avaliables-health-unit-button">
                     <v-icon small left>$box-cutter-close</v-icon>
                     CIRURGIAS NÃO ACEITAS
                  </v-tab>

                  <v-tab id="my_request-health-unit-button">
                     <v-icon small left>mdi-account</v-icon>
                     MINHAS SOLICITAÇÕES
                  </v-tab>

                  <v-tab id="filed-health-unit-button">
                     <v-icon small left> mdi-folder-open </v-icon>
                     SOLICITAÇÕES FINALIZADAS
                  </v-tab>

                  <v-tab
                     v-if="userStore.sub_type === 'regulator_manager'"
                     id="archived-health-unit-button"
                  >
                     <v-icon small left> mdi-archive-arrow-down </v-icon>
                     SOLICITAÇÕES ARQUIVADAS
                  </v-tab>

                  <v-tab-item>
                     <SurgeryRequestRegulatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequestRegulator"
                        @onClickEditRequest="onClickEditRequest"
                        :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                        :initialQuery="[
                           {
                              attribute: 'is_available',
                              query: 'true',
                           },
                           {
                              attribute: 'is_closed',
                              query: 'false',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'registered',
                           'in_progress',
                           'answered',
                           'regulated',
                           'rejected_by_executor',
                        ]"
                        hideAddButton
                        hideRemoveButton
                        ref="availablesurgeriesrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestRegulatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequestRegulator"
                        @onClickEditRequest="onClickEditRequest"
                        :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                        :initialQuery="[
                           {
                              attribute: 'is_available',
                              query: 'false',
                           },
                           {
                              attribute: 'is_closed',
                              query: 'false',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'registered',
                           'in_progress',
                           'answered',
                           'regulated',
                           'rejected_by_executor',
                        ]"
                        hideAddButton
                        hideRemoveButton
                        ref="unavailablesurgeriesrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestRegulatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequestRegulator"
                        @onClickEditRequest="onClickEditRequest"
                        :user="userStore"
                        :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                        :initialQuery="[
                           {
                              attribute: 'is_closed',
                              query: 'false',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'in_progress',
                           'answered',
                           'regulated',
                           'rejected_by_executor',
                        ]"
                        hideAddButton
                        hideRemoveButton
                        ref="myrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestRegulatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequestRegulator"
                        @onClickEditRequest="onClickEditRequest"
                        :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                        :initialQuery="[
                           {
                              attribute: 'is_closed',
                              query: 'true',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'answered',
                           'rejected',
                           'canceled',
                        ]"
                        hideAddButton
                        hideRemoveButton
                        ref="filedrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestRegulatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequestRegulator"
                        @onClickEditRequest="onClickEditRequest"
                        :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                        :initialQuery="[
                           {
                              attribute: 'is_archived',
                              query: 'true',
                           },
                        ]"
                        :allowedStatusInStatusFilter="[
                           'answered',
                           'rejected',
                           'canceled',
                        ]"
                        hideAddButton
                        hideRemoveButton
                        ref="archivedrequestlist"
                     />
                  </v-tab-item>
               </v-tabs>

               <v-tabs
                  v-else-if="
                     userStore.sub_type === 'solicitator' ||
                     userStore.sub_type === 'secretary_manager'
                  "
                  v-model="activeOperatorTab"
                  centered
                  show-arrows
               >
                  <v-tab id="request-health-unit-button">
                     <v-icon small left> mdi-hospital-building </v-icon>
                     SOLICITAÇÕES DA
                     {{
                        userStore.sub_type === "solicitator"
                           ? "UNIDADE"
                           : "SECRETARIA"
                     }}
                  </v-tab>
                  <v-tab id="my_request-health-unit-button">
                     <v-icon small left> mdi-account </v-icon>
                     MINHAS SOLICITAÇÕES
                  </v-tab>
                  <v-tab id="filed-health-unit-button">
                     <v-icon small left> mdi-folder-open </v-icon>
                     SOLICITAÇÕES FINALIZADAS
                  </v-tab>

                  <v-tab-item>
                     <SurgeryRequestOperatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequest"
                        @onClickEditRequest="onClickEditRequest"
                        @onClickRemoveRequest="onClickRemoveRequest"
                        :healthUnit="
                           operatorCurrentHealthUnitStore ||
                           healthSecretaryManagerCurrentHealthUnitStore
                        "
                        :operatorType="userStore.sub_type"
                        :initialQuery="[
                           { attribute: 'is_closed', query: 'false' },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :userInfo="'all-health-unit'"
                        hideAddButton
                        ref="allhealthunitrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestOperatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequest"
                        @onClickEditRequest="onClickEditRequest"
                        @onClickRemoveRequest="onClickRemoveRequest"
                        :healthUnit="
                           operatorCurrentHealthUnitStore ||
                           healthSecretaryManagerCurrentHealthUnitStore
                        "
                        :operatorType="userStore.sub_type"
                        :initialQuery="[
                           { attribute: 'is_closed', query: 'false' },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :user="userStore"
                        :userInfo="'my-health-unit'"
                        ref="myhealthunitrequestlist"
                     />
                  </v-tab-item>

                  <v-tab-item>
                     <SurgeryRequestOperatorList
                        @onClickAddRequest="onClickAddRequest"
                        @onClickViewRequest="onClickViewRequest"
                        @onClickEditRequest="onClickEditRequest"
                        @onClickRemoveRequest="onClickRemoveRequest"
                        :healthUnit="
                           operatorCurrentHealthUnitStore ||
                           healthSecretaryManagerCurrentHealthUnitStore
                        "
                        :operatorType="userStore.sub_type"
                        :initialQuery="[
                           {
                              attribute: 'is_closed',
                              query: 'true',
                           },
                           {
                              attribute: 'is_archived',
                              query: 'ne:true',
                           },
                        ]"
                        :userInfo="'filed-health-unit'"
                        hideAddButton
                        ref="filedhealthunitrequestlist"
                     />
                  </v-tab-item>
               </v-tabs>
            </v-card>
         </v-col>
      </v-row> -->

      <v-card class="overflow-hidden mt-12">
         <!-- Listagem exibida para o Administrador -->
         <v-tabs
            v-if="userStore.sub_type === 'admin'"
            v-model="activeAdminTab"
            centered
            show-arrows
         >
            <v-tab id="surgery-avaliables-button">
               <v-icon small left>$box-cutter-check</v-icon>
               CIRURGIAS ACEITAS
            </v-tab>

            <v-tab id="surgery-not-avaliables-button">
               <v-icon small left>$box-cutter-close</v-icon>
               CIRURGIAS NÃO ACEITAS
            </v-tab>

            <v-tab id="filed-button">
               <v-icon small left> mdi-folder-open </v-icon>
               SOLICITAÇÕES FINALIZADAS
            </v-tab>

            <v-tab id="archived-button">
               <v-icon small left> mdi-archive-arrow-down </v-icon>
               SOLICITAÇÕES ARQUIVADAS
            </v-tab>

            <v-tab-item>
               <SurgeryRequestAdminList
                  @onClickViewRequest="onClickViewRequestRegulator"
                  :initialQuery="[
                     {
                        attribute: 'is_available',
                        query: 'true'
                     },
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="availablesurgeriesrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestAdminList
                  @onClickViewRequest="onClickViewRequestRegulator"
                  :initialQuery="[
                     {
                        attribute: 'is_available',
                        query: 'false'
                     },
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="unavailablesurgeriesrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestAdminList
                  @onClickViewRequest="onClickViewRequestRegulator"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'true'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="filedrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestAdminList
                  @onClickViewRequest="onClickViewRequestRegulator"
                  :initialQuery="[
                     {
                        attribute: 'is_archived',
                        query: 'true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="archivedrequestlist"
               />
            </v-tab-item>
         </v-tabs>

         <!-- Listagem exibida para Regulador e Gestor Regulador -->
         <v-tabs
            v-else-if="
               userStore.sub_type === 'regulator_manager' ||
                  userStore.sub_type === 'regulator'
            "
            v-model="activeRegulatorTab"
            centered
            show-arrows
         >
            <v-tab id="surgery-avaliables-health-unit-button">
               <v-icon small left>$box-cutter-check</v-icon>
               CIRURGIAS ACEITAS
            </v-tab>

            <v-tab id="surgery-not-avaliables-health-unit-button">
               <v-icon small left>$box-cutter-close</v-icon>
               CIRURGIAS NÃO ACEITAS
            </v-tab>

            <v-tab id="my_request-health-unit-button">
               <v-icon small left>mdi-account</v-icon>
               MINHAS SOLICITAÇÕES
            </v-tab>

            <v-tab id="filed-health-unit-button">
               <v-icon small left> mdi-folder-open </v-icon>
               SOLICITAÇÕES FINALIZADAS
            </v-tab>

            <!-- Aba visível só para o gestor regulador -->
            <v-tab
               v-if="userStore.sub_type === 'regulator_manager'"
               id="archived-health-unit-button"
            >
               <v-icon small left> mdi-archive-arrow-down </v-icon>
               SOLICITAÇÕES ARQUIVADAS
            </v-tab>

            <v-tab-item>
               <SurgeryRequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequestRegulator"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_available',
                        query: 'true'
                     },
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="availablesurgeriesrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequestRegulator"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_available',
                        query: 'false'
                     },
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="unavailablesurgeriesrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequestRegulator"
                  @onClickEditRequest="onClickEditRequest"
                  :user="userStore"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="myrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequestRegulator"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'true'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="filedrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequestRegulator"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_archived',
                        query: 'true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="archivedrequestlist"
               />
            </v-tab-item>
         </v-tabs>

         <!-- Listagem exibida para Operador Solicitante e Gestor de secretaria -->
         <v-tabs
            v-else-if="
               userStore.sub_type === 'solicitator' ||
                  userStore.sub_type === 'secretary_manager'
            "
            v-model="activeOperatorTab"
            centered
            show-arrows
         >
            <v-tab id="request-health-unit-button">
               <v-icon small left> mdi-hospital-building </v-icon>
               SOLICITAÇÕES DA
               {{
                  userStore.sub_type === "solicitator"
                     ? "UNIDADE"
                     : "SECRETARIA"
               }}
            </v-tab>
            <v-tab id="my_request-health-unit-button">
               <v-icon small left> mdi-account </v-icon>
               MINHAS SOLICITAÇÕES
            </v-tab>
            <v-tab id="filed-health-unit-button">
               <v-icon small left> mdi-folder-open </v-icon>
               SOLICITAÇÕES FINALIZADAS
            </v-tab>

            <v-tab-item>
               <SurgeryRequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     { attribute: 'is_closed', query: 'false' },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :userInfo="'all-health-unit'"
                  hideAddButton
                  ref="allhealthunitrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     { attribute: 'is_closed', query: 'false' },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :user="userStore"
                  :userInfo="'my-health-unit'"
                  ref="myhealthunitrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <SurgeryRequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'true'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :userInfo="'filed-health-unit'"
                  hideAddButton
                  ref="filedhealthunitrequestlist"
               />
            </v-tab-item>
         </v-tabs>
      </v-card>
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import SurgeryRequestCancelForm from "components/surgeryRequest/cancel/SurgeryRequestCancelForm";
import PageTitle from "../../components/base/PageTitle";
import SurgeryRequestOperatorForm from "../../components/surgeryRequest/form/SurgeryRequestOperatorForm";
import SurgeryRequestAdminForm from "../../components/surgeryRequest/form/SurgeryRequestAdminForm";
import SurgeryRequestRegulatorForm from "../../components/surgeryRequest/form/SurgeryRequestRegulatorForm";
import SurgeryRequestAdminList from "../../components/surgeryRequest/list/SurgeryRequestAdminList";
import SurgeryRequestOperatorList from "../../components/surgeryRequest/list/SurgeryRequestOperatorList";
import SurgeryRequestRegulatorList from "../../components/surgeryRequest/list/SurgeryRequestRegulatorList";
import responsiveUtil from "../../utils/responsiveUtil";
import regulatorService from "../../services/regulatorService";
import regulatorManagerService from "../../services/regulatorManagerService";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import socketIoService from "services/socketIoService";

export default {
   name: "SurgeryRequest",

   components: {
      Close,
      SurgeryRequestCancelForm,
      PageTitle,
      SurgeryRequestOperatorForm,
      SurgeryRequestAdminForm,
      SurgeryRequestRegulatorForm,
      SurgeryRequestAdminList,
      SurgeryRequestOperatorList,
      SurgeryRequestRegulatorList
   },

   data: function() {
      return {
         requestSelected: {},

         editMode: false,

         activeAdminTab: 0,
         activeOperatorTab: 0,
         activeRegulatorTab: 0,

         dialogSurgeryRequestCancelFormVisibility: false,
         dialogRequestFormVisibility: false,

         disableDialogScrolling: false,

         socketIoConnection: null
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      healthSecretaryManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      },

      hideEditButtonOperator() {
         return !["registered", "in_progress"].includes(
            this.requestSelected.status
         );
      },

      hideEditButtonRegulator() {
         return (
            this.requestSelected.is_closed || !this.requestSelected.is_checked
         );
      },

      /* Esconde o botão de Finalizar a prescrição para todos os usuário exceto o Gestor regulador e para os status
      diferentes de "solicitada", "em andamento", e "rejeitada para o executante". */
      hideCloseRequestButtonRegulator() {
         return !(
            this.userStore.sub_type === "regulator_manager" &&
            ["registered", "in_progress", "rejected_by_executor"].includes(
               this.requestSelected.status
            )
         );
      },

      /* 
      Esconde o botão de reservar cirurgia caso:
      1. A solicitação esteja fechada.
      2. O regulador esteja na aba de cirurgias não aceitas.
      3. A solicitação não tenha cirurgia associada. 
      4. A solicitação não tem parecer médico.
      5. A solicitação tem status diferente de in_progress ou rejected_by_executor.
      */
      hideReserveBedButtonRegulator() {
         if (
            !this.requestSelected.is_checked ||
            this.activeRegulatorTab === 1 ||
            !(
               this.requestSelected.doctor_opinion &&
               this.requestSelected.doctor_opinion.sus_code
            ) ||
            !(
               this.requestSelected.doctor_opinion &&
               this.requestSelected.doctor_opinion.priority
            )
         )
            return true;
         else {
            return !["in_progress", "rejected_by_executor"].includes(
               this.requestSelected.status
            );
         }
      },

      /* Esconde o botão do chat para o operador caso a solicitação tenha sido cadastrada pelo OperaPB. */
      hideChatButtonOperator() {
         return !!this.requestSelected.was_created_by_patient;
      }
   },

   watch: {
      dialogRequestFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;

         if (
            ["regulator_manager", "regulator"].includes(this.userStore.sub_type)
         ) {
            // Efetua block e unblock nas requisições
            if (this.requestSelected.id) {
               if (val) {
                  // Efetua o block da request
                  this.socketIoConnection.emit(
                     "block_request",
                     this.requestSelected.id,
                     "surgery"
                  );
               } else {
                  // Efetua o unblock da request
                  this.socketIoConnection.emit(
                     "unblock_request",
                     this.requestSelected.id,
                     "surgery"
                  );
               }
            }
         }
      },

      activeAdminTab() {
         this.refreshRequestAdminList();
      },

      activeRegulatorTab() {
         this.refreshRequestRegulatorList();
      },

      activeOperatorTab() {
         this.refreshRequestOperatorList();
      },

      /**
       * socketIoConnection.on
       * Conexão com socket.io, usado só para o Regulador e Gestor regulador na
       * parte de bloquear e desbloquear a visualização das solicitações.
       */
      "socketIoConnection.on": function() {
         this.socketIoConnection.on("connect", () => {});

         this.socketIoConnection.on("block_request", requestId => {
            const component = this.getActiveRequestRegulatorList();
            if (component) component.blockRequestByRef(requestId);
         });

         this.socketIoConnection.on("unblock_request", requestId => {
            const component = this.getActiveRequestRegulatorList();
            if (component) component.unblockRequestByRef(requestId);
         });

         // this.socketIoConnection.on("error", (err) => {});
      },

      /**
       * Monitora os query params na url, caso tenha algum parâmetro vai checa-lós
       * e abrir a tab específica de acordo com os query passados.
       * OBS.: Válido só para o regulador e gestor regulador.
       */
      "$route.query": {
         immediate: true,
         handler(value) {
            switch (this.userStore.sub_type) {
               case "regulator_manager":
               case "regulator":
                  if (Object.keys(value).length) {
                     // Acessa o query params e abre a tab especificada nos query params.
                     this.setTabIndexFromQueryParams();
                  }
                  break;
            }
         }
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddRequest(request) {
         this.requestSelected = Object.assign({}, request);
         this.openRequestForm();
      },

      onClickViewRequestRegulator(request) {
         if (!request.is_blocked) {
            this.requestSelected = Object.assign({}, request);
            this.openRequestForm();
         } else {
            snackBarUtil.showCustomSnackBar({
               color: "warning",
               title: "SOLICITAÇÃO BLOQUEADA!",
               message: "Outro usuário já está visualizando essa solicitação"
            });
         }
      },

      onClickViewRequest(request) {
         this.requestSelected = Object.assign({}, request);
         this.openRequestForm();
      },

      onClickEditRequest() {
         this.editMode = true;
      },

      onClickRemoveRequest(request) {
         this.requestSelected = Object.assign({}, request);
         this.openSurgeryRequestCancelForm();
      },

      openRequestForm() {
         this.dialogRequestFormVisibility = true;
      },

      closeRequestForm() {
         this.dialogRequestFormVisibility = false;
      },

      openSurgeryRequestCancelForm() {
         this.dialogSurgeryRequestCancelFormVisibility = true;
      },

      closeSurgeryRequestCancelForm() {
         this.dialogSurgeryRequestCancelFormVisibility = false;
      },

      dialogScroll(status) {
         this.disableDialogScrolling = !!status;
      },

      getActiveRequestAdminList() {
         let component;

         switch (this.activeAdminTab) {
            case 0:
               component = this.$refs.availablesurgeriesrequestlist;
               break;
            case 1:
               component = this.$refs.unavailablesurgeriesrequestlist;
               break;
            case 2:
               component = this.$refs.filedrequestlist;
               break;
            case 3:
               component = this.$refs.archivedrequestlist;
               break;
         }

         return component;
      },

      refreshRequestAdminList() {
         const component = this.getActiveRequestAdminList();
         if (component) component.refreshAllRequestsByRef(true);
      },

      getActiveRequestOperatorList() {
         let component;

         switch (this.activeOperatorTab) {
            case 0:
               component = this.$refs.allhealthunitrequestlist;
               break;
            case 1:
               component = this.$refs.myhealthunitrequestlist;
               break;
            case 2:
               component = this.$refs.filedhealthunitrequestlist;
               break;
         }

         return component;
      },

      refreshRequestOperatorList() {
         const component = this.getActiveRequestOperatorList();
         if (component)
            component.refreshAllHealthUnitSurgeryRequestsByRef(true);
      },

      onSendMessageOperatorForm(request) {
         const component = this.getActiveRequestOperatorList();
         if (component) component.incrementRequestTotalMessagesByRef(request);
      },

      getActiveRequestRegulatorList() {
         let component;

         switch (this.activeRegulatorTab) {
            case 0:
               component = this.$refs.availablesurgeriesrequestlist;
               break;
            case 1:
               component = this.$refs.unavailablesurgeriesrequestlist;
               break;
            case 2:
               component = this.$refs.myrequestlist;
               break;
            case 3:
               component = this.$refs.filedrequestlist;
               break;
            case 4:
               component = this.$refs.archivedrequestlist;
               break;
         }

         return component;
      },

      refreshRequestRegulatorList() {
         const component = this.getActiveRequestRegulatorList();
         if (component) component.refreshAllRequestsByRef(true);
      },

      setRegulatorMessagesAsRead() {
         // Zera as mensagens não lidas
         this.requestSelected.unreadMessages = 0;
         // Marca a notificação com não lida
         this.$store.commit(
            "notificationCenter/setNewMessageNotificationAsRead",
            this.requestSelected.id
         );

         const component = this.getActiveRequestRegulatorList();
         if (component)
            component.setMessagesAsReadByRef(this.requestSelected.id);
      },

      onSendMessageRegulatorForm(request) {
         const component = this.getActiveRequestRegulatorList();
         if (component) component.incrementRequestTotalMessagesByRef(request);
      },

      async getRegulatorById(regulatorId) {
         try {
            const response = await regulatorService.getRegulatorById(
               regulatorId
            );

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      async getRegulatorManagerById(regulatorManagerId) {
         try {
            const response = await regulatorManagerService.getRegulatorManagerById(
               regulatorManagerId
            );

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      // Identifica os parametros do url e abre a aba específica nas tabs das solicitações.
      setTabIndexFromQueryParams() {
         const queryParams = this.$route.query;

         if (queryParams.is_closed === "true") this.activeRegulatorTab = 3;
         else if (queryParams.is_available === "false")
            this.activeRegulatorTab = 1;
         else this.activeRegulatorTab = 0;
      }
   },

   async created() {
      switch (this.userStore.sub_type) {
         case "solicitator":
            if (
               !(
                  this.operatorCurrentHealthUnitStore &&
                  this.operatorCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "secretary_manager":
            if (
               !(
                  this.healthSecretaryManagerCurrentHealthUnitStore &&
                  this.healthSecretaryManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "regulator_manager":
            await this.getRegulatorManagerById(this.userStore.sub);

            // Conexão com socket.io
            this.socketIoConnection = socketIoService.getConnection();
            break;
         case "regulator":
            await this.getRegulatorById(this.userStore.sub);

            // Conexão com socket.io
            this.socketIoConnection = socketIoService.getConnection();
            break;
      }
   }
};
</script>

<style scoped>
::v-deep .dialog-overflow-hidden {
   overflow-y: hidden !important;
}

.fullscreen-width {
   width: 1000px !important;
   max-width: 1000px !important;
}
</style>
