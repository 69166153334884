var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingOperatorsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.operators,"loading":_vm.loadingOperatorsPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (operator) { return (!_vm.blockClick ? _vm.onClickViewOperator(operator) : null); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('OperatorAdminListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingOperatorsPartially,"filterActor":"operador","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddOperator}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalOperators,"loading":_vm.loadingOperatorsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhum operador foi encontrado."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando operadores, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"name-operator-admin-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"cpf-operator-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatCPF(item.cpf))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"email-operator-admin-list-data"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.birth_date",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"birth_date-operator-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.birth_date))+" ")])]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"last_login-operator-admin-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm"))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"id":("edit-item-" + index + "-operator-admin-list-button"),"color":"black","small":""},on:{"click":function($event){return _vm.editOperator(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Para excluir um operador "),_c('br'),_vm._v(" você deve dessasociar "),_c('br'),_vm._v(" todas as entidades de saúde ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }