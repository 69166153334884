<template>
   <div>
      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="operatorResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeCapabilitiesFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeCapabilitiesForm" />

            <ChangeCapabilitiesForm
               @onClickCancel="closeCapabilitiesForm"
               @onResetCapabilities="closeCapabilitiesForm"
               :health_unit="operatorHealthUnit"
               :operator="operatorSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogOperatorAdminActiveFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeOperatorAdminActiveForm" />

            <OperatorAdminActiveForm
               @onActivedOperator="
                  closeOperatorAdminActiveForm();
                  refreshOperatorList();
               "
               :operator="operatorSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshOperatorList();
               "
               :user="operatorChangeTypeSelected"
               currentType="operator"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogOperatorFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeOperatorForm" />

            <div v-if="userStore.sub_type === 'admin'">
               <v-tabs v-model="activeTab" centered show-arrows>
                  <v-tab id="operator-button">
                     <v-icon small left> mdi-account-hard-hat </v-icon>
                     OPERADOR
                  </v-tab>
                  <v-tab
                     v-if="operatorSelected.id"
                     id="health_unit-operator-button"
                  >
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-chip
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              class="mr-1 pa-1"
                              x-small
                           >
                              Novo
                           </v-chip>
                        </template>

                        <p class="text-center">
                           Novo sistema de alterar<br />
                           o acesso do operador
                        </p>
                     </v-tooltip>
                     <v-icon small left> mdi-hospital-building </v-icon>
                     UNIDADES DE SAÚDE DO OPERADOR
                  </v-tab>
                  <v-tab
                     v-if="operatorSelected.id"
                     id="secretarie-operator-button"
                  >
                     <v-icon small left> mdi-home-plus </v-icon>
                     SECRETARIAS DE SAÚDE DO OPERADOR
                  </v-tab>
               </v-tabs>

               <div v-if="activeTab === 0">
                  <OperatorAdminForm
                     @onClickCancel="closeOperatorForm"
                     @onCreatedOperator="
                        closeOperatorForm();
                        refreshOperatorList();
                     "
                     @onUpdatedOperator="
                        closeOperatorForm();
                        refreshOperatorList();
                     "
                     @onClickResetOperator="onClickResetOperator"
                     @onClickRemoveOperator="onClickRemoveOperatorAdmin"
                     @onFindDisabledOperator="onFindDisabledOperator"
                     @onClickChangeType="onClickChangeType"
                     :operator="operatorSelected"
                     :editMode="editMode"
                     class="standard-padding"
                     hideRemoveButton
                     hideTitle
                  />
               </div>
               <div v-else-if="activeTab === 1 && operatorSelected.id">
                  <OperatorHealthUnits
                     @onUpdatedOperator="
                        closeOperatorForm();
                        refreshOperatorList();
                     "
                     :operator="operatorSelected"
                     class="standard-padding"
                  />
               </div>
               <div v-else-if="activeTab === 2 && operatorSelected.id">
                  <OperatorHealthSecretaries
                     @onUpdatedOperator="
                        closeOperatorForm();
                        refreshOperatorList();
                     "
                     :operator="operatorSelected"
                     class="standard-padding"
                  />
               </div>
            </div>

            <OperatorHealthUnitManagerForm
               v-else-if="userStore.sub_type === 'healthunit_manager'"
               @onClickCancel="closeOperatorForm"
               @onCreatedOperator="
                  closeOperatorForm();
                  refreshOperatorList();
               "
               @onUpdatedOperator="
                  closeOperatorForm();
                  refreshOperatorList();
               "
               @onClickResetOperator="onClickResetOperator"
               @onClickChangeCapabilitiesOperator="
                  onClickChangeCapabilitiesOperator
               "
               @onClickRemoveOperator="onClickRemoveOperatorHealthUnitManager"
               :operator="operatorSelected"
               :healthUnit="healthUnitManagerCurrentHealthUnitStore"
               :editMode="editMode"
               class="standard-padding"
               hideChangeTypeButton
            />

            <OperatorHealthSecretaryManagerForm
               v-else-if="userStore.sub_type === 'secretary_manager'"
               @onClickCancel="closeOperatorForm"
               @onCreatedOperator="
                  closeOperatorForm();
                  refreshOperatorList();
               "
               @onUpdatedOperator="
                  closeOperatorForm();
                  refreshOperatorList();
               "
               @onClickResetOperator="onClickResetOperator"
               @onClickRemoveOperator="
                  onClickRemoveOperatorHealthSecretaryManager
               "
               :operator="operatorSelected"
               :healthSecretary="
                  healthSecretaryManagerCurrentHealthSecretaryStore
               "
               :editMode="editMode"
               class="standard-padding"
               hideChangeTypeButton
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <OperatorAdminList
         v-if="userStore.sub_type === 'admin'"
         @onClickAddOperator="onClickAddOperator"
         @onClickViewOperator="onClickViewOperator"
         @onClickEditOperator="onClickEditOperator"
         @onClickRemoveOperator="onClickRemoveOperatorAdmin"
         class="mt-12"
         ref="operatoradminlist"
      />

      <OperatorHealthUnitManagerList
         v-else-if="userStore.sub_type === 'healthunit_manager'"
         @onClickAddOperator="onClickAddOperator"
         @onClickViewOperator="onClickViewOperator"
         @onClickEditOperator="onClickEditOperator"
         @onClickRemoveOperator="onClickRemoveOperatorHealthUnitManager"
         :healthUnit="healthUnitManagerCurrentHealthUnitStore"
         class="mt-12"
         ref="operatorhealthunitmanagerlist"
      />

      <OperatorHealthSecretaryManagerList
         v-else-if="userStore.sub_type === 'secretary_manager'"
         @onClickAddOperator="onClickAddOperator"
         @onClickViewOperator="onClickViewOperator"
         @onClickEditOperator="onClickEditOperator"
         @onClickRemoveOperator="onClickRemoveOperatorHealthSecretaryManager"
         :healthSecretary="healthSecretaryManagerCurrentHealthSecretaryStore"
         class="mt-12"
         ref="operatorhealthsecretarymanagerlist"
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import PageTitle from "../../components/base/PageTitle";
import OperatorAdminList from "../../components/operator/OperatorAdminList";
import OperatorHealthUnitManagerList from "../../components/operator/OperatorHealthUnitManagerList";
import OperatorHealthSecretaryManagerList from "../../components/operator/OperatorHealthSecretaryManagerList";
import OperatorAdminForm from "../../components/operator/OperatorAdminForm";
import OperatorHealthUnitManagerForm from "../../components/operator/OperatorHealthUnitManagerForm";
import OperatorHealthSecretaryManagerForm from "../../components/operator/OperatorHealthSecretaryManagerForm";
import OperatorHealthUnits from "../../components/operator/associationHealthUnitsForm/OperatorHealthUnits";
import OperatorHealthSecretaries from "../../components/operator/associationHealthSecretarieForm/OperatorHealthSecretaries";
import OperatorAdminActiveForm from "components/operator/OperatorAdminActiveForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import responsiveUtil from "../../utils/responsiveUtil";
import operatorService from "../../services/operatorService";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm.vue";
import ChangeCapabilitiesForm from "components/operator/associationHealthUnitsForm/ChangeCapabilitiesForm.vue";

export default {
   name: "Operator",

   components: {
      Close,
      PageTitle,
      OperatorAdminList,
      OperatorHealthUnitManagerList,
      OperatorHealthSecretaryManagerList,
      OperatorAdminForm,
      OperatorHealthUnitManagerForm,
      OperatorHealthSecretaryManagerForm,
      OperatorHealthUnits,
      OperatorHealthSecretaries,
      OperatorAdminActiveForm,
      ResetPasswordUsersForm,
      ChangeTypeUserForm,
      ChangeCapabilitiesForm
   },

   data: function() {
      return {
         operatorHealthUnit: {},
         operatorSelected: {},
         operatorResetPasswordSelected: {},
         operatorChangeTypeSelected: {},

         editMode: false,

         dialogOperatorFormVisibility: false,
         dialogOperatorAdminActiveFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogChangeCapabilitiesFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false,

         activeTab: 0
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      healthSecretaryManagerCurrentHealthSecretaryStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      }
   },

   watch: {
      dialogOperatorFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddOperator(operator) {
         this.operatorSelected = Object.assign({}, operator);
         this.openOperatorForm();
      },

      onClickViewOperator(operator) {
         this.operatorSelected = Object.assign({}, operator);
         this.openOperatorForm();
      },

      onClickEditOperator() {
         this.editMode = true;
      },

      onClickChangeType(operator) {
         this.operatorChangeTypeSelected = Object.assign({}, operator);
         this.closeOperatorForm();
         this.openChangeTypeUserForm();
      },

      onClickResetOperator(operator) {
         this.operatorResetPasswordSelected = Object.assign({}, operator);
         this.openResetPasswordForm();
      },

      onClickChangeCapabilitiesOperator() {
         this.operatorHealthUnit = this.operatorSelected.health_units.find(
            el => el.id === this.healthUnitManagerCurrentHealthUnitStore.id
         );
         this.openCapabilitiesForm();
      },

      onClickRemoveOperatorHealthSecretaryManager(item) {
         const methodDeleteItem = async () => {
            await operatorService.disassociateOperatorFromHealthSecretarieById(
               item.id,
               this.healthSecretaryManagerCurrentHealthSecretaryStore.id
            );

            this.dialogOperatorFormVisibility = false;
            this.refreshOperatorList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse operador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onClickRemoveOperatorHealthUnitManager(item) {
         const methodDeleteItem = async () => {
            await operatorService.disassociateOperatorFromHealthUnitById(
               item.id,
               this.healthUnitManagerCurrentHealthUnitStore.id
            );

            this.dialogOperatorFormVisibility = false;
            this.refreshOperatorList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse operador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onClickRemoveOperatorAdmin(item) {
         const methodDeleteItem = async () => {
            await operatorService.removeOperator(item.id, false);

            this.dialogOperatorFormVisibility = false;
            this.refreshOperatorList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse operador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledOperator(item) {
         this.operatorSelected = Object.assign({}, item);

         this.closeOperatorForm();
         this.openOperatorAdminActiveForm();
         // const methodActiveItem = async () => {
         //    await operatorService.activeAdmin(item.id, true);

         //    this.refreshAdminList();

         //    snackBarUtil.showCustomSnackBar({
         //       color: "success",
         //       title: "SUCESSO!",
         //       message: "O operador foi ativado!",
         //    });
         // };

         // this.dialogAdminFormVisibility = false;

         // snackBarUtil.showCustomSnackBar({
         //    color: "info",
         //    title: "ADMINISTRADOR JÁ EXISTENTE",
         //    message:
         //       "Um operador desativado com esse CPF foi encontrado, deseja ativá-lo?",
         //    action: {
         //       text: "Confirmar",
         //       method: methodActiveItem,
         //       closeOnFinished: false,
         //    },
         // });
      },

      openOperatorForm() {
         if (this.userStore.sub_type === "admin") this.activeTab = 0;
         this.dialogOperatorFormVisibility = true;
      },

      closeOperatorForm() {
         this.dialogOperatorFormVisibility = false;
      },

      openOperatorAdminActiveForm() {
         this.dialogOperatorAdminActiveFormVisibility = true;
      },

      closeOperatorAdminActiveForm() {
         this.dialogOperatorAdminActiveFormVisibility = false;
      },

      openCapabilitiesForm() {
         this.dialogChangeCapabilitiesFormVisibility = true;
      },

      closeCapabilitiesForm() {
         this.dialogChangeCapabilitiesFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      refreshOperatorList() {
         if (this.userStore.sub_type === "admin")
            this.$refs.operatoradminlist.refreshAllOperatorsByRef();
         else if (this.userStore.sub_type === "healthunit_manager")
            this.$refs.operatorhealthunitmanagerlist.refreshAllOperatorsByRef();
         else if (this.userStore.sub_type === "secretary_manager")
            this.$refs.operatorhealthsecretarymanagerlist.refreshAllOperatorsByRef();
      }
   },

   /* Toda vez ao exibir essa view é verificado se já existe o ID da unidade de saúde que essa pessoa deseja acessar,
   caso não exista, a tela de escolha da unidade de saúde é exibida, caso exista não será aberta a tela de escolha. */
   created() {
      switch (this.userStore.sub_type) {
         case "secretary_manager":
            if (
               !(
                  this.healthSecretaryManagerCurrentHealthSecretaryStore &&
                  this.healthSecretaryManagerCurrentHealthSecretaryStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;
      }
   }
};
</script>

<style scoped></style>
