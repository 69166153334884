var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingRequestsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit,"hideAddButton":_vm.hideAddButton}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.requests,"loading":_vm.loadingRequestsPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (admin) { return (!_vm.blockClick ? _vm.onClickViewRequest(admin) : null); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('SurgeryRequestListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"date":_vm.date,"initialFilterBy":_vm.initialFilterBy,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingRequestsPartially,"hideAddButton":_vm.hideAddButton,"allowedStatusInStatusFilter":_vm.allowedStatusInStatusFilter,"filterActor":"solicitação","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeDate":_vm.onChangeDate,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddRequest}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalRequests,"loading":_vm.loadingRequestsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhuma solicitação foi encontrada."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando solicitações, aguarde por favor."}})],1)]},proxy:true},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{key:_vm.newRequestKey,staticClass:"d-flex align-center ma-n1"},[_c('p',{staticClass:"code-regulator-request-operator-list-data ma-1"},[_vm._v(" "+_vm._s(item.code)+" ")]),(_vm.requestIsNew(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"chip-adjust ma-1",attrs:{"color":"primary","x-small":""}},'v-chip',attrs,false),on),[_vm._v(" Recente ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Solicitação cadastrada "),_c('br'),_vm._v(" "+_vm._s(_vm.relativeDate(item.created_at))+" ")])]):_vm._e(),(item.was_created_by_patient)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","size":"17","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cellphone-text ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Cadastrado pelo "),_c('br'),_vm._v(" OperaPB ")])]):_vm._e()],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:("request_created_at-" + _vm.userInfo + "-request-operator-list-data")},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatDateTime(item.created_at, "DD/MM [às] HH:mm"))+" ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.created_at))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.relativeDate(item.created_at))+" ")])])]}},{key:"item.municipality.name",fn:function(ref){
var item = ref.item;
return [_c('p',{class:"municipality-name-regulator-request-operator-list-data"},[_vm._v(" "+_vm._s(item.municipality.name)+" ")])]}},{key:"item.patient.name",fn:function(ref){
var item = ref.item;
return [_c('p',{class:"name-regulator-request-operator-list-data"},[_vm._v(" "+_vm._s(item.patient.name)+" ")])]}},{key:"item.surgery.name",fn:function(ref){
var item = ref.item;
return [(item.surgery && item.surgery.name)?_c('p',[_vm._v(" "+_vm._s(item.surgery.name)+" ")]):_c('p',[_vm._v("Sem cirurgia")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center ma-n1"},[_c('p',{staticClass:"font-weight-medium ma-1",class:((_vm.generateState(item.status).color) + "--text")},[_vm._v(" "+_vm._s(_vm.generateState(item.status).text)+" ")]),(item.doctor_opinion && item.doctor_opinion.priority)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":_vm.translatePriority(item.doctor_opinion.priority).color,"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Prioridade: "+_vm._s(_vm.translatePriority(item.doctor_opinion.priority) .translatedPriority)+" ")])]):_vm._e()],1)]}},{key:"item.alerts",fn:function(ref){
var item = ref.item;
return [_c('div',{key:_vm.messageNotificationKey,staticClass:"d-flex justify-center align-center flex-wrap mx-n1"},[(item.totalMessages)?_c('v-card',{staticClass:"mx-1",attrs:{"color":"transparent","height":"20px","width":"20px","flat":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-message-notification",attrs:{"color":"orange","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-chat ")])]}}],null,true)},[(!item.unreadMessages)?_c('p',{staticClass:"text-center"},[_vm._v(" Chat iniciado ")]):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.unreadMessages)+" "+_vm._s(item.unreadMessages > 1 ? "mensagens" : "mensagem")+" ")])]),_c('div',{staticClass:"icon-content-notification"},[(!item.unreadMessages)?_c('v-icon',{attrs:{"size":"11","dark":""}},[_vm._v(" mdi-check ")]):_c('p',{staticClass:"count-message-label"},[_vm._v(" "+_vm._s(item.unreadMessages)+" ")])],1)],1):_vm._e(),(item.is_blocked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1 pulsate-fwd",attrs:{"color":"error","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('p',{staticClass:"text-center"},[_vm._v(" Solicitação "),_c('br'),_vm._v(" sendo visualizada ")])]):_vm._e(),(item.is_checked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")])]}}],null,true)},[_c('p',[_vm._v("Validada")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"#bdbdbd","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('p',[_vm._v("Não validada")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-n1"},[(!_vm.hideEditButton && !_vm.hideEditButtonRegulator(item))?_c('v-icon',{staticClass:"mx-1 edit-request-regulator-list-data",attrs:{"color":"black","id":"edit-button-surgery-request","small":""},on:{"click":function($event){return _vm.editRequest(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.hideRemoveButton)?_c('v-icon',{staticClass:"mx-1 delete-request-regulator-list-data",attrs:{"color":"error","id":"remove-button-surgery-request","small":""}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }