<template>
   <div>
      <v-card
         class="
            standard-padding-whopping
            card-border
            d-flex
            flex-column
            justify-center
            align-stretch
         "
         elevation="3"
      >
         <div class="d-flex justify-center align-center">
            <v-icon size="150">
               $reg-logo
            </v-icon>
         </div>

         <v-form ref="form">
            <v-text-field
               v-model="formData.email"
               @keyup.enter="forgotPassword(formData)"
               :rules="[
                  (value) => !!value || 'Email obrigatório.',
                  (value) => emailRules(value) || 'Email inválido.',
               ]"
               class="mt-9 body-1 label-position"
               id="email-forgot-password-input"
               height="44"
               ><template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-email</v-icon>
               </template>

               <template v-slot:label>
                  <p class="body-1">Email</p>
               </template>
            </v-text-field>

            <!-- Se não colocar esse text-field, buga ao apertar enter -->
            <v-text-field v-show="false"></v-text-field>
         </v-form>

         <v-alert
            class="ma-0 mt-12 caption"
            color="primary"
            type="info"
            border="left"
            dense
            text
            >As instruções para recuperar a senha serão enviadas para o email
            cadastrado.</v-alert
         >

         <div class="mt-7">
            <vue-recaptcha
               id="recaptcha"
               @verify="recaptchaVerify"
               @render="recaptchaRender"
               @expired="recaptchaExpired"
               :sitekey="siteKey"
            ></vue-recaptcha>
            <p
               v-if="recaptchaValidation"
               class="ma-0 mb-n5 caption error--text"
            >
               Preencha a confirmação para continuar.
            </p>
         </div>

         <v-btn
            @click="forgotPassword(formData)"
            :loading="loadingForgotPassword"
            class="mt-8 py-6"
            color="primary"
            id="forgot-password-button"
            large
            block
            >ENVIAR EMAIL</v-btn
         >

         <div class="d-flex justify-center align-center mt-6">
            <router-link
               class="router-link-back-auth"
               to="/autenticacao"
               id="go-to-auth-button"
            >
               <p class="caption primary--text label-back-auth">
                  Voltar para tela de autenticação
               </p>
            </router-link>
         </div>
      </v-card>
   </div>
</template>

<script>
import snackBarUtil from "../../utils/snackBarUtil";
import validationUtil from "../../utils/validationUtil";
import authService from "../../services/authService";
import VueRecaptcha from "vue-recaptcha";

export default {
   name: "ForgotPasswordCard",

   components: { VueRecaptcha },

   data: function () {
      return {
         formData: {},

         loadingForgotPassword: false,

         recaptchaToken: null,
         recaptchaRendered: false,
         recaptchaValidation: false,
      };
   },

   computed: {
      siteKey() {
         return (
            process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
            "VUE_APP_RECAPTCHA_SITE_KEY"
         );
      },
   },

   methods: {
      async forgotPassword(data) {
         const dataValidation = this.validateForm();
         const recaptchaValidation = this.validateRecaptcha();

         if (dataValidation && recaptchaValidation) {
            this.loadingForgotPassword = true;

            try {
               await authService.authForgot(data.email, this.recaptchaToken);

               if (this.$route.path !== "/autenticacao")
                  this.$router.push({ path: "/autenticacao" });

               snackBarUtil.showCustomSnackBar({
                  color: "success",
                  title: "EMAIL ENVIADO!",
                  message:
                     "Um email com as instruções de recuperação de senha foi enviado para você.",
                  icon: "mdi-email",
                  timeout: 4000,
               });
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingForgotPassword = false;
            }
         }
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      validateForm() {
         return this.$refs.form.validate();
      },

      recaptchaRender() {
         this.recaptchaRendered = true;
      },

      recaptchaVerify(value) {
         value ? (this.recaptchaValidation = false) : null;
         this.recaptchaToken = value;
      },

      recaptchaExpired() {
         this.recaptchaVerify(null);
      },

      validateRecaptcha() {
         this.recaptchaValidation = !this.recaptchaToken;
         return !this.recaptchaValidation;
      },
   },
};
</script>

<style scoped>
.card-border {
   border-radius: 8px !important;
}

.label-back-auth {
   cursor: pointer !important;
}

.router-link-back-auth {
   text-decoration: none;
}

::v-deep .label-position > div > div > div > label {
   top: 4px !important;
}

::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}
</style>