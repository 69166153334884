import axiosService from "./axiosService";

async function getAllHealthUnitWards(healthUnitId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/healthunits/${healthUnitId}/wards?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function createWard(healthUnitId, ward) {
   return await axiosService.post(`/healthunits/${healthUnitId}/wards`, ward);
}

async function updateWard(healthUnitId, wardId, ward) {
   return await axiosService.patch(
      `/healthunits/${healthUnitId}/wards/${wardId}`,
      ward
   );
}

async function activeWard(wardId, active) {
   return await axiosService.put(`/wards/${wardId}/active`, {
      active
   });
}

export default { getAllHealthUnitWards, createWard, updateWard, activeWard };
