<template>
   <div>
      <FormTitle icon="mdi-map-marker" title="ALAS" />

      <v-alert
         v-if="getMode() === 'read' && formWard.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Ala cadastrada em
         {{
            formatDate(formWard.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     @change="onChangeWardName"
                     v-model="formWard.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     :loading="loadingWardByName"
                     label="Nome*"
                     id="name-ward-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="caption mb-1 mt-6">Tipo de leito*</p>

            <v-chip-group
               v-model="formWard.type"
               @change="(value) => (invalidType = !value)"
               class="my-n2"
               column
            >
               <v-chip
                  :color="formWard.type === 'clinical' ? 'primary' : 'normal'"
                  value="clinical"
                  id="type-clinical-ward-form-input"
                  filter
                  small
               >
                  Leito clínico
               </v-chip>
               <v-chip
                  :color="formWard.type === 'surgical' ? 'primary' : 'normal'"
                  value="surgical"
                  id="type-surgical-ward-form-input"
                  filter
                  small
               >
                  Leito cirúrgico
               </v-chip>
               <v-chip
                  :color="formWard.type === 'uti' ? 'primary' : 'normal'"
                  value="uti"
                  id="type-uti-ward-form-input"
                  filter
                  small
               >
                  Leito de UTI
               </v-chip>
            </v-chip-group>

            <v-expand-transition>
               <p v-if="invalidType" class="caption error--text mt-1">
                  Tipo de leito obrigatório.
               </p>
            </v-expand-transition>
         </v-card>
      </v-form>

      <FormActions
         @onClickEdit="onClickEditWard"
         @onClickRemove="onClickRemoveWard(ward)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveWard"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveWard"
         class="mt-9"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import FormActions from "../../components/base/form/FormActions";
import formatUtil from "../../utils/formatUtil";
import wardService from "../../services/wardService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "WardForm",

   components: { FormTitle, FormActions },

   props: {
      ward: {
         type: Object,
      },

      healthUnit: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formWard: {},

         loadingSaveWard: false,
         loadingWardByName: false,

         invalidType: false,
      };
   },

   watch: {
      ward: {
         immediate: true,
         handler(ward) {
            if (ward.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.formWard = this.formatObjectToView(ward);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,

      formatObjectToView(ward) {
         let wardTemp = util.mergeObject({}, ward);

         return wardTemp;
      },

      formatObjectToSend(ward) {
         let wardTemp = util.removeBlankAttributes(ward);

         return wardTemp;
      },

      onClickEditWard() {
         this.setMode("save");
      },

      onClickRemoveWard(ward) {
         this.$emit("onClickRemoveWard", ward);
      },

      onClickCancel() {
         this.setMode("read");
         this.formWard = this.formatObjectToView(this.ward);
         this.$emit("onClickCancel");
      },

      onClickSaveWard() {
         if (this.formWard.id)
            this.updateWard(
               this.healthUnit.id,
               this.formWard.id,
               this.formWard
            );
         else this.createWard(this.healthUnit.id, this.formWard);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.invalidType = !this.formWard.type;
            return this.$refs.form.validate() && !this.invalidType;
         } else return false;
      },

      resetValidation() {
         this.invalidType = false;
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onChangeWardName() {
         this.getWardByName(this.healthUnit.id, this.formWard.name);
      },

      async getWardByName(healthUnitId, name) {
         this.loadingWardByName = true;

         try {
            const response = await wardService.getAllHealthUnitWards(
               healthUnitId,
               {
                  page: 1,
                  limit: 1,
                  filter: [
                     {
                        attribute: "active",
                        query: false,
                     },
                     {
                        attribute: "name",
                        query: name,
                     },
                  ],
               }
            );

            if (
               response.data &&
               response.data.length &&
               response.data[0].name.toUpperCase() ===
                  this.formWard.name.toUpperCase()
            )
               this.$emit("onFindDisabledWard", response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingWardByName = false;
         }
      },

      async createWard(healthUnitId, ward) {
         if (!this.executeValidation() || !healthUnitId || !ward) return;

         this.loadingSaveWard = true;

         try {
            await wardService.createWard(
               healthUnitId,
               this.formatObjectToSend(ward)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A ala foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreatedWard");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveWard = false;
         }
      },

      async updateWard(healthUnitId, wardId, ward) {
         if (!this.executeValidation() || !healthUnitId || !wardId || !ward)
            return;

         this.loadingSaveWard = true;

         try {
            await wardService.updateWard(
               healthUnitId,
               wardId,
               this.formatObjectToSend(ward)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A ala foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedWard");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveWard = false;
         }
      },
   },
};
</script>

<style scoped>
</style>