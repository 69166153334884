<template>
   <div>
      <SurgerySkeleton
         v-if="loadingSurgeriesFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-card v-else class="standard-padding">
         <SurgeryListHeader
            @onChangeSort="onChangeSort"
            @onChangeFilter="onChangeFilter"
            @onClearFilter="onClearFilter"
            @onSearchItems="onSearchItems"
            @onClickAdd="onClickAddSurgerie"
            @onClickPrint="onClickPrintSurgeries"
            @onClickBatchSchedule="onClickBatchSchedule"
            @onClickCancelBatchSchedule="onClickCancelBatchSchedule"
            @onClickSaveBatchSchedule="onClickSaveBatchSchedule"
            :sort="sort"
            :sorts="sorts"
            :filter="filter"
            :filters="filters"
            :loading="loadingSurgeriesPartially"
            :loadingPrint="loadingPrintSurgeries"
            :healthUnit="healthUnit"
            :batchScheduleSelectedMode="selectedMode"
            :selectionEmpty="!selectedSurgeries.length"
            filterActor="cirurgia"
            id="header"
            hideAddButton
         />

         <v-divider class="my-6"></v-divider>

         <div class="my-6">
            <LoadingData v-if="loadingSurgeriesPartially" />

            <div v-else-if="surgeries.length" class="ma-n3">
               <p
                  v-if="selectedMode"
                  class="body-2 d-flex justify-end flex-wrap px-5"
               >
                  {{ selectedSurgeries.length }} cirurgia{{
                     selectedSurgeries.length > 1 ? "s" : ""
                  }}
                  selecionada{{ selectedSurgeries.length > 1 ? "s" : "" }}
               </p>

               <SurgeryListItem
                  v-for="surgerie in surgeries"
                  @onClickViewSurgery="onClickViewSurgerie"
                  @onClickEditSurgery="onClickEditSurgerie"
                  @onSelectSurgery="onSelectSurgery"
                  @onDeselectSurgery="onDeselectSurgery"
                  :key="surgerie.id"
                  :surgery="surgerie"
                  :hideEditButton="
                     ['done', 'canceled'].includes(surgerie.status) ||
                        selectedMode
                  "
                  :selectable="selectedMode && surgerie.status === 'pending'"
                  :selectableValue="showSurgerySelected(surgerie)"
                  class="ma-3"
               />
            </div>

            <NoData v-else />
         </div>

         <ListFooter
            @onChangePage="onChangePage"
            @onChangeLimit="onChangeLimit"
            :page="page"
            :limit="limit"
            :totalItems="totalSurgeries"
            :loading="loadingSurgeriesPartially"
         />
      </v-card>
   </div>
</template>

<script>
import SurgerySkeleton from "components/surgery/list/SurgerySkeleton";
import ListFooter from "components/base/list/ListFooter";
import SurgeryListHeader from "components/surgery/list/SurgeryListHeader/SurgeryListHeader";
import NoData from "components/base/NoData";
import LoadingData from "components/base/LoadingData";
import SurgeryListItem from "components/surgery/list/SurgeryListItem";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import formatUtil from "utils/formatUtil";
import surgerieService from "services/surgerieService";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import surgeryPrint from "export/pdf/surgeryPdfExport";
import basePdfExport from "export/pdf/basePdfExport";

export default {
   name: "SurgeryList",

   components: {
      SurgerySkeleton,
      ListFooter,
      SurgeryListHeader,
      NoData,
      LoadingData,
      SurgeryListItem
   },

   props: {
      healthUnit: {
         type: Object
      }
   },

   data: function() {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name"
            },
            {
               text: "CPF",
               class: "primary--text body-2 font-weight-medium",
               value: "cpf"
            },
            {
               text: "Email",
               class: "primary--text body-2 font-weight-medium",
               value: "email"
            },
            {
               text: "Nascimento",
               class: "primary--text body-2 font-weight-medium",
               value: "birth_date"
            },
            {
               text: "Último acesso",
               class: "primary--text body-2 font-weight-medium",
               value: "last_login"
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center"
            }
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Últimas cadastradas"
            },
            {
               sort: "created_at",
               text: "Primeiras cadastradas"
            }
         ],

         filters: [
            {
               filter: "patient_id",
               text: "Nome do paciente"
            },
            {
               filter: "municipality_id&sus_code",
               text: "Município X Cirurgia"
            },
            {
               filter: "sus_code",
               text: "Código SUS",
               mask: "##.##.##.###-##",
               maxLength: 14
            },
            {
               filter: "scheduling_date",
               text: "Data de agendamento"
            },
            {
               filter: "status",
               text: "Status da cirurgia"
            }
         ],

         surgeries: [],

         totalSurgeries: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "patient_id",
         filterBy: "",

         loadingSurgeriesFully: true,
         loadingSurgeriesPartially: true,

         loadingPrintSurgeries: false,

         selectedMode: false,
         selectedSurgeries: [],

         blockClick: false
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: []
         };

         if (
            ["status"].includes(this.filter) &&
            this.filterBy &&
            this.filterBy.length
         ) {
            this.filterBy.forEach(item =>
               query.filter.push({
                  attribute: item.text,
                  query: item.value
               })
            );
         } else if (
            ["municipality_id&sus_code"].includes(this.filter) &&
            this.filterBy &&
            this.filterBy.length
         ) {
            this.filterBy.forEach(item =>
               query.filter.push({
                  attribute: item.text,
                  query: item.value
               })
            );
         } else if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy
            });

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      }
   },

   watch: {
      healthUnit: {
         immediate: true,
         async handler(healthUnit) {
            if (healthUnit && healthUnit.id) {
               this.loadingSurgeriesFully = true;
               await this.getAllHealthUnitSurgeries(healthUnit.id, this.query);
               this.loadingSurgeriesFully = false;
            }
         }
      }
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
      },

      onClickAddSurgerie(surgerie) {
         this.$emit("onClickAddSurgerie", surgerie);
      },

      onClickViewSurgerie(surgerie) {
         this.$emit("onClickViewSurgerie", surgerie);
      },

      onClickEditSurgerie(surgerie) {
         this.$emit("onClickEditSurgerie", surgerie);
      },

      removeSurgerie(surgerie) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveSurgerie", surgerie);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllSurgeriesByRef() {
         this.loadingSurgeriesFully = true;
         await this.getAllHealthUnitSurgeries(this.healthUnit.id, this.query);
         this.loadingSurgeriesFully = false;
      },

      async getAllHealthUnitSurgeries(healthUnitId, query) {
         if (!healthUnitId) return;

         this.loadingSurgeriesPartially = true;

         try {
            const response = await surgerieService.getAllHealthUnitSurgeries(
               healthUnitId,
               query
            );

            this.totalSurgeries = parseInt(response.headers["x-total-count"]);
            this.surgeries = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeriesPartially = false;
         }
      },

      async getUserById(user) {
         let response;

         switch (user.sub_type) {
            case "admin":
               response = await adminService.getAdminById(user.sub);
               break;
            case "manager":
               response = await managerService.getManagerById(user.sub);
               break;
            case "healthunit_manager":
               response = await healthUnitManagerService.getHealthUnitManagerById(
                  user.sub
               );
               break;
            case "secretary_manager":
               response = await healthSecretaryManagerService.getHealthSecretaryManagerById(
                  user.sub
               );
               break;
            case "solicitator":
            case "executor":
               response = await operatorService.getOperatorById(user.sub);
               break;
            case "regulator_manager":
               response = await regulatorManagerService.getRegulatorManagerById(
                  user.sub
               );
               break;
            case "regulator":
               response = await regulatorService.getRegulatorById(user.sub);
               break;
         }

         return response;
      },

      async onClickPrintSurgeries(date) {
         this.loadingPrintSurgeries = true;

         try {
            // 1. Busca o usuário completo que irá realizar a impressão.
            const userResponse = await this.getUserById(this.userStore);

            const pdf = surgeryPrint.generateScheduledSurgeriesPDF(
               date,
               this.surgeries,
               userResponse.data.name,
               this.operatorCurrentHealthUnitStore
            );

            basePdfExport.openPDFNewWindow(pdf, "Mapa de cirurgias");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrintSurgeries = false;
         }
      },

      onClickBatchSchedule() {
         this.selectedMode = true;
      },

      onClickCancelBatchSchedule() {
         this.selectedSurgeries = [];
         this.selectedMode = false;
      },

      onClickSaveBatchSchedule() {
         if (this.selectedSurgeries.length)
            this.$emit("onClickSaveBatchSchedule", this.selectedSurgeries);
      },

      onSelectSurgery(surgery) {
         const surgeryIndex = this.selectedSurgeries.findIndex(
            surgeryTemp => surgery.id === surgeryTemp.id
         );

         if (surgeryIndex === -1) {
            this.selectedSurgeries.push(surgery);
         }
      },

      onDeselectSurgery(surgery) {
         const surgeryIndex = this.selectedSurgeries.findIndex(
            surgeryTemp => surgery.id === surgeryTemp.id
         );

         if (surgeryIndex !== -1) {
            this.selectedSurgeries.splice(surgeryIndex, 1);
         }
      },

      showSurgerySelected(surgery) {
         const surgeryIndex = this.selectedSurgeries.findIndex(
            surgeryTemp => surgery.id === surgeryTemp.id
         );

         return surgeryIndex !== -1;
      }
   }
};
</script>

<style scoped></style>
