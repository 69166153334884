<template>
   <div>
      <v-menu transition="slide-y-transition" offset-y>
         <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" id="logout-button" icon dark>
               <v-badge
                  :value="unreadNotifications"
                  :content="unreadNotifications"
                  color="warning"
                  overlap
               >
                  <v-icon>mdi-bell</v-icon>
               </v-badge>
            </v-btn>
         </template>

         <v-list class="list-notification-center" three-line>
            <div
               class="d-flex justify-space-between align-center px-4 mt-2 mb-3"
            >
               <p class="body-1">
                  Central de notificações

                  <v-tooltip bottom>
                     <template v-slot:activator="{ on, attrs }">
                        <v-chip
                           v-bind="attrs"
                           v-on="on"
                           color="primary"
                           class="mr-1 pa-1"
                           x-small
                        >
                           Novo
                        </v-chip>
                     </template>

                     <p class="text-center">
                        Exibe notificações para <br />
                        novas solicitações e <br />
                        novas mensagens
                     </p>
                  </v-tooltip>
               </p>

               <a
                  v-if="notificationStore.length"
                  @click="clearAllNotifications"
                  class="caption href-style"
               >
                  Limpar todas
               </a>
            </div>

            <v-divider></v-divider>

            <NoData
               v-if="!notificationStore.length"
               message="Nenhuma notificação por aqui"
               icon="mdi-message-processing"
               class="pa-7"
            />
            <div v-else>
               <template v-for="(notification, index) in notificationStore">
                  <v-list-item :key="index">
                     <v-list-item-content>
                        <div class="d-flex justify-space-between align-center">
                           <div class="d-flex align-center ma-n1">
                              <v-icon class="ma-1" left small>
                                 {{ notificationContent(notification).icon }}
                              </v-icon>

                              <!-- <v-badge
                                 :value="notification.unread"
                                 color="warning"
                                 class="body-2"
                                 offset-y="7"
                                 dot
                              >
                                 {{ notificationContent(notification).title }}
                              </v-badge> -->

                              <p class="body-2 ma-1">
                                 {{ notificationContent(notification).title }}
                              </p>

                              <v-chip
                                 v-if="notification.unread"
                                 color="primary"
                                 class="ma-1 px-1"
                                 x-small
                              >
                                 Recente
                              </v-chip>
                           </div>

                           <!-- <p class="caption mt-1 grey--text">
                              {{ relativeDate(notification.date) }}
                           </p> -->

                           <!-- <div class="d-flex align-center ma-n1">
                              <a
                                 v-if="notification.unread"
                                 @click="markAsRead(index)"
                                 class="caption href-style ma-1"
                              >
                                 Lida
                              </a>

                              <a
                                 @click="notification.action()"
                                 class="caption href-style ma-1"
                              >
                                 Visualizar
                              </a>
                           </div> -->
                        </div>

                        <p
                           class="body-2 my-1 text--secondary"
                           v-html="
                              notificationContent(notification).description
                           "
                        ></p>

                        <div class="d-flex align-center justify-space-between">
                           <div class="d-flex align-center ma-n2">
                              <a
                                 @click="notification.action()"
                                 class="caption href-style ma-2"
                              >
                                 Visualizar
                              </a>

                              <a
                                 v-if="notification.unread"
                                 @click="markAsRead(index)"
                                 class="caption href-style ma-2"
                              >
                                 Marcar como lida
                              </a>
                           </div>

                           <p class="caption mt-1 grey--text">
                              {{ relativeDate(notification.date) }}
                           </p>
                        </div>
                        <!-- <p class="caption mt-1 grey--text">
                           {{ relativeDate(notification.date) }}
                        </p> -->
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </div>
         </v-list>
      </v-menu>
   </div>
</template>

<script>
import formatUtil from "utils/formatUtil";
import socketIoService from "services/socketIoService";
import NoData from "./NoData.vue";

export default {
   name: "NotificationCenter",

   components: { NoData },

   data: function () {
      return {
         socketIoConnection: null,
      };
   },

   watch: {
      "socketIoConnection.on": function () {
         this.socketIoConnection.on("new_request", (request) => {
            this.notificationStore.push({
               sourceEvent: "new_request",
               date: new Date(),
               data: request,
               unread: true,
               action: () => this.redirectToRequest(request),
            });
         });

         this.socketIoConnection.on("new_message", (request) => {
            this.addNewMessageNotification("new_message", request, () =>
               this.redirectToRequest(request)
            );
         });
      },
   },

   computed: {
      notificationStore() {
         return this.$store.getters["notificationCenter/getNotifications"];
      },

      unreadNotifications() {
         return this.$store.getters[
            "notificationCenter/getNotifications"
         ].filter((notification) => notification.unread).length;
      },
   },

   methods: {
      ...formatUtil,

      redirectToRequest(request) {
         const queryStringBase = `code=${request.code}&is_closed=${request.is_closed}&created_at=${request.created_at}`;

         let route;
         switch (request.type) {
            case "surgery":
               route = `/solicitacoes-cirurgia?${queryStringBase}&is_available=${request.is_available}`;

               // Certifica de limpar os query params.
               this.$router.push(
                  {
                     path: "/solicitacoes-cirurgia",
                  },
                  () => {}
               );

               // Navega até a rota de solicitações passando os query params.
               this.$router.push({
                  path: route,
               });
               break;

            case "adult":
               route = `/solicitacoes?${queryStringBase}`;

               // Certifica de limpar os query params.
               this.$router.push(
                  {
                     path: "/solicitacoes",
                  },
                  () => {}
               );

               // Navega até a rota de solicitações passando os query params.
               this.$router.push({
                  path: route,
               });
               break;
         }
      },

      markAsRead(notificationIndex) {
         this.notificationStore[notificationIndex].unread = false;
      },

      addNewMessageNotification(sourceEvent, data, action) {
         // Checa se já tem alguma notificação de mensagens relacionada a essa solicitação
         const notificationIndex = this.notificationStore.findIndex(
            (notification) =>
               notification.sourceEvent === "new_message" &&
               notification.data &&
               notification.data.id &&
               notification.data.id === data.id
         );

         // Se já tem, marca como não lida e atualiza a data da existente
         if (notificationIndex !== -1) {
            this.notificationStore[notificationIndex].unread = true;
            this.notificationStore[notificationIndex].date = new Date();
         } else {
            this.notificationStore.push({
               sourceEvent: sourceEvent,
               date: new Date(),
               data: data,
               unread: true,
               action,
            });
         }
      },

      notificationContent(notification) {
         let result = {
            title: "",
            description: "",
            icon: "",
         };

         switch (notification.sourceEvent) {
            case "new_request":
               var requestType;

               if (notification.data.type === "surgery") {
                  requestType = "cirurgia";
                  result.icon = "mdi-box-cutter";
               } else {
                  requestType = "leito";
                  result.icon = "mdi-bed";
               }

               result.title = `SOLICITAÇÃO CADASTRADA`;
               result.description = `Uma solicitação de ${requestType} com registro <strong>${notification.data.code}</strong> para o paciente <strong>${notification.data.patient.name}</strong>, foi cadastrada.`;
               break;

            case "new_message":
               result.title = "NOVAS MENSAGENS";
               result.description = `A solicitação <strong>${notification.data.code}</strong> do paciente <strong>${notification.data.patient.name}</strong>, tem novas mensagens.`;
               result.icon = "mdi-chat";
               break;
         }

         return result;
      },

      clearAllNotifications() {
         this.$store.commit("notificationCenter/setNotifications", []);
      },
   },

   async created() {
      this.socketIoConnection = socketIoService.getConnection();
   },
};
</script>

<style scoped>
.list-notification-center {
   width: 400px;
   max-height: 400px;
   overflow-y: scroll;
}

@media (max-width: 599px) {
   .list-notification-center {
      width: 100%;
   }
}

.notification-hightlight-icon {
   font-size: 10px;
   margin-bottom: 3px;
}

.href-style {
   text-decoration: underline;
}
</style>