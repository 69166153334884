
<template>
   <svg
      version="1.1"
      id="target-hospital"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="config"
   >
      <path
         d="M-53.7,45.6v-11c0-0.4,0.3-0.7,0.7-0.7h2.9v-2.9h7.4v2.9h2.9c0.4,0,0.7,0.3,0.7,0.7v11h-5.9v-3.7h-2.9v3.7H-53.7
         M-48.5,32.3v4.4h1.5v-1.5h1.5v1.5h1.5v-4.4h-1.5v1.5H-47v-1.5H-48.5 M-52.2,44.1h2.9v-2.2h-2.9V44.1 M-52.2,40.4h2.9v-2.2h-2.9
         V40.4 M-43.4,44.1h2.9v-2.2h-2.9V44.1 M-43.4,40.4h2.9v-2.2h-2.9V40.4 M-47.8,40.4h2.9v-2.2h-2.9V40.4z"
      />
      <path
         d="M-39,26.7l-2.9-2.7v2h-9.5c-0.2-0.3-0.6-0.6-1-0.6c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.4,0,0.8-0.2,1-0.6h9.5v2
	L-39,26.7z"
      />
      <g>
         <path
            d="M16.3,13.2h-2.5v-2.5H7.4v2.5H4.9c-0.3,0-0.6,0.3-0.6,0.6v9.5h5v-3.2h2.5v3.2h5v-9.5C16.9,13.4,16.6,13.2,16.3,13.2z
         M8.1,22H5.6v-1.9h2.5V22z M8.1,18.8H5.6v-1.9h2.5V18.8z M11.9,18.8H9.3v-1.9h2.5V18.8z M12.5,15.7h-1.3v-1.3H10v1.3H8.7v-3.8H10
         v1.3h1.3v-1.3h1.3V15.7z M15.6,22h-2.5v-1.9h2.5V22z M15.6,18.8h-2.5v-1.9h2.5V18.8z"
         />
         <path
            d="M18.2,1.9c-0.8,0-0.8-0.8-1.9-0.8c-0.6,0-1.1,0.2-1.5,0.3V1.1c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v5.3H6.2v0
		C6,6.2,5.7,6,5.3,6C4.7,6,4.2,6.5,4.2,7.1s0.5,1.1,1.1,1.1c0.4,0,0.7-0.2,0.9-0.5v0H14h0.2h0.6V5c0,0,0.4-0.8,1.5-0.8
		c0.8,0,0.8,0.8,1.9,0.8s1.5-0.8,1.5-0.8V1.1C19.8,1.1,19.4,1.9,18.2,1.9z"
         />
      </g>
   </svg>
</template>

<script>
export default {
   name: "TargetHospital",
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>