<template>
   <div>
      <FormTitle
         v-if="!hideTitle"
         icon="mdi-medical-bag"
         title="SOLICITAÇÃO CIRÚRGICA"
      />

      <!-- <v-alert
         v-if="getMode() === 'read' && formSurgery.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cirúrgica cadastrada em
         {{ formatDate(formSurgery.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert> -->

      <!-- <v-expansion-panels v-model="patientDataPanel" flat>
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               Dados do paciente
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-n1">
               <ViewPatient :patient="formSurgery.patient" hideTitle />
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels> -->

      <v-form ref="form" :readonly="getMode() === 'read'">
         <div>
            <p class="caption text--secondary">Nome</p>
            <div class="d-flex align-center flex-wrap ma-n1">
               <p class="body-2 ma-1">{{ formSurgery.name }}</p>

               <v-chip class="ma-1" color="success" small>
                  {{ formSurgery.sus_code }}
               </v-chip>
            </div>
         </div>

         <div class="mt-1">
            <p class="caption text--secondary">Status da cirurgia</p>
            <div class="d-flex align-center flex-wrap ma-n1">
               <v-chip
                  class="ma-1"
                  :color="generateSurgeryStatus(formSurgery.status).color"
                  small
               >
                  {{ generateSurgeryStatus(formSurgery.status).text }}
                  {{
                     surgery.execution_date
                        ? `em ${formatDate(surgery.execution_date)}`
                        : surgery.scheduling_date
                        ? `para ${formatDate(surgery.scheduling_date)}`
                        : ""
                  }}
               </v-chip>
            </div>
         </div>

         <v-card :disabled="getMode() === 'read'" flat>
            <v-row class="mt-2">
               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formSurgery.doctor_name"
                     :rules="[
                        (value) => !!value || 'Nome do médico obrigatório.',
                     ]"
                     id="phone-surgery-form-input"
                     label="Nome do médico*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-menu offset-y>
                     <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                           v-model="formSurgery.scheduling_date"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) =>
                                 !!value || 'Data de agendamento obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           append-icon="mdi-calendar"
                           id="birth_date-surgery-form-input"
                           label="Data de agendamento*"
                        >
                           <template v-slot:append>
                              <v-icon v-bind="attrs" v-on="on"
                                 >mdi-calendar</v-icon
                              >
                           </template>
                        </v-text-field>
                     </template>

                     <v-date-picker
                        v-model="datePicker"
                        :allowed-dates="allowedDates"
                        locale="pt-br"
                        no-title
                     >
                     </v-date-picker>
                  </v-menu>
               </v-col>
            </v-row>

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-textarea
                     v-model="formSurgery.notes"
                     class="notes-height"
                     label="Observações"
                     rows="5"
                     no-resize
                  ></v-textarea>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <SurgeryFormActions
         @onClickEdit="onClickEditSurgery"
         @onClickRemove="onClickRemoveSurgery(surgery)"
         @onClickCancel="onClickCancel"
         @onClickDone="onClickDoneSurgery"
         @onClickCancelSurgery="onClickCancelSurgery(surgery)"
         @onClickOutcome="onClickOutcomeSurgery"
         @onClickSave="onClickSaveSurgery"
         :editButtonIcon="
            surgery.status === 'pending' ? 'mdi-calendar' : 'mdi-pencil'
         "
         :editButtonText="surgery.status === 'pending' ? 'Agendar' : 'Editar'"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideDoneButton="surgery.status !== 'scheduled'"
         :hideCancelSurgeryButton="surgery.status !== 'scheduled'"
         :hideRemoveButton="
            !surgeryRequest.id ||
            surgeryRequest.is_closed ||
            surgery.status !== 'pending'
         "
         :hideOutcomeButton="
            !surgeryRequest.id ||
            surgeryRequest.is_closed ||
            surgery.status !== 'done'
         "
         :loadingSave="loadingSaveSurgery"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle";
import SurgeryFormActions from "components/surgery/form/SurgeryFormActions";
// import ViewPatient from "components/surgeryRequest/form/subForm/ViewPatient.vue";
import formatUtil from "utils/formatUtil";
import validationUtil from "utils/validationUtil";
import surgerieService from "services/surgerieService";
import snackBarUtil from "utils/snackBarUtil";
import util from "utils/util";
import moment from "moment";
import requestSurgeryService from "services/requestSurgeryService";

export default {
   name: "SurgeryForm",

   components: { FormTitle, SurgeryFormActions /* ViewPatient */ },

   props: {
      healthUnit: {
         type: Object,
      },

      surgery: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formSurgery: {},
         surgeryRequest: {},

         loadingSaveSurgery: false,

         validationErrors: [],

         patientDataPanel: null,
         surgeryDataPanel: null,
      };
   },

   computed: {
      datePicker: {
         get() {
            if (
               validationUtil.dateValidation(
                  this.formSurgery.scheduling_date,
                  "DD/MM/YYYY"
               )
            )
               return formatUtil.formatDate(
                  this.formSurgery.scheduling_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.formSurgery.scheduling_date = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },
   },

   watch: {
      surgery: {
         immediate: true,
         async handler(surgery) {
            if (surgery.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            // this.surgeryRequest = {};
            this.formSurgery = this.formatObjectToView(surgery);

            // if (surgery.id) this.getAllSurgeryRequests(surgery);
            this.surgeryRequest = Object.assign({}, surgery.request);
            this.$emit("onSurgeryRequestLoad", this.surgeryRequest);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...util,
      ...formatUtil,
      ...validationUtil,

      allowedDates(val) {
         return moment().diff(val, "days") <= 0;
      },

      formatObjectToView(surgery) {
         let surgeryTemp = util.mergeObject({}, surgery);

         if (surgeryTemp.scheduling_date)
            surgeryTemp.scheduling_date = formatUtil.formatDate(
               surgeryTemp.scheduling_date
            );

         return surgeryTemp;
      },

      formatObjectToSend(surgery, removeEmptyStrings) {
         let surgeryTemp = util.removeBlankAttributes(surgery, {
            emptyStrings: removeEmptyStrings,
         });

         if (surgeryTemp.scheduling_date)
            surgeryTemp.scheduling_date = formatUtil.formatDate(
               surgeryTemp.scheduling_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return surgeryTemp;
      },

      onClickEditSurgery() {
         this.setMode("save");
      },

      onClickRemoveSurgery(surgery) {
         this.$emit("onClickRemoveSurgery", surgery);
      },

      onClickCancel() {
         this.setMode("read");
         this.formSurgery = this.formatObjectToView(this.surgery);
         this.$emit("onClickCancel");
      },

      onClickDoneSurgery() {
         this.$emit("onClickDoneSurgery");
      },

      onClickCancelSurgery(surgery) {
         this.$emit("onClickCancelSurgery", surgery);
      },

      onClickOutcomeSurgery() {
         this.$emit("onClickOutcomeSurgery");
      },

      onClickSaveSurgery() {
         if (this.healthUnit.id && this.formSurgery.id)
            this.updateSurgery(
               this.healthUnit.id,
               this.formSurgery.id,
               this.formSurgery
            );
      },

      setMode(mode) {
         this.mode = mode;

         if (mode === "read") {
            this.patientDataPanel = 0;
            this.surgeryDataPanel = 0;
         } else {
            this.patientDataPanel = null;
            this.surgeryDataPanel = 0;
         }
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async getAllSurgeryRequests(surgery) {
         const response = await requestSurgeryService.getAllSurgeryRequests({
            filter: [
               {
                  attribute: "surgery_id",
                  query: surgery.id,
               },
            ],
         });

         this.surgeryRequest = Object.assign({}, response.data[0]);
         this.$emit("onSurgeryRequestLoad", this.surgeryRequest);
      },

      async updateSurgery(healthUnitId, surgeryId, surgery) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !surgeryId ||
            !surgery
         )
            return;

         this.loadingSaveSurgery = true;

         try {
            await surgerieService.updateHealthUnitSurgerie(
               healthUnitId,
               surgeryId,
               this.formatObjectToSend(surgery, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação cirúrgica foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedSurgery");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveSurgery = false;
         }
      },
   },
};
</script>

<style scoped>
.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.notes-height {
   height: 100%;
}
</style>