<template>
   <div>
      <div
         v-if="attachments.length"
         class="d-flex align-center flex-wrap ma-n2"
      >
         <AttachmentItemDetails
            v-for="(attachment, index) in attachments"
            @onRemoveAttachment="onRemoveAttachment"
            @onViewAttachment="onViewAttachment"
            :key="index"
            :attachment="attachment"
            :hideRemoveButton="hideRemoveButton"
            class="ma-2"
         />

         <AttachmentItemNew
            v-if="!hideAddButton"
            @onSelectAttachment="onSelectAttachment"
            @onSelectInvalidFormat="onSelectInvalidFormat"
            @onSelectInvalidSize="onSelectInvalidSize"
            :disableAddButton="disableAddButton"
            :allowedTypes="allowedTypes"
            :maximumSize="maximumSize"
            class="ma-2"
         />
      </div>

      <div v-else class="d-flex align-center ma-n2">
         <AttachmentItemEmpty v-if="hideAddButton" class="ma-2" />

         <AttachmentItemNew
            v-else
            @onSelectAttachment="onSelectAttachment"
            @onSelectInvalidFormat="onSelectInvalidFormat"
            @onSelectInvalidSize="onSelectInvalidSize"
            :disableAddButton="disableAddButton"
            :allowedTypes="allowedTypes"
            :maximumSize="maximumSize"
            class="ma-2"
         />
      </div>
   </div>
</template>

<script>
import AttachmentItemEmpty from "components/attachment/AttachmentItemEmpty";
import AttachmentItemDetails from "components/attachment/AttachmentItemDetails";
import AttachmentItemNew from "components/attachment/AttachmentItemNew";

export default {
   name: "Attachments",

   components: {
      AttachmentItemDetails,
      AttachmentItemNew,
      AttachmentItemEmpty,
   },

   props: {
      attachments: {
         typeof: Array,
         default: [],
      },

      hideRemoveButton: {
         typeof: Boolean,
         default: false,
      },

      hideAddButton: {
         typeof: Boolean,
         default: false,
      },

      disableAddButton: {
         typeof: Boolean,
         default: false,
      },

      allowedTypes: {
         typeof: Array,
         default: () => [],
      },

      maximumSize: {
         typeof: Number,
         default: 1048576,
      },
   },

   methods: {
      onSelectAttachment(attachment) {
         this.attachments.push(attachment);
         this.$emit("onSelectAttachment", attachment);
      },

      onSelectInvalidFormat(attachmentType) {
         this.$emit("onSelectInvalidFormat", attachmentType);
      },

      onSelectInvalidSize(attachmentType) {
         this.$emit("onSelectInvalidSize", attachmentType);
      },

      onRemoveAttachment(attachment) {
         if (attachment && attachment.raw && attachment.id) {
            const index = this.attachments.findIndex(
               (item) => item.id === attachment.id
            );
            if (index !== -1) this.attachments.splice(index, 1);
         }

         this.$emit("onRemoveAttachment", attachment);
      },

      onViewAttachment(attachment) {
         this.$emit("onViewAttachment", attachment);
      },
   },
};
</script>

<style scoped>
</style>