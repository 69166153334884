<template>
   <div>
      <ListSkeleton
         v-if="loadingRequestsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
         :hideAddButton="hideAddButton"
      />

      <v-data-table
         v-else
         @click:row="admin => (!blockClick ? onClickViewRequest(admin) : null)"
         :headers="headers"
         :items="requests"
         :loading="loadingRequestsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <SurgeryRequestListHeader
               @onChangeSort="onChangeSort"
               @onChangeDate="onChangeDate"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddRequest"
               :sort="sort"
               :sorts="sorts"
               :date="date"
               :filter="filter"
               :filters="filters"
               :loading="loadingRequestsPartially"
               :hideAddButton="hideAddButton"
               :allowedStatusInStatusFilter="allowedStatusInStatusFilter"
               filterActor="solicitação"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalRequests"
               :loading="loadingRequestsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhuma solicitação foi encontrada."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando solicitações, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.code`]="{ item }">
            <div class="d-flex align-center">
               <p :class="`code-regulator-request-operator-list-data`">
                  {{ item.code }}
               </p>

               <v-tooltip v-if="item.was_created_by_patient" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        size="17"
                        right
                     >
                        mdi-cellphone-text
                     </v-icon>
                  </template>

                  <p class="text-center">
                     Cadastrado pelo <br />
                     OperaPB
                  </p>
               </v-tooltip>
            </div>
         </template>

         <template v-slot:[`item.created_at`]="{ item }">
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <p
                     :class="
                        `request_created_at-${userInfo}-request-operator-list-data`
                     "
                     v-bind="attrs"
                     v-on="on"
                  >
                     {{ formatDateTime(item.created_at, "DD/MM [às] HH:mm") }}
                  </p>
               </template>

               <p class="text-center">
                  {{ formatDateTime(item.created_at) }} <br />
                  {{ relativeDate(item.created_at) }}
               </p>
            </v-tooltip>
         </template>

         <template v-slot:[`item.municipality.name`]="{ item }">
            <p
               :class="`municipality-name-regulator-request-operator-list-data`"
            >
               {{ item.municipality.name }}
            </p>
         </template>

         <template v-slot:[`item.patient.name`]="{ item }">
            <p class="name-regulator-request-operator-list-data">
               {{ item.patient.name }}
            </p>
         </template>

         <template v-slot:[`item.surgery.name`]="{ item }">
            <p v-if="item.surgery && item.surgery.name">
               {{ item.surgery.name }}
            </p>

            <p v-else>Sem cirurgia</p>
         </template>

         <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex align-center">
               <p
                  :class="
                     `${
                        generateState(item.status).color
                     }--text status-regulator-request-operator-list-data`
                  "
                  class="font-weight-medium"
               >
                  {{ generateState(item.status).text }}
               </p>
            </div>
         </template>

         <template v-slot:[`item.alerts`]="{ item }">
            <div class="mx-n1">
               <v-tooltip v-if="item.is_checked" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        color="success"
                        small
                        >mdi-check-circle</v-icon
                     >
                  </template>

                  <p>Validada</p>
               </v-tooltip>

               <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        color="#bdbdbd"
                        small
                        >mdi-help-circle</v-icon
                     >
                  </template>

                  <p>Não validada</p>
               </v-tooltip>
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../../components/base/list/ListFooter";
import SurgeryRequestListHeader from "components/surgeryRequest/list/SurgeryRequestListHeader";
import NoData from "../../../components/base/NoData";
import LoadingData from "../../../components/base/LoadingData";
import responsiveUtil from "../../../utils/responsiveUtil";
import snackBarUtil from "../../../utils/snackBarUtil";
import formatUtil from "../../../utils/formatUtil";
import util from "../../../utils/util";
import requestSurgeryService from "services/requestSurgeryService";
import moment from "moment";

export default {
   name: "SurgeryRequestAdminList",

   components: {
      ListSkeleton,
      ListFooter,
      SurgeryRequestListHeader,
      NoData,
      LoadingData
   },

   props: {
      hideAddButton: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      allowedStatusInStatusFilter: {
         type: Array,
         default: () => [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected",
            "rejected_by_executor",
            "canceled"
         ]
      },

      initialQuery: {
         type: Array,
         default: () => []
      },

      userInfo: {
         type: String,
         default: null
      }
   },

   data: function() {
      return {
         headers: [
            {
               text: "Registro",
               class: "primary--text body-2 font-weight-medium",
               value: "code",
               width: "12%"
            },
            {
               text: "Cadastrado em",
               class: "primary--text body-2 font-weight-medium",
               value: "created_at",
               width: "131px"
            },
            {
               text: "Paciente",
               class: "primary--text body-2 font-weight-medium",
               value: "patient.name"
            },
            {
               text: "Cidade",
               class: "primary--text body-2 font-weight-medium",
               value: "municipality.name",
               width: "15%"
            },
            {
               text: "Cirurgia",
               class: "primary--text body-2 font-weight-medium",
               value: "surgery.name"
            },
            {
               text: "Estado",
               class: "primary--text body-2 font-weight-medium",
               value: "status"
            },
            {
               text: "Alertas",
               class: "primary--text body-2 font-weight-medium",
               value: "alerts",
               sortable: false,
               align: "center",
               width: "5%"
            }
         ],

         sorts: [
            {
               sort: "status",
               text: "Estado"
            },
            {
               sort: "-created_at",
               text: "Últimas cadastradas"
            },
            {
               sort: "created_at",
               text: "Primeiras cadastradas"
            },
            {
               sort: "-updated_at",
               text: "Últimas atualizadas"
            }
         ],

         filters: [
            {
               filter: "code",
               text: "Registro",
               mask: "#################",
               unmask: /[^0-9]/g,
               maxLength: 17
            },
            {
               filter: "patient_id",
               text: "Nome do paciente"
            },
            {
               filter: "municipality_id&doctor_opinion.sus_code",
               text: "Município x Cirurgia"
            },
            {
               filter: "sourcehealthentity_id&doctor_opinion.sus_code",
               text: "Unidade de origem x Cirurgia"
            },
            {
               filter: "status&is_checked",
               text: "Estado"
            },
            {
               filter: "was_created_by_patient",
               text: "Cadastrado pelo OperaPB"
            }
         ],

         requests: [],

         totalRequests: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "code",
         filterBy: "",
         date: [formatUtil.formatDate(moment(), "YYYY-MM")],

         loadingRequestsFully: true,
         loadingRequestsPartially: true,

         blockClick: false
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "created_at",
                  query: `gte:${this.getRangeDateFromDate.gte}`
               },
               {
                  attribute: "created_at",
                  query: `lte:${this.getRangeDateFromDate.lte}`
               }
            ]
         };

         if (this.initialQuery && this.initialQuery.length)
            this.initialQuery.forEach(item =>
               query.filter.push({
                  attribute: item.attribute,
                  query: item.query
               })
            );

         /* Caso do filtro múltiplo, de cirurgias por municípios, cirurgias por unidades de saúde e solicitação validada por estado. */
         if (
            [
               "municipality_id&doctor_opinion.sus_code",
               "sourcehealthentity_id&doctor_opinion.sus_code",
               "status&is_checked"
            ].includes(this.filter) &&
            this.filterBy &&
            this.filterBy.length
         ) {
            this.filterBy.forEach(item =>
               query.filter.push({
                  attribute: item.text,
                  query: item.value
               })
            );
         } else if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         ) {
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy
            });
         }

         return query;
      },

      getRangeDateFromDate() {
         const getGte = date =>
            formatUtil.formatDate(
               moment(date).startOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );
         const getLte = date =>
            formatUtil.formatDate(
               moment(date).endOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );

         return {
            gte: getGte(this.date[0]),
            lte: getLte(this.date[this.date.length - 1])
         };
      }
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,
      ...util,

      onChangePage(page) {
         this.page = page;
         this.getAllRequests(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onChangeDate(date) {
         this.date = date;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      async onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         await this.getAllRequests(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onClickAddRequest(request) {
         this.$emit("onClickAddRequest", request);
      },

      onClickViewRequest(request) {
         this.$emit("onClickViewRequest", request);
      },

      translatePriority(priority) {
         let translatedPriority = "Vermelha";
         let color = "error";

         switch (priority) {
            case "blue":
               translatedPriority = "Azul";
               color = "info";
               break;
            case "green":
               translatedPriority = "Verde";
               color = "success";
               break;
            case "yellow":
               translatedPriority = "Amarela";
               color = "amber";
               break;
            case "orange":
               translatedPriority = "Laranja";
               color = "warning";
               break;
         }

         return { translatedPriority, color };
      },

      async refreshAllRequestsByRef() {
         this.loadingRequestsFully = true;
         await this.getAllRequests(this.query);
         this.loadingRequestsFully = false;
      },

      async getAllRequests(query) {
         this.loadingRequestsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await requestSurgeryService.getAllSurgeryRequests(
               query
            );

            this.totalRequests = parseInt(response.headers["x-total-count"]);

            /* Alterar status 'visualmente' da solicitação */
            const requestsModifiedStatus = response.data.map(item => {
               let result = item;

               if (item.status === "rejected" && !item.is_closed)
                  item.status = "rejected_by_executor";

               return result;
            });

            this.requests = Object.assign([], requestsModifiedStatus);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRequestsPartially = false;
         }
      }
   },

   async created() {
      this.loadingRequestsFully = true;
      await this.getAllRequests(this.query);
      this.loadingRequestsFully = false;
   }
};
</script>

<style scoped>
.unread-messages-text {
   color: white;
   font-weight: 400;
   font-size: 0.7rem;
}

.pulsate-fwd {
   -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
   animation: pulsate-fwd 2s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
   0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }

   50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
   }

   100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }
}

@keyframes pulsate-fwd {
   0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }

   50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
   }

   100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }
}

.blink-2 {
   -webkit-animation: blink-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      infinite both;
   animation: blink-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite both;
}

@-webkit-keyframes blink-2 {
   0% {
      opacity: 1;
   }

   50% {
      opacity: 0.2;
   }

   100% {
      opacity: 1;
   }
}

@keyframes blink-2 {
   0% {
      opacity: 1;
   }

   50% {
      opacity: 0.2;
   }

   100% {
      opacity: 1;
   }
}
</style>
