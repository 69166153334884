<template>
   <div>
      <div v-if="loadingWards">
         <BedListWardSkeleton v-for="item in 3" :key="item" class="mb-6" />
      </div>
      <div v-else-if="healthunitWards.length">
         <BedListWardCard
            v-for="(ward, index) in healthunitWards"
            @onClickViewBed="onClickViewBed"
            @onClickAddBed="onClickAddBed"
            @onGetAllBedsWard="onGetAllBedsWard"
            :key="index"
            :ward="ward"
            :showAddButton="showAddButton"
            class="mb-6"
         />
      </div>
      <div v-else>
         <v-card class="standard-padding-small">
            <NoData message="Desculpe, nenhuma ala foi encontrada." />
         </v-card>
      </div>
   </div>
</template>

<script>
import BedListWardCard from "./BedListWardCard";
import BedListWardSkeleton from "./BedListWardSkeleton";
import NoData from "../../base/NoData";
import wardService from "../../../services/wardService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";

export default {
   name: "BedList",

   components: { BedListWardCard, BedListWardSkeleton, NoData },

   props: {
      healthUnit: {
         type: Object,
      },

      showAddButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingWards: true,

         healthunitWards: [],

         allWardBeds: [],
      };
   },

   watch: {
      healthUnit: {
         immediate: true,
         handler(healthUnit) {
            if (healthUnit && healthUnit.id)
               this.getAllHealthUnitWards(healthUnit.id);
         },
      },
   },

   methods: {
      //FIXME01:
      /* Isso aqui no futuro vai ser removido, já que o próprio component HealthUnitStatistic
      terá uma rota específica para ele. */
      onGetAllBedsWard(wardBeds) {
         this.allWardBeds = this.allWardBeds.concat(wardBeds);
         this.$emit(
            "onGenerateBedStatistics",
            util.generateBedStatistics(this.allWardBeds)
         );
      },

      refreshAllHealthUnitWardsByRef() {
         if (this.healthUnit && this.healthUnit.id)
            this.getAllHealthUnitWards(this.healthUnit.id);
      },

      async getAllHealthUnitWards(healthUnitId) {
         this.allWardBeds = [];
         this.loadingWards = true;

         try {
            const response = await wardService.getAllHealthUnitWards(
               healthUnitId,
               {
                  page: 1,
                  limit: 100,
                  filter: [
                     {
                        attribute: "active",
                        query: true,
                     },
                  ],
               }
            );

            this.healthunitWards = Object.assign([], response.data);

            if (!this.healthunitWards.length) this.onGetAllBedsWard([]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingWards = false;
         }
      },

      onClickAddBed(bed) {
         this.$emit("onClickAddBed", bed);
      },

      onClickViewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },
   },
};
</script>

<style scoped>
</style>