<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="cerhRecommendations.flu_syndrome"
            label="Sindrome Gripal"
            id="flu_syndrome-cerh-recomendations-form-input"
         ></v-checkbox>
      </div>

      <v-row class="mt-2 mb-0">
         <v-col cols="12">
            <v-text-field
               v-model="cerhRecommendations.inconclusive_criteria"
               label="Criterios Inconclusivos"
               id="inconclusive_criteria-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "CerhRecommendationsForm",

   props: {
      cerhRecommendations: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>