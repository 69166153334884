<template>
   <div>
      <FormTitle icon="mdi-download" title="EXPORTAR RELATÓRIO" />

      <div class="mt-6">
         <v-row class="ma-n2">
            <v-col
               v-for="(item, i) in profiles"
               :key="i"
               :id="`profile-item-${i}`"
               class="pa-2"
               cols="12"
               sm="6"
               md="4"
            >
               <v-card
                  class="d-flex flex-column justify-space-between align-center px-5 pt-6 pb-7 border-config"
                  height="100%"
                  outlined
               >
                  <v-icon color="primary" large>{{ item.icon }}</v-icon>

                  <p class="body-2 text-center mt-3 mb-4">{{ item.text }}</p>

                  <div
                     class="d-flex justify-center align-center flex-wrap ma-n1"
                  >
                     <v-btn
                        v-for="(fileType, i) in filesType"
                        :key="i"
                        @click="clickItem(item, fileType)"
                        class="ma-1"
                        color="primary"
                        outlined
                        rounded
                        x-small
                        >{{ fileType.text }}</v-btn
                     >
                  </div>
               </v-card>
            </v-col>
         </v-row>
      </div>
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle.vue";
import moment from "moment";
import basePdfExport from "export/pdf/basePdfExport";
import baseCsvExport from "export/csv/baseCsvExport";
import baseXlsxExport from "export/xlsx/baseXlsxExport";
import statisticPdfExport from "export/pdf/statisticPdfExport";
import formatUtil from "utils/formatUtil";
import util from "utils/util";

export default {
   name: "SurgeryStatisticExport",

   components: { FormTitle },

   props: {
      data: {
         type: Object,
         default: () => ({}),
      },
   },

   data: function () {
      return {
         filesType: [
            {
               text: "EXCEL",
               value: "xlsx",
            },
            {
               text: "PDF",
               value: "pdf",
            },
            {
               text: "CSV",
               value: "csv",
            },
         ],

         profiles: [
            {
               text: "Regulações por mês",
               icon: "mdi-calendar-month",
               value: "date_profile",
            },
            {
               text: "Regulações por faixa etária",
               icon: "mdi-human-male-female-child",
               value: "age_profile",
            },
            {
               text: "Regulações por gênero",
               icon: "mdi-gender-male-female",
               value: "gender_profile",
            },
            {
               text: "Regulações por nível de atenção",
               icon: "mdi-alert",
               value: "attention_level_profile",
            },
            {
               text: "Regulações por municípios",
               icon: "mdi-map-marker-radius",
               value: "source_municipalities",
            },
            {
               text: "Regulações por cirurgia",
               icon: "mdi-medical-bag",
               value: "surgeries",
            },
            {
               text: "Lista de regulações",
               icon: "mdi-format-list-bulleted-square",
               value: "data_frame",
            },
            {
               text: "Lista de cirurgias em espera",
               icon: "mdi-playlist-remove",
               value: "waiting_surgery_list_click",
            },
         ],
      };
   },

   methods: {
      async clickItem(reportType, filesType) {
         let headers;
         let json;
         let pdfOptions;
         const fileName = `${reportType.text
            .split(" ")
            .join("_")}_${formatUtil.formatDate(
            new Date(),
            "DD[_]MM[_]YYYY[_]HH[_]mm[_]ss"
         )}`;

         /* Gera o conteúdo e o cabeçalho do arquivo.  */
         switch (reportType.value) {
            case "date_profile":
               headers = [
                  {
                     value: "start",
                     text: "Mês/Ano",
                     computed: (data) => {
                        let date = moment(data);
                        let monthName = date.format("MMMM");

                        return `${
                           monthName.charAt(0).toUpperCase() +
                           monthName.slice(1)
                        }/${date.format("YYYY")}`;
                     },
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value].data_set;
               break;

            case "age_profile":
               headers = [
                  {
                     value: "name",
                     text: "Faixa etária (anos)",
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value];
               break;

            case "gender_profile":
               headers = [
                  {
                     value: "name",
                     text: "Gênero",
                     computed: (data) =>
                        data === "male" ? "Masculino" : "Feminino",
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value];
               break;

            case "attention_level_profile":
               headers = [
                  {
                     value: "name",
                     text: "Nível de atenção",
                     computed: (data) => {
                        let translated = "Verde";

                        switch (data) {
                           case "green":
                              translated = "Amarelo";
                              break;
                           case "yellow":
                              translated = "Laranja";
                              break;
                           case "orange":
                              translated = "Vermelho";
                              break;
                           case "red":
                              translated = "Preto";
                              break;
                        }

                        return translated;
                     },
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value];
               break;

            case "source_municipalities":
               headers = [
                  {
                     value: "name",
                     text: "Município",
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value];
               break;

            case "surgeries":
               headers = [
                  {
                     value: "name",
                     text: "Cirurgia",
                  },
                  { value: "total", text: "Regulações" },
               ];
               json = this.data[reportType.value];
               break;

            case "data_frame":
               headers = [
                  {
                     value: "index",
                     text: "Índice",
                  },
                  {
                     value: "patient_contact_number",
                     text: "Contato",
                     computed: (data) => formatUtil.formatPhone(data),
                  },
                  {
                     value: "patient_cpf",
                     text: "CPF",
                     computed: (data) => formatUtil.formatCPF(data),
                  },
                  { value: "patient_name", text: "Nome" },
                  {
                     value: "registered_by_health_entity",
                     text: "Entidade de saúde de origem",
                  },
                  { value: "surgery_name", text: "Cirurgia" },
                  { value: "surgery_request_city", text: "Município" },
                  {
                     value: "surgery_request_status",
                     text: "Estado da solicitação",
                     computed: (data) => {
                        return util.generateState(data).text
                     },
                  },
                  {
                     value: "surgery_status",
                     text: "Estado da cirurgia",
                     computed: (data) => {
                        const message = data.split('-');
                        if (data) return (message[0] === 'rejected' ? 'Rejeitada' : util.generateSurgeryStatus(message[0]).text)  + (message[1] || '');
                     },
                  },
                  { value: "sus_code", text: "Código SUS da cirurgia" },
                  {
                     value: "surgery_responsibles",
                     text: "Responsáveis",
                     computed: (data) => {
                        if (data && data.length)
                           return data.map(
                              (item) => "Nome: " + item.name + " Tipo: " + util.translateSubType(item.type)).join(", ");
                        else return "-";
                     },
                  },
               ];

               var dataTemp = Object.assign([], this.data[reportType.value]);
               /* Adiciona o índice para cada solicitação. */
               dataTemp.forEach((item, index) => {
                  if (item.surgery_status === "canceled") {
                     item.surgery_status = (item.surgery_cancellation_reason === 'patient_absence') ? item.surgery_status + '- por ausência do paciente' : 'rejected- por cancelamento'
                  }

                  item.index = index + 1;
               });

               json = dataTemp;
               pdfOptions = "landscape";
               break;

            // Emite um evento que vai disparar uma busca pela lista de cirurgias em espera.
            case "waiting_surgery_list_click":
               return this.$emit("onClickWaitingSurgeryList", filesType);
         }

         /* Gera o arquivo dependendo do tipo escolhido.  */
         switch (filesType.value) {
            case "xlsx":
               var xlsx = baseCsvExport.generateCSV(json, headers);
               baseXlsxExport.saveXLSX(xlsx, fileName);
               break;

            case "csv":
               var csv = baseCsvExport.generateCSV(json, headers);
               baseCsvExport.saveCSV(csv, fileName);
               break;

            case "pdf":
               var pdf = statisticPdfExport.generateSurgeryStatisticPDF(
                  json,
                  headers,
                  reportType.text,
                  pdfOptions
               );

               basePdfExport.savePDF(pdf, fileName);
               break;
         }
      },
   },
};
</script>

<style scoped>
.border-config {
   border-radius: 8px !important;
}
</style>