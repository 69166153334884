<template>
   <div>
      <v-row>
         <v-col cols="12">
            <v-card
               class="standard-padding-x-small d-flex justify-space-between align-center"
               height="100%"
               flat
            >
               <div class="d-flex flex-column justify-center flex-grow-1">
                  <div class="d-flex align-center">
                     <v-icon left>mdi-map-marker</v-icon>

                     <v-skeleton-loader
                        v-if="loadingHealthUnit"
                        class="rounded-pill"
                        type="image"
                        width="40%"
                        height="18px"
                     ></v-skeleton-loader>
                     <p v-else>{{ healthUnit.address.city }}</p>
                  </div>

                  <div class="d-flex align-center mt-2">
                     <v-icon left>mdi-hospital-building</v-icon>

                     <v-skeleton-loader
                        v-if="loadingHealthUnit"
                        class="rounded-pill"
                        type="image"
                        width="85%"
                        height="18px"
                     ></v-skeleton-loader>
                     <p v-else>
                        {{ healthUnit.name }}
                     </p>
                  </div>
               </div>

               <v-btn
                  @click="changeHealthUnit"
                  class="d-none d-md-flex"
                  color="normal"
                  small
                  depressed
                  >Alterar unidade de saúde
               </v-btn>

               <v-btn
                  @click="changeHealthUnit"
                  class="d-flex d-md-none"
                  color="normal"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </v-card>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" sm="12" lg="4">
            <SurgeryStatisticCard
               :statistic="statistics.free"
               color="info"
               icon="mdi-medical-bag"
               label="Cirurgias livres"
            />
         </v-col>
         <v-col cols="12" sm="6" lg="4">
            <SurgeryStatisticCard
               :statistic="statistics.answered"
               color="success"
               icon="mdi-check"
               label="Cirurgias confirmadas"
            />
         </v-col>
         <v-col cols="12" sm="6" lg="4">
            <SurgeryStatisticCard
               :statistic="statistics.pending"
               color="warning"
               icon="mdi-clock-outline"
               label="Cirurgias pendentes"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import SurgeryStatisticCard from "./SurgeryStatisticCard";
import snackBarUtil from "utils/snackBarUtil";
import surgeryTypeService from "services/surgeryTypeService";
import surgerieService from "services/surgerieService";

export default {
   name: "SurgeryStatistic",

   components: { SurgeryStatisticCard },

   props: {
      healthUnit: {
         type: Object,
      },
   },

   data: function () {
      return {
         loadingHealthUnit: true,
         statistics: {
            free: null,
            answered: null,
            pending: null,
         },
      };
   },

   watch: {
      healthUnit: {
         immediate: true,
         handler(healthUnit) {
            if (healthUnit && healthUnit.id) {
               this.loadingHealthUnit = false;
               this.getHealthUnitStatistics(healthUnit.id);
            }
         },
      },
   },

   methods: {
      changeHealthUnit() {
         this.$emit("onClickChangeHealthUnit");
      },

      async getHealthUnitStatistics(healthUnitId) {
         if (!healthUnitId) return;

         try {
            let response =
               await surgeryTypeService.getAllHealthUnitSurgeryTypes(
                  healthUnitId,
                  {
                     page: 1,
                     limit: 100,
                  }
               );

            let total = 0;
            let answered = 0;

            response.data.forEach((item) => {
               total += item.total;
               if (item.answered) answered += item.answered;
            });

            this.statistics.free = total - answered;

            response = await surgerieService.getAllHealthUnitSurgeries(
               healthUnitId,
               {
                  page: 1,
                  limit: 100,
                  filter: [
                     {
                        attribute: "status",
                        query: "pending",
                     },
                  ],
               }
            );

            this.statistics.pending = parseInt(
               response.headers["x-total-count"]
            );

            response = await surgerieService.getAllHealthUnitSurgeries(
               healthUnitId,
               {
                  page: 1,
                  limit: 100,
                  filter: [
                     {
                        attribute: "status",
                        query: "scheduled",
                     },
                  ],
               }
            );

            this.statistics.answered = parseInt(
               response.headers["x-total-count"]
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      async refreshHealthUnitStatisticsByRef() {
         await this.getHealthUnitStatistics(this.healthUnit.id);
      },
   },
};
</script>

<style scoped>
</style>