<template>
   <div>
      <div class="mb-8 d-flex flex-column justify-center align-center">
         <v-icon class="mb-2" color="primary" x-large>mdi-information</v-icon>

         <p class="font-weight-bold primary--text text-center mb-2">
            OPERADOR JÁ EXISTENTE
         </p>

         <p class="body-2 text-center active-information">
            Um operador desativado com o CPF
            <strong>{{
               operator.cpf ? formatCPF(operator.cpf) : "não informado"
            }}</strong>
            foi encontrado, para ativá-lo escolha uma unidade de saúde
            <strong>OU</strong>
            secretaria que ele deve ser associado, em seguida clique em
            "Ativar".
         </p>
      </div>

      <v-form ref="form">
         <v-card flat>
            <div class="d-flex justify-center align-center mb-6">
               <v-btn-toggle
                  v-model="entitiesTab"
                  class="px-1 mx-auto flex-wrap"
                  dense
               >
                  <v-btn class="overline-button primary--text">
                     <v-icon class="mr-1" color="primary" small
                        >mdi-hospital-building</v-icon
                     >
                     UNIDADE DE SAÚDE
                  </v-btn>
                  <v-btn class="overline-button error--text">
                     <v-icon class="mr-1" color="error" small
                        >mdi-home-plus</v-icon
                     >
                     SECRETARIA DE SAÚDE
                  </v-btn>
               </v-btn-toggle>
            </div>

            <div v-if="entitiesTab === 0" class="mb-3">
               <HealthUnitInput
                  @onSelectHealthUnit="onSelectHealthUnit"
                  ref="operatorahealthunitinput"
               />
            </div>
            <div v-else-if="entitiesTab === 1" class="mb-3">
               <HealthSecretarieInput
                  @onSelectHealthSecretarie="onSelectHealthSecretary"
                  ref="operatorahealthsecretaryinput"
               />
            </div>

            <!-- <v-row>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.new_password"
                     @click:append="showNewPassword = !showNewPassword"
                     :rules="[
                        (value) => !!value || 'Nova senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Nova senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     :type="showNewPassword ? 'text' : 'password'"
                     :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                     id="new-password-form-input"
                     label="Nova senha*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.confirm_password"
                     @click:append="showConfirmPassword = !showConfirmPassword"
                     :rules="[
                        (value) =>
                           !!value || 'Confirmação da nova senha obrigatória.',
                        (value) =>
                           value === formPassword.new_password ||
                           'Confirmação da nova senha e a nova senha são diferentes.',
                     ]"
                     :type="showConfirmPassword ? 'text' : 'password'"
                     :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                     "
                     id="confirm-new-password-form-input"
                     label="Confirmação da nova senha*"
                  ></v-text-field>
               </v-col>
            </v-row> -->
         </v-card>
      </v-form>

      <div class="d-flex justify-end align-center mt-7">
         <v-btn
            @click="save"
            :loading="loadingActive"
            class="shadow-floating-primary-button d-none d-sm-flex ml-3"
            color="primary"
            id="save-primary-active-form-button"
            depressed
            >Ativar
            <v-icon right small>mdi-check</v-icon>
         </v-btn>
         <v-btn
            @click="save"
            :loading="loadingActive"
            class="shadow-floating-primary-button d-flex d-sm-none ml-3"
            color="primary"
            id="save-secondary-active-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
import HealthUnitInput from "../healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "../healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import operatorService from "services/operatorService";
import snackBarUtil from "utils/snackBarUtil";
import formatUtil from "utils/formatUtil";
import validationUtil from "utils/validationUtil";

export default {
   name: "OperatorAdminActiveForm",

   components: {
      HealthUnitInput,
      HealthSecretarieInput,
   },

   props: {
      operator: {
         type: Object,
      },
   },

   data: function () {
      return {
         healthUnitSelected: null,
         healthSecretarySelected: null,
         formPassword: {},

         showNewPassword: false,
         showConfirmPassword: false,

         loadingActive: false,

         entitiesTab: 0,
      };
   },

   watch: {
      operator: {
         immediate: true,
         handler() {
            this.formPassword = {};
            this.healthUnitSelected = null;
            this.healthSecretarySelected = null;

            if (this.$refs.operatorahealthunitinput)
               this.$refs.operatorahealthunitinput.reset();
            if (this.$refs.operatorahealthsecretaryinput)
               this.$refs.operatorahealthsecretaryinput.reset();
            this.resetValidation();
         },
      },

      entitiesTab: {
         immediate: true,
         handler(tab) {
            if (tab === 0) {
               if (this.$refs.operatorahealthunitinput)
                  this.$refs.operatorahealthunitinput.reset();
            } else if (tab === 1) {
               if (this.$refs.operatorahealthsecretaryinput)
                  this.$refs.operatorahealthsecretaryinput.reset();
            }
         },
      },
   },

   methods: {
      ...validationUtil,
      ...formatUtil,

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      onSelectHealthSecretary(healthSecretary) {
         this.healthSecretarySelected = Object.assign({}, healthSecretary);
      },

      async save() {
         if (!this.executeValidation() || !(this.operator && this.operator.id))
            return;

         if (
            this.entitiesTab === 0 &&
            !(this.healthUnitSelected && this.healthUnitSelected.id)
         )
            return;
         if (
            this.entitiesTab === 1 &&
            !(this.healthSecretarySelected && this.healthSecretarySelected.id)
         )
            return;

         this.loadingActive = true;

         try {
            if (this.entitiesTab === 0)
               await operatorService.associateOperatorToHealthUnitById(
                  this.operator.id,
                  this.healthUnitSelected.id
               );
            else if (this.entitiesTab === 1)
               await operatorService.associateOperatorToHealthSecretarieById(
                  this.operator.id,
                  this.healthSecretarySelected.id
               );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi ativado!",
            });

            this.$emit("onActivedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingActive = false;
         }
      },
   },
};
</script>

<style scoped>
.active-information {
   width: 70% !important;
}

.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}
</style>