<template>
   <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      class="elevation-2 standard-padding-data-list"
      mobile-breakpoint="960"
      hide-default-footer
      disable-sort
   >
      <template v-slot:top>
         <div class="d-flex justify-space-between align-end" id="header">
            <div>
               <div class="d-flex d-md-none">
                  <v-skeleton-loader
                     type="card"
                     height="32px"
                     width="32px"
                  ></v-skeleton-loader>
               </div>
               <div class="d-none d-md-flex">
                  <v-skeleton-loader
                     type="image"
                     height="36px"
                     width="107px"
                  ></v-skeleton-loader>
               </div>
            </div>

            <v-skeleton-loader
               class="mx-4"
               type="image"
               height="1.5px"
               width="500px"
            ></v-skeleton-loader>

            <div class="ml-auto">
               <div v-if="!hideAddButton">
                  <div class="d-flex d-md-none">
                     <v-skeleton-loader
                        type="card"
                        height="32px"
                        width="32px"
                     ></v-skeleton-loader>
                  </div>
                  <div class="d-none d-md-flex">
                     <v-skeleton-loader
                        type="image"
                        height="36px"
                        width="140px"
                     ></v-skeleton-loader>
                  </div>
               </div>
            </div>
         </div>
      </template>

      <template v-slot:footer>
         <v-skeleton-loader
            class="mb-6"
            type="divider"
            height="1px"
         ></v-skeleton-loader>

         <v-row>
            <v-col
               class="d-flex column-limit-skeleton align-center"
               cols="12"
               md="3"
            >
               <v-skeleton-loader
                  type="image"
                  height="40px"
                  width="90px"
               ></v-skeleton-loader>
            </v-col>

            <v-col
               class="d-flex column-pagination-skeleton align-center"
               cols="12"
               md="9"
            >
               <v-skeleton-loader
                  class="mr-4"
                  type="image"
                  height="15px"
                  width="143px"
               ></v-skeleton-loader>

               <div class="d-flex d-md-none">
                  <v-skeleton-loader
                     v-for="item in 2"
                     :key="item"
                     class="mx-1"
                     type="card"
                     height="30px"
                     width="30px"
                  ></v-skeleton-loader>
               </div>
               <div class="d-none d-md-flex">
                  <v-skeleton-loader
                     v-for="item in 10"
                     :key="item"
                     class="mx-1"
                     type="card"
                     height="30px"
                     width="30px"
                  ></v-skeleton-loader>
               </div>
            </v-col>
         </v-row>
      </template>

      <template v-slot:[`header.header`]>
         <v-skeleton-loader
            :width="`${generateRandomInt(minWidthValue, maxWidthValue)}px`"
            type="image"
            height="18px"
         ></v-skeleton-loader>
      </template>

      <template v-slot:[`item.header`]>
         <v-skeleton-loader
            :width="`${generateRandomInt(minWidthValue, maxWidthValue)}px`"
            type="image"
            height="12px"
         ></v-skeleton-loader>
      </template>
   </v-data-table>
</template>

<script>
import util from "../../../utils/util";
import vuetify from "../../../plugins/vuetify";

export default {
   name: "ListSkeleton",

   props: {
      headersLength: {
         type: Number,
         default: 5,
      },

      itemsPerPage: {
         type: Number,
         default: 10,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      headers() {
         return new Array(this.headersLength).fill({ value: "header" });
      },

      items() {
         return new Array(this.itemsPerPage).fill({ header: "header" });
      },

      cols() {
         return 12 / this.headersLength;
      },

      maxWidthValue() {
         let percentage = 0.5;
         if (vuetify.framework.breakpoint.width < 960) percentage = 3;
         return Math.ceil(
            (vuetify.framework.breakpoint.width * percentage) /
               this.headersLength
         );
      },

      minWidthValue() {
         return Math.ceil(this.maxWidthValue * 0.5);
      },
   },

   methods: {
      ...util,
   },
};
</script>

<style scoped>
.column-limit-skeleton {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

@media (max-width: 959px) {
   .column-limit-skeleton {
      justify-content: center;
      padding-bottom: 0px;
   }
}

.column-pagination-skeleton {
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

@media (max-width: 959px) {
   .column-pagination-skeleton {
      justify-content: center;
   }
}
</style>