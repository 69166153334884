<template>
   <div>
      <FormTitle
         v-if="!hideTitle"
         icon="mdi-file-document"
         title="SOLICITAÇÃO DE LEITO"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="
            request.status === 'rejected' ||
            request.status === 'rejected_by_executor'
         "
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <RequestSummary :request="formRequest" />

      <v-expansion-panels v-model="patientData" flat>
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div class="d-flex align-center">
                  <p>Dados do paciente</p>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-card :disabled="getMode() === 'read'" flat>
                  <div v-if="requestPatient && requestPatient.id">
                     <ViewPatient
                        :patient="requestPatient"
                        class="mt-n5"
                        hideTitle
                     />
                  </div>

                  <div v-else class="d-flex align-center">
                     <v-chip class="ma-0 mr-2" color="black" dark label>
                        <p class="body-2">Anônimo</p>

                        <v-icon right small> mdi-incognito </v-icon>
                     </v-chip>

                     <p class="body-2">
                        Essa solicitação não foi associada a um paciente.
                     </p>
                  </div>
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form readonly>
         <v-expansion-panels v-model="requestData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div class="d-flex align-center">
                     <p>Sinais vitais e tipo de leito</p>
                  </div>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-card disabled flat>
                     <PatientData :patientData="formRequest.clinical_data" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>

         <v-expansion-panels multiple flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Síndrome gripal
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <FluSyndromeForm
                        :fluSyndrome="formRequest.clinical_data.flu_syndrome"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Comorbidades
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <ComorbiditiesForm
                        :comorbidities="formRequest.clinical_data.comorbidities"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Oximetria periférica – Sistema Respiratório e Ventilação
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <RespiratoryForm
                        :respiratory="formRequest.clinical_data.respiratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Verificação de PA/FC/FR – Cardiovascular e Infecção
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <CardiovascularForm
                        :cardiovascular="
                           formRequest.clinical_data.cardiovascular
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> SARS CoV-2 </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <Sarscov2Form
                        :sarscov2="formRequest.clinical_data.sarscov2"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Desorientação, padrão neurologico, nível de consciência
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <NeurologicalForm
                        :neurological="formRequest.clinical_data.neurological"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Laboratório
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <LaboratoryForm
                        :laboratory="formRequest.clinical_data.laboratory"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> Imagem </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <ImageExamForm
                        :imageExam="formRequest.clinical_data.image_exams"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Recomendações do CERH
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <CerhRecommendationsForm
                        :cerhRecommendations="
                           formRequest.clinical_data.cerh_recommendations
                        "
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> NEWS FAST </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <NewsFastForm
                        :regulationData="formRequest.clinical_data"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header> UTI </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card disabled flat>
                     <UtiForm :uti="formRequest.clinical_data.uti" />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-form :readonly="getMode() === 'read'" ref="form" id="doctor-opinion">
         <v-expansion-panels v-model="regulationData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Parecer médico
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <DoctorOpinionForm
                        :doctorOpinion="formRequest.doctor_opinion"
                        ref="doctoropinion"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import RequestSummary from "../../../components/request/form/subForm/RequestSummary";
import PatientData from "../../../components/request/form/subForm/PatientData";
import FluSyndromeForm from "../../../components/request/form/subForm/FluSyndromeForm";
import ComorbiditiesForm from "../../../components/request/form/subForm/ComorbiditiesForm";
import RespiratoryForm from "../../../components/request/form/subForm/RespiratoryForm";
import CardiovascularForm from "../../../components/request/form/subForm/CardiovascularForm";
import Sarscov2Form from "../../../components/request/form/subForm/Sarscov2Form";
import NeurologicalForm from "../../../components/request/form/subForm/NeurologicalForm";
import LaboratoryForm from "../../../components/request/form/subForm/LaboratoryForm";
import ImageExamForm from "../../../components/request/form/subForm/ImageExamForm";
import CerhRecommendationsForm from "../../../components/request/form/subForm/CerhRecommendationsForm";
import NewsFastForm from "../../../components/request/form/subForm/NewsFastForm";
import UtiForm from "../../../components/request/form/subForm/UtiForm";
import DoctorOpinionForm from "../../../components/request/form/subForm/DoctorOpinionForm";
import ViewPatient from "components/patient/ViewPatient";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import responsiveUtil from "../../../utils/responsiveUtil";
import util from "../../../utils/util";

export default {
   name: "RequestAdminForm",

   components: {
      FormTitle,
      OutcomeDetails,
      JustificationRejection,
      OriginAndDestinyDetails,
      RequestSummary,
      PatientData,
      FluSyndromeForm,
      ComorbiditiesForm,
      RespiratoryForm,
      CardiovascularForm,
      Sarscov2Form,
      NeurologicalForm,
      LaboratoryForm,
      ImageExamForm,
      CerhRecommendationsForm,
      NewsFastForm,
      UtiForm,
      DoctorOpinionForm,
      ViewPatient,
   },

   props: {
      request: {
         type: Object,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",
         formRequest: {},

         patientData: 0,
         requestData: 0,
         regulationData: [],

         requestPatient: {},
      };
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id) this.setMode("read");
            else this.setMode("save");

            this.patientData = 0;

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();

            if (request.id && request.patient && request.patient.id)
               this.requestPatient = Object.assign({}, request.patient);
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,
      ...responsiveUtil,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject(
            {
               clinical_data: {
                  patient_data: {},
                  flu_syndrome: {},
                  comorbidities: {},
                  respiratory: {},
                  cardiovascular: {},
                  sarscov2: {
                     rt_pcr: {},
                     corticosteroid: {},
                     hydroxychloroquine: {},
                     oseltamivir: {},
                     heparin: {},
                  },
                  neurological: {},
                  laboratory: {},
                  image_exams: {},
                  cerh_recommendations: {},
                  uti: {},
               },
               doctor_opinion: {
                  patient_not_fit_for_regulation: false,
               },
            },
            request
         );

         if (requestTemp.clinical_data.sarscov2.rt_pcr.date)
            requestTemp.clinical_data.sarscov2.rt_pcr.date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.rt_pcr.date
               );

         if (requestTemp.clinical_data.sarscov2.corticosteroid.start_date)
            requestTemp.clinical_data.sarscov2.corticosteroid.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.corticosteroid.start_date
               );

         if (requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date)
            requestTemp.clinical_data.sarscov2.hydroxychloroquine.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.hydroxychloroquine
                     .start_date
               );

         if (requestTemp.clinical_data.sarscov2.oseltamivir.start_date)
            requestTemp.clinical_data.sarscov2.oseltamivir.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.oseltamivir.start_date
               );

         if (requestTemp.clinical_data.sarscov2.heparin.start_date)
            requestTemp.clinical_data.sarscov2.heparin.start_date =
               formatUtil.formatDate(
                  requestTemp.clinical_data.sarscov2.heparin.start_date
               );

         if (requestTemp.clinical_data.laboratory.date)
            requestTemp.clinical_data.laboratory.date = formatUtil.formatDate(
               requestTemp.clinical_data.laboratory.date
            );

         return requestTemp;
      },

      setMode(mode) {
         this.mode = mode;

         if (mode === "read") {
            this.requestData = 0;
            this.regulationData = null;
         } else {
            this.requestData = null;
            this.regulationData = 0;

            var formDoctorOpinion = document.getElementById("doctor-opinion");
            if (formDoctorOpinion)
               formDoctorOpinion.scrollIntoView({ behavior: "smooth" });
         }
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         let justificationValidation = true;
         let doctorOpinionValidation = true;

         if (this.$refs.form)
            justificationValidation = this.$refs.form.validate();
         if (this.$refs.doctoropinion)
            doctorOpinionValidation = this.$refs.doctoropinion.validate();

         return justificationValidation && doctorOpinionValidation;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();

         if (this.$refs.doctoropinion)
            this.$refs.doctoropinion.resetValidation();

         this.requestPatient = {};
      },
   },
};
</script>

<style scoped>
.overlay-config {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.history-overlay {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 1000 !important;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}
</style>
