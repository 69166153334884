<template>
   <div>
      <OperatorHealthSecretariesSkeleton v-if="!healthSecretarie.id" />

      <v-list-item v-else class="px-0" two-line>
         <v-icon class="mr-4" color="error" large>mdi-home-plus</v-icon>

         <v-list-item-content>
            <v-list-item-title>{{ healthSecretarie.name }}</v-list-item-title>

            <v-list-item-subtitle
               v-if="
                  healthSecretarie.address && healthSecretarie.address.street
               "
            >
               {{ healthSecretarie.address.street }},
               {{ healthSecretarie.address.number }},
               {{ healthSecretarie.address.district }}
            </v-list-item-subtitle>
         </v-list-item-content>

         <div class="mt-2">
            <v-btn
               @click="remove(healthSecretarie)"
               class="d-none d-sm-flex ma-1"
               color="error"
               id="disassociate-operator-health-secretaries"
               depressed
               small
               >Desassociar
               <v-icon right x-small>mdi-delete</v-icon>
            </v-btn>
            <v-btn
               @click="remove(healthSecretarie)"
               class="d-flex d-sm-none ma-1"
               color="error"
               id="disassociate_small-operator-health-secretaries"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-delete</v-icon>
            </v-btn>
         </div>
      </v-list-item>
   </div>
</template>

<script>
import OperatorHealthSecretariesSkeleton from "./OperatorHealthSecretariesSkeleton";
import healthSecretarieService from "../../../services/healthSecretarieService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "OperatorHealthSecretariesDetails",

   components: { OperatorHealthSecretariesSkeleton },

   props: {
      healthSecretarieId: {
         type: String,
         default: null,
      },
   },

   data: function () {
      return {
         healthSecretarie: {},
      };
   },

   watch: {
      healthSecretarieId: {
         immediate: true,
         handler(healthSecretarieId) {
            if (healthSecretarieId)
               this.getHealthSecretarieById(healthSecretarieId);
         },
      },
   },

   methods: {
      remove(healthSecretarie) {
         this.$emit("onDisassociateHealthSecretarie", healthSecretarie);
      },

      async getHealthSecretarieById(healthSecretarieId) {
         try {
            const response =
               await healthSecretarieService.getHealthSecretarieById(
                  healthSecretarieId
               );

            this.healthSecretarie = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(92, 107, 192, 1);
}
</style>