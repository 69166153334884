<template>
   <div>
      <v-row>
         <v-col cols="12" md="6">
            <p class="caption text--secondary">Cirurgia solicitada</p>

            <div class="d-flex align-center ma-n1">
               <p class="body-2 ma-1">{{ schedule.name || "-" }}</p>

               <v-chip
                  v-if="schedule.status"
                  :color="generateSurgeryStatus(schedule.status).color"
                  class="ma-1 px-2"
                  small
                  >{{ generateSurgeryStatus(schedule.status).text }}</v-chip
               >
            </div>
         </v-col>

         <v-col cols="12" md="6">
            <p class="caption text--secondary">Codigo SUS da cirurgia</p>

            <p class="body-2">
               {{ schedule.sus_code || "-" }}
            </p>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" md="6">
            <p class="caption text--secondary">Médico</p>

            <p class="body-2">
               {{ schedule.doctor_name || "-" }}
            </p>
         </v-col>

         <v-col cols="12" md="3">
            <p class="caption text--secondary">Data do agendamento</p>

            <p class="body-2">
               {{ formatDate(schedule.scheduling_date) || "-" }}
            </p>
         </v-col>

         <v-col v-if="schedule.execution_date" cols="12" md="3">
            <p class="caption text--secondary">Data de execução</p>

            <p class="body-2">
               {{ formatDate(schedule.execution_date) || "-" }}
            </p>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12">
            <p class="caption text--secondary">Observações</p>

            <p class="body-2">
               {{ schedule.notes || "-" }}
            </p>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import util from "utils/util";
import formatUtil from "utils/formatUtil";

export default {
   name: "ViewSchedule",

   props: {
      schedule: {
         type: Object,
      },
   },

   methods: {
      ...util,
      ...formatUtil,
   },
};
</script>

<style scoped>
</style>