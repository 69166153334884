import axiosService from "./axiosService";

async function getAllHealthSecretaryManagers(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/secretarymanagers?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item, index) => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
            case "cpf":
            case "healthsecretary_id":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }

         if (index < query.filter.length - 1) url += "&";
      });
   }

   return await axiosService.get(url);
}

async function getHealthSecretaryManagerById(healthSecretaryManagerId) {
   return await axiosService.get(
      `/secretarymanagers/${healthSecretaryManagerId}`
   );
}

async function createHealthSecretaryManager(healthSecretaryManager) {
   return await axiosService.post(`/secretarymanagers`, healthSecretaryManager);
}

async function updateHealthSecretaryManager(
   healthSecretaryManagerId,
   healthSecretaryManager
) {
   return await axiosService.patch(
      `/secretarymanagers/${healthSecretaryManagerId}`,
      healthSecretaryManager
   );
}

export default {
   getAllHealthSecretaryManagers,
   createHealthSecretaryManager,
   updateHealthSecretaryManager,
   getHealthSecretaryManagerById
};
