var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingWardsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit,"hideAddButton":_vm.hideAddButton}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.wards,"loading":_vm.loadingWardsPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (ward) { return (!_vm.blockClick ? _vm.onClickViewWard(ward) : null); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingWardsPartially,"hideAddButton":_vm.hideAddButton,"filterActor":"ala","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddWard}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalWards,"loading":_vm.loadingWardsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhuma ala foi encontrada."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando alas, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"name-ward-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"type-ward-list-data"},[_vm._v(" "+_vm._s(_vm.translateType(item.type))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"created_at-ward-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"color":"black","id":("edit-item-" + index + "-ward-list-button"),"small":""},on:{"click":function($event){return _vm.editWard(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mx-1",attrs:{"id":("remove-item-" + index + "-ward-list-button"),"color":"error","small":""},on:{"click":function($event){return _vm.removeWard(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }