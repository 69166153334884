<template>
   <div>
      <ListSkeleton
         v-if="loadingSurgeryTypesFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
         :hideAddButton="hideAddButton"
      />

      <v-data-table
         v-else
         @click:row="
            (surgeryType) =>
               !blockClick ? onClickViewSurgeryType(surgeryType) : null
         "
         :headers="headers"
         :items="surgeryTypes"
         :loading="loadingSurgeryTypesPartially"
         :items-per-page="limit"
         :class="listElevation"
         class="standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddSurgeryType"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingSurgeryTypesPartially"
               :hideAddButton="hideAddButton"
               filterActor="cirurgia"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalSurgeryTypes"
               :loading="loadingSurgeryTypesPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData message="Desculpe, nenhuma cirurgia foi encontrada." />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando cirurgias, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-surgeryType-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.total`]="{ item }">
            <v-chip :color="colorByTotal(item.total - item.answered)" small>
               {{ item.total - item.answered }}</v-chip
            >
         </template>

         <template v-slot:[`item.created_at`]="{ item }">
            <p class="created_at-surgeryType-list-data">
               {{ formatDate(item.created_at) }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
               v-if="!hideEditButton"
               @click="editSurgeryType(item)"
               class="mx-1"
               color="black"
               :id="`edit-item-${index}-surgeryType-list-button`"
               small
            >
               mdi-pencil
            </v-icon>

            <v-icon
               v-if="!hideRemoveButton"
               @click="removeSurgeryType(item)"
               :id="`remove-item-${index}-surgeryType-list-button`"
               color="error"
               class="mx-1"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import surgeryTypeService from "../../services/surgeryTypeService";

export default {
   name: "SurgeryTypeList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   props: {
      healthUnit: {
         type: Object,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      listElevation: {
         type: String,
         default: "elevation-2",
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Código SUS",
               class: "primary--text body-2 font-weight-medium",
               value: "sus_code",
            },
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "Disponíveis",
               class: "primary--text body-2 font-weight-medium",
               value: "total",
            },
            {
               text: "Cadastrado em",
               class: "primary--text body-2 font-weight-medium",
               value: "created_at",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
         ],

         surgeryTypes: [],

         totalSurgeryTypes: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingSurgeryTypesFully: true,
         loadingSurgeryTypesPartially: true,

         blockClick: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "active",
                  query: true,
               },
            ],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         return query;
      },
   },

   watch: {
      healthUnit: {
         immediate: true,
         async handler(healthUnit) {
            if (healthUnit && healthUnit.id) {
               this.loadingSurgeryTypesFully = true;
               await this.getAllHelthUnitSurgeryTypes(
                  healthUnit.id,
                  this.query
               );
               this.loadingSurgeryTypesFully = false;
            }
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllHelthUnitSurgeryTypes(this.healthUnit.id, this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllHelthUnitSurgeryTypes(this.healthUnit.id, this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllHelthUnitSurgeryTypes(this.healthUnit.id, this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllHelthUnitSurgeryTypes(this.healthUnit.id, this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllHelthUnitSurgeryTypes(this.healthUnit.id, this.query);
      },

      onClickAddSurgeryType(surgeryType) {
         this.$emit("onClickAddSurgeryType", surgeryType);
      },

      onClickViewSurgeryType(surgeryType) {
         this.$emit("onClickViewSurgeryType", surgeryType);
      },

      editSurgeryType(surgeryType) {
         this.$emit("onClickEditSurgeryType", surgeryType);
      },

      removeSurgeryType(surgeryType) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveSurgeryType", surgeryType);
            this.blockClick = false;
         }, 1);
      },

      colorByTotal(total) {
         let color;

         if (total >= 300) color = "info";
         else if (total >= 100) color = "success";
         else if (total >= 50) color = "amber";
         else if (total >= 10) color = "warning";
         else color = "error";

         return color;
      },

      async refreshAllHelthUnitSurgeryTypesByRef() {
         if (this.healthUnit && this.healthUnit.id) {
            this.loadingSurgeryTypesFully = true;
            await this.getAllHelthUnitSurgeryTypes(
               this.healthUnit.id,
               this.query
            );
            this.loadingSurgeryTypesFully = false;
         }
      },

      async getAllHelthUnitSurgeryTypes(healthUnitId, query) {
         this.loadingSurgeryTypesPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response =
               await surgeryTypeService.getAllHealthUnitSurgeryTypes(
                  healthUnitId,
                  query
               );

            this.totalSurgeryTypes = parseInt(
               response.headers["x-total-count"]
            );
            this.surgeryTypes = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeryTypesPartially = false;
         }
      },
   },
};
</script>

<style scoped>
</style>