<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="cardiovascular.dva"
            class="mr-3"
            label="Uso de DVA"
            id="dva-cardiovascular-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="cardiovascular.arrhythmia"
            class="mr-3"
            label="Arritmia"
            id="arrhythmia-cardiovascular-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="cardiovascular.concomitant_bacterial_infection"
            class="mr-3"
            label="Infecção bacteriana concomitante"
            id="concomitant_bacterial_infection-cardiovascular-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="cardiovascular.antibiotic_therapy"
            class="mr-3"
            label="Uso de antibioticoterapia"
            id="antibiotic_therapy-cardiovascular-form-input"
         ></v-checkbox>
      </div>

      <v-row class="mt-1">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.blood_pressure"
               label="PA (mmHg)"
               id="blood_pressure-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model.number="cardiovascular.axillary_temperature"
               v-maska="['##', '##.#']"
               label="Temperatura auxiliar (Cº)"
               id="axillary_temperature-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.noradrenaline"
               label="Noradrenalina - Dose (mcg/kg/min)"
               id="noradrenaline-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.adrenaline"
               label="Adrenalina - Dose"
               id="adrenaline-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.vasopressin"
               label="Vasopressina - Dose (Ul/min)"
               id="vasopressin-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.dobutamine"
               label="Dobutamina - Dose (mcg/Kg/min)"
               id="dobutamine-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field 
               v-model="cardiovascular.fa" 
               label="FA"
               id="fa-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.flutter"
               label="Flutter"
               id="flutter-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.tsv"
               label="TSV"
               id="tsv-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field 
               v-model="cardiovascular.tv" 
               label="TV"
               id="tv-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.infection_focus"
               label="Foco"
               id="infection_focus-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.abdominal_infection"
               label="Abdominal"
               id="abdmonial_infection-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5 mb-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.skin_soft_tissue_infection"
               label="Pele/Tecidos moles"
               id="skin_soft_tissue_infection-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="cardiovascular.blood_flow_infection"
               label="Corrente sanguínea"
               id="blood_flow_infection-cardiovascular-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "CardiovascularForm",

   props: {
      cardiovascular: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>