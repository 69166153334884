<template>
   <div>
      <ListSkeleton
         v-if="loadingRequestsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
         :hideAddButton="hideAddButton"
      />

      <v-data-table
         v-else
         @click:row="admin => (!blockClick ? onClickViewRequest(admin) : null)"
         :headers="headers"
         :items="requests"
         :loading="loadingRequestsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <SurgeryRequestListHeader
               @onChangeSort="onChangeSort"
               @onChangeDate="onChangeDate"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddRequest"
               :sort="sort"
               :sorts="sorts"
               :date="date"
               :initialFilterBy="initialFilterBy"
               :filter="filter"
               :filters="filters"
               :loading="loadingRequestsPartially"
               :hideAddButton="hideAddButton"
               :allowedStatusInStatusFilter="allowedStatusInStatusFilter"
               filterActor="solicitação"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalRequests"
               :loading="loadingRequestsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhuma solicitação foi encontrada."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando solicitações, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.code`]="{ item }">
            <div class="d-flex align-center ma-n1" :key="newRequestKey">
               <p class="code-regulator-request-operator-list-data ma-1">
                  {{ item.code }}
               </p>

               <v-tooltip v-if="requestIsNew(item)" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-chip
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        class="chip-adjust ma-1"
                        x-small
                     >
                        Recente
                     </v-chip>
                  </template>

                  <p class="text-center">
                     Solicitação cadastrada <br />
                     {{ relativeDate(item.created_at) }}
                  </p>
               </v-tooltip>

               <v-tooltip v-if="item.was_created_by_patient" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        class="ma-1"
                        size="17"
                        right
                     >
                        mdi-cellphone-text
                     </v-icon>
                  </template>

                  <p class="text-center">
                     Cadastrado pelo <br />
                     OperaPB
                  </p>
               </v-tooltip>
            </div>
         </template>

         <template v-slot:[`item.created_at`]="{ item }">
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <p
                     :class="
                        `request_created_at-${userInfo}-request-operator-list-data`
                     "
                     v-bind="attrs"
                     v-on="on"
                  >
                     {{ formatDateTime(item.created_at, "DD/MM [às] HH:mm") }}
                  </p>
               </template>

               <p class="text-center">
                  {{ formatDateTime(item.created_at) }} <br />
                  {{ relativeDate(item.created_at) }}
               </p>
            </v-tooltip>
         </template>

         <template v-slot:[`item.municipality.name`]="{ item }">
            <p
               :class="`municipality-name-regulator-request-operator-list-data`"
            >
               {{ item.municipality.name }}
            </p>
         </template>

         <template v-slot:[`item.patient.name`]="{ item }">
            <p :class="`name-regulator-request-operator-list-data`">
               {{ item.patient.name }}
            </p>
         </template>

         <template v-slot:[`item.surgery.name`]="{ item }">
            <p v-if="item.surgery && item.surgery.name">
               {{ item.surgery.name }}
            </p>

            <p v-else>Sem cirurgia</p>
         </template>

         <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex align-center ma-n1">
               <p
                  :class="`${generateState(item.status).color}--text`"
                  class="font-weight-medium ma-1"
               >
                  {{ generateState(item.status).text }}
               </p>

               <v-tooltip
                  v-if="item.doctor_opinion && item.doctor_opinion.priority"
                  top
               >
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="
                           translatePriority(item.doctor_opinion.priority).color
                        "
                        class="ma-1"
                        x-small
                     >
                        mdi-circle
                     </v-icon>
                  </template>

                  <p class="text-center">
                     Prioridade:
                     {{
                        translatePriority(item.doctor_opinion.priority)
                           .translatedPriority
                     }}
                  </p>
               </v-tooltip>
            </div>
         </template>

         <template v-slot:[`item.alerts`]="{ item }">
            <div
               :key="messageNotificationKey"
               class="d-flex justify-center align-center flex-wrap mx-n1"
            >
               <v-card
                  v-if="item.totalMessages"
                  color="transparent"
                  height="20px"
                  width="20px"
                  class="mx-1"
                  flat
               >
                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-icon
                           v-bind="attrs"
                           v-on="on"
                           class="icon-message-notification"
                           color="orange"
                           size="20"
                        >
                           mdi-chat
                        </v-icon>
                     </template>

                     <p v-if="!item.unreadMessages" class="text-center">
                        Chat iniciado
                     </p>
                     <p v-else class="text-center">
                        {{ item.unreadMessages }}
                        {{ item.unreadMessages > 1 ? "mensagens" : "mensagem" }}
                     </p>
                  </v-tooltip>

                  <div class="icon-content-notification">
                     <v-icon v-if="!item.unreadMessages" size="11" dark>
                        mdi-check
                     </v-icon>
                     <p v-else class="count-message-label">
                        {{ item.unreadMessages }}
                     </p>
                  </div>
               </v-card>

               <v-tooltip v-if="item.is_blocked" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1 pulsate-fwd"
                        color="error"
                        size="20"
                     >
                        mdi-eye
                     </v-icon>
                  </template>

                  <p class="text-center">
                     Solicitação <br />
                     sendo visualizada
                  </p>
               </v-tooltip>

               <v-tooltip v-if="item.is_checked" top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        color="success"
                        small
                        >mdi-check-circle</v-icon
                     >
                  </template>

                  <p>Validada</p>
               </v-tooltip>
               <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        color="#bdbdbd"
                        small
                        >mdi-help-circle</v-icon
                     >
                  </template>

                  <p>Não validada</p>
               </v-tooltip>
            </div>
         </template>

         <template v-slot:[`item.actions`]="{ item }">
            <div class="mx-n1">
               <v-icon
                  v-if="!hideEditButton && !hideEditButtonRegulator(item)"
                  @click="editRequest(item)"
                  class="mx-1 edit-request-regulator-list-data"
                  color="black"
                  id="edit-button-surgery-request"
                  small
               >
                  mdi-pencil
               </v-icon>

               <v-icon
                  v-if="!hideRemoveButton"
                  class="mx-1 delete-request-regulator-list-data"
                  color="error"
                  id="remove-button-surgery-request"
                  small
               >
                  mdi-delete
               </v-icon>
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../../components/base/list/ListFooter";
import SurgeryRequestListHeader from "components/surgeryRequest/list/SurgeryRequestListHeader";
import NoData from "../../../components/base/NoData";
import LoadingData from "../../../components/base/LoadingData";
import responsiveUtil from "../../../utils/responsiveUtil";
import snackBarUtil from "../../../utils/snackBarUtil";
import formatUtil from "../../../utils/formatUtil";
import util from "../../../utils/util";
import messageService from "services/messageService";
import requestSurgeryService from "services/requestSurgeryService";
import moment from "moment";
import socketIoService from "services/socketIoService";

export default {
   name: "SurgeryRequestRegulatorList",

   components: {
      ListSkeleton,
      ListFooter,
      SurgeryRequestListHeader,
      NoData,
      LoadingData
   },

   props: {
      /* Pode ser um regulator ou um regulatorManager */
      user: {
         type: Object
      },

      hideAddButton: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      initialQuery: {
         type: Array,
         default: () => []
      },

      userInfo: {
         type: String,
         default: null
      },

      allowedStatusInStatusFilter: {
         type: Array,
         default: () => [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected",
            "rejected_by_executor",
            "canceled"
         ]
      }
   },

   data: function() {
      return {
         headers: [
            {
               text: "Registro",
               class: "primary--text body-2 font-weight-medium",
               value: "code",
               width: "12%"
            },
            {
               text: "Cadastrado em",
               class: "primary--text body-2 font-weight-medium",
               value: "created_at",
               width: "131px"
            },
            {
               text: "Paciente",
               class: "primary--text body-2 font-weight-medium",
               value: "patient.name"
            },
            {
               text: "Cidade",
               class: "primary--text body-2 font-weight-medium",
               value: "municipality.name",
               width: "15%"
            },
            {
               text: "Cirurgia",
               class: "primary--text body-2 font-weight-medium",
               value: "surgery.name"
            },
            {
               text: "Estado",
               class: "primary--text body-2 font-weight-medium",
               value: "status",
               width: "155px"
            },
            {
               text: "Alertas",
               class: "primary--text body-2 font-weight-medium",
               value: "alerts",
               sortable: false,
               align: "center",
               width: "8%"
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
               width: "5%"
            }
         ],

         sorts: [
            {
               sort: "status",
               text: "Estado"
            },
            {
               sort: "-created_at",
               text: "Últimas cadastradas"
            },
            {
               sort: "-last_message_date&last_message_to=regulation",
               text: "Mensagens pendentes"
            },
            {
               sort: "created_at",
               text: "Primeiras cadastradas"
            },
            {
               sort: "-updated_at",
               text: "Últimas atualizadas"
            }
         ],

         filters: [
            {
               filter: "code",
               text: "Registro",
               mask: "#################",
               unmask: /[^0-9]/g,
               maxLength: 17
            },
            {
               filter: "patient_id",
               text: "Nome do paciente"
            },
            {
               filter: "municipality_id&doctor_opinion.sus_code",
               text: "Município x Cirurgia"
            },
            {
               filter: "sourcehealthentity_id&doctor_opinion.sus_code",
               text: "Unidade de origem x Cirurgia"
            },
            {
               filter: "status&is_checked",
               text: "Estado"
            },
            {
               filter: "was_created_by_patient",
               text: "Cadastrado pelo OperaPB"
            }
         ],

         requests: [],
         requestsByPatient: [],

         totalRequests: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "code",
         filterBy: "",
         initialFilterBy: null,
         date: [formatUtil.formatDate(moment(), "YYYY-MM")],

         loadingRequestsFully: true,
         loadingRequestsPartially: true,

         blockClick: false,

         messageNotificationKey: 0,
         newRequestKey: 0,

         socketIoConnection: null
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "created_at",
                  query: `gte:${this.getRangeDateFromDate.gte}`
               },
               {
                  attribute: "created_at",
                  query: `lte:${this.getRangeDateFromDate.lte}`
               }
            ]
         };

         if (this.initialQuery && this.initialQuery.length)
            this.initialQuery.forEach(item =>
               query.filter.push({
                  attribute: item.attribute,
                  query: item.query
               })
            );

         /* Caso do filtro múltiplo, de cirurgias por municípios, cirurgias por unidades de saúde e solicitação validada por estado. */
         if (
            [
               "municipality_id&doctor_opinion.sus_code",
               "sourcehealthentity_id&doctor_opinion.sus_code",
               "status&is_checked"
            ].includes(this.filter) &&
            this.filterBy &&
            this.filterBy.length
         ) {
            this.filterBy.forEach(item =>
               query.filter.push({
                  attribute: item.text,
                  query: item.value
               })
            );
         } else if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         ) {
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy
            });
         }

         if (this.user && this.user.sub)
            query.filter.push({
               attribute: "responsibles",
               query: this.user.sub
            });

         return query;
      },

      getRangeDateFromDate() {
         const getGte = date =>
            formatUtil.formatDate(
               moment(date).startOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );
         const getLte = date =>
            formatUtil.formatDate(
               moment(date).endOf("month"),
               "YYYY-MM-DD[T]HH:mm:ss"
            );

         return {
            gte: getGte(this.date[0]),
            lte: getLte(this.date[this.date.length - 1])
         };
      },

      notificationStore() {
         return this.$store.getters["notificationCenter/getNotifications"];
      }
   },

   watch: {
      "socketIoConnection.on": function() {
         this.socketIoConnection.on("new_request", () =>
            this.getAllRequests(this.query)
         );

         this.socketIoConnection.on("new_message", () =>
            this.getAllRequests(this.query)
         );
      },

      /**
       * Monitora os query params na url, caso tenha algum parâmetro vai checa-lós
       * e abrir a tab específica de acordo com os query passados.
       * OBS.: Válido só para o regulador e gestor regulador.
       */
      "$route.query": {
         immediate: true,
         handler(value) {
            if (Object.keys(value).length) {
               this.checkQueryParams();
            }
         }
      }
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,
      ...util,

      onChangePage(page) {
         this.page = page;
         this.getAllRequests(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onChangeDate(date) {
         this.date = date;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      async onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.date = [formatUtil.formatDate(moment(), "YYYY-MM")];

         await this.getAllRequests(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllRequests(this.query);
      },

      onClickAddRequest(request) {
         this.$emit("onClickAddRequest", request);
      },

      onClickViewRequest(request) {
         this.$store.commit(
            "notificationCenter/setNewRequestNotificationAsRead",
            request.id
         );

         this.$emit("onClickViewRequest", request);
      },

      editRequest(request) {
         this.$emit("onClickEditRequest", request);
      },

      hideEditButtonRegulator(request) {
         return request.is_closed || !request.is_checked;
      },

      /* Renderiza a coluna de alerts novamente. */
      forceMessageNotificationRerender() {
         this.messageNotificationKey += 1;
      },

      /* Renderiza a coluna que apresenta o símbolo de nova solicitação. */
      forceNewRequestRerender() {
         this.newRequestKey += 1;
      },

      translatePriority(priority) {
         let translatedPriority = "Vermelha";
         let color = "error";

         switch (priority) {
            case "blue":
               translatedPriority = "Azul";
               color = "info";
               break;
            case "green":
               translatedPriority = "Verde";
               color = "success";
               break;
            case "yellow":
               translatedPriority = "Amarela";
               color = "amber";
               break;
            case "orange":
               translatedPriority = "Laranja";
               color = "warning";
               break;
         }

         return { translatedPriority, color };
      },

      blockRequestByRef(requestId) {
         const requestIndex = this.requests.findIndex(
            item => item.id === requestId
         );

         if (requestIndex !== -1) {
            this.requests[requestIndex].is_blocked = true;
            this.forceMessageNotificationRerender();
         }
      },

      unblockRequestByRef(requestId) {
         const requestIndex = this.requests.findIndex(
            item => item.id === requestId
         );

         if (requestIndex !== -1) {
            this.requests[requestIndex].is_blocked = false;
            this.forceMessageNotificationRerender();
         }
      },

      setMessagesAsReadByRef(requestId) {
         const requestIndex = this.requests.findIndex(
            item => item.id === requestId
         );

         if (requestIndex !== -1) {
            this.requests[requestIndex].unreadMessages = 0;
            this.forceMessageNotificationRerender();
         }
      },

      /**
       * incrementRequestTotalMessagesByRef
       * Método para incrementar o total de mensagens de uma solicitação, exibindo se o chat já foi usado ou não
       * na listagem.
       * @param request objeto solicitação que teve a mensagem adicionada.
       */
      async incrementRequestTotalMessagesByRef(request) {
         const requestTemp = this.requests.find(item => item.id === request.id);

         if (
            requestTemp.totalMessages !== undefined &&
            requestTemp.totalMessages !== null &&
            Number.isInteger(requestTemp.totalMessages)
         )
            requestTemp.totalMessages += 1;
      },

      /**
       * refreshAllRequestsByRef
       * Método para que o componente pai consiga atualizar as solicitações via referência.
       * @param loadingPartially muda o tipo de animação de loading das solicitações.
       */
      async refreshAllRequestsByRef(loadingPartially) {
         if (loadingPartially) await this.getAllRequests(this.query);
         else {
            this.loadingRequestsFully = true;
            await this.getAllRequests(this.query);
            this.loadingRequestsFully = false;
         }
      },

      async getAllRequests(query) {
         this.loadingRequestsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await requestSurgeryService.getAllSurgeryRequests(
               query
            );

            this.totalRequests = parseInt(response.headers["x-total-count"]);

            /* Alterar status 'visualmente' da solicitação de "Rejeitada" para "Rejeitada pelo executante" */
            const requestsModifiedStatus = response.data.map(item => {
               let result = item;

               if (item.status === "rejected" && !item.is_closed)
                  item.status = "rejected_by_executor";

               return result;
            });

            /* Verificar se tem mensagens que o REGULADOR enviou para o QUALQUER USUÁRIO. */
            requestsModifiedStatus.forEach(item => {
               messageService
                  .getRequestMessageInfoById(item.id)
                  .then(response => {
                     if (item.totalMessages)
                        item.totalMessages +=
                           response.data.total_messages_received;
                     else
                        item.totalMessages =
                           response.data.total_messages_received;

                     this.forceMessageNotificationRerender();
                  });
            });

            /* Verificar se tem mensagens enviadas de QUALQUER USUÁRIO para o REGULADOR. */
            requestsModifiedStatus.forEach(item => {
               messageService
                  .getRequestMessageInfoById(item.id, "regulation")
                  .then(response => {
                     if (item.totalMessages)
                        item.totalMessages +=
                           response.data.total_messages_received;
                     else
                        item.totalMessages =
                           response.data.total_messages_received;

                     item.unreadMessages = response.data.total_unread_messages;

                     this.forceMessageNotificationRerender();
                  });
            });

            this.requests = Object.assign([], requestsModifiedStatus);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRequestsPartially = false;
         }
      },

      checkQueryParams() {
         const queryParams = this.$route.query;

         if (Object.keys(queryParams).length && queryParams.code) {
            // Reseta as variaveis de paginação, ordenação, data e seta o parametro de filtro
            // por código, filtrando pela solicitação passada no query params.
            this.page = 1;
            this.sort = "-created_at";
            this.date = [
               formatUtil.formatDate(moment(queryParams.created_at), "YYYY-MM")
            ];

            this.filter = "code";
            this.filterBy = queryParams.code;
            this.initialFilterBy = queryParams.code;

            this.refreshAllRequestsByRef();
         }
      },

      // Função para ler as notificações e verificar se alguma dos items da variavel
      // request tem o mesmo id da solicitação contida no data das notificações, para
      // colocar a tag de "Recente" na listagem.
      requestIsNew(request) {
         const indexFound = this.notificationStore.findIndex(
            notification =>
               notification.unread &&
               notification.sourceEvent === "new_request" &&
               notification.data.id === request.id
         );

         return indexFound !== -1;
      }
   },

   async created() {
      // Caso tenha query params, não inicializa a listagem, pois o watch do
      // query params, vai iniciar os items.
      if (!Object.keys(this.$route.query).length)
         this.refreshAllRequestsByRef();

      switch (this.userStore.sub_type) {
         case "regulator_manager":
         case "regulator":
            this.socketIoConnection = socketIoService.getConnection();
            break;
      }
   }
};
</script>

<style scoped>
.chip-adjust {
   padding: 0 5px;
}

.unread-messages-text {
   color: white;
   font-weight: 400;
   font-size: 0.7rem;
}

.icon-message-notification {
   display: inherit !important;
}

.icon-content-notification {
   pointer-events: none;

   position: absolute !important;
   top: 0px;
   left: 0px;

   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
}

.count-message-label {
   color: white;
   font-size: 0.65rem !important;
   font-weight: 500;
}

.pulsate-fwd {
   -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
   animation: pulsate-fwd 2s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
   0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }

   50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
   }

   100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }
}

@keyframes pulsate-fwd {
   0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }

   50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
   }

   100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
   }
}

.blink-2 {
   -webkit-animation: blink-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      infinite both;
   animation: blink-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite both;
}

@-webkit-keyframes blink-2 {
   0% {
      opacity: 1;
   }

   50% {
      opacity: 0.2;
   }

   100% {
      opacity: 1;
   }
}

@keyframes blink-2 {
   0% {
      opacity: 1;
   }

   50% {
      opacity: 0.2;
   }

   100% {
      opacity: 1;
   }
}
</style>
