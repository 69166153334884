<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="fluSyndrome.cough"
            class="mr-3"
            label="Tosse"
            id="cough-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.runny_nose"
            class="mr-3"
            label="Coriza nasal"
            id="runny_nose-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.anosmia"
            class="mr-3"
            label="Anosmia"
            id="anosmia-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.fever"
            class="mr-3"
            label="Febre"
            id="fever-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.myalgia"
            class="mr-3"
            label="Mialgia"
            id="myalgia-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.headache"
            class="mr-3"
            label="Cefaleia"
            id="headache-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.fatigue"
            class="mr-3"
            label="Fadiga"
            id="fatigue-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.dyspnoea"
            class="mr-3"
            label="Dispineia"
            id="dyspnoea-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.asthenia"
            class="mr-3"
            label="Astenia"
            id="asthenia-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.kinship_degree"
            class="mr-3"
            label="Grau Parentesco"
            id="kinship_degree-flu-syndrome-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="fluSyndrome.exposure_to_sars_cov_2"
            class="mr-3"
            label="Exposição epidemiológica a qualquer pessoa infectada pelo SARS CoV-2"
            id="exposure_to_sars_cov_2-flu-syndrome-form-input"
         ></v-checkbox>
      </div>

      <v-text-field
         v-model="fluSyndrome.onset_time"
         class="mt-5 mb-2"
         label="Tempo início de quadro sintomatico"
         id="onset_time-flu-syndrome-form-input"
      ></v-text-field>
   </div>
</template>

<script>
export default {
   name: "FluSyndromeForm",

   props: {
      fluSyndrome: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>