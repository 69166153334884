import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllHealthUnits(municipalityId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/municipalities/${municipalityId}/healthunits?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function createHealthUnit(municipalityId, healthUnit) {
   return await axiosService.post(
      `/municipalities/${municipalityId}/healthunits`,
      healthUnit
   );
}

async function updateHealthUnit(municipalityId, healthUnitId, healthUnit) {
   return await axiosService.patch(
      `/municipalities/${municipalityId}/healthunits/${healthUnitId}`,
      healthUnit
   );
}

async function getHealthUnitById(healthUnitId) {
   return await axiosService.get(`/healthunits/${healthUnitId}`);
}

async function activeHealthUnit(healthUnitId, active) {
   return await axiosService.put(`/healthunits/${healthUnitId}/active`, {
      active
   });
}

async function capabilitiesHealthUnit(healthUnitId, capabilities) {
   return await axiosService.put(
      `/healthunits/${healthUnitId}/capabilities`,
      capabilities
   );
}

export default {
   getAllHealthUnits,
   createHealthUnit,
   updateHealthUnit,
   getHealthUnitById,
   activeHealthUnit,
   capabilitiesHealthUnit
};
