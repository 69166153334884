<template>
   <div>
      <FormTitle icon="mdi-map" title="MUNICÍPIO" />

      <v-alert
         v-if="getMode() === 'read' && formMunicipality.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Município cadastrado em
         {{
            formatDate(formMunicipality.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card class="mb-3" :disabled="getMode() === 'read'" flat>
            <v-row class="my-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMunicipality.name"
                     id="name-municipality-form-input"
                     label="Nome"
                     disabled
                     readonly
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <!-- <v-text-field
                     v-model="formMunicipality.macro_region"
                     id="macro_region-municipality-form-input"
                     label="Mesorregião"
                     disabled
                     readonly
                  ></v-text-field> -->

                  <v-select
                     v-model="formMunicipality.macro_region"
                     :items="macroRegionItems"
                     menu-props="auto"
                     label="Macro região"
                     id="macro_region-municipality-form-input"
                  >
                  </v-select>
               </v-col>
               <v-col cols="12" md="4">
                  <v-select
                     v-model="formMunicipality.health_region"
                     :items="healthRegionItems"
                     menu-props="auto"
                     label="Região de saúde"
                     id="health_region-municipality-form-input"
                  >
                  </v-select>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <FormActions
         @onClickEdit="onClickEditMunicipality"
         @onClickRemove="onClickRemoveMunicipality"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveMunicipality"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveMunicipality"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import FormActions from "../../components/base/form/FormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import municipalityService from "../../services/municipalityService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "MunicipalityForm",

   components: { FormTitle, FormActions },

   props: {
      municipality: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formMunicipality: {},

         loadingSaveMunicipality: false,

         macroRegionItems: [
            { text: "1º", value: "first" },
            { text: "2º", value: "second" },
            { text: "3º", value: "third" },
         ],

         healthRegionItems: [
            { text: "1º", value: "first" },
            { text: "2º", value: "second" },
            { text: "3º", value: "third" },
            { text: "4º", value: "fourth" },
            { text: "5º", value: "fifth" },
            { text: "6º", value: "sixth" },
            { text: "7º", value: "seventh" },
            { text: "8º", value: "eighth" },
            { text: "9º", value: "ninth" },
            { text: "10º", value: "tenth" },
            { text: "11º", value: "eleventh" },
            { text: "12º", value: "twelfth" },
            { text: "13º", value: "thirteenth" },
            { text: "14º", value: "fourteenth" },
            { text: "15º", value: "fifteenth" },
            { text: "16º", value: "sixteenth" },
         ],
      };
   },

   watch: {
      municipality: {
         immediate: true,
         handler(municipality) {
            if (municipality.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.formMunicipality = this.formatObjectToView(municipality);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(municipality) {
         let municipalityTemp = util.mergeObject({}, municipality);

         return municipalityTemp;
      },

      formatObjectToSend(municipality, removeEmptyStrings) {
         let municipalityTemp = util.removeBlankAttributes(municipality, {
            emptyStrings: removeEmptyStrings,
         });

         return municipalityTemp;
      },

      onClickEditMunicipality() {
         this.setMode("save");
      },

      onClickRemoveMunicipality() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formMunicipality = this.formatObjectToView(this.municipality);
         this.$emit("onClickCancel");
      },

      onClickSaveMunicipality() {
         if (this.formMunicipality.id)
            this.updateMunicipality(
               this.formMunicipality.id,
               this.formMunicipality
            );
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async updateMunicipality(municipalityId, municipality) {
         if (!this.executeValidation() || !municipalityId || !municipality)
            return;

         this.loadingSaveMunicipality = true;

         try {
            await municipalityService.updateMunicipality(
               municipalityId,
               this.formatObjectToSend(municipality, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O município foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedMunicipality");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveMunicipality = false;
         }
      },
   },
};
</script>

<style scoped>
</style>