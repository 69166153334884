<template>
   <div>
      <div class="d-flex align-center">
         <v-icon size="21" left>mdi-hospital-building</v-icon>

         <v-skeleton-loader
            class="rounded-pill"
            type="image"
            width="150"
            height="12px"
         ></v-skeleton-loader>
      </div>

      <v-card class="standard-padding-tiny mt-2" outlined flat>
         <div class="d-flex align-center">
            <v-icon size="20" left>mdi-medical-bag</v-icon>

            <v-skeleton-loader
               class="rounded-pill"
               type="image"
               width="250"
               height="12px"
            ></v-skeleton-loader>
         </div>

         <div class="d-flex align-center mt-2">
            <v-icon size="20" left>mdi-check-circle</v-icon>

            <v-skeleton-loader
               class="rounded-pill"
               type="image"
               width="100"
               height="12px"
            ></v-skeleton-loader>
         </div>
      </v-card>
   </div>
</template>

<script>
export default {
   name: "SurgeryTypeReserveListCardSkeleton",
};
</script>

<style>
</style>