<template>
   <div>
      <ListSkeleton
         v-if="loadingHealthUnitsFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (healthUnit) =>
               !blockClick ? onClickViewHealthUnit(healthUnit) : null
         "
         :headers="headers"
         :items="healthUnits"
         :loading="loadingHealthUnitsPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddHealthUnit"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingHealthUnitsPartially"
               filterActor="unidade de saúde"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalHealthUnits"
               :loading="loadingHealthUnitsPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhuma unidade de saúde foi encontrada."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando unidade de saúdes, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="name-health-unit-list-data">
               {{ item.name }}
            </p>
         </template>

         <template v-slot:[`item.director`]="{ item }">
            <p class="director-health-unit-list-data">
               {{ item.director }}
            </p>
         </template>

         <template v-slot:[`item.address.state`]="{ item }">
            <p class="address_state-health-unit-list-data">
               {{ item.address.state }}
            </p>
         </template>

         <template v-slot:[`item.address.city`]="{ item }">
            <p class="address_city-health-unit-list-data">
               {{ item.address.city }}
            </p>
         </template>

         <template v-slot:[`item.cpf`]="{ item }">
            <p>
               {{ formatCPF(item.cpf) }}
            </p>
         </template>

         <template v-slot:[`item.birth_date`]="{ item }">
            <p>
               {{ formatDate(item.birth_date) }}
            </p>
         </template>

         <template v-slot:[`item.last_login`]="{ item }">
            <p>
               {{ formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm") }}
            </p>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
               @click="editHealthUnit(item)"
               :id="`edit-item-${index}-healthunit-list-button`"
               class="mx-1"
               color="black"
               small
            >
               mdi-pencil
            </v-icon>
            <v-icon
               v-if="!hideRemoveButton"
               @click="removeHealthUnit(item)"
               :id="`remove-item-${index}-healthunit-list-button`"
               color="error"
               class="mx-1"
               small
            >
               mdi-delete
            </v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import healthUnitService from "../../services/healthUnitService";

export default {
   name: "HealthUnitList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   props: {
      municipality: {
         type: Object,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "Diretor",
               class: "primary--text body-2 font-weight-medium",
               value: "director",
            },
            {
               text: "Estado",
               class: "primary--text body-2 font-weight-medium",
               value: "address.state",
            },
            {
               text: "Cidade",
               class: "primary--text body-2 font-weight-medium",
               value: "address.city",
            },

            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "name",
               text: "Ordem alfabética",
            },
         ],

         filters: [
            {
               filter: "name",
               text: "Nome",
            },
            {
               filter: "director",
               text: "Diretor",
            },
            {
               filter: "address.city",
               text: "Cidade",
            },
         ],

         healthUnits: [],

         totalHealthUnits: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "name",
         filterBy: "",

         loadingHealthUnitsFully: true,
         loadingHealthUnitsPartially: true,

         blockClick: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "active",
                  query: true,
               },
            ],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         return query;
      },
   },

   watch: {
      municipality: {
         immediate: true,
         async handler(municipality) {
            if (municipality && municipality.id) {
               this.loadingHealthUnitsFully = true;
               await this.getAllHealthUnits(municipality.id, this.query);
               this.loadingHealthUnitsFully = false;
            }
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllHealthUnits(this.municipality.id, this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllHealthUnits(this.municipality.id, this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllHealthUnits(this.municipality.id, this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllHealthUnits(this.municipality.id, this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllHealthUnits(this.municipality.id, this.query);
      },

      onClickAddHealthUnit(healthUnit) {
         this.$emit("onClickAddHealthUnit", healthUnit);
      },

      onClickViewHealthUnit(healthUnit) {
         this.$emit("onClickViewHealthUnit", healthUnit);
      },

      editHealthUnit(healthUnit) {
         this.$emit("onClickEditHealthUnit", healthUnit);
      },

      removeHealthUnit(healthUnit) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveHealthUnit", healthUnit);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllHealthUnitsByRef() {
         this.loadingHealthUnitsFully = true;
         await this.getAllHealthUnits(this.municipality.id, this.query);
         this.loadingHealthUnitsFully = false;
      },

      async getAllHealthUnits(municipalityId, query) {
         this.loadingHealthUnitsPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await healthUnitService.getAllHealthUnits(
               municipalityId,
               query
            );

            this.totalHealthUnits = parseInt(response.headers["x-total-count"]);
            this.healthUnits = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthUnitsPartially = false;
         }
      },
   },
};
</script>

<style scoped>
</style>