<template>
   <div>
      <!-- Janela de cancelar reserva de um leito -->
      <v-dialog
         v-model="dialogCancelReservationFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeCancelReservationForm" />

            <BedRequestReserveCancelForm
               @onClickCancel="closeCancelReservationForm"
               @onCancelReservation="onCancelReservation"
               :request="request"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <!-- Janela de confirmar desfecho da solicitação -->
      <v-dialog
         v-model="dialogConfirmOutcomeFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeConfirmOutcomeForm" />

            <BedRequestConfirmOutcomeForm
               @onClickCancel="closeConfirmOutcomeForm"
               @onConfirmOutcome="onConfirmOutcome"
               :request="request"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogTransferReservationVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeTransferReservationForm" />

            <BedReserveForm
               @onClickViewBed="onClickViewBed"
               :request="request"
               :healthUnitTransfer="request && request.target_health_unit"
               class="standard-padding"
               alertHelp
            />
         </v-card>
      </v-dialog>

      <LoadingData
         v-if="loadingRequests"
         class="height-config"
         message="Carregando solicitação, aguarde por favor..."
      />

      <div v-else-if="request && request.id">
         <BedRequestActions
            @onClickCancelReservation="onClickCancelReservation"
            @onClickConfirmReservation="onClickConfirmReservation"
            @onClickConfirmArrival="onClickConfirmArrival"
            @onClickConfirmOutcome="onClickConfirmOutcome"
            @onClickTransferReservation="onClickTransferReservation"
            :bed="bed"
            :request="request"
            class="mb-4"
         />

         <RequestOperatorForm
            @onOpenChat="onOpenChat"
            @onCloseChat="onCloseChat"
            :request="request"
            :fullscreenParentDialog="fullscreenParentDialog"
            hideTitle
            hideEditButton
            hideRemoveButton
            showDoctorOpinion
         />
      </div>

      <NoData
         v-else
         class="height-config"
         message="Nenhuma solicitação encontrada."
      />
   </div>
</template>

<script>
import Close from "../../base/Close";
import LoadingData from "../../base/LoadingData";
import RequestOperatorForm from "../../request/form/RequestOperatorForm";
import BedRequestActions from "../../bed/bedRequest/BedRequestActions";
import BedRequestReserveCancelForm from "../bedRequest/BedRequestReserveCancelForm";
import BedRequestConfirmOutcomeForm from "../bedRequest/BedRequestConfirmOutcomeForm";
import NoData from "../../base/NoData";
import requestService from "../../../services/requestService";
import snackBarUtil from "../../../utils/snackBarUtil";
import bedService from "../../../services/bedService";
import responsiveUtil from "../../../utils/responsiveUtil";
import messageService from "services/messageService";
import BedReserveForm from "../../../components/bed/bedReserve/BedReserveForm";

export default {
   name: "BedRequest",

   components: {
      NoData,
      Close,
      LoadingData,
      RequestOperatorForm,
      BedRequestActions,
      BedRequestReserveCancelForm,
      BedRequestConfirmOutcomeForm,
      BedReserveForm,
   },

   props: {
      bed: {
         type: Object,
      },

      fullscreenParentDialog: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         request: null,

         loadingRequests: true,

         dialogCancelReservationFormVisibility: false,
         dialogConfirmOutcomeFormVisibility: false,
         dialogTransferReservationVisibility: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 1,
            filter: [],
         };

         query.filter.push(
            { attribute: "bed_id", query: this.bed.id },
            { attribute: "status", query: "regulated,answered" },
            { attribute: "is_closed", query: "false" }
         );

         return query;
      },
   },

   watch: {
      bed: {
         immediate: true,
         handler(bed) {
            if (bed && bed.id) this.getAllRequests(this.query);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      closeCancelReservationForm() {
         this.dialogCancelReservationFormVisibility = false;
      },

      openCancelReservationForm() {
         this.dialogCancelReservationFormVisibility = true;
      },

      closeConfirmOutcomeForm() {
         this.dialogConfirmOutcomeFormVisibility = false;
      },

      openConfirmOutcomeForm() {
         this.dialogConfirmOutcomeFormVisibility = true;
      },

      closeTransferReservationForm() {
         this.dialogTransferReservationVisibility = false;
      },

      openTransferReservationForm() {
         this.dialogTransferReservationVisibility = true;
      },

      onOpenChat() {
         this.$emit("onOpenChat");
      },

      onCloseChat() {
         this.$emit("onCloseChat");
      },

      async onClickCancelReservation() {
         this.openCancelReservationForm();
      },

      async onClickConfirmReservation() {
         if (
            this.request &&
            this.request.source_health_entity &&
            this.request.source_health_entity.id
         )
            this.updateHealthUnitRequestStatus(
               this.request.source_health_entity.id,
               this.request.id,
               {
                  status: "answered",
               }
            );
      },

      async onClickConfirmArrival() {
         await this.updateStatusBed(this.bed.ward_id, this.bed.id, {
            status: "occupied",
         });
      },

      onClickConfirmOutcome() {
         this.openConfirmOutcomeForm();
      },

      onConfirmOutcome() {
         this.closeConfirmOutcomeForm();
         this.$emit("onUpdatedStatusBed");
      },

      onCancelReservation() {
         this.closeCancelReservationForm();
         this.$emit("onUpdatedStatusBed");
      },

      updateHealthUnitRequestStatus(healthUnitId, requestId, status) {
         if (!healthUnitId || !requestId || !status) return;

         const methodUpdateStatusItem = async () => {
            await requestService.updateHealthUnitRequestStatus(
               healthUnitId,
               requestId,
               status
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message:
                  "A reserva foi confirmada e o status do leito atualizado!",
            });

            this.$emit("onUpdatedStatusBed");
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-bed",
            title: "CONFIRMAÇÃO DE RESERVA",
            message: "Deseja realmente confirmar essa reserva?",
            action: {
               text: "Confirmar",
               method: methodUpdateStatusItem,
               closeOnFinished: false,
            },
         });
      },

      updateStatusBed(wardId, bedId, bed) {
         if (!wardId || !bedId || !bed) return;

         const methodUpdateStatusItem = async () => {
            await bedService.updateStatusBed(wardId, bedId, bed);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O status do leito foi atualizado!",
            });

            this.$emit("onUpdatedStatusBed");
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-bed",
            title: "CONFIRMAÇÃO DE ATUALIZAÇÃO DE STATUS",
            message: "Deseja realmente atualizar o status desse leito?",
            action: {
               text: "Confirmar",
               method: methodUpdateStatusItem,
               closeOnFinished: false,
            },
         });
      },

      async getAllRequests(query) {
         this.loadingRequests = true;

         try {
            const response = await requestService.getAllRequests(query);

            if (response.data.length) {
               await messageService
                  .getRequestMessageInfoById(response.data[0].id, "executor")
                  .then((res) => {
                     response.data[0].unreadMessages =
                        res.data.total_unread_messages;
                  });

               this.request = Object.assign({}, response.data[0]);
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRequests = false;
         }
      },

      onClickTransferReservation() {
         this.openTransferReservationForm();
      },

      onClickViewBed(reserveData) {
         if (!reserveData) return;

         const methodUpdateItem = async () => {
            this.closeTransferReservationForm();

            await bedService.transferBed(
               reserveData.healthUnit.id,
               this.request.id,
               {
                  bed_id: reserveData.bed.id,
               }
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A transferência interna foi realizada com sucesso!",
            });

            this.$emit("onUpdatedStatusBed");
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-bed",
            title: "CONFIRMAÇÃO DE TRANSFERÊNCIA!",
            message: "Deseja realmente fazer a transferência para esse leito?",
            action: {
               text: "Confirmar",
               method: methodUpdateItem,
               closeOnFinished: true,
            },
         });
      },
   },
};
</script>

<style scoped>
.height-config {
   height: 500px;
}
</style>