<template>
   <div>
      <v-alert
         v-if="date"
         class="mb-5"
         color="primary"
         type="info"
         icon="mdi-calendar"
         border="left"
         dense
         text
      >
         <div class="d-flex justify-space-between align-center ma-n2">
            <p class="ma-2 body-2" style="word-break: break-all">
               Você está visualizando as requisições do dia
               <strong>
                  {{ dateTime }}
               </strong>
               de
               <strong>
                  {{ rangeHour.start }}
               </strong>
               até
               <strong> {{ rangeHour.end }}</strong
               >.
            </p>

            <div class="d-flex align-center">
               <v-menu :close-on-content-click="false" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                     <div class="ma-1">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-none d-md-flex"
                           color="primary"
                           x-small
                           text
                           >Alterar data
                        </v-btn>

                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-flex d-md-none"
                           color="primary"
                           fab
                           text
                           x-small
                        >
                           <v-icon>mdi-calendar-edit</v-icon>
                        </v-btn>
                     </div>
                  </template>

                  <v-date-picker
                     @input="onChangeDate"
                     v-model="datePicker"
                     locale="pt-br"
                     no-title
                  >
                  </v-date-picker>
               </v-menu>

               <v-menu :close-on-content-click="false" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                     <div class="ma-1">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-none d-md-flex"
                           color="primary"
                           x-small
                           text
                        >
                           Alterar hora
                        </v-btn>

                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-flex d-md-none"
                           color="primary"
                           fab
                           text
                           x-small
                        >
                           <v-icon>mdi-clock-edit-outline</v-icon>
                        </v-btn>
                     </div>
                  </template>

                  <v-card class="standard-padding" height="144px">
                     <DisplayHoursFilter
                        @onChangeHour="onChangeHour"
                        :rangeHour="hours"
                     >
                     </DisplayHoursFilter>
                  </v-card>
               </v-menu>
            </div>
         </div>
      </v-alert>

      <div class="d-flex justify-space-between align-center">
         <div class="d-flex align-center flex-grow-1">
            <v-menu
               v-model="filterMenuVisibility"
               :close-on-content-click="false"
               transition="slide-y-transition"
               close-on-click
               offset-y
            >
               <template v-slot:activator="{ on, attrs }">
                  <v-btn
                     v-bind="attrs"
                     v-on="on"
                     class="d-flex d-md-none"
                     color="primary"
                     :id="
                        userInfo
                           ? `filter-${userInfo}-secondary-list-header-button`
                           : 'filter-secondary-list-header-button'
                     "
                     fab
                     depressed
                     x-small
                  >
                     <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-btn
                     v-bind="attrs"
                     v-on="on"
                     class="d-none d-md-flex"
                     color="primary"
                     :id="
                        userInfo
                           ? `filter-${userInfo}-primary-list-header-button`
                           : 'filter-primary-list-header-button'
                     "
                     outlined
                  >
                     FILTRO
                     <v-icon right>mdi-filter</v-icon>
                  </v-btn>
               </template>

               <v-card :disabled="loading" class="standard-padding-x-small">
                  <div class="d-flex align-center">
                     <v-icon color="primary" small left>mdi-filter</v-icon>

                     <p class="caption font-weight-bold primary--text">
                        Filtrar por
                     </p>
                  </div>

                  <v-chip-group
                     @change="changeFilter"
                     :value="filter"
                     mandatory
                     column
                  >
                     <v-chip
                        v-for="(item, index) in filters"
                        :key="item.filter"
                        :color="filter === item.filter ? 'primary' : null"
                        :value="item.filter"
                        :id="`filter-item${index}-list-header-chip`"
                        filter
                        small
                     >
                        {{ item.text }}
                     </v-chip>
                  </v-chip-group>

                  <div class="d-flex align-center mt-1">
                     <v-icon color="primary" small left>mdi-sort</v-icon>

                     <p class="caption font-weight-bold primary--text">
                        Listar por
                     </p>
                  </div>

                  <v-chip-group
                     @change="changeSort"
                     :value="sort"
                     class="pa-0"
                     mandatory
                     column
                  >
                     <v-chip
                        v-for="(item, index) in sorts"
                        :key="item.sort"
                        :color="sort === item.sort ? 'primary' : null"
                        :value="item.sort"
                        :id="`sort-item${index}-list-header-chip`"
                        filter
                        small
                     >
                        {{ item.text }}
                     </v-chip>
                  </v-chip-group>
               </v-card>
            </v-menu>

            <UserInput
               v-if="filter === 'user.id'"
               @onSelectUser="onSelectUser"
               class="search-bar-patient"
            />

            <div
               v-else-if="
                  filter === 'success&request.resource&request.operator'
               "
               class="d-flex align-center w-100"
            >
               <ResourceOperatorAndSuccessFilter
                  @onFilter="onfilterByResourceOperatorAndSuccess"
                  class="search-bar-multiple-filters"
                  ref="resourceoperatorandsuccessfilter"
               />
            </div>

            <v-text-field
               v-else
               v-model="filterBy"
               v-maska="filterMask"
               @click:append="
                  filterBy && filterBy !== '' ? searchItems() : null
               "
               :placeholder="`Digite o(a) ${filterText} do(a) ${filterActor}...`"
               class="search-bar body-2"
               append-icon="mdi-magnify"
               :maxlength="filterMaxLength"
               :id="
                  userInfo
                     ? `filter-${userInfo}-by-list-header-input`
                     : 'filter-by-list-header-input'
               "
            ></v-text-field>

            <v-tooltip :value="filterBy !== '' ? true : false" top>
               <template v-slot:activator="{ on, attrs }">
                  <v-btn
                     v-bind="attrs"
                     v-on="on"
                     @click="
                        filterBy = null;
                        clearFilter();
                     "
                     height="36px"
                     width="36px"
                     icon
                  >
                     <v-icon>mdi-delete</v-icon>
                  </v-btn>
               </template>
               <p>Limpar filtro</p>
            </v-tooltip>
         </div>

         <div v-if="!hideAddButton">
            <v-btn
               @click="add"
               class="d-flex d-md-none"
               color="primary"
               id="add-secondary-list-header-button"
               fab
               depressed
               x-small
            >
               <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
               @click="add"
               class="d-none d-md-flex"
               color="primary"
               id="add-primary-list-header-button"
               depressed
            >
               ADICIONAR
               <v-icon right>mdi-plus</v-icon>
            </v-btn>
         </div>
      </div>
   </div>
</template>

<script>
import DisplayHoursFilter from "./DisplayHoursFilter";
import UserInput from "components/users/UserInput";
import responsiveUtil from "utils/responsiveUtil";
import ResourceOperatorAndSuccessFilter from "./ResourceOperatorAndSuccessFilter";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "utils/validationUtil";
import moment from "moment";

const SEARCH_DELAY = 800;

export default {
   name: "AuditListHeader",

   components: {
      UserInput,
      ResourceOperatorAndSuccessFilter,
      DisplayHoursFilter,
   },

   props: {
      filterActor: {
         type: String,
         default: "default",
      },

      date: {
         type: Array,
      },

      filter: {
         type: String,
         default: "name",
      },

      filters: {
         type: Array,
         default: () => [],
      },

      sort: {
         type: String,
         default: "-created_at",
      },

      sorts: {
         type: Array,
         default: () => [],
      },

      loading: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      userInfo: {
         type: String,
         default: null,
      },
   },

   data: function () {
      return {
         filterBy: "",

         filterTimeout: null,

         dialogFilterVisibility: false,
         filterMenuVisibility: false,

         hours: [
            formatUtil.formatDate(moment(this.date[0]), "HH:mm"),
            formatUtil.formatDate(
               moment(this.date[this.date.length - 1]),
               "HH:mm"
            ),
         ],

         dateTime: null,
         rangeHour: {
            start: null,
            end: null,
         },
      };
   },

   computed: {
      datePicker: {
         get() {
            if (validationUtil.dateValidation(this.dateTime, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.dateTime,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.dateTime = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },

      filterText() {
         return this.filters
            .find((item) => item.filter === this.filter)
            .text.toLowerCase();
      },

      filterMask() {
         let mask = {
            mask: "Z*",
            tokens: {
               Z: { pattern: /[0-9a-zA-Z!@#$%^&*()_+-={}çãáéúíóâêîôû ]/ },
            },
         };

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask) mask = filterItem.mask;

         return mask;
      },

      filterMaxLength() {
         let length = -1;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.maxLength) length = filterItem.maxLength;

         return length;
      },
   },

   watch: {
      filterBy(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.filterTimeout);
         this.filterTimeout = setTimeout(this.searchItems, SEARCH_DELAY);
      },
   },

   methods: {
      ...formatUtil,
      ...responsiveUtil,
      ...validationUtil,

      resetTime(dateActual) {
         this.dateTime = formatUtil.formatDate(
            moment(dateActual[0]),
            "DD/MM/YYYY"
         );
         this.rangeHour.start = formatUtil.formatDate(
            moment(dateActual[0]),
            "HH:mm"
         );
         this.rangeHour.end = formatUtil.formatDate(
            moment(dateActual[dateActual.length - 1]),
            "HH:mm"
         );
      },

      closeFilterMenu() {
         this.filterMenuVisibility = false;
      },

      changeFilter(filter) {
         this.filterBy = null;
         this.searchItems();

         this.closeFilterMenu();
         this.$emit("onChangeFilter", filter);
      },

      changeSort(sort) {
         this.$emit("onChangeSort", sort);
         this.closeFilterMenu();
      },

      clearFilter() {
         this.$emit("onClearFilter");
      },

      onChangeDate() {
         let time = [
            moment(
               `${this.dateTime} ${this.rangeHour.start}`,
               "DD/MM/YYYY hh:mm"
            ).format(),
            moment(
               `${this.dateTime} ${this.rangeHour.end}`,
               "DD/MM/YYYY hh:mm"
            ).format(),
         ];
         this.$emit("onChangeDate", time.sort());
      },

      onChangeHour(entry) {
         this.rangeHour.start = entry.rangeHour.start;
         this.rangeHour.end = entry.rangeHour.end;
         this.onChangeDate();
      },

      searchItems() {
         let filterByTemp = this.filterBy;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask && filterItem.unmask && filterByTemp)
            filterByTemp = filterByTemp.replace(filterItem.unmask, "");

         this.$emit("onSearchItems", filterByTemp);
      },

      add() {
         this.$emit("onClickAdd");
      },

      onSelectUser(user) {
         this.$emit("onSearchItems", user.id);
      },

      onfilterByResourceOperatorAndSuccess(filter) {
         this.$emit("onSearchItems", filter);
      },

      resourceOperatorAndSuccessClearFilter() {
         if (this.$refs.resourceoperatorandsuccessfilter)
            this.$refs.resourceoperatorandsuccessfilter.reset();
      },
   },
   created: function () {
      this.resetTime(this.date);
   },
};
</script>

<style scoped>
.search-bar {
   max-width: 600px;
   margin: 0px 16px;
}

.search-bar-patient {
   min-width: 600px !important;
   margin: 0px 16px;
}

@media (max-width: 959px) {
   .search-bar-patient {
      min-width: 20px !important;
      margin: 0px 16px;
   }
}

.search-bar-multiple-filters {
   min-width: 600px !important;
   margin-left: 16px;
}

@media (max-width: 959px) {
   .search-bar-multiple-filters {
      min-width: 20px !important;
   }
}
</style>
