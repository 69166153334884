<template>
   <div>
      <v-fade-transition>
         <div v-if="dialogHistoryVisibility" class="overlay-config">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="history-overlay"
               tile
               flat
            >
               <Close class="align-self-end" @onClickClose="closeHistory" />

               <History class="mt-n5 mb-n5" :request="request" />
            </v-card>
         </div>
      </v-fade-transition>

      <v-fade-transition>
         <div v-if="dialogChatVisibility" class="overlay-config">
            <v-card
               :height="fullscreenParentDialog ? '100vh' : '90vh'"
               class="chat-drawer"
               elevation="24"
               tile
               flat
            >
               <Close @onClickClose="closeChat" dense />

               <Chat
                  @onSendMessage="onSendMessage"
                  :request="request"
                  :disable="disableChat"
                  class="standard-padding flex-grow-1 mt-3"
               />
            </v-card>
         </div>
      </v-fade-transition>

      <v-dialog
         v-model="dialogCloseRequestFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="600"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeRequestForm" />

            <CloseForm @onCloseRequest="onCloseRequest" :request="request" />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogSurgeryTypeReserveFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1100"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close @onClickClose="closeSurgeryTypeReserveForm" />

            <SurgeryTypeReserveForm
               @onReserveSurgery="onReserveSurgery"
               :request="request"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <FormTitle
         v-if="!hideTitle"
         icon="mdi-medical-bag"
         title="SOLICITAÇÃO DE CIRURGIA"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="
            request.status === 'rejected' ||
            request.status === 'rejected_by_executor'
         "
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <RequestSummary class="mb-n3" :request="formRequest" />

      <v-expand-transition>
         <v-card class="mt-5 standard-padding" outlined>
            <ViewPatient :patient="request.patient" />
         </v-card>
      </v-expand-transition>

      <v-expansion-panels
         v-if="request.status === 'answered' && request.surgery"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               Agendamento da cirurgia
            </v-expansion-panel-header>

            <v-expansion-panel-content class="mt-n1">
               <v-card flat>
                  <ViewSchedule :schedule="request.surgery" />
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form
         :readonly="getMode() === 'read' || !user || (user && !user.crm)"
         ref="form"
         id="doctor-opinion"
      >
         <v-expansion-panels v-model="regulationData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Parecer médico
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-alert
                     v-if="!user || (user && !user.crm)"
                     class="caption"
                     icon="mdi-alert"
                     type="warning"
                     border="left"
                     dense
                     text
                     >Somente regulador médico pode alterar o
                     <strong>"Parecer médico"</strong>.
                  </v-alert>

                  <v-card
                     :disabled="
                        getMode() === 'read' || !user || (user && !user.crm)
                     "
                     flat
                  >
                     <DoctorOpinionForm
                        :doctorOpinion="formRequest.doctor_opinion"
                        ref="doctoropinion"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-card flat>
         <p class="body-1 font-weight-medium mt-6 mb-3">
            Documentos(s)
            <v-icon>mdi-paperclip</v-icon>
         </p>

         <RequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            hideRemoveButton
            hideAddButton
         />
      </v-card>

      <SurgeryRequestRegulatorFormActions
         v-if="!hideActions"
         @onClickHistory="onClickHistoryRequest"
         @onClickPrint="onClickPrintRequest"
         @onClickClose="onClickCloseRequest"
         @onClickChat="onClickChatRequest"
         @onClickEdit="onClickEditRequest"
         @onClickReserveBed="onClickReserveBed"
         @onClickRemove="onClickRemoveRequest"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRequest"
         @onClickCheck="onClickCheck"
         :mode="getMode()"
         :hideCheckButton="hideCheckButton"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :hideReserveBedButton="hideReserveBedButton"
         :hideCloseButton="hideCloseButton"
         :unreadMessages="unreadMessages"
         :loadingSave="loadingSaveRequest"
         :loadingCheck="loadingCheckRequest"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import Close from "../../../components/base/Close";
import History from "components/requestBase/history/History";
import Chat from "components/requestBase/chat/Chat";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import RequestAttachments from "components/requestBase/subForm/RequestAttachments";
import SurgeryRequestRegulatorFormActions from "../../../components/surgeryRequest/form/SurgeryRequestRegulatorFormActions";
import DoctorOpinionForm from "../../../components/surgeryRequest/form/subForm/DoctorOpinionForm";
import SurgeryTypeReserveForm from "../../../components/surgeryType/surgeryTypeReserve/SurgeryTypeReserveForm";
import ViewPatient from "components/patient/ViewPatient";
import RequestSummary from "components/surgeryRequest/form/subForm/RequestSummary";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import CloseForm from "./subForm/CloseForm";
import ViewSchedule from "./subForm/ViewSchedule.vue";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import snackBarUtil from "../../../utils/snackBarUtil";
import responsiveUtil from "../../../utils/responsiveUtil";
import util from "../../../utils/util";
import requestPdfExport from "export/pdf/requestPdfExport";
import adminService from "services/adminService";
import managerService from "services/managerService";
import healthUnitManagerService from "services/healthUnitManagerService";
import operatorService from "services/operatorService";
import regulatorManagerService from "services/regulatorManagerService";
import regulatorService from "services/regulatorService";
import requestSurgeryService from "services/requestSurgeryService";
import { ALLOWED_TYPES, MAXIMUM_SIZE } from "utils/defaultUtil";
import basePdfExport from "export/pdf/basePdfExport";

export default {
   name: "SurgeryRequestRegulatorForm",

   components: {
      Close,
      FormTitle,
      History,
      Chat,
      OriginAndDestinyDetails,
      RequestAttachments,
      SurgeryRequestRegulatorFormActions,
      DoctorOpinionForm,
      SurgeryTypeReserveForm,
      ViewPatient,
      RequestSummary,
      OutcomeDetails,
      JustificationRejection,
      ViewSchedule,
      CloseForm,
   },

   props: {
      request: {
         type: Object,
      },

      /* Pode ser um regulator ou um regulatorManager */
      user: {
         type: Object,
      },

      hideActions: {
         type: Boolean,
         default: false,
      },

      hideCheckButton: {
         type: Boolean,
         default: false,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideReserveBedButton: {
         type: Boolean,
         default: false,
      },

      hideCloseButton: {
         type: Boolean,
         default: true,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },

      fullscreenParentDialog: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",
         formRequest: {},

         loadingSaveRequest: false,
         loadingCheckRequest: false,

         requestData: 0,
         regulationData: [],

         dialogSurgeryTypeReserveFormVisibility: false,
         dialogCloseRequestFormVisibility: false,
         dialogChatVisibility: false,

         dialogHistoryVisibility: false,
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      unreadMessages() {
         if (this.request.unreadMessages) return this.request.unreadMessages;
         else return 0;
      },

      disableChat() {
         return this.request.is_closed;
      },
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id && !this.editMode) {
               this.setMode("read");
            } else this.setMode("save");

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,
      ...responsiveUtil,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject(
            {
               doctor_opinion: {
                  patient_not_fit_for_regulation: false,
               },
            },
            request
         );

         return requestTemp;
      },

      formatObjectToSend(request) {
         let requestTemp = util.cloneObject(request);

         return util.removeBlankAttributes(requestTemp);
      },

      onClickChatRequest() {
         this.openChat();
      },

      onClickHistoryRequest() {
         this.openHistory();
      },

      async getUserById(user) {
         let response;

         switch (user.sub_type) {
            case "admin":
               response = await adminService.getAdminById(user.sub);
               break;
            case "manager":
               response = await managerService.getManagerById(user.sub);
               break;
            case "healthunit_manager":
               response =
                  await healthUnitManagerService.getHealthUnitManagerById(
                     user.sub
                  );
               break;
            case "solicitator":
            case "executor":
               response = await operatorService.getOperatorById(user.sub);
               break;
            case "regulator_manager":
               response = await regulatorManagerService.getRegulatorManagerById(
                  user.sub
               );
               break;
            case "regulator":
               response = await regulatorService.getRegulatorById(user.sub);
               break;
         }

         return response;
      },

      async onClickPrintRequest() {
         this.loadingPrint = true;

         try {
            const userResponse = await this.getUserById(this.userStore);

            const pdf = requestPdfExport.generateSurgeryRequestPDF(
               this.formRequest,
               userResponse.data.name
            );

            basePdfExport.openPDFNewWindow(pdf, "Solicitação");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPrint = false;
         }
      },

      onClickCloseRequest() {
         this.openRequestForm();
      },

      onClickEditRequest() {
         this.setMode("save");
      },

      onClickReserveBed() {
         this.openSurgeryTypeReserveForm();
      },

      onClickRemoveRequest() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formRequest = this.formatObjectToView(this.request);
         this.$emit("onClickCancel");
      },

      onClickSaveRequest() {
         if (this.formRequest.id)
            this.updateRequest(this.formRequest.id, this.formRequest);
      },

      onClickCheck() {
         if (this.formRequest.id) this.checkRequest(this.formRequest.id);
      },

      onReserveSurgery(reserveSurgery) {
         const methodUpdateItem = async () => {
            this.formRequest.targethealthunit_id = reserveSurgery.healthunit_id;

            if (this.request.id) {
               /* Remove o doctor_opinion do objeto, caso contrário dá erro para o regulador sem CRM. */
               const requestWithoutDoctorOpinion = util.cloneObject(
                  this.formRequest
               );
               delete requestWithoutDoctorOpinion.doctor_opinion;

               await this.updateRequest(
                  this.formRequest.id,
                  requestWithoutDoctorOpinion
               );
            }
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            icon: "mdi-medical-bag",
            title: "CONFIRMAÇÃO DE RESERVA!",
            message: "Deseja realmente reservar essa cirurgia?",
            action: {
               text: "Confirmar",
               method: methodUpdateItem,
               closeOnFinished: false,
            },
         });
      },

      onCloseRequest() {
         this.closeRequestForm();
         this.$emit("onCloseRequest");
      },

      setMode(mode) {
         this.mode = mode;

         if (mode === "read") {
            this.requestData = 0;
            this.regulationData = null;
         } else {
            this.requestData = null;
            this.regulationData = 0;

            var formDoctorOpinion = document.getElementById("doctor-opinion");
            if (formDoctorOpinion)
               formDoctorOpinion.scrollIntoView({ behavior: "smooth" });
         }
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         let justificationValidation = true;
         let doctorOpinionValidation = true;

         if (this.$refs.form)
            justificationValidation = this.$refs.form.validate();
         if (this.$refs.doctoropinion)
            doctorOpinionValidation = this.$refs.doctoropinion.validate();

         return justificationValidation && doctorOpinionValidation;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();

         if (this.$refs.doctoropinion)
            this.$refs.doctoropinion.resetValidation();
      },

      openSurgeryTypeReserveForm() {
         this.dialogSurgeryTypeReserveFormVisibility = true;
      },

      closeSurgeryTypeReserveForm() {
         this.dialogSurgeryTypeReserveFormVisibility = false;
      },

      openRequestForm() {
         this.dialogCloseRequestFormVisibility = true;
      },

      closeRequestForm() {
         this.dialogCloseRequestFormVisibility = false;
      },

      openHistory() {
         this.dialogHistoryVisibility = true;
         this.$emit("onOpenHistory");
      },

      closeHistory() {
         this.dialogHistoryVisibility = false;
         this.$emit("onCloseHistory");
      },

      openChat() {
         this.dialogChatVisibility = true;
         this.$emit("onOpenChat");
      },

      closeChat() {
         this.dialogChatVisibility = false;
         this.$emit("onCloseChat");
      },

      onSendMessage(request, message) {
         this.$emit("onSendMessage", request, message);
      },

      async updateRequest(requestId, request) {
         if (!this.executeValidation() || !requestId || !request) return;

         this.loadingSaveRequest = true;

         try {
            await requestSurgeryService.updateSurgeryRequest(
               requestId,
               this.formatObjectToSend(request)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRequest = false;
         }
      },

      async checkRequest(requestId) {
         if (!requestId) return;

         this.loadingCheckRequest = true;

         try {
            await requestSurgeryService.checkSurgeryRequest(requestId);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi validada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingCheckRequest = false;
         }
      },
   },
};
</script>

<style scoped>
.overlay-config {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.history-overlay {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 1000 !important;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}
</style>
