<template>
   <div class="form-actions-position">
      <div v-if="mode === 'read'" class="d-flex justify-end align-center ma-n1">
         <v-btn
            @click="print"
            :loading="loadingPrint"
            class="shadow-floating-black-button ma-1"
            color="normal"
            id="print-secondary-form-button"
            fab
            dark
            depressed
            x-small
         >
            <v-icon small>mdi-printer</v-icon>
         </v-btn>

         <v-badge
            v-if="!hideChatButton"
            :value="unreadMessages"
            :content="unreadMessages"
            color="warning"
            left
            overlap
         >
            <v-btn
               @click="chat"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1"
               color="primary"
               id="chat-primary-form-button"
               depressed
               dark
               >Chat
               <v-icon right small>mdi-forum</v-icon>
            </v-btn>
            <v-btn
               @click="chat"
               class="shadow-floating-primary-button d-flex d-sm-none ma-1"
               color="primary"
               id="chat-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-forum</v-icon>
            </v-btn>
         </v-badge>

         <div v-if="!hideEditButton">
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-none d-sm-flex ma-1"
               color="normal"
               id="edit-primary-form-button"
               depressed
               dark
               >Editar
               <v-icon right small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-flex d-sm-none ma-1"
               color="normal"
               id="edit-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-pencil</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideRemoveButton">
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-none d-sm-flex ma-1"
               color="error"
               id="remove-primary-form-button"
               depressed
               >Remover
               <v-icon right small>mdi-delete</v-icon>
            </v-btn>
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-flex d-sm-none ma-1"
               color="error"
               id="remove-secondary-form-button"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-delete</v-icon>
            </v-btn>
         </div>
      </div>

      <div v-else class="d-flex justify-end align-center ma-n1">
         <v-alert
            v-model="activeTooltip"
            class="caption alert-config ma-1"
            color="error"
            border="left"
            dense
            dark
         >
            <div class="d-none d-sm-flex">
               <p class="alert-text-config">
                  <v-icon small left>mdi-alert</v-icon>
                  <strong>Campos inválidos:</strong>
                  {{ errors.join(", ") }}
                  {{ validationErrors.length > 3 ? "..." : "." }}
               </p>
            </div>

            <div class="d-flex d-sm-none">
               <p class="alert-text-config">
                  <v-icon small left>mdi-alert</v-icon>
                  <strong>Campos inválidos!</strong>
               </p>
            </div>
         </v-alert>

         <v-btn
            @click="cancel"
            class="
               shadow-floating-primary-light-button
               d-none d-sm-flex
               primary--text
               ma-1
            "
            color="primaryLight"
            id="cancel-primary-form-button"
            depressed
            >Cancelar
         </v-btn>
         <v-btn
            @click="cancel"
            class="shadow-floating-primary-light-button d-flex d-sm-none ma-1"
            color="primaryLight"
            id="cancel-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small color="primary">mdi-close</v-icon>
         </v-btn>

         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-none d-sm-flex ma-1"
            color="primary"
            id="save-primary-form-button"
            depressed
            >Salvar
            <v-icon right small>mdi-check</v-icon>
         </v-btn>
         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-flex d-sm-none ma-1"
            color="primary"
            id="save-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   name: "SurgeryRequestOperatorFormActions",

   props: {
      mode: {
         type: String,
         default: "save"
      },

      unreadMessages: {
         type: Number,
         default: 0
      },

      hideChatButton: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      loadingSave: {
         type: Boolean,
         default: false
      },

      loadingPrint: {
         type: Boolean,
         default: false
      },

      validationErrors: {
         type: Array,
         default: () => []
      }
   },

   data: function() {
      return {
         activeTooltip: false
      };
   },

   computed: {
      errors() {
         return this.validationErrors.filter((item, index) => index < 3);
      }
   },

   watch: {
      validationErrors: {
         immediate: true,
         handler(validationErrors) {
            if (validationErrors.length) this.openTooltipValitation();
            else this.closeTooltipValitation();
         }
      }
   },

   methods: {
      chat() {
         this.$emit("onClickChat");
      },

      print() {
         this.$emit("onClickPrint");
      },

      edit() {
         this.$emit("onClickEdit");
      },

      remove() {
         this.$emit("onClickRemove");
      },

      cancel() {
         this.$emit("onClickCancel");
      },

      openTooltipValitation() {
         this.activeTooltip = true;
      },

      closeTooltipValitation() {
         this.activeTooltip = false;
      },

      save() {
         this.$emit("onClickSave");
      }
   }
};
</script>

<style scoped>
.form-actions-position {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

@media (max-width: 959px) {
   .form-actions-position {
      bottom: 16px;
   }
}

.alert-config {
   height: 36px;
   margin-bottom: 0px;
   margin-left: auto;
}

.alert-text-config {
   height: 20px !important;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>
