var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideTitle)?_c('FormTitle',{attrs:{"icon":"mdi-medical-bag","title":"SOLICITAÇÃO CIRÚRGICA"}}):_vm._e(),_c('v-form',{ref:"form",attrs:{"readonly":_vm.getMode() === 'read'}},[_c('div',[_c('p',{staticClass:"caption text--secondary"},[_vm._v("Nome")]),_c('div',{staticClass:"d-flex align-center flex-wrap ma-n1"},[_c('p',{staticClass:"body-2 ma-1"},[_vm._v(_vm._s(_vm.formSurgery.name))]),_c('v-chip',{staticClass:"ma-1",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(_vm.formSurgery.sus_code)+" ")])],1)]),_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"caption text--secondary"},[_vm._v("Status da cirurgia")]),_c('div',{staticClass:"d-flex align-center flex-wrap ma-n1"},[_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.generateSurgeryStatus(_vm.formSurgery.status).color,"small":""}},[_vm._v(" "+_vm._s(_vm.generateSurgeryStatus(_vm.formSurgery.status).text)+" "+_vm._s(_vm.surgery.execution_date ? ("em " + (_vm.formatDate(_vm.surgery.execution_date))) : _vm.surgery.scheduling_date ? ("para " + (_vm.formatDate(_vm.surgery.scheduling_date))) : "")+" ")])],1)]),_c('v-card',{attrs:{"disabled":_vm.getMode() === 'read',"flat":""}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"rules":[
                     function (value) { return !!value || 'Nome do médico obrigatório.'; } ],"id":"phone-surgery-form-input","label":"Nome do médico*"},model:{value:(_vm.formSurgery.doctor_name),callback:function ($$v) {_vm.$set(_vm.formSurgery, "doctor_name", $$v)},expression:"formSurgery.doctor_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"rules":[
                           function (value) { return !!value || 'Data de agendamento obrigatória.'; },
                           function (value) { return _vm.dateValidation(value) || 'Data inválida.'; } ],"append-icon":"mdi-calendar","id":"birth_date-surgery-form-input","label":"Data de agendamento*"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true}],null,true),model:{value:(_vm.formSurgery.scheduling_date),callback:function ($$v) {_vm.$set(_vm.formSurgery, "scheduling_date", $$v)},expression:"formSurgery.scheduling_date"}})]}}])},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"locale":"pt-br","no-title":""},model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"notes-height",attrs:{"label":"Observações","rows":"5","no-resize":""},model:{value:(_vm.formSurgery.notes),callback:function ($$v) {_vm.$set(_vm.formSurgery, "notes", $$v)},expression:"formSurgery.notes"}})],1)],1)],1)],1),_c('SurgeryFormActions',{staticClass:"mt-6",attrs:{"editButtonIcon":_vm.surgery.status === 'pending' ? 'mdi-calendar' : 'mdi-pencil',"editButtonText":_vm.surgery.status === 'pending' ? 'Agendar' : 'Editar',"mode":_vm.getMode(),"hideEditButton":_vm.hideEditButton,"hideDoneButton":_vm.surgery.status !== 'scheduled',"hideCancelSurgeryButton":_vm.surgery.status !== 'scheduled',"hideRemoveButton":!_vm.surgeryRequest.id ||
         _vm.surgeryRequest.is_closed ||
         _vm.surgery.status !== 'pending',"hideOutcomeButton":!_vm.surgeryRequest.id ||
         _vm.surgeryRequest.is_closed ||
         _vm.surgery.status !== 'done',"loadingSave":_vm.loadingSaveSurgery,"validationErrors":_vm.validationErrors},on:{"onClickEdit":_vm.onClickEditSurgery,"onClickRemove":function($event){return _vm.onClickRemoveSurgery(_vm.surgery)},"onClickCancel":_vm.onClickCancel,"onClickDone":_vm.onClickDoneSurgery,"onClickCancelSurgery":function($event){return _vm.onClickCancelSurgery(_vm.surgery)},"onClickOutcome":_vm.onClickOutcomeSurgery,"onClickSave":_vm.onClickSaveSurgery}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }