<template>
   <div>
      <v-dialog
         v-model="dialogActivateUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card>
            <ActivateUserForm
               @onActivateUser="
                  closeActivateUserForm();
                  refreshRegulatorManagerList();
               "
               :user="disableRegulatorManager"
               userType="gestor regulador"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="regulatorManagerResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshRegulatorManagerList();
               "
               :user="regulatorManagerChangeTypeSelected"
               currentType="regulator_manager"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogRegulatorManagerFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeRegulatorManagerForm" />

            <RegulatorManagerForm
               @onClickCancel="closeRegulatorManagerForm"
               @onCreatedRegulatorManager="
                  closeRegulatorManagerForm();
                  refreshRegulatorManagerList();
               "
               @onUpdatedRegulatorManager="
                  closeRegulatorManagerForm();
                  refreshRegulatorManagerList();
               "
               @onClickResetRegulatorManager="onClickResetRegulatorManager"
               @onClickRemoveRegulatorManager="onClickRemoveRegulatorManager"
               @onFindDisabledRegulatorManager="onFindDisabledRegulatorManager"
               @onClickChangeType="onClickChangeType"
               :regulatorManager="regulatorManagerSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <RegulatorManagerList
         @onClickAddRegulatorManager="onClickAddRegulatorManager"
         @onClickViewRegulatorManager="onClickViewRegulatorManager"
         @onClickEditRegulatorManager="onClickEditRegulatorManager"
         @onClickRemoveRegulatorManager="onClickRemoveRegulatorManager"
         class="mt-12"
         ref="regulatorManagerlist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import RegulatorManagerList from "../../components/regulatorManager/RegulatorManagerList";
import RegulatorManagerForm from "../../components/regulatorManager/RegulatorManagerForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import ActivateUserForm from "components/users/ActivateUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm.vue";

export default {
   name: "RegulatorManager",

   components: {
      Close,
      PageTitle,
      RegulatorManagerList,
      RegulatorManagerForm,
      ResetPasswordUsersForm,
      ActivateUserForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         regulatorManagerSelected: {},
         regulatorManagerResetPasswordSelected: {},
         regulatorManagerChangeTypeSelected: {},
         disableRegulatorManager: {},

         editMode: false,

         dialogRegulatorManagerFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogActivateUserFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogRegulatorManagerFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddRegulatorManager(regulatorManager) {
         this.regulatorManagerSelected = Object.assign({}, regulatorManager);
         this.openRegulatorManagerForm();
      },

      onClickViewRegulatorManager(regulatorManager) {
         this.regulatorManagerSelected = Object.assign({}, regulatorManager);
         this.openRegulatorManagerForm();
      },

      onClickResetRegulatorManager(regulatorManager) {
         this.regulatorManagerResetPasswordSelected = Object.assign(
            {},
            regulatorManager
         );
         this.openResetPasswordForm();
      },

      onClickEditRegulatorManager() {
         this.editMode = true;
      },

      onClickChangeType(regulatorManager) {
         this.regulatorManagerChangeTypeSelected = Object.assign(
            {},
            regulatorManager
         );
         this.closeRegulatorManagerForm();
         this.openChangeTypeUserForm();
      },

      onClickRemoveRegulatorManager(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, { active: false });

            this.closeRegulatorManagerForm();
            this.refreshRegulatorManagerList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor regulador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse gestor regulador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledRegulatorManager(item) {
         this.disableRegulatorManager = Object.assign({}, item);

         this.closeRegulatorManagerForm();
         this.openActivateUserForm();
      },

      openRegulatorManagerForm() {
         this.dialogRegulatorManagerFormVisibility = true;
      },

      closeRegulatorManagerForm() {
         this.dialogRegulatorManagerFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      openActivateUserForm() {
         this.dialogActivateUserFormVisibility = true;
      },

      closeActivateUserForm() {
         this.dialogActivateUserFormVisibility = false;
      },

      refreshRegulatorManagerList() {
         this.$refs.regulatorManagerlist.refreshAllRegulatorManagersByRef();
      }
   }
};
</script>

<style scoped></style>
