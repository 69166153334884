<template>
   <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="config"
   >
      <path
         d="M5.1,11.9c-0.3,0.3-0.5,0.7-0.6,1.2l5.5,2.4L18.5,7c0.8-0.8,0.8-2.1,0-2.8l-1.4-1.4c-0.8-0.8-2-0.8-2.8,0L5.1,11.9 M2.8,16
	v5.8l5.8-5.2l-5-2L2.8,16 M15,4.8c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0C14.6,5.9,14.6,5.2,15,4.8z"
      />
      <polyline
         points="19.3,14.2 20.7,15.6 14.1,22.2 10.7,18.7 12.1,17.3 14.1,19.4 19.3,14.2 "
      />
   </svg>
</template>

<script>
export default {
   name: "BoxCutterCheck",
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>