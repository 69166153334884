<template>
   <div>
      <v-dialog
         v-if="showAvaliableSurgeries"
         v-model="dialogAvaliableSurgeriesVisibility"
         max-width="800"
         transition="slide-y-transition"
      >
         <Close @onClickClose="closeAvaliableSurgeriesForm" />

         <v-card class="standard-padding-small pb-7">
            <div class="d-flex justify-center align-center mb-5">
               <v-icon color="primary" size="20" left>$box-cutter-check</v-icon>
               <p class="primary--text font-weight-medium">Cirurgias aceitas</p>
            </div>

            <v-data-table
               @click:row="fillAutocomplete"
               :headers="avaliableSurgeriesHeaders"
               :items="avaliableSurgeries"
               :items-per-page="limit"
               :loading="loadingAvaliableSurgeries"
               hide-default-footer
               hide-default-header
               loader-height="0px"
               mobile-breakpoint="0"
               dense
            >
               <template v-slot:footer>
                  <ListFooter
                     @onChangePage="onChangePage"
                     @onChangeLimit="onChangeLimit"
                     :page="page"
                     :limit="limit"
                     :totalItems="totalAvaliableSurgeries"
                     :loading="loadingAvaliableSurgeries"
                  />
               </template>

               <template v-slot:no-data>
                  <div class="standard-padding">
                     <NoData
                        message="Desculpe, nenhuma cirurgia aceita foi encontrada."
                     />
                  </div>
               </template>

               <template v-slot:loading>
                  <div class="standard-padding">
                     <LoadingData
                        message="Carregando cirurgias aceitas, aguarde por favor."
                     />
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-dialog>

      <div class="d-flex align-center">
         <v-autocomplete
            @change="selectSurgerie"
            @keyup.enter="getAllSurgeries"
            v-model.lazy="surgerieSelected"
            :items="surgeriesItems"
            :search-input.sync="surgerieSearchFilter"
            :loading="loadingSurgeries"
            :rules="
               required
                  ? [
                       () =>
                          !!surgerieSelected.sus_code ||
                          'Cirurgia obrigatória.',
                    ]
                  : []
            "
            :label="`Cirurgia${required ? '*' : ''}`"
            item-text="name"
            item-value="id"
            id="surgery-input"
            return-object
            hide-no-data
            hide-details
            cache-items
         >
         </v-autocomplete>

         <div v-if="showAvaliableSurgeries" class="ml-2">
            <v-btn
               @click="openAvaliableSurgeriesForm"
               class="d-none d-sm-flex py-4"
               color="primary"
               id="edit-primary-form-button"
               depressed
               dark
               x-small
               >Cirurgias <br />
               aceitas
            </v-btn>
            <v-btn
               @click="openAvaliableSurgeriesForm"
               class="d-flex d-sm-none"
               color="primary"
               id="edit-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>$box-cutter-check</v-icon>
            </v-btn>
         </div>
      </div>
   </div>
</template>

<script>
import LoadingData from "components/base/LoadingData";
import NoData from "components/base/NoData";
import Close from "components/base/Close";
import ListFooter from "components/base/list/ListFooter";
import surgerieService from "services/surgerieService";
import snackBarUtil from "utils/snackBarUtil";
import responsiveUtil from "utils/responsiveUtil";
import avaliableSurgeryService from "services/avaliableSurgeryService";

export default {
   name: "SurgerieInput",

   components: { LoadingData, NoData, Close, ListFooter },

   props: {
      surgerySusCode: {
         type: String,
      },

      showAvaliableSurgeries: {
         type: Boolean,
         default: false,
      },

      required: {
         type: Boolean,
         default: true,
      },
   },

   data: function () {
      return {
         surgerieSelected: {},
         surgeriesItems: [],
         surgerieSearchFilter: "",
         loadingSurgeries: false,
         surgerieTimeout: null,

         /* Avaliable surgeries */
         dialogAvaliableSurgeriesVisibility: false,
         loadingAvaliableSurgeries: false,
         avaliableSurgeries: [],
         avaliableSurgeriesHeaders: [
            {
               value: "name",
            },
         ],
         totalAvaliableSurgeries: 0,
         page: 1,
         limit: 10,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            filter: [],
         };

         if (
            this.surgerieSearchFilter &&
            this.surgerieSearchFilter.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: "name",
               query: this.surgerieSearchFilter,
            });

         return query;
      },
   },

   watch: {
      surgerySusCode: {
         immediate: true,
         handler(surgerySusCode) {
            this.reset();
            if (surgerySusCode) this.getSurgerieBySusCode(surgerySusCode);
         },
      },

      surgerieSearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            this.surgeriesItems.find((item) => item.name === newFilter)
         )
            return;

         clearTimeout(this.surgerieTimeout);
         this.surgerieTimeout = setTimeout(() => {
            this.page = 1;
            this.getAllSurgeries(this.query);
         }, 800);
      },
   },

   methods: {
      ...responsiveUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllAvaliableSurgerys(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      async getAllSurgeries(query) {
         this.loadingSurgeries = true;

         try {
            const response = await surgerieService.getAllSurgeries(query);

            this.surgeriesItems = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeries = false;
         }
      },

      async getSurgerieBySusCode() {
         this.loadingSurgeries = true;

         try {
            const response = await surgerieService.getAllSurgeries({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "sus_code",
                     query: this.surgerySusCode,
                  },
               ],
            });

            this.surgeriesItems = Object.assign([], response.data);
            this.surgerieSelected = Object.assign({}, response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeries = false;
         }
      },

      async getAllAvaliableSurgerys(query) {
         this.loadingAvaliableSurgeries = true;
         this.avaliableSurgeries = [];

         try {
            const response =
               await avaliableSurgeryService.getAllAvaliableSurgeries(query);
            this.totalAvaliableSurgeries = parseInt(
               response.headers["x-total-count"]
            );

            const requestsModifiedStatus = Object.assign([], response.data);

            const promisses = [];
            requestsModifiedStatus.forEach((item) => {
               promisses.push(
                  surgerieService
                     .getAllSurgeries({
                        page: 1,
                        limit: 1,
                        filter: [
                           {
                              attribute: "sus_code",
                              query: item.sus_code,
                           },
                        ],
                     })
                     .then((response) => {
                        this.avaliableSurgeries.push(response.data[0]);
                     })
               );
            });

            Promise.all(promisses).then(() => {
               this.loadingAvaliableSurgeries = false;
            });
         } catch (error) {
            this.loadingAvaliableSurgeries = false;
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      reset() {
         this.surgerieSelected = {};
      },

      selectSurgerie(surgerie) {
         if (surgerie && surgerie.name && surgerie.sus_code)
            this.$emit("onSelectSurgerie", surgerie);
      },

      fillAutocomplete(surgerie) {
         this.surgeriesItems = Object.assign([], [surgerie]);
         this.surgerieSelected = Object.assign({}, surgerie);
         this.selectSurgerie(surgerie);
         this.closeAvaliableSurgeriesForm();
      },

      openAvaliableSurgeriesForm() {
         this.dialogAvaliableSurgeriesVisibility = true;
         this.getAllAvaliableSurgerys(this.query);
      },

      closeAvaliableSurgeriesForm() {
         this.dialogAvaliableSurgeriesVisibility = false;
      },
   },
};
</script>

<style scoped>
.topic-dot {
   height: 5px;
   width: 5px;
   background-color: rgb(59, 59, 59);
   border-radius: 50%;
   display: inline-block;
   margin-right: 3px;
   margin-bottom: 2px;
}
</style>