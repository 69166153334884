<template>
   <div>
      <OperatorHealthUnitsSkeleton v-if="!healthUnit.id" />

      <v-list-item v-else class="px-0" two-line>
         <v-icon class="mr-4" color="primary" large
            >mdi-hospital-building</v-icon
         >

         <v-list-item-content>
            <v-list-item-title>{{ healthUnit.name }}</v-list-item-title>

            <v-list-item-subtitle>
               {{ healthUnit.address.street }}, {{ healthUnit.address.number }},
               {{ healthUnit.address.district }}
            </v-list-item-subtitle>
         </v-list-item-content>

         <v-chip-group 
            v-model="capabilitiesComputed"
            @change="changeCapabilities"
            multiple 
            column
         >
            <v-chip 
               :disabled="this.disabled"
               :color="capabilitiesComputed.includes('can_access_beds') ? 'primary' : 'normal'" 
               :class="capabilitiesComputed.includes('can_access_beds') ? 'elevation-4' : 'elevation-0'"
               class="chip_adjusts pa-2"
               value="can_access_beds" 
               label 
               filter 
               large
            >
               <div class="d-flex justify-space-between align-center">
                  <p class="d-none d-md-flex ma-0 mr-2 chip-text">leitos</p>
                  <v-icon>mdi-bed</v-icon>
               </div>
            </v-chip>
            <v-chip 
               :disabled="this.disabled"
               :color="capabilitiesComputed.includes('can_access_surgeries') ? 'primary' : 'normal'"
               :class="capabilitiesComputed.includes('can_access_surgeries') ? 'elevation-4': 'elevation-0'" 
               class="chip_adjusts pa-2"
               value="can_access_surgeries" 
               label 
               filter 
               large
            >
               <div class="d-flex justify-space-between align-center">
                  <p class="d-none d-md-flex ma-0 mr-2 chip-text">cirurgias</p>
                  <v-icon>mdi-box-cutter</v-icon>
               </div>
            </v-chip>
         </v-chip-group>

         <div>
            <v-btn
               :disabled="this.disabled"
               @click="remove(healthUnit)"
               class="d-none d-sm-flex ma-1"
               color="error"
               id="disassociate-operator-health-units"
               depressed
               small
               >Desassociar
               <v-icon right x-small>mdi-delete</v-icon>
            </v-btn>
            <v-btn
               :disabled="this.disabled"
               @click="remove(healthUnit)"
               class="d-flex d-sm-none ma-1"
               color="error"
               id="disassociate_small-operator-health-units"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-delete</v-icon>
            </v-btn>
         </div>
      </v-list-item>
   </div>
</template>

<script>
import OperatorHealthUnitsSkeleton from "./OperatorHealthUnitsSkeleton";
import healthUnitService from "../../../services/healthUnitService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "OperatorHealthUnitsDetails",

   components: { OperatorHealthUnitsSkeleton },

   props: {
      health_unit: {
         type: Object,
         default: null,
      },

      disabled: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         healthUnit: {},
         access_capabilities: {
            can_access_beds: this.health_unit.capabilities.includes("beds"),
            can_access_surgeries: this.health_unit.capabilities.includes("surgeries")
         }
      };
   },

   watch: {
      health_unit: {
         immediate: true,
         handler(health_unit) {
            if (health_unit) this.getHealthUnitById(health_unit);
         },
      },
   },

   computed: {
      capabilitiesComputed: {
         get() {
            let result = [];

            if (this.access_capabilities) {
               if (this.access_capabilities.can_access_beds)
                  result.push("can_access_beds");
               if (this.access_capabilities.can_access_surgeries)
                  result.push("can_access_surgeries");
            }

            return result;
         },

         set(value) {
            this.access_capabilities.can_access_beds =
               value.includes("can_access_beds");
            this.access_capabilities.can_access_surgeries =
               value.includes("can_access_surgeries");
         },
      }
   },

   methods: {
      reset(capabilities) {
         this.access_capabilities.can_access_beds = capabilities.includes("beds");
         this.access_capabilities.can_access_surgeries = capabilities.includes("surgeries");
      },

      remove(healthUnit) {
         this.$emit("onDisassociateHealthUnit", healthUnit);
      },
      
      async changeCapabilities() {
         const capabilities = [];

         if(this.access_capabilities.can_access_surgeries)
            capabilities.unshift("surgeries");
         if(this.access_capabilities.can_access_beds)
            capabilities.unshift("beds");

         this.$emit("changeCapabilities", {
            id: this.health_unit.id,
            capabilities
         });
      },

      async getHealthUnitById(health_unit) {
         try {
            const response = await healthUnitService.getHealthUnitById(
               health_unit.id
            );

            this.healthUnit = Object.assign({}, response.data);

            this.capabilities = {
               can_access_beds: this.health_unit.capabilities.includes("beds"),
               can_access_surgeries: this.health_unit.capabilities.includes("surgeries")
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>

.chip-text{
   font-weight: 500;
   text-transform: uppercase;
   letter-spacing: 0.089em;
   font-size: 13px;
}
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(92, 107, 192, 1);
}

.chip_adjusts {
   height: 30px;
}
</style>