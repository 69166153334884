<template>
   <div>
      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="healthUnitManagerResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogHealthUnitManagerActiveFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeHealthUnitManagerActiveForm" />

            <HealthUnitManagerActiveForm
               @onActivedHealthUnitManager="
                  closeHealthUnitManagerActiveForm();
                  refreshHealthUnitManagerList();
               "
               :healthUnitManager="healthUnitManagerSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshHealthUnitManagerList();
               "
               :user="healthUnitManagerChangeTypeSelected"
               currentType="healthunit_manager"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogHealthUnitManagerFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeHealthUnitManagerForm" />

            <HealthUnitManagerForm
               @onClickCancel="closeHealthUnitManagerForm"
               @onCreatedHealthUnitManager="
                  closeHealthUnitManagerForm();
                  refreshHealthUnitManagerList();
               "
               @onUpdatedHealthUnitManager="
                  closeHealthUnitManagerForm();
                  refreshHealthUnitManagerList();
               "
               @onClickResetHealthUnitManager="onClickResetHealthUnitManager"
               @onClickRemoveHealthUnitManager="onClickRemoveHealthUnitManager"
               @onFindDisabledHealthUnitManager="
                  onFindDisabledHealthUnitManager
               "
               @onClickChangeType="onClickChangeType"
               :healthUnitManager="healthUnitManagerSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <HealthUnitManagerList
         @onClickAddHealthUnitManager="onClickAddHealthUnitManager"
         @onClickViewHealthUnitManager="onClickViewHealthUnitManager"
         @onClickEditHealthUnitManager="onClickEditHealthUnitManager"
         @onClickRemoveHealthUnitManager="onClickRemoveHealthUnitManager"
         class="mt-12"
         ref="healthUnitManagerlist"
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import PageTitle from "../../components/base/PageTitle";
import HealthUnitManagerList from "../../components/healthUnitManager/HealthUnitManagerList";
import HealthUnitManagerActiveForm from "../../components/healthUnitManager/HealthUnitManagerActiveForm";
import HealthUnitManagerForm from "../../components/healthUnitManager/HealthUnitManagerForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "HealthUnitManager",

   components: {
      Close,
      PageTitle,
      HealthUnitManagerActiveForm,
      HealthUnitManagerList,
      HealthUnitManagerForm,
      ResetPasswordUsersForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         healthUnitManagerSelected: {},
         healthUnitManagerResetPasswordSelected: {},
         healthUnitManagerChangeTypeSelected: {},

         editMode: false,

         dialogSelectHealthUnitVisibility: false,
         dialogHealthUnitManagerFormVisibility: false,
         dialogHealthUnitManagerActiveFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogHealthUnitManagerFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      selectHealthUnit() {
         this.dialogSelectHealthUnitVisibility = false;
      },

      onClickAddHealthUnitManager(healthUnitManager) {
         this.healthUnitManagerSelected = Object.assign({}, healthUnitManager);
         this.openHealthUnitManagerForm();
      },

      onClickViewHealthUnitManager(healthUnitManager) {
         this.healthUnitManagerSelected = Object.assign({}, healthUnitManager);
         this.openHealthUnitManagerForm();
      },

      onClickEditHealthUnitManager() {
         this.editMode = true;
      },

      onClickChangeType(healthUnitManager) {
         this.healthUnitManagerChangeTypeSelected = Object.assign(
            {},
            healthUnitManager
         );
         this.closeHealthUnitManagerForm();
         this.openChangeTypeUserForm();
      },

      onClickResetHealthUnitManager(healthUnitManager) {
         this.healthUnitManagerResetPasswordSelected = Object.assign(
            {},
            healthUnitManager
         );
         this.openResetPasswordForm();
      },

      onClickRemoveHealthUnitManager(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, {
               active: false
            });

            this.dialogHealthUnitManagerFormVisibility = false;
            this.refreshHealthUnitManagerList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor da unidade de saúde foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message:
               "Deseja realmente excluir esse gestor da unidade de saúde?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledHealthUnitManager(item) {
         this.healthUnitManagerSelected = Object.assign({}, item);

         this.closeHealthUnitManagerForm();
         this.openHealthUnitManagerActiveForm();
      },

      openHealthUnitManagerForm() {
         this.dialogHealthUnitManagerFormVisibility = true;
      },

      closeHealthUnitManagerForm() {
         this.dialogHealthUnitManagerFormVisibility = false;
      },

      openHealthUnitManagerActiveForm() {
         this.dialogHealthUnitManagerActiveFormVisibility = true;
      },

      closeHealthUnitManagerActiveForm() {
         this.dialogHealthUnitManagerActiveFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      refreshHealthUnitManagerList() {
         this.$refs.healthUnitManagerlist.refreshAllHealthUnitManagersByRef();
      }
   }
};
</script>

<style scoped></style>
