<template>
   <div>
      <FormTitle icon="$box-cutter-check" title="CIRURGIAS ACEITAS" />

      <v-alert
         v-if="getMode() === 'read' && formAvaliableSurgery.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Cirurgia cadastrada em
         {{
            formatDate(
               formAvaliableSurgery.created_at,
               "DD/MM/YYYY [às] HH:mm"
            )
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card
            v-if="!formAvaliableSurgery.id"
            :disabled="getMode() === 'read'"
            flat
         >
            <v-row class="mt-0">
               <v-col cols="12">
                  <SurgerieInput
                     @onSelectSurgerie="onSelectSurgery"
                     :surgerySusCode="avaliableSurgery.sus_code"
                     ref="surgerytypeinput"
                  />
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <v-card
         v-if="formAvaliableSurgery && formAvaliableSurgery.sus_code"
         class="mt-4 standard-padding"
         outlined
      >
         <ViewSurgery :surgery="formAvaliableSurgery" />
      </v-card>

      <FormActions
         @onClickEdit="onClickEditAvaliableSurgery"
         @onClickRemove="onClickRemoveAvaliableSurgery(avaliableSurgery)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveAvaliableSurgery"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveAvaliableSurgery"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import FormActions from "../../components/base/form/FormActions";
import SurgerieInput from "components/surgerie/SurgerieInput";
import ViewSurgery from "components/surgeryRequest/form/subForm/ViewSurgery.vue";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import avaliableSurgeryService from "../../services/avaliableSurgeryService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "AvaliableSurgeryForm",

   components: { FormTitle, FormActions, SurgerieInput, ViewSurgery },

   props: {
      avaliableSurgery: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formAvaliableSurgery: {},

         loadingSaveAvaliableSurgery: false,

         validationErrors: [],
      };
   },

   watch: {
      avaliableSurgery: {
         immediate: true,
         handler(avaliableSurgery) {
            if (avaliableSurgery.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formAvaliableSurgery =
               this.formatObjectToView(avaliableSurgery);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(avaliableSurgery) {
         let avaliableSurgeryTemp = util.mergeObject({}, avaliableSurgery);

         return avaliableSurgeryTemp;
      },

      formatObjectToSend(avaliableSurgery, removeEmptyStrings) {
         let avaliableSurgeryTemp = util.removeBlankAttributes(
            avaliableSurgery,
            {
               emptyStrings: removeEmptyStrings,
            }
         );

         return avaliableSurgeryTemp;
      },

      onClickEditAvaliableSurgery() {
         this.setMode("save");
      },

      onClickRemoveAvaliableSurgery(avaliableSurgery) {
         this.$emit("onClickRemoveAvaliableSurgery", avaliableSurgery);
      },

      onClickCancel() {
         this.setMode("read");
         this.formAvaliableSurgery = this.formatObjectToView(
            this.avaliableSurgery
         );
         this.$emit("onClickCancel");
      },

      onClickSaveAvaliableSurgery() {
         if (this.formAvaliableSurgery.id)
            this.updateAvaliableSurgery(
               this.formAvaliableSurgery.id,
               this.formAvaliableSurgery
            );
         else this.createAvaliableSurgery(this.formAvaliableSurgery);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
         if (this.$refs.surgerytypeinput) this.$refs.surgerytypeinput.reset();
      },

      onSelectSurgery(surgery) {
         if (surgery && surgery.sus_code)
            this.formAvaliableSurgery = Object.assign({}, surgery);
      },

      async createAvaliableSurgery(avaliableSurgery) {
         if (!this.executeValidation() || !avaliableSurgery) return;

         this.loadingSaveAvaliableSurgery = true;

         try {
            await avaliableSurgeryService.createAvaliableSurgery(
               this.formatObjectToSend(avaliableSurgery)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreatedAvaliableSurgery");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAvaliableSurgery = false;
         }
      },

      async updateAvaliableSurgery(avaliableSurgeryId, avaliableSurgery) {
         if (
            !this.executeValidation() ||
            !avaliableSurgeryId ||
            !avaliableSurgery
         )
            return;

         this.loadingSaveAvaliableSurgery = true;

         try {
            await avaliableSurgeryService.updateAvaliableSurgery(
               avaliableSurgeryId,
               this.formatObjectToSend(avaliableSurgery, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedAvaliableSurgery");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAvaliableSurgery = false;
         }
      },
   },
};
</script>

<style scoped>
</style>