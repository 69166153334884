<template>
   <div>
      <v-dialog
         v-model="dialogSurgeryOutcomeFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryOutcomeForm" />

            <SurgeryOutcomeForm
               @onClickCancel="closeSurgeryOutcomeForm"
               @onConfirmOutcome="
                  closeSurgeryForm();
                  closeSurgeryOutcomeForm();
                  refreshSurgeryList();
               "
               class="standard-padding"
               :request="surgeryRequest"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogSurgeryRejectRequestFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryRejectRequestForm" />

            <SurgeryRejectRequestForm
               @onClickCancel="closeSurgeryRejectRequestForm"
               @onCancelReservation="
                  closeSurgeryForm();
                  closeSurgeryRejectRequestForm();
                  refreshSurgeryList();
               "
               class="standard-padding"
               :request="surgeryRequest"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogSurgeryCancelFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryCancelForm" />

            <SurgeryCancelForm
               @onClickCancel="closeSurgeryCancelForm"
               @onCancelSurgery="
                  closeSurgeryForm();
                  closeSurgeryCancelForm();
                  refreshSurgeryList();
               "
               class="standard-padding"
               :request="surgeryRequest"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogSurgeryDoneFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryDoneForm" />

            <SurgeryDoneForm
               @onClickCancel="closeSurgeryDoneForm"
               @onDoneSurgery="
                  closeSurgeryForm();
                  closeSurgeryDoneForm();
                  refreshSurgeryList();
               "
               :surgery="surgerySelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogSurgeryFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryForm" />

            <v-tabs v-model="activeTab" centered show-arrows>
               <v-tab id="bed-dashboard-button">
                  <v-icon left> mdi-medical-bag </v-icon>
                  CIRURGIA
               </v-tab>
               <v-tab
                  v-if="surgeryRequest.id"
                  id="request-bed-dashboard-button"
               >
                  <v-icon left> mdi-file-document </v-icon>
                  SOLICITAÇÃO
               </v-tab>
            </v-tabs>

            <div>
               <div v-show="activeTab === 0">
                  <SurgeryForm
                     @onClickCancel="closeSurgeryForm"
                     @onUpdatedSurgery="
                        closeSurgeryForm();
                        refreshSurgeryList();
                     "
                     @onClickCancelSurgery="onClickCancelSurgery"
                     @onClickRemoveSurgery="onClickRemoveSurgery"
                     @onClickDoneSurgery="onClickDoneSurgery"
                     @onClickOutcomeSurgery="onClickOutcomeSurgery"
                     @onSurgeryRequestLoad="onSurgeryRequestLoad"
                     :healthUnit="operatorCurrentHealthUnitStore"
                     :surgery="surgerySelected"
                     :editMode="editMode"
                     class="standard-padding"
                     :hideEditButton="hideEditButtonComputed"
                     hideRemoveButton
                     hideTitle
                  />
               </div>
               <div v-show="activeTab === 1 && surgeryRequest.id">
                  <SurgeryRequestOperatorForm
                     :request="surgeryRequest"
                     class="standard-padding"
                     hideEditButton
                     hideRemoveButton
                     hideTitle
                  />
               </div>
            </div>
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogBatchScheduleFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeBatchScheduleForm" />

            <BatchSurgeryForm
               @onScheduledSurgeries="
                  closeBatchScheduleForm();
                  refreshSurgeryList();
                  resetSelectMode();
               "
               @onClickCancel="closeBatchScheduleForm"
               :surgeries="selectedSurgeries"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <SurgeryStatistic
         @onClickChangeHealthUnit="selectHealthUnitVisibilityStore = true"
         :statistics="generalStatistics"
         :healthUnit="operatorCurrentHealthUnitStore"
         ref="surgerystatistic"
      />

      <SurgeryList
         @onClickViewSurgerie="onClickViewSurgery"
         @onClickEditSurgerie="onClickEditSurgerie"
         @onClickSaveBatchSchedule="onClickSaveBatchSchedule"
         :healthUnit="operatorCurrentHealthUnitStore"
         class="mt-12"
         ref="surgerylist"
      />
   </div>
</template>

<script>
import Close from "components/base/Close.vue";
import SurgeryStatistic from "../../components/surgery/statistics/SurgeryStatistic";
import PageTitle from "../../components/base/PageTitle";
import SurgeryForm from "components/surgery/form/SurgeryForm";
import SurgeryDoneForm from "components/surgery/form/SurgeryDoneForm";
import SurgeryRejectRequestForm from "components/surgery/form/SurgeryRejectRequestForm";
import SurgeryOutcomeForm from "components/surgery/form/SurgeryOutcomeForm";
import SurgeryRequestOperatorForm from "components/surgeryRequest/form/SurgeryRequestOperatorForm";
import SurgeryList from "components/surgery/list/SurgeryList";
import SurgeryCancelForm from "components/surgery/form/SurgeryCancelForm";
import responsiveUtil from "../../utils/responsiveUtil";
import BatchSurgeryForm from "components/surgery/form/BatchSurgeryForm.vue";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

//FIXME01:
/* Por enquanto o BedList fará a consulta e em seguida passa os dados via emit para o SurgeryStatistic,
futuramente o próprio component SurgeryStatistic terá uma rota específica e fará a consulta da parte de estatísticas. */

export default {
   name: "SurgeryDashboard",

   components: {
      PageTitle,
      SurgeryStatistic,
      SurgeryList,
      Close,
      SurgeryForm,
      SurgeryDoneForm,
      SurgeryRejectRequestForm,
      SurgeryOutcomeForm,
      SurgeryRequestOperatorForm,
      SurgeryCancelForm,
      BatchSurgeryForm,
   },

   data: function () {
      return {
         surgerySelected: {},
         surgeryRequest: {},

         editMode: false,

         dialogBedFormVisibility: false,

         generalStatistics: {
            freeBedCount: null,
            blockedBedCount: null,
            occupiedBedCount: null,
            waitingReservationCount: null,
            reservedBedCount: null,
            totalOccupancyPercentage: null,
         },

         activeTab: 1,

         disableDialogScrolling: false,

         dialogBatchScheduleFormVisibility: false,
         dialogSurgeryFormVisibility: false,
         dialogSurgeryDoneFormVisibility: false,
         dialogSurgeryRejectRequestFormVisibility: false,
         dialogSurgeryCancelFormVisibility: false,
         dialogSurgeryOutcomeFormVisibility: false,

         selectedSurgeries: [],
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         },
      },

      // TODO: Remover healthUnitManager desse componente, só quem vai acessar é o operador.
      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         },
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         },
      },

      //FIXME: Usar os scopos para delimitar as permissões
      showAddButton() {
         return this.userStore.sub_type === "healthunit_manager";
      },

      hideEditButtonComputed() {
         return ["done", "canceled"].includes(this.surgerySelected.status);
      },
   },

   watch: {
      dialogBedFormVisibility() {
         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      dialogSurgeryFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },
   },

   methods: {
      ...responsiveUtil,

      //FIXME01:
      /* Isso aqui no futuro vai ser removido, já que o próprio component SurgeryStatistic
      terá uma rota específica para ele. */
      onGenerateBedStatistics(generalStatistics) {
         this.generalStatistics = generalStatistics;
      },

      onClickViewSurgery(surgery) {
         this.surgerySelected = Object.assign({}, surgery);
         this.openSurgeryForm();
      },

      onClickEditSurgerie() {
         this.editMode = true;
      },

      onClickSaveBatchSchedule(surgeries) {
         this.selectedSurgeries = surgeries;
         this.openBatchScheduleForm();
      },

      onClickRemoveSurgery() {
         this.openSurgeryRejectRequestForm();
      },

      onClickCancelSurgery() {
         this.openSurgeryCancelForm();
      },

      onClickDoneSurgery() {
         this.openSurgeryDoneForm();
      },

      onClickOutcomeSurgery() {
         this.openSurgeryOutcomeForm();
      },

      onSurgeryRequestLoad(request) {
         this.surgeryRequest = Object.assign({}, request);
      },

      openBatchScheduleForm() {
         this.dialogBatchScheduleFormVisibility = true;
      },

      closeBatchScheduleForm() {
         this.dialogBatchScheduleFormVisibility = false;
      },

      openSurgeryForm() {
         this.dialogSurgeryFormVisibility = true;
         this.activeTab = 0;
      },

      closeSurgeryForm() {
         this.dialogSurgeryFormVisibility = false;
      },

      openSurgeryDoneForm() {
         this.dialogSurgeryDoneFormVisibility = true;
      },

      closeSurgeryDoneForm() {
         this.dialogSurgeryDoneFormVisibility = false;
      },

      openSurgeryCancelForm() {
         this.dialogSurgeryCancelFormVisibility = true;
      },

      closeSurgeryCancelForm() {
         this.dialogSurgeryCancelFormVisibility = false;
      },

      openSurgeryRejectRequestForm() {
         this.dialogSurgeryRejectRequestFormVisibility = true;
      },

      closeSurgeryRejectRequestForm() {
         this.dialogSurgeryRejectRequestFormVisibility = false;
      },

      openSurgeryOutcomeForm() {
         this.dialogSurgeryOutcomeFormVisibility = true;
      },

      closeSurgeryOutcomeForm() {
         this.dialogSurgeryOutcomeFormVisibility = false;
      },

      refreshSurgeryList() {
         this.$refs.surgerylist.refreshAllSurgeriesByRef();
         this.$refs.surgerystatistic.refreshHealthUnitStatisticsByRef();
      },

      resetSelectMode() {
         this.$refs.surgerylist.onClickCancelBatchSchedule();
      },

      onOpenChat() {
         this.disableDialogScrolling = true;
      },

      onCloseChat() {
         this.disableDialogScrolling = false;
      },
   },

   /* Toda vez ao exibir essa view é verificado se já existe o ID da unidade de saúde que essa pessoa deseja acessar,
   caso não exista, a tela de escolha da unidade de saúde é exibida, caso exista não será aberta a tela de escolha. */
   created() {
      switch (this.userStore.sub_type) {
         case "executor":
            if (
               !(
                  this.operatorCurrentHealthUnitStore &&
                  this.operatorCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;
      }
   },
};
</script>

<style scoped>
::v-deep .dialog-overflow-hidden {
   overflow-y: hidden !important;
}

.fullscreen-width {
   width: 500px !important;
   max-width: 500px !important;
}

.tab-buttons {
   border: none !important;
   padding: 10px 20px !important;
   height: 38px !important;
}
</style>