<template>
   <div>
      <FormTitle
         v-if="!hideTitle"
         icon="mdi-hospital-building"
         title="UNIDADE DE SAÚDE"
      />

      <v-alert
         v-if="getMode() === 'read' && formHealthUnit.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Unidade de saúde cadastrada em
         {{
            formatDate(formHealthUnit.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">
               Dados da unidade de saúde
            </p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     @change="onChangeHealthUnitName"
                     v-model="formHealthUnit.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     :loading="loadingHealthUnitByName"
                     id="name-healthunit-form-input"
                     label="Nome*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthUnit.director"
                     :rules="[(value) => !!value || 'Diretor obrigatório.']"
                     id="director-healthunit-form-input"
                     label="Diretor*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model.number="formHealthUnit.built_area"
                     id="built_area-healthunit-form-input"
                     v-maska="'#*'"
                     label="Área construída (m²)"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-3">
               <v-col cols="12">
                  <v-combobox
                     v-model="formHealthUnit.laboratories"
                     id="laboratories-healthunit-form-input"
                     label="Laboratórios"
                     placeholder="Digite o nome do laboratório, ao finalizar aperte 'enter'."
                     style="height: 100%"
                     chips
                     deletable-chips
                     hide-selected
                     multiple
                  >
                  </v-combobox>
               </v-col>
            </v-row>

            <v-row class="mt-n4">
               <v-col cols="12">
                  <v-combobox
                     v-model="formHealthUnit.pharmacies"
                     id="pharmacies-healthunit-form-input"
                     class="combobox-height"
                     label="Farmácias"
                     placeholder="Digite o nome da farmácia, ao finalizar aperte 'enter'."
                     chips
                     deletable-chips
                     hide-selected
                     multiple
                  >
                  </v-combobox>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-6">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formHealthUnit.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-healthunit-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthUnit.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-healthunit-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formHealthUnit.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-healthunit-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formHealthUnit.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-healthunit-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthUnit.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-healthunit-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formHealthUnit.address.state"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     :items="statesItems"
                     id="state-healthunit-form-input"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formHealthUnit.address.complement"
                     id="complement-healthunit-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5 mb-0">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formHealthUnit.address.latitude"
                     :rules="[(value) => !!value || 'Latitude obrigatório.']"
                     id="latitude-healthunit-form-input"
                     label="Latitude*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formHealthUnit.address.longitude"
                     :rules="[(value) => !!value || 'Longitude obrigatória.']"
                     id="longitude-healthunit-form-input"
                     label="Longitude*"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <p class="body-1 font-weight-medium mt-6">Capacidades</p>

      <v-chip-group
         v-model="capabilitiesComputed"
         @change="changeCapabilities"
         multiple
         column
      >
         <v-chip
            :color="
               capabilitiesComputed.includes('can_have_beds')
                  ? 'primary'
                  : 'normal'
            "
            :class="
               capabilitiesComputed.includes('can_have_beds')
                  ? 'elevation-4'
                  : 'elevation-0'
            "
            value="can_have_beds"
            label
            filter
            large
         >
            <div class="d-flex justify-space-between align-center">
               <p class="ma-0 mx-3">Oferece leitos</p>
               <v-icon>mdi-bed</v-icon>
            </div>
         </v-chip>
         <v-chip
            :color="
               capabilitiesComputed.includes('can_offer_surgeries')
                  ? 'primary'
                  : 'normal'
            "
            :class="
               capabilitiesComputed.includes('can_offer_surgeries')
                  ? 'elevation-4'
                  : 'elevation-0'
            "
            value="can_offer_surgeries"
            label
            filter
            large
         >
            <div class="d-flex justify-space-between align-center">
               <p class="ma-0 mx-3">Oferece cirurgias</p>
               <v-icon>mdi-box-cutter</v-icon>
            </div>
         </v-chip>
      </v-chip-group>

      <FormActions
         v-if="!hideActions"
         @onClickEdit="onClickEditHealthUnit"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveHealthUnit"
         @onClickRemove="onClickRemoveHealthUnit(healthUnit)"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveHealthUnit"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import FormActions from "../../components/base/form/FormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import healthUnitService from "../../services/healthUnitService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "HealthUnitForm",

   components: { FormTitle, FormActions },

   props: {
      healthUnit: {
         type: Object,
      },

      municipality: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      hideActions: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formHealthUnit: {},

         loadingSaveHealthUnit: false,
         loadingSearchZipCode: false,
         loadingHealthUnitByName: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   computed: {
      capabilitiesComputed: {
         get() {
            let result = [];

            if (
               this.formHealthUnit.capabilities &&
               this.formHealthUnit.capabilities.can_have_beds
            )
               result.push("can_have_beds");
            if (
               this.formHealthUnit.capabilities &&
               this.formHealthUnit.capabilities.can_offer_surgeries
            )
               result.push("can_offer_surgeries");

            return result;
         },

         set(value) {
            this.formHealthUnit.capabilities.can_have_beds =
               value.includes("can_have_beds");
            this.formHealthUnit.capabilities.can_offer_surgeries =
               value.includes("can_offer_surgeries");
         },
      },
   },

   watch: {
      healthUnit: {
         immediate: true,
         handler(healthUnit) {
            if (healthUnit.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formHealthUnit = this.formatObjectToView(healthUnit);

            this.resetValidation();
         },
      },

      "formHealthUnit.address.zip_code": async function handler(
         newZip,
         oldZip
      ) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formHealthUnit.address.street = response.data.logradouro;
                  this.formHealthUnit.address.district = response.data.bairro;
                  this.formHealthUnit.address.city = response.data.localidade;
                  this.formHealthUnit.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formHealthUnit.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(healthUnit) {
         let healthUnitTemp = util.mergeObject(
            {
               address: {},
               laboratories: [],
               pharmacies: [],
               capabilities: {
                  can_have_beds: false,
                  can_offer_surgeries: false,
               },
            },
            healthUnit
         );

         return healthUnitTemp;
      },

      formatObjectToSend(healthUnit, removeEmptyStrings) {
         let healthUnitTemp = util.removeBlankAttributes(healthUnit, {
            emptyStrings: removeEmptyStrings,
            // emptyArrays: removeEmptyStrings,
         });

         return healthUnitTemp;
      },

      onClickEditHealthUnit() {
         this.setMode("save");
      },

      onClickCancel() {
         this.setMode("read");
         this.formHealthUnit = this.formatObjectToView(this.healthUnit);
         this.$emit("onClickCancel");
      },

      onClickSaveHealthUnit() {
         if (this.formHealthUnit.id)
            this.updateHealthUnit(
               this.municipality.id,
               this.formHealthUnit.id,
               this.formHealthUnit
            );
         else this.createHealthUnit(this.municipality.id, this.formHealthUnit);
      },

      onClickRemoveHealthUnit(healthUnit) {
         this.$emit("onClickRemoveHealthUnit", healthUnit);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onChangeHealthUnitName() {
         this.getHealthUnitByName(
            this.municipality.id,
            this.formHealthUnit.name
         );
      },

      async getHealthUnitByName(municipalityId, name) {
         this.loadingHealthUnitByName = true;

         try {
            const response = await healthUnitService.getAllHealthUnits(
               municipalityId,
               {
                  page: 1,
                  limit: 1,
                  filter: [
                     {
                        attribute: "active",
                        query: false,
                     },
                     {
                        attribute: "name",
                        query: name,
                     },
                  ],
               }
            );

            if (
               response.data &&
               response.data.length &&
               response.data[0].name.toUpperCase() ===
                  this.formHealthUnit.name.toUpperCase()
            )
               this.$emit("onFindDisabledHealthUnit", response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthUnitByName = false;
         }
      },

      async createHealthUnit(municipalityId, healthUnit) {
         if (!this.executeValidation() || !municipalityId || !healthUnit)
            return;

         this.loadingSaveHealthUnit = true;

         try {
            const healthUnitResponse = await healthUnitService.createHealthUnit(
               municipalityId,
               this.formatObjectToSend(healthUnit)
            );

            if (healthUnitResponse.data && healthUnitResponse.data.id) {
               const capabilities = {
                  can_have_beds: this.formHealthUnit.capabilities.can_have_beds,
                  can_offer_surgeries:
                     this.formHealthUnit.capabilities.can_offer_surgeries,
               };

               await healthUnitService.capabilitiesHealthUnit(
                  healthUnitResponse.data.id,
                  capabilities
               );
            }

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A unidade de saúde foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreatedHealthUnit");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveHealthUnit = false;
         }
      },

      async updateHealthUnit(municipalityId, healthUnitId, healthUnit) {
         if (
            !this.executeValidation() ||
            !municipalityId ||
            !healthUnitId ||
            !healthUnit
         )
            return;

         this.loadingSaveHealthUnit = true;

         try {
            await healthUnitService.updateHealthUnit(
               municipalityId,
               healthUnitId,
               this.formatObjectToSend(healthUnit, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A unidade de saúde foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedHealthUnit");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveHealthUnit = false;
         }
      },

      changeCapabilities(value) {
         if (this.formHealthUnit.id) {
            const methodChangeCapabilitiesItem = async () => {
               const capabilities = {
                  can_have_beds: value.includes("can_have_beds"),
                  can_offer_surgeries: value.includes("can_offer_surgeries"),
               };

               const healthUnitResponse =
                  await healthUnitService.capabilitiesHealthUnit(
                     this.formHealthUnit.id,
                     capabilities
                  );

               this.setMode("read");
               this.$emit("onUpdatedHealthUnit", healthUnitResponse.data);

               snackBarUtil.showCustomSnackBar({
                  color: "success",
                  title: "SUCESSO!",
                  message: "As capacidades foram alteradas com sucesso!",
               });
            };

            snackBarUtil.showCustomSnackBar({
               color: "warning",
               title: "CONFIRMAÇÃO DE ALTERAÇÃO!",
               message: "Deseja alterar a capacidade dessa unidade de saúde?",
               action: {
                  text: "Confirmar",
                  method: methodChangeCapabilitiesItem,
                  closeOnFinished: false,
               },
            });
         }
      },
   },
};
</script>

<style scoped>
.combobox-height {
   height: 100%;
}
</style>