<template>
   <v-card class="map-container" flat>
      <div v-if="loadingRegions" class="loading-config">
         <LoadingData message="Buscando municípios, aguarde..." />
      </div>

      <svg
         id="mapparaibabyregion"
         version="1.1"
         x="0px"
         y="0px"
         viewBox="0 0 1343.6 792"
         style="enable-background: new 0 0 1343.6 792"
         xml:space="preserve"
      >
         <g>
            <path
               @click="clickRegion(5)"
               id="5"
               class="region-config"
               d="M803.6,437.2l5.5-29.7l-25.1,9.3l-3.9-5.9l-21.2-3l-8.9-0.8l-16.7-2.6h0l-7.4-2.1l-6.8-13.7l-15.4,0.5l1.1,5
                  l-0.8,8.9l1.5,3.7l-1,2.3l-4.7,5.1l1.8,0l-0.1,1.6l-3.3,7v0l-11.5,14l2.8,3.2l0.2,3.4l1.6,2.8l0.2,2.4l-3.9-0.2l-0.5-1.2l-1.3,0.8
                  l-5.6-0.9l-3.8-2.9l-2.3,0.3v0h0l-5.3-1.5v0l-4.3,3.2l-25-2.1l-28.6-6l-7.1-2l-10.5-8.8l0.9,10.3l-4.8,1.1l-8,28.9l1-0.3l7.4,3.2
                  l4,5v0l0,0l-3.9,5.9l2.6,0.7l1,1.8l-2.9,1.1l3.3,3.5l0.8,2.7l4.2,1.8l1,1.7l-0.4,2.5l2.7,0.8l0.8,2.2l-5.9,4.5l-2.3-2.1l-4.6,2.3
                  l0,0l-0.3,0.1l-8.7,2.3l-4.5,4l-5.6,0.4v0l-6.2,4.1l-4.8,1.6l-3.8,4.4l-6.9,5.6l-3.8,4.4l-3.9,6.4l-2,5.2l-2.7,3l-0.4,1.1l0.5,6.3
                  l4.2,4.2l1.9,5.1l2.5,3l0,0l2,2.2l0,0l2.9,4.8l0.8,5.7l3.2,4.5l-5.7,8.7l0.1,1.2l1.5,0.9h0l-2.8,4.3l-3.3,0.5l0.2,1.6l-1.6-1.2
                  l-2.3-1.8l-2.7,0.1l-2,2.5l-6.1,2.4l-1.8-0.8l-2.4,0.8l0.1,2l-4.2,1.9l1,1.3l3.9-0.5l1.9,6.6l-7.9,1.4l-1,2.2l1.7,2.5l0.1,4
                  l-13.4,9.3l-5.1,0.1l-1.4,1.2l-4.4,1.1l3.9,0.8l-0.6,2.5l1,1.7l-0.3,2.2l-1.8,0.5l-1,2.2l-7.6,0.9l-2.3,0.8l0.5,1.9l-2.6,6.5
                  l4.5-0.1l0.5,1.6l7.1-3.7l4.3,0.7l2.4-1.7l5.7,2.2l4.3-1.2l5.2,0.4l3,1.2l2.1-1.2l0.6,1l2.9,0l-0.4-3.5l0.6-0.9l3.6-2l4.6,2.7
                  l-0.2,7l1.6,0l1.1,2.2l1.5-0.1l1.2,2.1l4.5,0.2l0.9,0.7l2.2,3.2l0.7,4.5h0v0l0.4,3.6l-3,5.1l0.4,4.2l4.1,7.4l-1.3,3.8l1.2,7.4h0
                  l-0.1,0.4l-2.8,0.8l-1.7,3.6l2.4,3.5l-3.9,2.7l-0.1,2.2l1.1,1.5l6,2.2l4.6-1.1l1.1,0.2l-0.7,7.7l0.7,3.2l8.4,9.9l2.4,4.7l13.3,2
                  l7.9,8.8l9.2,4.2l4.2-1.7l6.4,0.6l1.7-0.9h0l1.4-1.6l-0.1-3.8l0.9-1.2l6.9-1.7l4.6-5l3.9-4.2l5.2-1.6l5.5-4.2l15.4-5.4l2.7-3.4
                  l4.8-1.2l4.1,0l4.4,1.8l2.5-1.5l1.1-5l2.4-0.8l5.8-0.2l0.5-1.3l2.1-0.3l-3.1-3.4l0.9-1.7l4.5,1.7l6.7-0.2l-2-4.5l8.5-5.5l8.6-1.6
                  l3.2-2.4v-2.6l-2.6-3l-3.2-0.2l-1-3.5l-2.2-0.4l0.2-1.2l2.5-2.7l6.6-3.7v-21.8l1.5-1.7l0.1-3.6l3.6-6.7l6.6,2.8l4.2,0.8l1-1.4
                  l-2-2.7l2.3-3.4l-1.7-6.6l1.7-2.9l6.5-2.3l4.9,0.9h0l11.6,5.9l3.5-1.5l2.5,3l5-3l6,0l-0.3-2.2l3.4-3.8l1.5-3.3l-0.8-1.8l-2.4-0.5
                  l3.5-3.5l0.2-13.5l1.4-2.7l5.2-0.5l3.4-1.7l-0.9-13.4l-14.6-28.9h0l-10.5-6.6l16.3-51.9l30.3-57.1l0,0L803.6,437.2z"
            />
            <text transform="matrix(1 0 0 1 614.1426 586.1513)">5º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(15)"
               id="15"
               class="region-config"
               d="M1066.3,505.3l-11.2-1.8l-3.6-3.9l0-1.8l2-1.3l-1.3-1.9l2.1-5l-1.4-0.5l-1-2.1l-1.8,0.9l-3.5-1.8l-4.9,3.8l0,0
                  l-1.9-1l-1.5,2.2l-3.1-1.2l-3.9-0.4l-2,0.9l-2.4-0.7l-9.5,3.2l-1.7,1.9l-3.4-1.8l-0.4,2.4l-2.2,0.1l0.3,2l-1,0.9l-1.5-0.1l-1.9-2.8
                  l-2.6,1.3l-1.4-0.7l-2.5,1.2l-1.1-2.9l-3.3,3l-0.9-2.2l-3.3,2.1l-2.1-1.5l-1.6,2.6l-1.5-0.2l-2,1.4l-1-0.5l0.1-1.9l-1.8-1.1
                  l-2.4,1.5l0-2.9l-1.4-0.8l-1.5,2.4l-3.6,0.1l-0.5-1.8l0.8-0.7l-2.3-1.1l-2.2,1.2l-1.2-1.3l0,0l-0.1,0.1l0.1-0.1h0v0l-0.9-2.1
                  l-3,0.7l0.6-1.6l-0.8-3.3l1.9-3.8l3.3-1.3l0.4-1.3l-5.2-7.8l-1.1-4l1-13l-1.5-12.6l-9.5-0.4l-4.9-2.6l0-1.6l-16.1-0.2l-1.7-0.4
                  l0.2-1.5l-2-0.2l-1,1.7l-2.6,1.1l0.1,3l-6.5-0.6l1.8,1.8l-0.6,6.2l-1.3,0.2l1,5.4l-1.1,0.3l0.4,1.2l-1.8,0.7l0.4,6.9l-1.2,1.6
                  l-2.2,0.4l-2.2,2.2l0.3,1l-1.7-0.4l-1.6,1l-0.9-2l-0.8,0.6l-3.1-3l-3.7-1.5L871,461h0l-6.8-4.2l-7.9,0.6l-6.3,4.5l-3.3-0.2l0,0
                  l-0.5-1.6l-1.6,0l-2.8,3.8l-4.1,0.6l-1.1-1.2l1.6-2l-2.5-1.5l-3.1,2.3l-6.5-1.5l-2.7,3.7l-13.3,0.7l-6.9-1.3l4.7-19l-30.3,57.1l0,0
                  L761,553.8l10.5,6.7l0,0l14.6,28.8l0.9,13.4l7.2,1.3l3.7-2l17.9,0.6l4.3,2l6.2,5l2.4,0.4l4.1-1.4l3.2-3l-0.5-2.5l-3.9-2.5l0.6-2.4
                  l11.2-9.4l3.2,2l1.7,6.1l5.9-1.9l12.2-0.7l5.2,0.8l0,0l3,0.5l4.6-2l10,0.4l1,0.6l4.3,5.8l3.3-2.4l2.5,0.8l0,0l0,0l0,0v0l0,0l0,0
                  l4.3,4.4l5.7,0.9l1.6,0.1l1.7-4l3.6,0.9l3.6-4.3l3.4-0.7l4.9,0.9l3.3-1.8l8.9,2.5l6.5-5.5l0.3-2.9l0.4-3l3-2.1l3.1,0.5l6.5-1.3
                  l4.1,1.5l4-1.3l0,0l8.1-2.2l2.9-0.7l5.8-4.3l3.2-0.3l1.8-0.9l1-2.2l2.1-1.3l5.7-0.6l1.8-0.9l1.3-2.4l2.6-1.7l1.5,0.7l0.5,1.8l4,0.1
                  l7.3-0.8l1.4-0.6l5.1-3.4l2.5-2.3l3-4.1l0,0l2.8-3l4-0.1l2.7-1.1l5.2,1.3l3.6,1.5l8.8-1.6l1.8-2.8l3.7-0.8l-5.2-6.8l1.8-4.4
                  l2.4-0.4l-1.2-2.4l2.1-4.4l-4.6,1.5l-2-0.7l-0.1-2.3l3-3.2l2.2-6.1l0.1-4.4l2.7-2.5l0.6-3.5l2.3-1.6L1066.3,505.3z"
            />
            <text transform="matrix(1 0 0 1 857.3862 539.5896)">
               15º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(16)"
               id="16"
               class="region-config"
               d="M1033.3,398.7l-3.1-2.3l-1.2-6.6l-3.4,0.1l-3.9,3.8l-1.7-1.7l-2.3-0.2l-3.7,2.5l-2.6,0.4v0l-8.6-14l-1.6,2
                  l-5.5,2.8l0.3,0.8l-4,0.9l-3.2,2.9l-3.8,1.8l-1.9-0.1l-4.8,3.6l-5.2,1.5l-6.9,5.5l0.5,4.3l-3.6,2.4l0,0h0l-2.2,0.3l-0.8-1.1
                  l-2.6,3.3l-3.4,2.8l-1.1-0.3l0.1-2.7l-2.2-2.6L951,407l-3.5-4.1l-1.1-5.5l-5.4,7.9l-1.8-5l-3.2-3.1l-0.4-1.9l-3.6-4.4l1.2-5l-0.1-4
                  l-12.2,0.5l-3.3-2.7l0,0l1.7-6.8l3.6-3.3l-2.1-0.2l1.2-1.8l2.2-0.8l-0.1-4.3l2.7-5.2l-1.7-1.6l-2.2,0.1l1.8-2.5l-6.9-10.2l3.8-3.1
                  l0.6-2.7l8-5.7l-0.6-3.2l-3-5.5l-0.4-3.4l-3.8,4.1l-5,0.7l-1.4,2.3l-8.3,3.9l0,1.2l-2.1,1.4l-0.7,3l-9.1,4.9l-2.4-1.9l-6.6-0.1
                  l-10.9-12.6l-10.9,4h0l-7.1-0.8l-28.6-30.2l-9.5,18.9l-2.3,8.2l-3.2,2.4l-5.3-0.1l-2.6,2.7l-5.3,2.7h0l0,0l-13.3,8.6l-25.9,3.4
                  l1.5-13.7l-27-19.4l-3.1-4.5L720.1,328l-2.1-0.9l-6.5-0.1l-5.6-1.6l-1.5,1.9l-0.6,8.5l-1.7,2.5l-1.4,0.7l-4.5-0.8l-3.2,2.3l-1.8,3
                  l2.1,4.8l2.7,2.6l0,0l-12.2-1.8l-1.6,4.8l-3.6,1.9l-0.2,2.4l-3.5,3.1l1.2,5l-0.3,6.7l-8.5,10.3v0h0l-9.9,11.9l-7.3-1.7l-3.5,0.4
                  l-1.8,1.3l-2.7-0.4l-6.7,3.2l-4.6,0.3l-3.2-2.6l-4.5,1l-3.6-0.6l-0.1-1.1l-4.1,0.5l-3.2-0.9l-3,0.6l-3.9,3.1l-5,6.2l-2.9-1.4
                  l-5.4,4.6l-4.8-0.2l6.2,19.2l0,0l10.5,8.8l7.1,2l28.6,6l25,2.1l4.3-3.2l5.3,1.5l2.3-0.3l3.8,2.9l5.6,0.9l1.3-0.8l0.5,1.2l3.9,0.2
                  l-0.2-2.4l-1.6-2.8l-0.2-3.4l-2.8-3.2l11.5-14l3.3-7l0.1-1.6l-1.8,0l4.7-5.1l1-2.2L704,403l0.8-8.9l-1.1-5v0l0,0l15.4-0.5l6.8,13.7
                  l7.4,2.1l16.7,2.6l8.9,0.8l0,0h0l21.2,3l3.9,5.9l25.1-9.3v0l-5.5,29.7l4,7.7h0l-4.7,19l6.9,1.4l13.3-0.7l2.7-3.7l6.5,1.5l3.2-2.3
                  l2.5,1.5l-1.6,2l1.1,1.1l4.1-0.6l2.8-3.8l1.7,0l0.5,1.6l3.3,0.2l6.3-4.5l7.9-0.6l6.8,4.2v0l0,0l27.3,2.8l3.7,1.5l3.2,3l0.8-0.6
                  l0.9,2l1.6-1l1.7,0.4l-0.3-1l2.2-2.2l2.2-0.4l1.2-1.6l-0.4-6.9l1.8-0.7l-0.4-1.2l1.1-0.3l-1-5.4l1.3-0.2l0.6-6.2l-1.8-1.8l6.5,0.6
                  l-0.1-3l2.6-1.1l1-1.7l2,0.2l-0.2,1.5l1.7,0.4l16.1,0.2l0,1.6l4.9,2.6l9.5,0.4l1.5,12.6h0l0,0v0l-1,13l1.1,4l5.2,7.8l-0.4,1.3
                  l-3.3,1.3l-1.9,3.8l0.8,3.3l-0.6,1.6l3-0.7l0.9,2.1l1.2,1.3l2.2-1.2l2.3,1.1l-0.8,0.7l0.5,1.8l3.6-0.1l1.5-2.4l1.4,0.8l0,2.9
                  l2.4-1.6l1.8,1.1l-0.1,1.9l0.9,0.6l2-1.4l1.5,0.2l1.6-2.6l16.3-41.5l-6.5-1.5l-6.8,2.8l12.8-25.8l0,0l0,0l1.8-13h0l0,0l8.4-3.5
                  l3.1-5.3l12.2-3.8l4.7-3.2L1033.3,398.7z"
            />
            <text transform="matrix(1 0 0 1 778.3752 380.9596)">
               16º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(4)"
               id="4"
               class="region-config"
               d="M928.8,188.2l-6.6-2.7l-3.9,1.4l-8.7-2.5l-0.6-2.1l-1.7-0.6l1.4-2.4l-1.5-3.2l-7.3-3.4l-4.6-3.8l-3.3-6.9
                  l-1.7,1.5l-2.1-0.1l0.3-1.4l-2.3-1.7l-2.1,3.3l-3.1,1.1l-0.6,0.2l-1.5,0.5l-2.6,2.3l-5.1-1.2l-1.4,0.9l-3.6-0.9v0l-8.9,1.6l-14.9,1
                  v0l-9.6,1.1l-0.4-10.4l-13.9-3.5l-0.9-4.6l1.1-2.5l5.1-7.9l-0.1-8.9l-0.3-2.7l-4.9-4.8l-3.8-3.8l-11.4,6.6l0,0l-5.9,2.4l-10-5
                  L776.4,147l-21.8-1.3l-1.6,8.8l-0.8,4.4l-6.8,14.8l-0.3,4.2l0.6,5.9h0l0.7,6.9l2.1,0.4l5.9,3.5l5.6,1.9l1.3,6.7l2.9,2.6l0,0l0,0
                  l6.2,1.6l1.8,2.1l0.7,12.4l1.7,8.2l-0.7,1.1l0,0l-3.5-1.6l-3.5,3.2l-4.1-6.3l-8.9-4.1l-1.9-1.9l-1.8-0.3l-3.4,1.7l0.9,3.4
                  l-4.8,13.6l-3.2,13.1l-3.1,3.8l-0.1,1.1l4.3,6.4l3.3,0.8h0l2.1,0.8l1.5,2l5.6,4.3l1,6.8l-1.8,11.2l-1.6,2.1l-5.9,2.3l-4.1,4.8v0v0
                  h0l-5.2-0.4l-3,2.3l-1.2,3.2l2.1,2.7l-0.4,2.6l3.1,4.5l27,19.4l-1.5,13.7l25.9-3.4l13.3-8.6l5.3-2.7l2.6-2.7l0,0l5.3,0.1l3.2-2.4
                  l2.3-8.2l9.5-18.9v0h0v0l0,0l0,0l28.6,30.2l7.1,0.8l10.9-4l8.5-4.3l9.5-7.5l9.6-4.8l7.1-2.8l19.9-12.9l3.5-2.7l3.8-8l5.5-0.5
                  l-2.9-2.8l0.4-1.9l1.7-0.9l0.2-3l-1.1-2.6l2.1-2.8l-1.5-2.3l-1.9-0.2l-4.8-3.1l-0.1-3.5l3.6-0.9l1.3-2.5l2.6-1.5l-0.9-1.6l2.7-2.8
                  l4.3-19.8l-1.3-8.1l2.4-8.9l0,0l2.4-9l4.7-18.3L928.8,188.2z"
            />
            <text transform="matrix(1 0 0 1 813.1857 241.1013)">4º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(2)"
               id="2"
               class="region-config"
               d="M1138.8,331.5l-1.1-1.6l-3,0.1l-2-6.4l-3.9-2.7l1.5-3.4l-1.4-3.4l0.2-2.8l-3.3-3.9l-0.8-3.5l5.8-2.9l-2.2-6.6
                  l-6.4-1.8l1.4-8.2l2.2-3.2l-1.5,0l-1.3-2.8l-4.8,0l-3.1-1.6l-1.7-4.3l-1.9-1.1l-3.1-0.5l0,0l-0.7,0.2v0h0h0l0.7-0.2h0l7-2l1.3-2.1
                  l-0.4-5.5l4-6.8l1.8-0.4l1.1,2.4l1.6-0.6l0.2-11.6l-4.4-5.9l-2.2-0.4l3.8-9.5v0l0.6-5.9l-1.4-1.9l0.1-2.4l-1.3,1l-1.8-0.8l-2.1,2.8
                  l-2-0.6l-2.4,0.8l-3.3-0.1l-1.2,1.5l-4.3-1.4l-2.2-11.4l10.1-16l-10.8-6.1l-2.8-0.6l0,0l-10.6-0.9l-0.3-2.6l-1.3,2.3l-4.2-3.1
                  l-1.2,1.8l-2.8,0.2l-1.3,1.2l-2.2-0.3l-2.5-1.9l-1.2,1l-1.8-1.7l-3.4-0.3l-1.7,0.8l-2.4-1l-0.6,1l-2,1.2l-1.4-1l-0.9,1l-1.9-0.4
                  l-1.6,2.7l-2.3,0.8l-1.8-0.3l0.2-1.4l-5.1-0.1l-0.3-0.4l-3.2-2.7l-0.1-1.7l-2.5-3.5l-5.2,0.6l-1.1-1.6l-5.2-1l-6.8-2.7l-1.4,0.7
                  l-0.7,0.7l-1.9,0.4l-3.3-1.2l-8.9,7.1l-2.4,0.6l-3.1-0.3l-14.3,3.6l-2.1,0.1l-22.9,1.4l-4.7,18.3l-2.4,9l-2.4,8.9l1.3,8.1
                  l-4.3,19.8v0l0,0l-2.6,2.8l0.9,1.6l-2.6,1.5l-1.3,2.5l-3.6,0.9l0.1,3.5l4.8,3.1l1.9,0.2l1.5,2.3l-2.1,2.8l1.1,2.6l-0.2,3l-1.7,0.9
                  l-0.4,1.9l2.9,2.8l2-0.1l7,7.4l7.8,6.1l1.6,3.2l3.3,3.2l5.9,2.2l0.5-4.9l-1.1-1.5l1.7-0.7l0.4-2.1l2.8-0.1l1.3-2.4l1.7-0.8l0,0h0
                  l10.8,0.4l2.5-3.6l4.7,1l3.1,2.5l0.2-0.9l3.5-0.6l2.2-1.8l6.6,4l0,0l3.1,2.4l-0.2,3.9l-17.9,14.9l14.8-1.1l7.3,6l2-0.1l11.4,2.1
                  l9.7,1l0,0l3.6,4.9l-3,2.1l2.1,8.8l4.6,2.6l0.7,0.9h0v0l6.9,9.1l3.5,6.9l2.6-0.3l1.3,0.5l1.3-2l2.2,0.4l2.8,12.5l12.2,2.2l3.1-1
                  l6.3-4.4l2.3-4.4l7.4,0.6l4.7-3.7l0.1-2.9l1.5-0.5l0.1-4.6l2-2.9l14.8-4l0,0l12.3-5.1l4.8-4.4L1138.8,331.5z"
            />
            <text transform="matrix(1 0 0 1 1002.1802 256.5901)">
               2º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(14)"
               id="14"
               class="region-config"
               d="M1251.9,291.2l-1.6-16.2l-5.8,0.7v0l1.1-0.7l0.1-3.8l-1.8-1.1l3.1-4.3l-1.7-2.6l0.9-3.4v0l-0.9-7l1.1-3.3
                  l-5-2.3l-2.2-3.9l-2.6-9l-1-11.7l0,0L1235,188l-0.7-1.3l-0.5-0.9l-2.7,1.5l-3.9,3.5l-6,1.5l-2.4-1l-3,0.8l-2.6,3.7l-6.5,3.4l-2.9-1
                  l-8.9-8.5l-1.9,0.8l-0.6,1.8l-2.8-0.2h0v0l-3.4,7.8l-2.9,1.9l-4.1,5.3l-3.7-0.3l-4,2.1l-7.4-2.6v0l-1.7-0.1l-2.7-2.6l-3.1-7.3
                  l-1.5-0.7l-0.5,1.6l-2.5,3.2l-6.5,0.7l-6.7,3.1l-17.6,0.1l-3.3-1.8l-7.3-8.4l-10.1,16l2.2,11.4l4.3,1.4l1.2-1.5l3.3,0.1l2.4-0.8
                  l2,0.6l2.1-2.8l1.8,0.8l1.3-1l-0.1,2.4l1.4,1.9l-0.7,5.9h0l-3.8,9.4l2.2,0.4l4.4,5.9l-0.2,11.6l-1.6,0.6l-1.1-2.4l-1.8,0.4l-4,6.8
                  l0.4,5.5l-1.3,2.1l-7,2l0,0l3.2,0.5l2,1.1l1.7,4.3l0,0l3.1,1.6l4.8,0l1.3,2.9l1.5,0l-2.2,3.2l-1.4,8.2l6.4,1.8l2.2,6.6l0,0v0l0,0
                  l-5.7,2.9l0.8,3.5l3.3,3.9l-0.2,2.8l1.4,3.4l-1.5,3.4l3.9,2.7l2,6.4l3-0.1l1.1,1.6l3.7,2.3l1.9-0.8l5.5,0.4l5.2-2.2l0.9-1.5
                  l3.8-0.6l7.2,4.4l1.6-1.3l0.1,0.7l7.5-3.9l1.8,0.7l5.2-0.6l8.2-1.1l1.7,0.4l3.3,0.4l4.6-1.5l5.2-0.1l6.8-4.9l15.1-3.5l4.3-2
                  l3.7-0.5l4.3-2.5l3.7-0.3l3.2-2.4l3.4-3.7l4.6-2.2l0-3.2L1251.9,291.2z"
            />
            <text transform="matrix(1 0 0 1 1143.7164 263.3542)">
               14º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(3)"
               id="3"
               class="region-config"
               d="M1066.7,372.3l-2.4-2.1l-0.9-2.6l7.4,1.4l-2.8-12.5l-2.1-0.4l-1.3,2l-1.3-0.5l-2.6,0.3l-3.5-6.9l-6.9-9.1
                  l-0.7-0.9l-4.6-2.6l-2.1-8.8h0l3-2.1l-3.6-4.9l-9.7-1l-11.4-2.1l-2,0.1l-7.3-6l-14.8,1.1l17.9-14.9l0.2-3.9l-3.1-2.4l-6.6-4
                  l-2.2,1.8l-3.5,0.6l-0.2,0.9l-3.1-2.5l-4.7-1l-2.5,3.6l-10.8-0.4l-1.7,0.8l-1.3,2.4l-2.8,0.1l-0.4,2.1l-1.7,0.7l1.1,1.5l-0.5,4.9
                  l-5.9-2.2l-3.3-3.2l-1.6-3.2l-7.8-6.1l-7-7.4l-2,0l-5.5,0.5l-3.8,8l-3.5,2.7l-19.9,12.9l-7.1,2.8l-9.6,4.8l-9.5,7.5l-8.5,4.3
                  l10.9,12.6l6.6,0.1l2.4,1.9l9.1-5l0.7-3l2.1-1.4v-1.2l8.3-3.9l1.4-2.3l5-0.7l3.9-4.1h0l0.4,3.4l3,5.5l0,0l0.6,3.1l-8,5.8l-0.6,2.7
                  l-3.8,3.1l6.9,10.1v0l-1.8,2.5l2.2-0.1l1.7,1.5l-2.7,5.2l0.1,4.3l-2.2,0.8l-1.2,1.8l2.1,0.2l-3.6,3.3l-1.7,6.8l3.3,2.7l12.2-0.5v0
                  l0.1,4l-1.2,5l3.6,4.4l0.4,1.9l3.2,3.1l1.8,5l5.4-7.9l1.1,5.5l3.5,4.1l0.1,2.1l2.2,2.6l-0.1,2.7l1.1,0.3l3.4-2.8l2.6-3.3l0.8,1.1
                  l2.2-0.3l3.6-2.4l-0.5-4.3l6.9-5.5l5.1-1.5h0l4.8-3.6l1.9,0.1l3.8-1.9l3.2-2.9l4-0.9l-0.3-0.8l5.5-2.8l1.6-2l8.6,14l2.6-0.4
                  l3.7-2.5l2.3,0.2l1.7,1.7l3.9-3.8l3.4-0.1l3.2,0.5l18.1-3.9l7.3-0.5l1.9-1.8l1-3l7.1-3.3l-2.1-3.4L1066.7,372.3z"
            />
            <text transform="matrix(1 0 0 1 946.1652 349.7255)">3º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(12)"
               id="12"
               class="region-config"
               d="M1239.6,470.5l-4.6-6.6l3.9-6.1l-27.7-22l-3.7-3.7l-3.7-11.9l-4.4-0.2l-3.7,1.2l-5.4-0.7l-5.1,1.6l-3.2,5.2
                  l-0.8-11l-3.8-1.2h0l-1.3-1.9l-1.1,0.8l-7.3-8.2l-1.3-0.1l-0.3-1.9l-3.5,2.5l-6.7,1.8l-3.3,0.3l-0.8,5l-1.7,1.5l-4.6,0.7l-0.8-0.7
                  l-4.2,1l-1.8-1.3l-2.5,1.7l-1.3-0.9l-1.2,1.1l-4.3-0.7h0h0v0l-1.3-3.3l-4.7-6.2l-3.2-1.8l0.4-1.5l2.2-5.8l4.3-1.5l7.6-13.4
                  l-1.2,1.2l-2.4-0.1l-2.1,2l-1.4-1l-1.6,0.8l0.7-2.2l-3.1-0.6l0.6-2.3l-3.3-1.6l-13.8-20.3l0,0v0l-4.7,3.7l-7.4-0.6l-2.3,4.4
                  l-6.3,4.5l-3.1,1l-12.2-2.2l-7.4-1.4l0.9,2.6l2.4,2.1l-1,2.2l2.1,3.4l-7.1,3.3l-1,3l-1.9,1.8l-7.3,0.5l-18.1,3.9l-3.2-0.5v0
                  l1.2,6.6l3.1,2.3l0.9,2.4l0,0l0,0l0,0l-4.7,3.2l-12.2,3.8l-3.1,5.3h0l-8.4,3.5l-1.8,13.1h0l-12.8,25.8l0,0l6.8-2.8l6.4,1.5l0,0
                  L988.2,496l2.2,1.5l3.3-2.1l0.9,2.2l3.3-3l1.1,2.9l2.5-1.2l1.4,0.7l2.6-1.3l1.9,2.8l1.5,0.1l0.9-0.9l-0.3-2l2.2-0.1l0.4-2.4
                  l3.4,1.8l1.7-1.9l9.5-3.2l2.4,0.7l2-0.9l3.9,0.4l3.1,1.1l1.5-2.1l1.9,0.9l4.9-3.8l3.5,1.9l1.8-0.9l1,2.1l1.4,0.5l-2.1,5l1.3,1.9
                  l-2,1.3l0,1.8l3.6,3.9l11.2,1.8l-0.6-4l1.3-9.7l7.5-3.5l11.9,3.8l2.2,4.3l4.1,1l1.9-0.8l2.2-3.2l8.7-3.8v0l1.5-0.1l2.1-2.4l2,0.5
                  l3.7-0.8l0.8-1.7l-1.2-3.9l3.1,0l2.8,0.1l0.2-2.3l-0.7-2.7l3.4,1.4l2.5-1.8l2.9,0.5l8.3-6.2h0l0,0l11.4-4.2l13.7,4.2l2.9,3.2
                  l3.1-0.6l3.1-2.4l5.4,1.3v0l8.7,2.4l2,1.2l6.9,1.4l3-1.3l2.7-2.3l1.4,0.4l3,1.7l5.5,4.5l5.8,2.5l6.8,7.1l6.4,4.6l0.2,2.5l-1.7,5.5
                  l2.1,3.7l2.4-9.6l-1.3-4l0.9-4.8l0.7-1.5l2.2-0.8l4.6-9.1l0.7-1.7L1239.6,470.5z"
            />
            <text transform="matrix(1 0 0 1 1053.7092 450.2754)">
               12º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(1)"
               id="1"
               class="region-config"
               d="M1287,448.1l1.2-4.8l-0.9-2.5l0.6-4.6l-1.7-5.4l-0.4-7.1l0,0l0.8-9.3l2.6-4.7l0.3-13l-1.2-2.4l-4-0.5l-3.8-4.5
                  l0-6.3l-3.7-4l1.1-7.9l-2.5-1.6l-1.7-4v0h0l0.9-4.1l3.4-3.7l1.4-7.5l-1.4-12.7l-3.6,0l2,4l0.1,6.1l-4.2,4l-2,5.1l-1.4-0.1h0
                  l-2.5-5.9l-1-7.2l0.6-1.9l0,0l4-1.9l1.7-2.7l-3.3-3.8l-1.8-5l1.2-6.2l3-5.3l-4.7-0.8l-3.6-2l-7.1-8.2l-4.6,2.2l-3.4,3.7l-3.2,2.4
                  l-3.7,0.3l-4.3,2.5l-3.7,0.5l-4.3,2l0,0l-15.1,3.5l-6.8,4.9l-5.2,0.1l-4.6,1.5l-3.3-0.4l-1.7-0.4l-8.2,1.1l0,0l-5.2,0.5l-1.8-0.7
                  l-7.5,3.9l-0.1-0.7l-1.6,1.3l-7.2-4.4l-3.8,0.6l-0.9,1.5l-5.2,2.2l-5.5-0.4l-1.9,0.8v0l-4.8,4.4l-12.3,5.1l-14.8,4l-2,2.9l-0.1,4.6
                  l-1.5,0.5l-0.1,2.9h0l13.8,20.3l3.3,1.6l-0.6,2.3l3.1,0.6l-0.6,2.2l1.6-0.8l1.4,1l2.1-2l2.4,0.1l1.2-1.2l0,0l0,0l0,0l0,0l-7.5,13.4
                  l-4.3,1.5l-2.2,5.8v0l-0.4,1.5l3.2,1.7l4.7,6.1l1.3,3.3l4.3,0.7l1.2-1.1l1.4,0.9l2.5-1.7l1.8,1.3l4.2-1l0.8,0.7l4.6-0.7l1.7-1.6
                  l0.8-5l3.3-0.2l6.7-1.8l3.5-2.5l0,0v0l0.3,1.9l1.3,0.1l7.3,8.2l1.1-0.8l1.3,2l3.8,1.1l0.8,11l3.2-5.2l5.1-1.6l5.4,0.7l3.7-1.2
                  l4.4,0.2h0l3.7,11.9l3.7,3.6h0l27.8,22l-3.9,6.1l4.6,6.6l0.1,3.5l-0.7,1.7l0,0l-4.6,9.1l-2.2,0.8l-0.7,1.5l-0.9,4.8l1.3,4l-2.4,9.6
                  v0l0.2,2l4.7,4.6l2.7,5.6l3.2-0.4l5.6-2.9l2.6,1.3l2.9,3.2l1.2,0.1l0.3-1.5l2.8-0.3l0.2,2.7h1.7l2.2-2.8l5.5,3.9l4-3.5l1.4,0.4
                  l4.2,1.4l2,1.8l2.4-0.5l1.8-6l3.3-5.2l-1.6-8l1.2-2.7l2.3-1.9l-2.5-4l2.9-23.9l0,0l1.6-5.2l-0.9-2.9l1.9-8.9L1287,448.1z M1272.5,342.3l-0.1-0.1l-0.2,0l-2.3,0.7l-2.1-0.3l0-0.1
                  l0.4-0.2v-0.2l-0.8-0.2l-0.5,0.2l-0.6,2l0,1.3l0.2,1.2l0.4,2.9l0.5,0.7l0.8,2.1l0.1,0.5l0.2,0.1l0.5,0.1l0.2-0.1l0.1-0.2l0.2-0.2
                  l0.6-0.7l0.7-1l0.5-0.8l0.8-2l0.7-4.4L1272.5,342.3z"
            />
            <text transform="matrix(1 0 0 1 1186.6863 388.0054)">
               1º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(6)"
               id="6"
               class="region-config"
               d="M695.6,324.9l-7.3,16l-2.2,1.5l-8-4.7l-6.8-9.8l-0.2-0.8l2-6.2h0l0.8-4.8l3.9-6.3l4.6-13.6l-12.3,0.7l-1-16.3
                  l-19.5-17.7l-23.5,8.2h0l-8.5,4.9l-6.2,5.6l-0.6-2.3h0l2-2.6l-3.7-0.4l-1.6-1.5l-1.2-3.7l0.5-5.4l-1.8-5.9l-3.9-4l-3.9,0.1l-10,7.9
                  l-2.2,1.7l-5.7,2.9l-5,1.5l-14,4.1l-2.9-0.3l-7.2,10.1v0v0l-12.1,8.5l-13.8-0.2h0v0l-6.4-4.3l-0.1-4l-1.6-3.5l1-2.2l-4.9-4.1
                  l1.4-2.6l1.5-8.5l-5-2.5l-3.2-3.5l2-6l-9.6-1.6l-8.9,3.9l-17.2-0.5l-3-0.8l-24.2,4l-10.6,0.8l-19.6,2.6l-18,0.5h0l-5.8,0.2l3.1,2
                  l1,4.7l4.1,4.1l-0.7,3.4l3.3,3.9L402,281l-2.6,2.9l0.6,3.1l1.2,6.3l-1.9,4.6l0.2,3.6l-1.6,2.3l-2.2,1.2l-2.6,3.5l-4.9,3.1l-3.4,4.4
                  l-0.3,4.6L383,322l2.2,3.1l0.4,4l-6.1,1.2l0.6,4.9l5.5,3.3l3.1,12.1v0l-2.9,2h0l-9.1,2l-13.5,1l-9.4-1.6l-13.4,0l-0.6,23.7l0,0
                  l-0.2,7.5l5.8,1.7l2,2.6l2.5-2.9l3.2,5.1l1.6,1l6.9-0.2l1.7,6.3l2.4,4.1l11.4,0.3h0l0,0l8.6-0.9l0.3,5.9l2.3,4.2l-1.4,3l3.6,3.8
                  l0.3,2.7l1-0.8l2.7,0.5l0.2,1.3l5.6,3.6l2,4.2l2.7,0.8l0.2,1.7l5.9,0.5l0.7,4l2.6,1.1l1.5,6.3l3.3,2.9l3.5-2.1l3.7-5.8l7.7-4.8
                  l0-3.5l4.9-2.1l-1.5-3l4.4-3.7l1.1-3.4l0,0h0l14.5,26.3l15.7-0.9l-0.2,2.2l1.7,1.4l-0.9,4.4l3.2,7.2l11.3-6.2l-2.2-4.7l7.8-10.2
                  l5.5-0.1l0,0l8.1-0.4l3.8-4.1l4.8-0.3l8.7,0.3l5.9,5.1l4.9,1l6.8,4.8l0,0h0l2.6,3.3l1,5.2l2.6,0.5l3.8-1l3,3.6l2.3-0.8l2,2.2
                  l9.2,1.9l6,3.6l1.6,4.4l4.7-1.1l8-28.9l4.8-1.1l-0.9-10.3v0l-6.2-19.2l4.8,0.2l5.4-4.6l0,0l0,0l0.5-0.4l0,0l-0.5,0.4l2.9,1.4l5-6.2
                  l3.9-3.1l3-0.6l3.2,0.9l4.1-0.5l0,0l0,0l0.1,1.1l3.6,0.6l4.5-1l3.2,2.6l4.6-0.3l6.7-3.2l2.6,0.4l1.8-1.3l3.5-0.4l7.3,1.7l9.9-12
                  l8.5-10.3l0.3-6.7l-1.2-4.9l3.5-3.1l0.2-2.4v0l3.6-1.9l1.6-4.8l12.2,1.8l-2.7-2.6l-2.1-4.8l1.8-3l3.2-2.3l4.5,0.8l1.4-0.7l1.7-2.5
                  l0.6-8.5l1.5-1.9L695.6,324.9z"
            />
            <text transform="matrix(1 0 0 1 484.6176 362.7461)">6º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(13)"
               id="13"
               class="region-config"
               d="M448.2,235.1l0.8-7.6l-0.5-3.1l-1.4-0.9l0.1-3.5l1.7-9.4l-1.8-1.5l-0.8-3l4.4-4.8l1.8-0.4l-9.2-7.5l-0.7,4.4
                  l-4.3,0.8l-9.8,5.5l-5.1,1.7l-5.2,0.4l-9.9-4.4l-3.2-6.9l-1.3,0.2l-0.7,4l-2.7,0.1l-4.7,1.2l-1.5,4.5l-5.2,0.3l-10-0.5l2.3,2.6
                  l-0.7,1.6l-3.6,1.3l-3.6-0.5l0.6,2.9l-1.3,2.4l-2.7,0.7l-3.7-0.8l-3.8,1.1l-1.4,4.3l-10.5,0.5l-3.9-0.4l-9.7-1.1l1.3-2.6l0.2-7.7
                  l-7.4-1.1l-4.1-4.2l-12.8-0.2l-3.9-2.7l-5-1.2l-14.9,0.7l5.6,12.1l-2.1,2.7l-1.4,4.7l3.2,3.8l0.3,1.4l-1.3,1.4l-16,10.3l1.7,9
                  l4.3,0.5l4.7,6.2l-0.9,4.7l-13.5,1.8l1.3,14.7l2.6,2.5l0.2,6l4.9,8l3.1,2.9l-1.6,0.8l-2.4-1.4l-2.3,2.2l-0.5,5.3l3.4,2.4l1.2,5.4v0
                  l-5.4,3.7l2.6,18.8l24.1,15.8l18.5,7.8h0h0l11.8,1l3.6-0.9l13.4,0l9.4,1.6l13.5-1l9.1-2l2.9-2l-3.1-12.1l-5.5-3.3h0l-0.6-4.9
                  l6.1-1.2l-0.4-4L383,322l1.5-1.5l0.3-4.6l3.4-4.4l4.9-3.1l2.6-3.5l2.2-1.2l1.6-2.3l-0.2-3.6l1.9-4.6L400,287l-0.6-3.1l2.6-2.9
                  l0.6-2.3l-3.3-3.9l0.7-3.4l-4.1-4.1l-1-4.7l-3.1-2l5.8-0.2l18-0.5l19.6-2.6l10.6-0.8l4.3-9.4l-1.3-2.4l2.8-4.1L448.2,235.1z"
            />
            <text transform="matrix(1 0 0 1 303.4417 276.4953)">
               13º Região
            </text>
         </g>
         <g>
            <path
               @click="clickRegion(8)"
               id="8"
               class="region-config"
               d="M545.6,81.3l-2.7-3.7l2.5-10l-1.7-3.6l0.7-5l-1.6-8.9l0.5-2.2l-6.4,0l-3-2.9l-6,1l-2.6-4.2l-4.3-0.6l-3.2,0.4
                  l-2.7,5.7l-2.8,3l-4.5,0.7l-3.8-2.5l-3.2,2.4l-8.3,3.1l-5.8,6l-5.4-1.2l-2.3,0.7l-1.3,0.7l-3.3,7l-4.5,1.1l-5.7-1.2l-6.2,1
                  l-2.6,2.5l-1.6,7.9l-7.5,2.1l-2-2.3l-4.4,0l0.2,2.4l-5.8,0.1l-4.6,0.1l-1.1,4.1l-6.5-0.5l-2.8-2.9l-1.3,1.1l-3.5,1.2l-4.7,3.6
                  l-5.1,0.8v0l-7,1.1l-2.6,3l-9.8-0.1l-6.3-0.8l-3.4-2.9l-12.6,5.2l-1.7,2l0.3,4.6l-3.2,1.3l-3.5,4l3.2,1l0.9,1.3l-4.6,2.2l5,2.6
                  l-3.1,3.6l-0.3,2.3l4.2,4.6l0.1,1l-11.6-0.5l-5,0.8l-4.3,4.5l2.9,2.5l-6,2.4l-3,3.6l0,0l2.8,2.9l-3.1,3.8l0.8,2.7l-4,1l-1.8,2.7
                  l-8.9-3.3l-0.1,7.2l1.3,1.4l-0.6,1.7l-1.3,1l-3.5-0.1l-5.6,3.8v0l-2.7,3.1L302,166l-3.7-4.6l-0.1-3.1l-4,2.2l-0.2,4.3l-3.3,2.5
                  l-10.8,0.2l-0.2,0.9l1.9,0.6l-3.7,4.3l2.3,7l-2.9,0l-1.6,2v0l3,1.6l-2.7,15.2l12.8,0.1l1.6,1l14.9-0.7l5,1.2l3.8,2.7h0l12.7,0.2
                  l4.1,4.2l7.4,1.1l-0.2,7.7l-1.3,2.6l9.7,1.1l0,0l3.9,0.5l10.5-0.5l1.4-4.3l3.8-1.1l3.7,0.8l2.7-0.6l1.3-2.4l-0.6-2.9l3.7,0.5
                  l3.6-1.3l0.7-1.6l-2.3-2.5l10.1,0.5v0l5.1-0.3l1.5-4.5l4.8-1.2l2.7-0.1l0.7-4l1.3-0.2l3.2,6.8l9.9,4.4l5.1-0.4l5.1-1.7l9.8-5.5h0
                  l4.3-0.8l0.7-4.4l9.2,7.5l14.6-0.6l6.2-5.6l1.9-19.3l2.8-5l-1.4-3.4l-3.4-3l0.4-4.8l2.1-3.2h0l0.3-3.6l3.5-3.3l0.1-7.9l4-6.4l9.5-5
                  v0l1.9-1.6l2.9-7.2l3.6-0.7l6.4,1.3l3.8-0.3l1.3-9.4l6-8l5.7-3.8l0,0l9.3-2.5l2.9-2.5l0.4-4.8l5.9-0.9l2.7-3.1l2.7-5.7L545.6,81.3z
                  "
            />
            <text transform="matrix(1 0 0 1 372.0804 156.2703)">8º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(10)"
               id="10"
               class="region-config"
               d="M292.6,219.8l1.4-4.7l2.1-2.7l-5.6-12.1l-1.6-1l-12.8-0.1l2.7-15.2l-3-1.6l-4.3-0.5l1.4-4.1l-2.4-2.5l-0.6-3.3
                  l-1.8,0.3l-0.7,3.2l-1.7,0.2l-0.8,2l-2.8,1.4l-3.8,6.9l-3.9,2.4l-0.8,5l-5.5,3.8l-1.4,0.1v0l-4.9-1.1l-3.9-8.5l-4.2,2.4l-8.2,0
                  l-1.5-0.3l-2.6-3.2l-8.4-1.5l0,0l-0.6-0.1l-3.2-1l-3.7,1.7l-1.8-0.8l-10.1,7.1l1.7,5.4l-6.9,1.5l1.9,10.1l-0.8,3.4l-5.2,2.3l-5,0.1
                  l-2.6,4.1l0,3.8l-3.2,3.6l0.1,1.8l3.6,2.7l-1.5,2.5l-2.9,14.1l-0.1,23.6l-5.2,5.1l5.9-0.2l0,0l0,0l0.7,7.1l-3.8,9.5l-0.2,10l-2,2.5
                  l3.9,4.7l2.9,0.6l-4.6,9.2l-2.2,1.9l0.9,1.5l-3.6-0.6l-1.4,3l-5.8,0.2l-3.1-0.9l-4.7,1.8l-0.2,1.6h0l1.3,9.3l5.7,0.5l5.5,6l7.8,0.5
                  l15.5,1.6l15.9-0.1l2.7,0.5v0h0l15.2,4.9l6.2,0.5l16.6-1.2l13.3,1.5l0.3-1.2l6.1-1.9l-1-3.9l3.5-3.3l8-1.7l3.1-5.8l6.3-3.8
                  l-2.6-18.8l0,0l0,0l5.4-3.7l-1.2-5.4l-3.4-2.4l0.5-5.3l2.3-2.2l2.4,1.4l1.6-0.8l-3.1-2.9l-4.9-8l-0.2-6l-2.6-2.5l-1.3-14.7
                  l13.5-1.8l0.9-4.8l-4.7-6.2l-4.3-0.6v0l-1.7-9l16-10.3h0l1.3-1.4l-0.3-1.4L292.6,219.8z"
            />
            <text transform="matrix(1 0 0 1 187.669 269.9521)">10º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(9)"
               id="9"
               class="region-config"
               d="M186.1,345.9l-15.5-1.6h0l-7.7-0.5l-5.5-6l-5.7-0.5l-1.3-9.3l0-0.2l0.2-1.5l4.7-1.8l3.1,0.9l5.8-0.2l1.4-3
                  l3.6,0.6l-0.9-1.5l2.2-1.9l4.6-9.2l-2.9-0.6l-3.9-4.7l1.9-2.5l0.2-10l3.8-9.5l-0.7-7.1l-5.9,0.2l5.2-5.1l0.2-23.6l2.9-14.1l1.5-2.5
                  l-3.6-2.7l-0.1-1.8l3.2-3.6l0-3.8l2.6-4.1l0,0l5-0.1l5.2-2.3l0.8-3.4l-1.9-10.1l6.9-1.5l-1.7-5.5l-1.7-5.1l-6.1-0.3l-1.5-4.7
                  l-3.5-4.3l-3.1,0l-1.2-0.6l-2.5-0.4l-3.1,1.6l-0.9-2.6l-3.1,0.7l-0.4-5.8l-2.1-1.7l-0.7-2.8l-1-1.1l-4.3-3.1l-7.8,1.3l-6.9-3.4
                  l-1.1-3.3l-4.5,1.1l0,0h0h0l-6.4,0.7l1-5.6l-2.2-5l4.4-6.1l5.8-5l-5.6,1.2l-5.8,3.5l-3.5,3.7l-3.8-3.3l-5.1-1.1l-3.5,0.7l-1.5,1.9
                  l1.5,1l-5.8,0.6l-0.6,0.2l-0.6,1.6l-2.6,0.8l0.8,3.5l-4.8,0.3l-1.3,0.7l-0.8,2.6l-2.2,1l2.5,2.3l1.5-0.4l2.5,0.4l0.1,1.3l2.4,0.9
                  l6.8-1.8l2.8,1.2l3.9-2.7l3.9,4.8l-2.1-0.5l-9.3,8.1l-0.2,1.4v0h0l-0.5,3.2l-4.2,2.3l-0.2,4.8l-7.7,9.5l-5.4,4l-1.2,4.7l2,1.5
                  l-5.3,6.3l0.3,1.6l-2.2,1.4l-0.1,2.1l-3.1,3.8l3.1,2.8l1.2,4.6l-5.5,3.6l-0.8,3.7v0l0,0l-1.9,0.9l-0.6,3.7l3.2,3.8l-0.1,3.1
                  l-1.5,2.9l-3.3,0.4l0.6,1.4l-1.2,0.4l-2.7,5.4l1,1.7l-0.8,1.4l2.4,5.4l2.8,1.9l-0.5,3.3l2.4,3.6l-0.5,1.5l0.2,1l3.4,1.2l3.5,4.3
                  l4.7,1.7l0,0l-1,3.4l-3.8-1.3l-3.6,0.1l-0.2,0.3l1.8,2.2l0.3,2l-4.3,1.1l-2,1.9l-1.8,2.8l-4.8,2.9l-0.3,0.6l2.3,3l-0.6,1h0L72,302
                  l0.5,2.4l-3.5,1.2l-3.7,2.9l-5,0.6l0,2.9l-4.6,0.3l-1.1,0.8l0.6,2.2l-8.1,2.2l-2.8,2.3l1.9,1.8l0.1,2.7l-1.1,0.4l2.2,4l-3.1,2.8
                  l2.5,3.2l3.2,0.8l-0.4,1.9l1.6,2.7l-2.8,4.2l-2.8,0.5l-1.3,1.2l0,0l2,1.4l2.4-0.4l6.4,4.6l5.3,1.9l6.9,3.5l0.5,3.5l5.2,2l0,0h0
                  l1,0.4l0,0v0l-0.1,2l-2.2,4.1l1.8,1.1l1.3,3l2.4,1.7l1.1,2.2l-1.8,5.6l-4.8,6.8l0.6,7.5l-3.1,1.8l2.6,2.4l-1.9,2.2l-1.5,4.5
                  l8.2-1.7l5.7-0.2l0,0l0,0l2.2,3.1l3.3-0.7l2,1.7l-1,3.3l1.4,4.1l1,3l3.1,2.1l1.5-0.7l1.7,3.7l3.8,0.6l8-2.8l1.8,1.3l-0.8,2.6l0,0v0
                  l0.7,3.7l3.1,0.7l-0.2,3.9l2.5,4.9l-1.7,3.9l-3.2,2.9l0.2,6.1l6.7,4.8l1.2,4.7l1.3,0.1l1.1,1.6l0.1-0.9l3.5-0.7l0.6,3.3l2.3-0.2
                  l4.7,2.2l1.1-2.2l4.3-1.3l3-3l4.1-1l2.4-4.8l5.5,0.1l2-3.7l-1.9-4.7l0.3-3.4l-2.5-0.4l0-4.7l1.3-3.6l-0.7-2.5l0.9-2.7l1.2-2.5
                  l3-1.7l-2.6-3.3l1.9-5.2l-0.2-4.4l0,0l0.4-2.3l3.6-6.7l2.7,0.2l0.7-1.1l-0.5-3l12.6-3.6l2.9,0.2l0.6,1.4l4.7-0.1l1.1-0.1l0.9-1.8
                  l5.1,0.9l0.3-3.7l3.3-4.9l-5.5-0.6l-9.2,4.7l-0.1-11.3l0,0l3.1-3.2l1.1-3.9l2.6-0.5l-8.1-5.8l4.1-0.5l7.8-8.3l4.2-7.6l2.1-2.5
                  l4.7-3.5L186.1,345.9z"
            />
            <text transform="matrix(1 0 0 1 71.3091 327.3224)">9º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(7)"
               id="7"
               class="region-config"
               d="M415.8,445l-1.5-6.3l-2.6-1.1l-0.7-4l-5.9-0.5l-0.2-1.7l-2.7-0.8l-2-4.2l-5.6-3.6l-0.2-1.3l-2.7-0.5l-1,0.8
                  l-0.3-2.7l-3.6-3.9l1.4-3l-2.3-4.2l-0.3-5.9l-8.6,0.9l-11.4-0.3l-2.4-4.1l-1.7-6.3l-6.9,0.2h0l-1.6-1l-3.1-5.1l-2.5,2.9l-2-2.6
                  l-5.8-1.7l0.2-7.5v0l0.6-23.7l-3.6,0.9l-11.8-1l-18.5-7.8l-24.1-15.8l-6.3,3.8L273,340l-8,1.7l-3.5,3.3l1,3.9l-6.1,1.9l-0.3,1.2
                  l0,0h0l-13.4-1.5l-16.6,1.2l-6.2-0.5l-15.3-4.9l-2.7-0.5l-4.7,3.5l-2.1,2.5l-4.2,7.6l-7.8,8.3l-4.1,0.5l8.1,5.8l-2.6,0.5l-1.1,3.9
                  l-3,3.2l0,0l0.1,11.3l9.2-4.7l5.5,0.6l-3.3,4.9l-0.3,3.7l-5.1-0.9l-0.9,1.8l-1.1,0.1l0,0l-4.7,0.1l-0.5-1.4l-2.9-0.2l-12.6,3.6
                  l0.5,3l-0.7,1.1l-2.7-0.2l-3.6,6.7l-0.4,2.3l0.2,4.4l-1.9,5.2l2.6,3.3l-3,1.7l-1.2,2.5l-0.9,2.7l0.7,2.5l-1.3,3.6l0,4.7l2.5,0.4
                  l0,0l0,0l-0.3,3.4l1.9,4.7l-2,3.7h0l-5.5-0.1l-2.4,4.8l-4.1,1l-3,3l-4.3,1.3l-1.1,2.2l-4.7-2.2l-2.3,0.1L126,463l-3.5,0.7l-0.1,0.8
                  l-1.1-1.6l-1.3-0.1l-1.3-4.6l-6.7-4.8l-3.6,7.4l-4.7,5.8l-2.4,6.8l-2.1,0.9l-1.6,4.2l3.5,0.6l-0.2,5.8l-3-0.3l-0.3,1.5l-3,1.9
                  l0.8,4.1l-13,0.8l-0.4,1l1.4,0.5l3.1,6.4l-4.5,5.2l-0.8,3l0.6,1.9l-2.6,1.5l1,1.8l-0.4,3.7l-1.2,2l0.9,2.6l-1.7,1.9l1.2,1.6
                  l-6.2,1.4l-1.4,1.5h0l-2,2L64,531l-2.6,1.8l-0.6,6.9l-1.7,0.3l1,4v0l3,0l3,3.4L69,548l4.4,7.5l0.9,0.5l2-0.9l4,3.5l-0.2,2.7
                  l2.9,0.2l0.2,2.8l4.7,2.6l0.4,4.3l2.9,1.9l2.6,3.7l0.1,4.3l3.1,0.8l1.3,3.9l2.4,0.2l2.5-2.6l1,2.4l0.9-0.9l3.1,1.5l5.9-2.2l4.3,1.9
                  l4.1-1.8l6.3,0.2l0.3-3.5l3.5-1.6l6.4-0.9l2.2-4l7.1,0.7l2.1,3l5.6,0.1l2.8-3.3l-1-1.8l6.8-2.2l3.5-2.9l0.5-2.3l2-1.1l1.7-3.3
                  l4,5.6l2.3-0.5l2.2,0.9l1.2-0.8l1.9,1.3l1.4,3.5l-0.3,2l1.6,0.2l-1.2,3.8l1.6,3.2l8.3-11.2l3.8-1.8l7.5-7.5l1.1-1.8l-0.8-2l1.6-2.1
                  l3.8-2.5l15.7-2l-1.2-8.6l4.3,0l11.5-4.5l8.6-8.6l0.5-0.3l0.4-0.4l5-2.9l11-2.9l1.4-2.9l-0.3-7.8l0.4-0.7l9.5,4l4.7,1.1l2.2,2.3
                  l5.3-1.5l-1.5,2l2.6-0.5l0-1.8l-1.8-2.2l8-7.7l0,0l4.5-2.4l5.8-5.7l11-9.8l9.6-3.3l0,0l1.8-0.9l3.1-4.2l6-1.6l5.1-4.4l3.8-0.4
                  l2.6-2.2l5.5,3.2l2.9-2.5l3.6,0.9l7.9-3.3l-0.3-0.8l-5-1.2l-0.7-4l4.8-1.5l-0.2-5l0.8-0.6l2,0l3.2,2.9l2.4-5.4l4.8-3.4l6.3,1.6
                  l3.7-0.3l3.2-1.6l4.8,2.7l5.4-1.3l3.2,1.5l4.2-0.2l4.4-4.7L415.8,445z"
            />
            <text transform="matrix(1 0 0 1 192.6674 468.8845)">7º Região</text>
         </g>
         <g>
            <path
               @click="clickRegion(11)"
               id="11"
               class="region-config"
               d="M473.7,453.1l0.9-4.4l-1.7-1.4l0.2-2.3l-15.8,0.9l-14.5-26.3l-1.1,3.4l-4.4,3.7l1.5,3l-4.9,2.1l0,3.5l-7.7,4.8
                  l-3.7,5.8l-3.5,2.1l-4.4,4.7l-4.2,0.2l-3.2-1.5l-5.4,1.3l-4.8-2.7l-3.2,1.6l-3.7,0.3l-6.3-1.6l-4.8,3.4l-2.4,5.4l-3.2-2.9l-2,0
                  l-0.8,0.6l0.2,5l-4.8,1.5l0.7,4l5,1.1l0,0h0l0.3,0.8l-7.8,3.3l-3.6-0.9l-2.9,2.5l-5.5-3.2l-2.6,2.2l-3.7,0.4l-5.1,4.4l-6,1.6
                  l-3.1,4.2l-1.8,0.9L320,488l-11,9.8l0,0v0l-5.8,5.7l-4.5,2.4l-8.1,7.7l1.8,2.2l0,1.8l-2.6,0.5l0,0l1.5-2l-5.2,1.5l-2.2-2.3
                  l-4.7-1.1l-9.6-4l-0.4,0.7l0.3,7.8l-1.4,2.9l-11,2.9l-5,2.9l-0.4,0.4h0l-0.5,0.3l-0.1,0.1l-8.5,8.5l-11.5,4.5l-4.4,0.1l1.2,8.6
                  l-15.7,2l-3.8,2.5l-1.6,2.1l0.8,2l-1.1,1.8l-7.5,7.5l-3.8,1.8l-8.3,11.2l0,0l2.7,2l-4.6,6.1l5.3-0.5l-0.4,1.7l2.1,2.9l-2.5,3.3
                  l1.5,8l3.4,5.1l3.3,1.2l3.6-1.9l2.5,1l5.8-0.1l1-1.5l4.2-1.9l5-4l3.8-2l5.2-4l0.9,0.4l3.3-3.1l7,1.7l3.2,3.3v0l0.1,2.5l2.7,1.1
                  l-0.5,1.8l1.3,1.9l0.7,0.7l12.7,3l2.9-2.7l0.5-1.2l-2.9-4l0.1-5.1l1.9-5.2v0l9.4-7.7l5.2,2.1l4.6,3.8l1.2,3.5l1.2,0.3l3.3,0l2.3-2
                  l3.7,1.5l4-3.6l3.4-3.1l1.4-4.8l5.1-3.5l1.8-5.1l2.5-1.4l5.9,1.3l0.6-0.8l-2.2-1.5l-1.3-2.5l0,0l1.9-2.3l5.2-2.3l6.9-9.5l4.6-2.1
                  l11.3-1l4.8-5l3.2-0.3l1.9,2.5l0.1,3.8l2.5,0.7l-0.2,2.4l0.4,0.4l4,3.9l0.8-1.8l4.2-1.3l0.1-5l-2.4-2.4l-3.7-0.8l0.5-3.6l0.1-1
                  l3.2-3.2l1.3-3.7l2-0.1h0v0l1.9-5.5l2.4,0.7l4.1-3.5l-0.7-3.5l1.1-2.8l7.6-0.7l1.7-4l5.4-3.9l3,2.3l6.1,0.7l3.3-2l2.6-2.3l0.5-3.3
                  l6-2.3l5.9-3.6l0,0l6.5-3.1l5.6-1.9l1.6-2.5l0.2-5.3l2.2-2.5l1.9-2.2l-0.4-1.8l1.5-2.2l1.6-0.4l2.7-5l3.1-2.6l-3.2-8.3l0.3-0.8
                  l9.5-2.6l7.7,0.5l3.1-1l1.5-0.9l3.6,2.5l2.3-1.1l7-0.4L473.7,453.1z"
            />
            <text transform="matrix(1 0 0 1 277.476 537.4145)">11º Região</text>
         </g>
      </svg>
   </v-card>
</template>

<script>
import LoadingData from "../LoadingData";
import municipalityService from "../../../services/municipalityService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "utils/util";

export default {
   name: "MapParaibaByRegion",

   components: { LoadingData },

   props: {},

   data: function () {
      return {
         loadingRegions: false,

         regions: [
            {
               id: 1,
               name: "1º Região",
               municipalities: [
                  { id: 2500601, name: "Alhandra" },
                  { id: 2501807, name: "Bayeux" },
                  { id: 2503001, name: "Caaporã" },
                  { id: 2503209, name: "Cabedelo" },
                  { id: 2504603, name: "Conde" },
                  { id: 2504900, name: "Cruz do Espírito Santo" },
                  { id: 2507507, name: "João Pessoa" },
                  { id: 2508604, name: "Lucena" },
                  { id: 2509107, name: "Mari" },
                  { id: 2511905, name: "Pitimbu" },
                  { id: 2512762, name: "Riachão do Poço" },
                  { id: 2513703, name: "Santa Rita" },
                  { id: 2515302, name: "Sapé" },
                  { id: 2515971, name: "Sobrado" },
               ],
            },
            {
               id: 2,
               name: "2º Região",
               municipalities: [
                  { id: 2500502, name: "Alagoinha" },
                  { id: 2500809, name: "Araçagi" },
                  { id: 2501005, name: "Araruna" },
                  { id: 2501500, name: "Bananeiras" },
                  { id: 2501906, name: "Belém" },
                  { id: 2502706, name: "Borborema" },
                  { id: 2503506, name: "Cacimba de Dentro" },
                  { id: 2503605, name: "Caiçara" },
                  { id: 2504157, name: "Casserengue" },
                  { id: 2505204, name: "Cuitegi" },
                  { id: 2505709, name: "Dona Inês" },
                  { id: 2505808, name: "Duas Estradas" },
                  { id: 2506301, name: "Guarabira" },
                  { id: 2508208, name: "Lagoa de Dentro" },
                  { id: 2508554, name: "Logradouro" },
                  { id: 2509800, name: "Mulungu" },
                  { id: 2511608, name: "Pilões" },
                  { id: 2511707, name: "Pilõezinhos" },
                  { id: 2511806, name: "Pirpirituba" },
                  { id: 2512747, name: "Riachão" },
                  { id: 2515609, name: "Serra da Raiz" },
                  { id: 2515906, name: "Serraria" },
                  { id: 2515930, name: "Sertãozinho" },
                  { id: 2516003, name: "Solânea" },
                  { id: 2516409, name: "Tacima" },
               ],
            },
            {
               id: 3,
               name: "3º Região",
               municipalities: [
                  { id: 2500304, name: "Alagoa Grande" },
                  { id: 2500403, name: "Alagoa Nova" },
                  { id: 2500577, name: "Algodão de Jandaíra" },
                  { id: 2500908, name: "Arara" },
                  { id: 2501104, name: "Areia" },
                  { id: 2501203, name: "Areial" },
                  { id: 2506004, name: "Esperança" },
                  { id: 2508307, name: "Lagoa Seca" },
                  { id: 2509339, name: "Matinhas" },
                  { id: 2509503, name: "Montadas" },
                  { id: 2512705, name: "Remígio" },
                  { id: 2515104, name: "São Sebastião de Lagoa de Roça" },
               ],
            },
            {
               id: 4,
               name: "4º Região",
               municipalities: [
                  { id: 2501534, name: "Baraúna" },
                  { id: 2501609, name: "Barra de Santa Rosa" },
                  { id: 2505006, name: "Cubati" },
                  { id: 2505105, name: "Cuité" },
                  { id: 2505352, name: "Damião" },
                  { id: 2506202, name: "Frei Martinho" },
                  { id: 2510105, name: "Nova Floresta" },
                  { id: 2510303, name: "Nova Palmeira" },
                  { id: 2511103, name: "Pedra Lavrada" },
                  { id: 2511400, name: "Picuí" },
                  { id: 2515401, name: "São Vicente do Seridó" },
                  { id: 2516151, name: "Sossêgo" },
               ],
            },
            {
               id: 5,
               name: "5º Região",
               municipalities: [
                  { id: 2500734, name: "Amparo" },
                  { id: 2503902, name: "Camalaú" },
                  { id: 2504074, name: "Caraúbas" },
                  { id: 2504702, name: "Congo" },
                  { id: 2504850, name: "Coxixola" },
                  { id: 2506509, name: "Gurjão" },
                  { id: 2509701, name: "Monteiro" },
                  { id: 2510600, name: "Ouro Velho" },
                  { id: 2510659, name: "Parari" },
                  { id: 2512200, name: "Prata" },
                  { id: 2514008, name: "São João do Cariri" },
                  { id: 2514107, name: "São João do Tigre" },
                  { id: 2514800, name: "São José dos Cordeiros" },
                  { id: 2515203, name: "São Sebastião do Umbuzeiro" },
                  { id: 2515500, name: "Serra Branca" },
                  { id: 2516300, name: "Sumé" },
                  { id: 2517407, name: "Zabelê" },
               ],
            },
            {
               id: 6,
               name: "6º Região",
               municipalities: [
                  { id: 2501153, name: "Areia de Baraúnas" },
                  { id: 2503407, name: "Cacimba de Areia" },
                  { id: 2503555, name: "Cacimbas" },
                  { id: 2504207, name: "Catingueira" },
                  { id: 2504504, name: "Condado" },
                  { id: 2505402, name: "Desterro" },
                  { id: 2505501, name: "Vista Serrana" },
                  { id: 2505907, name: "Emas" },
                  { id: 2507804, name: "Junco do Seridó" },
                  { id: 2508703, name: "Mãe d'Água" },
                  { id: 2508802, name: "Malta" },
                  { id: 2509396, name: "Maturéia" },
                  { id: 2510709, name: "Passagem" },
                  { id: 2510808, name: "Patos" },
                  { id: 2512606, name: "Quixaba" },
                  { id: 2513000, name: "Salgadinho" },
                  { id: 2513406, name: "Santa Luzia" },
                  { id: 2513802, name: "Santa Teresinha" },
                  { id: 2514404, name: "São José de Espinharas" },
                  { id: 2514602, name: "São José do Bonfim" },
                  { id: 2514701, name: "São José do Sabugi" },
                  { id: 2514909, name: "São Mamede" },
                  { id: 2516706, name: "Teixeira" },
                  { id: 2517100, name: "Várzea" },
               ],
            },
            {
               id: 7,
               name: "7º Região",
               municipalities: [
                  { id: 2500205, name: "Aguiar" },
                  { id: 2502102, name: "Boa Ventura" },
                  { id: 2502607, name: "Igaracy" },
                  { id: 2504405, name: "Conceição" },
                  { id: 2504801, name: "Coremas" },
                  { id: 2505303, name: "Curral Velho" },
                  { id: 2505600, name: "Diamante" },
                  { id: 2506608, name: "Ibiara" },
                  { id: 2507002, name: "Itaporanga" },
                  { id: 2510204, name: "Nova Olinda" },
                  { id: 2510402, name: "Olho d'Água" },
                  { id: 2511004, name: "Pedra Branca" },
                  { id: 2511301, name: "Piancó" },
                  { id: 2513356, name: "Santa Inês" },
                  { id: 2513505, name: "Santana de Mangueira" },
                  { id: 2513604, name: "Santana dos Garrotes" },
                  { id: 2514305, name: "São José de Caiana" },
                  { id: 2515708, name: "Serra Grande" },
               ],
            },
            {
               id: 8,
               name: "8º Região",
               municipalities: [
                  { id: 2502003, name: "Belém do Brejo do Cruz" },
                  { id: 2502300, name: "Bom Sucesso" },
                  { id: 2502805, name: "Brejo do Cruz" },
                  { id: 2502904, name: "Brejo dos Santos" },
                  { id: 2504306, name: "Catolé do Rocha" },
                  { id: 2507408, name: "Jericó" },
                  { id: 2509370, name: "Mato Grosso" },
                  { id: 2512804, name: "Riacho dos Cavalos" },
                  { id: 2513901, name: "São Bento" },
                  { id: 2514651, name: "São José do Brejo do Cruz" },
               ],
            },
            {
               id: 9,
               name: "9º Região",
               municipalities: [
                  { id: 2500700, name: "São João do Rio do Peixe" },
                  { id: 2502052, name: "Bernardino Batista" },
                  { id: 2502201, name: "Bom Jesus" },
                  { id: 2502409, name: "Bonito de Santa Fé" },
                  { id: 2503308, name: "Cachoeira dos Índios" },
                  { id: 2503704, name: "Cajazeiras" },
                  { id: 2504108, name: "Carrapateira" },
                  { id: 2509602, name: "Monte Horebe" },
                  { id: 2512036, name: "Poço Dantas" },
                  { id: 2512077, name: "Poço de José de Moura" },
                  { id: 2513307, name: "Santa Helena" },
                  { id: 2513653, name: "Joca Claudino" },
                  { id: 2514503, name: "São José de Piranhas" },
                  { id: 2516805, name: "Triunfo" },
                  { id: 2516904, name: "Uiraúna" },
               ],
            },
            {
               id: 10,
               name: "10º Região",
               municipalities: [
                  { id: 2500775, name: "Aparecida" },
                  { id: 2508406, name: "Lastro" },
                  { id: 2509156, name: "Marizópolis" },
                  { id: 2510006, name: "Nazarezinho" },
                  { id: 2513208, name: "Santa Cruz" },
                  { id: 2513984, name: "São Francisco" },
                  { id: 2514206, name: "São José da Lagoa Tapada" },
                  { id: 2516201, name: "Sousa" },
                  { id: 2517209, name: "Vieirópolis" },
               ],
            },
            {
               id: 11,
               name: "11º Região",
               municipalities: [
                  { id: 2500106, name: "Água Branca" },
                  { id: 2506707, name: "Imaculada" },
                  { id: 2508000, name: "Juru" },
                  { id: 2509008, name: "Manaíra" },
                  { id: 2512309, name: "Princesa Isabel" },
                  { id: 2514552, name: "São José de Princesa" },
                  { id: 2516607, name: "Tavares" },
               ],
            },
            {
               id: 12,
               name: "12º Região",
               municipalities: [
                  { id: 2503803, name: "Caldas Brandão" },
                  { id: 2506400, name: "Gurinhém" },
                  { id: 2506806, name: "Ingá" },
                  { id: 2506905, name: "Itabaiana" },
                  { id: 2507200, name: "Itatuba" },
                  { id: 2507606, name: "Juarez Távora" },
                  { id: 2507903, name: "Juripiranga" },
                  { id: 2509404, name: "Mogeiro" },
                  { id: 2511202, name: "Pedras de Fogo" },
                  { id: 2511509, name: "Pilar" },
                  { id: 2512754, name: "Riachão do Bacamarte" },
                  { id: 2513109, name: "Salgado de São Félix" },
                  { id: 2514453, name: "São José dos Ramos" },
                  { id: 2515005, name: "São Miguel de Taipu" },
               ],
            },
            {
               id: 13,
               name: "13º Região",
               municipalities: [
                  { id: 2503753, name: "Cajazeirinhas" },
                  { id: 2508109, name: "Lagoa" },
                  { id: 2510907, name: "Paulista" },
                  { id: 2512101, name: "Pombal" },
                  { id: 2513927, name: "São Bentinho" },
                  { id: 2513968, name: "São Domingos" },
               ],
            },
            {
               id: 14,
               name: "14º Região",
               municipalities: [
                  { id: 2501401, name: "Baía da Traição" },
                  { id: 2504033, name: "Capim" },
                  { id: 2505238, name: "Cuité de Mamanguape" },
                  { id: 2505279, name: "Curral de Cima" },
                  { id: 2507101, name: "Itapororoca" },
                  { id: 2507309, name: "Jacaraú" },
                  { id: 2508901, name: "Mamanguape" },
                  { id: 2509057, name: "Marcação" },
                  { id: 2509305, name: "Mataraca" },
                  { id: 2512721, name: "Pedro Régis" },
                  { id: 2512903, name: "Rio Tinto" },
               ],
            },
            {
               id: 15,
               name: "15º Região",
               municipalities: [
                  { id: 2500536, name: "Alcantil" },
                  { id: 2501302, name: "Aroeiras" },
                  { id: 2501575, name: "Barra de Santana" },
                  { id: 2501708, name: "Barra de São Miguel" },
                  { id: 2502508, name: "Boqueirão" },
                  { id: 2503100, name: "Cabaceiras" },
                  { id: 2504355, name: "Caturité" },
                  { id: 2506251, name: "Gado Bravo" },
                  { id: 2509909, name: "Natuba" },
                  { id: 2512507, name: "Queimadas" },
                  { id: 2512788, name: "Riacho de Santo Antônio" },
                  { id: 2513158, name: "Santa Cecília" },
                  { id: 2513943, name: "São Domingos do Cariri" },
                  { id: 2517001, name: "Umbuzeiro" },
               ],
            },
            {
               id: 16,
               name: "16º Região",
               municipalities: [
                  { id: 2501351, name: "Assunção" },
                  { id: 2502151, name: "Boa Vista" },
                  { id: 2504009, name: "Campina Grande" },
                  { id: 2506103, name: "Fagundes" },
                  { id: 2507705, name: "Juazeirinho" },
                  { id: 2508505, name: "Livramento" },
                  { id: 2509206, name: "Massaranduba" },
                  { id: 2510501, name: "Olivedos" },
                  { id: 2512002, name: "Pocinhos" },
                  { id: 2512408, name: "Puxinanã" },
                  { id: 2513851, name: "Santo André" },
                  { id: 2515807, name: "Serra Redonda" },
                  { id: 2516102, name: "Soledade" },
                  { id: 2516508, name: "Taperoá" },
                  { id: 2516755, name: "Tenório" },
               ],
            },
         ],
      };
   },

   methods: {
      ...util,

      resetMultiple() {
         let elements = document
            .getElementById("mapparaibabyregion")
            .getElementsByClassName("selected-municipality");

         while (elements.length)
            elements[0].classList.remove("selected-municipality");
      },

      clickRegion(id) {
         const region = this.regions.find((item) => item.id === id);

         this.getRegions(
            {
               page: 1,
               limit: region.municipalities.length,
               filter: {
                  attribute: "specificName",
                  query: region.municipalities
                     .map((item) => item.name)
                     .join(","),
               },
            },
            region
         );
      },

      async getRegions(query, currentRegion) {
         this.loadingRegions = true;
         this.$emit("onChangeLoadingRegions", true);

         try {
            const response = await municipalityService.getAllMunicipalities(
               query
            );

            if (response.data.length === 0)
               snackBarUtil.showCustomSnackBar({
                  title: "NÃO ENCONTRADO",
                  message: "A região não foi encontrada, tente novamente!",
                  icon: "mdi-map-marker-off",
                  color: "info",
                  timeout: 3000,
               });
            else {
               this.$emit("onGetRegion", response.data, currentRegion);
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRegions = false;
            this.$emit("onChangeLoadingRegions", false);
         }
      },

      highlightRegion(region) {
         /* 1. Busca no DOM da página o <path/> do município selecionado. */
         const pathRegion = document.getElementById(region);

         /* 2. Caso o município não foi selecionado ainda, adiciona a classe de seleção no componente.
                  Caso já estava selecionado, remove a classe de seleção do componente. */
         if (pathRegion.classList.contains("selected-municipality"))
            pathRegion.classList.remove("selected-municipality");
         else pathRegion.classList.add("selected-municipality");
      },
   },
};
</script>

<style scoped>
.map-container {
   display: inline-block;
   position: relative;
   width: 100%;
   vertical-align: middle;
   overflow: hidden;
}

.loading-config {
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   width: 100%;
   height: 100%;
   opacity: 1 !important;
   pointer-events: none;
}

.region-config {
   fill: var(--v-primaryLight-base) !important;
   stroke: var(--v-primary-base) !important;
   stroke-width: 1;
   stroke-linecap: round;
   stroke-linejoin: round;
   stroke-miterlimit: 4;
   stroke-dasharray: none;
   stroke-opacity: 1;
   cursor: pointer;
}

.region-config:hover {
   fill: var(--v-primary-base) !important;
   stroke: var(--v-primary-base) !important;
}

text {
   fill: rgba(0, 0, 0, 0.7);
   font-size: 1rem;
   pointer-events: none;
}

.selected-municipality {
   fill: var(--v-black-base) !important;
   stroke: var(--v-black-base) !important;
}

.selected-municipality:hover {
   fill: var(--v-black-base) !important;
   stroke: var(--v-black-base) !important;
}
</style>