<template>
   <div>
      <div v-if="operator.health_secretaries.length">
         <OperatorHealthSecretariesDetails
            v-for="id in operator.health_secretaries"
            @onDisassociateHealthSecretarie="disassociate"
            :key="id"
            :healthSecretarieId="id"
         />
      </div>
      <div v-else>
         <NoData
            message="Desculpe, nenhuma secretaria de saúde associada a esse operador foi encontrada."
         />
      </div>

      <v-expansion-panels
         v-model="healthSecretarieExpansionInput"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  id="associate_new_healthsecretarie-operator-health-units-select"
               >
                  Associar nova secretaria de saúde ao Operador
                  <v-icon right>mdi-plus</v-icon>
               </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-form ref="form">
                  <HealthSecretarieInput
                     @onSelectHealthSecretarie="onSelectHealthSecretarie"
                     ref="operatorhealthsecretarieinput"
                  />
               </v-form>

               <div class="d-flex justify-end align-center ma-n1">
                  <v-btn
                     @click="associate(healthSecretarieSelected)"
                     :loading="loadingSaveAssociation"
                     class="d-none d-sm-flex ma-1"
                     color="primary"
                     id="associate-operator-health-units"
                     depressed
                     small
                     >Associar
                     <v-icon right x-small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                     @click="associate(healthSecretarieSelected)"
                     :loading="loadingSaveAssociation"
                     class="d-flex d-sm-none ma-1"
                     color="primary"
                     id="associate_small-operator-health-units"
                     fab
                     depressed
                     x-small
                  >
                     <v-icon small>mdi-plus</v-icon>
                  </v-btn>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
   </div>
</template>

<script>
import OperatorHealthSecretariesDetails from "components/operator/associationHealthSecretarieForm/OperatorHealthSecretariesDetails";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import NoData from "components/base/NoData";
import operatorService from "services/operatorService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "OperatorHealthSecretaries",

   components: {
      OperatorHealthSecretariesDetails,
      HealthSecretarieInput,
      NoData
   },

   props: {
      operator: {
         type: Object
      }
   },

   data: function() {
      return {
         loadingHealthSecretaries: false,

         loadingSaveAssociation: false,

         healthSecretarieSelected: null,

         healthSecretaries: [],

         healthSecretarieExpansionInput: null
      };
   },

   watch: {
      operator: {
         immediate: true,
         handler() {
            this.healthSecretarieExpansionInput = null;

            if (this.$refs.operatorhealthsecretarieinput)
               this.$refs.operatorhealthsecretarieinput.reset();
            this.resetValidation();
         }
      }
   },

   methods: {
      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthSecretarie(healthSecretarie) {
         this.healthSecretarieSelected = healthSecretarie;
      },

      disassociate(healthSecretarie) {
         const methodDisassociateItem = async () => {
            await operatorService.disassociateOperatorFromHealthSecretarieById(
               this.operator.id,
               healthSecretarie.id
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi desassociado ao hospital com sucesso!"
            });

            this.$emit("onUpdatedOperator");
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE DESASSOCIAÇÃO!",
            message:
               "Deseja realmente desassociar essa secretaria de saúde do operador?",
            action: {
               text: "Confirmar",
               method: methodDisassociateItem,
               closeOnFinished: false
            }
         });
      },

      associate(healthSecretarie) {
         this.associateOperatorToHealthSecretarieById(
            this.operator.id,
            healthSecretarie ? healthSecretarie.id : null
         );
      },

      async associateOperatorToHealthSecretarieById(
         operatorId,
         healthSecretarieId
      ) {
         if (!this.executeValidation() || !operatorId || !healthSecretarieId)
            return;

         this.loadingSaveAssociation = true;

         try {
            await operatorService.associateOperatorToHealthSecretarieById(
               operatorId,
               healthSecretarieId
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi associado ao hospital com sucesso!"
            });

            this.$emit("onUpdatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveAssociation = false;
         }
      }
   }
};
</script>

<style scoped>
.panels-border {
   border-style: solid;
   border-width: 1px;
   border-color: rgba(0, 0, 0, 0.2);
   margin-top: 6px;
}
</style>
