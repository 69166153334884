export default {
   namespaced: true,

   state: {
      notifications: [
         // Exemplo de uma notificação:
         //
         // {
         //    sourceEvent: "new_request",
         //    date: "2023-10-09T14:41:34.484Z",
         //    data: {
         //       id: "6524111ecf2a9d9204f34481",
         //       created_at: "2023-10-09T14:41:34.484Z",
         //       code: "20231009114134396",
         //       type: "surgery",
         //       status: "registered",
         //       patient: {
         //          name: "Paciente Bug",
         //          cpf: "13992737012",
         //          birth_date: "1998-11-11"
         //       },
         //       surgery: {
         //          sus_code: "04.05.05.038-0",
         //          name: "CIRURGIA DE CATARATA CONGÊNITA"
         //       }
         //    },
         //    unread: true,
         //    action: () => {}
         // }
      ]
   },

   getters: {
      getNotifications(state) {
         return state.notifications.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date);
         });
      }
   },

   mutations: {
      setNewRequestNotificationAsRead(state, requestId) {
         const indexFound = state.notifications.findIndex(
            notification =>
               notification.sourceEvent === "new_request" &&
               notification.data.id === requestId
         );

         if (indexFound !== -1) state.notifications[indexFound].unread = false;
      },

      setNewMessageNotificationAsRead(state, requestId) {
         const indexFound = state.notifications.findIndex(
            notification =>
               notification.sourceEvent === "new_message" &&
               notification.data.id === requestId
         );

         if (indexFound !== -1) state.notifications[indexFound].unread = false;
      },

      setNotifications(state, value) {
         state.notifications = value;
      }
   }
};
