import axiosService from "./axiosService";
import store from "../store/index";

/* 
   healthUnitId: String com o ID da unidade de saúde;
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Array com objeto ou objetos compostos por dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getAllHealthUnitRequests(healthUnitId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   // Recupera o type da solicitação e adiciona na url.
   const requestType = store.getters["request/getRequestType"];

   let url = `/healthentities/${healthUnitId}/bedrequests?type=${requestType}&page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      if (!query.filter.some(item => !item.attribute || !item.query)) {
         query.filter.forEach((item, index) => {
            url += `&${item.attribute}=`;
            switch (item.attribute) {
               case "bed_id":
               case "status":
               case "is_closed":
               case "responsibles":
               case "sourcehealthentity_id":
               case "municipality_id":
               case "patient_id":
               case "is_checked":
               case "is_archived":
               case "created_at":
                  url += `${item.query}`;
                  break;
               default:
                  url += `*${item.query}*`;
                  break;
            }

            if (index < query.filter.length - 1) url += "&";
         });
      }
   }

   return await axiosService.get(url);
}

async function createHealthUnitRequest(healthUnitId, request) {
   // Adiciona o type da solicitação no body.
   request.type = store.getters["request/getRequestType"];

   return await axiosService.post(
      `/healthentities/${healthUnitId}/bedrequests`,
      request
   );
}

async function updateHealthUnitRequest(healthUnitId, requestId, request) {
   // Adiciona o type da solicitação no body.
   request.type = store.getters["request/getRequestType"];

   return await axiosService.patch(
      `/healthentities/${healthUnitId}/bedrequests/${requestId}`,
      request
   );
}

async function updateHealthUnitRequestStatus(healthUnitId, requestId, status) {
   // Adiciona o type da solicitação no body.
   status.type = store.getters["request/getRequestType"];

   return await axiosService.put(
      `/healthentities/${healthUnitId}/bedrequests/${requestId}/status`,
      status
   );
}

async function updateHealthUnitRequestOutcome(
   healthUnitId,
   requestId,
   outcome
) {
   // Adiciona o type da solicitação no body.
   outcome.type = store.getters["request/getRequestType"];

   return await axiosService.put(
      `/healthentities/${healthUnitId}/bedrequests/${requestId}/outcome`,
      outcome
   );
}

/* 
   healthUnitId: String com o ID da unidade de saúde;
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getAllRequests(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   // Recupera o type da solicitação e adiciona na url.
   const requestType = store.getters["request/getRequestType"];

   let url = `/bedrequests?type=${requestType}&page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      if (!query.filter.some(item => !item.attribute || !item.query)) {
         query.filter.forEach((item, index) => {
            url += `&${item.attribute}=`;
            switch (item.attribute) {
               case "bed_id":
               case "responsibles":
               case "status":
               case "is_closed":
               case "sourcehealthentity_id":
               case "municipality_id":
               case "patient_id":
               case "is_checked":
               case "is_archived":
               case "created_at":
                  url += `${item.query}`;
                  break;
               default:
                  url += `*${item.query}*`;
                  break;
            }

            if (index < query.filter.length - 1) url += "&";
         });
      }
   }

   return await axiosService.get(url);
}

async function updateRequest(requestId, request) {
   // Adiciona o type da solicitação no body.
   request.type = store.getters["request/getRequestType"];

   return await axiosService.patch(`/bedrequests/${requestId}`, request);
}

async function checkRequest(requestId) {
   return await axiosService.post(`/requests/${requestId}/check`);
}

export default {
   getAllHealthUnitRequests,
   createHealthUnitRequest,
   updateHealthUnitRequest,

   getAllRequests,
   updateRequest,

   updateHealthUnitRequestStatus,
   updateHealthUnitRequestOutcome,

   checkRequest
};
