<template>
   <div>
      <v-progress-linear
         v-if="surgeryLoading"
         class="mb-2"
         indeterminate
         rounded
         height="4"
      ></v-progress-linear>

      <div v-if="!hideTitle" class="d-flex align-center mb-3">
         <p class="body-2 font-weight-medium">Dados da cirurgia</p>
      </div>

      <v-row>
         <v-col cols="12" md="9">
            <p class="caption text--secondary">Nome</p>

            <p class="body-2">{{ surgeryFully.name || "-" }}</p>
         </v-col>
         <v-col cols="12" md="3">
            <p class="caption text--secondary">Código SUS</p>
            <p class="body-2">{{ surgeryFully.sus_code || "-" }}</p>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import formatUtil from "utils/formatUtil";
import snackBarUtil from "utils/snackBarUtil";
import surgerieService from "services/surgerieService";

export default {
   name: "ViewSurgery",

   props: {
      surgery: {
         type: Object,
      },

      surgerySusCode: {
         type: String,
         default: null,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         surgeryFully: {},
         surgeryLoading: false,
      };
   },

   watch: {
      surgery: {
         immediate: true,
         handler(surgery) {
            this.surgeryFully = {};
            this.surgeryFully = Object.assign({}, surgery);
         },
      },

      surgerySusCode: {
         immediate: true,
         handler(surgerySusCode) {
            if (surgerySusCode) this.getSurgeryBySusCode(surgerySusCode);
         },
      },
   },

   methods: {
      ...formatUtil,

      async getSurgeryBySusCode(susCode) {
         this.surgeryLoading = true;

         try {
            const response = await surgerieService.getAllSurgeries({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "sus_code",
                     query: susCode,
                  },
               ],
            });

            this.surgeryFully = Object.assign({}, response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.surgeryLoading = false;
         }
      },
   },
};
</script>

<style>
</style>