var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"500","transition":"slide-y-transition"},model:{value:(_vm.dialogResetPasswordFormVisibility),callback:function ($$v) {_vm.dialogResetPasswordFormVisibility=$$v},expression:"dialogResetPasswordFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeResetPasswordForm}}),_c('ResetPasswordUsersForm',{staticClass:"standard-padding",attrs:{"user":_vm.patientResetPasswordSelected},on:{"onClickCancel":_vm.closeResetPasswordForm,"onResetPassword":_vm.closeResetPasswordForm}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"1000","transition":"slide-y-transition"},model:{value:(_vm.dialogPatientFormVisibility),callback:function ($$v) {_vm.dialogPatientFormVisibility=$$v},expression:"dialogPatientFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closePatientForm}}),(_vm.userStore.sub_type === 'admin')?_c('div',[_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"id":"pacient-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-account ")]),_vm._v(" PACIENTE ")],1),(_vm.patientSelected.id)?_c('v-tab',{attrs:{"id":"health_entities-pacient-button"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-home-city ")]),_vm._v(" ENTIDADES DO PACIENTE ")],1):_vm._e()],1),(_vm.activeTab === 0)?_c('div',[(_vm.userStore.sub_type === 'admin')?_c('PatientAdminForm',{staticClass:"standard-padding",attrs:{"patient":_vm.patientSelected,"editMode":_vm.editMode,"hideTitle":"","hideRemoveButton":""},on:{"onClickCancel":_vm.closePatientForm,"onCreatedPatient":function($event){_vm.closePatientForm();
                     _vm.refreshPatientList();},"onUpdatedPatient":function($event){_vm.closePatientForm();
                     _vm.refreshPatientList();},"onClickResetPatient":_vm.onClickResetPatient,"onClickRemovePatient":_vm.onClickRemovePatient,"onFindDisabledPatient":_vm.onFindDisabledPatient,"onFindExistentPatient":_vm.onFindExistentPatient}}):_vm._e()],1):(_vm.activeTab === 1 && _vm.patientSelected.id)?_c('div',[_c('PatientHealthEntity',{staticClass:"standard-padding",attrs:{"patient":_vm.patientSelected},on:{"onUpdatedPatient":function($event){_vm.closePatientForm();
                     _vm.refreshPatientList();}}})],1):_vm._e()],1):_c('PatientForm',{staticClass:"standard-padding",attrs:{"patient":_vm.patientSelected,"healthEntity":_vm.operatorCurrentHealthUnitStore ||
                  _vm.healthSecretaryManagerCurrentHealthUnitStore,"hideRemoveButton":!['solicitator', 'secretary_manager'].includes(
                  _vm.userStore.sub_type
               ),"hideEditButton":_vm.hideEditButtonOperator,"editMode":_vm.editMode},on:{"onClickCancel":_vm.closePatientForm,"onCreatedPatient":function($event){_vm.closePatientForm();
               _vm.refreshPatientList();},"onUpdatedPatient":function($event){_vm.closePatientForm();
               _vm.refreshPatientList();},"onClickResetPatient":_vm.onClickResetPatient,"onClickRemovePatient":_vm.onClickRemovePatient,"onFindDisabledPatient":_vm.onFindDisabledPatient,"onFindExistentPatient":_vm.onFindExistentPatient}})],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('PatientList',{ref:"patientlist",staticClass:"mt-12",attrs:{"hideRemoveButton":!['solicitator', 'secretary_manager'].includes(_vm.userStore.sub_type)},on:{"onClickAddPatient":_vm.onClickAddPatient,"onClickViewPatient":_vm.onClickViewPatient,"onClickEditPatient":_vm.onClickEditPatient,"onClickRemovePatient":_vm.onClickRemovePatient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }