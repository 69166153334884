<template>
   <div>
      <v-alert
         v-if="getMode() === 'read' && formPatient.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Paciente cadastrado em
         {{
            formatDate(formPatient.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formPatient.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        value => !!value || 'CPF obrigatório.',
                        value => cpfValidation(value) || 'CPF Inválido.'
                     ]"
                     :loading="loadingPatientByCpf"
                     :readonly="!!formPatient.id"
                     id="cpf-patient-form-input"
                     label="CPF*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formPatient.name"
                     :rules="[value => !!value || 'Nome obrigatório.']"
                     id="name-patient-form-input"
                     label="Nome*"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-select
                     v-model="formPatient.gender"
                     :items="genderItems"
                     :rules="[value => !!value || 'Sexo obrigatório.']"
                     label="Sexo*"
                     id="gender-patient-form-input"
                  >
                     <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                           <v-list-item-content
                              :id="`item-${attrs.id.split('-')[3]}`"
                           >
                              <v-list-item-title>
                                 {{ item.text }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formPatient.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        value => !!value || 'Telefone obrigatório.',
                        value =>
                           (value && value.length >= 14) || 'Telefone inválido.'
                     ]"
                     id="phone-patient-form-input"
                     label="Telefone*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formPatient.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        value => !!value || 'Data de nascimento obrigatória.',
                        value => dateValidation(value) || 'Data inválida.'
                     ]"
                     id="birth_date-patient-form-input"
                     label="Data de nascimento*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formPatient.address.birth_city"
                     :rules="[
                        value => !!value || 'Cidade de nascimento obrigatória.'
                     ]"
                     label="Cidade de nascimento*"
                     id="birth_city-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formPatient.sus_card"
                     v-maska="'###############'"
                     :rules="[
                        value =>
                           !value
                              ? true
                              : (value && value.length >= 15) ||
                                'Cartão do SUS inválido.'
                     ]"
                     id="sus_card-patient-form-input"
                     label="Cartão do SUS (Opcional)"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formPatient.rg"
                     v-maska="'##########'"
                     :readonly="!!formPatient.id"
                     label="RG (Opcional)"
                     id="rg-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formPatient.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        value => !!value || 'CEP obrigatório.',
                        value =>
                           (value && value.length >= 8) || 'CEP inválido.',
                        value =>
                           cepParaibaOnlyValidation(value) ||
                           'O CEP informado não é da Paraíba.'
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-patient-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formPatient.address.street"
                     :rules="[value => !!value || 'Rua obrigatória.']"
                     id="street-patient-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formPatient.address.number"
                     :rules="[value => !!value || 'Número obrigatório.']"
                     id="number-patient-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formPatient.address.district"
                     :rules="[value => !!value || 'Bairro obrigatório.']"
                     id="district-patient-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="8">
                  <MunicipalityInput
                     @onNotFindMunicipality="clearPatientCity"
                     @onSelectMunicipality="selectMunicipality"
                     :municipalityName="formPatient.address.city"
                     description="Cidade"
                     ref="municipalityinput"
                     required
                  />
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formPatient.address.complement"
                     id="complement-patient-form-input"
                     label="Complemento (Opcional)"
                  ></v-text-field>
               </v-col>
            </v-row>

            <div v-if="!formPatient.id">
               <p class="body-1 font-weight-medium mt-10 mb-4">
                  Entidade de saúde
               </p>

               <v-btn-toggle v-model="entitiesTab" class="flex-wrap mb-2" dense>
                  <v-btn class="overline-button primary--text">
                     <v-icon class="mr-1" color="primary" small
                        >mdi-hospital-building</v-icon
                     >
                     UNIDADE DE SAÚDE
                  </v-btn>
                  <v-btn class="overline-button error--text">
                     <v-icon class="mr-1" color="error" small
                        >mdi-home-plus</v-icon
                     >
                     SECRETARIA DE SAÚDE
                  </v-btn>
               </v-btn-toggle>

               <div v-if="entitiesTab === 0" class="mb-3">
                  <HealthUnitInput
                     @onSelectHealthUnit="onSelectHealthUnit"
                     ref="patienthealthunitinput"
                  />
               </div>
               <div v-else-if="entitiesTab === 1" class="mb-3">
                  <HealthSecretarieInput
                     @onSelectHealthSecretarie="onSelectHealthSecretary"
                     ref="patienthealthsecretaryinput"
                  />
               </div>
            </div>

            <p class="body-1 font-weight-medium mt-8">Dados de autenticação</p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formPatient.email"
                     :rules="[
                        value =>
                           !value
                              ? true
                              : emailValidation(value) || 'Email inválido.'
                     ]"
                     id="email-patient-form-input"
                     label="Email (Opcional)"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formPatient.id" cols="12" md="6">
                  <v-text-field
                     v-model="formPatient.password"
                     :rules="[
                        value =>
                           !value
                              ? true
                              : passwordValidation(value) ||
                                'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.'
                     ]"
                     id="password-patient-form-input"
                     label="Senha (mínimo 6 caracteres) (Opcional)"
                     type="password "
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <UsersFormActions
         v-if="!hideActions"
         @onClickReset="onClickResetPatient(patient)"
         @onClickEdit="onClickEditPatient"
         @onClickRemove="onClickRemovePatient(patient)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSavePatient"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSavePatient"
         :validationErrors="validationErrors"
         hideChangeTypeButton
         class="mt-6"
      />
   </div>
</template>

<script>
import UsersFormActions from "components/users/UsersFormActions";
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import MunicipalityInput from "components/municipality/municipalityInput/MunicipalityInput";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import patientService from "../../services/patientService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "utils/defaultUtil";

export default {
   name: "PatientAdminForm",

   components: {
      UsersFormActions,
      HealthUnitInput,
      HealthSecretarieInput,
      MunicipalityInput
   },

   props: {
      patient: {
         type: Object
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      editMode: {
         type: Boolean,
         default: false
      }
   },

   data: function() {
      return {
         mode: "read",

         formPatient: {},

         loadingSavePatient: false,
         loadingSearchZipCode: false,
         loadingPatientByCpf: false,

         hideActions: false,

         validationErrors: [],

         genderItems: [
            {
               text: "Masculino",
               value: "male"
            },
            {
               text: "Feminino",
               value: "female"
            }
         ],

         entitiesTab: 0,
         healthUnitSelected: {},
         healthSecretarySelected: {}
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      }
   },

   watch: {
      patient: {
         immediate: true,
         handler(patient) {
            if (patient.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];
            this.healthUnitSelected = {};
            this.healthSecretarySelected = {};
            if (!patient.id) this.clearMunicipalityInput();

            this.formPatient = this.formatObjectToView(patient);

            this.resetValidation();
         }
      },

      "formPatient.address.zip_code": async function handler(newZip, oldZip) {
         if (
            newZip &&
            newZip.length === 8 &&
            oldZip &&
            oldZip.length === 7 &&
            this.cepParaibaOnlyValidation(newZip)
         ) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info"
                  });
               } else {
                  if (response.data.uf.toUpperCase() === "PB") {
                     this.formPatient.address.street = response.data.logradouro;
                     this.formPatient.address.district = response.data.bairro;
                     this.formPatient.address.city = response.data.localidade;
                     this.formPatient.address.state = response.data.uf;
                     if (response.data.complemento)
                        this.formPatient.address.complement =
                           response.data.complemento;
                  }
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formPatient.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getPatientByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      }
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(patient) {
         let patientTemp = util.mergeObject(
            {
               address: {
                  state: "PB"
               }
            },
            patient
         );

         if (patientTemp.birth_date)
            patientTemp.birth_date = formatUtil.formatDate(
               patientTemp.birth_date
            );

         if (patientTemp.cpf)
            patientTemp.cpf = formatUtil.formatCPF(patientTemp.cpf);

         if (patientTemp.phone)
            patientTemp.phone = formatUtil.formatPhone(patientTemp.phone);

         return patientTemp;
      },

      formatObjectToSend(patient, removeEmptyStrings) {
         let patientTemp = util.removeBlankAttributes(patient, {
            emptyStrings: removeEmptyStrings
         });

         if (patientTemp.birth_date)
            patientTemp.birth_date = formatUtil.formatDate(
               patientTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (patientTemp.cpf)
            patientTemp.cpf = patientTemp.cpf.replace(/[^0-9]/g, "");

         if (patientTemp.phone)
            patientTemp.phone = patientTemp.phone.replace(/[^0-9]/g, "");

         return patientTemp;
      },

      onClickResetPatient(patient) {
         this.$emit("onClickResetPatient", patient);
      },

      onClickEditPatient() {
         this.setMode("save");
      },

      onClickRemovePatient(patient) {
         this.$emit("onClickRemovePatient", patient);
      },

      onClickCancel() {
         this.setMode("read");
         this.formPatient = this.formatObjectToView(this.patient);
         this.$emit("onClickCancel");
      },

      onClickSavePatient() {
         if (this.formPatient.id)
            this.updatePatient(this.formPatient.id, this.formPatient);
         else {
            if (this.entitiesTab === 0)
               this.createPatient(this.formPatient, this.healthUnitSelected.id);
            else if (this.entitiesTab === 1)
               this.createPatient(
                  this.formPatient,
                  this.healthSecretarySelected.id
               );
         }
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter(item => item.hasError && item.label)
               .map(item => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
         if (this.$refs.patienthealthunitinput)
            this.$refs.patienthealthunitinput.reset();
         if (this.$refs.patienthealthsecretaryinput)
            this.$refs.patienthealthsecretaryinput.reset();
      },

      clearMunicipalityInput() {
         if (this.$refs.municipalityinput) this.$refs.municipalityinput.reset();
      },

      clearPatientCity() {
         delete this.formPatient.address.city;
      },

      selectMunicipality(municipality) {
         this.formPatient.address.city = municipality.name;
      },

      async getPatientByCpf(cpf) {
         this.loadingPatientByCpf = true;

         try {
            const response = await patientService.getPatientByCpf(cpf);

            if (response.data && response.data.id) {
               this.formPatient = this.formatObjectToView(response.data);

               snackBarUtil.showCustomSnackBar({
                  title: "PACIENTE JÁ EXISTENTE",
                  message: "Um paciente com esse CPF foi encontrado!",
                  icon: "mdi-account",
                  color: "info"
               });
            }
         } catch (error) {
            if (error.response && error.response.status === 404) {
               const patientResponse = await checkCpfService
                  .getCheckCpf(cpf)
                  .catch(() =>
                     snackBarUtil.showCustomSnackBar({
                        title: "CPF NÃO ENCONTRADO",
                        message:
                           "O CPF informado não foi encontrado na base de dados!",
                        icon: "mdi-account-off",
                        color: "info"
                     })
                  );

               delete this.formPatient.name;
               if (
                  patientResponse &&
                  patientResponse.data &&
                  patientResponse.data.name !== ""
               )
                  this.formPatient.name = patientResponse.data.name;
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPatientByCpf = false;
         }
      },

      async createPatient(patient, healthEntityId) {
         if (!this.executeValidation() || !patient || !healthEntityId) return;

         this.loadingSavePatient = true;

         patient.registrant_id = this.userStore.sub;
         patient.healthentity_id = healthEntityId;

         try {
            await patientService.createPatient(
               this.formatObjectToSend(patient)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O paciente foi cadastrado!"
            });

            this.setMode("read");
            this.$emit("onCreatedPatient");
         } catch (error) {
            if (error.response && error.response.status === 403) {
               snackBarUtil.showCustomSnackBar({
                  title: "PACIENTE DE OUTRO MUNICÍPIO",
                  message:
                     "Paciente de outro município, você não tem permissão para cadastrá-lo!",
                  icon: "mdi-map-marker",
                  color: "warning"
               });
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSavePatient = false;
         }
      },

      async updatePatient(patientId, patient) {
         if (!this.executeValidation() || !patientId || !patient) return;

         this.loadingSavePatient = true;

         patient.registrant_id = this.userStore.sub;

         try {
            await patientService.updatePatient(
               patientId,
               this.formatObjectToSend(patient, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O paciente foi atualizado!"
            });

            this.setMode("read");
            this.$emit("onUpdatedPatient");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSavePatient = false;
         }
      },

      cepParaibaOnlyValidation(cep) {
         if (cep && cep.length >= 8)
            return parseInt(cep) >= 58000000 && parseInt(cep) <= 58999999;

         return false;
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      onSelectHealthSecretary(healthSecretary) {
         this.healthSecretarySelected = Object.assign({}, healthSecretary);
      }
   }
};
</script>

<style scoped>
.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}
</style>
