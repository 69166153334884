<template>
   <div>
      <p class="px-6">
         Selecione a unidade de saúde/secretaria que deseja acessar
      </p>
      <v-divider class="mt-5"></v-divider>

      <div v-if="loadingUser">
         <SelectHealthUnitDetailsSkeleton v-for="item in 1" :key="item" />
      </div>
      <div v-else-if="healthUnits.length || healthSecretariesIds.length">
         <SelectHealthUnitDetails 
            v-for="(health_unit, index) in healthUnits" 
            @onSelectHealthUnit="selectHealthEntity"
            @onGetHealthUnit="getHealthEntity" 
            :key="health_unit.id" 
            :healthUnitInfo="health_unit"
            :id="`item-${index}-select-health-unit-button`" 
         />

         <SelectHealthSecretarieDetails 
            v-for="(id, index) in healthSecretariesIds"
            @onSelectHealthSecretarie="selectHealthEntity"
            @onGetHealthSecretarie="getHealthEntity" 
            :key="id"
            :healthSecretarieId="id" 
            :id="`item-${index}-select-health-unit-button`" 
         />
      </div>
      <div v-else>
         <div class="d-flex flex-column align-center">
            <NoData />

            <v-btn @click="logout" class="mb-2 mt-1" color="primary" depressed small>Sair
               <v-icon small right>mdi-logout</v-icon>
            </v-btn>
         </div>
      </div>
   </div>
</template>

<script>
import SelectHealthUnitDetailsSkeleton from "./SelectHealthUnitDetailsSkeleton";
import SelectHealthUnitDetails from "./SelectHealthUnitDetails";
import SelectHealthSecretarieDetails from "components/healthSecretarie/selectHealthSecretarie/SelectHealthUnitDetails";
import NoData from "../../base/NoData";
import snackBarUtil from "../../../utils/snackBarUtil";
import healthUnitManagerService from "../../../services/healthUnitManagerService";
import operatorService from "../../../services/operatorService";
import userService from "../../../services/userService";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";

export default {
   name: "SelectHealthUnit",

   components: {
      SelectHealthSecretarieDetails,
      SelectHealthUnitDetails,
      SelectHealthUnitDetailsSkeleton,
      NoData,
   },

   props: {
      user: {
         type: Object,
      },
   },

   data: function () {
      return {
         loadingUser: false,
         healthUnits: [],
         healthSecretariesIds: [],
      };
   },

   computed: {
      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         },
      },
   },

   watch: {
      user: {
         immediate: true,
         handler(user, oldUser) {
            if (user && user.sub && user.sub_type) {
               if (oldUser && oldUser.sub && user.sub === oldUser.sub) return;

               this.healthUnits = [];
               this.healthSecretariesIds = [];

               switch (user.sub_type) {
                  case "executor":
                  case "solicitator":
                     this.getOperatorById(user.sub);
                     break;

                  case "healthunit_manager":
                     this.getHealthUnitManagerById(user.sub);
                     break;

                  case "secretary_manager":
                     this.getHealthSecretaryManagerById(user.sub);
                     break;
               }
            }
         },
      },
   },

   methods: {
      logout() {
         userService.removeUserSession();

         if (this.$route.path !== "/autenticacao")
            this.$router.push({ path: "/autenticacao" });
      },

      async getOperatorById(operatorId) {
         this.loadingUser = true;

         try {
            const response = await operatorService.getOperatorById(operatorId);
            this.fullUserStore = Object.assign({}, response.data);
            this.healthUnits = this.fullUserStore.health_units;

            // Só procura por secretarias de saúde caso o operador esteja logado como solicitante
            if (this.user.sub_type === "solicitator")
               this.healthSecretariesIds =
                  this.fullUserStore.health_secretaries;
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingUser = false;
         }
      },

      async getHealthUnitManagerById(healthUnitManagerId) {
         this.loadingUser = true;

         try {
            const response =
               await healthUnitManagerService.getHealthUnitManagerById(
                  healthUnitManagerId
               );

            this.fullUserStore = Object.assign({}, response.data);
            this.healthUnits = [{ id: this.fullUserStore.healthunit_id }];
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingUser = false;
         }
      },

      async getHealthSecretaryManagerById(healthSecretaryManagerId) {
         this.loadingUser = true;

         try {
            const response =
               await healthSecretaryManagerService.getHealthSecretaryManagerById(
                  healthSecretaryManagerId
               );

            this.fullUserStore = Object.assign({}, response.data);
            this.healthSecretariesIds = [this.fullUserStore.healthsecretary_id];
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingUser = false;
         }
      },

      selectHealthEntity(healthEntity) {
         const localStorageData = userService.getUserSession();
         localStorageData.healthUnit = healthEntity;
         userService.setUserSession(localStorageData);

         this.refreshPermission();
         this.$emit("onSelectHealthUnit", healthEntity);
      },

      // Se o usuário tiver 1 entidade, seja ela unidade de saúde ou secretaria, já seta automaticamente.
      getHealthEntity(healthEntity) {
         if (
            this.healthUnits.length + this.healthSecretariesIds.length ===
            1
         )
            this.selectHealthEntity(healthEntity);
      },

      refreshPermission() {
         if (this.$store.getters["user/getUser"]) {
            const routesList = this.$store.getters["user/getUserRoutes"];
            if (!routesList.includes(this.$router.currentRoute.fullPath))
               this.$router.push({ path: routesList[0] });
         }
      }
   },
};
</script>

<style scoped></style>