<template>
   <div>
      <v-switch
         v-model="doctorOpinion.patient_not_fit_for_regulation"
         label="Paciente não preenche criterios para regulação"
         id="patient-not-fit-for-regulation-doctor-opinion-form-input"
      ></v-switch>

      <div v-if="!doctorOpinion.patient_not_fit_for_regulation" class="mt-2">
         <p class="caption mb-1">Prioridade*</p>

         <v-chip-group
            v-model="doctorOpinion.priority"
            @change="(value) => (invalidPriority = !value)"
            class="my-n2"
            column
         >
            <v-chip
               color="info"
               value="blue"
               small
               filter
               id="blue-doctor-opinion-form-input"
            >
               Azul
            </v-chip>
            <v-chip
               color="success"
               value="green"
               small
               filter
               id="green-doctor-opinion-form-input"
            >
               Verde
            </v-chip>
            <v-chip
               color="amber"
               value="yellow"
               small
               filter
               id="yellow-doctor-opinion-form-input"
            >
               Amarela
            </v-chip>
            <v-chip
               color="warning"
               value="orange"
               small
               filter
               id="orange-doctor-opinion-form-input"
            >
               Laranja
            </v-chip>
            <v-chip
               color="error"
               value="red"
               small
               filter
               id="red-doctor-opinion-form-input"
            >
               Vermelha
            </v-chip>
         </v-chip-group>

         <v-expand-transition>
            <p v-if="invalidPriority" class="caption error--text mt-1" id="required-priority-doctor-opnion-form-text">
               Prioridade obrigatória.
            </p>
         </v-expand-transition>

         <p class="caption mb-1 mt-4">Tipo de leito*</p>
         <v-chip-group
            v-model="doctorOpinion.bed_type"
            @change="(value) => (invalidBedType = !value)"
            class="my-n2"
            column
         >
            <v-chip
               :color="
                  doctorOpinion.bed_type === 'clinical' ? 'primary' : 'normal'
               "
               value="clinical"
               filter
               small
               id="clinical-doctor-opinion-form-input"
            >
               Leito clínico
            </v-chip>
            <v-chip
               :color="
                  doctorOpinion.bed_type === 'surgical' ? 'primary' : 'normal'
               "
               value="surgical"
               filter
               small
               id="surgical-doctor-opinion-form-input"
            >
               Leito cirúrgico
            </v-chip>
            <v-chip
               :color="doctorOpinion.bed_type === 'uti' ? 'primary' : 'normal'"
               value="uti"
               filter
               small
               id="uti-doctor-opinion-form-input"
            >
               Leito de UTI
            </v-chip>
         </v-chip-group>

         <v-expand-transition>
            <p v-if="invalidBedType" class="caption error--text mt-1"  id="required-bed_type-doctor-opnion-form-text">
               Tipo de leito obrigatório.
            </p>
         </v-expand-transition>
      </div>

      <v-row class="mt-2">
         <v-col cols="12">
            <v-textarea
               v-model="doctorOpinion.justification"
               :rules="[(value) => !!value || 'Justificativa obrigatória.']"
               class="justification-height"
               label="Justificativa*"
               rows="8"
               no-resize
               id="justification-doctor-opinion-form-input"
            ></v-textarea>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "DoctorOpinionForm",

   props: {
      doctorOpinion: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },

   data: function () {
      return {
         invalidPriority: false,
         invalidBedType: false,
      };
   },

   methods: {
      resetValidation() {
         this.invalidPriority = false;
         this.invalidBedType = false;
      },

      validate() {
         if (!this.doctorOpinion.patient_not_fit_for_regulation) {
            this.invalidPriority = !this.doctorOpinion.priority;
            this.invalidBedType = !this.doctorOpinion.bed_type;

            return !this.invalidPriority && !this.invalidBedType;
         } else return true;
      },
   },
};
</script>

<style scoped>
.justification-height {
   height: 100%;
}
</style>