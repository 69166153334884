import { render, staticRenderFns } from "./DoctorOpinionForm.vue?vue&type=template&id=dfa9a4f4&scoped=true&"
import script from "./DoctorOpinionForm.vue?vue&type=script&lang=js&"
export * from "./DoctorOpinionForm.vue?vue&type=script&lang=js&"
import style0 from "./DoctorOpinionForm.vue?vue&type=style&index=0&id=dfa9a4f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa9a4f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VChip,VChipGroup,VCol,VExpandTransition,VRow,VSwitch,VTextarea})
