<template>
   <v-alert
      class="ma-0 d-flex flex-column justify-center"
      type="info"
      border="left"
      height="100%"
      icon="mdi-check"
      prominent
      text
   >
      <p class="body-1 font-weight-medium">SOLICITAÇÃO FINALIZADA!</p>

      <p class="body-2">
         Finalizada por <strong>{{ outcomeTranslated }}</strong> em
         {{ formatDate(request.outcome_date, "DD/MM/YYYY [às] HH:mm") }}.
      </p>

      <p v-if="request.outcome_justification" class="body-2">
         {{ request.outcome_justification }}
      </p>
   </v-alert>
</template>

<script>
import formatUtil from "utils/formatUtil";

export default {
   name: "OutcomeDetails",

   props: {
      request: {
         type: Object,
      },
   },

   computed: {
      outcomeTranslated() {
         let result = "Alta médica";

         switch (this.request.outcome) {
            case "death":
               result = "Óbito";
               break;
            case "transfer":
               result = "Transferência";
               break;
         }

         return result;
      },
   },

   methods: {
      ...formatUtil,
   },
};
</script>

<style scoped>
</style>