<template>
   <v-row class="ma-n2">
      <v-col class="pa-2" cols="12" md="6">
         <v-alert
            v-if="sourceHealthUnit"
            class="ma-0 d-flex flex-column justify-center"
            color="orange"
            border="left"
            icon="$source-hospital"
            height="100%"
            prominent
            text
         >
            <p
               class="body-1 font-weight-medium mb-1"
               id="origin_unit-origin-and-destiny-details-label"
            >
               UNIDADE DE ORIGEM
            </p>

            <p class="body-2" id="source_healthunit_name-origin-details">
               {{ sourceHealthUnit.name }}
            </p>

            <p
               v-if="sourceHealthUnit.address"
               class="body-2"
               id="source_healthunit_address-origin-details"
            >
               {{ sourceHealthUnit.address.city }},
               {{ sourceHealthUnit.address.state }},
               {{ sourceHealthUnit.address.street }}
            </p>
         </v-alert>
      </v-col>

      <v-col class="pa-2" cols="12" md="6">
         <v-alert
            v-if="targetHealthUnit"
            class="ma-0 d-flex flex-column justify-center"
            color="success"
            border="left"
            icon="$target-hospital"
            height="100%"
            prominent
            text
         >
            <p
               class="body-1 font-weight-medium mb-1"
               id="destiny_unit-origin-and-destiny-details-label"
            >
               UNIDADE DE DESTINO
            </p>

            <p class="body-2" id="source_healthunit_name-destiny-details">
               {{ targetHealthUnit.name }}
            </p>

            <p
               v-if="targetHealthUnit.address"
               class="body-2"
               id="source_healthunit_address-destiny-details"
            >
               {{ targetHealthUnit.address.city }},
               {{ targetHealthUnit.address.state }},
               {{ targetHealthUnit.address.street }}
            </p>
         </v-alert>
      </v-col>
   </v-row>
</template>

<script>
export default {
   name: "OriginAndDestinyDetails",

   props: {
      sourceHealthUnit: {
         type: Object,
      },

      targetHealthUnit: {
         type: Object,
      },
   },
};
</script>

<style scoped>
::v-deep #source-hospital {
   transform: translate(0px, -2px) scale(1.6);
}

::v-deep #target-hospital {
   transform: translate(3px, -5px) scale(2);
}
</style>