<template>
   <div class="div-scrollable">
      <v-card
         v-if="!options.xaxis.categories.length"
         class="d-flex flex-column align-center justify-center"
         height="100%"
         flat
      >
         <p class="text-center text--secondary">Nenhum município encontrado.</p>
      </v-card>

      <apexchart
         v-else
         :options="options"
         :series="series"
         :height="chartHeight"
         :key="chartInputKey"
         class="mt-n3"
         type="bar"
      ></apexchart>
   </div>
</template>

<script>
export default {
   name: "RegulationByMunicipalityChart",

   props: {
      statistics: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         options: {
            colors: [
               function ({ value }) {
                  if (value <= 100) return "#eedddb";
                  else if (value > 100 && value <= 300) return "#e2bfbb";
                  else if (value > 300 && value <= 700) return "#d99f9a";
                  else if (value > 700 && value <= 1500) return "#e4857c";
                  else return "#bc685f";
               },
            ],

            states: {
               hover: {
                  filter: {
                     type: "darken",
                     value: 0.7,
                  },
               },
            },

            chart: {
               id: "attention-level-chart",
               toolbar: {
                  show: false,
               },
            },

            xaxis: {
               categories: [],
               labels: {
                  show: false,
               },
            },

            yaxis: {
               labels: {
                  offsetY: 2,
                  offsetX: 2,
                  style: {
                     fontSize: "11px",
                     fontFamily: "Roboto, sans-serif",
                     fontWeight: "regular",
                     colors: ["#3b3b3b"],
                  },
               },
            },

            plotOptions: {
               bar: {
                  dataLabels: {
                     position: "top",
                  },
                  borderRadius: 5,
                  horizontal: true,
               },
            },

            dataLabels: {
               offsetX: 30,
               style: {
                  fontSize: "10px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  colors: ["#3b3b3b"],
               },
            },

            tooltip: {
               theme: "dark",
               x: {
                  show: false,
               },
               marker: {
                  show: false,
               },
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },

            legend: {
               show: false,
            },
         },

         chartInputKey: 0,
      };
   },

   watch: {
      statistics: {
         immediate: true,
         handler(statistics) {
            if (Array.isArray(statistics)) {
               this.options.xaxis.categories = statistics.map(
                  (item) => item.name
               );

               this.forceChartInputRerender();
            }
         },
      },
   },

   computed: {
      series() {
         let result = [
            {
               name: "Regulaçõe(s)",
               data: [],
            },
         ];

         if (this.statistics.length > 0)
            result[0].data = this.statistics.map((item) => item.total);

         return result;
      },

      chartHeight() {
         let result = this.options.xaxis.categories.length * 45;

         if (this.options.xaxis.categories.length <= 1)
            result = this.options.xaxis.categories.length * 90;
         else if (this.options.xaxis.categories.length <= 3)
            result = this.options.xaxis.categories.length * 75;
         else if (this.options.xaxis.categories.length <= 8)
            result = this.options.xaxis.categories.length * 60;

         return result;
      },
   },

   methods: {
      forceChartInputRerender() {
         this.chartInputKey += 1;
      },
   },
};
</script>

<style scoped>
::v-deep::-webkit-scrollbar {
   width: 0px;
   background-color: white;
}

::v-deep::-webkit-scrollbar-thumb {
   background-color: #c2c2c2;
}

.div-scrollable {
   overflow-y: scroll;
   height: 600px;
}

@media (min-width: 1263px) {
   .div-scrollable {
      overflow-y: scroll;
      height: 550px;
   }
}

@media (min-width: 959px) and (max-width: 1263px) {
   .div-scrollable {
      overflow-y: scroll;
      height: 500px;
   }
}

@media (max-width: 959px) {
   .div-scrollable {
      overflow-y: hidden;
      height: 100%;
   }
}
</style>