<template>
   <div>
      <FormTitle icon="mdi-account-hard-hat" title="OPERADOR" />

      <v-alert
         v-if="getMode() === 'read' && formOperator.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Operador cadastrado em
         {{
            formatDate(formOperator.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     :loading="loadingOperatorByCpf"
                     :readonly="!!formOperator.id"
                     label="CPF*"
                     id="cpf-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formOperator.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     label="Nome*"
                     id="name-operator-health-unit-manager-form-input"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     label="Telefone*"
                     id="phone-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     label="Data de nascimento*"
                     id="birth_date-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     maxlength="8"
                     label="CEP*"
                     id="zip_code-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     label="Rua*"
                     id="street-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formOperator.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     label="Número*"
                     id="number-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formOperator.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     label="Bairro*"
                     id="district-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     label="Cidade*"
                     id="city-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formOperator.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                     id="state-operator-health-unit-manager-form-input"
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formOperator.address.complement"
                     label="Complemento"
                     id="complement-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Dados de autenticação</p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     label="Email*"
                     id="email-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formOperator.id" cols="12" md="6">
                  <v-text-field
                     v-model="formOperator.password"
                     :rules="[
                        (value) => !!value || 'Senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     label="Senha* (mínimo 6 caracteres)"
                     type="password"
                     id="password-operator-health-unit-manager-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <UsersFormActions
         v-if="!hideActions"
         @onClickReset="onClickResetOperator(operator)"
         @onClickEdit="onClickEditOperator"
         @onClickRemove="onClickRemoveOperator(operator)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveOperator"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :hideChangeTypeButton="hideChangeTypeButton"
         :loadingSave="loadingSaveOperator"
         :validationErrors="validationErrors"
         class="mt-9"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import UsersFormActions from "components/users/UsersFormActions";
import zipCodeService from "../../services/zipCodeService";
import operatorService from "../../services/operatorService";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import responsiveUtil from "../../utils/responsiveUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "utils/defaultUtil";

export default {
   name: "OperatorHealthSecretaryManagerForm",

   components: { FormTitle, UsersFormActions },

   props: {
      operator: {
         type: Object,
      },

      healthSecretary: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideChangeTypeButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formOperator: {},

         loadingSaveOperator: false,
         loadingSearchZipCode: false,

         loadingOperatorByCpf: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         hideActions: false,

         validationErrors: [],
      };
   },

   watch: {
      operator: {
         immediate: true,
         handler(operator) {
            this.hideActions = false;

            if (operator.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formOperator = this.formatObjectToView(operator);

            this.resetValidation();
         },
      },

      "formOperator.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;
            try {
               const response = await zipCodeService.getZipCode(newZip);
               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formOperator.address.street = response.data.logradouro;
                  this.formOperator.address.district = response.data.bairro;
                  this.formOperator.address.city = response.data.localidade;
                  this.formOperator.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formOperator.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formOperator.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getOperatorByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      },
   },

   computed: {
      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...responsiveUtil,

      formatObjectToView(operator) {
         let operatorTemp = util.mergeObject(
            {
               address: {},
               health_units: [],
            },
            operator
         );

         if (operatorTemp.birth_date)
            operatorTemp.birth_date = formatUtil.formatDate(
               operatorTemp.birth_date
            );

         if (operatorTemp.cpf)
            operatorTemp.cpf = formatUtil.formatCPF(operatorTemp.cpf);

         if (operatorTemp.phone)
            operatorTemp.phone = formatUtil.formatPhone(operatorTemp.phone);

         return operatorTemp;
      },

      formatObjectToSend(operator, removeEmptyStrings) {
         let operatorTemp = util.removeBlankAttributes(operator, {
            emptyStrings: removeEmptyStrings,
         });

         if (operatorTemp.birth_date)
            operatorTemp.birth_date = formatUtil.formatDate(
               operatorTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (operatorTemp.cpf)
            operatorTemp.cpf = operatorTemp.cpf.replace(/[^0-9]/g, "");

         if (operatorTemp.phone)
            operatorTemp.phone = operatorTemp.phone.replace(/[^0-9]/g, "");

         return operatorTemp;
      },

      onClickResetOperator(operator) {
         this.$emit("onClickResetOperator", operator);
      },

      onClickEditOperator() {
         this.setMode("save");
      },

      onClickRemoveOperator(operator) {
         this.$emit("onClickRemoveOperator", operator);
      },

      onClickCancel() {
         this.setMode("read");
         this.formOperator = this.formatObjectToView(this.operator);
         this.$emit("onClickCancel");
      },

      onClickSaveOperator() {
         if (this.formOperator.id)
            this.updateOperator(this.formOperator.id, this.formOperator);
         else this.createOperator(this.formOperator);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      associateOperatorToHealthSecretarieById() {
         this.hideActions = true;
         this.setMode("read");

         const methodAssociateItem = async () => {
            if (!this.formOperator.id || !this.healthSecretary.id) return;

            await operatorService.associateOperatorToHealthSecretarieById(
               this.formOperator.id,
               this.healthSecretary.id
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message:
                  "O operador foi associado a sua secretaria com sucesso!",
            });

            this.hideActions = false;
            this.$emit("onUpdatedOperator");
         };

         snackBarUtil.showCustomSnackBar({
            title: "OPERADOR JÁ EXISTENTE",
            message:
               "Um operador com esse CPF foi encontrado, deseja associá-lo a sua secretaria?",
            icon: "mdi-account-hard-hat",
            color: "info",
            action: {
               text: "Associar",
               method: methodAssociateItem,
               closeOnFinished: false,
            },
         });
      },

      async getOperatorByCpf(cpf) {
         this.loadingOperatorByCpf = true;

         try {
            const response = await operatorService.getOperatorByCpf(cpf);

            if (response.data) {
               this.formOperator = this.formatObjectToView(response.data);

               if (
                  this.formOperator.health_secretaries.includes(
                     this.healthSecretary.id
                  )
               ) {
                  snackBarUtil.showCustomSnackBar({
                     title: "OPERADOR JÁ EXISTENTE",
                     message: "Um operador com esse CPF foi encontrado!",
                     icon: "mdi-account-hard-hat",
                     color: "info",
                  });
               } else this.associateOperatorToHealthSecretarieById();
            }
         } catch (error) {
            if (error.response && error.response.status === 404) {
               const operatorResponse = await checkCpfService
                  .getCheckCpf(cpf)
                  .catch(() =>
                     snackBarUtil.showCustomSnackBar({
                        title: "CPF NÃO ENCONTRADO",
                        message:
                           "O CPF informado não foi encontrado na base de dados!",
                        icon: "mdi-account-off",
                        color: "info",
                     })
                  );

               delete this.formOperator.name;
               if (
                  operatorResponse &&
                  operatorResponse.data &&
                  operatorResponse.data.name !== ""
               )
                  this.formOperator.name = operatorResponse.data.name;
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingOperatorByCpf = false;
         }
      },

      async createOperator(operator) {
         if (
            !this.executeValidation() ||
            !operator ||
            !this.healthSecretary ||
            !this.healthSecretary.id
         )
            return;

         this.loadingSaveOperator = true;

         /* O id da unidade de saúde do operador é setado o mesmo id da unidade de saúde
          do gestor da unidade de saúde */
         this.formOperator.healthsecretary_id = this.healthSecretary.id;

         try {
            await operatorService.createOperator(
               this.formatObjectToSend(operator)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveOperator = false;
         }
      },

      async updateOperator(operatorId, operator) {
         if (!this.executeValidation() || !operatorId || !operator) return;

         this.loadingSaveOperator = true;

         try {
            await operatorService.updateOperator(
               operatorId,
               this.formatObjectToSend(operator, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O operador foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedOperator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveOperator = false;
         }
      },
   },
};
</script>

<style scoped>
</style>