<template>
   <div>
      <v-dialog
         v-if="filter === 'municipality_id&doctor_opinion.sus_code'"
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close
               @onClickClose="surgeryByMunicipalityCloseSelectMunicipality"
            />

            <SelectMunicipality
               @onSelectMunicipality="surgeryByMunicipalityOnSelectMunicipality"
               :loadMunicipalitiesOnCreated="false"
               class="standard-padding"
               alertMessage="Selecione o município que deseja visualizar as solicitações."
               ref="surgerybymunicipalityselectmunicipality"
               multiple
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-if="filter === 'sourcehealthentity_id&doctor_opinion.sus_code'"
         v-model="dialogSelectHealthEntityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)">
            <Close
               @onClickClose="surgeryByHealthEntityCloseSelectHealthEntity"
            />

            <div class="standard-padding pt-8">
               <HealthUnitInput
                  @onSelectHealthUnit="surgeryByHealthEntityOnAddHealthUnit"
                  :required="false"
                  class="body-2"
                  ref="surgerybyhealthentityhealthunitinput"
               />

               <NoData
                  v-if="!surgeryByHealthEntityHealthEntitiesSelected.length"
                  icon="mdi-hospital-building"
                  message="Selecione uma ou
                     mais entidades de saúde para filtrar."
               />

               <div v-else class="mt-3">
                  <div class="d-flex align-center">
                     <v-icon left small>mdi-hospital-building</v-icon>

                     <p class="caption">Entidade(s) de saúde selecionada(s):</p>
                  </div>

                  <v-row class="mt-0 mx-n2 mb-n2">
                     <v-col
                        v-for="(
                           item, index
                        ) in surgeryByHealthEntityHealthEntitiesSelected"
                        :key="index"
                        class="pa-2"
                        cols="6"
                        md="3"
                     >
                        <v-card
                           class="d-flex flex-column justify-center align-center standard-padding-x-small"
                           height="100%"
                           outlined
                        >
                           <v-icon class="mb-2" color="primary">
                              mdi-hospital-building
                           </v-icon>

                           <p class="caption text-center">{{ item.name }}</p>
                        </v-card>
                     </v-col>
                  </v-row>

                  <div class="ma-1">
                     <v-btn
                        @click="surgeryByHealthEntityOnSelectHealthUnit"
                        class="shadow-floating-primary-button d-none d-sm-flex ml-auto"
                        color="primary"
                        id="save-primary-form-button"
                        depressed
                        small
                        >Filtrar
                        <v-icon right small>mdi-check</v-icon>
                     </v-btn>
                     <v-btn
                        @click="surgeryByHealthEntityOnSelectHealthUnit"
                        class="shadow-floating-primary-button d-flex d-sm-none ml-auto"
                        color="primary"
                        id="save-secondary-form-button"
                        fab
                        small
                        x-small
                     >
                        <v-icon small>mdi-check</v-icon>
                     </v-btn>
                  </div>
               </div>
            </div>
         </v-card>
      </v-dialog>

      <div>
         <v-alert
            v-if="date"
            class="mb-5"
            color="primary"
            type="info"
            icon="mdi-calendar"
            border="left"
            dense
            text
         >
            <div class="d-flex justify-space-between align-center ma-n2">
               <p class="ma-2 body-2" style="word-break: break-all">
                  Você está visualizando as solicitações do mês de
                  <strong>{{
                     date
                        .map((item) => formatDate(item, "MMMM[/]YY"))
                        .join(" até ")
                  }}</strong
                  >.
               </p>

               <v-menu :close-on-content-click="false" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                     <div class="ma-2">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-none d-md-flex"
                           color="primary"
                           id="change-filter-date-surgery-request"
                           x-small
                           text
                           >Alterar data
                        </v-btn>

                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           class="d-flex d-md-none"
                           color="primary"
                           id="change-filter-date-surgery-request"
                           fab
                           text
                           x-small
                        >
                           <v-icon>mdi-calendar-edit</v-icon>
                        </v-btn>
                     </div>
                  </template>

                  <v-date-picker
                     @input="onChangeDate"
                     :value="date"
                     type="month"
                     locale="pt-br"
                     multiple
                     range
                     no-title
                  >
                  </v-date-picker>
               </v-menu>
            </div>
         </v-alert>

         <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center flex-grow-1">
               <v-menu
                  v-model="filterMenuVisibility"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  close-on-click
                  offset-y
               >
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex d-md-none"
                        color="primary"
                        :id="
                           userInfo
                              ? `filter-${userInfo}-secondary-list-header-button`
                              : 'filter-secondary-list-header-button'
                        "
                        fab
                        depressed
                        x-small
                     >
                        <v-icon>mdi-filter</v-icon>
                     </v-btn>

                     <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="d-none d-md-flex"
                        color="primary"
                        :id="
                           userInfo
                              ? `filter-${userInfo}-primary-list-header-button`
                              : 'filter-primary-list-header-button'
                        "
                        outlined
                     >
                        FILTRO
                        <v-icon right>mdi-filter</v-icon>
                     </v-btn>
                  </template>

                  <v-card :disabled="loading" class="standard-padding-x-small">
                     <div class="d-flex align-center">
                        <v-icon color="primary" small left>mdi-filter</v-icon>

                        <p class="caption font-weight-bold primary--text">
                           Filtrar por
                        </p>
                     </div>

                     <v-chip-group
                        @change="changeFilter"
                        :value="filter"
                        mandatory
                        column
                     >
                        <v-chip
                           v-for="(item, index) in filters"
                           :key="item.filter"
                           :color="filter === item.filter ? 'primary' : null"
                           :value="item.filter"
                           :id="`filter-item${index}-list-header-chip`"
                           filter
                           small
                        >
                           {{ item.text }}
                        </v-chip>
                     </v-chip-group>

                     <div class="d-flex align-center mt-1">
                        <v-icon color="primary" small left>mdi-sort</v-icon>

                        <p class="caption font-weight-bold primary--text">
                           Listar por
                        </p>
                     </div>

                     <v-chip-group
                        @change="changeSort"
                        :value="sort"
                        class="pa-0"
                        mandatory
                        column
                     >
                        <v-chip
                           v-for="(item, index) in sorts"
                           :key="item.sort"
                           :color="sort === item.sort ? 'primary' : null"
                           :value="item.sort"
                           :id="`sort-item${index}-list-header-chip`"
                           filter
                           small
                        >
                           {{ item.text }}
                        </v-chip>
                     </v-chip-group>
                  </v-card>
               </v-menu>

               <!-- Filtragem por paciente. -->
               <div
                  v-if="filter === 'patient_id'"
                  class="d-flex align-center ml-4"
               >
                  <PatientInput
                     @onSelectPatient="onSelectPatient"
                     :required="false"
                     class="search-bar-inputs body-2"
                     loadPatientsOnCreated
                     ref="patientinput"
                  />

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              clearFilterPatient();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem múltipla de cirurgias por municípios. -->
               <div
                  v-else-if="
                     filter === 'municipality_id&doctor_opinion.sus_code'
                  "
                  class="d-flex align-center ml-4"
               >
                  <v-chip
                     @click="surgeryByMunicipalityOpenSelectMunicipality"
                     color="primary"
                     class="ma-0 mr-1 select-municipality"
                     small
                  >
                     <p
                        v-if="
                           !surgeryByMunicipalityMunicipalitiesSelected ||
                           !surgeryByMunicipalityMunicipalitiesSelected.length
                        "
                     >
                        Selecione o <br />
                        município
                     </p>
                     <p v-else>
                        <strong>
                           {{
                              surgeryByMunicipalityMunicipalitiesSelected.length
                           }}
                        </strong>
                        município(s) <br />
                        selecionado(s)
                     </p>
                  </v-chip>

                  <SurgerieInput
                     @onSelectSurgerie="surgeryByMunicipalityOnSelectSurgerie"
                     :required="false"
                     class="search-bar-inputs body-2 ml-3"
                     ref="surgerybymunicipalitysurgerieinput"
                  />

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              surgeryByMunicipalityClearFilter();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem múltipla de cirurgias por entidades de origem. -->
               <div
                  v-else-if="
                     filter === 'sourcehealthentity_id&doctor_opinion.sus_code'
                  "
                  class="d-flex align-center ml-4"
               >
                  <v-chip
                     @click="surgeryByHealthEntityOpenSelectHealthEntity"
                     color="primary"
                     class="ma-0 mr-1 select-municipality"
                     small
                  >
                     <p
                        v-if="
                           !surgeryByHealthEntityHealthEntitiesSelected ||
                           !surgeryByHealthEntityHealthEntitiesSelected.length
                        "
                     >
                        Selecione uma <br />
                        entidade de origem
                     </p>
                     <p v-else>
                        <strong>
                           {{
                              surgeryByHealthEntityHealthEntitiesSelected.length
                           }}
                        </strong>
                        entidade(s) <br />
                        selecionada(s)
                     </p>
                  </v-chip>

                  <SurgerieInput
                     @onSelectSurgerie="surgeryByHealthEntityOnSelectSurgerie"
                     :required="false"
                     class="search-bar-inputs body-2 ml-3"
                     ref="surgerybyhealthentitysurgerieinput"
                  />

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              surgeryByHealthEntityClearFilter();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <!-- Filtragem de pacientes cadastrados pelo OperaPB. -->
               <div
                  v-else-if="filter === 'was_created_by_patient'"
                  class="d-flex justify-center align-center ml-4"
               >
                  <v-icon color="primary" left> mdi-cellphone-text </v-icon>

                  <p class="body-2">
                     Exibindo somente solicitações cadastradas pelo OperaPB.
                  </p>
               </div>

               <!-- Filtragem por status. -->
               <div
                  v-else-if="filter === 'status&is_checked'"
                  class="d-flex align-center mr-n1 my-n1 ml-3 flex-wrap"
               >
                  <v-select
                     v-model="statusByCheckedSelected"
                     @change="statusByCheckedOnSelectStatus"
                     :items="statusByCheckedItems"
                     class="search-bar-status body-2 ma-1"
                     placeholder="Estado"
                     return-object
                  >
                     <template v-slot:selection="{ item }">
                        <p
                           class="label-selection font-weight-bold"
                           :class="`${item.color}--text`"
                        >
                           {{ item.text }}
                        </p>
                     </template>

                     <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                           <v-list-item-content>
                              <v-list-item-title :class="`${item.color}--text`">
                                 {{ item.text }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-select>

                  <v-chip
                     @click="
                        statusByCheckedOnSelectIsChecked(statusByCheckedIsCheck)
                     "
                     :color="statusByCheckedIsCheckComputed.color"
                     class="ma-1"
                  >
                     <v-icon left small>
                        {{ statusByCheckedIsCheckComputed.icon }}
                     </v-icon>

                     <p>
                        {{ statusByCheckedIsCheckComputed.text }}
                     </p>
                  </v-chip>

                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              statusByCheckedClearFilter();
                              clearFilter();
                           "
                           class="ml-1"
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>

               <div class="d-flex align-center ma-n1 w-100" v-else>
                  <v-text-field
                     v-model="filterBy"
                     v-maska="filterMask"
                     @click:append="
                        filterBy && filterBy !== '' ? searchItems() : null
                     "
                     :placeholder="`Digite o(a) ${filterText} do ${filterActor}...`"
                     class="search-bar body-2"
                     append-icon="mdi-magnify"
                     :maxlength="filterMaxLength"
                     :id="
                        userInfo
                           ? `filter-${userInfo}-by-list-header-input`
                           : 'filter-by-list-header-input'
                     "
                  >
                  </v-text-field>

                  <v-tooltip :value="filterBy !== '' ? true : false" top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           @click="
                              filterBy = null;
                              clearFilter();
                           "
                           height="36px"
                           width="36px"
                           icon
                        >
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </template>

                     <p>Limpar filtro</p>
                  </v-tooltip>
               </div>
            </div>

            <div v-if="!hideAddButton">
               <v-btn
                  @click="add"
                  class="d-flex d-md-none"
                  color="primary"
                  id="add-secondary-list-header-button"
                  fab
                  depressed
                  x-small
               >
                  <v-icon>mdi-plus</v-icon>
               </v-btn>
               <v-btn
                  @click="add"
                  class="d-none d-md-flex"
                  color="primary"
                  id="add-primary-list-header-button"
                  depressed
               >
                  ADICIONAR
                  <v-icon right>mdi-plus</v-icon>
               </v-btn>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import SelectMunicipality from "components/municipality/selectMunicipality/SelectMunicipality";
import SurgerieInput from "components/surgerie/SurgerieInput";
import PatientInput from "components/patient/PatientInput";
import NoData from "components/base/NoData";
import Close from "components/base/Close";
import responsiveUtil from "utils/responsiveUtil";
import formatUtil from "utils/formatUtil";

const SEARCH_DELAY = 800;

export default {
   name: "SurgeryRequestListHeader",

   components: {
      HealthUnitInput,
      SelectMunicipality,
      SurgerieInput,
      PatientInput,
      Close,
      NoData,
   },

   props: {
      filterActor: {
         type: String,
         default: "default",
      },

      date: {
         type: Array,
      },

      filter: {
         type: String,
         default: "name",
      },

      filters: {
         type: Array,
         default: () => [],
      },

      sort: {
         type: String,
         default: "-created_at",
      },

      sorts: {
         type: Array,
         default: () => [],
      },

      loading: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      userInfo: {
         type: String,
         default: null,
      },

      initialFilterBy: {
         type: String,
         default: null,
      },

      allowedStatusInStatusFilter: {
         type: Array,
         default: () => [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected",
            "rejected_by_executor",
            "canceled",
         ],
      },
   },

   data: function () {
      return {
         filterBy: this.initialFilterBy ? this.initialFilterBy : "",

         filterTimeout: null,

         filterMenuVisibility: false,
         dialogSelectMunicipalityVisibility: false,
         dialogSelectHealthEntityVisibility: false,

         /* Filtro de cirurgias por municípios. */
         surgeryByMunicipalityMunicipalitiesSelected: [],
         surgeryByMunicipalitySurgerySelected: {},

         /* Filtro de cirurgias por entidades de origem. */
         surgeryByHealthEntityHealthEntitiesSelected: [],
         surgeryByHealthEntitySurgerySelected: {},

         /* Filtro de status. */
         statusByCheckedIsCheck: "all",
         statusByCheckedSelected: {},
      };
   },

   computed: {
      filterText() {
         return this.filters
            .find((item) => item.filter === this.filter)
            .text.toLowerCase();
      },

      filterMask() {
         let mask = {
            mask: "Z*",
            tokens: {
               Z: { pattern: /[0-9a-zA-Z!@#$%^&*()_+-={}çãáéúíóâêîôû ]/ },
            },
         };

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask) mask = filterItem.mask;

         return mask;
      },

      filterMaxLength() {
         let length = -1;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.maxLength) length = filterItem.maxLength;

         return length;
      },

      statusByCheckedIsCheckComputed() {
         let result = {
            icon: "mdi-check-underline-circle",
            color: "primary",
            text: "Validadas e não validadas",
         };

         switch (this.statusByCheckedIsCheck) {
            case "checked":
               result.icon = "mdi-check-circle";
               result.color = "success";
               result.text = "Validadas";
               break;

            case "unchecked":
               result.icon = "mdi-help-circle";
               result.color = "normal";
               result.text = "Não validadas";
               break;
         }

         return result;
      },

      statusByCheckedItems() {
         let status = [
            {
               value: "registered",
               text: "Solicitada",
               color: "info",
            },
            {
               value: "in_progress",
               text: "Em andamento",
               color: "warning",
            },
            {
               value: "regulated",
               text: "Regulado",
               color: "primary",
            },
            {
               value: "answered",
               text: "Atendida",
               color: "success",
            },
            {
               value: "rejected",
               text: "Rejeitada",
               color: "error",
            },
            {
               value: "rejected_by_executor",
               text: "Rejeitada pelo executante",
               color: "error",
            },
            {
               value: "canceled",
               text: "Cancelada",
               color: "error",
            },
         ];

         return status.filter((item) =>
            this.allowedStatusInStatusFilter.includes(item.value)
         );
      },
   },

   watch: {
      filterBy(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.filterTimeout);
         this.filterTimeout = setTimeout(this.searchItems, SEARCH_DELAY);
      },

      filter(value, oldValue) {
         if (oldValue === "municipality_id&doctor_opinion.sus_code")
            this.surgeryByMunicipalityClearFilter();
         else if (oldValue === "sourcehealthentity_id&doctor_opinion.sus_code")
            this.surgeryByHealthEntityClearFilter();
         else if (oldValue === "status&is_checked")
            this.statusByCheckedClearFilter();

         if (value === "was_created_by_patient")
            this.onSearchRequestsCreatedByPatient();
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      closeFilterMenu() {
         this.filterMenuVisibility = false;
      },

      changeFilter(filter) {
         this.filterBy = null;

         /* Não atualiza a página para filtros instantâneos. */
         if (!["was_created_by_patient"].includes(filter)) this.searchItems();

         this.closeFilterMenu();
         this.$emit("onChangeFilter", filter);
      },

      changeSort(sort) {
         this.$emit("onChangeSort", sort);
         this.closeFilterMenu();
      },

      clearFilter() {
         this.$emit("onClearFilter");
      },

      add() {
         this.$emit("onClickAdd");
      },

      onChangeDate(date) {
         this.$emit("onChangeDate", date.sort());
      },

      /* Filtro padrão. */
      searchItems() {
         let filterByTemp = this.filterBy;

         const filterItem = this.filters.find(
            (item) => item.filter === this.filter
         );

         if (filterItem.mask && filterItem.unmask && filterByTemp)
            filterByTemp = filterByTemp.replace(filterItem.unmask, "");

         this.$emit("onSearchItems", filterByTemp);
      },

      /* Filtro pelo nome do paciente. */
      onSelectPatient(patient) {
         this.$emit("onSearchItems", patient.id);
      },

      clearFilterPatient() {
         if (this.$refs.patientinput) this.$refs.patientinput.reset();
      },

      /* Filtro pela unidade de saúde de origem. */
      onSelectHealthUnit(healthUnit) {
         this.$emit("onSearchItems", healthUnit.id);
      },

      /* Filtro de cirurgias por municípios. */
      surgeryByMunicipalityOpenSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      surgeryByMunicipalityCloseSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      surgeryByMunicipalityOnSelectMunicipality(municipalities) {
         if (municipalities.length) {
            this.surgeryByMunicipalityMunicipalitiesSelected = municipalities;

            this.surgeryByMunicipalitySearchEventEmit(
               this.surgeryByMunicipalityMunicipalitiesSelected,
               this.surgeryByMunicipalitySurgerySelected
            );
         }

         this.surgeryByMunicipalityCloseSelectMunicipality();
      },

      surgeryByMunicipalityOnSelectSurgerie(surgerie) {
         if (surgerie && surgerie.name && surgerie.sus_code) {
            this.surgeryByMunicipalitySurgerySelected = surgerie;

            this.surgeryByMunicipalitySearchEventEmit(
               this.surgeryByMunicipalityMunicipalitiesSelected,
               this.surgeryByMunicipalitySurgerySelected
            );
         }
      },

      surgeryByMunicipalitySearchEventEmit(municipalities, surgerie) {
         let filter = [];

         if (municipalities && municipalities.length)
            filter.push({
               text: "municipality_id",
               value: municipalities.map((item) => item.id).join(","),
            });
         if (surgerie && surgerie.sus_code)
            filter.push({
               text: "doctor_opinion.sus_code",
               value: surgerie.sus_code,
            });

         this.$emit("onSearchItems", filter);
      },

      surgeryByMunicipalityClearFilter() {
         this.surgeryByMunicipalityMunicipalitiesSelected = [];
         this.surgeryByMunicipalitySurgerySelected = {};
         if (this.$refs.surgerybymunicipalitysurgerieinput)
            this.$refs.surgerybymunicipalitysurgerieinput.reset();
         if (this.$refs.surgerybymunicipalityselectmunicipality)
            this.$refs.surgerybymunicipalityselectmunicipality.reset();
      },

      /* Filtro de cirurgias por entidades de origem. */
      surgeryByHealthEntityOpenSelectHealthEntity() {
         this.dialogSelectHealthEntityVisibility = true;
      },

      surgeryByHealthEntityCloseSelectHealthEntity() {
         this.dialogSelectHealthEntityVisibility = false;
      },

      surgeryByHealthEntityOnAddHealthUnit(healthUnit) {
         const healthEntityIndex =
            this.surgeryByHealthEntityHealthEntitiesSelected.findIndex(
               (item) => item.id === healthUnit.id
            );

         if (healthEntityIndex === -1)
            this.surgeryByHealthEntityHealthEntitiesSelected.push(healthUnit);

         setTimeout(() => {
            if (this.$refs.surgerybyhealthentityhealthunitinput)
               this.$refs.surgerybyhealthentityhealthunitinput.resetHealthEntity();
         }, 300);
      },

      surgeryByHealthEntityOnSelectHealthUnit() {
         if (this.surgeryByHealthEntityHealthEntitiesSelected.length) {
            this.surgeryByHealthEntitySearchEventEmit(
               this.surgeryByHealthEntityHealthEntitiesSelected,
               this.surgeryByHealthEntitySurgerySelected
            );
         }

         this.surgeryByHealthEntityCloseSelectHealthEntity();
      },

      surgeryByHealthEntityOnSelectSurgerie(surgerie) {
         if (surgerie && surgerie.name && surgerie.sus_code) {
            this.surgeryByHealthEntitySurgerySelected = surgerie;

            this.surgeryByHealthEntitySearchEventEmit(
               this.surgeryByHealthEntityHealthEntitiesSelected,
               this.surgeryByHealthEntitySurgerySelected
            );
         }
      },

      surgeryByHealthEntitySearchEventEmit(healthEntities, surgerie) {
         let filter = [];

         if (healthEntities && healthEntities.length)
            filter.push({
               text: "sourcehealthentity_id",
               value: healthEntities.map((item) => item.id).join(","),
            });
         if (surgerie && surgerie.sus_code)
            filter.push({
               text: "doctor_opinion.sus_code",
               value: surgerie.sus_code,
            });

         this.$emit("onSearchItems", filter);
      },

      surgeryByHealthEntityClearFilter() {
         this.surgeryByHealthEntityHealthEntitiesSelected = [];
         this.surgeryByHealthEntitySurgerySelected = {};
         if (this.$refs.surgerybyhealthentitysurgerieinput)
            this.$refs.surgerybyhealthentitysurgerieinput.reset();
         if (this.$refs.surgerybyhealthentityhealthunitinput)
            this.$refs.surgerybyhealthentityhealthunitinput.reset();
      },

      /* Filtro de pacientes cadastrados pelo OperaPB. */
      onSearchRequestsCreatedByPatient() {
         this.$emit("onSearchItems", "true");
      },

      /* Filtro de status. */
      statusByCheckedOnSelectStatus() {
         this.statusByCheckedSearchEventEmit(
            this.statusByCheckedSelected,
            this.statusByCheckedIsCheck
         );
      },

      statusByCheckedOnSelectIsChecked(value) {
         switch (value) {
            case "all":
               this.statusByCheckedIsCheck = "checked";
               break;
            case "checked":
               this.statusByCheckedIsCheck = "unchecked";
               break;
            case "unchecked":
               this.statusByCheckedIsCheck = "all";
               break;
         }

         this.statusByCheckedSearchEventEmit(
            this.statusByCheckedSelected,
            this.statusByCheckedIsCheck
         );
      },

      statusByCheckedSearchEventEmit(status, isChecked) {
         let filter = [];

         if (status && status.text && status.value) {
            /* Particularidade do status rejected e rejected_by_executor. */
            if (status.value.includes("rejected")) {
               filter.push({
                  text: "status",
                  value: "rejected",
               });

               if (status.value === "rejected_by_executor")
                  filter.push({
                     text: "is_closed",
                     value: "false",
                  });
               else
                  filter.push({
                     text: "is_closed",
                     value: "true",
                  });
            } else
               filter.push({
                  text: "status",
                  value: status.value,
               });
         }

         switch (isChecked) {
            case "checked":
               filter.push({
                  text: "is_checked",
                  value: "true",
               });
               break;

            case "unchecked":
               filter.push({
                  text: "is_checked",
                  value: "false",
               });
               break;
         }

         if (filter.length) this.$emit("onSearchItems", filter);
         else this.clearFilter();
      },

      statusByCheckedClearFilter() {
         this.statusByCheckedSelected = {};
         this.statusByCheckedIsCheck = "all";
      },
   },
};
</script>

<style scoped>
.search-bar {
   max-width: 500px;
   margin-left: 16px;
}

.search-bar-inputs {
   min-width: 500px !important;
}

@media (max-width: 959px) {
   .search-bar-inputs {
      min-width: 20px !important;
   }
}

.search-bar-status {
   min-width: 300px !important;
}

@media (max-width: 959px) {
   .search-bar-status {
      min-width: 20px !important;
   }
}

.municipality-chip {
   font-size: 0.7rem !important;
   line-height: 11px;
   letter-spacing: 0.0333333333em;
   text-align: center;

   /* height: 36px !important; */
}

.select-municipality {
   font-size: 0.55rem !important;
   line-height: 10px;
   text-align: center;
}

.municipality-label {
   white-space: nowrap;
   min-width: 30px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.label-selection {
   white-space: nowrap;
}
</style>