<template>
   <div>
      <v-dialog
         v-model="dialogLogoutWarningVisibility"
         max-width="600"
         transition="slide-y-transition"
      >
         <v-alert
            class="ma-0 pa-6 d-flex align-center"
            prominent
            type="error"
            color="warning"
         >
            <div class="d-flex flex-column justify-center align-end mt-4">
               <p class="text-justify">
                  Caso o usuário que teve o perfil alterado esteja
                  <strong>logado</strong> no sistema, é indicado que o mesmo
                  <strong>saia da sua conta e entre</strong> para que as
                  alterações sejam percebidas.
               </p>

               <v-btn
                  @click="onClickSaveChangeType"
                  :loading="loadingChangeType"
                  small
                  >Continuar
                  <v-icon right small>mdi-check-circle</v-icon>
               </v-btn>
            </div>
         </v-alert>
      </v-dialog>

      <FormTitle icon="mdi-swap-horizontal" title="ALTERAR PERFIL" />

      <v-alert
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
      >
         Selecione o novo tipo de perfil que
         <strong>{{ user.name || "anônimo" }} </strong>
         deve executar.
      </v-alert>

      <div class="mt-6">
         <v-card
            class="
               standard-padding-x-small
               d-flex
               flex-column
               justify-center
               align-center
            "
            outlined
         >
            <p class="body-1 font-weight-medium">Perfil atual do usuário</p>

            <v-chip class="mt-2" color="primary" filter>
               {{ currentUserType.text }}
            </v-chip>
         </v-card>

         <div class="d-flex justify-center align-center my-3">
            <v-icon color="primary" x-large>mdi-swap-horizontal</v-icon>
         </div>

         <v-card
            class="
               standard-padding-x-small
               d-flex
               flex-column
               justify-center
               align-center
            "
            outlined
         >
            <p class="body-1 font-weight-medium">Novo perfil do usuário</p>

            <v-chip-group
               v-model="userTypeSelected"
               class="mt-1"
               mandatory
               column
            >
               <v-chip
                  v-for="(
                     userType, index
                  ) in userTypesComputedWithoutCurrentUserType"
                  :key="index"
                  :color="
                     userTypeSelected.value === userType.value
                        ? 'primary'
                        : 'normal'
                  "
                  :value="userType"
                  filter
               >
                  {{ userType.text }}
               </v-chip>
            </v-chip-group>

            <v-form ref="form">
               <v-expand-transition>
                  <div v-if="needsHealthEntity" class="mt-4">
                     <div
                        v-if="isOperator"
                        class="d-flex justify-center align-center"
                     >
                        <v-btn-toggle
                           v-model="entitiesTab"
                           class="mx-auto flex-wrap mb-2"
                           dense
                        >
                           <v-btn class="overline-button primary--text">
                              <v-icon class="mr-1" color="primary" small
                                 >mdi-hospital-building</v-icon
                              >
                              UNIDADE DE SAÚDE
                           </v-btn>
                           <v-btn class="overline-button error--text">
                              <v-icon class="mr-1" color="error" small
                                 >mdi-home-plus</v-icon
                              >
                              SECRETARIA DE SAÚDE
                           </v-btn>
                        </v-btn-toggle>
                     </div>

                     <div v-if="entitiesTab === 0">
                        <HealthUnitInput
                           @onSelectHealthUnit="onSelectHealthUnit"
                           ref="userhealthunitinput"
                        />
                     </div>
                     <div v-else-if="entitiesTab === 1">
                        <HealthSecretarieInput
                           @onSelectHealthSecretarie="onSelectHealthSecretary"
                           ref="userhealthsecretaryinput"
                        />
                     </div>
                  </div>
               </v-expand-transition>
            </v-form>
         </v-card>
      </div>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="openLogoutWarning"
         :loadingSave="loadingChangeType"
         class="mt-8"
         mode="save"
      />
   </div>
</template>

<script>
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import FormTitle from "components/base/form/FormTitle";
import FormActions from "components/base/form/FormActions.vue";
import userService from "services/userService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "ChangeTypeUserForm",

   components: {
      FormTitle,
      FormActions,
      HealthUnitInput,
      HealthSecretarieInput,
   },

   props: {
      currentType: {
         type: String,
         default: "",
      },

      user: {
         type: Object,
      },
   },

   data: function () {
      return {
         entitiesTab: 0,
         healthUnitSelected: null,
         healthSecretarySelected: null,

         userTypes: [
            {
               value: "admin",
               text: "Administrador",
            },
            {
               value: "manager",
               text: "Gestor",
            },
            {
               value: "healthunit_manager",
               text: "Gestor de unidade de saúde",
            },
            {
               value: "secretary_manager",
               text: "Gestor de secretaria",
            },
            {
               value: "regulator_manager",
               text: "Gestor regulador",
            },
            {
               value: "operator",
               text: "Operador",
            },

            {
               value: "regulator",
               text: "Regulador",
            },
         ],

         userTypeSelected: {},

         loadingChangeType: false,
         needsHealthEntity: false,
         dialogLogoutWarningVisibility: false,
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      currentUserType() {
         return this.userTypes.find((item) => item.value === this.currentType);
      },

      userTypesComputedWithoutCurrentUserType() {
         /* Remove o tipo de usuário do usuário logado. */
         let result = this.userTypes.filter(
            (item) => item.value !== this.currentType
         );

         /* Remover alguns usuários dependendo do tipo do usuário logado. */
         switch (this.userStore.sub_type) {
            /* Para o gestor, remove a opção de mudar para 'Administrador' e 'Gestor'. */
            case "manager":
               result = result.filter(
                  (item) => !["admin", "manager"].includes(item.value)
               );
               break;
         }

         return result;
      },

      isOperator() {
         return this.userTypeSelected.value === "operator";
      },
   },

   watch: {
      user: {
         immediate: true,
         handler() {
            if (this.$refs.userhealthunitinput)
               this.$refs.userhealthunitinput.reset();
            if (this.$refs.userhealthsecretaryinput)
               this.$refs.userhealthsecretaryinput.reset();
            this.userTypeSelected = {};
            this.resetValidation();
         },
      },

      userTypeSelected(currentUserTypes) {
         this.needsHealthEntity = [
            "healthunit_manager",
            "secretary_manager",
            "operator",
         ].includes(currentUserTypes.value);

         if (this.needsHealthEntity) {
            this.entitiesTab = 0;

            switch (currentUserTypes.value) {
               case "healthunit_manager":
                  this.entitiesTab = 0;
                  break;
               case "secretary_manager":
                  this.entitiesTab = 1;
                  break;
            }
         }
      },
   },

   methods: {
      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      onSelectHealthSecretary(healthSecretary) {
         this.healthSecretarySelected = Object.assign({}, healthSecretary);
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickSaveChangeType() {
         if (this.user) {
            this.closeLogoutWarning();
            this.typeUser(this.user.id);
         }
      },

      mountTypeUserData() {
         let typeUserData = {
            type: this.userTypeSelected.value,
         };

         if (this.needsHealthEntity) {
            if (this.entitiesTab === 0)
               typeUserData.healthunit_id = this.healthUnitSelected.id;
            else if (this.entitiesTab === 1)
               typeUserData.healthsecretary_id =
                  this.healthSecretarySelected.id;
         }

         return typeUserData;
      },

      async typeUser(userId) {
         if (!this.executeValidation() || !userId) return;

         this.loadingChangeType = true;

         try {
            await userService.typeUser(userId, this.mountTypeUserData());

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O perfil do usuário foi alterado com sucesso!",
            });

            this.$emit("onChangeType");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingChangeType = false;
         }
      },

      openLogoutWarning() {
         this.dialogLogoutWarningVisibility = true;
      },

      closeLogoutWarning() {
         this.dialogLogoutWarningVisibility = false;
      },
   },
};
</script>

<style scoped>
.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}
</style>