<template>
   <div>
      <v-dialog
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               @onSelectMunicipality="onSelectMunicipalityFromMap"
               :loadMunicipalitiesOnCreated="false"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-autocomplete
         v-model="municipalitySelected"
         @click:append="openSelectMunicipality"
         @change="municipalitySelectedEmit"
         :items="municipalitiesItems"
         :search-input.sync="municipalitySearchFilter"
         :rules="
            required
               ? [
                    () =>
                       !!(municipalitySelected && municipalitySelected.id) ||
                       `${description} obrigatório(a).`,
                 ]
               : []
         "
         :disabled="loadingMunicipalities"
         :loading="loadingMunicipalities"
         :label="`${description}${required ? '*' : ''}`"
         :placeholder="`Digite o(a) ${description.toLowerCase()} para buscar...`"
         append-icon="mdi-map-marker"
         item-text="name"
         id="municipality-input"
         return-object
      >
         <template v-slot:no-data>
            <div class="px-5 py-2">
               Desculpe, nenhum(a)
               {{ description.toLowerCase() }} encontrado(a).
            </div>
         </template>
      </v-autocomplete>
   </div>
</template>

<script>
import SelectMunicipality from "../selectMunicipality/SelectMunicipality";
import municipalityService from "services/municipalityService";
import snackBarUtil from "utils/snackBarUtil";
import responsiveUtil from "utils/responsiveUtil";
import { SEARCH_DELAY } from "utils/defaultUtil";

export default {
   components: { SelectMunicipality },
   name: "MunicipalityInput",

   props: {
      /* Caso essa props seja passada, o componente vai buscar esse municipality pelo 'id' e em seguida setar. */
      municipalityId: {
         type: String,
      },

      /* Caso essa props seja passada, o componente vai buscar esse municipality pelo 'name' e em seguida setar. */
      municipalityName: {
         type: String,
      },

      description: {
         type: String,
         default: "Município",
      },

      required: {
         type: Boolean,
         default: true,
      },

      loadMunicipalitiesOnCreated: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         dialogSelectMunicipalityVisibility: false,

         municipalitySelected: {},
         municipalitiesItems: [],
         municipalitySearchFilter: "",
         municipalityTimeout: null,
         loadingMunicipalities: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 20,
            sort: "name",
            filter: [],
         };

         if (
            this.municipalitySearchFilter &&
            this.municipalitySearchFilter.replace(/ /g, "") !== ""
         )
            query.filter = {
               attribute: "name",
               query: this.municipalitySearchFilter,
            };

         return query;
      },
   },

   watch: {
      /* Monitora a prop municipalityId, se tiver sido passada busca e seta o município. */
      municipalityId: {
         immediate: true,
         handler(municipalityId) {
            if (
               municipalityId &&
               municipalityId !== "" &&
               municipalityId !== this.municipalitySelected.id
            )
               this.getMunicipalityIdProp(municipalityId);
         },
      },

      /* Monitora a prop municipalityId, se tiver sido passada busca e seta o município. */
      municipalityName: {
         immediate: true,
         handler(municipalityName) {
            if (
               municipalityName &&
               municipalityName !== "" &&
               municipalityName !== this.municipalitySelected.name
            )
               this.getMunicipalityNameProp(municipalityName);
         },
      },

      municipalitySearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            this.municipalitiesItems.find((item) => item.name === newFilter)
         )
            return;

         clearTimeout(this.municipalityTimeout);
         this.municipalityTimeout = setTimeout(() => {
            this.getAllMunicipalities(this.query);
         }, SEARCH_DELAY);
      },
   },

   methods: {
      ...responsiveUtil,

      reset() {
         this.municipalitySelected = {};
      },

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      setMunicipalityInterface(municipality) {
         this.municipalitySelected = municipality;
         this.municipalitiesItems.push(municipality);
      },

      onSelectMunicipalityFromMap(municipality) {
         this.setMunicipalityInterface(municipality);
         this.municipalitySelectedEmit(municipality);

         this.closeSelectMunicipality();
      },

      async getAllMunicipalities(query) {
         this.loadingMunicipalities = true;

         try {
            const response = await municipalityService.getAllMunicipalities(
               query
            );

            this.municipalitiesItems = this.municipalitiesItems.concat(
               Object.assign([], response.data)
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMunicipalities = false;
         }
      },

      async getMunicipalityIdProp(municipalityId) {
         this.loadingMunicipalities = true;

         try {
            /* Busca o municipality pelo atributo 'id'. */
            const response = await municipalityService.getMunicipalityById(
               municipalityId
            );

            if (response && response.data && response.data.id)
               this.setMunicipalityInterface(Object.assign({}, response.data));
            else this.notFindMunicipalityEmit();
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMunicipalities = false;
         }
      },

      async getMunicipalityNameProp(municipalityName) {
         this.loadingMunicipalities = true;

         try {
            /* Busca o municipality pelo atributo 'name'. */
            const response = await municipalityService.getAllMunicipalities({
               filter: {
                  attribute: "specificName",
                  query: municipalityName,
               },
            });

            if (response && response.data && response.data.length)
               this.setMunicipalityInterface(
                  Object.assign({}, response.data[0])
               );
            else this.notFindMunicipalityEmit();
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMunicipalities = false;
         }
      },

      /* O municipality passado nas props não foi encontrado. */
      notFindMunicipalityEmit() {
         this.$emit("onNotFindMunicipality");
      },

      /*
      Quando o município foi selecionado no autocomplete ou no mapa, emite o 
      evento com o município selecionado. 
      */
      municipalitySelectedEmit(municipality) {
         this.$emit("onSelectMunicipality", municipality);
      },
   },

   created() {
      if (this.loadMunicipalitiesOnCreated)
         this.getAllMunicipalities(this.query);
   },
};
</script>

<style scoped>
/* Remove a animação do append icon rotacionar. */
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
   transform: none;
}

/* Diminui o tamanho do append icon. */
::v-deep .v-icon.v-icon {
   font-size: 20px;
}
</style>