<template>
   <div>
      <v-dialog
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               ref="selectMunicipality"
               @onSelectMunicipality="onSelectMunicipality"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-alert
         v-if="mode === 'save' && !dense"
         class="caption mt-2 mb-4"
         color="primary"
         type="info"
         icon="mdi-map"
         border="left"
         text
         dense
      >
         <div class="d-flex justify-space-between align-center">
            <p v-if="!municipalitySelected">
               Selecione um município para visualizar suas respectivas unidades
               de saúde.
            </p>

            <p v-else>
               Você está visualizando as unidades de saúde de
               <span class="body-2"
                  ><strong>{{ municipalitySelected.name }}</strong></span
               >.
            </p>

            <div class="ml-2">
               <v-btn
                  @click="openSelectMunicipality"
                  id="change-municipality-primary-button"
                  class="d-none d-md-flex"
                  color="primary"
                  x-small
                  text
                  >{{
                     !municipalitySelected
                        ? "Selecionar munípicio"
                        : "Alterar município"
                  }}
               </v-btn>

               <v-btn
                  @click="openSelectMunicipality"
                  id="change-municipality-secondary-button"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  text
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </div>
         </div>
      </v-alert>

      <v-row :class="dense ? '' : 'mb-2'" :no-gutters="dense" class="mt-0">
         <v-col cols="12">
            <v-autocomplete
               v-model="healthunitSelected"
               @focus="!municipalitySelected ? openSelectMunicipality() : null"
               @change="selectHealthUnit"
               :items="healthUnitsItems"
               :search-input.sync="healthUnitSearchFilter"
               :readonly="!municipalitySelected"
               :loading="loadingHealthUnits"
               :placeholder="
                  !municipalitySelected
                     ? 'Escolha um município para visualizar suas unidades de saúde.'
                     : 'Digite o nome do hospital para buscar...'
               "
               :menu-props="{ top: true }"
               :rules="
                  required
                     ? dialogSelectMunicipalityVisibility
                        ? []
                        : [
                             () =>
                                !!healthunitSelected ||
                                'Unidade de saúde obrigatória.',
                          ]
                     : []
               "
               :disabled="loadingHealthUnits"
               :label="`Unidade de saúde${required ? '*' : ''}`"
               id="select-healthunit-input"
               item-text="name"
               item-value="id"
               return-object
            >
               <template v-slot:no-data>
                  <div class="px-5 py-2">
                     Desculpe, nenhum hospital encontrado.
                  </div>
               </template>

               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content>
                        <v-list-item-title
                           :id="`select-healthunit-item-${
                              attrs.id.split('-')[3]
                           }`"
                        >
                           {{ item.name }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>

               <template v-if="dense" v-slot:prepend>
                  <v-chip
                     @click="openSelectMunicipality"
                     color="primary"
                     class="ma-0 mr-1 select-municipality"
                     small
                  >
                     <p v-if="!municipalitySelected">
                        Selecione o <br />
                        município
                     </p>
                     <p v-else>
                        Município <br />
                        <strong>
                           {{ municipalitySelected.name }}
                        </strong>
                     </p>
                  </v-chip>
               </template>
            </v-autocomplete>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import SelectMunicipality from "../../municipality/selectMunicipality/SelectMunicipality";
import healthUnitService from "../../../services/healthUnitService";
import responsiveUtil from "../../../utils/responsiveUtil";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "HealthUnitInput",

   components: { SelectMunicipality },

   props: {
      healthUnitId: {
         type: String,
      },

      mode: {
         type: String,
         default: "save",
      },

      itemDisable: {
         type: Object,
      },

      dense: {
         type: Boolean,
         default: false,
      },

      required: {
         type: Boolean,
         default: true,
      },

      multiple: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         healthunitSelected: null,

         loadingHealthUnits: false,

         municipalitySelected: null,
         dialogSelectMunicipalityVisibility: false,
         healthUnitSearchFilter: "",

         healthUnitsItems: [],

         municipalityTimeout: null,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 20,
            filter: [],
         };

         if (
            this.healthUnitSearchFilter &&
            this.healthUnitSearchFilter.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: "name",
               query: this.healthUnitSearchFilter,
            });

         return query;
      },
   },

   watch: {
      healthUnitId: {
         immediate: true,
         handler(healthUnitId) {
            this.reset();
            if (healthUnitId) {
               this.getHealthUnitById(healthUnitId);
               this.healthunitSelected = healthUnitId;
            }
         },
      },

      healthUnitSearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            this.healthUnitsItems.find((item) => item.name === newFilter)
         )
            return;

         clearTimeout(this.municipalityTimeout);
         this.municipalityTimeout = setTimeout(() => {
            this.getAllHealthUnits(this.municipalitySelected.id, this.query);
         }, 800);
      },
   },

   methods: {
      ...responsiveUtil,

      resetHealthEntity() {
         this.healthunitSelected = null;
      },

      reset() {
         if(this.$refs.selectMunicipality) this.$refs.selectMunicipality.reset();

         this.municipalitySelected = null;
         this.healthunitSelected = null;
      },

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      selectHealthUnit(healthUnit) {
         this.$emit("onSelectHealthUnit", healthUnit);
      },

      onSelectMunicipality(municipality) {
         if (municipality) {
            this.municipalitySelected = municipality;
            this.healthunitSelected = null;
            this.healthUnitSearchFilter = "";
            this.healthUnitsItems = [];
            this.getAllHealthUnits(municipality.id, this.query);
            this.$emit("onSelectMunicipality", municipality);
         }
         this.closeSelectMunicipality();
      },

      async getHealthUnitById(healthUnitId) {
         this.loadingHealthUnits = true;
         this.healthUnitsItems = [];

         try {
            const response = await healthUnitService.getHealthUnitById(
               healthUnitId
            );

            if (response.data)
               this.healthUnitsItems.push(Object.assign({}, response.data));
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthUnits = false;
         }
      },

      async getAllHealthUnits(municipalityId, query) {
         this.loadingHealthUnits = true;

         try {
            const response = await healthUnitService.getAllHealthUnits(
               municipalityId,
               query
            );

            this.healthUnitsItems = this.healthUnitsItems.concat(
               Object.assign([], response.data)
            );

            if (this.itemDisable && this.itemDisable.id) {
               const itemDisableIndex = this.healthUnitsItems.findIndex(
                  (item) => item.id === this.itemDisable.id
               );

               if (itemDisableIndex !== -1)
                  this.healthUnitsItems[itemDisableIndex].disabled = true;
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthUnits = false;
         }
      },
   },
};
</script>

<style scoped>
.select-municipality {
   font-size: 0.55rem !important;
   line-height: 10px;
   text-align: center;
}
</style>