import "jspdf-autotable";
import jsPDF from "jspdf";
import formatUtil from "utils/formatUtil";
import _ from "lodash";

export const SECTION_ROW_CONFIG = {
   styles: { halign: "center", fillColor: [215, 215, 215] }
};

export const TABLE_STYLE_DEFAULT = {
   theme: "grid",
   styles: {
      textColor: "black",
      lineColor: [100, 100, 100]
   }
};

/**
 * Método para gerar o cabeçalho do PDF, com logo do regNUTES, logo da Paraíba, descrição do arquivo, etc.
 * @param {*} header: Objeto para configurar algumas propriedades descritivas do PDF.
 * {
 *    title: 'Título do PDF'
 *    subject: 'Assunto do PDF'
 *    autor: 'Responsável pela impressão'
 * }
 *
 * @param {*} options: Atributo options padrão da lib jsPDF.
 * @returns Arquivo PDF (jsPDF).
 */
function generatePDFHeader(header, options) {
   const title = header && header.title ? header.title : "Título";
   const subject = header && header.subject ? header.subject : "";
   const autor = header && header.autor ? header.autor : "Não informado";
   const HEADER_HEIGHT = 25;

   const pdf = new jsPDF(options);
   pdf.setProperties({
      title: title,
      subject: subject,
      author: autor,
      creator: "regNUTES"
   });

   pdf.autoTable({
      ...TABLE_STYLE_DEFAULT,
      body: [
         [
            {
               content: "",
               styles: { minCellHeight: HEADER_HEIGHT }
            },
            {
               content: "",
               styles: { minCellHeight: HEADER_HEIGHT }
            },
            {
               content: "",
               styles: { minCellHeight: HEADER_HEIGHT }
            }
         ]
      ],
      didDrawCell: function(data) {
         if (data.column.dataKey === 0)
            pdf.addImage(
               require("@/assets/images/regnutes-logo-print.png"),
               "PNG",
               data.cell.x + data.cell.width / 2 - 25 / 2,
               data.cell.y + data.cell.height / 2 - 25 / 2,
               25,
               25
            );
         else if (data.column.dataKey === 1) {
            const TEXT_START_HEIGHT = 9;

            pdf.text(
               "Sistema regNUTES",
               data.cell.x + data.cell.width / 2,
               data.cell.y + TEXT_START_HEIGHT,
               "center"
            );
            pdf.text(
               title,
               data.cell.x + data.cell.width / 2,
               data.cell.y + TEXT_START_HEIGHT + 5,
               "center"
            );
            pdf.setFontSize(8);

            let printedBy = `${formatUtil.formatDate(
               new Date(),
               "DD/MM/YYYY [às] HH:mm"
            )}`;
            if (autor !== "Não informado")
               printedBy = `Gerado por ${autor}, ${printedBy}`;
            else printedBy = `Gerado em ${printedBy}`;

            pdf.text(
               printedBy,
               data.cell.x + data.cell.width / 2,
               data.cell.y + TEXT_START_HEIGHT + 10,
               "center"
            );
         } else if (data.column.dataKey === 2)
            pdf.addImage(
               require("@/assets/images/paraiba-logo-print.png"),
               "PNG",
               data.cell.x + data.cell.width / 2 - 25 / 2,
               data.cell.y + data.cell.height / 2 - 25 / 2,
               25,
               25
            );
      },
      columnStyles: {
         0: { cellWidth: 36 },
         1: { fontStyle: "bold" },
         2: { cellWidth: 36 }
      }
   });

   return pdf;
}

/**
 * Método para gerar uma tabela a partir de dados em PDF.
 * @param {*} data: Array com os objetos em JSON.
 * @param headers: Array com o nome das colunas.
 * @param sourcePDF: Objeto PDF (jsPDF) fonte, caso queira concatenar a tabela com um cabeçalhjo já pronto, por exemplo.
 * @returns Arquivo PDF (jsPDF).
 */
function generatePDFTableFromJSON(data, headers, sourcePDF) {
   /* Constrói o cabeçalho da tabela. */
   let headContent = [];
   if (headers && headers.length)
      headContent = [headers.map(item => item.text)];
   else if (data.length) {
      const keys = [];
      for (let [key] of Object.entries(data[0])) keys.push(key);
      headContent = [keys];
   }

   /* Constrói o corpo da tabela. */
   let bodyContent = [];
   data.forEach(itemData => {
      const finalItem = [];

      if (headers && headers.length)
         headers.forEach(itemHead => {
            if (itemHead.computed && typeof itemHead.computed === "function")
               finalItem.push(
                  itemHead.computed(_.get(itemData, itemHead.value))
               );
            else finalItem.push(_.get(itemData, itemHead.value));
         });
      else
         for (let [, value] of Object.entries(itemData)) finalItem.push(value);

      bodyContent.push(finalItem);
   });

   let pdfConfig = {
      ...TABLE_STYLE_DEFAULT,
      headStyles: {
         fillColor: [215, 215, 215],
         valign: "middle",
         halign: "center",
         lineWidth: 0.1
      },
      bodyStyles: {
         valign: "middle",
         halign: "center"
      },
      head: headContent,
      body: bodyContent
   };

   if (sourcePDF) {
      pdfConfig.startY = sourcePDF.lastAutoTable.finalY;
      return sourcePDF.autoTable(pdfConfig);
   } else return new jsPDF().autoTable(pdfConfig);
}

/**
 * Método para abrir um PDF (jsPDF) em uma nova guia.
 * @param {*} pdf: Objeto PDF (jsPDF) que vai ser aberto na nova guia.
 * @param {*} windowTitle: String com o título que será exibido na nova guia.
 */
function openPDFNewWindow(pdf, windowTitle) {
   var string = pdf.output("datauristring");
   var x = window.open();

   /* Write pdf */
   x.document.write(
      `<iframe width="100%" height="100%" frameborder="no" src="${string}"></iframe>`
   );

   /* Reset margins and padding default of browsers */
   const style = x.document.createElement("style");
   style.textContent = `* { margin: 0; padding: 0; }`;
   x.document.head.append(style);

   x.document.title = windowTitle || "Título";

   x.document.close();
}

/**
 * Método para salvar localmente um PDF (jsPDF).
 * @param {*} pdf: Objeto PDF (jsPDF) que vai ser salvo.
 * @param {*} fileName: String com o nome do arquivo que será salvo.
 */
function savePDF(pdf, fileName) {
   pdf.save(fileName || "arquivo");
}

export default {
   generatePDFHeader,
   generatePDFTableFromJSON,
   openPDFNewWindow,
   savePDF
};
