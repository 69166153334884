<template>
   <div>
      <SelectHealthUnitDetailsSkeleton v-if="!healthUnit.id" />

      <v-list-item
         v-else
         @click="selectHealthUnit(healthUnit)"
         class="px-5"
         two-line
      >
         <v-icon class="mr-4" color="primary" large
            >mdi-hospital-building</v-icon
         >

         <v-list-item-content>
            <v-list-item-title>{{ healthUnit.name }}</v-list-item-title>

            <v-list-item-subtitle
               v-if="healthUnit.address && healthUnit.address.city"
               >{{ healthUnit.address.city }}</v-list-item-subtitle
            >
         </v-list-item-content>

         <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>
   </div>
</template>

<script>
import SelectHealthUnitDetailsSkeleton from "./SelectHealthUnitDetailsSkeleton";
import healthUnitService from "../../../services/healthUnitService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "SelectHealthUnitDetails",

   components: { SelectHealthUnitDetailsSkeleton },

   props: {
      healthUnitInfo: {
         type: Object,
         default: null,
      },
   },

   data: function () {
      return {
         healthUnit: {},
      };
   },

   watch: {
      healthUnitInfo: {
         immediate: true,
         handler(healthUnitInfo) {
            if (healthUnitInfo) this.getHealthUnitById(healthUnitInfo);
         },
      },
   },

   methods: {
      async getHealthUnitById(healthUnitInfo) {
         try {
            const response = await healthUnitService.getHealthUnitById(
               healthUnitInfo.id
            );

            this.healthUnit = Object.assign({}, response.data);
            this.healthUnit.userCapabilities = this.healthUnitInfo.capabilities;
            this.$emit("onGetHealthUnit", this.healthUnit);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      selectHealthUnit(healthUnit) {
         this.$emit("onSelectHealthUnit", healthUnit);
      },
   },
};
</script>

<style scoped>
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(92, 107, 192, 1);
}
</style>