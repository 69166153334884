<template>
   <div>
      <v-dialog
         v-model="dialogSurgeryRejectRequestFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeSurgeryRejectRequestForm" />

            <SurgeryRejectRequestForm
               @onClickCancel="closeSurgeryRejectRequestForm"
               @onCancelReservation="
                  closeSurgeryRejectRequestForm();
                  $emit('onUpdatedRequest');
               "
               class="standard-padding"
               :request="request"
            />
         </v-card>
      </v-dialog>

      <FormTitle
         v-if="!hideTitle"
         icon="mdi-medical-bag"
         title="SOLICITAÇÃO DE CIRURGIA"
      />

      <v-alert
         v-if="getMode() === 'read' && formRequest.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Solicitação cadastrada em
         {{ formatDate(formRequest.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <OutcomeDetails
         v-if="request.status === 'answered' && !!request.outcome"
         :request="request"
         class="mb-4"
      />

      <JustificationRejection
         v-if="
            request.status === 'rejected' ||
            request.status === 'rejected_by_executor'
         "
         :request="request"
         class="mb-4"
      />

      <div class="mb-2">
         <OriginAndDestinyDetails
            :sourceHealthUnit="request.source_health_entity"
            :targetHealthUnit="request.target_health_unit"
         />
      </div>

      <RequestSummary class="mb-n3" :request="formRequest" />

      <v-expand-transition>
         <v-card class="mt-5 standard-padding" outlined>
            <ViewPatient :patient="request.patient" />
         </v-card>
      </v-expand-transition>

      <v-expansion-panels
         v-if="request.status === 'answered' && request.surgery"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               Agendamento da cirurgia
            </v-expansion-panel-header>

            <v-expansion-panel-content class="mt-n1">
               <v-card flat>
                  <ViewSchedule :schedule="request.surgery" />
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form :readonly="getMode() === 'read'" ref="form" id="doctor-opinion">
         <v-expansion-panels v-model="regulationData" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  Parecer médico
               </v-expansion-panel-header>
               <v-expansion-panel-content class="mt-n1">
                  <v-card :disabled="getMode() === 'read'" flat>
                     <DoctorOpinionForm
                        :doctorOpinion="formRequest.doctor_opinion"
                        ref="doctoropinion"
                     />
                  </v-card>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <v-card flat>
         <p class="body-1 font-weight-medium mt-6 mb-3">
            Documentos(s)
            <v-icon>mdi-paperclip</v-icon>
         </p>

         <RequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            hideRemoveButton
            hideAddButton
         />
      </v-card>

      <SurgeryRequestAdminFormActions
         v-if="!hideActions"
         @onClickRemove="onClickRemove"
         :hideRemoveButton="request.status !== 'regulated'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../../components/base/form/FormTitle";
import OriginAndDestinyDetails from "components/requestBase/subForm/OriginAndDestinyDetails";
import RequestAttachments from "components/requestBase/subForm/RequestAttachments";
import DoctorOpinionForm from "../../../components/surgeryRequest/form/subForm/DoctorOpinionForm";
import ViewPatient from "components/patient/ViewPatient";
import RequestSummary from "components/surgeryRequest/form/subForm/RequestSummary";
import OutcomeDetails from "components/requestBase/subForm/OutcomeDetails";
import JustificationRejection from "components/requestBase/subForm/JustificationRejection";
import ViewSchedule from "./subForm/ViewSchedule";
import SurgeryRequestAdminFormActions from "./SurgeryRequestAdminFormActions";
import SurgeryRejectRequestForm from "components/surgery/form/SurgeryRejectRequestForm";
import Close from "components/base/Close";
import formatUtil from "../../../utils/formatUtil";
import validationUtil from "../../../utils/validationUtil";
import responsiveUtil from "../../../utils/responsiveUtil";
import util from "../../../utils/util";
import { ALLOWED_TYPES, MAXIMUM_SIZE } from "utils/defaultUtil";

export default {
   name: "SurgeryRequestAdminForm",

   components: {
      FormTitle,
      OriginAndDestinyDetails,
      RequestAttachments,
      DoctorOpinionForm,
      ViewPatient,
      RequestSummary,
      OutcomeDetails,
      JustificationRejection,
      ViewSchedule,
      SurgeryRequestAdminFormActions,
      SurgeryRejectRequestForm,
      Close,
   },

   props: {
      request: {
         type: Object,
      },

      hideActions: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",
         formRequest: {},

         requestData: 0,
         regulationData: [],

         dialogSurgeryRejectRequestFormVisibility: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            if (request.id) {
               this.setMode("read");
            } else this.setMode("save");

            this.formRequest = this.formatObjectToView(request);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,
      ...util,
      ...responsiveUtil,

      formatObjectToView(request) {
         let requestTemp = util.mergeObject(
            {
               doctor_opinion: {
                  patient_not_fit_for_regulation: false,
               },
            },
            request
         );

         return requestTemp;
      },

      formatObjectToSend(request) {
         let requestTemp = util.cloneObject(request);

         return util.removeBlankAttributes(requestTemp);
      },

      setMode(mode) {
         this.mode = mode;

         if (mode === "read") {
            this.requestData = 0;
            this.regulationData = null;
         } else {
            this.requestData = null;
            this.regulationData = 0;

            var formDoctorOpinion = document.getElementById("doctor-opinion");
            if (formDoctorOpinion)
               formDoctorOpinion.scrollIntoView({ behavior: "smooth" });
         }
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         let justificationValidation = true;
         let doctorOpinionValidation = true;

         if (this.$refs.form)
            justificationValidation = this.$refs.form.validate();
         if (this.$refs.doctoropinion)
            doctorOpinionValidation = this.$refs.doctoropinion.validate();

         return justificationValidation && doctorOpinionValidation;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();

         if (this.$refs.doctoropinion)
            this.$refs.doctoropinion.resetValidation();
      },

      openSurgeryRejectRequestForm() {
         this.dialogSurgeryRejectRequestFormVisibility = true;
      },

      closeSurgeryRejectRequestForm() {
         this.dialogSurgeryRejectRequestFormVisibility = false;
      },

      onClickRemove() {
         this.openSurgeryRejectRequestForm();
      },
   },
};
</script>

<style scoped>
.overlay-config {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1000;
   top: 0px;
   left: 0px;
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.chat-drawer {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 999;
   width: 35%;

   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.history-overlay {
   position: sticky;
   background-color: white;
   bottom: 0px;
   z-index: 1000 !important;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@media (min-width: 959px) and (max-width: 1263px) {
   .chat-drawer {
      width: 45%;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .chat-drawer {
      width: 65%;
   }
}

@media (max-width: 599px) {
   .chat-drawer {
      width: 100%;
   }
}

.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.12);
}

.disable-click {
   pointer-events: none;
}

::v-deep .v-expansion-panel-header {
   font-weight: 500 !important;
}
</style>
