<template>
   <div>
      <v-dialog
         v-model="dialogActivateUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card>
            <ActivateUserForm
               @onActivateUser="
                  closeActivateUserForm();
                  refreshAdminList();
               "
               :user="disableAdmin"
               userType="administrador"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshAdminList();
               "
               :user="adminChangeTypeSelected"
               currentType="admin"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogAdminFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeAdminForm" />

            <AdminForm
               @onClickCancel="closeAdminForm"
               @onCreatedAdmin="
                  closeAdminForm();
                  refreshAdminList();
               "
               @onUpdatedAdmin="
                  closeAdminForm();
                  refreshAdminList();
               "
               @onClickRemoveAdmin="onClickRemoveAdmin"
               @onFindDisabledAdmin="onFindDisabledAdmin"
               @onClickChangeType="onClickChangeType"
               :admin="adminSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <AdminList
         @onClickAddAdmin="onClickAddAdmin"
         @onClickViewAdmin="onClickViewAdmin"
         @onClickEditAdmin="onClickEditAdmin"
         @onClickRemoveAdmin="onClickRemoveAdmin"
         class="mt-12"
         ref="adminlist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import AdminList from "../../components/admin/AdminList";
import AdminForm from "../../components/admin/AdminForm";
import ActivateUserForm from "components/users/ActivateUserForm";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "Admin",

   components: {
      Close,
      PageTitle,
      AdminList,
      AdminForm,
      ActivateUserForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         adminSelected: {},
         adminChangeTypeSelected: {},
         disableAdmin: {},

         editMode: false,

         dialogAdminFormVisibility: false,
         dialogActivateUserFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogAdminFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddAdmin(admin) {
         this.adminSelected = Object.assign({}, admin);
         this.openAdminForm();
      },

      onClickViewAdmin(admin) {
         this.adminSelected = Object.assign({}, admin);
         this.openAdminForm();
      },

      onClickEditAdmin() {
         this.editMode = true;
      },

      onClickChangeType(admin) {
         this.adminChangeTypeSelected = Object.assign({}, admin);
         this.closeAdminForm();
         this.openChangeTypeUserForm();
      },

      onClickRemoveAdmin(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, { active: false });

            this.closeAdminForm();
            this.refreshAdminList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O administrador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse administrador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledAdmin(item) {
         this.disableAdmin = Object.assign({}, item);

         this.closeAdminForm();
         this.openActivateUserForm();
      },

      openAdminForm() {
         this.dialogAdminFormVisibility = true;
      },

      closeAdminForm() {
         this.dialogAdminFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      openActivateUserForm() {
         this.dialogActivateUserFormVisibility = true;
      },

      closeActivateUserForm() {
         this.dialogActivateUserFormVisibility = false;
      },

      refreshAdminList() {
         this.$refs.adminlist.refreshAllAdminsByRef();
      }
   }
};
</script>

<style scoped></style>
