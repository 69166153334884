<template>
   <div>
      <ListSkeleton
         v-if="loadingAvaliableSurgerysFully"
         :headersLength="headers.length - 1"
         :itemsPerPage="limit"
      />

      <v-data-table
         v-else
         @click:row="
            (avaliableSurgery) =>
               !blockClick
                  ? onClickViewAvaliableSurgery(avaliableSurgery)
                  : null
         "
         :headers="headers"
         :items="avaliableSurgerys"
         :loading="loadingAvaliableSurgerysPartially"
         :items-per-page="limit"
         class="elevation-2 standard-padding-data-list"
         mobile-breakpoint="960"
         loader-height="3px"
         hide-default-footer
         disable-sort
      >
         <template v-slot:top>
            <ListHeader
               @onChangeSort="onChangeSort"
               @onChangeFilter="onChangeFilter"
               @onClearFilter="onClearFilter"
               @onSearchItems="onSearchItems"
               @onClickAdd="onClickAddAvaliableSurgery"
               :sort="sort"
               :sorts="sorts"
               :filter="filter"
               :filters="filters"
               :loading="loadingAvaliableSurgerysPartially"
               filterActor="cirurgia aceita"
               id="header"
            />
         </template>

         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalAvaliableSurgerys"
               :loading="loadingAvaliableSurgerysPartially"
            />
         </template>

         <template v-slot:no-data>
            <div class="standard-padding">
               <NoData
                  message="Desculpe, nenhuma cirurgia aceita foi encontrada."
               />
            </div>
         </template>

         <template v-slot:loading>
            <div class="standard-padding">
               <LoadingData
                  message="Carregando cirurgias aceitas, aguarde por favor."
               />
            </div>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p v-if="item.name" class="name-avaliableSurgery-list-data">
               {{ item.name }}
            </p>

            <v-skeleton-loader
               v-else
               class="rounded-pill"
               type="image"
               width="130"
               height="12px"
            ></v-skeleton-loader>
         </template>

         <template v-slot:[`item.actions`]="{ item, index }">
            <div class="ma-n1">
               <v-icon
                  @click="removeAvaliableSurgery(item)"
                  :id="`remove-item-${index}-avaliableSurgery-list-button`"
                  :key="surgeryKey"
                  color="error"
                  class="mx-1"
                  small
               >
                  mdi-delete
               </v-icon>
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListSkeleton from "../../components/base/skeletons/ListSkeleton";
import ListFooter from "../../components/base/list/ListFooter";
import ListHeader from "../../components/base/list/ListHeader";
import NoData from "../../components/base/NoData";
import LoadingData from "../../components/base/LoadingData";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import formatUtil from "../../utils/formatUtil";
import avaliableSurgeryService from "../../services/avaliableSurgeryService";
import surgerieService from "services/surgerieService";

export default {
   name: "AvaliableSurgeryList",

   components: {
      ListSkeleton,
      ListFooter,
      ListHeader,
      NoData,
      LoadingData,
   },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
            {
               text: "Código do SUS",
               class: "primary--text body-2 font-weight-medium",
               value: "sus_code",
            },
            {
               text: "Ações",
               class: "primary--text body-2 font-weight-medium",
               value: "actions",
               sortable: false,
               align: "center",
            },
         ],

         sorts: [
            {
               sort: "-created_at",
               text: "Mais recentes",
            },
            {
               sort: "sus_code",
               text: "Código do SUS",
            },
         ],

         filters: [
            {
               filter: "sus_code",
               text: "Código do SUS",
               mask: "##.##.##.###-#",
               maxLength: 14,
            },
         ],

         avaliableSurgerys: [],

         totalAvaliableSurgerys: 0,
         page: 1,
         limit: 20,
         sort: "-created_at",
         filter: "sus_code",
         filterBy: "",

         loadingAvaliableSurgerysFully: true,
         loadingAvaliableSurgerysPartially: true,

         blockClick: false,

         surgeryKey: 0,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [],
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: this.filter,
               query: this.filterBy,
            });

         return query;
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllAvaliableSurgerys(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      onChangeFilter(filter) {
         this.filter = filter;
      },

      onClearFilter() {
         this.filterBy = "";
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      onSearchItems(filterBy) {
         this.filterBy = filterBy;
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      onChangeSort(sort) {
         this.sort = sort;
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      onClickAddAvaliableSurgery(avaliableSurgery) {
         this.$emit("onClickAddAvaliableSurgery", avaliableSurgery);
      },

      onClickViewAvaliableSurgery(avaliableSurgery) {
         this.$emit("onClickViewAvaliableSurgery", avaliableSurgery);
      },

      editAvaliableSurgery(avaliableSurgery) {
         this.$emit("onClickEditAvaliableSurgery", avaliableSurgery);
      },

      removeAvaliableSurgery(avaliableSurgery) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onClickRemoveAvaliableSurgery", avaliableSurgery);
            this.blockClick = false;
         }, 1);
      },

      async refreshAllAvaliableSurgerysByRef() {
         this.loadingAvaliableSurgerysFully = true;
         await this.getAllAvaliableSurgerys(this.query);
         this.loadingAvaliableSurgerysFully = false;
      },

      async getAllAvaliableSurgerys(query) {
         this.loadingAvaliableSurgerysPartially = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response =
               await avaliableSurgeryService.getAllAvaliableSurgeries(query);

            this.totalAvaliableSurgerys = parseInt(
               response.headers["x-total-count"]
            );

            const requestsModifiedStatus = Object.assign([], response.data);

            requestsModifiedStatus.forEach((item) => {
               surgerieService
                  .getAllSurgeries({
                     page: 1,
                     limit: 1,
                     filter: [
                        {
                           attribute: "sus_code",
                           query: item.sus_code,
                        },
                     ],
                  })
                  .then((response) => {
                     item.name = response.data[0].name;

                     this.forceSurgeryRerender();
                  });
            });

            this.avaliableSurgerys = Object.assign([], requestsModifiedStatus);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingAvaliableSurgerysPartially = false;
         }
      },

      forceSurgeryRerender() {
         this.surgeryKey += 1;
      },
   },

   async created() {
      this.loadingAvaliableSurgerysFully = true;
      await this.getAllAvaliableSurgerys(this.query);
      this.loadingAvaliableSurgerysFully = false;
   },
};
</script>

<style scoped></style>
