<template>
   <div class="d-flex align-center mb-9">
      <span class="divider"></span>

      <v-icon class="mx-4" color="primary" size="34" left>{{ icon }}</v-icon>
      <p class="title primary--text font-weight-bold" :id="`${title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(' ', '-')}-page-title`">
         {{ title.toUpperCase() }}
      </p>
   </div>
</template>

<script>
export default {
   name: "PageTitle",

   props: {
      icon: {
         type: String,
         default: "mdi-account",
      },

      title: {
         type: String,
         default: "TITLE",
      },
   },
};
</script>

<style scoped>
.divider {
   height: 40px;
   width: 3px;
   background-color: rgba(92, 107, 192, 1);
}
</style>