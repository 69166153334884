<template>
   <div>
      <v-row>
         <v-col cols="12" md="5">
            <p class="caption mb-1">NEWS FAST (SES-PB)</p>

            <v-chip-group
               v-model="regulationData.news_fast"
               class="my-n2"
               column
               id="news_fast-news-fast-form-input"
            >
               <v-chip color="info" value="one" small filter id="news_fast_one-news-fast-form-input"> 
                  1 ponto 
               </v-chip>
               <v-chip color="amber" value="two_yellow" small filter id="news_fast_two_yellow-news-fast-form-input">
                  2 pontos
               </v-chip>
               <v-chip color="warning" value="two_orange" small filter id="news_fast_two_orange-news-fast-form-input">
                  2 pontos
               </v-chip>
               <v-chip
                  color="error"
                  value="greater_equal_to_three"
                  small
                  filter
                  id="news_fast_three-news-fast-form-input"
               >
                  ≥3 pontos
               </v-chip>
            </v-chip-group>
         </v-col>

         <v-col cols="12" md="7">
            <p class="caption mb-1">NEWS modificado</p>
            <v-chip-group
               v-model="regulationData.news_modified"
               class="my-n2"
               column
               id="news_modified-news-fast-form-input"
            >
               <v-chip color="normal" value="zero" small filter id="news_modified_zero-news-fast-form-input">
                  0 pontos
               </v-chip>
               <v-chip color="amber" value="one_to_four" small filter id="news_modified_one_to_four-news-fast-form-input">
                  1-4 pontos
               </v-chip>
               <v-chip color="warning" value="five_to_six" small filter id="news_modified_five_to_six-news-fast-form-input">
                  5-6 pontos
               </v-chip>
               <v-chip
                  color="error"
                  value="greater_equal_to_seven_red"
                  small
                  filter
                  id="news_modified_greater_equal_to_seven_red-news-fast-form-input"
               >
                  ≥7 pontos
               </v-chip>
               <v-chip
                  color="black"
                  value="greater_equal_to_seven_black"
                  class="white--text"
                  small
                  filter
                  id="news_modified_greater_equal_to_seven_black-news-fast-form-input"
               >
                  ≥7 pontos
               </v-chip>
            </v-chip-group>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "NewsFastForm",

   props: {
      regulationData: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>