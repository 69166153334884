var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"\n         standard-padding-whopping\n         card-border\n         d-flex\n         flex-column\n         justify-center\n         align-stretch\n      ",attrs:{"elevation":"3"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{attrs:{"size":"150"}},[_vm._v(" $reg-logo ")])],1),_c('v-form',{ref:"form"},[_c('v-text-field',{staticClass:"mt-9 body-1 label-position",attrs:{"rules":[
               function (value) { return !!value || 'Email obrigatório.'; },
               function (value) { return _vm.emailValidation(value) || 'Email inválido.'; } ],"id":"password-change-password-input","height":"44"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"21","left":""}},[_vm._v("mdi-email")])]},proxy:true},{key:"label",fn:function(){return [_c('p',{staticClass:"body-1"},[_vm._v("Email*")])]},proxy:true}]),model:{value:(_vm.formChangePassword.email),callback:function ($$v) {_vm.$set(_vm.formChangePassword, "email", $$v)},expression:"formChangePassword.email"}}),_c('v-text-field',{staticClass:"mt-12 body-1 label-position",attrs:{"rules":[
               function (value) { return !!value || 'Nova senha obrigatória.'; },
               function (value) { return _vm.passwordValidation(value) ||
                  'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.'; } ],"type":_vm.showNewPassword ? 'text' : 'password',"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"id":"new-password-change-password-input","height":"44"},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"21","left":""}},[_vm._v("mdi-lock")])]},proxy:true},{key:"label",fn:function(){return [_c('p',{staticClass:"body-1"},[_vm._v("Nova senha*")])]},proxy:true}]),model:{value:(_vm.formChangePassword.new_password),callback:function ($$v) {_vm.$set(_vm.formChangePassword, "new_password", $$v)},expression:"formChangePassword.new_password"}}),_c('v-text-field',{staticClass:"mt-12 body-1 label-position",attrs:{"rules":[
               function (value) { return !!value || 'Confirmação da nova senha obrigatória.'; },
               function (value) { return value === _vm.formChangePassword.new_password ||
                  'Confirmação da nova senha e a nova senha são diferentes.'; } ],"type":_vm.showConfirmationNewPassword ? 'text' : 'password',"append-icon":_vm.showConfirmationNewPassword ? 'mdi-eye' : 'mdi-eye-off',"id":"confirm-new-password-change-password-input","height":"44"},on:{"click:append":function($event){_vm.showConfirmationNewPassword = !_vm.showConfirmationNewPassword}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"21","left":""}},[_vm._v("mdi-lock")])]},proxy:true},{key:"label",fn:function(){return [_c('p',{staticClass:"body-1"},[_vm._v("Confirmação da nova senha*")])]},proxy:true}]),model:{value:(_vm.formChangePassword.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.formChangePassword, "confirm_new_password", $$v)},expression:"formChangePassword.confirm_new_password"}})],1),_c('v-btn',{staticClass:"mt-16 py-6",attrs:{"loading":_vm.loadingSaveChangePassword,"color":"primary","id":"change-password-button","large":"","block":""},on:{"click":_vm.onClickSaveChangePassword}},[_vm._v("ALTERAR SENHA")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }