<template>
   <div>
      <div class="d-flex align-center flex-wrap">
         <v-checkbox
            v-model="respiratory.spontaneous"
            class="mr-3"
            label="Espontânea"
            id="spontaneous-respiratory-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="respiratory.iot"
            class="mr-3"
            label="IOT"
            id="iot-respiratory-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="respiratory.o2_support"
            class="mr-3"
            label="O² Suporte"
            id="o2_support-respiratory-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="respiratory.mask_with_reservoir"
            class="mr-3"
            label="Máscara com reservatório"
            id="mask_with_reservoir-respiratory-form-input"
         ></v-checkbox>
      </div>

      <v-row class="mt-1">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.spo2"
               label="SPO² (%)"
               id="spo2-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model.number="respiratory.respiratory_rate"
               v-maska="'#*'"
               label="FR (IRPM)"
               id="respiratory_rate-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.ventilation"
               label="Ventilação"
               id="ventilation-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.cn"
               label="CN (lts/min)"
               id="cn-respiratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.venturi"
               label="Venturi (%)"
               id="venturi-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.vmi"
               label="VMI"
               id="vmi-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.pcv"
               label="PCV"
               id="pcv-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.psv"
               label="PSV"
               id="psv-respiratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.vcv"
               label="VCV"
               id="vcv-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.vt"
               label="VT (ml/Kg)"
               id="vt-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.delta_pressure"
               label="Delta pressure"
               id="delta_pressure-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.plateau_pressure"
               label="cmH20 Platô"
               id="plateau_pressure-respiratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5 mb-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.raw"
               label="cmH20 Raw"
               id="raw-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.cest"
               label="Cest"
               id="cest-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.sao2"
               label="Sa0²"
               id="sao2-respiratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="respiratory.pao2"
               label="Pa0²"
               id="pao2-respiratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: "RespiratoryForm",

   props: {
      respiratory: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>