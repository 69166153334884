<template>
   <div>
      <div class="mb-8 d-flex flex-column justify-center align-center">
         <v-icon class="mb-2" color="primary" x-large>mdi-information</v-icon>

         <p class="font-weight-bold primary--text text-center mb-2">
            GESTOR DE UNIDADE DE SAÚDE JÁ EXISTENTE
         </p>

         <p class="body-2 text-center active-information">
            Um gestor de unidade de saúde desativado com o CPF
            <strong>{{
               healthUnitManager.cpf
                  ? formatCPF(healthUnitManager.cpf)
                  : "não informado"
            }}</strong>
            foi encontrado, para ativá-lo escolha uma unidade de saúde que ele
            deve gerir e forneça uma nova senha de acesso, em seguida clique em
            "Ativar".
         </p>
      </div>

      <v-form ref="form">
         <v-card flat>
            <HealthUnitInput
               @onSelectHealthUnit="onSelectHealthUnit"
               ref="healthunitmanagerahealthunitinput"
               class="mb-3"
            />

            <v-row>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.new_password"
                     @click:append="showNewPassword = !showNewPassword"
                     :rules="[
                        (value) => !!value || 'Nova senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Nova senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     :type="showNewPassword ? 'text' : 'password'"
                     :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                     id="new-password-form-input"
                     label="Nova senha*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.confirm_password"
                     @click:append="showConfirmPassword = !showConfirmPassword"
                     :rules="[
                        (value) =>
                           !!value || 'Confirmação da nova senha obrigatória.',
                        (value) =>
                           value === formPassword.new_password ||
                           'Confirmação da nova senha e a nova senha são diferentes.',
                     ]"
                     :type="showConfirmPassword ? 'text' : 'password'"
                     :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                     "
                     id="confirm-new-password-form-input"
                     label="Confirmação da nova senha*"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <div class="d-flex justify-end align-center mt-7">
         <v-btn
            @click="save"
            :loading="loadingActive"
            class="shadow-floating-primary-button d-none d-sm-flex ml-3"
            color="primary"
            id="save-primary-active-form-button"
            depressed
            >Ativar
            <v-icon right small>mdi-check</v-icon>
         </v-btn>
         <v-btn
            @click="save"
            :loading="loadingActive"
            class="shadow-floating-primary-button d-flex d-sm-none ml-3"
            color="primary"
            id="save-secondary-active-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
import HealthUnitInput from "../healthUnit/healthUnitInput/HealthUnitInput";
import snackBarUtil from "utils/snackBarUtil";
import validationUtil from "utils/validationUtil";
import formatUtil from "utils/formatUtil";
import userService from "services/userService";

export default {
   name: "HealthUnitManagerActiveForm",

   components: {
      HealthUnitInput,
   },

   props: {
      healthUnitManager: {
         type: Object,
      },
   },

   data: function () {
      return {
         healthUnitSelected: null,
         formPassword: {},

         showNewPassword: false,
         showConfirmPassword: false,

         loadingActive: false,
      };
   },

   watch: {
      healthUnitManager: {
         immediate: true,
         handler() {
            if (this.$refs.healthunitmanagerahealthunitinput)
               this.$refs.healthunitmanagerahealthunitinput.reset();
            this.formPassword = {};
            this.resetValidation();
         },
      },
   },

   methods: {
      ...validationUtil,
      ...formatUtil,

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
      },

      async save() {
         if (
            !this.executeValidation() ||
            !(this.healthUnitManager && this.healthUnitManager.id) ||
            !(this.healthUnitSelected && this.healthUnitSelected.id)
         )
            return;

         this.loadingActive = true;

         try {
            await userService.activateUser(this.healthUnitManager.id, {
               active: true,
               healthunit_id: this.healthUnitSelected.id,
               password: this.formPassword.new_password,
            });

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor de unidade de saúde foi ativado!",
            });

            this.$emit("onActivedHealthUnitManager");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingActive = false;
         }
      },
   },
};
</script>

<style scoped>
.active-information {
   width: 70% !important;
}
</style>