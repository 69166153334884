<template>
   <div class="form-actions-position ma-n1">
      <div v-if="!hideRemoveButton">
         <v-btn
            @click="remove"
            class="shadow-floating-error-button d-none d-sm-flex ma-1"
            color="error"
            id="remove-primary-form-button"
            depressed
            >Desfazer a regulação
            <v-icon right small>mdi-close</v-icon>
         </v-btn>
         <v-btn
            @click="remove"
            class="shadow-floating-error-button d-flex d-sm-none ma-1"
            color="error"
            id="remove-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-close</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   name: "SurgeryRequestAdminFormActions",

   props: {
      mode: {
         type: String,
         default: "save",
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      remove() {
         this.$emit("onClickRemove");
      },
   },
};
</script>

<style scoped>
.form-actions-position {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

@media (max-width: 959px) {
   .form-actions-position {
      bottom: 16px;
   }
}

.alert-config {
   height: 36px;
   margin-bottom: 0px;
   margin-left: auto;
}

.alert-text-config {
   height: 20px !important;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>