import FileSaver from "file-saver";
import formatUtil from "utils/formatUtil";

/**
 * Método para salvar localmente o XLSX.
 * @param {*} csv: String XLSX que vai ser salvo.
 * @param {*} fileName: String com o nome do arquivo que será salvo.
 */
function saveXLSX(csv, fileName) {
   const blob = new Blob([csv], { type: "data:text/xlsx;charset=utf-8" });
   FileSaver.saveAs(
      blob,
      `${fileName ||
         formatUtil.formatDate(
            new Date(),
            "DD[_]MM[_]YYYY[_]HH[_]mm[_]ss"
         )}.xlsx`
   );
}

export default {
   saveXLSX
};
