<template>
   <div>
      <FormTitle icon="mdi-account-details" title="REGULADOR" />

      <v-alert
         v-if="getMode() === 'read' && formRegulator.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Regulador cadastrado em
         {{
            formatDate(formRegulator.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card class="mb-3" :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulator.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     :loading="loadingRegulatorByCpf"
                     :readonly="!!formRegulator.id"
                     id="cpf-regulator-form-input"
                     label="CPF*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formRegulator.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     id="name-regulator-form-input"
                     label="Nome*"
                     :readonly="!vueAppEnableNameInputComputed"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulator.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     id="phone-regulator-form-input"
                     label="Telefone*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulator.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     id="birth_date-regulator-form-input"
                     label="Data de nascimento*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulator.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-regulator-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulator.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-regulator-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formRegulator.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-regulator-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formRegulator.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-regulator-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulator.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-regulator-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formRegulator.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     id="state-regulator-form-input"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formRegulator.address.complement"
                     id="complement-regulator-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <div v-if="getMode() === 'save' || formRegulator.crm">
               <p class="body-1 font-weight-medium mt-10">Dados médico</p>

               <v-row class="my-0">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formRegulator.crm"
                        id="crm-regulator-form-input"
                        label="CRM (opcional caso seja médico)"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </div>

            <p class="body-1 font-weight-medium mt-8">Dados de autenticação</p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formRegulator.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     id="email-regulator-form-input"
                     label="Email*"
                  ></v-text-field>
               </v-col>

               <v-col v-if="!formRegulator.id" cols="12" md="6">
                  <v-text-field
                     v-model="formRegulator.password"
                     :rules="[
                        (value) => !!value || 'Senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     id="password-regulator-form-input"
                     label="Senha* (mínimo 6 caracteres)"
                     type="password"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <UsersFormActions
         @onClickChangeType="onClickChangeType(regulator)"
         @onClickReset="onClickResetRegulator(regulator)"
         @onClickEdit="onClickEditRegulator"
         @onClickRemove="onClickRemoveRegulator(regulator)"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveRegulator"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveRegulator"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import UsersFormActions from "components/users/UsersFormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import regulatorService from "../../services/regulatorService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";
import checkCpfService from "services/checkCpfService";
import { NODE_ENV } from "utils/defaultUtil";

export default {
   name: "RegulatorForm",

   components: { FormTitle, UsersFormActions },

   props: {
      regulator: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formRegulator: {},

         regulatorMedic: true,

         loadingSaveRegulator: false,
         loadingSearchZipCode: false,
         loadingRegulatorByCpf: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   watch: {
      regulator: {
         immediate: true,
         handler(regulator) {
            if (regulator.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formRegulator = this.formatObjectToView(regulator);

            this.resetValidation();
         },
      },

      "formRegulator.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formRegulator.address.street = response.data.logradouro;
                  this.formRegulator.address.district = response.data.bairro;
                  this.formRegulator.address.city = response.data.localidade;
                  this.formRegulator.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formRegulator.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },

      "formRegulator.cpf": async function handler(newCpf, oldCpf) {
         if (
            newCpf &&
            newCpf.length === 14 &&
            oldCpf &&
            oldCpf.length === 13 &&
            this.cpfValidation(newCpf)
         ) {
            this.getRegulatorByCpf(newCpf.replace(/[^0-9]/g, ""));
         }
      },
   },

   computed: {
      vueAppEnableNameInputComputed() {
         return NODE_ENV !== "production" ? true : false;
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(regulator) {
         let regulatorTemp = util.mergeObject(
            {
               address: {},
            },
            regulator
         );

         if (regulatorTemp.birth_date)
            regulatorTemp.birth_date = formatUtil.formatDate(
               regulatorTemp.birth_date
            );

         if (regulatorTemp.cpf)
            regulatorTemp.cpf = formatUtil.formatCPF(regulatorTemp.cpf);

         if (regulatorTemp.phone)
            regulatorTemp.phone = formatUtil.formatPhone(regulatorTemp.phone);

         return regulatorTemp;
      },

      formatObjectToSend(regulator, removeEmptyStrings) {
         let regulatorTemp = util.removeBlankAttributes(regulator, {
            emptyStrings: removeEmptyStrings,
         });

         if (regulatorTemp.birth_date)
            regulatorTemp.birth_date = formatUtil.formatDate(
               regulatorTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (regulatorTemp.cpf)
            regulatorTemp.cpf = regulatorTemp.cpf.replace(/[^0-9]/g, "");

         if (regulatorTemp.phone)
            regulatorTemp.phone = regulatorTemp.phone.replace(/[^0-9]/g, "");

         return regulatorTemp;
      },

      onClickChangeType(regulator) {
         this.$emit("onClickChangeType", regulator);
      },

      onClickResetRegulator(regulator) {
         this.$emit("onClickResetRegulator", regulator);
      },

      onClickEditRegulator() {
         this.setMode("save");
      },

      onClickRemoveRegulator(regulator) {
         this.$emit("onClickRemoveRegulator", regulator);
      },

      onClickCancel() {
         this.setMode("read");
         this.formRegulator = this.formatObjectToView(this.regulator);
         this.$emit("onClickCancel");
      },

      onClickSaveRegulator() {
         if (this.formRegulator.id)
            this.updateRegulator(this.formRegulator.id, this.formRegulator);
         else this.createRegulator(this.formRegulator);
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async getRegulatorByCpf(cpf) {
         this.loadingRegulatorByCpf = true;

         try {
            const response = await regulatorService.getAllRegulators({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "active",
                     query: false,
                  },
                  {
                     attribute: "cpf",
                     query: cpf,
                  },
               ],
            });

            if (response.data && response.data.length)
               this.$emit("onFindDisabledRegulator", response.data[0]);
            else {
               const regulatorResponse = await checkCpfService.getCheckCpf(cpf);

               if (regulatorResponse.data && regulatorResponse.data.name !== "")
                  this.formRegulator.name = regulatorResponse.data.name;
            }
         } catch (error) {
            if (
               error.response &&
               error.response.status === 404 &&
               error.response.data &&
               error.response.data.description ===
                  "Cpf provided does not belong to any registered user."
            ) {
               delete this.formRegulator.name;
               snackBarUtil.showCustomSnackBar({
                  title: "CPF NÃO ENCONTRADO",
                  message:
                     "O CPF informado não foi encontrado na base de dados!",
                  icon: "mdi-account-off",
                  color: "info",
               });
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRegulatorByCpf = false;
         }
      },

      async createRegulator(regulator) {
         if (!this.executeValidation() || !regulator) return;

         this.loadingSaveRegulator = true;

         try {
            await regulatorService.createRegulator(
               this.formatObjectToSend(regulator)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O regulador foi cadastrado!",
            });

            this.setMode("read");
            this.$emit("onCreatedRegulator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRegulator = false;
         }
      },

      async updateRegulator(regulatorId, regulator) {
         if (!this.executeValidation() || !regulatorId || !regulator) return;

         this.loadingSaveRegulator = true;

         try {
            await regulatorService.updateRegulator(
               regulatorId,
               this.formatObjectToSend(regulator, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O regulador foi atualizado!",
            });

            this.setMode("read");
            this.$emit("onUpdatedRegulator");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveRegulator = false;
         }
      },
   },
};
</script>

<style scoped>
</style>