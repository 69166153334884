import socketIoService from "services/socketIoService";
import userService from "services/userService";

export default {
   namespaced: true,

   state: {
      user: null,

      userRoutes: ["/minha-conta", "/page404"],

      fullUser: null
   },

   getters: {
      getUser(state) {
         return state.user;
      },

      getUserRoutes(state) {
         return state.userRoutes;
      },

      getFullUser(state) {
         return state.fullUser;
      }
   },

   mutations: {
      setUser(state, user) {
         const defaultValues = ["/minha-conta", "/page404"];

         state.user = user;
         if (user && user.sub_type) {
            // Seta as rotas permitidas para cada tipo de usuário.
            const variableRoutes = [];
            switch (user.sub_type) {
               case "admin":
                  state.userRoutes = [
                     "/administradores",
                     "/gestores",
                     "/gestores-unidades-saude",
                     "/gestores-secretarias",
                     "/operadores",
                     "/gestores-reguladores",
                     "/reguladores",
                     "/municipios",
                     "/unidades-saude",
                     "/secretaria-saude",
                     "/cirurgias-aceitas",
                     "/solicitacoes-cirurgia",
                     "/solicitacoes",
                     "/estatisticas-cirurgias",
                     "/estatisticas-leitos",
                     "/pacientes",
                     "/auditoria"
                  ].concat(state.userRoutes);
                  break;
               case "manager":
                  state.userRoutes = [
                     "/gestores-unidades-saude",
                     "/gestores-reguladores",
                     "/gestores-secretarias",
                     "/municipios",
                     "/unidades-saude",
                     "/cirurgias-aceitas"
                  ].concat(state.userRoutes);
                  break;
               case "healthunit_manager":
                  state.userRoutes = [
                     "/painel-controle-leitos",
                     "/alas",
                     "/operadores",
                     "/cirurgias",
                     "/minha-unidade-saude"
                  ].concat(state.userRoutes);
                  break;
               case "secretary_manager":
                  state.userRoutes = [
                     "/solicitacoes-cirurgia",
                     "/solicitacoes",
                     "/pacientes",
                     "/operadores"
                  ].concat(state.userRoutes);
                  break;
               case "solicitator":
                  if (user.capabilities) {
                     if (user.capabilities.includes("surgeries")) variableRoutes.push("/solicitacoes-cirurgia");
                     if (user.capabilities.includes("beds")) variableRoutes.push("/solicitacoes");
                     state.userRoutes = variableRoutes.concat([
                        "/pacientes"
                     ]).concat(defaultValues);
                  }
                  else {
                     state.userRoutes = [
                        "/solicitacoes-cirurgia",
                        "/solicitacoes",
                        "/pacientes"
                     ].concat(defaultValues);
                  }

                  // Inicializa a conexão com o socket.io, já que o regulador é um dos usuário que emite e recebe eventos nesse canal.
                  socketIoService.createConnection(
                     userService.getUserSession().token
                  );
                  break;
               case "executor":
                  if (user.capabilities) {
                     if (user.capabilities.includes("surgeries")) variableRoutes.push("/painel-controle-cirurgias");
                     if (user.capabilities.includes("beds")) variableRoutes.push("/painel-controle-leitos");
                     state.userRoutes = variableRoutes.concat(defaultValues);
                  }
                  else {
                     state.userRoutes = [
                        "/painel-controle-cirurgias",
                        "/painel-controle-leitos"
                     ].concat(defaultValues);
                  }
                  break;
               case "regulator_manager":
                  state.userRoutes = [
                     "/solicitacoes-cirurgia",
                     "/solicitacoes",
                     "/reguladores",
                     "/estatisticas-cirurgias",
                     "/estatisticas-leitos"
                  ].concat(state.userRoutes);

                  // Inicializa a conexão com o socket.io, já que o regulador é um dos usuário que emite e recebe eventos nesse canal.
                  socketIoService.createConnection(
                     userService.getUserSession().token
                  );
                  break;
               case "regulator":
                  state.userRoutes = [
                     "/solicitacoes-cirurgia",
                     "/solicitacoes",
                     "/estatisticas-cirurgias",
                     "/estatisticas-leitos"
                  ].concat(state.userRoutes);

                  // Inicializa a conexão com o socket.io, já que o regulador é um dos usuário que emite e recebe eventos nesse canal.
                  socketIoService.createConnection(
                     userService.getUserSession().token
                  );
                  break;
            }
         } else {
            state.userRoutes = defaultValues;

            socketIoService.destroyConnection();
         }
      },

      setFullUser(state, fullUser) {
         state.fullUser = fullUser;
      }
   }
};
