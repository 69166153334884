var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"body-1 font-weight-medium mt-5"},[_vm._v("Confirmação de desfecho")]),_c('p',{staticClass:"caption mt-5 mb-1"},[_vm._v("Tipo de desfecho*")]),_c('v-chip-group',{staticClass:"my-n2",attrs:{"column":""},on:{"change":function (value) { return (_vm.invalidOutcome = !value); }},model:{value:(_vm.formOutcome.outcome),callback:function ($$v) {_vm.$set(_vm.formOutcome, "outcome", $$v)},expression:"formOutcome.outcome"}},[_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_cure' ? 'primary' : 'normal',"value":"discharge_cure","id":"discharge_cure-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta Curado ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_improvement'
               ? 'primary'
               : 'normal',"value":"discharge_improvement","id":"discharge_improvement-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta Melhorado ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_on_request'
               ? 'primary'
               : 'normal',"value":"discharge_on_request","id":"discharge_on_request-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta a pedido ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_with_return'
               ? 'primary'
               : 'normal',"value":"discharge_with_return","id":"discharge_with_return-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta com previsão de retorno ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_evasion'
               ? 'primary'
               : 'normal',"value":"discharge_evasion","id":"discharge_evasion-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta por Evasão ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'discharge_other_reasons'
               ? 'primary'
               : 'normal',"value":"discharge_other_reasons","id":"discharge_other_reasons-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Alta por outros motivos ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'transfer' ? 'primary' : 'normal',"value":"transfer","id":"transfer-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Transfêrencia ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'death_certificate_by_doctor'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_doctor","id":"death_certificate_by_doctor-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo médico ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'death_certificate_by_iml'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_iml","id":"death_certificate_by_iml-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo IML ")]),_c('v-chip',{attrs:{"color":_vm.formOutcome.outcome === 'death_certificate_by_svo'
               ? 'primary'
               : 'normal',"value":"death_certificate_by_svo","id":"death_certificate_by_svo-bed-request-confirm-outcome-form-button","filter":"","small":""}},[_vm._v(" Declaração de óbito fornecida pelo SVO ")])],1),_c('v-expand-transition',[(_vm.invalidOutcome)?_c('p',{staticClass:"caption error--text mt-1"},[_vm._v(" Tipo de desfecho obrigatória. ")]):_vm._e()]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"outcome-height",attrs:{"label":"Justificativa","id":"outcome_justification-bed-request-confirm-outcome-form-input","rows":"4","no-resize":""},model:{value:(_vm.formOutcome.outcome_justification),callback:function ($$v) {_vm.$set(_vm.formOutcome, "outcome_justification", $$v)},expression:"formOutcome.outcome_justification"}})],1)],1),_c('FormActions',{staticClass:"mt-6",attrs:{"loadingSave":_vm.loadingSaveOutcome,"mode":'save'},on:{"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.onClickConfirmOutcome}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }