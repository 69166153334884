var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingAvaliableSurgerysFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.avaliableSurgerys,"loading":_vm.loadingAvaliableSurgerysPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (avaliableSurgery) { return !_vm.blockClick
               ? _vm.onClickViewAvaliableSurgery(avaliableSurgery)
               : null; }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingAvaliableSurgerysPartially,"filterActor":"cirurgia aceita","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddAvaliableSurgery}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalAvaliableSurgerys,"loading":_vm.loadingAvaliableSurgerysPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhuma cirurgia aceita foi encontrada."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando cirurgias aceitas, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
               var item = ref.item;
return [(item.name)?_c('p',{staticClass:"name-avaliableSurgery-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-skeleton-loader',{staticClass:"rounded-pill",attrs:{"type":"image","width":"130","height":"12px"}})]}},{key:"item.actions",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [_c('div',{staticClass:"ma-n1"},[_c('v-icon',{key:_vm.surgeryKey,staticClass:"mx-1",attrs:{"id":("remove-item-" + index + "-avaliableSurgery-list-button"),"color":"error","small":""},on:{"click":function($event){return _vm.removeAvaliableSurgery(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }