<template>
   <div class="form-actions-position">
      <div v-if="mode === 'read'" class="d-flex justify-end align-center ma-n1">
         <div>
            <v-menu offset-y top>
               <template v-slot:activator="{ on, attrs }">
                  <v-btn
                     v-bind="attrs"
                     v-on="on"
                     class="shadow-floating-black-button d-none d-sm-flex ma-1"
                     color="normal"
                     height="36"
                     width="36"
                     id="dots-request-regulator-form-actions-button"
                     fab
                     depressed
                     dark
                  >
                     <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>

                  <v-btn
                     v-bind="attrs"
                     v-on="on"
                     class="shadow-floating-black-button d-flex d-sm-none ma-1"
                     color="normal"
                     id="dots_small-request-regulator-form-actions-button"
                     fab
                     depressed
                     dark
                     x-small
                  >
                     <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
               </template>

               <v-list class="pa-0">
                  <div
                     v-for="(action, index) in actions"
                     :key="index"
                     :id="`action-${index}-form-button`"
                  >
                     <v-list-item @click="action.onClick">
                        <v-list-item-title
                           class="body-2"
                           :id="
                              `action-${action.name
                                 .toLowerCase()
                                 .normalize('NFD')
                                 .replace(/[\u0300-\u036f]/g, '')
                                 .replaceAll(' ', '-')}-form-button`
                           "
                        >
                           {{ action.name }}
                        </v-list-item-title>

                        <v-icon size="20" right>{{ action.icon }}</v-icon>
                     </v-list-item>

                     <v-divider></v-divider>
                  </div>
               </v-list>
            </v-menu>
         </div>

         <v-badge
            v-if="!hideChatButton"
            :value="unreadMessages"
            :content="unreadMessages"
            color="warning"
            left
            overlap
         >
            <v-btn
               @click="chat"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1"
               color="primary"
               id="chat-primary-form-button"
               depressed
               dark
               >Chat
               <v-icon right small>mdi-forum</v-icon>
            </v-btn>
            <v-btn
               @click="chat"
               class="shadow-floating-primary-button d-flex d-sm-none ma-1"
               color="primary"
               id="chat-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-forum</v-icon>
            </v-btn>
         </v-badge>

         <div v-if="!hideReserveBedButton">
            <v-btn
               @click="reserveBed"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1"
               color="primary"
               id="print-primary-form-button"
               depressed
               dark
               >Reservar leito
               <v-icon right small>mdi-bed</v-icon>
            </v-btn>
            <v-btn
               @click="reserveBed"
               class="shadow-floating-primary-button d-flex d-sm-none ma-1"
               color="primary"
               id="print-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-bed</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideCheckButton">
            <v-btn
               @click="check"
               :loading="loadingCheck"
               class="shadow-floating-black-button d-none d-sm-flex ma-1"
               color="success"
               id="edit-primary-form-button"
               depressed
               dark
               >Validar
               <v-icon right small>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
               @click="check"
               :loading="loadingCheck"
               class="shadow-floating-black-button d-flex d-sm-none ma-1"
               color="success"
               id="edit-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-check-circle</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideEditButton">
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-none d-sm-flex ma-1"
               color="normal"
               id="edit-primary-form-button"
               depressed
               dark
               >Editar
               <v-icon right small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-flex d-sm-none ma-1"
               color="normal"
               id="edit-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-pencil</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideRemoveButton">
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-none d-sm-flex ma-1"
               color="error"
               id="remove-primary-form-button"
               depressed
               >Remover
               <v-icon right small>mdi-delete</v-icon>
            </v-btn>
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-flex d-sm-none ma-1"
               color="error"
               id="remove-secondary-form-button"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-delete</v-icon>
            </v-btn>
         </div>
      </div>

      <div v-else class="d-flex justify-end align-center ma-n1">
         <v-btn
            @click="cancel"
            class="
               shadow-floating-primary-light-button
               d-none d-sm-flex
               primary--text
               ma-1
            "
            color="primaryLight"
            id="cancel-primary-form-button"
            depressed
            >Cancelar
         </v-btn>
         <v-btn
            @click="cancel"
            class="shadow-floating-primary-light-button d-flex d-sm-none ma-1"
            color="primaryLight"
            id="cancel-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small color="primary">mdi-close</v-icon>
         </v-btn>

         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-none d-sm-flex ma-1"
            color="primary"
            id="save-primary-form-button"
            depressed
            >Salvar
            <v-icon right small>mdi-check</v-icon>
         </v-btn>
         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-flex d-sm-none ma-1"
            color="primary"
            id="save-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   name: "RequestRegulatorFormActions",

   props: {
      mode: {
         type: String,
         default: "save"
      },

      unreadMessages: {
         type: Number,
         default: 0
      },

      hideChatButton: {
         type: Boolean,
         default: false
      },

      hideCheckButton: {
         type: Boolean,
         default: false
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideReserveBedButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      loadingSave: {
         type: Boolean,
         default: false
      },

      loadingCheck: {
         type: Boolean,
         default: false
      }
   },

   data: function() {
      return {
         actions: [
            {
               name: "Histórico",
               icon: "mdi-history",
               onClick: this.history
            },
            {
               name: "Imprimir",
               icon: "mdi-printer",
               onClick: this.print
            }
         ]
      };
   },

   methods: {
      history() {
         this.$emit("onClickHistory");
      },

      print() {
         this.$emit("onClickPrint");
      },

      chat() {
         this.$emit("onClickChat");
      },

      check() {
         this.$emit("onClickCheck");
      },

      edit() {
         this.$emit("onClickEdit");
      },

      reserveBed() {
         this.$emit("onClickReserveBed");
      },

      remove() {
         this.$emit("onClickRemove");
      },

      cancel() {
         this.$emit("onClickCancel");
      },

      save() {
         this.$emit("onClickSave");
      }
   }
};
</script>

<style scoped>
.form-actions-position {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

@media (max-width: 959px) {
   .form-actions-position {
      bottom: 16px;
   }
}

::v-deep .v-list-item {
   min-height: 45px;
}
</style>
