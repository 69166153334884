import basePdfExport from "./basePdfExport";

/**
 * Método para gerar o PDF dos dados estatísticos da parte de cirurgias.
 * @param {*} data: Array com os objetos em JSON.
 * @param {*} headers: Array com o nome das colunas.
 * @param {*} title: String com o título do PDF.
 * @param {*} options: Atributo options padrão da lib jsPDF.
 * @returns Arquivo PDF (jsPDF).
 */
function generateSurgeryStatisticPDF(data, headers, title, options) {
   const headerPdf = basePdfExport.generatePDFHeader({ title }, options);

   const pdf = basePdfExport.generatePDFTableFromJSON(data, headers, headerPdf);

   return pdf;
}

export default {
   generateSurgeryStatisticPDF
};
