var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingHealthSecretaryManagersFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.healthSecretaryManagers,"loading":_vm.loadingHealthSecretaryManagersPartially,"items-per-page":_vm.limit,"mobile-breakpoint":"960","loader-height":"3px","hide-default-footer":"","disable-sort":""},on:{"click:row":function (healthSecretaryManager) { return !_vm.blockClick
               ? _vm.onClickViewHealthSecretaryManager(healthSecretaryManager)
               : null; }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('HealthSecretaryManagerListHeader',{attrs:{"sort":_vm.sort,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingHealthSecretaryManagersPartially,"filterActor":"gestor de secretaria","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onClickAdd":_vm.onClickAddHealthSecretaryManager}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalHealthSecretaryManagers,"loading":_vm.loadingHealthSecretaryManagersPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhum gestor de secretaria foi encontrado."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando gestores das secretarias, aguarde por favor."}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
               var item = ref.item;
return [_c('p',{staticClass:"name-health-secretary-manager-list-data"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.cpf",fn:function(ref){
               var item = ref.item;
return [_c('p',{staticClass:"cpf-health-secretary-manager-list-data"},[_vm._v(" "+_vm._s(_vm.formatCPF(item.cpf))+" ")])]}},{key:"item.email",fn:function(ref){
               var item = ref.item;
return [_c('p',{staticClass:"email-health-secretary-manager-list-data"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.birth_date",fn:function(ref){
               var item = ref.item;
return [_c('p',{staticClass:"birth_date-health-secretary-manager-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.birth_date))+" ")])]}},{key:"item.last_login",fn:function(ref){
               var item = ref.item;
return [_c('p',{staticClass:"last_login-health-secretary-manager-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.last_login, "DD/MM/YYYY [às] HH:mm"))+" ")])]}},{key:"item.actions",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"id":("edit-item-" + index + "-healthsecretary-manager-list-button"),"color":"black","small":""},on:{"click":function($event){return _vm.editHealthSecretaryManager(item)}}},[_vm._v(" mdi-pencil ")]),(_vm.userStore.sub_type === 'admin')?_c('v-icon',{staticClass:"mx-1",attrs:{"id":("remove-item-" + index + "-healthsecretary-manager-list-button"),"color":"error","small":""},on:{"click":function($event){return _vm.removeHealthSecretaryManager(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }