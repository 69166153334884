<template>
   <div>
      <v-menu
         max-width="290px"
         min-width="auto"
         offset-x
         offset-y
         nudge-left="290"
      >
         <template v-slot:activator="{ on, attrs }">
            <v-text-field
               v-model="dateTextField"
               v-maska="'##/##/####'"
               @update:error="validationUpdate"
               :rules="[
                  (value) =>
                     !!value ? dateValidation(value) || 'Data inválida.' : true,
               ]"
               append-icon="mdi-calendar"
               placeholder="Digite ou selecione a data do agendamento..."
            >
               <template v-slot:append>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
               </template>
            </v-text-field>
         </template>

         <v-date-picker
            v-model="datePicker"
            @input="datePickerInput"
            @update:picker-date="getEvents"
            :events="scheduledSurgeriesEvents"
            event-color="primary"
            no-title
            locale="pt-br"
         >
            <template v-slot:default>
               <div class="d-flex align-center px-3 pb-2 ma-n1">
                  <span class="dot-event ma-1"></span>

                  <p class="caption ma-1">Dias com cirurgias agendadas</p>
               </div>
            </template>
         </v-date-picker>
      </v-menu>
   </div>
</template>

<script>
import validationUtil from "utils/validationUtil";
import formatUtil from "utils/formatUtil";
import surgerieService from "services/surgerieService";
import snackBarUtil from "utils/snackBarUtil";
import moment from "moment";

export default {
   name: "FilterByScheduleDate",

   props: {
      healthUnitId: {
         type: String,
         default: null,
      },
   },

   data: function () {
      return {
         datePickerMenu: false,
         dateTextField: null,

         scheduledSurgeriesEvents: [],
         scheduledSurgeries: [],
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 100,
            filter: [
               {
                  attribute: "scheduling_date",
                  query: `gte:${moment()
                     .startOf("month")
                     .format("YYYY-MM-DD")}`,
               },
               {
                  attribute: "scheduling_date",
                  query: `lte:${moment().endOf("month").format("YYYY-MM-DD")}`,
               },
            ],
         };

         return query;
      },

      datePicker: {
         get() {
            if (validationUtil.dateValidation(this.dateTextField, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.dateTextField,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.dateTextField = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },
   },

   methods: {
      ...validationUtil,
      ...formatUtil,

      async getAllHealthUnitSurgeries(healthUnitId, query) {
         if (!healthUnitId) return;

         this.loadingSurgeries = true;
         this.scheduledSurgeriesEvents = [];

         try {
            const response = await surgerieService.getAllHealthUnitSurgeries(
               healthUnitId,
               query
            );

            this.scheduledSurgeriesEvents = this.mountEvents(
               Object.assign([], response.data)
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeries = false;
         }
      },

      mountEvents(surgeries) {
         let events = [];

         if (surgeries && surgeries.length) {
            surgeries.forEach((item) => {
               if (!events.includes(item.scheduling_date))
                  events.push(item.scheduling_date);
            });
         }

         return events;
      },

      datePickerInput() {
         this.datePickerMenu = false;
         this.emitSelectedDateEvent(this.dateTextField);
      },

      getEvents() {
         this.getAllHealthUnitSurgeries(this.healthUnitId, this.query);
      },

      validationUpdate(errors) {
         if (!errors && validationUtil.dateValidation(this.dateTextField))
            this.emitSelectedDateEvent(this.dateTextField);
      },

      emitSelectedDateEvent(date) {
         this.$emit(
            "onSelectedDate",
            formatUtil.formatDate(date, "YYYY-MM-DD", "DD/MM/YYYY")
         );
      },
   },
};
</script>

<style scoped>
.dot-event {
   height: 9px;
   width: 9px;
   background-color: var(--v-primary-base);
   border-radius: 50%;
   display: inline-block;
}

::v-deep .v-picker__body {
   height: 260px;
}
</style>