<template>
   <v-chip-group v-if="request.id" column dark>
      <input type="hidden" id="request-code" :value="request.code" />

      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
            <v-chip
               v-bind="attrs"
               v-on="on"
               @click="copyValueClipboard('request-code')"
               color="primary"
               label
            >
               Registro:

               <p class="ml-1">
                  <strong>
                     {{ request.code }}
                  </strong>
               </p>

               <v-icon right small>mdi-content-copy</v-icon>
            </v-chip>
         </template>

         <span>
            Copiar registro para <br />
            área de transferência
         </span>
      </v-tooltip>

      <v-chip
         v-if="request.was_created_by_patient"
         color="primary"
         class="disable-click"
         label
         id="bed_id-request-sumary-input"
      >
         Cadastrada pelo OperaPB
         <v-icon right small>mdi-cellphone-text</v-icon>
      </v-chip>

      <v-chip
         :color="generateState(request.status).color"
         class="disable-click"
         label
         id="request_status_color-request-sumary-input"
      >
         Estado solicitação:

         <p class="ml-1">
            <strong>
               {{ generateState(request.status).text }}
            </strong>
         </p>
      </v-chip>

      <v-chip
         v-if="request.target_health_unit && request.target_health_unit.id"
         :color="generateSurgeryStatus(request.surgery.status).color"
         class="disable-click"
         label
         id="request_status_color-request-sumary-input"
      >
         Estado cirurgia:

         <p class="ml-1">
            <strong>
               {{ generateSurgeryStatus(request.surgery.status).text }}
            </strong>
         </p>
      </v-chip>
   </v-chip-group>
</template>

<script>
import util from "../../../../utils/util";

export default {
   name: "RequestSummary",

   props: {
      request: {
         type: Object,
         default: () => ({}),
      },
   },

   methods: {
      ...util,
   },
};
</script>

<style scoped>
.disable-click {
   pointer-events: none;
}
</style>