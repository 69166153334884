import Vue from "vue";
import VueRouter from "vue-router";

import snackBarUtil from "../utils/snackBarUtil";
import store from "../store";

import Auth from "../views/auth/Auth.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ChangePassword from "../views/auth/ChangePassword.vue";
import BedDashboard from "../views/bed/BedDashboard.vue";
import SurgeryDashboard from "../views/surgery/SurgeryDashboard.vue";
import Request from "../views/request/Request.vue";
import SurgeryRequest from "../views/surgeryRequest/SurgeryRequest.vue";
import Ward from "../views/ward/Ward.vue";
import Admin from "../views/admin/Admin.vue";
import Manager from "../views/manager/Manager.vue";
import HealthUnitManager from "../views/healthUnitManager/HealthUnitManager.vue";
import HealthSecretaryManager from "../views/healthSecretaryManager/HealthSecretaryManager.vue";
import Operator from "../views/operator/Operator.vue";
import RegulatorManager from "../views/regulatorManager/RegulatorManager.vue";
import Regulator from "../views/regulator/Regulator.vue";
import Municipality from "../views/municipality/Municipality.vue";
import HealthUnit from "../views/healthUnit/HealthUnit.vue";
import HealthSecretarie from "../views/healthSecretary/HealthSecretarie.vue";
import Audit from "../views/audit/Audit.vue";
import Statistic from "../views/statistic/Statistic.vue";
import SurgeryStatistic from "../views/surgeryStatistic/SurgeryStatistic.vue";
import Patient from "../views/patient/Patient.vue";
import SurgeryType from "../views/surgeryType/SurgeryType.vue";
import AvaliableSurgery from "../views/avaliableSurgery/AvaliableSurgery.vue";
import MyHealthUnit from "../views/myHealthUnit/MyHealthUnit.vue";

import MyAccount from "../views/myAccount/MyAccount.vue";
import Page404 from "../views/page404/Page404.vue";
import { PUBLIC_ROUTES } from "../utils/defaultUtil";

Vue.use(VueRouter);

const routes = [
   {
      path: "/autenticacao",
      name: "Auth",
      component: Auth
   },
   {
      path: "/esqueci-minha-senha",
      name: "ForgotPassword",
      component: ForgotPassword
   },
   {
      path: "/password-reset",
      name: "ChangePassword",
      component: ChangePassword
   },
   {
      path: "/painel-controle-leitos",
      name: "BedDashboard",
      component: BedDashboard
   },
   {
      path: "/painel-controle-cirurgias",
      name: "SurgeryDashboard",
      component: SurgeryDashboard
   },
   {
      path: "/solicitacoes",
      name: "Request",
      component: Request
   },
   {
      path: "/solicitacoes-cirurgia",
      name: "SurgeryRequest",
      component: SurgeryRequest
   },
   {
      path: "/pacientes",
      name: "Patient",
      component: Patient
   },
   {
      path: "/cirurgias",
      name: "SurgeryType",
      component: SurgeryType
   },
   {
      path: "/cirurgias-aceitas",
      name: "AvaliableSurgery",
      component: AvaliableSurgery
   },
   {
      path: "/alas",
      name: "Ward",
      component: Ward
   },
   {
      path: "/administradores",
      name: "Admin",
      component: Admin
   },
   {
      path: "/Gestores",
      name: "Manager",
      component: Manager
   },
   {
      path: "/gestores-unidades-saude",
      name: "HealthUnitManager",
      component: HealthUnitManager
   },
   {
      path: "/gestores-secretarias",
      name: "HealthSecretaryManager",
      component: HealthSecretaryManager
   },
   {
      path: "/operadores",
      name: "Operator",
      component: Operator
   },
   {
      path: "/gestores-reguladores",
      name: "RegulatorManager",
      component: RegulatorManager
   },
   {
      path: "/reguladores",
      name: "Regulator",
      component: Regulator
   },
   {
      path: "/municipios",
      name: "Municipality",
      component: Municipality
   },
   {
      path: "/unidades-saude",
      name: "HealthUnit",
      component: HealthUnit
   },
   {
      path: "/secretaria-saude",
      name: "HealthSecretarie",
      component: HealthSecretarie
   },
   {
      path: "/auditoria",
      name: "Audit",
      component: Audit
   },
   {
      path: "/estatisticas-leitos",
      name: "Statistic",
      component: Statistic
   },
   {
      path: "/estatisticas-cirurgias",
      name: "SurgeryStatistic",
      component: SurgeryStatistic
   },
   {
      path: "/minha-unidade-saude",
      name: "MyHealthUnit",
      component: MyHealthUnit
   },
   {
      path: "/minha-conta",
      name: "MyAccount",
      component: MyAccount
   },
   {
      path: "/page404",
      name: "Page404",
      component: Page404
   },
   {
      path: "/",
      redirect: "/autenticacao"
   },
   {
      path: "*",
      redirect: "/page404"
   }
];

const router = new VueRouter({
   mode: "hash",
   base: process.env.BASE_URL,
   routes
});

const publicRoutes = PUBLIC_ROUTES;

function showUnauthorizedSnackBar() {
   snackBarUtil.showCustomSnackBar({
      title: "NÃO AUTORIZADO",
      message:
         "Usuário não possui permissão para acessar o recurso solicitado.",
      icon: "mdi-alert-octagon-outline",
      color: "warning"
   });
}

router.beforeEach((to, from, next) => {
   if (from.name) {
      if (store.getters["user/getUser"]) {
         const userRoutes = store.getters["user/getUserRoutes"];

         if (!userRoutes.includes(to.path)) {
            if (!publicRoutes.includes(to.path)) showUnauthorizedSnackBar();

            return next({ path: userRoutes[0] });
         }
      } else if (!publicRoutes.includes(to.path))
         return next({ path: "/autenticacao" });
   }

   next();
});

export default router;
