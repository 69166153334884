<template>
   <div>
      <FormTitle icon="mdi-lock" title="ALTERAR SENHA" />

      <v-form ref="form">
         <v-row>
            <v-col cols="12">
               <v-text-field
                  v-model="formChangePassword.old_password"
                  @click:append="showOldPassword = !showOldPassword"
                  :rules="[(value) => !!value || 'Senha atual obrigatória.']"
                  :type="showOldPassword ? 'text' : 'password'"
                  :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Senha atual*"
                  id="old-password-my-account-change-password-form-input"
               ></v-text-field>
            </v-col>
         </v-row>

         <v-row class="mt-5">
            <v-col cols="12">
               <v-text-field
                  v-model="formChangePassword.new_password"
                  @click:append="showNewPassword = !showNewPassword"
                  :rules="[
                     (value) => !!value || 'Nova senha obrigatória.',
                     (value) =>
                        passwordValidation(value) ||
                        'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                  ]"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Nova senha*"
                  id="new-password-my-account-change-password-form-input"
               ></v-text-field>
            </v-col>
         </v-row>

         <v-row class="mt-5">
            <v-col cols="12">
               <v-text-field
                  v-model="formChangePassword.confirm_new_password"
                  @click:append="
                     showConfirmationNewPassword = !showConfirmationNewPassword
                  "
                  :rules="[
                     (value) =>
                        !!value || 'Confirmação da nova senha obrigatória.',
                     (value) =>
                        value === formChangePassword.new_password ||
                        'Confirmação da nova senha e a nova senha são diferentes.',
                  ]"
                  :type="showConfirmationNewPassword ? 'text' : 'password'"
                  :append-icon="
                     showConfirmationNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  label="Confirmação da nova senha*"
                  id="confirm-new-password-my-account-change-password-form-input"
               ></v-text-field>
            </v-col>
         </v-row>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveChangePassword"
         :mode="'save'"
         :loadingSave="loadingSaveChangePassword"
         class="mt-10"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import FormActions from "../../components/base/form/FormActions";
import validationUtil from "../../utils/validationUtil";
import axiosService from "../../services/axiosService";
import authService from "../../services/authService";
import snackBarUtil from "../../utils/snackBarUtil";

export default {
   name: "ChangePasswordForm",

   components: { FormTitle, FormActions },

   props: {
      user: {
         type: Object,
      },
   },

   data: function () {
      return {
         formChangePassword: {},

         showOldPassword: false,
         showNewPassword: false,
         showConfirmationNewPassword: false,

         loadingSaveChangePassword: false,
      };
   },

   watch: {
      user: {
         immediate: true,
         handler() {
            this.formChangePassword = {};

            this.resetValidation();
         },
      },
   },

   methods: {
      ...validationUtil,

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickSaveChangePassword() {
         if (!this.user && !this.user.email) return;

         this.formChangePassword.email = this.user.email;

         this.authChangePassword(this.formChangePassword);
      },

      async authChangePassword(changePassword) {
         if (!this.executeValidation()) return;

         this.loadingSaveChangePassword = true;

         try {
            await authService.authChangePassword(
               changePassword,
               axiosService.defaults.headers.common["Authorization"]
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Sua senha foi atualizada!",
            });

            this.$emit("onUpdatedChangePassword");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveChangePassword = false;
         }
      },
   },
};
</script>

<style>
</style>