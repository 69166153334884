export default {
   namespaced: true,

   state: {
      currentHealthUnit: null,
   },

   getters: {
      getCurrentHealthUnit(state) {
         return state.currentHealthUnit;
      },
   },

   mutations: {
      setCurrentHealthUnit(state, currentHealthUnit) {
         state.currentHealthUnit = currentHealthUnit;
      },
   },
};
