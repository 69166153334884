<template>
   <div>
      <v-alert
         v-if="getMode() === 'read' && myAccount.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Sua conta foi cadastrada em
         {{
            formatDate(myAccount.created_at, "DD/MM/YYYY [às] HH:mm")
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formMyAccount.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     label="Nome*"
                     id="name-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMyAccount.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     label="CPF*"
                     id="cpf-my-account-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMyAccount.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     label="Telefone*"
                     id="phone-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMyAccount.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     label="Data de nascimento*"
                     id="birth-date-my-account-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMyAccount.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     maxlength="8"
                     label="CEP*"
                     id="zip-code-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formMyAccount.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     label="Rua*"
                     id="street-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formMyAccount.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     label="Número*"
                     id="number-my-account-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formMyAccount.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     label="Bairro*"
                     id="district-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formMyAccount.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     label="Cidade*"
                     id="city-my-account-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formMyAccount.address.state"
                     :items="statesItems"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                     id="state-my-account-form-input"
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formMyAccount.address.complement"
                     label="Complemento"
                     id="complement-my-account-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-10">Dados de autenticação</p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formMyAccount.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailValidation(value) || 'Email inválido.',
                     ]"
                     label="Email*"
                     id="email-my-account-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <MyAccountFormActions
         @onClickEdit="onClickEditMyAccount"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveMyAccount"
         @onClickChangePassword="onClickChangePassword"
         :mode="getMode()"
         :loadingSave="loadingSaveMyAccount"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import MyAccountFormActions from "./MyAccountFormActions.vue";
import adminService from "../../services/adminService";
import healthUnitManagerService from "../../services/healthUnitManagerService";
import managerService from "../../services/managerService";
import operatorService from "../../services/operatorService";
import regulatorManagerService from "../../services/regulatorManagerService";
import regulatorService from "../../services/regulatorService";
import zipCodeService from "../../services/zipCodeService";
import formatUtil from "../../utils/formatUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import validationUtil from "../../utils/validationUtil";
import util from "../../utils/util";
import healthSecretaryManagerService from "services/healthSecretaryManagerService";

export default {
   name: "MyAccountForm",

   components: { MyAccountFormActions },

   props: {
      myAccount: {
         type: Object,
      },
   },

   data: function () {
      return {
         mode: "read",

         formMyAccount: {},

         loadingSaveMyAccount: false,
         loadingSearchZipCode: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   watch: {
      myAccount: {
         immediate: true,
         handler(myAccount) {
            if (myAccount.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.formMyAccount = this.formatObjectToView(myAccount);

            this.resetValidation();
         },
      },

      "formMyAccount.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formMyAccount.address.street = response.data.logradouro;
                  this.formMyAccount.address.district = response.data.bairro;
                  this.formMyAccount.address.city = response.data.localidade;
                  this.formMyAccount.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formMyAccount.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(myAccount) {
         let myAccountTemp = util.mergeObject(
            {
               address: {},
            },
            myAccount
         );

         if (myAccountTemp.birth_date)
            myAccountTemp.birth_date = formatUtil.formatDate(
               myAccountTemp.birth_date
            );

         if (myAccountTemp.cpf)
            myAccountTemp.cpf = formatUtil.formatCPF(myAccountTemp.cpf);

         if (myAccountTemp.phone)
            myAccountTemp.phone = formatUtil.formatPhone(myAccountTemp.phone);

         return myAccountTemp;
      },

      formatObjectToSend(myAccount, removeEmptyStrings) {
         let myAccountTemp = util.removeBlankAttributes(myAccount, {
            emptyStrings: removeEmptyStrings,
         });

         if (myAccountTemp.birth_date)
            myAccountTemp.birth_date = formatUtil.formatDate(
               myAccountTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (myAccountTemp.cpf)
            myAccountTemp.cpf = myAccountTemp.cpf.replace(/[^0-9]/g, "");

         if (myAccountTemp.phone)
            myAccountTemp.phone = myAccountTemp.phone.replace(/[^0-9]/g, "");

         return myAccountTemp;
      },

      onClickEditMyAccount() {
         this.setMode("save");
      },

      onClickCancel() {
         this.setMode("read");
         this.formMyAccount = this.formatObjectToView(this.myAccount);
      },

      onClickSaveMyAccount() {
         this.updateMyAccount(this.formMyAccount.id, this.formMyAccount);
      },

      onClickChangePassword() {
         this.$emit("onClickChangePassword");
      },

      setMode(mode) {
         this.validationErrors = [];
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      async updateMyAccount(myAccountId, myAccount) {
         if (!this.executeValidation() || !myAccountId || !myAccount) return;

         this.loadingSaveMyAccount = true;

         try {
            switch (this.userStore.sub_type) {
               case "admin":
                  await adminService.updateAdmin(
                     myAccountId,
                     this.formatObjectToSend(myAccount, false)
                  );
                  break;
               case "manager":
                  await managerService.updateManager(
                     myAccountId,
                     this.formatObjectToSend(myAccount, false)
                  );
                  break;
               case "healthunit_manager":
                  var healthUnitManagerTemp = this.formatObjectToSend(
                     myAccount,
                     false
                  );
                  delete healthUnitManagerTemp.healthunit_id;

                  await healthUnitManagerService.updateHealthUnitManager(
                     myAccountId,
                     healthUnitManagerTemp
                  );
                  break;
               case "secretary_manager":
                  var healthSecretaryManagerTemp = this.formatObjectToSend(
                     myAccount,
                     false
                  );
                  delete healthSecretaryManagerTemp.healthsecretary_id;

                  await healthSecretaryManagerService.updateHealthSecretaryManager(
                     myAccountId,
                     healthSecretaryManagerTemp
                  );
                  break;
               case "solicitator":
               case "executor":
                  await operatorService.updateOperator(
                     myAccountId,
                     this.formatObjectToSend(myAccount, false)
                  );
                  break;
               case "regulator_manager":
                  var regulatorManagerTemp = this.formatObjectToSend(
                     myAccount,
                     false
                  );
                  delete regulatorManagerTemp.crm;

                  await regulatorManagerService.updateRegulatorManager(
                     myAccountId,
                     regulatorManagerTemp
                  );
                  break;
               case "regulator":
                  var regulatorTemp = this.formatObjectToSend(myAccount, false);
                  delete regulatorTemp.crm;

                  await regulatorService.updateRegulator(
                     myAccountId,
                     regulatorTemp
                  );
                  break;
            }

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Seus dados foram atualizados!",
            });

            this.setMode("read");
            this.$emit("onUpdatedMyAccount");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveMyAccount = false;
         }
      },
   },
};
</script>

<style>
</style>