<template>
   <div class="d-flex flex-column justify-center align-start">
      <p class="caption">Situação atual</p>
      <v-chip :color="configByStatus.color" small dark>{{
         configByStatus.translatedState
      }}</v-chip>

      <div class="d-flex align-center mt-2">
         <div>
            <p class="caption">Nome</p>
            <p class="body-2 font-weight-medium">{{ bed.name || "" }}</p>
         </div>

         <div class="mx-5">
            <p class="caption">Código do leito</p>
            <p class="body-2 font-weight-medium">{{ bed.code }}</p>
         </div>

         <div>
            <p class="caption">Última alteração</p>
            <p class="body-2 font-weight-medium">
               {{ formatDate(bed.updated_at) }}
            </p>
         </div>
      </div>

      <div
         v-if="bed.status === 'waiting_reservation'"
         class="d-flex align-center mt-2 pb-1"
      >
         <v-icon color="warning" size="20" left>mdi-alert</v-icon>
         <p class="warning--text font-weight-bold">
            Leito aguardando confirmação de reserva.
         </p>
      </div>
   </div>
</template>

<script>
import formatUtil from "../../../utils/formatUtil";

export default {
   name: "BedTooltipDetails",

   props: {
      bed: {
         type: Object,
      },
   },

   computed: {
      configByStatus() {
         let translatedState = "Indefinido";
         let color = "black";

         if (this.bed && this.bed.status) {
            switch (this.bed.status) {
               case "free":
                  color = "success";
                  translatedState = "Livre";
                  break;
               // case "waiting_reservation":
               //    color = "success";
               //    translatedState = "Aguardando reserva";
               //    break;
               case "blocked":
                  color = "error";
                  translatedState = "Bloqueado";
                  break;
               case "occupied":
                  color = "warning";
                  translatedState = "Ocupado";
                  break;
               case "reserved":
                  color = "purple";
                  translatedState = "Reservado";
                  break;
            }
         }

         return { color, translatedState };
      },
   },

   methods: {
      ...formatUtil,
   },
};
</script>

<style scoped>
</style>