<template>
   <div class="div-scrollable">
      <v-card
         v-if="!options.xaxis.categories.length"
         class="d-flex flex-column align-center justify-center"
         height="100%"
         flat
      >
         <p class="text-center text--secondary">Nenhum cirurgia cadastrada.</p>
      </v-card>

      <apexchart
         v-else
         :options="options"
         :series="series"
         :height="chartHeight"
         :key="chartInputKey"
         class="mt-n3 ml-n3"
         type="bar"
      ></apexchart>
   </div>
</template>

<script>
export default {
   name: "RegulationByMunicipalityChart",

   props: {
      statistics: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         options: {
            colors: this.surgeryColors,

            chart: {
               id: "surgeries-chart",
               toolbar: {
                  show: false,
               },
            },

            xaxis: {
               categories: [],
               labels: {
                  show: false,
               },
            },

            yaxis: {
               labels: {
                  show: false,
               },
            },

            plotOptions: {
               bar: {
                  dataLabels: {
                     position: "bottom",
                  },
                  borderRadius: 5,
                  horizontal: true,
                  distributed: true,
               },
            },

            dataLabels: {
               textAnchor: "start",
               offsetX: 0,
               dropShadow: {
                  enabled: false,
               },
               style: {
                  fontSize: "10px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "600",
                  colors: ["#3b3b3b"],
               },
               formatter: function (val, opt) {
                  return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
               },
            },

            tooltip: {
               theme: "dark",
               x: {
                  show: false,
               },
               marker: {
                  show: false,
               },
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },

            legend: {
               show: false,
            },
         },

         chartInputKey: 0,
      };
   },

   watch: {
      statistics: {
         immediate: true,
         handler(statistics) {
            if (Array.isArray(statistics)) {
               this.options.xaxis.categories = statistics.map(
                  (item) => item.name
               );

               this.forceChartInputRerender();
            }
         },
      },
   },

   computed: {
      series() {
         let result = [
            {
               name: "Cirurgia(s)",
               data: [],
            },
         ];

         if (this.statistics.length > 0)
            result[0].data = this.statistics.map((item) => item.total);

         return result;
      },

      surgeryColors() {
         return this.statistics.forEach((item) => {
            this.options.colors.push(this.colorByName(item.name));
         });
      },

      chartHeight() {
         let result = this.options.xaxis.categories.length * 45;

         if (this.options.xaxis.categories.length <= 1)
            result = this.options.xaxis.categories.length * 90;
         else if (this.options.xaxis.categories.length <= 3)
            result = this.options.xaxis.categories.length * 75;
         else if (this.options.xaxis.categories.length <= 8)
            result = this.options.xaxis.categories.length * 60;

         return result;
      },
   },

   methods: {
      forceChartInputRerender() {
         this.chartInputKey += 1;
      },

      splitString(string, namesPerItem) {
         let spacesCount = 0;
         let stringTemp = string;
         const arrayFinal = [];

         for (let index = 0; index < string.length; index++) {
            const element = string[index];

            if (element === " ") spacesCount++;

            if (spacesCount === (namesPerItem || 2)) {
               spacesCount = 0;
               arrayFinal.push(stringTemp.substr(0, index));
               stringTemp = stringTemp.substr(index + 1, stringTemp.length);
            }
         }

         if (stringTemp.length) arrayFinal.push(stringTemp);

         return arrayFinal;
      },

      colorByName(name, saturation = 60, lightness = 70) {
         let hash = 0;
         for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
         }
         let colour = "#";
         for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xff;
            colour += ("00" + value.toString(16)).substr(-2);
         }
         const hsl = this.hexToHSL(colour);

         return `hsl(${hsl.h * 255}, ${saturation}%, ${lightness}%)`;
      },

      hexToHSL(hex) {
         let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
         let r = parseInt(result[1], 16);
         let g = parseInt(result[2], 16);
         let b = parseInt(result[3], 16);
         (r /= 255), (g /= 255), (b /= 255);
         let max = Math.max(r, g, b),
            min = Math.min(r, g, b);
         let h,
            s,
            l = (max + min) / 2;
         if (max == min) {
            h = s = 0; // achromatic
         } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
               case r:
                  h = (g - b) / d + (g < b ? 6 : 0);
                  break;
               case g:
                  h = (b - r) / d + 2;
                  break;
               case b:
                  h = (r - g) / d + 4;
                  break;
            }
            h /= 6;
         }
         let HSL = new Object();
         HSL["h"] = h;
         HSL["s"] = s;
         HSL["l"] = l;
         return HSL;
      },
   },
};
</script>

<style scoped>
::v-deep::-webkit-scrollbar {
   width: 0px;
   background-color: white;
}

::v-deep::-webkit-scrollbar-thumb {
   background-color: #c2c2c2;
}

.div-scrollable {
   overflow-y: scroll;
   /* height: 600px; */
}

@media (min-width: 1263px) {
   .div-scrollable {
      overflow-y: scroll;
      height: 550px;
   }
}

@media (min-width: 959px) and (max-width: 1263px) {
   .div-scrollable {
      overflow-y: scroll;
      height: 500px;
   }
}

@media (max-width: 959px) {
   .div-scrollable {
      overflow-y: hidden;
      height: 100%;
   }
}
</style>