<template>
   <div>
      <v-switch
         v-model="doctorOpinion.patient_not_fit_for_regulation"
         label="Paciente não preenche criterios para regulação"
         id="patient-not-fit-for-regulation-doctor-opinion-form-input"
      ></v-switch>

      <div v-if="!doctorOpinion.patient_not_fit_for_regulation" class="mt-2">
         <p class="caption mb-1">Prioridade*</p>

         <v-chip-group
            v-model="doctorOpinion.priority"
            @change="(value) => (invalidPriority = !value)"
            class="my-n2"
            column
         >
            <v-chip
               color="info"
               value="blue"
               small
               filter
               id="blue-doctor-opinion-form-input"
            >
               Azul
            </v-chip>
            <v-chip
               color="success"
               value="green"
               small
               filter
               id="green-doctor-opinion-form-input"
            >
               Verde
            </v-chip>
            <v-chip
               color="amber"
               value="yellow"
               small
               filter
               id="yellow-doctor-opinion-form-input"
            >
               Amarela
            </v-chip>
            <v-chip
               color="warning"
               value="orange"
               small
               filter
               id="orange-doctor-opinion-form-input"
            >
               Laranja
            </v-chip>
            <v-chip
               color="error"
               value="red"
               small
               filter
               id="red-doctor-opinion-form-input"
            >
               Vermelha
            </v-chip>
         </v-chip-group>

         <v-expand-transition>
            <p
               v-if="invalidPriority"
               class="caption error--text mt-1"
               id="required-priority-doctor-opnion-form-text"
            >
               Prioridade obrigatória.
            </p>
         </v-expand-transition>

         <v-row class="mt-3">
            <v-col cols="12">
               <SurgerieInput
                  @onSelectSurgerie="onSelectSurgerie"
                  :surgerySusCode="doctorOpinion.sus_code"
                  ref="surgerytypeinput"
               />
            </v-col>
         </v-row>
      </div>

      <v-row class="mt-4">
         <v-col cols="12">
            <v-textarea
               v-model="doctorOpinion.justification"
               :rules="[(value) => !!value || 'Justificativa obrigatória.']"
               class="justification-height"
               label="Justificativa*"
               rows="8"
               no-resize
               id="justification-doctor-opinion-form-input"
            ></v-textarea>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import SurgerieInput from "components/surgerie/SurgerieInput";

export default {
   name: "DoctorOpinionForm",

   components: { SurgerieInput },

   props: {
      doctorOpinion: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },

   data: function () {
      return {
         invalidPriority: false,
      };
   },

   methods: {
      resetValidation() {
         this.invalidPriority = false;
         // if (this.$refs.surgerytypeinput) this.$refs.surgerytypeinput.reset();
      },

      validate() {
         if (!this.doctorOpinion.patient_not_fit_for_regulation) {
            this.invalidPriority = !this.doctorOpinion.priority;

            return !this.invalidPriority;
         } else return true;
      },

      onSelectSurgerie(surgerie) {
         this.doctorOpinion.sus_code = surgerie.sus_code;
      },
   },
};
</script>

<style scoped>
.justification-height {
   height: 100%;
}
</style>