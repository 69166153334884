<template>
   <div>
      <v-dialog
         v-model="dialogRequestCancelFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeRequestCancelForm" />

            <RequestCancelForm
               @onCancelRequest="
                  closeRequestCancelForm();
                  refreshRequestOperatorList();
               "
               :request="requestSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogRequestFormVisibility"
         :content-class="
            disableDialogScrolling ? 'dialog-overflow-hidden' : null
         "
         max-width="1000"
         transition="slide-y-transition"
         persistent
         no-click-animation
      >
         <v-card id="scrollTop" tile>
            <Close @onClickClose="closeRequestForm" />

            <div class="d-flex justify-center align-center">
               <!-- Formulário exibido para o Administrador -->
               <RequestAdminForm
                  v-if="userStore.sub_type === 'admin'"
                  :request="requestSelected"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
               />

               <!-- Formulário exibido para Regulador e Gestor Regulador -->
               <RequestRegulatorForm
                  v-else-if="
                     userStore.sub_type === 'regulator_manager' ||
                        userStore.sub_type === 'regulator'
                  "
                  @onClickCancel="closeRequestForm"
                  @onUpdatedRequest="
                     closeRequestForm();
                     refreshRequestRegulatorList();
                  "
                  @onOpenHistory="dialogScroll(true)"
                  @onCloseHistory="dialogScroll(false)"
                  @onOpenChat="
                     dialogScroll(true);
                     setRegulatorMessagesAsRead();
                  "
                  @onCloseChat="dialogScroll(false)"
                  @onSendMessage="onSendMessageRegulatorForm"
                  :request="requestSelected"
                  :user="fullUserStore"
                  :editMode="editMode"
                  :hideCheckButton="
                     requestSelected.is_checked || requestSelected.is_closed
                  "
                  :hideEditButton="hideEditButtonRegulator"
                  :hideReserveBedButton="hideReserveBedButtonRegulator"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
                  hideRemoveButton
               />

               <!-- Formulário exibido para Operador Solicitante e Gestor de secretaria -->
               <RequestOperatorForm
                  v-else-if="
                     userStore.sub_type === 'solicitator' ||
                        userStore.sub_type === 'secretary_manager'
                  "
                  @onClickCancel="closeRequestForm"
                  @onCreatedRequest="
                     closeRequestForm();
                     refreshRequestOperatorList();
                  "
                  @onUpdatedRequest="
                     closeRequestForm();
                     refreshRequestOperatorList();
                  "
                  @onOpenChat="dialogScroll(true)"
                  @onCloseChat="dialogScroll(false)"
                  @onSendMessage="onSendMessageOperatorForm"
                  :request="requestSelected"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :editMode="editMode"
                  :hideEditButton="hideEditButtonOperator"
                  :hideActions="requestSelected.is_archived"
                  class="standard-padding fullscreen-width"
                  hideRemoveButton
               />
            </div>
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <!-- <div class="d-flex flex-column align-start">
         <div class="d-flex justify-center align-center">
            <v-tabs
               @change="changeTabs"
               id="request-type"
               background-color="transparent"
               height="35"
            >
               <v-tab
                  v-for="type in requestTypes"
                  :key="type.value"
                  :ripple="false"
                  :value="type.value"
                  class="px-7"
                  >{{ type.text }}</v-tab
               >
            </v-tabs>
         </div> -->

      <!-- <p class="caption text--secondary mt-2">
            Você está visualizando as solicitações de <strong>Adulto</strong>.
         </p> -->
      <!-- </div> -->

      <v-card class="overflow-hidden mt-5">
         <!-- Listagem exibida para o Administrador -->
         <v-tabs
            v-if="userStore.sub_type === 'admin'"
            v-model="activeAdminTab"
            centered
            show-arrows
         >
            <v-tab id="open-requests-button">
               <v-icon small left> mdi-file-document </v-icon>
               SOLICITAÇÕES ABERTAS
            </v-tab>

            <v-tab id="archived-button">
               <v-icon small left> mdi-archive-arrow-down </v-icon>
               SOLICITAÇÕES ARQUIVADAS
            </v-tab>

            <v-tab-item>
               <RequestAdminList
                  @onClickViewRequest="onClickViewRequest"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="openrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestAdminList
                  @onClickViewRequest="onClickViewRequest"
                  :initialQuery="[
                     {
                        attribute: 'is_archived',
                        query: 'true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideEditButton
                  hideAddButton
                  hideRemoveButton
                  ref="archivedrequestlist"
               />
            </v-tab-item>
         </v-tabs>

         <!-- Listagem exibida para Regulador e Gestor Regulador -->
         <v-tabs
            v-else-if="
               userStore.sub_type === 'regulator_manager' ||
                  userStore.sub_type === 'regulator'
            "
            v-model="activeRegulatorTab"
            centered
            show-arrows
         >
            <v-tab id="all_request-health-unit-button">
               <v-icon small left>mdi-file-document</v-icon>
               TODAS SOLICITAÇÕES
            </v-tab>

            <v-tab id="my_request-health-unit-button">
               <v-icon small left> mdi-account </v-icon>
               MINHAS SOLICITAÇÕES
            </v-tab>

            <v-tab id="filed-health-unit-button">
               <v-icon small left> mdi-folder-open </v-icon>
               SOLICITAÇÕES FINALIZADAS
            </v-tab>

            <!-- Aba visível só para o gestor regulador -->
            <v-tab
               v-if="userStore.sub_type === 'regulator_manager'"
               id="archived-health-unit-button"
            >
               <v-icon small left> mdi-archive-arrow-down </v-icon>
               SOLICITAÇÕES ARQUIVADAS
            </v-tab>

            <v-tab-item>
               <RequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     { attribute: 'is_closed', query: 'false' },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="allrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  :user="userStore"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     { attribute: 'is_closed', query: 'false' },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'registered',
                     'in_progress',
                     'answered',
                     'regulated',
                     'rejected_by_executor'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="myrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'true'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="filedrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestRegulatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  :hideEditButton="!(fullUserStore && fullUserStore.crm)"
                  :initialQuery="[
                     {
                        attribute: 'is_archived',
                        query: 'true'
                     }
                  ]"
                  :allowedStatusInStatusFilter="[
                     'answered',
                     'rejected',
                     'canceled'
                  ]"
                  hideAddButton
                  hideRemoveButton
                  ref="archivedrequestlist"
               />
            </v-tab-item>
         </v-tabs>

         <!-- Listagem exibida para Operador Solicitante e Gestor de secretaria -->
         <v-tabs
            v-else-if="
               userStore.sub_type === 'solicitator' ||
                  userStore.sub_type === 'secretary_manager'
            "
            v-model="activeOperatorTab"
            centered
            show-arrows
         >
            <v-tab id="request-health-unit-button">
               <v-icon small left> mdi-hospital-building </v-icon>
               SOLICITAÇÕES DA UNIDADE
            </v-tab>

            <v-tab id="my_request-health-unit-button">
               <v-icon small left> mdi-account </v-icon>
               MINHAS SOLICITAÇÕES
            </v-tab>

            <v-tab id="filed-health-unit-button">
               <v-icon small left> mdi-folder-open </v-icon>
               SOLICITAÇÕES FINALIZADAS
            </v-tab>

            <v-tab-item>
               <RequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :userInfo="'all-health-unit'"
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'false'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  hideAddButton
                  ref="allhealthunitrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :user="userStore"
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     { attribute: 'is_closed', query: 'false' },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :userInfo="'my-health-unit'"
                  ref="myhealthunitrequestlist"
               />
            </v-tab-item>

            <v-tab-item>
               <RequestOperatorList
                  @onClickAddRequest="onClickAddRequest"
                  @onClickViewRequest="onClickViewRequest"
                  @onClickEditRequest="onClickEditRequest"
                  @onClickRemoveRequest="onClickRemoveRequest"
                  :healthUnit="
                     operatorCurrentHealthUnitStore ||
                        healthSecretaryManagerCurrentHealthUnitStore
                  "
                  :operatorType="userStore.sub_type"
                  :initialQuery="[
                     {
                        attribute: 'is_closed',
                        query: 'true'
                     },
                     {
                        attribute: 'is_archived',
                        query: 'ne:true'
                     }
                  ]"
                  :userInfo="'filed-health-unit'"
                  hideAddButton
                  ref="filedhealthunitrequestlist"
               />
            </v-tab-item>
         </v-tabs>
      </v-card>
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import RequestCancelForm from "components/request/cancel/RequestCancelForm";
import PageTitle from "../../components/base/PageTitle";
import RequestAdminForm from "../../components/request/form/RequestAdminForm";
import RequestOperatorForm from "../../components/request/form/RequestOperatorForm";
import RequestRegulatorForm from "../../components/request/form/RequestRegulatorForm";
import RequestOperatorList from "../../components/request/list/RequestOperatorList";
import RequestRegulatorList from "../../components/request/list/RequestRegulatorList";
import RequestAdminList from "../../components/request/list/RequestAdminList";
import responsiveUtil from "../../utils/responsiveUtil";
import regulatorService from "../../services/regulatorService";
import regulatorManagerService from "../../services/regulatorManagerService";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import socketIoService from "services/socketIoService";

export default {
   name: "Request",

   components: {
      Close,
      RequestCancelForm,
      PageTitle,
      RequestAdminForm,
      RequestOperatorForm,
      RequestRegulatorForm,
      RequestOperatorList,
      RequestRegulatorList,
      RequestAdminList
   },

   data: function() {
      return {
         requestSelected: {},

         editMode: false,

         activeAdminTab: 0,
         activeOperatorTab: 0,
         activeRegulatorTab: 0,

         dialogRequestCancelFormVisibility: false,
         dialogRequestFormVisibility: false,

         disableDialogScrolling: false,

         socketIoConnection: null,

         requestTypes: [
            { text: "Adulto", value: "adult" },
            { text: "Pediatria", value: "pediatrics" }
         ]
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      healthSecretaryManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthSecretaryManager/getCurrentHealthSecretary"
            ];
         },

         set(value) {
            this.$store.commit(
               "healthSecretaryManager/setCurrentHealthSecretary",
               value
            );
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      },

      hideEditButtonOperator() {
         return !["registered", "in_progress"].includes(
            this.requestSelected.status
         );
      },

      hideEditButtonRegulator() {
         return (
            this.requestSelected.is_closed || !this.requestSelected.is_checked
         );
      },

      /* 
      Esconde o botão de reservar leito caso:
      1. A solicitação esteja fechada.
      2. A solicitação não tenha leito associada. 
      3. A solicitação não tem parecer médico.
      4. A solicitação tem status diferente de in_progress ou rejected_by_executor.
      */
      hideReserveBedButtonRegulator() {
         if (
            !this.requestSelected.is_checked ||
            !(
               this.requestSelected.doctor_opinion &&
               this.requestSelected.doctor_opinion.priority
            )
         )
            return true;
         else {
            return !["in_progress", "rejected_by_executor"].includes(
               this.requestSelected.status
            );
         }
      },

      requestTypeStore: {
         get() {
            return this.$store.getters["request/getRequestType"];
         },

         set(value) {
            this.$store.commit("request/setRequestType", value);
         }
      }
   },

   watch: {
      dialogRequestFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;

         if (
            ["regulator_manager", "regulator"].includes(this.userStore.sub_type)
         ) {
            // Efetua block e unblock nas requisições
            if (this.requestSelected.id) {
               if (val) {
                  // Efetua o block da request
                  this.socketIoConnection.emit(
                     "block_request",
                     this.requestSelected.id,
                     "covid"
                  );
               } else {
                  // Efetua o unblock da request
                  this.socketIoConnection.emit(
                     "unblock_request",
                     this.requestSelected.id,
                     "covid"
                  );
               }
            }
         }
      },

      activeAdminTab() {
         this.refreshRequestAdminList();
      },

      activeRegulatorTab() {
         this.refreshRequestRegulatorList();
      },

      activeOperatorTab() {
         this.refreshRequestOperatorList();
      },

      "socketIoConnection.on": function() {
         this.socketIoConnection.on("connect", () => {});

         this.socketIoConnection.on("block_request", requestId => {
            const component = this.getActiveRequestRegulatorList();
            if (component) component.blockRequestByRef(requestId);
         });

         this.socketIoConnection.on("unblock_request", requestId => {
            const component = this.getActiveRequestRegulatorList();
            if (component) component.unblockRequestByRef(requestId);
         });

         // this.socketIoConnection.on("error", (err) => {});
      },

      /**
       * Monitora os query params na url, caso tenha algum parâmetro vai checa-lós
       * e abrir a tab específica de acordo com os query passados.
       * OBS.: Válido só para o regulador e gestor regulador.
       */
      "$route.query": {
         immediate: true,
         handler(value) {
            switch (this.userStore.sub_type) {
               case "regulator_manager":
               case "regulator":
                  if (Object.keys(value).length) {
                     // Acessa o query params e abre a tab especificada nos query params.
                     this.setTabIndexFromQueryParams();
                  }
                  break;
            }
         }
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddRequest(request) {
         this.requestSelected = Object.assign({}, request);
         this.openRequestForm();
      },

      onClickViewRequest(request) {
         if (!request.is_blocked) {
            this.requestSelected = Object.assign({}, request);
            this.openRequestForm();
         } else {
            snackBarUtil.showCustomSnackBar({
               color: "warning",
               title: "SOLICITAÇÃO BLOQUEADA!",
               message: "Outro usuário já está visualizando essa solicitação"
            });
         }
      },

      onClickEditRequest() {
         this.editMode = true;
      },

      onClickRemoveRequest(request) {
         this.requestSelected = Object.assign({}, request);
         this.openRequestCancelForm();
      },

      openRequestForm() {
         this.dialogRequestFormVisibility = true;
      },

      closeRequestForm() {
         this.dialogRequestFormVisibility = false;
      },

      openRequestCancelForm() {
         this.dialogRequestCancelFormVisibility = true;
      },

      closeRequestCancelForm() {
         this.dialogRequestCancelFormVisibility = false;
      },

      dialogScroll(status) {
         this.disableDialogScrolling = !!status;
      },

      getActiveRequestAdminList() {
         let component;

         switch (this.activeAdminTab) {
            case 0:
               component = this.$refs.openrequestlist;
               break;
            case 1:
               component = this.$refs.archivedrequestlist;
               break;
         }

         return component;
      },

      refreshRequestAdminList() {
         const component = this.getActiveRequestAdminList();
         if (component) component.refreshAllRequestsByRef(true);
      },

      getActiveRequestOperatorList() {
         let component;

         switch (this.activeOperatorTab) {
            case 0:
               component = this.$refs.allhealthunitrequestlist;
               break;
            case 1:
               component = this.$refs.myhealthunitrequestlist;
               break;
            case 2:
               component = this.$refs.filedhealthunitrequestlist;
               break;
         }

         return component;
      },

      refreshRequestOperatorList() {
         const component = this.getActiveRequestOperatorList();
         if (component) component.refreshAllHealthUnitRequestsByRef(true);
      },

      onSendMessageOperatorForm(request) {
         const component = this.getActiveRequestOperatorList();
         if (component) component.incrementRequestTotalMessagesByRef(request);
      },

      getActiveRequestRegulatorList() {
         let component;

         switch (this.activeRegulatorTab) {
            case 0:
               component = this.$refs.allrequestlist;
               break;
            case 1:
               component = this.$refs.myrequestlist;
               break;
            case 2:
               component = this.$refs.filedrequestlist;
               break;
            case 3:
               component = this.$refs.archivedrequestlist;
               break;
         }

         return component;
      },

      refreshRequestRegulatorList() {
         const component = this.getActiveRequestRegulatorList();
         if (component) component.refreshAllRequestsByRef(true);
      },

      setRegulatorMessagesAsRead() {
         // Zera as mensagens não lidas
         this.requestSelected.unreadMessages = 0;
         // Marca a notificação com não lida
         this.$store.commit(
            "notificationCenter/setNewMessageNotificationAsRead",
            this.requestSelected.id
         );

         const component = this.getActiveRequestRegulatorList();
         if (component)
            component.setMessagesAsReadByRef(this.requestSelected.id);
      },

      onSendMessageRegulatorForm(request) {
         const component = this.getActiveRequestRegulatorList();
         if (component) component.incrementRequestTotalMessagesByRef(request);
      },

      async getRegulatorById(regulatorId) {
         try {
            const response = await regulatorService.getRegulatorById(
               regulatorId
            );

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      async getRegulatorManagerById(regulatorManagerId) {
         try {
            const response = await regulatorManagerService.getRegulatorManagerById(
               regulatorManagerId
            );

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      changeTabs(tabIndex) {
         this.requestTypeStore = this.requestTypes[tabIndex].value;

         switch (this.userStore.sub_type) {
            case "admin":
               this.refreshRequestAdminList();
               break;

            case "solicitator":
            case "healthunit_manager":
            case "secretary_manager":
               this.refreshRequestOperatorList();
               break;

            case "regulator_manager":
            case "regulator":
               this.refreshRequestRegulatorList();
               break;
         }
      },

      // Identifica os parametros do url e abre a aba específica nas tabs das solicitações.
      setTabIndexFromQueryParams() {
         const queryParams = this.$route.query;

         if (queryParams.is_closed === "true") this.activeRegulatorTab = 2;
         else this.activeRegulatorTab = 0;
      }
   },

   async created() {
      switch (this.userStore.sub_type) {
         case "solicitator":
            if (
               !(
                  this.operatorCurrentHealthUnitStore &&
                  this.operatorCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "secretary_manager":
            if (
               !(
                  this.healthSecretaryManagerCurrentHealthUnitStore &&
                  this.healthSecretaryManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "regulator_manager":
            await this.getRegulatorManagerById(this.userStore.sub);

            // Conexão com socket.io
            this.socketIoConnection = socketIoService.getConnection();
            break;
         case "regulator":
            await this.getRegulatorById(this.userStore.sub);

            // Conexão com socket.io
            this.socketIoConnection = socketIoService.getConnection();
            break;
      }
   }
};
</script>

<style scoped>
::v-deep .dialog-overflow-hidden {
   overflow-y: hidden !important;
}

.fullscreen-width {
   width: 1000px !important;
   max-width: 1000px !important;
}

::v-deep #request-type {
   background-color: rgba(0, 0, 0, 0.05);
   border-radius: 100px;
}

::v-deep #request-type .v-tab::before {
   background-color: transparent;
   border-radius: 100px !important;
}

::v-deep #request-type .v-tabs-slider-wrapper {
   opacity: 0;
   height: 100% !important;
}

::v-deep #request-type .v-tab:not(.v-tab--active) {
   color: rgba(0, 0, 0, 0.3);
}

::v-deep #request-type .v-tab--active {
   background-color: var(--v-primary-base) !important;
   border-radius: 100px !important;
   color: white;
}
</style>
