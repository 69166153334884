var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingAuditsFully)?_c('ListSkeleton',{attrs:{"headersLength":_vm.headers.length - 1,"itemsPerPage":_vm.limit}}):_c('v-data-table',{staticClass:"elevation-2 standard-padding-data-list",attrs:{"headers":_vm.headers,"items":_vm.audits,"loading":_vm.loadingAuditsPartially,"items-per-page":_vm.limit,"expanded":_vm.expanded,"item-key":"id","mobile-breakpoint":"960","loader-height":"3px","single-expand":"","show-expand":"","hide-default-footer":"","disable-sort":""},on:{"click:row":function (audit) { return (!_vm.blockClick ? _vm.onClickViewAudit(audit) : null); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('AuditListHeader',{attrs:{"sort":_vm.sort,"date":_vm.date,"sorts":_vm.sorts,"filter":_vm.filter,"filters":_vm.filters,"loading":_vm.loadingAuditsPartially,"hideAddButton":_vm.hideAddButton,"filterActor":"auditoria","id":"header"},on:{"onChangeSort":_vm.onChangeSort,"onChangeFilter":_vm.onChangeFilter,"onClearFilter":_vm.onClearFilter,"onSearchItems":_vm.onSearchItems,"onChangeDate":_vm.onChangeDate}})]},proxy:true},{key:"footer",fn:function(){return [_c('ListFooter',{attrs:{"page":_vm.page,"limit":_vm.limit,"totalItems":_vm.totalAudits,"loading":_vm.loadingAuditsPartially},on:{"onChangePage":_vm.onChangePage,"onChangeLimit":_vm.onChangeLimit}})]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"standard-padding cursor-config",attrs:{"width":"100%","tile":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"grey--text lighten-3 mb-1"},[_vm._v(" Conteúdo da requisição ")]),_c('div',{staticClass:"json-container standard-padding-tiny"},[_c('code',{staticClass:"code-container body-audit-list-data"},[_vm._v(_vm._s(item.request.body ? item.request.body : "{}"))])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"grey--text lighten-3 mb-1"},[_vm._v(" Conteúdo da resposta ")]),_c('div',{staticClass:"json-container standard-padding-tiny"},[_c('code',{staticClass:"code-container body-audit-list-data"},[_vm._v(_vm._s(item.response.body ? item.response.body : "{}"))])])])],1),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"grey--text lighten-3"},[_vm._v("Dispositivo")]),_c('p',{staticClass:"user_agent-audit-list-data"},[_vm._v(" "+_vm._s(item.request.user_agent)+" ")])])],1)],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('NoData',{attrs:{"message":"Desculpe, nenhum registro foi encontrado."}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"standard-padding"},[_c('LoadingData',{attrs:{"message":"Carregando registro, aguarde por favor."}})],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"id-audit-list"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.user.type",fn:function(ref){
var item = ref.item;
return [_c('p',{class:['user-type-audit-list']},[_vm._v(" "+_vm._s(item.user.type)+" ")])]}},{key:"item.request.operator",fn:function(ref){
var item = ref.item;
return [_c('p',{class:['operator-action-audit-list']},[_vm._v(" "+_vm._s(item.request.operator)+" ")])]}},{key:"item.request.path",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"path-wrap request-audit-list-data"},[_vm._v(" "+_vm._s(item.request.path)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"date-audit-list-data"},[_vm._v(" "+_vm._s(_vm.formatDate(item.date, "DD/MM/YYYY [às] HH:mm"))+" ")])]}},{key:"item.response.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getColor(item.response.code),"dark":"","small":""}},[_c('span',{staticClass:"code-audit-list"},[_vm._v(" "+_vm._s(item.response.code)+" ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }