
<template>
   <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="config"
   >
      <g>
         <path
            d="M5.8,11.4c-0.3,0.3-0.5,0.7-0.6,1.2l5.5,2.4l8.5-8.5c0.8-0.8,0.8-2.1,0-2.8l-1.4-1.4c-0.8-0.8-2-0.8-2.8,0L5.8,11.4		 M3.5,15.5v5.8l5.8-5.2l-5-2L3.5,15.5 M15.7,4.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4s-1,0.4-1.4,0C15.3,5.4,15.3,4.7,15.7,4.3z"
         />
         <g>
            <path
               d="M20.1,16.2l-0.6-0.6L16.9,18l-2.5-2.5l-0.6,0.6l2.5,2.5l-2.5,2.5l0.6,0.6l2.5-2.5l2.5,2.5l0.6-0.6l-2.5-2.5L20.1,16.2z"
            />
            <path
               d="M19.4,22.3l-2.5-2.5l-2.5,2.5l-1.1-1.1l2.5-2.5l-2.5-2.5l1.1-1.1l2.5,2.5l2.5-2.5l1.1,1.1L18,18.7l2.5,2.5L19.4,22.3z
		M16.9,18.9l2.5,2.5l0.2-0.2l-2.5-2.5l2.5-2.5L19.4,16l-2.5,2.5L14.4,16l-0.2,0.2l2.5,2.5l-2.5,2.5l0.2,0.2L16.9,18.9z"
            />
         </g>
      </g>
   </svg>
</template>

<script>
export default {
   name: "BoxCutterClose",
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>