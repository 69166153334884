<template>
   <div>
      <v-row>
         <v-col cols="12" sm="4">
            <p class="caption mb-1">Teste rápido</p>

            <v-chip-group v-model="sarscov2.rapid_test" class="my-n2" column>
               <v-chip
                  :color="
                     sarscov2.rapid_test === 'positive' ? 'primary' : 'normal'
                  "
                  value="positive"
                  filter
                  small
                  id="rapid_test_positive-sarscov2-form-input"
               >
                  Positivo
               </v-chip>
               <v-chip
                  :color="
                     sarscov2.rapid_test === 'negative' ? 'primary' : 'normal'
                  "
                  value="negative"
                  filter
                  small
                  id="rapid_test_negative-sarscov2-form-input"
               >
                  Negativo
               </v-chip>
               <v-chip
                  :color="
                     sarscov2.rapid_test === 'unrealized' ? 'primary' : 'normal'
                  "
                  value="unrealized"
                  filter
                  small
                  id="rapid_test_unrealized-sarscov2-form-input"
               >
                  Não realizou
               </v-chip>
            </v-chip-group>
         </v-col>

         <v-col cols="12" sm="4">
            <p class="caption mb-1">IGM</p>

            <v-chip-group v-model="sarscov2.igm" class="my-n2" column>
               <v-chip
                  :color="sarscov2.igm === 'positive' ? 'primary' : 'normal'"
                  value="positive"
                  filter
                  small
                  id="igm_positive-sarscov2-form-input"
               >
                  Positivo
               </v-chip>
               <v-chip
                  :color="sarscov2.igm === 'negative' ? 'primary' : 'normal'"
                  value="negative"
                  filter
                  small
                  id="igm_negative-sarscov2-form-input"
               >
                  Negativo
               </v-chip>
               <v-chip
                  :color="sarscov2.igm === 'unrealized' ? 'primary' : 'normal'"
                  value="unrealized"
                  filter
                  small
                  id="igm_unrealized-sarscov2-form-input"
               >
                  Não realizou
               </v-chip>
            </v-chip-group>
         </v-col>

         <v-col cols="12" sm="4">
            <p class="caption mb-1">IGG</p>

            <v-chip-group v-model="sarscov2.igg" class="my-n2" column>
               <v-chip
                  :color="sarscov2.igg === 'positive' ? 'primary' : 'normal'"
                  value="positive"
                  filter
                  small
                  id="igg_positive-sarscov2-form-input"
               >
                  Positivo
               </v-chip>
               <v-chip
                  :color="sarscov2.igg === 'negative' ? 'primary' : 'normal'"
                  value="negative"
                  filter
                  small
                  id="igg_negative-sarscov2-form-input"
               >
                  Negativo
               </v-chip>
               <v-chip
                  :color="sarscov2.igg === 'unrealized' ? 'primary' : 'normal'"
                  value="unrealized"
                  filter
                  small
                  id="igg_unrealized-sarscov2-form-input"
               >
                  Não realizou
               </v-chip>
            </v-chip-group>
         </v-col>
      </v-row>

      <v-text-field
         v-model="sarscov2.symptoms"
         class="mt-8"
         label="Sintomas"
         id="symptoms-sarscov2-form-input"
      ></v-text-field>

      <v-row class="mt-2">
         <v-col cols="12">
            <p class="caption mb-1">RT PCR SARS CoV-2</p>

            <v-chip-group v-model="sarscov2.rt_pcr.result" class="my-n2" column>
               <v-chip
                  :color="
                     sarscov2.rt_pcr.result === 'detectable'
                        ? 'primary'
                        : 'normal'
                  "
                  value="detectable"
                  filter
                  small
                  id="rt_pcr_detectable-sarscov2-form-input"
               >
                  Detectável
               </v-chip>
               <v-chip
                  :color="
                     sarscov2.rt_pcr.result === 'undetectable'
                        ? 'primary'
                        : 'normal'
                  "
                  value="undetectable"
                  filter
                  small
                  id="rt_pcr_undetectable-sarscov2-form-input"
               >
                  Não detectável
               </v-chip>
               <v-chip
                  :color="
                     sarscov2.rt_pcr.result === 'waiting_result'
                        ? 'primary'
                        : 'normal'
                  "
                  value="waiting_result"
                  filter
                  small
                  id="rt_pcr_waiting_result-sarscov2-form-input"
               >
                  Aguarda resultado
               </v-chip>
            </v-chip-group>
         </v-col>
      </v-row>

      <v-row class="mt-n6">
         <v-col cols="12" md="4">
            <v-text-field
               v-maska="'##/##/####'"
               v-model="sarscov2.rt_pcr.date"
               :rules="[(value) => dateRules(value) || 'Data inválida.']"
               class="mt-8"
               label="Data da coleta"
               id="rt_pcr_date-sarscov2-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mb-0">
         <v-col cols="12" md="4">
            <v-switch
               v-model="sarscov2.corticosteroid.in_use"
               label="Em uso de corticostróide"
               id="corticosteroid-sarscov2-form-input"
            ></v-switch>

            <v-row v-if="sarscov2.corticosteroid.in_use" class="mt-n1">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="sarscov2.corticosteroid.dose"
                     label="Dose"
                     id="corticosteroid_in_use-sarscov2-form-input"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="sarscov2.corticosteroid.start_date"
                     :rules="[(value) => dateRules(value) || 'Data inválida.']"
                     v-maska="'##/##/####'"
                     label="Data de início"
                     id="corticosteroid_start_date-sarscov2-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-col>

         <v-col cols="12" md="4">
            <v-switch
               v-model="sarscov2.hydroxychloroquine.in_use"
               label="Em uso de hidroxicloroquina"
               id="hydroxychloroquine-sarscov2-form-input"
            ></v-switch>

            <v-row v-if="sarscov2.hydroxychloroquine.in_use" class="mt-n1">
               <v-col cols="12">
                  <v-text-field
                     v-model="sarscov2.hydroxychloroquine.start_date"
                     :rules="[(value) => dateRules(value) || 'Data inválida.']"
                     v-maska="'##/##/####'"
                     label="Data de início"
                     id="hydroxychloroquine_start_date-sarscov2-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-col>

         <v-col cols="12" md="4">
            <v-switch
               v-model="sarscov2.oseltamivir.in_use"
               label="Em uso de oseltamivir"
               id="oseltamivir-sarscov2-form-input"
            ></v-switch>

            <v-row v-if="sarscov2.oseltamivir.in_use" class="mt-n1">
               <v-col cols="12">
                  <v-text-field
                     v-model="sarscov2.oseltamivir.start_date"
                     :rules="[(value) => dateRules(value) || 'Data inválida.']"
                     v-maska="'##/##/####'"
                     label="Data de início"
                     id="oseltamivir_start_date-sarscov2-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-col>

         <v-col cols="12" md="4">
            <v-switch
               v-model="sarscov2.heparin.in_use"
               label="Em uso de heparina"
               id="heparin-sarscov2-form-input"
            ></v-switch>

            <v-row v-if="sarscov2.heparin.in_use" class="mt-n1">
               <v-col cols="12">
                  <v-text-field
                     v-model="sarscov2.heparin.start_date"
                     :rules="[(value) => dateRules(value) || 'Data inválida.']"
                     v-maska="'##/##/####'"
                     label="Data de início"
                     id="heparin_start_date-sarscov2-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import validationUtil from "../../../../utils/validationUtil";

export default {
   name: "Sarscov2Form",

   props: {
      sarscov2: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },

   methods: {
      ...validationUtil,

      dateRules(value) {
         if (value) return this.dateValidation(value);
         else return true;
      },
   },
};
</script>

<style>
</style>