import { render, staticRenderFns } from "./History.vue?vue&type=template&id=0585b278&scoped=true&"
import script from "./History.vue?vue&type=script&lang=js&"
export * from "./History.vue?vue&type=script&lang=js&"
import style0 from "./History.vue?vue&type=style&index=0&id=0585b278&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0585b278",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VIcon,VTimeline,VTimelineItem})
