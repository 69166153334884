<template>
   <div>
      <v-dialog
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               @onSelectMunicipality="onSelectMunicipality"
               class="standard-padding"
               alertMessage="Selecione o município que deseja visualizar seus respectivos hospitais."
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <v-expand-transition>
         <v-alert
            v-if="municipalitySelected.id"
            class="mt-12 mb-n4"
            color="primary"
            type="info"
            icon="mdi-map"
            border="left"
            text
         >
            <div class="d-flex justify-space-between align-center">
               <p>
                  Você está visualizando a secretaria de saúde de
                  <strong>{{ municipalitySelected.name }}</strong
                  >.
               </p>

               <v-btn
                  @click="openSelectMunicipality"
                  class="d-none d-md-flex"
                  color="primary"
                  small
                  text
                  >Alterar município
               </v-btn>

               <v-btn
                  @click="openSelectMunicipality"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  text
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </div>
         </v-alert>
      </v-expand-transition>

      <v-card class="mt-12">
         <HealthSecretarieFormSkeleton
            v-if="!healthSecretarieSelected.id"
            class="standard-padding"
         />

         <HealthSecretarieForm
            v-else
            @onCreatedHealthSecretarie="
               getAllHealthSecretaries(municipalitySelected.id)
            "
            @onUpdatedHealthSecretarie="
               getAllHealthSecretaries(municipalitySelected.id)
            "
            :healthSecretarie="healthSecretarieSelected"
            :municipality="municipalitySelected"
            class="standard-padding"
            hideRemoveButton
         />
      </v-card>
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import HealthSecretarieForm from "../../components/healthSecretarie/HealthSecretarieForm";
import HealthSecretarieFormSkeleton from "components/healthSecretarie/HealthSecretarieFormSkeleton.vue";
import SelectMunicipality from "../../components/municipality/selectMunicipality/SelectMunicipality";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import healthSecretarieService from "../../services/healthSecretarieService";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "HealthSecretarie",

   components: {
      PageTitle,
      HealthSecretarieForm,
      SelectMunicipality,
      HealthSecretarieFormSkeleton,
   },

   data: function () {
      return {
         healthSecretarieSelected: {},
         municipalitySelected: {},

         dialogSelectMunicipalityVisibility: true,
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      onSelectMunicipality(municipality) {
         if (municipality && municipality.id !== this.municipalitySelected.id) {
            this.municipalitySelected = municipality;
            this.getAllHealthSecretaries(municipality.id);
         }
         this.closeSelectMunicipality();
      },

      refreshHealthSecretarieList() {
         this.$refs.healthSecretarielist.refreshAllHealthSecretariesByRef();
      },

      async getAllHealthSecretaries(municipalityId, query) {
         try {
            const response =
               await healthSecretarieService.getAllHealthSecretaries(
                  municipalityId,
                  query
               );

            if (response.data && response.data.length)
               this.healthSecretarieSelected = Object.assign(
                  {},
                  response.data[0]
               );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
</style>