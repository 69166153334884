<template>
   <div>
      <FormTitle icon="mdi-box-cutter" title="RESERVAR CIRURGIA" />

      <SurgeryTypeReserveList
         v-if="request.doctor_opinion && request.doctor_opinion.sus_code"
         @onReserveSurgery="onReserveSurgery"
         :susCode="request.doctor_opinion.sus_code"
      />

      <div v-else class="standard-padding-small">
         <NoData
            :message="`Desculpe, essa solicitação não tem nenhuma cirurgia. Adicione uma cirurgia no parecer médico para conseguir reserva-lá.`"
         />
      </div>
   </div>
</template>

<script>
import SurgeryTypeReserveList from "./SurgeryTypeReserveList.vue";
import FormTitle from "components/base/form/FormTitle";
import NoData from "components/base/NoData.vue";

export default {
   name: "SurgeryTypeReserveForm",

   components: { FormTitle, SurgeryTypeReserveList, NoData },

   props: {
      request: {
         type: Object,
         default: () => ({
            doctor_opinion: {},
         }),
      },
   },

   methods: {
      onReserveSurgery(surgery) {
         this.$emit("onReserveSurgery", surgery);
      },
   },
};
</script>

<style scoped>
</style>