<template>
   <div>
      <div>
         <v-checkbox
            v-model="uti.accute_breathing_insufficiency"
            class="mr-3"
            label="Insuficiência respiratória aguda com necessidade de ventilação mecânica"
            id="accute_breathing_insufficiency-uti-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="uti.sepsis_septic_shock"
            class="mr-3"
            label="Sepse/Choque séptico e infecção pulmonar pelo COVID-19"
            id="sepsis_septic_shock-uti-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="uti.hemodynamic_instability"
            class="mr-3"
            label="Instabilidade hemodinâmica ou hipertensão arterial ou sinais de má perfusão orgânica"
            id="hemodynamic_instability-uti-form-input"
         ></v-checkbox>
         <v-checkbox
            v-model="uti.organic_dysfunction"
            class="mr-3"
            label="Disfunção orgânica, além do pulmonar"
            id="organic_dysfunction-uti-form-input"
         ></v-checkbox>
      </div>
   </div>
</template>

<script>
export default {
   name: "UtiForm",

   props: {
      uti: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },
};
</script>

<style>
</style>