var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","height":"64px","app":""}},[_c('div',{staticClass:"d-flex justify-space-between flex-grow-1"},[_c('div',[(_vm.appBarMenuVisibilityStore)?_c('v-btn',{attrs:{"id":"menu-button","icon":"","dark":""},on:{"click":_vm.setNavigationDrawerVisibilityStore}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[(
               ['regulator_manager', 'regulator'].includes(
                  _vm.userStore.sub_type
               )
            )?_c('NotificationCenter'):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"logout-button","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-list',[(_vm.currentHealthUnit)?_c('div',[_c('div',{staticClass:"d-flex flex-column align-center px-4 py-4"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-hospital-building")]),_c('p',{staticClass:"healthunit-name font-weight-medium text-center my-2"},[_vm._v(" "+_vm._s(_vm.currentHealthUnit.name)+" ")]),_c('v-btn',{staticClass:"text--secondary",attrs:{"rounded":"","x-small":"","depressed":""},on:{"click":_vm.changeHealthUnit}},[_vm._v("Alterar")])],1),_c('v-divider')],1):_vm._e(),_c('v-list-item',{attrs:{"id":"my-account-app-bar-button","color":"primary","to":"/minha-conta"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cogs")]),_c('v-list-item-title',{staticClass:"body-2"},[_vm._v("Minha conta")])],1),_c('v-list-item',{attrs:{"color":"primary"},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-exit-to-app")]),_c('v-list-item-title',{staticClass:"body-2"},[_vm._v("Sair")])],1)],1)],1)],1)]),_c('div',{staticClass:"div-logo"},[_c('v-icon',{staticClass:"shadow-svg",attrs:{"size":"150"}},[_vm._v(" $hospital-bed ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }