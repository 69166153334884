<template>
   <div>
      <p class="body-1 font-weight-medium mt-5">
         Justificativa de cancelamento
      </p>

      <v-form ref="form">
         <v-row class="mt-2">
            <v-col cols="12">
               <v-textarea
                  v-model="formJustification.status_justification"
                  :rules="[(value) => !!value || 'Justificativa obrigatória.']"
                  class="justification-height"
                  label="Justificativa*"
                  id="status_justification-bed-request-reserve-cancel-form-input"
                  rows="8"
                  no-resize
               ></v-textarea>
            </v-col>
         </v-row>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickCancelReservation"
         :loadingSave="loadingSaveJustification"
         :mode="'save'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormActions from "../../../components/base/form/FormActions";
import requestService from "../../../services/requestService";
import snackBarUtil from "../../../utils/snackBarUtil";
import util from "../../../utils/util";

export default {
   name: "BedReserveCancelForm",

   components: { FormActions },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         formJustification: {
            status: "rejected",
         },

         loadingSaveJustification: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler() {
            this.formJustification = {
               status: "rejected",
            };

            this.resetValidation();
         },
      },
   },

   methods: {
      formatObjectToSend(justification) {
         let justificationTemp = util.removeBlankAttributes(justification);

         return justificationTemp;
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickCancelReservation() {
         if (
            this.request &&
            this.request.id &&
            this.request.source_health_entity &&
            this.request.source_health_entity.id
         )
            this.updateHealthUnitRequestStatus(
               this.request.source_health_entity.id,
               this.request.id,
               this.formJustification
            );
      },

      async updateHealthUnitRequestStatus(healthUnitId, requestId, status) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !requestId ||
            !status
         )
            return;

         this.loadingSaveJustification = true;

         try {
            await requestService.updateHealthUnitRequestStatus(
               healthUnitId,
               requestId,
               this.formatObjectToSend(status)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A reserva foi cancelada com sucesso!",
            });

            this.$emit("onCancelReservation");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveJustification = false;
         }
      },
   },
};
</script>

<style scoped>
.justification-height {
   height: 100%;
}
</style>