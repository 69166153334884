<template>
   <v-tooltip open-delay="300" transition="slide-y-transition" top>
      <template v-slot:activator="{ on, attrs }">
         <div v-bind="attrs" v-on="on" class="ma-1">
            <v-badge
               :value="badgeVisible"
               icon="mdi-exclamation"
               color="error"
               bordered
               overlap
            >
               <v-card
                  @click="viewBed(bed)"
                  :color="`${configByStatus.color}Light`"
                  :id="`bed-${bed.id}-list-button`"
                  class="d-flex flex-column justify-center align-center overflow-hidden"
                  height="65px"
                  width="65px"
                  flat
               >
                  <v-icon :color="configByStatus.color" size="38">{{
                     configByStatus.icon
                  }}</v-icon>

                  <p
                     class="bed-description"
                     :class="`${configByStatus.color}--text`"
                  >
                     {{ bed.code }}
                  </p>
               </v-card>
            </v-badge>
         </div>
      </template>

      <span>
         <BedTooltipDetails :bed="bed" />
      </span>
   </v-tooltip>
</template>

<script>
import requestService from "services/requestService";
import BedTooltipDetails from "./BedTooltipDetails";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "BedListWardBedCard",

   components: { BedTooltipDetails },

   props: {
      bed: {
         type: Object,
      },
   },

   data: function () {
      return {
         badgeVisible: false,
      };
   },

   computed: {
      configByStatus() {
         let color = "black";
         let icon = "mdi-alert";

         if (this.bed && this.bed.status) {
            switch (this.bed.status) {
               // case "waiting_reservation":
               case "free":
                  color = "success";
                  icon = "mdi-bed-empty";
                  break;
               case "blocked":
                  color = "error";
                  icon = "mdi-close";
                  break;
               case "occupied":
                  color = "warning";
                  icon = "mdi-bed";
                  break;
               case "reserved":
                  color = "purple";
                  icon = "mdi-exclamation";
                  break;
            }
         }

         return { color, icon };
      },
   },

   methods: {
      viewBed(bed) {
         this.$emit("onClickViewBed", bed);
      },

      async getOpenAndRegulatedRequests() {
         try {
            const response = await requestService.getAllRequests({
               filter: [
                  { attribute: "bed_id", query: this.bed.id },
                  { attribute: "status", query: "regulated" },
                  {
                     attribute: "is_closed",
                     query: "false",
                  },
               ],
            });

            this.badgeVisible = response.data.length;
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },

   created() {
      if (this.bed.id && this.bed.status === "reserved") {
         /* Verifica se o leito tem uma solicitação que não está fechada, e apresenta o status de regulada.
         Se tiver, adiciona o badge de notificação no leito. */
         this.getOpenAndRegulatedRequests();
      }
   },
};
</script>

<style scoped>
.bed-description {
   width: 50px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
   font-size: 0.7rem;
   margin-bottom: 5px !important;
}
</style>