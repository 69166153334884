var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"logout-button","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.unreadNotifications,"content":_vm.unreadNotifications,"color":"warning","overlap":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{staticClass:"list-notification-center",attrs:{"three-line":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center px-4 mt-2 mb-3"},[_c('p',{staticClass:"body-1"},[_vm._v(" Central de notificações "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 pa-1",attrs:{"color":"primary","x-small":""}},'v-chip',attrs,false),on),[_vm._v(" Novo ")])]}}])},[_c('p',{staticClass:"text-center"},[_vm._v(" Exibe notificações para "),_c('br'),_vm._v(" novas solicitações e "),_c('br'),_vm._v(" novas mensagens ")])])],1),(_vm.notificationStore.length)?_c('a',{staticClass:"caption href-style",on:{"click":_vm.clearAllNotifications}},[_vm._v(" Limpar todas ")]):_vm._e()]),_c('v-divider'),(!_vm.notificationStore.length)?_c('NoData',{staticClass:"pa-7",attrs:{"message":"Nenhuma notificação por aqui","icon":"mdi-message-processing"}}):_c('div',[_vm._l((_vm.notificationStore),function(notification,index){return [_c('v-list-item',{key:index},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center ma-n1"},[_c('v-icon',{staticClass:"ma-1",attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.notificationContent(notification).icon)+" ")]),_c('p',{staticClass:"body-2 ma-1"},[_vm._v(" "+_vm._s(_vm.notificationContent(notification).title)+" ")]),(notification.unread)?_c('v-chip',{staticClass:"ma-1 px-1",attrs:{"color":"primary","x-small":""}},[_vm._v(" Recente ")]):_vm._e()],1)]),_c('p',{staticClass:"body-2 my-1 text--secondary",domProps:{"innerHTML":_vm._s(
                           _vm.notificationContent(notification).description
                        )}}),_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center ma-n2"},[_c('a',{staticClass:"caption href-style ma-2",on:{"click":function($event){return notification.action()}}},[_vm._v(" Visualizar ")]),(notification.unread)?_c('a',{staticClass:"caption href-style ma-2",on:{"click":function($event){return _vm.markAsRead(index)}}},[_vm._v(" Marcar como lida ")]):_vm._e()]),_c('p',{staticClass:"caption mt-1 grey--text"},[_vm._v(" "+_vm._s(_vm.relativeDate(notification.date))+" ")])])])],1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }