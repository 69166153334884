<template>
   <apexchart
      :options="options"
      :series="series"
      height="320"
      class="ml-n4 mb-n6"
      type="bar"
   ></apexchart>
</template>

<script>
export default {
   name: "AttentionLevelChart",

   props: {
      statistics: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         options: {
            colors: ["#56C15C", "#FAFF00", "#f48024", "#F4443E", "#262626"],

            chart: {
               id: "attention-level-chart",
               toolbar: {
                  show: false,
               },
            },

            xaxis: {
               categories: ["Verde", "Amarelo", "Laranja", "Vermelho", "Preto"],
               labels: {
                  style: {
                     cssClass: "caption",
                  },
                  rotate: 0,
               },
            },

            yaxis: {
               labels: {
                  style: {
                     cssClass: "caption",
                  },
               },
               max: function (max) {
                  return Math.round(max * 1.2) || 1;
               },
            },

            plotOptions: {
               bar: {
                  dataLabels: {
                     position: "top",
                  },
                  borderRadius: 5,
                  columnWidth: "35px",
                  horizontal: true,
                  distributed: true,
               },
            },

            dataLabels: {
               offsetX: 30,
               formatter: function (val, attrs) {
                  const total = parseInt(
                     attrs.w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                     }, 0)
                  );

                  if (total > 0) return ((val / total) * 100).toFixed(0) + "%";
                  else return 0;
               },
               style: {
                  fontSize: "11px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  colors: ["#3b3b3b"],
               },
            },

            tooltip: {
               theme: "dark",
               x: {
                  show: false,
               },
               marker: {
                  show: false,
               },
               style: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
               },
            },

            legend: {
               show: false,
            },
         },
      };
   },

   computed: {
      series() {
         let result = [
            {
               name: "Paciente(s)",
               data: [0, 0, 0, 0, 0],
            },
         ];

         if (this.statistics.length > 0)
            result[0].data = this.statistics.map((item) => item.total);

         return result;
      },
   },
};
</script>

<style>
</style>