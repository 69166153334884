<template>
   <div>
      <v-dialog
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               @onSelectMunicipality="onSelectMunicipality"
               ref="selectMunicipality"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-alert
         v-if="mode === 'save' && !dense"
         class="caption mt-2 mb-4"
         color="primary"
         type="info"
         icon="mdi-map"
         border="left"
         text
         dense
      >
         <div class="d-flex justify-space-between align-center">
            <p v-if="!municipalitySelected">
               Selecione um município para visualizar suas respectivas
               secretarias de saúde.
            </p>

            <p v-else>
               Você está visualizando as secretarias de saúde de
               <span class="body-2"
                  ><strong>{{ municipalitySelected.name }}</strong></span
               >.
            </p>

            <div class="ml-2">
               <v-btn
                  @click="openSelectMunicipality"
                  id="change-municipality-primary-button"
                  class="d-none d-md-flex"
                  color="primary"
                  x-small
                  text
                  >{{
                     !municipalitySelected
                        ? "Selecionar munípicio"
                        : "Alterar município"
                  }}
               </v-btn>

               <v-btn
                  @click="openSelectMunicipality"
                  id="change-municipality-secondary-button"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  text
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </div>
         </div>
      </v-alert>

      <v-row :class="dense ? '' : 'mb-2'" :no-gutters="dense" class="mt-0">
         <v-col cols="12">
            <v-autocomplete
               v-model="healthSecretarieSelected"
               @focus="!municipalitySelected ? openSelectMunicipality() : null"
               @change="selectHealthSecretarie"
               :items="healthSecretariesItems"
               :search-input.sync="healthSecretarieSearchFilter"
               :readonly="!municipalitySelected"
               :loading="loadingHealthSecretaries"
               :placeholder="
                  !municipalitySelected
                     ? 'Escolha um município para visualizar suas secretarias de saúde.'
                     : 'Digite o nome do hospital para buscar...'
               "
               :menu-props="{ top: true }"
               :rules="
                  required
                     ? dialogSelectMunicipalityVisibility
                        ? []
                        : [
                             () =>
                                !!healthSecretarieSelected ||
                                'Secretaria de saúde obrigatória.',
                          ]
                     : []
               "
               :disabled="loadingHealthSecretaries"
               :label="`Secretaria de saúde${required ? '*' : ''}`"
               id="select-healthSecretarie-input"
               item-text="name"
               item-value="id"
               return-object
            >
               <template v-slot:no-data>
                  <div class="px-5 py-2">
                     Desculpe, nenhum hospital encontrado.
                  </div>
               </template>

               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content>
                        <v-list-item-title
                           :id="`select-healthSecretarie-item-${
                              attrs.id.split('-')[3]
                           }`"
                        >
                           {{ item.name }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>

               <template v-if="dense" v-slot:prepend>
                  <v-chip
                     @click="openSelectMunicipality"
                     color="primary"
                     class="ma-0 mr-1 select-municipality"
                     small
                  >
                     <p v-if="!municipalitySelected">
                        Selecione o <br />
                        município
                     </p>
                     <p v-else>
                        Município <br />
                        <strong>
                           {{ municipalitySelected.name }}
                        </strong>
                     </p>
                  </v-chip>
               </template>
            </v-autocomplete>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import SelectMunicipality from "../../municipality/selectMunicipality/SelectMunicipality";
import healthSecretarieService from "../../../services/healthSecretarieService";
import responsiveUtil from "../../../utils/responsiveUtil";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "HealthSecretarieInput",

   components: { SelectMunicipality },

   props: {
      healthSecretarieId: {
         type: String,
      },

      mode: {
         type: String,
         default: "save",
      },

      dense: {
         type: Boolean,
         default: false,
      },

      required: {
         type: Boolean,
         default: true,
      },

      itemDisable: {
         type: Object,
      },
   },

   data: function () {
      return {
         healthSecretarieSelected: null,

         loadingHealthSecretaries: false,

         municipalitySelected: null,
         dialogSelectMunicipalityVisibility: false,
         healthSecretarieSearchFilter: "",

         healthSecretariesItems: [],

         municipalityTimeout: null,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 20,
            filter: [],
         };

         if (
            this.healthSecretarieSearchFilter &&
            this.healthSecretarieSearchFilter.replace(/ /g, "") !== ""
         )
            query.filter.push({
               attribute: "name",
               query: this.healthSecretarieSearchFilter,
            });

         return query;
      },
   },

   watch: {
      healthSecretarieId: {
         immediate: true,
         handler(healthSecretarieId) {
            this.reset();
            if (healthSecretarieId) {
               this.getHealthSecretarieById(healthSecretarieId);
               this.healthSecretarieSelected = healthSecretarieId;
            }
         },
      },

      healthSecretarieSearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            this.healthSecretariesItems.find((item) => item.name === newFilter)
         )
            return;

         clearTimeout(this.municipalityTimeout);
         this.municipalityTimeout = setTimeout(() => {
            this.getAllHealthSecretaries(
               this.municipalitySelected.id,
               this.query
            );
         }, 800);
      },
   },

   methods: {
      ...responsiveUtil,

      reset() {
         this.municipalitySelected = null;
         this.healthSecretarieSelected = null;
         if(this.$refs.selectMunicipality) this.$refs.selectMunicipality.reset();
      },

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      selectHealthSecretarie(healthSecretarie) {
         this.$emit("onSelectHealthSecretarie", healthSecretarie);
      },

      onSelectMunicipality(municipality) {
         if (municipality) {
            this.municipalitySelected = municipality;
            this.healthSecretarieSelected = null;
            this.healthSecretarieSearchFilter = "";
            this.healthSecretariesItems = [];
            this.getAllHealthSecretaries(municipality.id, this.query);
            this.$emit("onSelectMunicipality", municipality);
         }
         this.closeSelectMunicipality();
      },

      async getHealthSecretarieById(healthSecretarieId) {
         this.loadingHealthSecretaries = true;
         this.healthSecretariesItems = [];

         try {
            const response =
               await healthSecretarieService.getHealthSecretarieById(
                  healthSecretarieId
               );

            if (response.data)
               this.healthSecretariesItems.push(
                  Object.assign({}, response.data)
               );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthSecretaries = false;
         }
      },

      async getAllHealthSecretaries(municipalityId, query) {
         this.loadingHealthSecretaries = true;

         try {
            const response =
               await healthSecretarieService.getAllHealthSecretaries(
                  municipalityId,
                  query
               );

            this.healthSecretariesItems = this.healthSecretariesItems.concat(
               Object.assign([], response.data)
            );

            if (this.itemDisable && this.itemDisable.id) {
               const itemDisableIndex = this.healthSecretariesItems.findIndex(
                  (item) => item.id === this.itemDisable.id
               );

               if (itemDisableIndex !== -1)
                  this.healthSecretariesItems[itemDisableIndex].disabled = true;
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthSecretaries = false;
         }
      },
   },
};
</script>

<style scoped>
.select-municipality {
   font-size: 0.55rem !important;
   line-height: 10px;
   text-align: center;
}
</style>