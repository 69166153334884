var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"buttons-config align-self-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","height":"28","width":"28","id":"reload-chat-button","fab":"","depressed":""},on:{"click":function($event){return _vm.resetMessages(_vm.request.id, _vm.query, true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cached")])],1),(_vm.isRegulator)?_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.loadingPrint,"color":"primary","height":"28","width":"28","id":"print-chat-button","fab":"","depressed":""},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-printer")])],1):_vm._e()],1),_c('div',{staticClass:"tabs-config"},[(_vm.isRegulator)?_c('v-tabs',{attrs:{"centered":"","show-arrows":""},on:{"change":_vm.changeTabs}},[(!_vm.request.was_created_by_patient)?_c('v-tab',[_vm._v("Solicitante")]):_c('v-tab',[_vm._v("Paciente")]),_c('v-tab',[_vm._v("Executante")]),_c('v-tab',[_vm._v("Sistema")])],1):_c('v-tabs',{attrs:{"centered":"","show-arrows":""},on:{"change":_vm.changeTabs}},[_c('v-tab',[_vm._v("Regulador")]),_c('v-tab',[_vm._v("Sistema")])],1)],1),(_vm.loadingMessagesFully)?_c('LoadingData',{staticClass:"my-auto"}):_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"messages-content d-flex flex-column flex-grow-1",attrs:{"id":"messages-content-div"},on:{"scroll":_vm.scrollMessageContent}},[(_vm.loadingMessagesPartially)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"color":"primary","width":"2","size":"22","indeterminate":""}})],1):_vm._e(),(_vm.messages && _vm.messages.length)?_c('div',_vm._l((_vm.messages),function(message,index){return _c('div',{key:message.id,staticClass:"d-flex flex-column align-start mb-3",class:_vm.messageOrigin(message.user_id) === 'local'
                     ? 'align-end'
                     : null,attrs:{"id":("message-" + index),"outlined":""}},[(_vm.messageOrigin(message.user_id) === 'local')?_c('div',{staticClass:"d-flex justify-end align-center flex-wrap"},[_c('p',{staticClass:"caption text--secondary"},[_vm._v(" "+_vm._s(_vm.formatDate(message.created_at, "DD MMM[,] HH:mm"))+" • "+_vm._s(_vm.getResponsibleMessage(message))+" ")])]):_c('div',{staticClass:"d-flex align-center flex-wrap ma-n1"},[_c('p',{staticClass:"caption text--secondary ma-1"},[_vm._v(" "+_vm._s(_vm.getResponsibleMessage(message))+" • "+_vm._s(_vm.formatDate(message.created_at, "DD MMM[,] HH:mm"))+" ")]),(!message.is_read)?_c('v-chip',{staticClass:"px-1 my-n1 ma-1",attrs:{"color":"primary","x-small":""}},[_vm._v(" Recente ")]):_vm._e()],1),_c('v-card',{staticClass:"message-box",attrs:{"color":_vm.messageOrigin(message.user_id) === 'local'
                        ? 'primary'
                        : 'grey lighten-2',"dark":_vm.messageOrigin(message.user_id) === 'local'
                        ? true
                        : false,"outlined":""}},[_c('p',{staticClass:"body-2"},[_vm._v(_vm._s(message.text))])])],1)}),0):_c('NoData',{staticClass:"my-auto",attrs:{"icon":"mdi-forum","message":"Nenhum histórico de mensagens."}})],1),(_vm.activeChat !== 'system')?_c('div',{staticClass:"pb-3"},[_c('v-text-field',{attrs:{"disabled":_vm.disable,"placeholder":_vm.disable
                  ? 'Essa solicitação foi terminada.'
                  : 'Digite sua mensagem aqui...',"append-icon":"mdi-send","id":"message-chat-input","autofocus":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loadingSendMessage)?_c('div',{staticClass:"d-flex flex-column justify-center mr-1",staticStyle:{"height":"25px"}},[_c('v-progress-circular',{attrs:{"color":"primary","width":"2","size":"18","indeterminate":""}})],1):_vm._e(),(!_vm.loadingSendMessage)?_c('div',{staticClass:"d-flex flex-column justify-center mr-1",staticStyle:{"height":"25px"}},[_c('v-icon',{attrs:{"id":"send-chat-icon"},on:{"click":_vm.sendMessage}},[_vm._v("mdi-send")])],1):_vm._e()]},proxy:true}],null,false,761838393),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }