import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllMunicipalities(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/municipalities?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.attribute && query.filter.query) {
      url += `&${query.filter.attribute}=`;

      switch (query.filter.attribute) {
         case "macro_region":
            var macroRegions = [
               { text: "1º", value: "first" },
               { text: "2º", value: "second" },
               { text: "3º", value: "third" }
            ];

            var macroRegionItem = macroRegions.find(item =>
               item.text
                  .toUpperCase()
                  .includes(query.filter.query.toUpperCase())
            );

            if (!macroRegionItem) macroRegionItem = { value: "null" };

            url += `${macroRegionItem.value}`;
            break;

         case "health_region":
            var healthRegions = [
               { text: "1º", value: "first" },
               { text: "2º", value: "second" },
               { text: "3º", value: "third" },
               { text: "4º", value: "fourth" },
               { text: "5º", value: "fifth" },
               { text: "6º", value: "sixth" },
               { text: "7º", value: "seventh" },
               { text: "8º", value: "eighth" },
               { text: "9º", value: "ninth" },
               { text: "10º", value: "tenth" },
               { text: "11º", value: "eleventh" },
               { text: "12º", value: "twelfth" },
               { text: "13º", value: "thirteenth" },
               { text: "14º", value: "fourteenth" },
               { text: "15º", value: "fifteenth" },
               { text: "16º", value: "sixteenth" }
            ];

            var healthRegionItem = healthRegions.find(item =>
               item.text
                  .toUpperCase()
                  .includes(query.filter.query.toUpperCase())
            );

            if (!healthRegionItem) healthRegionItem = { value: "null" };

            url += `${healthRegionItem.value}`;
            break;

         /* TODO Caso específico do componente MapParaiba. Isso futuramente deverá ser refatorado. */
         case "specificName":
            var urlTemp = url.substring(0, url.lastIndexOf("&"));
            urlTemp += `&name=${query.filter.query}`;
            url = urlTemp;
            break;

         default:
            url += `*${query.filter.query}*`;
            break;
      }
   }

   return await axiosService.get(url);
}

async function getMunicipalityById(municipalityId) {
   return await axiosService.get(`/municipalities/${municipalityId}`);
}

async function updateMunicipality(municipalityId, municipality) {
   return await axiosService.patch(
      `/municipalities/${municipalityId}`,
      municipality
   );
}

export default {
   getAllMunicipalities,
   getMunicipalityById,
   updateMunicipality
};
