<template>
   <div>
      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <StatisticContent />
   </div>
</template>

<script>
import StatisticContent from "components/statistic/StatisticContent";
import PageTitle from "../../components/base/PageTitle";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "Statistic",

   components: { PageTitle, StatisticContent },

   data: function () {
      return {};
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },
   },
};
</script>

<style scoped>
</style>