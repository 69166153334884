var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center div-wrap"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('v-select',{staticClass:"width-user-type",attrs:{"items":_vm.userTypes,"label":"Tipo de usuário*","id":"user-type-form-input"},on:{"change":_vm.onChangeUserType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',{attrs:{"id":("item-" + (attrs.id.split('-')[3]))}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}]),model:{value:(_vm.userTypeSelected),callback:function ($$v) {_vm.userTypeSelected=$$v},expression:"userTypeSelected"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.userItems,"search-input":_vm.userSearchFilter,"loading":_vm.loadingUsers,"item-text":"name","item-value":"id","label":"Usuário*","return-object":""},on:{"change":_vm.selectUser,"update:searchInput":function($event){_vm.userSearchFilter=$event},"update:search-input":function($event){_vm.userSearchFilter=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-5 py-2"},[_vm._v(" Desculpe, nenhum usuário encontrado. ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"id":("select-healthunit-item-" + (attrs.id.split('-')[3]))}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}]),model:{value:(_vm.userSelected),callback:function ($$v) {_vm.userSelected=$$v},expression:"userSelected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }