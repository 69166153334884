import axiosService from "./axiosService";

async function getAllHealthUnitSurgeryTypes(healthUnitId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/healthunits/${healthUnitId}/surgerytypes?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function createHealthUnitSurgeryType(healthUnitId, surgeryType) {
   return await axiosService.post(
      `/healthunits/${healthUnitId}/surgerytypes`,
      surgeryType
   );
}

async function updateHealthUnitSurgeryType(
   healthUnitId,
   surgeryTypeId,
   surgeryType
) {
   return await axiosService.patch(
      `/healthunits/${healthUnitId}/surgerytypes/${surgeryTypeId}`,
      surgeryType
   );
}

async function getAllSurgeryTypes(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/surgerytypes?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

export default {
   getAllHealthUnitSurgeryTypes,
   createHealthUnitSurgeryType,
   updateHealthUnitSurgeryType,
   getAllSurgeryTypes
};
