<template>
   <div class="form-actions-position">
      <div v-if="mode === 'read'" class="d-flex justify-end align-center ma-n1">
         <div v-if="!hideChangeTypeButton">
            <v-btn
               @click="changeType"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1"
               color="primary"
               id="change-type-primary-form-button"
               depressed
               >Alterar perfil
               <v-icon right small>mdi-swap-horizontal</v-icon>
            </v-btn>
            <v-btn
               @click="changeType"
               class="shadow-floating-primary-button d-flex d-sm-none ma-1"
               color="primary"
               id="change-type-secondary-form-button"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-swap-horizontal</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideResetPasswordButton">
            <v-btn
               @click="reset"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1"
               color="primary"
               id="reset-primary-form-button"
               depressed
               >Redefinir senha
               <v-icon right small>mdi-lock-reset</v-icon>
            </v-btn>
            <v-btn
               @click="reset"
               class="shadow-floating-primary-button d-flex d-sm-none ma-1"
               color="primary"
               id="reset-secondary-form-button"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-lock-reset</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideCapabilitiesButton">
            <v-btn
               @click="change"
               class="shadow-floating-primary-button d-none d-sm-flex ma-1 "
               color="primary"
               id="change-primary-form-button"
               depressed
            >
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-chip
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        class="mr-1 pa-1"
                        x-small
                     >
                        Novo
                     </v-chip>
                  </template>

                  <p class="text-center">
                     Novo sistema de alterar<br />
                     o acesso do operador
                  </p>
               </v-tooltip>
               Alterar acesso
               <v-icon right small>mdi-folder-lock-open</v-icon>
            </v-btn>
            <div class="d-flex d-sm-none">
               <v-btn
                  @click="change"
                  class="shadow-floating-primary-button  ma-1"
                  color="primary"
                  id="change-secondary-form-button"
                  fab
                  depressed
                  x-small
               >
                  <v-icon class="pl-3" small>mdi-folder-lock-open</v-icon>
                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-chip
                           v-bind="attrs"
                           v-on="on"
                           color="yellow"
                           class="chip-upside"
                           x-small
                        >
                        </v-chip>
                     </template>
                     <p class="text-center">
                        Novo sistema de <br />
                        atualização automática <br />
                        das cirurgias
                     </p>
                  </v-tooltip>
               </v-btn>
            </div>
         </div>

         <div v-if="!hideEditButton">
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-none d-sm-flex ma-1"
               color="normal"
               id="edit-primary-form-button"
               depressed
               dark
               >Editar
               <v-icon right small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
               @click="edit"
               class="shadow-floating-black-button d-flex d-sm-none ma-1"
               color="normal"
               id="edit-secondary-form-button"
               fab
               dark
               depressed
               x-small
            >
               <v-icon small>mdi-pencil</v-icon>
            </v-btn>
         </div>

         <div v-if="!hideRemoveButton">
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-none d-sm-flex ma-1"
               color="error"
               id="remove-primary-form-button"
               depressed
               >Remover
               <v-icon right small>mdi-delete</v-icon>
            </v-btn>
            <v-btn
               @click="remove"
               class="shadow-floating-error-button d-flex d-sm-none ma-1"
               color="error"
               id="remove-secondary-form-button"
               fab
               depressed
               x-small
            >
               <v-icon small>mdi-delete</v-icon>
            </v-btn>
         </div>
      </div>

      <div v-else class="d-flex justify-end align-center ma-n1">
         <v-alert
            v-model="activeTooltip"
            class="caption alert-config ma-1"
            color="error"
            border="left"
            dense
            dark
         >
            <div class="d-none d-sm-flex">
               <p class="alert-text-config">
                  <v-icon small left>mdi-alert</v-icon>
                  <strong>Campos inválidos:</strong>
                  {{ errors.join(", ") }}
                  {{ validationErrors.length > 3 ? "..." : "." }}
               </p>
            </div>

            <div class="d-flex d-sm-none">
               <p class="alert-text-config">
                  <v-icon small left>mdi-alert</v-icon>
                  <strong>Campos inválidos!</strong>
               </p>
            </div>
         </v-alert>

         <v-btn
            @click="cancel"
            class="
               shadow-floating-primary-light-button
               d-none d-sm-flex
               primary--text
               ma-1
            "
            color="primaryLight"
            id="cancel-primary-form-button"
            depressed
            >Cancelar
         </v-btn>
         <v-btn
            @click="cancel"
            class="shadow-floating-primary-light-button d-flex d-sm-none ma-1"
            color="primaryLight"
            id="cancel-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small color="primary">mdi-close</v-icon>
         </v-btn>

         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-none d-sm-flex ma-1"
            color="primary"
            id="save-primary-form-button"
            depressed
            >Salvar
            <v-icon right small>mdi-check</v-icon>
         </v-btn>
         <v-btn
            @click="save"
            :loading="loadingSave"
            class="shadow-floating-primary-button d-flex d-sm-none ma-1"
            color="primary"
            id="save-secondary-form-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
export default {
   name: "UsersFormActions",

   props: {
      mode: {
         type: String,
         default: "save"
      },

      hideEditButton: {
         type: Boolean,
         default: false
      },

      hideRemoveButton: {
         type: Boolean,
         default: false
      },

      hideResetPasswordButton: {
         type: Boolean,
         default: false
      },

      hideCapabilitiesButton: {
         type: Boolean,
         default: true
      },

      hideChangeTypeButton: {
         type: Boolean,
         default: false
      },

      loadingSave: {
         type: Boolean,
         default: false
      },

      validationErrors: {
         type: Array,
         default: () => []
      }
   },

   data: function() {
      return {
         activeTooltip: false
      };
   },

   computed: {
      errors() {
         return this.validationErrors.filter((item, index) => index < 3);
      }
   },

   watch: {
      validationErrors: {
         immediate: true,
         handler(validationErrors) {
            if (validationErrors.length) this.openTooltipValitation();
            else this.closeTooltipValitation();
         }
      }
   },

   methods: {
      changeType() {
         this.$emit("onClickChangeType");
      },

      reset() {
         this.$emit("onClickReset");
      },

      change() {
         this.$emit("onClickChangeCapabilities");
      },

      edit() {
         this.$emit("onClickEdit");
      },

      remove() {
         this.$emit("onClickRemove");
      },

      cancel() {
         this.$emit("onClickCancel");
      },

      openTooltipValitation() {
         this.activeTooltip = true;
      },

      closeTooltipValitation() {
         this.activeTooltip = false;
      },

      save() {
         this.$emit("onClickSave");
      }
   }
};
</script>

<style scoped>
.form-actions-position {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

@media (max-width: 959px) {
   .form-actions-position {
      bottom: 16px;
   }
}

.alert-config {
   height: 36px;
   margin-bottom: 0px;
   margin-left: auto;
}

.alert-text-config {
   height: 20px !important;
   overflow: hidden;
   text-overflow: ellipsis;
}

.chip-upside {
   position: relative;
   bottom: 0.8rem;
   right: -0.5 rem;
   height: 10px;
   margin-right: 0px;
   padding: 6px;
}
</style>
