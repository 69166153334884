<template>
   <div>
      <v-alert
         class="body-2 mb-5"
         color="primary"
         type="info"
         icon="mdi-medical-bag"
         border="left"
         text
         dense
      >
         <strong>Clique</strong> na cirurgia da unidade de saúde que deseja
         reservar, para finalizar <strong>confirme</strong> a reserva.
      </v-alert>

      <v-row v-if="loadingSurgeryTypes">
         <v-col v-for="surgeryType in 5" :key="surgeryType" cols="12" md="4">
            <SurgeryTypeReserveListCardSkeleton />
         </v-col>
      </v-row>

      <v-row v-else-if="surgeryTypes.length">
         <v-col
            v-for="surgeryType in surgeryTypes"
            :key="surgeryType.id"
            cols="12"
            md="4"
         >
            <SurgeryTypeReserveListCard
               @onReserveSurgery="onReserveSurgery"
               :surgery="surgeryType"
            />
         </v-col>
      </v-row>

      <div v-else class="standard-padding-small">
         <NoData
            :message="`Desculpe, nenhuma cirurgia com código ${susCode} disponível foi encontrada.`"
         />
      </div>
   </div>
</template>

<script>
import SurgeryTypeReserveListCard from "components/surgeryType/surgeryTypeReserve/SurgeryTypeReserveListCard";
import SurgeryTypeReserveListCardSkeleton from "components/surgeryType/surgeryTypeReserve/SurgeryTypeReserveListCardSkeleton";
import NoData from "components/base/NoData.vue";
import snackBarUtil from "utils/snackBarUtil";
import surgeryTypeService from "services/surgeryTypeService";

export default {
   name: "SurgeryTypeReserveList",

   components: {
      SurgeryTypeReserveListCardSkeleton,
      SurgeryTypeReserveListCard,
      NoData,
   },

   props: {
      susCode: {
         type: String,
      },
   },

   data: function () {
      return {
         surgeryTypes: [],
         loadingSurgeryTypes: true,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 100,
            filter: [],
         };

         if (this.susCode && this.susCode.replace(/ /g, "") !== "")
            query.filter.push({
               attribute: "sus_code",
               query: this.susCode,
            });

         return query;
      },
   },

   watch: {
      susCode: {
         immediate: true,
         handler(susCode) {
            if (susCode) this.getAllSurgeryTypes(this.query);
         },
      },
   },

   methods: {
      colorByTotal(total) {
         let color;

         if (total >= 300) color = "info";
         else if (total >= 100) color = "success";
         else if (total >= 50) color = "amber";
         else if (total >= 10) color = "warning";
         else color = "error";

         return color;
      },

      async getAllSurgeryTypes(query) {
         this.loadingSurgeryTypes = true;

         try {
            const response = await surgeryTypeService.getAllSurgeryTypes(query);

            this.surgeryTypes = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSurgeryTypes = false;
         }
      },

      onReserveSurgery(surgery) {
         this.$emit("onReserveSurgery", surgery);
      },
   },
};
</script>

<style scoped>
.surgery-label {
   line-height: 130% !important;
}
</style>