<template>
   <div>
      <v-dialog
         v-model="dialogVisibility"
         :fullscreen="isSmallerThan(599)"
         transition="slide-y-transition"
         max-width="420"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <AuthSelectOperatorType
               @onSelectType="onSelectType"
               :token="operatorToken"
               class="py-6"
            />
         </v-card>
      </v-dialog>

      <div class="d-flex justify-center align-center auth-div">
         <AuthCard class="auth-card" @onAuth="onAuth" />
      </div>
   </div>
</template>

<script>
import AuthCard from "../../components/auth/AuthCard";
import AuthSelectOperatorType from "../../components/auth/AuthSelectOperatorType";
import userService from "../../services/userService";
import jwtUtil from "../../utils/jwtUtil";
import responsiveUtil from "../../utils/responsiveUtil";
import axiosService from "services/axiosService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "Auth",

   components: { AuthCard, AuthSelectOperatorType },

   data: function () {
      return {
         dialogVisibility: false,

         operatorToken: null,
      };
   },

   computed: {
      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },

      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         },
      },

      termVisibilityStore: {
         get() {
            return this.$store.getters["term/getVisibility"];
         },

         set(value) {
            this.$store.commit("term/setVisibility", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      async onAuth(response) {
         const userTemp = jwtUtil.decodeJwtToken(response.data.access_token);

         switch (userTemp.sub_type) {
            case "admin":
            case "manager":
            case "healthunit_manager":
            case "secretary_manager":
            case "regulator_manager":
            case "regulator":
               /* 1. Busca o usuário e seta no fullUserStore. */
               await this.getFullUserRaw(userTemp, response.data.access_token);

               /* 2. Verifica se o paciente já aceitou o não o termo.
               Caso já tenha aceitado, envia para tela inicial. Caso não tenha, abre o termo. */
               if (!this.fullUserStore.is_authorized)
                  this.termVisibilityStore = true;
               else {
                  userService.setUserSession({
                     token: response.data.access_token,
                  });

                  if (this.$route.path !== this.getUserRoutesStore[0])
                     this.$router.push({ path: this.getUserRoutesStore[0] });
               }
               break;

            case "operator":
               this.operatorToken = response.data.access_token;
               this.dialogVisibility = true;
               break;
         }
      },

      async onSelectType(authData) {
         const userTemp = jwtUtil.decodeJwtToken(authData.token);
         await this.getFullUserRaw(userTemp, authData.token);

         if (!this.fullUserStore.is_authorized) this.termVisibilityStore = true;
         else {
            userService.setUserSession({
               token: authData.token,
               operatorType: authData.type,
            });

            if (this.$route.path !== this.getUserRoutesStore[0])
               this.$router.push({ path: this.getUserRoutesStore[0] });
         }
      },

      /* Busca o usuário de forma crua, pois o token ainda não foi setado na store. */
      async getFullUserRaw(user, token) {
         let response;

         /* Seta o token temporariamente. */
         axiosService.defaults.headers.common[
            "Authorization"
         ] = `bearer ${token}`;

         try {
            switch (user.sub_type) {
               case "admin":
                  response = await axiosService.get(`/admins/${user.sub}`);
                  break;
               case "manager":
                  response = await axiosService.get(`/managers/${user.sub}`);
                  break;
               case "healthunit_manager":
                  response = await axiosService.get(
                     `/healthunitmanagers/${user.sub}`
                  );
                  break;
               case "secretary_manager":
                  response = await axiosService.get(
                     `/secretarymanagers/${user.sub}`
                  );
                  break;
               case "operator":
                  response = await axiosService.get(`/operators/${user.sub}`);
                  break;
               case "regulator_manager":
                  response = await axiosService.get(
                     `/regulatormanagers/${user.sub}`
                  );
                  break;
               case "regulator":
                  response = await axiosService.get(`/regulators/${user.sub}`);
                  break;
            }

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.auth-div {
   height: 100%;
}

.auth-card {
   width: 400px;
}
</style>