<template>
   <div>
      <FormTitle
         v-if="!hideTitle"
         icon="mdi-map"
         title="SELECIONAR MUNICÍPIO"
      />

      <v-alert
         v-if="!hideAlert"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >{{ alertMessage }}</v-alert
      >

      <div v-if="!mapByRegion" class="d-flex justify-center align-center">
         <v-autocomplete
            v-model="municipalitySelected"
            :items="municipalities"
            :search-input.sync="municipalitySearchFilter"
            :loading="loading"
            id="municipality-input"
            class="search-bar mb-4 mt-3"
            placeholder="Digite o município para buscar ou selecione no mapa abaixo..."
            item-text="name"
            item-value="id"
            return-object
            hide-no-data
            auto-select-first
            cache-items
            clearable
         >
            <template v-slot:no-data>
               <div class="px-5 py-2">
                  Desculpe, nenhum município encontrado.
               </div>
            </template>
         </v-autocomplete>
      </div>

      <div v-if="multiple" class="d-flex justify-center align-center">
         <v-switch v-model="mapByRegion" label="Visualizar regiões"></v-switch>
      </div>

      <v-card class="d-flex justify-center align-center" flat>
         <MapParaibaByRegion
            v-show="mapByRegion && multiple"
            @onChangeLoadingMunicipality="onChangeLoading"
            @onGetRegion="getRegions"
            ref="mapparaibabyregion"
         />

         <MapParaiba
            v-show="!mapByRegion"
            @onChangeLoadingMunicipality="onChangeLoading"
            @onGetMunicipality="
               (municipality) => onGetMunicipality(municipality, 'map')
            "
            ref="mapparaiba"
         />
      </v-card>

      <div v-if="multiple && !hideAlert">
         <v-alert
            :icon="
               municipalitiesSelected.length ? 'mdi-map' : 'mdi-gesture-tap'
            "
            class="caption ma-0 mt-5 py-3"
            color="primary"
            type="info"
            border="left"
            dense
            text
         >
            <div v-if="municipalitiesSelected.length">
               <p class="mb-3">
                  <strong>Municípios selecionados</strong>:
                  {{
                     municipalitiesSelected.map((item) => item.name).join(", ")
                  }}.
               </p>

               <div
                  v-if="!emitEventOnSelectMunicipality"
                  class="d-flex justify-end align-center ma-n1"
               >
                  <div class="ma-1">
                     <v-btn
                        @click="resetMunicipalities"
                        class="d-none d-sm-flex ml-auto"
                        color="primary"
                        id="save-primary-form-button"
                        text
                        x-small
                        >Limpar
                     </v-btn>
                     <v-btn
                        @click="resetMunicipalities"
                        class="d-flex d-sm-none ml-auto"
                        color="primary"
                        id="save-secondary-form-button"
                        fab
                        text
                        small
                        x-small
                     >
                        <v-icon small>mdi-delete</v-icon>
                     </v-btn>
                  </div>

                  <div class="ma-1">
                     <v-btn
                        @click="applyFilter"
                        class="shadow-floating-primary-button d-none d-sm-flex ml-auto"
                        color="primary"
                        id="save-primary-form-button"
                        depressed
                        x-small
                        >Filtrar
                        <v-icon right small>mdi-check</v-icon>
                     </v-btn>
                     <v-btn
                        @click="applyFilter"
                        class="shadow-floating-primary-button d-flex d-sm-none ml-auto"
                        color="primary"
                        id="save-secondary-form-button"
                        fab
                        small
                        x-small
                     >
                        <v-icon small>mdi-check</v-icon>
                     </v-btn>
                  </div>
               </div>
            </div>
            <p v-else>
               Selecione <strong>um</strong> ou <strong>mais</strong> municípios
               para filtrar.
            </p>
         </v-alert>
      </div>
   </div>
</template>

<script>
import FormTitle from "../../base/form/FormTitle";
import MapParaibaByRegion from "../../base/mapParaiba/MapParaibaByRegion";
import MapParaiba from "../../base/mapParaiba/MapParaiba";
import municipalityService from "../../../services/municipalityService";
import snackBarUtil from "../../../utils/snackBarUtil";

export default {
   name: "SelectMunicipality",

   components: { MapParaiba, MapParaibaByRegion, FormTitle },

   props: {
      alertMessage: {
         type: String,
         default: "Clique sobre um município ou pesquise-o abaixo.",
      },

      loadMunicipalitiesOnCreated: {
         type: Boolean,
         default: true,
      },

      multiple: {
         type: Boolean,
         default: false,
      },

      emitEventOnSelectMunicipality: {
         type: Boolean,
         default: false,
      },

      hideTitle: {
         type: Boolean,
         default: false,
      },

      hideAlert: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         municipalitySelected: {},
         municipalitiesSelected: [],
         municipalities: [],

         municipalitySearchFilter: "",
         loading: false,

         municipalityTimeout: null,

         mapByRegion: false,
      };
   },

   computed: {
      query() {
         const query = {
            page: 1,
            limit: 20,
            sort: "name",
         };

         if (
            this.municipalitySearchFilter &&
            this.municipalitySearchFilter.replace(/ /g, "") !== ""
         )
            query.filter = {
               attribute: "name",
               query: this.municipalitySearchFilter,
            };
         return query;
      },
   },

   watch: {
      municipalitySearchFilter(newFilter, oldFilter) {
         if (
            !newFilter ||
            !oldFilter ||
            newFilter.length < oldFilter.length ||
            (this.municipalitySelected &&
               newFilter === this.municipalitySelected.name) ||
            (this.municipalitySelected &&
               oldFilter === this.municipalitySelected.name)
         )
            return;

         clearTimeout(this.municipalityTimeout);
         this.municipalityTimeout = setTimeout(() => {
            this.getAllMunicipalities(this.query);
         }, 800);
      },

      municipalitySelected(newMunicipality) {
         if (newMunicipality) {
            if (this.multiple) {
               this.onGetMunicipality(newMunicipality, "input");
            } else {
               this.onGetMunicipality(newMunicipality, "input");
               this.$emit("onSelectMunicipality", newMunicipality);
            }
         }
      },
   },

   methods: {
      reset() {
         this.municipalitiesSelected = [];
         if (this.$refs.mapparaiba) this.$refs.mapparaiba.resetMultiple();
         if (this.$refs.mapparaibabyregion)
            this.$refs.mapparaibabyregion.resetMultiple();
      },

      back() {
         this.$emit("onClickBack");
      },

      resetMunicipalities() {
         this.reset();
         this.$emit("onSelectMunicipality", []);
      },

      applyFilter() {
         this.$emit("onSelectMunicipality", this.municipalitiesSelected);
      },

      searchItems() {
         if (
            this.municipalitySearchFilter &&
            this.municipalitySearchFilter.replace(/ /g, "") !== ""
         )
            this.getAllMunicipalities(this.query);
      },

      onChangeLoading(value) {
         this.loading = value;
      },

      onGetMunicipality(municipality, source) {
         this.municipalities.push(municipality);

         if (this.multiple) {
            /* Limpa o mapa por regioes */
            if (this.$refs.mapparaibabyregion)
               this.$refs.mapparaibabyregion.resetMultiple();

            /* Adiciona o município em um array, caso o mesmo ainda não exista */
            const municipalityIndex = this.municipalitiesSelected.findIndex(
               (item) => item.id === municipality.id
            );

            if (source === "input") {
               /* Se essa function foi chamada pelo input de busca, só seta os municípios. Não
               é possível desmarcar um município utilizando o input de busca. */
               if (municipalityIndex === -1) {
                  this.municipalitiesSelected.push(municipality);

                  if (this.$refs.mapparaiba) {
                     this.$refs.mapparaiba.highlightMunicipality(
                        municipality.name
                     );
                  }
               }
            } else {
               /* Se essa function foi chamada pelo map, significa que o usuário está marcando e
               desmarcando manualmente, nesse caso é possível marcar e desmarcar. */
               if (this.$refs.mapparaiba) {
                  this.$refs.mapparaiba.highlightMunicipality(
                     municipality.name
                  );
               }

               if (municipalityIndex === -1)
                  this.municipalitiesSelected.push(municipality);
               else this.municipalitiesSelected.splice(municipalityIndex, 1);
            }

            if (this.emitEventOnSelectMunicipality) this.applyFilter();
         } else {
            if (this.$refs.mapparaiba) {
               this.$refs.mapparaiba.resetMultiple();
               this.$refs.mapparaiba.highlightMunicipality(municipality.name);
            }

            this.municipalitySelected = municipality;
         }
      },

      getRegions(municipalities, region) {
         this.municipalities.push(...municipalities);

         municipalities.forEach((municipality) => {
            /* Adiciona o município em um array, caso o mesmo ainda não exista */
            const municipalityIndex = this.municipalitiesSelected.findIndex(
               (item) => item.id === municipality.id
            );

            /* Se essa function foi chamada pelo map, significa que o usuário está marcando e
               desmarcando manualmente, nesse caso é possível marcar e desmarcar. */
            if (this.$refs.mapparaiba) {
               this.$refs.mapparaiba.highlightMunicipality(municipality.name);
            }

            if (municipalityIndex === -1)
               this.municipalitiesSelected.push(municipality);
            else this.municipalitiesSelected.splice(municipalityIndex, 1);
         });

         if (this.$refs.mapparaibabyregion)
            this.$refs.mapparaibabyregion.highlightRegion(region.id);

         if (this.emitEventOnSelectMunicipality) this.applyFilter();
      },

      async getAllMunicipalities(query) {
         this.loading = true;

         try {
            const response = await municipalityService.getAllMunicipalities(
               query
            );

            this.municipalities = Object.assign([], response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loading = false;
         }
      },
   },

   created() {
      if (this.loadMunicipalitiesOnCreated)
         this.getAllMunicipalities(this.query);
   },
};
</script>

<style scoped>
.search-bar {
   max-width: 500px;
}
</style>