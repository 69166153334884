<template>
   <div>
      <p class="body-1 font-weight-medium mt-3">Sinais vitais</p>

      <v-row class="mt-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model.number="patientData.patient_data.respiratory_rate"
               v-maska="'#*'"
               label="Frequência respiratória"
               id="patient_respiratory_rate-data-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="patientData.patient_data.oxygen_saturation"
               v-maska="'###'"
               label="Saturação (%)"
               id="patient_oxygen_saturation-data-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="patientData.patient_data.oxygen_equipment"
               label="Ar/O²"
               id="patient_oxygen_equipment-data-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model.number="patientData.patient_data.heart_rate"
               v-maska="'#*'"
               label="Frequência cardiaca"
               id="patient_heart_rate-data-patient-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="patientData.patient_data.blood_pressure"
               v-maska="'###/###'"
               label="Pressão arterial"
               id="patient_blood_pressure-data-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model.number="patientData.patient_data.temperature"
               v-maska="['##', '##.#']"
               label="Temperatura (Cº)"
               id="patient_temperature-data-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="patientData.patient_data.consciousness_level"
               label="Consciência"
               id="patient_consciousness_level-data-patient-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <p class="body-1 font-weight-medium mt-10">Tipo de leito e contato</p>

      <v-row class="mt-0">
         <v-col cols="12" md="12">
            <v-text-field
               v-model="patientData.resource"
               :rules="[value => !!value || 'Tipo de leito obrigatório.']"
               label="Tipo de leito*"
               id="patient_resource-patient-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="4">
            <v-text-field
               v-model="patientData.doctor_name"
               :rules="[
                  value =>
                     !!value ||
                     !!patientData.nurse_name ||
                     'Nome do médico ou enfermeiro obrigatório.'
               ]"
               label="Nome do médico*"
               id="patient_doctor_name-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="4">
            <v-text-field
               v-model="patientData.nurse_name"
               :rules="[
                  value =>
                     !!value ||
                     !!patientData.doctor_name ||
                     'Nome do médico ou enfermeiro obrigatório.'
               ]"
               label="Nome do enfermeiro*"
               id="patient_nurse_name-patient-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="4">
            <v-text-field
               v-model="patientData.contact_phone"
               v-maska="['(##) ####-####', '(##) #####-####']"
               :rules="[
                  value => !!value || 'Telefone para contato obrigatório.',
                  value =>
                     (value && value.length >= 14) ||
                     'Telefone para contato inválido.'
               ]"
               label="Telefone para contato*"
               id="patient_contact_phone-patient-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-textarea
         v-model="patientData.notes"
         class="notes-height mt-8"
         label="História Clínica"
         rows="3"
         no-resize
         id="patient_notes-patient-form-input"
      ></v-textarea>
   </div>
</template>

<script>
import validationUtil from "../../../../utils/validationUtil";

export default {
   name: "PatientData",

   props: {
      patientData: {
         type: Object,
         default: function() {
            return {};
         }
      }
   },

   data: function() {
      return {};
   },

   methods: {
      ...validationUtil
   }
};
</script>

<style scoped>
.notes-height {
   height: 100%;
}

</style>
