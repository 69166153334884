export default {
   namespaced: true,

   state: {
      visibility: false,
   },

   getters: {
      getVisibility(state) {
         return state.visibility;
      },
   },

   mutations: {
      setVisibility(state, visibility) {
         if (visibility === undefined) state.visibility = !state.visibility;
         else state.visibility = visibility;
      },
   },
};
