<template>
   <div>
      <FormTitle icon="mdi-home-plus" title="SECRETARIA DE SAÚDE" />

      <v-skeleton-loader type="image" height="40px"></v-skeleton-loader>

      <v-form>
         <v-card flat>
            <p class="body-1 font-weight-medium mt-5 text--secondary">
               Dados da secretaria de saúde
            </p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="60%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="6">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="40%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="30%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-6 text--secondary">
               Endereço
            </p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="40%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="6">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="70%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="2">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="60%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="50%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="6">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="80%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="2">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="40%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="40%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>

            <v-row class="mt-5 mb-0">
               <v-col cols="12" md="3">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="40%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
               <v-col cols="12" md="3">
                  <v-skeleton-loader
                     class="mt-1 mb-3 rounded-pill"
                     type="image"
                     height="18px"
                     width="60%"
                  ></v-skeleton-loader>

                  <v-skeleton-loader
                     type="image"
                     height="1px"
                  ></v-skeleton-loader>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <div class="mt-8">
         <div class="d-flex d-md-none">
            <v-skeleton-loader
               class="rounded-circle"
               type="image"
               height="32px"
               width="32px"
            ></v-skeleton-loader>
         </div>
         <div class="d-none d-md-flex">
            <v-skeleton-loader
               type="image"
               height="36px"
               width="110px"
            ></v-skeleton-loader>
         </div>
      </div>
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle.vue";

export default {
   name: "HealthSecretarieFormSkeleton",

   components: { FormTitle },
};
</script>

<style scoped>
</style>