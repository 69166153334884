var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"slide-y-transition","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex d-md-none",attrs:{"color":"primary","id":_vm.userInfo
                     ? ("filter-" + _vm.userInfo + "-secondary-list-header-button")
                     : 'filter-secondary-list-header-button',"fab":"","depressed":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"color":"primary","id":_vm.userInfo
                     ? ("filter-" + _vm.userInfo + "-primary-list-header-button")
                     : 'filter-primary-list-header-button',"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" FILTRO "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filterMenuVisibility),callback:function ($$v) {_vm.filterMenuVisibility=$$v},expression:"filterMenuVisibility"}},[_c('v-card',{staticClass:"standard-padding-x-small",attrs:{"disabled":_vm.loading}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary","small":"","left":""}},[_vm._v("mdi-filter")]),_c('p',{staticClass:"caption font-weight-bold primary--text"},[_vm._v(" Filtrar por ")])],1),_c('v-chip-group',{attrs:{"value":_vm.filter,"mandatory":"","column":""},on:{"change":_vm.changeFilter}},_vm._l((_vm.filters),function(item,index){return _c('v-chip',{key:item.filter,attrs:{"color":_vm.filter === item.filter ? 'primary' : null,"value":item.filter,"id":("filter-item" + index + "-list-header-chip"),"filter":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),_c('div',{staticClass:"d-flex align-center mt-1"},[_c('v-icon',{attrs:{"color":"primary","small":"","left":""}},[_vm._v("mdi-sort")]),_c('p',{staticClass:"caption font-weight-bold primary--text"},[_vm._v(" Listar por ")])],1),_c('v-chip-group',{staticClass:"pa-0",attrs:{"value":_vm.sort,"mandatory":"","column":""},on:{"change":_vm.changeSort}},_vm._l((_vm.sorts),function(item,index){return _c('v-chip',{key:item.sort,attrs:{"color":_vm.sort === item.sort ? 'primary' : null,"value":item.sort,"id":("sort-item" + index + "-list-header-chip"),"filter":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1),_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.filterMask),expression:"filterMask"}],staticClass:"search-bar body-2",attrs:{"placeholder":("Digite o(a) " + _vm.filterText + " do(a) " + _vm.filterActor + "..."),"append-icon":"mdi-magnify","maxlength":_vm.filterMaxLength,"id":_vm.userInfo
               ? ("filter-" + _vm.userInfo + "-by-list-header-input")
               : 'filter-by-list-header-input'},on:{"click:append":function($event){_vm.filterBy && _vm.filterBy !== '' ? _vm.searchItems() : null}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"id":"search-button-list-header"},on:{"click":function($event){_vm.filterBy && _vm.filterBy !== '' ? _vm.searchItems() : null}}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}}),_c('v-tooltip',{attrs:{"value":_vm.filterBy !== '' ? true : false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"36px","width":"36px","icon":""},on:{"click":function($event){_vm.filterBy = null;
                  _vm.clearFilter();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('p',[_vm._v("Limpar filtro")])])],1),(!_vm.hideAddButton)?_c('div',[_c('v-btn',{staticClass:"d-flex d-md-none",attrs:{"color":"primary","id":"add-secondary-list-header-button","fab":"","depressed":"","x-small":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"color":"primary","id":"add-primary-list-header-button","depressed":""},on:{"click":_vm.add}},[_vm._v(" ADICIONAR "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }