<template>
   <div>
      <v-dialog
         v-model="dialogMunicipalityFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeMunicipalityForm" />

            <MunicipalityForm
               @onClickCancel="closeMunicipalityForm"
               @onCreatedMunicipality="
                  closeMunicipalityForm();
                  refreshMunicipalityList();
               "
               @onUpdatedMunicipality="
                  closeMunicipalityForm();
                  refreshMunicipalityList();
               "
               :municipality="municipalitySelected"
               :editMode="editMode"
               class="standard-padding"
               hideRemoveButton
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <MunicipalityList
         @onClickViewMunicipality="onClickViewMunicipality"
         @onClickEditMunicipality="onClickEditMunicipality"
         class="mt-12"
         ref="municipalitylist"
         hideAddButton
         hideRemoveButton
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import MunicipalityList from "../../components/municipality/MunicipalityList";
import MunicipalityForm from "../../components/municipality/MunicipalityForm";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "Municipality",

   components: { Close, PageTitle, MunicipalityList, MunicipalityForm },

   data: function () {
      return {
         municipalitySelected: {},

         editMode: false,

         dialogMunicipalityFormVisibility: false,
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   watch: {
      dialogMunicipalityFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },
   },

   methods: {
      ...responsiveUtil,

      onClickAddMunicipality(municipality) {
         this.municipalitySelected = Object.assign({}, municipality);
         this.openMunicipalityForm();
      },

      onClickViewMunicipality(municipality) {
         this.municipalitySelected = Object.assign({}, municipality);
         this.openMunicipalityForm();
      },

      onClickEditMunicipality() {
         this.editMode = true;
      },

      openMunicipalityForm() {
         this.dialogMunicipalityFormVisibility = true;
      },

      closeMunicipalityForm() {
         this.dialogMunicipalityFormVisibility = false;
      },

      refreshMunicipalityList() {
         this.$refs.municipalitylist.refreshAllMunicipalitysByRef();
      },
   },
};
</script>

<style scoped>
</style>