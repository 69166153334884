<template>
   <div class="d-flex justify-center align-center forgot-password-div">
      <ForgotPasswordCard class="forgot-password-card" />
   </div>
</template>

<script>
import ForgotPasswordCard from "../../components/auth/ForgotPasswordCard";

export default {
   name: "ForgotPassword",

   components: { ForgotPasswordCard },
};
</script>

<style scoped>
.forgot-password-div {
   height: 100%;
}

.forgot-password-card {
   width: 400px;
}
</style>