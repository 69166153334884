<template>
   <div>
      <FormTitle icon="mdi-calendar-multiple-check" title="AGENDAR MUTIRÃO" />

      <v-expansion-panels v-model="surgeriesPanel">
         <v-expansion-panel class="panels-border mb-1">
            <v-expansion-panel-header class="px-5">
               <div class="d-flex align-center">
                  <v-icon left>mdi-view-list</v-icon>
                  <p>
                     {{ surgeries.length }} cirurgia{{
                        surgeries.length > 1 ? "s" : ""
                     }}
                     selecionada{{ surgeries.length > 1 ? "s" : "" }}
                  </p>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="surgeries-list">
               <v-card
                  v-for="surgery in surgeries"
                  :key="surgery.id"
                  class="standard-padding-x-tiny mb-2"
                  color="#e9e9e9"
                  flat
               >
                  <div class="d-flex align-center">
                     <v-icon left small>mdi-account</v-icon>
                     <p class="caption">
                        {{ surgery.patient ? surgery.patient.name : "-" }}
                     </p>
                  </div>

                  <div class="d-flex align-center">
                     <v-icon left small>mdi-medical-bag</v-icon>

                     <div class="d-flex align-center flex-wrap truncate">
                        <p class="caption">{{ surgery.name }}</p>
                     </div>
                  </div>
               </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form ref="form">
         <v-card flat>
            <v-row class="mt-2">
               <v-col cols="12" md="8">
                  <v-text-field
                     v-model="formSurgery.doctor_name"
                     :rules="[
                        (value) => !!value || 'Nome do médico obrigatório.',
                     ]"
                     id="phone-surgery-form-input"
                     label="Nome do médico*"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="4">
                  <v-menu offset-y>
                     <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                           v-model="formSurgery.scheduling_date"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) =>
                                 !!value || 'Data de agendamento obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           append-icon="mdi-calendar"
                           id="birth_date-surgery-form-input"
                           label="Data de agendamento*"
                        >
                           <template v-slot:append>
                              <v-icon v-bind="attrs" v-on="on">
                                 mdi-calendar
                              </v-icon>
                           </template>
                        </v-text-field>
                     </template>

                     <v-date-picker
                        v-model="datePicker"
                        :allowed-dates="allowedDates"
                        locale="pt-br"
                        no-title
                     >
                     </v-date-picker>
                  </v-menu>
               </v-col>
            </v-row>

            <v-row class="mt-4">
               <v-col cols="12">
                  <v-textarea
                     v-model="formSurgery.notes"
                     class="notes-height"
                     label="Observações"
                     rows="5"
                     no-resize
                  ></v-textarea>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <FormActions
         @onClickSave="onClickSaveBatchSurgery"
         @onClickCancel="onClickCancel"
         :loadingSave="loadingSaveSurgery"
         :validationErrors="validationErrors"
         class="mt-7"
      />
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle.vue";
import util from "utils/util";
import formatUtil from "utils/formatUtil";
import validationUtil from "utils/validationUtil";
import moment from "moment";
import FormActions from "components/base/form/FormActions.vue";
import snackBarUtil from "utils/snackBarUtil";
import surgerieService from "services/surgerieService";

export default {
   components: { FormTitle, FormActions },

   name: "BatchSurgeryForm",

   props: {
      surgeries: {
         type: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         formSurgery: {},

         surgeriesPanel: false,

         validationErrors: [],

         loadingSaveSurgery: false,
      };
   },

   watch: {
      surgeries() {
         this.surgeriesPanel = false;

         this.formSurgery = {};
         this.validationErrors = [];
         this.resetValidation();
      },
   },

   computed: {
      datePicker: {
         get() {
            if (
               validationUtil.dateValidation(
                  this.formSurgery.scheduling_date,
                  "DD/MM/YYYY"
               )
            )
               return formatUtil.formatDate(
                  this.formSurgery.scheduling_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.formSurgery.scheduling_date = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },
   },

   methods: {
      ...util,
      ...formatUtil,
      ...validationUtil,

      formatObjectToSend(surgery, removeEmptyStrings) {
         let surgeryTemp = util.removeBlankAttributes(surgery, {
            emptyStrings: removeEmptyStrings,
         });

         if (surgeryTemp.scheduling_date)
            surgeryTemp.scheduling_date = formatUtil.formatDate(
               surgeryTemp.scheduling_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return surgeryTemp;
      },

      allowedDates(val) {
         return moment().diff(val, "days") <= 0;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickSaveBatchSurgery() {
         if (!this.executeValidation() || !this.surgeries.length) return;

         this.loadingSaveSurgery = true;

         try {
            const promisses = [];

            this.surgeries.forEach((surgery) => {
               promisses.push(
                  surgerieService.updateHealthUnitSurgerie(
                     surgery.healthunit_id,
                     surgery.id,
                     this.formatObjectToSend(this.formSurgery, false)
                  )
               );
            });

            Promise.all(promisses).then(() => {
               snackBarUtil.showCustomSnackBar({
                  color: "success",
                  title: "SUCESSO!",
                  message: "As cirurgias foram agendadas!",
               });

               this.$emit("onScheduledSurgeries");
               this.loadingSaveSurgery = false;
            });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
            this.loadingSaveSurgery = false;
         }
      },
   },
};
</script>

<style scoped>
.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.2);
}

.notes-height {
   height: 100%;
}

.truncate {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.surgeries-list {
   max-height: 300px;
   overflow-y: scroll;
}
</style>