<template>
   <div>
      <div class="standard-padding pb-0">
         <v-alert
            class="caption mb-1"
            color="primary"
            type="info"
            border="left"
            dense
            text
            >Siga os passos abaixo para finalizar a solicitação.
         </v-alert>
      </div>

      <v-stepper v-model="currentStep" class="elevation-0" vertical>
         <!-- Passo 1: Informar a cirurgia. -->
         <v-stepper-step
            :complete="currentStep > 1"
            :color="currentStep > 1 ? 'success' : 'primary'"
            class="body-2"
            step="1"
         >
            Informe a cirurgia
         </v-stepper-step>

         <v-stepper-content step="1">
            <div class="mt-2 mb-7">
               <ViewSurgery
                  v-if="
                     request.doctor_opinion && request.doctor_opinion.sus_code
                  "
                  :surgerySusCode="request.doctor_opinion.sus_code"
                  hideTitle
               />

               <v-form ref="formsurgerieinput" v-else>
                  <SurgerieInput
                     @onSelectSurgerie="fillSurgery"
                     ref="surgerieinput"
                  />
               </v-form>
            </div>

            <v-btn color="primary" @click="surgeryStepValidation">
               Próximo
            </v-btn>
         </v-stepper-content>

         <!-- Passo 2: Informar a unidade de saúde. -->
         <v-stepper-step
            :complete="currentStep > 2"
            :color="currentStep > 2 ? 'success' : 'primary'"
            class="body-2"
            step="2"
         >
            Informe a unidade de saúde realizadora
         </v-stepper-step>

         <v-stepper-content step="2">
            <v-form ref="formhealthunitinput">
               <HealthUnitInput
                  @onSelectHealthUnit="fillHealthUnit"
                  ref="healthunitinput"
                  class="mb-5"
               />
            </v-form>

            <div class="ma-n1">
               <v-btn class="ma-1" @click="previousStep()" text> Voltar </v-btn>
               <v-btn
                  class="ma-1"
                  color="primary"
                  @click="healthUnitStepValidation"
               >
                  Próximo
               </v-btn>
            </div>
         </v-stepper-content>

         <!-- Passo 3: Informar a data de realização. -->
         <v-stepper-step
            :complete="currentStep > 3"
            :color="currentStep > 3 ? 'success' : 'primary'"
            class="body-2"
            step="3"
         >
            Informe a data de realização
         </v-stepper-step>

         <v-stepper-content step="3">
            <v-form ref="formexecutiondate">
               <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                        v-model="formClose.surgery_execution_date"
                        v-maska="'##/##/####'"
                        :rules="[
                           (value) =>
                              !!value || 'Data de realização obrigatória.',
                           (value) => dateValidation(value) || 'Data inválida.',
                           (value) =>
                              allowedDates(
                                 formatDate(value, 'YYYY-MM-DD', 'DD/MM/YYYY')
                              ) || 'Data não pode ser posterior a data atual.',
                        ]"
                        class="mt-3 mb-8"
                        append-icon="mdi-calendar"
                        id="birth_date-surgery-form-input"
                        label="Data de realização*"
                     >
                        <template v-slot:append>
                           <v-icon v-bind="attrs" v-on="on"
                              >mdi-calendar</v-icon
                           >
                        </template>
                     </v-text-field>
                  </template>

                  <v-card class="d-flex justify-end align-center">
                     <v-date-picker
                        v-model="datePicker"
                        :allowed-dates="allowedDates"
                        locale="pt-br"
                        no-title
                     >
                     </v-date-picker>
                  </v-card>
               </v-menu>
            </v-form>

            <div class="ma-n1">
               <v-btn class="ma-1" @click="previousStep()" text> Voltar </v-btn>
               <v-btn
                  class="ma-1"
                  color="primary"
                  @click="executionDateStepValidation"
               >
                  Próximo
               </v-btn>
            </div>
         </v-stepper-content>

         <!-- Passo 4: Informar o desfecho. -->
         <v-stepper-step step="4" class="body-2">
            Informe o desfecho
         </v-stepper-step>

         <v-stepper-content step="4">
            <div>
               <p class="caption mb-1">Tipo de desfecho*</p>

               <v-chip-group
                  v-model="formClose.outcome"
                  @change="(value) => (invalidOutcome = !value)"
                  class="my-n2"
                  column
               >
                  <v-chip
                     :color="
                        formClose.outcome === 'discharge' ? 'primary' : 'normal'
                     "
                     value="discharge"
                     id="discharge-bed-request-confirm-outcome-form-button"
                     filter
                     small
                  >
                     Alta
                  </v-chip>
                  <v-chip
                     :color="
                        formClose.outcome === 'death' ? 'primary' : 'normal'
                     "
                     value="death"
                     id="death-bed-request-confirm-outcome-form-button"
                     filter
                     small
                  >
                     Óbito
                  </v-chip>
                  <v-chip
                     :color="
                        formClose.outcome === 'transfer' ? 'primary' : 'normal'
                     "
                     value="transfer"
                     id="transfer-bed-request-confirm-outcome-form-button"
                     filter
                     small
                  >
                     Transferência
                  </v-chip>
               </v-chip-group>

               <v-expand-transition>
                  <p v-if="invalidOutcome" class="caption error--text mt-1">
                     Tipo de desfecho obrigatória.
                  </p>
               </v-expand-transition>

               <v-row class="mt-2">
                  <v-col cols="12">
                     <v-textarea
                        v-model="formClose.outcome_justification"
                        class="outcome-height"
                        label="Justificativa"
                        id="outcome_justification-bed-request-confirm-outcome-form-input"
                        rows="4"
                        no-resize
                     ></v-textarea>
                  </v-col>
               </v-row>
            </div>

            <div class="ma-n1">
               <v-btn class="ma-1" @click="previousStep()" text> Voltar </v-btn>
               <v-btn
                  @click="submit"
                  :loading="loadingStatus"
                  class="ma-1"
                  color="primary"
               >
                  Finalizar
               </v-btn>
            </div>
         </v-stepper-content>
      </v-stepper>
   </div>
</template>

<script>
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import SurgerieInput from "components/surgerie/SurgerieInput";
import ViewSurgery from "./ViewSurgery";
import validationUtil from "utils/validationUtil";
import formatUtil from "utils/formatUtil";
import snackBarUtil from "utils/snackBarUtil";
import requestSurgeryService from "services/requestSurgeryService";
import util from "utils/util";
import moment from "moment";

export default {
   name: "CloseForm",

   components: { HealthUnitInput, SurgerieInput, ViewSurgery },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         currentStep: 1,

         formClose: {},

         loadingStatus: false,
         invalidOutcome: false,
      };
   },

   computed: {
      datePicker: {
         get() {
            if (
               validationUtil.dateValidation(
                  this.formClose.surgery_execution_date,
                  "DD/MM/YYYY"
               )
            )
               return formatUtil.formatDate(
                  this.formClose.surgery_execution_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.formClose.surgery_execution_date = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },
   },

   watch: {
      request: {
         immediate: true,
         handler(request) {
            this.formClose = {
               status: "answered",
               outcome: "",
            };
            this.clearSurgeryInput();
            this.clearHealthUnitInput();
            this.clearExecutionDate();
            this.clearOutcome();

            /* Caso a solicitação já tenha cirurgia, pula para o passo 2. */
            if (
               request &&
               request.doctor_opinion &&
               request.doctor_opinion.sus_code
            ) {
               {
                  this.fillSurgery({
                     sus_code: request.doctor_opinion.sus_code,
                  });
                  this.currentStep = 2;
               }
            } else this.currentStep = 1;
         },
      },
   },

   methods: {
      ...validationUtil,
      ...formatUtil,

      previousStep() {
         this.currentStep -= 1;
      },

      nextStep() {
         this.currentStep += 1;
      },

      /* Preenche a cirurgia. */
      fillSurgery(surgery) {
         if (surgery && surgery.sus_code)
            this.formClose.sus_code = surgery.sus_code;
      },

      /* Valida o campo de cirurgia. */
      surgeryStepValidation() {
         if (this.$refs.formsurgerieinput) {
            if (this.$refs.formsurgerieinput.validate()) this.nextStep();
         } else this.nextStep();
      },

      /* Limpar o campo de cirurgia. */
      clearSurgeryInput() {
         if (this.$refs.formsurgerieinput) {
            this.$refs.formsurgerieinput.resetValidation();
         }
         if (this.$refs.surgerieinput) {
            this.$refs.surgerieinput.reset();
         }
      },

      /* Preenche a unidade de saúde. */
      fillHealthUnit(healthUnit) {
         if (healthUnit && healthUnit.id)
            this.formClose.targethealthunit_id = healthUnit.id;
      },

      /* Valida o campo de unidade de saúde. */
      healthUnitStepValidation() {
         if (this.$refs.formhealthunitinput.validate()) this.nextStep();
      },

      /* Limpar o campo de unidade de saúde. */
      clearHealthUnitInput() {
         if (this.$refs.formhealthunitinput) {
            this.$refs.formhealthunitinput.resetValidation();
         }
         if (this.$refs.healthunitinput) {
            this.$refs.healthunitinput.reset();
         }
      },

      /* Valida o campo de data de realização. */
      executionDateStepValidation() {
         if (this.$refs.formexecutiondate.validate()) this.nextStep();
      },

      /* Limpar o campo de data de realização. */
      clearExecutionDate() {
         this.formClose.surgery_execution_date = formatUtil.formatDate(
            new Date(),
            "DD/MM/YYYY"
         );
      },

      /* Limpar o campo de desfecho. */
      clearOutcome() {
         this.formClose.outcome = null;
         this.invalidOutcome = false;
      },

      /* Valida o campo de desfecho. */
      executeOutcomeValidation() {
         this.invalidOutcome = !this.formClose.outcome;
         return !this.invalidOutcome;
      },

      formatObjectToSend(outcome) {
         let outcomeTemp = util.removeBlankAttributes(outcome);

         if (outcomeTemp.surgery_execution_date)
            outcomeTemp.surgery_execution_date = formatUtil.formatDate(
               outcomeTemp.surgery_execution_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return outcomeTemp;
      },

      submit() {
         if (!this.executeOutcomeValidation()) return;

         this.updateSurgeryRequestStatus(this.request.id, this.formClose);
      },

      async updateSurgeryRequestStatus(requestId, request) {
         if (!requestId || !request) return;

         this.loadingStatus = true;

         try {
            await requestSurgeryService.updateSurgeryRequestStatus(
               requestId,
               this.formatObjectToSend(request)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi finalizada com sucesso!",
            });

            this.$emit("onCloseRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingStatus = false;
         }
      },

      allowedDates(date) {
         return moment(date) <= moment();
      },
   },
};
</script>

<style scoped>
::v-deep .v-stepper--vertical .v-stepper__step {
   padding: 24px 24px 0px 24px;
}

.outcome-height {
   height: 100%;
}
</style>