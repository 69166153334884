<template>
   <div>
      <SelectHealthSecretarieDetailsSkeleton v-if="!healthSecretarie.id" />

      <v-list-item
         v-else
         @click="selectHealthSecretarie(healthSecretarie)"
         class="px-5"
         two-line
      >
         <v-icon class="mr-4" color="error" large>mdi-home-plus</v-icon>

         <v-list-item-content>
            <v-list-item-title>{{ healthSecretarie.name }}</v-list-item-title>

            <v-list-item-subtitle
               v-if="healthSecretarie.address && healthSecretarie.address.city"
               >{{ healthSecretarie.address.city }}</v-list-item-subtitle
            >
         </v-list-item-content>

         <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>
   </div>
</template>

<script>
import SelectHealthSecretarieDetailsSkeleton from "components/healthUnit/selectHealthUnit/SelectHealthUnitDetailsSkeleton";
import healthSecretarieService from "services/healthSecretarieService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "SelectHealthSecretarieDetails",

   components: { SelectHealthSecretarieDetailsSkeleton },

   props: {
      healthSecretarieId: {
         type: String,
         default: null,
      },
   },

   data: function () {
      return {
         healthSecretarie: {},
      };
   },

   watch: {
      healthSecretarieId: {
         immediate: true,
         handler(healthSecretarieId) {
            if (healthSecretarieId)
               this.getHealthSecretarieById(healthSecretarieId);
         },
      },
   },

   methods: {
      async getHealthSecretarieById(healthSecretarieId) {
         try {
            const response =
               await healthSecretarieService.getHealthSecretarieById(
                  healthSecretarieId
               );

            this.healthSecretarie = Object.assign({}, response.data);
            this.$emit("onGetHealthSecretarie", this.healthSecretarie);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      selectHealthSecretarie(healthSecretarie) {
         this.$emit("onSelectHealthSecretarie", healthSecretarie);
      },
   },
};
</script>

<style scoped>
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(92, 107, 192, 1);
}
</style>