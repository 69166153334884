<template>
   <div>
      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <v-card class="standard-padding">
         <HealthUnitForm
            :healthUnit="healthUnitManagerCurrentHealthUnitStore"
            @onUpdatedHealthUnit="onUpdatedHealthUnit"
            hideTitle
            hideActions
         />
      </v-card>
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import HealthUnitForm from "../../components/healthUnit/HealthUnitForm";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "MyHealthUnit",

   components: {
      PageTitle,
      HealthUnitForm,
   },

   data: function () {
      return {
         healthUnitSelected: {},
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find((item) => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      onUpdatedHealthUnit(healthUnit) {
         const localStorageData = userService.getUserSession();
         localStorageData.healthUnit = healthUnit;
         userService.setUserSession(localStorageData);
      },
   },
};
</script>

<style scoped>
</style>