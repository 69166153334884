import axiosService from "./axiosService";

async function getAllHealthUnitManagers(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/healthunitmanagers?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item, index) => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
            case "cpf":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }

         if (index < query.filter.length - 1) url += "&";
      });
   }

   return await axiosService.get(url);
}

async function getHealthUnitManagerById(healthUnitManagerId) {
   return await axiosService.get(`/healthunitmanagers/${healthUnitManagerId}`);
}

async function createHealthUnitManager(healthUnitManager) {
   return await axiosService.post(`/healthunitmanagers`, healthUnitManager);
}

async function updateHealthUnitManager(healthUnitManagerId, healthUnitManager) {
   return await axiosService.patch(
      `/healthunitmanagers/${healthUnitManagerId}`,
      healthUnitManager
   );
}

export default {
   getAllHealthUnitManagers,
   createHealthUnitManager,
   updateHealthUnitManager,
   getHealthUnitManagerById
};
