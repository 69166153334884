<template>
   <div>
      <p class="body-1 font-weight-medium">Confirmação de cancelamento</p>

      <p class="caption mt-5 mb-1">Tipo de cancelamento*</p>

      <v-chip-group
         v-model="formRequestCancel.outcome"
         @change="(value) => (invalidOutcome = !value)"
         class="my-n2"
         column
      >
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_cure'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_cure"
            filter
            small
         >
            Alta Curado
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_improvement'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_improvement"
            filter
            small
         >
            Alta Melhorado
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_on_request'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_on_request"
            filter
            small
         >
            Alta a pedido
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_with_return'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_with_return"
            filter
            small
         >
            Alta com previsão de retorno
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_evasion'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_evasion"
            filter
            small
         >
            Alta por Evasão
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'discharge_other_reasons'
                  ? 'primary'
                  : 'normal'
            "
            value="discharge_other_reasons"
            filter
            small
         >
            Alta por outros motivos
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'transfer' ? 'primary' : 'normal'
            "
            value="transfer"
            filter
            small
         >
            Transfêrencia
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'death_certificate_by_doctor'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_doctor"
            filter
            small
         >
            Declaração de óbito fornecida pelo médico
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'death_certificate_by_iml'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_iml"
            filter
            small
         >
            Declaração de óbito fornecida pelo IML
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'death_certificate_by_svo'
                  ? 'primary'
                  : 'normal'
            "
            value="death_certificate_by_svo"
            filter
            small
         >
            Declaração de óbito fornecida pelo SVO
         </v-chip>
         <v-chip
            :color="
               formRequestCancel.outcome === 'others' ? 'primary' : 'normal'
            "
            value="others"
            filter
            small
         >
            Outros
         </v-chip>
      </v-chip-group>

      <v-expand-transition>
         <p v-if="invalidOutcome" class="caption error--text mt-1">
            Tipo de cancelamento obrigatória.
         </p>
      </v-expand-transition>

      <v-form ref="form">
         <v-row class="mt-2">
            <v-col cols="12">
               <v-textarea
                  v-model="formRequestCancel.outcome_justification"
                  :rules="
                     formRequestCancel.outcome === 'others'
                        ? [(value) => !!value || 'Justificativa obrigatória.']
                        : []
                  "
                  class="outcome-height"
                  label="Justificativa"
                  rows="4"
                  no-resize
               ></v-textarea>
            </v-col>
         </v-row>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickConfirmCancel"
         :loadingSave="loadingSaveCancel"
         :mode="'save'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormActions from "components/base/form/FormActions";
import requestService from "services/requestService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "RequestCancelForm",

   components: { FormActions },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         formRequestCancel: {},

         loadingSaveCancel: false,

         invalidOutcome: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler() {
            this.formRequestCancel = {};

            this.invalidOutcome = false;
            this.resetValidation();
         },
      },
   },

   methods: {
      executeValidation() {
         this.invalidOutcome = !this.formRequestCancel.outcome;

         return !this.invalidOutcome && this.$refs.form.validate();
      },

      resetValidation() {
         this.invalidOutcome = false;
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickConfirmCancel() {
         this.updateHealthUnitRequestStatus(
            this.request.source_health_entity.id,
            this.request.id,
            {
               status: "canceled",
               ...this.formRequestCancel,
            }
         );
      },

      async updateHealthUnitRequestStatus(healthUnitId, requestId, status) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !requestId ||
            !status
         )
            return;

         this.loadingSaveCancel = true;

         try {
            await requestService.updateHealthUnitRequestStatus(
               healthUnitId,
               requestId,
               status
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi cancelada!",
            });

            this.$emit("onCancelRequest");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveCancel = false;
         }
      },
   },
};
</script>

<style scoped>
.outcome-height {
   height: 100%;
}
</style>