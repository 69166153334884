<template>
   <v-alert
      class="ma-0 overflow-hidden"
      type="error"
      border="left"
      height="100%"
      icon="mdi-close"
      prominent
      text
   >
      <p
         class="body-1 font-weight-medium"
         id="rejected_by-justification-rejection-label"
      >
         REJEITADA PELO
         {{ request.status === "rejected" ? "REGULADOR" : "EXECUTANTE" }}
      </p>

      <p class="body-2">
         {{
            request.status === "rejected"
               ? request.doctor_opinion.justification
               : (request.surgery.status_justification || request.status_justification)
         }}
      </p>
   </v-alert>
</template>

<script>
export default {
   name: "JustificationRejection",

   props: {
      request: {
         type: Object,
      },
   },
};
</script>

<style scoped>
</style>