export default {
   namespaced: true,

   state: {
      currentHealthSecretary: null
   },

   getters: {
      getCurrentHealthSecretary(state) {
         return state.currentHealthSecretary;
      }
   },

   mutations: {
      setCurrentHealthSecretary(state, currentHealthSecretary) {
         state.currentHealthSecretary = currentHealthSecretary;
      }
   }
};
