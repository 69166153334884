<template>
   <div>
      <div class="attatchment-controls standard-padding-tiny">
         <p class="white--text text--center attachment-label-overflow ml-3">
            {{ attachment.content.name }}
         </p>

         <div class="d-flex align-center ma-n1">
            <v-btn
               v-if="preview"
               @click="openNewWidow(attachment)"
               class="ma-1"
               dark
               fab
               text
               small
            >
               <v-icon>mdi-open-in-new</v-icon>
            </v-btn>

            <v-btn
               @click="
                  download(attachment.content.data, attachment.content.name)
               "
               :loading="loadingDownload"
               class="ma-1"
               dark
               fab
               text
               small
            >
               <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-btn @click="closeOverlay" class="ma-1" dark fab text small>
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </div>
      </div>

      <div class="preview">
         <div v-if="preview">
            <!-- Visualizador de imagem -->
            <v-img
               v-if="attachment.content.type.includes('image')"
               @load="finishLoadingImage"
               :src="preview"
               height="80vh"
               width="100vw"
               contain
            >
               <template v-slot:placeholder>
                  <div
                     v-if="preview && loadingImagePreview"
                     class="loading-preview"
                  >
                     <p class="text-center title white--text">
                        Carregando o <br />
                        documento...
                     </p>
                  </div>
               </template>
            </v-img>

            <!-- Visualizador de pdf -->
            <div
               v-else-if="attachment.content.type.includes('pdf')"
               class="
                  pdf-out-div
                  d-flex
                  flex-column
                  justify-center
                  align-center
               "
            >
               <vue-pdf-embed
                  :page="null"
                  :source="preview"
                  class="pdf-content"
                  ref="vuepdfembed"
               />
            </div>
         </div>

         <div v-else-if="attachment.content.data && attachment.content.name">
            <p class="text-center primary--text">
               Nenhuma visualização disponível <br />
               baixe para visualizar!
            </p>

            <p class="text-center caption white--text mt-2">
               (Arquivo muito grande ou com visualizador indisponível)
            </p>

            <v-img
               :src="require('@/assets/images/image-not-preview.png')"
               max-height="95px"
               width="95px"
               class="mt-4 mx-auto"
               contain
            >
            </v-img>
         </div>
      </div>
   </div>
</template>

<script>
import FileSaver from "file-saver";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "AttachmentViewer",

   components: {
      VuePdfEmbed,
   },

   props: {
      attachment: {
         typeof: Object,
      },
   },

   data() {
      return {
         preview: null,

         loadingDownload: false,
         loadingImagePreview: false,
      };
   },

   watch: {
      attachment: {
         immediate: true,
         async handler(attachment) {
            this.preview = null;
            this.loadingImagePreview = false;

            if (
               attachment &&
               attachment.content &&
               attachment.content.size <= 15000000 &&
               attachment.content.data.length < 6000000
            ) {
               if (
                  attachment.content.name.toLowerCase().includes("jpg") ||
                  attachment.content.name.toLowerCase().includes("jpeg") ||
                  attachment.content.name.toLowerCase().includes("png") ||
                  attachment.content.name.toLowerCase().includes("tif") ||
                  attachment.content.name.toLowerCase().includes("bmp")
               ) {
                  this.loadingImagePreview = true;
                  this.preview = attachment.content.data;
               } else if (attachment.content.name.toLowerCase().includes("pdf"))
                  this.preview = encodeURI(attachment.content.data);
            }
         },
      },
   },

   methods: {
      closeOverlay() {
         this.$emit("onCloseOverlay");
      },

      finishLoadingImage() {
         this.loadingImagePreview = false;
      },

      download(file, fileName) {
         this.loadingDownload = true;

         new Promise((resolve) => {
            resolve(FileSaver.saveAs(file, fileName));
         })
            .catch(() =>
               snackBarUtil.showCustomSnackBar({
                  color: "error",
                  title: "ERROR AO BAIXAR",
                  icon: "mdi-download-off",
                  message:
                     "Error ao baixar o arquivo, por favor tente novamente.",
               })
            )
            .finally(() => (this.loadingDownload = false));
      },

      openNewWidow(attachment) {
         let newWindow = window.open("");
         let content;

         if (attachment.content.type.includes("image")) {
            content = `<img src='${this.preview}'>`;
         } else if (attachment.content.type.includes("pdf"))
            content = `<iframe width='100%' height='100%' frameBorder='0' src='${this.preview}'></iframe>`;

         newWindow.document.write(
            `
               <body style="margin: 0px; padding: 0px;">
                  ${content}
               </body>
               `
         );
      },
   },
};
</script>

<style scoped>
.pdf-out-div {
   margin-top: 64px;
   height: calc(100vh - 64px);
   width: 100vw;
   overflow: auto;
}

.pdf-out-div::-webkit-scrollbar {
   display: none;
}

.pdf-content {
   height: 100%;
   width: 50vw;
}

@media (max-width: 959px) {
   .pdf-content {
      width: 95vw;
   }
}

::v-deep .vue-pdf-embed > div {
   margin-bottom: 10px;
}

.attachment-label-overflow {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 65%;
}

.attatchment-controls {
   position: absolute;
   top: 0px;
   left: 0px;

   display: flex;
   justify-content: space-between;
   align-items: center;

   width: 100vw;
}

.preview {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100vh;
}

.loading-preview {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.loading-pdf {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: absolute;
   z-index: 1;
}
</style>