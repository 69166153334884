<template>
   <div>
      <v-chip
         :color="`${color}Light`"
         class="chip-ward-information caption"
         label
         dark
      >
         <p :class="`${color}--text`" class="d-none d-lg-flex mr-1">
            {{ label }}
         </p>

         <span :class="`${color}--text`" class="body-2 font-weight-bold">
            <strong>{{ statistic }}{{ percentage ? "%" : null }}</strong>
         </span>
      </v-chip>
   </div>
</template>

<script>
export default {
   name: "BedListWardHeaderStatistic",

   props: {
      color: {
         type: String,
         default: "success",
      },

      statistic: {
         type: Number,
         default: 0,
      },

      label: {
         type: String,
         default: "Livres",
      },

      percentage: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
.chip-ward-information {
   height: 28px !important;
}
</style>