var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"content-class":_vm.disableDialogScrolling ? 'dialog-overflow-hidden' : null,"max-width":1000,"transition":"slide-y-transition"},model:{value:(_vm.dialogBedFormVisibility),callback:function ($$v) {_vm.dialogBedFormVisibility=$$v},expression:"dialogBedFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeBedForm}}),(_vm.userStore.sub_type === 'healthunit_manager')?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('BedHealthUnitManagerForm',{staticClass:"standard-padding fullscreen-width",attrs:{"bed":_vm.bedSelected},on:{"onClickCancel":_vm.closeBedForm,"onCreatedBed":function($event){_vm.closeBedForm();
                  _vm.refreshBedList();},"onUpdatedBed":function($event){_vm.closeBedForm();
                  _vm.refreshBedList();},"onClickRemoveBed":_vm.onClickRemoveBed,"onFindDisabledBed":_vm.onFindDisabledBed}})],1):(
               _vm.userStore.sub_type === 'executor' ||
                  _vm.userStore.sub_type === 'solicitator'
            )?_c('div',[_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"id":"bed-dashboard-button"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bed ")]),_vm._v(" LEITO ")],1),(
                     _vm.bedSelected.status === 'occupied' ||
                        _vm.bedSelected.status === 'reserved'
                  )?_c('v-tab',{attrs:{"id":"request-bed-dashboard-button"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-document ")]),_vm._v(" SOLICITAÇÃO ")],1):_vm._e()],1),_c('div',[(_vm.activeTab === 0)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('BedOperatorForm',{staticClass:"standard-padding fullscreen-width",attrs:{"bed":_vm.bedSelected,"hideTitle":"","hideRemoveButton":""},on:{"onUpdatedBed":function($event){_vm.closeBedForm();
                        _vm.refreshBedList();}}})],1):(
                     _vm.activeTab === 1 &&
                        (_vm.bedSelected.status === 'waiting_reservation' ||
                           _vm.bedSelected.status === 'occupied' ||
                           _vm.bedSelected.status === 'reserved')
                  )?_c('div',[_c('BedRequest',{staticClass:"standard-padding",attrs:{"bed":_vm.bedSelected,"fullscreenParentDialog":_vm.isSmallerThan(599)},on:{"onOpenChat":_vm.onOpenChat,"onCloseChat":_vm.onCloseChat,"onUpdatedStatusBed":function($event){_vm.closeBedForm();
                        _vm.refreshBedList();},"onUpdatedStatusRequest":function($event){return _vm.refreshBedList()}}})],1):_vm._e()])],1):_vm._e()],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('HealthUnitStatistic',{attrs:{"statistics":_vm.generalStatistics,"healthUnit":_vm.operatorCurrentHealthUnitStore ||
            _vm.healthUnitManagerCurrentHealthUnitStore},on:{"onClickChangeHealthUnit":function($event){_vm.selectHealthUnitVisibilityStore = true}}}),_c('BedList',{ref:"bedlist",staticClass:"mt-12",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore ||
            _vm.healthUnitManagerCurrentHealthUnitStore,"showAddButton":_vm.showAddButton},on:{"onClickViewBed":_vm.onClickViewBed,"onClickAddBed":_vm.onClickAddBed,"onGenerateBedStatistics":_vm.onGenerateBedStatistics}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }