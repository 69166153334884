<template>
   <div>
      <div class="div-wrap ma-n2 w-100">
         <div class="operator-config ma-2">
            <v-select
               v-model="operatorSelected"
               @change="onChangeOperator"
               :items="operators"
               label="Operação"
               id="operator-form-input"
               class="width-operator"
            >
               <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                     <v-list-item-content
                        :id="`item-${attrs.id.split('-')[3]}`"
                     >
                        <v-list-item-title>
                           {{ item }}
                        </v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </v-select>
         </div>

         <div class="resource-config ma-2">
            <v-text-field
               v-model="resource"
               placeholder="Digite o recurso"
               class="body-2 width-operator"
            ></v-text-field>
         </div>

         <div class="success-config ma-2">
            <v-chip
               @click="changeSuccessValue(success)"
               :color="successChipConfig.color"
            >
               <v-icon left small>
                  {{ successChipConfig.icon }}
               </v-icon>

               <p>
                  {{ successChipConfig.text }}
               </p>
            </v-chip>
         </div>
      </div>
   </div>
</template>

<script>
const SEARCH_DELAY = 800;

export default {
   name: "ResourceOperatorAndSuccessFilter",

   data: function () {
      return {
         operatorSelected: null,
         operators: ["POST", "GET", "PUT", "PATCH", "DELETE"],

         resource: "",
         resourceTimeout: null,

         success: "both",
      };
   },

   watch: {
      resource(newFilter, oldFilter) {
         if (
            newFilter === null ||
            (newFilter === "" && oldFilter === null) ||
            newFilter === oldFilter ||
            (newFilter.length > 0 && newFilter.replace(/ /g, "") === "")
         )
            return;

         clearTimeout(this.resourceTimeout);
         this.resourceTimeout = setTimeout(() => {
            this.onChangeFilter(
               this.resource,
               this.success,
               this.operatorSelected
            );
         }, SEARCH_DELAY);
      },

      success() {
         this.onChangeFilter(
            this.resource,
            this.success,
            this.operatorSelected
         );
      },
   },

   computed: {
      successChipConfig() {
         let config = {
            color: "primary",
            icon: "mdi-minus-box-outline",
            text: "Todas",
         };

         if (this.success === "yes") {
            config.color = "success";
            config.icon = "mdi-check-circle";
            config.text = "Sucesso";
         } else if (this.success === "no") {
            config.color = "error";
            config.icon = "mdi-close-circle";
            config.text = "Sem sucesso";
         }

         return config;
      },
   },

   methods: {
      reset() {
         this.operatorSelected = null;
         this.resource = "";
         this.success = "both";
      },

      onChangeOperator() {
         this.onChangeFilter(
            this.resource,
            this.success,
            this.operatorSelected
         );
      },

      changeSuccessValue(value) {
         switch (value) {
            case "yes":
               this.success = "no";
               break;
            case "no":
               this.success = "both";
               break;
            case "both":
               this.success = "yes";
               break;
         }
      },

      onChangeFilter(resource, success, operator) {
         let filter = [];

         if (success !== "both") {
            filter.push({
               attribute: "success",
               value: success,
            });
         }

         if (resource.replace(/ /g, "") !== "") {
            filter.push({
               attribute: "request.resource",
               value: resource,
            });
         }

         if (operator) {
            filter.push({
               attribute: "request.operator",
               value: operator,
            });
         }

         this.$emit("onFilter", filter);
      },
   },
};
</script>

<style scoped>
.div-wrap {
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
}

@media (max-width: 959px) {
   .div-wrap {
      flex-wrap: wrap;
   }
}

.operator-config {
   min-width: 20%;
}

@media (max-width: 959px) {
   .operator-config {
      width: 100%;
   }
}

.resource-config {
   width: 100%;
}
</style>