<template>
   <div>
      <v-dialog
         v-model="dialogBedFormVisibility"
         :fullscreen="isSmallerThan(599)"
         :content-class="
            disableDialogScrolling ? 'dialog-overflow-hidden' : null
         "
         :max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeBedForm" />

            <!-- Visualização caso o usuário seja um gestor da unidade de saúde -->
            <div
               v-if="userStore.sub_type === 'healthunit_manager'"
               class="d-flex justify-center align-center"
            >
               <BedHealthUnitManagerForm
                  @onClickCancel="closeBedForm"
                  @onCreatedBed="
                     closeBedForm();
                     refreshBedList();
                  "
                  @onUpdatedBed="
                     closeBedForm();
                     refreshBedList();
                  "
                  @onClickRemoveBed="onClickRemoveBed"
                  @onFindDisabledBed="onFindDisabledBed"
                  :bed="bedSelected"
                  class="standard-padding fullscreen-width"
               />
            </div>

            <!-- Visualização caso o usuário seja um operador -->
            <div
               v-else-if="
                  userStore.sub_type === 'executor' ||
                     userStore.sub_type === 'solicitator'
               "
            >
               <v-tabs v-model="activeTab" centered show-arrows>
                  <v-tab id="bed-dashboard-button">
                     <v-icon left> mdi-bed </v-icon>
                     LEITO
                  </v-tab>
                  <v-tab
                     v-if="
                        bedSelected.status === 'occupied' ||
                           bedSelected.status === 'reserved'
                     "
                     id="request-bed-dashboard-button"
                  >
                     <v-icon left> mdi-file-document </v-icon>
                     SOLICITAÇÃO
                  </v-tab>
               </v-tabs>

               <div>
                  <div
                     v-if="activeTab === 0"
                     class="d-flex justify-center align-center"
                  >
                     <BedOperatorForm
                        @onUpdatedBed="
                           closeBedForm();
                           refreshBedList();
                        "
                        :bed="bedSelected"
                        class="standard-padding fullscreen-width"
                        hideTitle
                        hideRemoveButton
                     />
                  </div>

                  <div
                     v-else-if="
                        activeTab === 1 &&
                           (bedSelected.status === 'waiting_reservation' ||
                              bedSelected.status === 'occupied' ||
                              bedSelected.status === 'reserved')
                     "
                  >
                     <BedRequest
                        @onOpenChat="onOpenChat"
                        @onCloseChat="onCloseChat"
                        @onUpdatedStatusBed="
                           closeBedForm();
                           refreshBedList();
                        "
                        @onUpdatedStatusRequest="refreshBedList()"
                        :bed="bedSelected"
                        :fullscreenParentDialog="isSmallerThan(599)"
                        class="standard-padding"
                     />
                  </div>
               </div>
            </div>
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <HealthUnitStatistic
         @onClickChangeHealthUnit="selectHealthUnitVisibilityStore = true"
         :statistics="generalStatistics"
         :healthUnit="
            operatorCurrentHealthUnitStore ||
               healthUnitManagerCurrentHealthUnitStore
         "
      />

      <BedList
         @onClickViewBed="onClickViewBed"
         @onClickAddBed="onClickAddBed"
         @onGenerateBedStatistics="onGenerateBedStatistics"
         :healthUnit="
            operatorCurrentHealthUnitStore ||
               healthUnitManagerCurrentHealthUnitStore
         "
         :showAddButton="showAddButton"
         ref="bedlist"
         class="mt-12"
      />
   </div>
</template>

<script>
import Close from "../../components/base/Close";
import BedRequest from "../../components/bed/bedRequest/BedRequest";
import HealthUnitStatistic from "../../components/bed/statistics/HealthUnitStatistic";
import PageTitle from "../../components/base/PageTitle";
import BedList from "../../components/bed/list/BedList";
import BedOperatorForm from "../../components/bed/form/BedOperatorForm";
import BedHealthUnitManagerForm from "../../components/bed/form/BedHealthUnitManagerForm";
import responsiveUtil from "../../utils/responsiveUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import bedService from "services/bedService";
import snackBarUtil from "utils/snackBarUtil";

//FIXME01:
/* Por enquanto o BedList fará a consulta e em seguida passa os dados via emit para o HealthUnitStatistic,
futuramente o próprio component HealthUnitStatistic terá uma rota específica e fará a consulta da parte de estatísticas. */

export default {
   name: "BedDashboard",

   components: {
      Close,
      BedRequest,
      PageTitle,
      HealthUnitStatistic,
      BedList,
      BedOperatorForm,
      BedHealthUnitManagerForm
   },

   data: function() {
      return {
         bedSelected: {},

         dialogBedFormVisibility: false,

         generalStatistics: {
            freeBedCount: null,
            blockedBedCount: null,
            occupiedBedCount: null,
            waitingReservationCount: null,
            reservedBedCount: null,
            totalOccupancyPercentage: null
         },

         activeTab: 0,

         disableDialogScrolling: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      },

      operatorCurrentHealthUnitStore: {
         get() {
            return this.$store.getters["operator/getCurrentHealthUnit"];
         },

         set(value) {
            this.$store.commit("operator/setCurrentHealthUnit", value);
         }
      },

      healthUnitManagerCurrentHealthUnitStore: {
         get() {
            return this.$store.getters[
               "healthUnitManager/getCurrentHealthUnit"
            ];
         },

         set(value) {
            this.$store.commit("healthUnitManager/setCurrentHealthUnit", value);
         }
      },

      selectHealthUnitVisibilityStore: {
         get() {
            return this.$store.getters["selectHealthUnit/getVisibility"];
         },

         set(value) {
            this.$store.commit("selectHealthUnit/setVisibility", value);
         }
      },

      showAddButton() {
         return (
            this.userStore.sub_type === "healthunit_manager" &&
            this.healthUnitManagerCurrentHealthUnitStore &&
            this.healthUnitManagerCurrentHealthUnitStore.id &&
            this.healthUnitManagerCurrentHealthUnitStore.capabilities
               .can_have_beds
         );
      }
   },

   watch: {
      dialogBedFormVisibility() {
         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      //FIXME01:
      /* Isso aqui no futuro vai ser removido, já que o próprio component HealthUnitStatistic
      terá uma rota específica para ele. */
      onGenerateBedStatistics(generalStatistics) {
         this.generalStatistics = generalStatistics;
      },

      refreshBedList() {
         this.$refs.bedlist.refreshAllHealthUnitWardsByRef();
      },

      onClickAddBed(bed) {
         this.bedSelected = Object.assign({}, bed);
         this.openBedForm();
      },

      onClickViewBed(bed) {
         this.bedSelected = Object.assign({}, bed);
         this.openBedForm();
      },

      onClickRemoveBed(item) {
         const methodDeleteItem = async () => {
            await bedService.activeBed(item.id, false);

            this.dialogBedFormVisibility = false;
            this.refreshBedList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O leito foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse leito?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledBed(item) {
         const methodActiveItem = async () => {
            await bedService.activeBed(item.id, true);

            this.refreshBedList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O leito foi ativado!"
            });
         };

         this.dialogBedFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "LEITO JÁ EXISTENTE",
            message:
               "Um leito desativado com esse NOME foi encontrado, deseja ativá-lo?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      openBedForm() {
         this.dialogBedFormVisibility = true;
         this.activeTab = 0;
      },

      closeBedForm() {
         this.dialogBedFormVisibility = false;
      },

      onOpenChat() {
         this.disableDialogScrolling = true;
      },

      onCloseChat() {
         this.disableDialogScrolling = false;
      }
   },

   /* Toda vez ao exibir essa view é verificado se já existe o ID da unidade de saúde que essa pessoa deseja acessar,
   caso não exista, a tela de escolha da unidade de saúde é exibida, caso exista não será aberta a tela de escolha. */
   created() {
      switch (this.userStore.sub_type) {
         case "executor":
            if (
               !(
                  this.operatorCurrentHealthUnitStore &&
                  this.operatorCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;

         case "healthunit_manager":
            if (
               !(
                  this.healthUnitManagerCurrentHealthUnitStore &&
                  this.healthUnitManagerCurrentHealthUnitStore.id
               )
            )
               this.selectHealthUnitVisibilityStore = true;
            break;
      }
   }
};
</script>

<style scoped>
::v-deep .dialog-overflow-hidden {
   overflow-y: hidden !important;
}

.fullscreen-width {
   width: 500px !important;
   max-width: 500px !important;
}
</style>
