<template>
   <div>
      <FormTitle icon="mdi-lock-reset" title="REDEFINIR SENHA" />

      <v-alert
         v-if="user && user.name"
         class="body-2 font-weight-bold mb-5"
         color="primary"
         icon="mdi-account"
         border="left"
         dense
         text
         >{{ user.name }}
      </v-alert>

      <v-form ref="form">
         <v-card flat>
            <v-row>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.new_password"
                     @click:append="showNewPassword = !showNewPassword"
                     :rules="[
                        (value) => !!value || 'Nova senha obrigatória.',
                        (value) =>
                           passwordValidation(value) ||
                           'Nova senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                     ]"
                     :type="showNewPassword ? 'text' : 'password'"
                     :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                     id="new-password-form-input"
                     label="Nova senha*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12">
                  <v-text-field
                     v-model="formPassword.confirm_password"
                     @click:append="showConfirmPassword = !showConfirmPassword"
                     :rules="[
                        (value) =>
                           !!value || 'Confirmação da nova senha obrigatória.',
                        (value) =>
                           value === formPassword.new_password ||
                           'Confirmação da nova senha e a nova senha são diferentes.',
                     ]"
                     :type="showConfirmPassword ? 'text' : 'password'"
                     :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                     "
                     id="confirm-new-password-form-input"
                     label="Confirmação da nova senha*"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSavePassword"
         :loadingSave="loadingResetPassword"
         class="mt-8"
         mode="save"
      />
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle";
import FormActions from "components/base/form/FormActions";
import validationUtil from "utils/validationUtil";
import userService from "services/userService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "ResetPasswordUsersForm",

   components: { FormTitle, FormActions },

   props: {
      user: {
         type: Object,
      },
   },

   data: function () {
      return {
         formPassword: {},

         showNewPassword: false,
         showConfirmPassword: false,

         loadingResetPassword: false,
      };
   },

   watch: {
      user: {
         immediate: true,
         handler() {
            this.formPassword = {};
            this.resetValidation();
         },
      },
   },

   methods: {
      ...validationUtil,

      executeValidation() {
         if (this.$refs.form) return this.$refs.form.validate();
         else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickSavePassword() {
         if (this.user)
            this.resetPassword(this.user.id, this.formPassword.new_password);
      },

      async resetPassword(userId, password) {
         if (!this.executeValidation() || !userId || !password) return;

         this.loadingResetPassword = true;

         try {
            await userService.resetPassword(userId, password);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A senha do usuário foi redefinida!",
            });

            this.$emit("onResetPassword");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingResetPassword = false;
         }
      },
   },
};
</script>

<style>
</style>