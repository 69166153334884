<template>
   <v-chip-group v-if="request.id" column dark>
      <input type="hidden" id="request-code" :value="request.code" />

      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
            <v-chip
               v-bind="attrs"
               v-on="on"
               @click="copyValueClipboard('request-code')"
               color="primary"
               label
            >
               Registro:

               <p class="ml-1">
                  <strong>
                     {{ request.code }}
                  </strong>
               </p>

               <v-icon right small>mdi-content-copy</v-icon>
            </v-chip>
         </template>
         <span>
            Copiar registro para <br />
            área de transferência
         </span>
      </v-tooltip>

      <v-chip
         v-if="request.pb_heart"
         color="red"
         class="disable-click"
         label
      >
         <v-icon left small>$coracao-paraibano</v-icon>
         Coração paraíbano
      </v-chip>

      <v-chip
         :color="generateState(request.status).color"
         class="disable-click"
         label
         id="request_status_color-request-sumary-input"
      >
         Estado solicitação:

         <p class="ml-1">
            <strong>
               {{ generateState(request.status).text }}
            </strong>
         </p>
      </v-chip>

      <v-chip
         v-if="request.bed_id"
         color="success"
         class="disable-click"
         label
         id="bed_id-request-sumary-input"
      >
         Leito reservado
         <v-icon right small>mdi-bed</v-icon>
      </v-chip>
   </v-chip-group>
</template>

<script>
import util from "../../../../utils/util";

export default {
   name: "RequestSummary",

   props: {
      request: {
         type: Object,
         default: function() {
            return {};
         }
      }
   },

   methods: {
      ...util
   }
};
</script>

<style scoped>
.disable-click {
   pointer-events: none;
}
</style>
