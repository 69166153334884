<template>
   <v-card
      :color="color"
      class="standard-padding-x-small d-flex justify-space-between align-center"
      height="100%"
      dark
      flat
   >
      <div>
         <v-card class="circle" height="60" width="60" flat>
            <v-icon large>{{ icon }}</v-icon>
         </v-card>
      </div>

      <div>
         <v-skeleton-loader
            v-if="loadingStatistic"
            class="skeleton-loader-statistic mb-1"
            type="image"
            width="100%"
            height="42px"
         ></v-skeleton-loader>

         <v-text-field
            v-else-if="editMode"
            v-maska="'########'"
            v-model.number="statisticEdited"
            :placeholder="statistic.toString()"
            :rules="[(value) => value !== null || '']"
            id="available-surgery-total-input"
            class="display-2"
            type="tel"
            autofocus
            color="transparent"
            solo
            flat
         ></v-text-field>

         <p v-else class="display-2 text-end mb-n1">
            {{ statistic }}{{ percentage ? "%" : null }}
         </p>
         <p class="caption text-end">{{ label }}</p>
      </div>
   </v-card>
</template>

<script>
export default {
   name: "SurgeryTypeStatisticCard",

   props: {
      color: {
         type: String,
         default: "success",
      },

      icon: {
         type: String,
         default: "mdi-bed-empty",
      },

      statistic: {
         type: Number,
         default: 0,
      },

      label: {
         type: String,
         default: "Leitos livres",
      },

      percentage: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingStatistic: true,

         statisticEdited: null,
      };
   },

   watch: {
      statistic: {
         immediate: true,
         handler(statistic) {
            this.statisticEdited = null;
            this.loadingStatistic = true;
            if (statistic !== null) this.loadingStatistic = false;
         },
      },
   },
};
</script>

<style scoped>
.circle {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50% !important;
   background-color: rgba(255, 255, 255, 0.4);
}

.skeleton-loader-statistic {
   background-color: rgba(255, 255, 255, 0.7);
   border-radius: 20px;
}

::v-deep .v-input {
   height: 43px;
}

::v-deep .v-input input {
   max-height: 50px;
   outline: none !important;
   text-align: end !important;
}

::v-deep .v-input__slot {
   background: transparent !important;
   padding: 0px !important;
}

::v-deep ::placeholder {
   color: white;
   opacity: 0.5;
}
</style>