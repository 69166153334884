<template>
   <div>
      <v-dialog
         v-model="dialogSelectMunicipalityVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="800"
         transition="slide-y-transition"
         persistent
      >
         <v-card :tile="isSmallerThan(599)">
            <SelectMunicipality
               @onSelectMunicipality="onSelectMunicipality"
               class="standard-padding"
               alertMessage="Selecione o município que deseja visualizar seus respectivos hospitais."
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogHealthUnitFormVisibility"
         :fullscreen="isSmallerThan(599) || userStore.sub_type === 'admin'"
         :persistent="userStore.sub_type === 'admin'"
         no-click-animation
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeHealthUnitForm" />

            <!-- Listagem exibida para o Administrador -->
            <div v-if="userStore.sub_type === 'admin'">
               <v-tabs v-model="activeTab" centered show-arrows>
                  <v-tab>
                     <v-icon small left>mdi-hospital-building</v-icon>
                     UNIDADE DE SAÚDE
                  </v-tab>
                  <v-tab v-if="healthUnitSelected.id">
                     <v-icon small left>mdi-box-cutter</v-icon>
                     CIRURGIAS
                  </v-tab>
               </v-tabs>

               <div
                  v-if="activeTab === 0"
                  class="d-flex justify-center align-center"
               >
                  <v-card width="1000" flat>
                     <HealthUnitForm
                        @onClickCancel="closeHealthUnitForm"
                        @onCreatedHealthUnit="
                           closeHealthUnitForm();
                           refreshHealthUnitList();
                        "
                        @onUpdatedHealthUnit="
                           closeHealthUnitForm();
                           refreshHealthUnitList();
                        "
                        @onClickRemoveHealthUnit="onClickRemoveHealthUnit"
                        @onFindDisabledHealthUnit="onFindDisabledHealthUnit"
                        :healthUnit="healthUnitSelected"
                        :municipality="municipalitySelected"
                        :editMode="editMode"
                        hideTitle
                        class="standard-padding"
                     />
                  </v-card>
               </div>
               <div
                  v-if="healthUnitSelected.id && activeTab === 1"
                  class="d-flex justify-center align-center"
               >
                  <v-dialog
                     v-model="dialogSurgeryTypeFormVisibility"
                     :fullscreen="isSmallerThan(599)"
                     max-width="1000"
                     transition="slide-y-transition"
                  >
                     <v-card :tile="isSmallerThan(599)" id="scrollTop">
                        <Close @onClickClose="closeSurgeryTypeForm" />

                        <SurgeryTypeForm
                           @onClickCancel="closeSurgeryTypeForm"
                           @onCreatedSurgeryType="
                              closeSurgeryTypeForm();
                              refreshSurgeryTypeList();
                           "
                           @onUpdatedSurgeryType="
                              closeSurgeryTypeForm();
                              refreshSurgeryTypeList();
                           "
                           :surgeryType="surgeryTypeSelected"
                           :editMode="surgeryTypeEditMode"
                           :healthUnit="healthUnitSelected"
                           :hideEditButton="
                              !healthUnitSelected.capabilities
                                 .can_offer_surgeries
                           "
                           class="standard-padding"
                           hideRemoveButton
                        />
                     </v-card>
                  </v-dialog>

                  <v-card width="1400" flat>
                     <SurgeryTypeList
                        @onClickAddSurgeryType="onClickAddSurgeryType"
                        @onClickViewSurgeryType="onClickViewSurgeryType"
                        @onClickEditSurgeryType="onClickEditSurgeryType"
                        :healthUnit="healthUnitSelected"
                        class="standard-padding-whopping pt-0"
                        listElevation="elevation-0"
                        ref="surgerytypelist"
                        hideRemoveButton
                        hideAddButton
                     />
                  </v-card>
               </div>
            </div>

            <!-- Listagem exibida para os outros usuários -->
            <HealthUnitForm
               v-else
               @onClickCancel="closeHealthUnitForm"
               @onCreatedHealthUnit="
                  closeHealthUnitForm();
                  refreshHealthUnitList();
               "
               @onUpdatedHealthUnit="
                  closeHealthUnitForm();
                  refreshHealthUnitList();
               "
               @onClickRemoveHealthUnit="onClickRemoveHealthUnit"
               @onFindDisabledHealthUnit="onFindDisabledHealthUnit"
               :healthUnit="healthUnitSelected"
               :municipality="municipalitySelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <v-expand-transition>
         <v-alert
            v-if="municipalitySelected.id"
            class="mt-12 mb-n4"
            color="primary"
            type="info"
            icon="mdi-map"
            border="left"
            text
         >
            <div class="d-flex justify-space-between align-center">
               <p>
                  Você está visualizando as unidade de saúde de
                  <strong>{{ municipalitySelected.name }}</strong
                  >.
               </p>

               <v-btn
                  @click="openSelectMunicipality"
                  class="d-none d-md-flex"
                  color="primary"
                  small
                  text
                  >Alterar município
               </v-btn>

               <v-btn
                  @click="openSelectMunicipality"
                  class="d-flex d-md-none"
                  color="primary"
                  fab
                  text
                  x-small
               >
                  <v-icon>mdi-cached</v-icon>
               </v-btn>
            </div>
         </v-alert>
      </v-expand-transition>

      <HealthUnitList
         @onClickAddHealthUnit="onClickAddHealthUnit"
         @onClickViewHealthUnit="onClickViewHealthUnit"
         @onClickEditHealthUnit="onClickEditHealthUnit"
         @onClickRemoveHealthUnit="onClickRemoveHealthUnit"
         :municipality="municipalitySelected"
         ref="healthUnitlist"
         class="mt-12"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import HealthUnitList from "../../components/healthUnit/HealthUnitList";
import HealthUnitForm from "../../components/healthUnit/HealthUnitForm";
import SelectMunicipality from "../../components/municipality/selectMunicipality/SelectMunicipality";
import SurgeryTypeList from "components/surgeryType/SurgeryTypeList";
import SurgeryTypeForm from "components/surgeryType/SurgeryTypeForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import healthUnitService from "../../services/healthUnitService";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";

export default {
   name: "HealthUnit",

   components: {
      Close,
      PageTitle,
      HealthUnitList,
      HealthUnitForm,
      SelectMunicipality,
      SurgeryTypeList,
      SurgeryTypeForm
   },

   data: function() {
      return {
         healthUnitSelected: {},
         municipalitySelected: {},

         editMode: false,

         dialogHealthUnitFormVisibility: false,
         dialogSurgeryTypeFormVisibility: false,
         dialogSelectMunicipalityVisibility: true,

         surgeryTypeSelected: {},
         surgeryTypeEditMode: false,

         activeTab: 0
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogHealthUnitFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      dialogSurgeryTypeFormVisibility(val) {
         if (!val) this.surgeryTypeEditMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
         this.openHealthUnitForm();
      },

      onClickViewHealthUnit(healthUnit) {
         this.healthUnitSelected = Object.assign({}, healthUnit);
         this.openHealthUnitForm();
      },

      onClickEditHealthUnit() {
         this.editMode = true;
      },

      onClickRemoveHealthUnit(item) {
         const methodDeleteItem = async () => {
            await healthUnitService.activeHealthUnit(item.id, false);

            this.dialogHealthUnitFormVisibility = false;
            this.refreshHealthUnitList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A unidade de saúde foi excluída!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir essa unidade de saúde?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledHealthUnit(item) {
         const methodActiveItem = async () => {
            await healthUnitService.activeHealthUnit(item.id, true);

            this.refreshHealthUnitList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A unidade de saúde foi ativada!"
            });
         };

         this.dialogHealthUnitFormVisibility = false;

         snackBarUtil.showCustomSnackBar({
            color: "info",
            title: "UNIDADE DE SAÚDE JÁ EXISTENTE",
            message:
               "Uma unidade de saúde desativada com esse NOME foi encontrada, deseja ativá-la?",
            action: {
               text: "Confirmar",
               method: methodActiveItem,
               closeOnFinished: false
            }
         });
      },

      openSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = true;
      },

      closeSelectMunicipality() {
         this.dialogSelectMunicipalityVisibility = false;
      },

      onSelectMunicipality(municipality) {
         if (municipality && municipality.id !== this.municipalitySelected.id)
            this.municipalitySelected = municipality;
         this.closeSelectMunicipality();
      },

      openHealthUnitForm() {
         if (this.userStore.sub_type === "admin") this.activeTab = 0;
         this.dialogHealthUnitFormVisibility = true;
      },

      closeHealthUnitForm() {
         this.dialogHealthUnitFormVisibility = false;
      },

      refreshHealthUnitList() {
         this.$refs.healthUnitlist.refreshAllHealthUnitsByRef();
      },

      /* Métodos relacionados a aba de Cirurgias. */
      openSurgeryTypeForm() {
         this.dialogSurgeryTypeFormVisibility = true;
      },

      closeSurgeryTypeForm() {
         this.dialogSurgeryTypeFormVisibility = false;
      },

      onClickAddSurgeryType(surgeryType) {
         this.surgeryTypeSelected = Object.assign({}, surgeryType);
         this.openSurgeryTypeForm();
      },

      onClickViewSurgeryType(surgeryType) {
         this.surgeryTypeSelected = Object.assign({}, surgeryType);
         this.openSurgeryTypeForm();
      },

      onClickEditSurgeryType() {
         this.surgeryTypeEditMode = true;
      },

      refreshSurgeryTypeList() {
         this.$refs.surgerytypelist.refreshAllHelthUnitSurgeryTypesByRef();
      }
   }
};
</script>

<style scoped></style>
