<template>
   <div>
      <FormTitle icon="mdi-box-cutter" title="CIRURGIA" />

      <v-alert
         v-if="getMode() === 'read' && formSurgeryType.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Cirurgia cadastrada em
         {{ formatDate(formSurgeryType.created_at, "DD/MM/YYYY [às] HH:mm") }}.
      </v-alert>

      <v-form ref="form" :readonly="!!surgeryType.id">
         <v-card :disabled="!!surgeryType.id" flat>
            <v-row v-if="surgeryType.id" class="mt-5">
               <v-col cols="12" md="9">
                  <v-text-field
                     v-model="formSurgeryType.name"
                     label="Nome"
                     id="name-surgeryType-form-input"
                     readonly
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formSurgeryType.sus_code"
                     :rules="[(value) => !!value || 'Código SUS obrigatório.']"
                     label="Código SUS*"
                     id="sus_code-surgeryType-form-input"
                     readonly
                  ></v-text-field>
               </v-col>
            </v-row>

            <div v-else>
               <v-row class="mt-5">
                  <v-col cols="12">
                     <SurgerieInput
                        @onSelectSurgerie="onSelectSurgerie"
                        ref="surgerytypeinput"
                     />
                  </v-col>
               </v-row>

               <v-row class="mt-5">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model.number="formSurgeryType.total"
                        :rules="[(value) => !!value || 'Total obrigatório.']"
                        label="Total disponível*"
                        id="total-surgeryType-form-input"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </div>
         </v-card>
      </v-form>

      <v-form v-if="formSurgeryType.id" ref="formUpdate">
         <v-row class="mt-3">
            <v-col cols="12" md="4">
               <SurgeryTypeStatisticCard
                  :statistic="formSurgeryType.total"
                  icon="mdi-plus"
                  label="Cirurgias cadastradas"
                  color="primary"
               />
            </v-col>

            <v-col v-if="formSurgeryType.id" cols="12" md="4">
               <SurgeryTypeStatisticCard
                  :statistic="formSurgeryType.answered"
                  icon="mdi-check"
                  label="Cirurgias atendidas"
                  color="success"
               />
            </v-col>

            <v-col v-if="formSurgeryType.id" cols="12" md="4">
               <SurgeryTypeStatisticCard
                  :statistic="formSurgeryType.total - formSurgeryType.answered"
                  :editMode="getMode() === 'save'"
                  :color="
                     colorByTotal(
                        formSurgeryType.total - formSurgeryType.answered
                     )
                  "
                  ref="availablesurgeriesinput"
                  icon="mdi-medical-bag"
                  label="Cirurgias disponíveis"
               />
            </v-col>
         </v-row>
      </v-form>

      <SurgeryTypeFormActions
         @onClickEdit="onClickEditSurgeryType"
         @onClickRemove="onClickRemoveSurgeryType"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveSurgeryType"
         :validationErrors="validationErrors"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveSurgeryType"
         class="mt-9"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import SurgerieInput from "components/surgerie/SurgerieInput";
import SurgeryTypeStatisticCard from "./SurgeryTypeStatisticCard";
import SurgeryTypeFormActions from "./SurgeryTypeFormActions";
import formatUtil from "../../utils/formatUtil";
import surgeryTypeService from "../../services/surgeryTypeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "SurgeryTypeForm",

   components: {
      FormTitle,
      SurgeryTypeFormActions,
      SurgerieInput,
      SurgeryTypeStatisticCard,
   },

   props: {
      surgeryType: {
         type: Object,
      },

      healthUnit: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formSurgeryType: {},

         loadingSaveSurgeryType: false,
         loadingSurgeryTypeByName: false,

         validationErrors: [],

         invalidType: false,
      };
   },

   watch: {
      surgeryType: {
         immediate: true,
         handler(surgeryType) {
            if (surgeryType.id && !this.editMode) this.setMode("read");
            else this.setMode("save");
            
            this.validationErrors = [];

            this.formSurgeryType = this.formatObjectToView(surgeryType);

            // Resetar o valor para o update
            if (this.$refs.availablesurgeriesinput) this.$refs.availablesurgeriesinput.statisticEdited = null;
            this.resetValidation();
         },
      },
   },

   methods: {
      ...formatUtil,

      formatObjectToView(surgeryType) {
         let surgeryTypeTemp = util.mergeObject({}, surgeryType);

         return surgeryTypeTemp;
      },

      formatObjectToSend(surgeryType) {
         let surgeryTypeTemp = util.removeBlankAttributes(surgeryType);

         return surgeryTypeTemp;
      },

      onClickEditSurgeryType() {
         this.setMode("save");
      },

      onClickRemoveSurgeryType() {
         //
      },

      onClickCancel() {
         this.setMode("read");
         this.formSurgeryType = this.formatObjectToView(this.surgeryType);
         this.$emit("onClickCancel");
      },

      onClickSaveSurgeryType() {
         if (this.formSurgeryType.id)
            this.updateHealthUnitSurgeryType(
               this.healthUnit.id,
               this.formSurgeryType.id,
               this.formSurgeryType
            );
         else
            this.createHealthUnitSurgeryType(
               this.healthUnit.id,
               this.formSurgeryType
            );
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      executeUpdateValidation() {
         if (this.$refs.formUpdate)  {
            this.validationErrors = this.$refs.formUpdate.inputs[0].hasError ? ["Cirurgias disponíveis"] : [];
            return this.$refs.formUpdate.validate();
         }
         else return false;
      },

      resetValidation() {
         this.invalidType = false;

         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onSelectSurgerie(surgerie) {
         this.formSurgeryType.sus_code = surgerie.sus_code;
      },

      colorByTotal(total) {
         let color;

         if (total >= 300) color = "info";
         else if (total >= 100) color = "success";
         else if (total >= 50) color = "amber";
         else if (total >= 10) color = "warning";
         else color = "error";

         return color;
      },

      async createHealthUnitSurgeryType(healthUnitId, surgeryType) {
         if (!this.executeValidation() || !healthUnitId || !surgeryType) return;

         this.loadingSaveSurgeryType = true;

         try {
            await surgeryTypeService.createHealthUnitSurgeryType(
               healthUnitId,
               this.formatObjectToSend(surgeryType)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreatedSurgeryType");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveSurgeryType = false;
         }
      },

      async updateHealthUnitSurgeryType(
         healthUnitId,
         surgeryTypeId,
         surgeryType
      ) {
         if (
            !this.executeUpdateValidation() ||
            !healthUnitId ||
            !surgeryTypeId ||
            !surgeryType
         )
            return;

         this.loadingSaveSurgeryType = true;

         /* 1. Recupera o novo valor de cirurgias disponíveis */
         let surgeryTypeTemp = util.cloneObject(surgeryType);
         if (
            this.$refs.availablesurgeriesinput &&
            this.$refs.availablesurgeriesinput.statisticEdited !== null
         )
            surgeryTypeTemp.total =
               parseInt(this.$refs.availablesurgeriesinput.statisticEdited) +
               parseInt(surgeryTypeTemp.answered);

         try {
            await surgeryTypeService.updateHealthUnitSurgeryType(
               healthUnitId,
               surgeryTypeId,
               this.formatObjectToSend(surgeryTypeTemp)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedSurgeryType");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveSurgeryType = false;
         }
      },
   },
};
</script>

<style scoped>
</style>