<template>
   <div>
      <p class="body-1 font-weight-medium mt-5">
         Justificativa de rejeição da cirurgia
      </p>

      <p class="body-2 font-weight-low mt-4">Motivo da rejeição*</p>

      <v-chip-group
         v-model="formJustification.cancellation_reason"
         @change="(value) => (invalidReason = !value)"
         column
      >
         <v-chip
            :color="
               formJustification.cancellation_reason ===
               'cancellation_by_health_entity'
                  ? 'primary'
                  : 'normal'
            "
            :value="'cancellation_by_health_entity'"
            id="available-surgery-chip"
            filter
            small
         >
            Cancelamento
         </v-chip>
         <v-chip
            :color="
               formJustification.cancellation_reason === 'patient_absence'
                  ? 'primary'
                  : 'normal'
            "
            :value="'patient_absence'"
            id="unavailable-surgery-chip"
            filter
            small
         >
            Falta
         </v-chip>
      </v-chip-group>

      <v-expand-transition>
         <p
            v-if="invalidReason"
            class="caption error--text"
            id="required-priority-doctor-opnion-form-text"
         >
            Motivo da rejeição obrigatória.
         </p>
      </v-expand-transition>

      <v-form ref="form">
         <v-row class="mt-2">
            <v-col cols="12">
               <v-textarea
                  v-model="formJustification.status_justification"
                  :rules="[(value) => !!value || 'Justificativa obrigatória.']"
                  class="justification-height"
                  label="Justificativa*"
                  id="status_justification-bed-request-reserve-cancel-form-input"
                  rows="8"
                  no-resize
               ></v-textarea>
            </v-col>
         </v-row>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickCancelSurgery"
         :loadingSave="loadingSaveJustification"
         :mode="'save'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormActions from "components/base/form/FormActions";
import snackBarUtil from "utils/snackBarUtil";
import util from "utils/util";
import surgerieService from "services/surgerieService";

export default {
   name: "SurgeryCancelForm",

   components: { FormActions },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         invalidReason: false,

         formJustification: {
            status: "canceled",
            cancellation_reason: "",
         },

         loadingSaveJustification: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler() {
            this.formJustification = {
               status: "canceled",
               cancellation_reason: "",
            };

            this.resetValidation();
         },
      },
   },

   methods: {
      formatObjectToSend(justification) {
         let justificationTemp = util.removeBlankAttributes(justification);

         return justificationTemp;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.$refs.form.validate();
            this.invalidReason = !this.formJustification.cancellation_reason;

            return this.$refs.form.validate() && !this.invalidReason;
         }

         return false;
      },

      resetValidation() {
         if (this.$refs.form) {
            this.invalidReason = false;
            this.$refs.form.resetValidation();
         }
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickCancelSurgery() {
         if (
            this.request &&
            this.request.id &&
            this.request.source_health_entity &&
            this.request.source_health_entity.id
         )
            this.updateStatusHealthUnitSurgerie(
               this.request.target_health_unit.id,
               this.request.surgery.id,
               this.formJustification
            );
      },

      async updateStatusHealthUnitSurgerie(healthUnitId, surgerieId, status) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !surgerieId ||
            !status
         )
            return;

         this.loadingSaveJustification = true;

         try {
            await surgerieService.updateStatusHealthUnitSurgerie(
               healthUnitId,
               surgerieId,
               this.formatObjectToSend(status)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi cancelada com sucesso!",
            });

            this.$emit("onCancelSurgery");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveJustification = false;
         }
      },
   },
};
</script>

<style scoped>
.justification-height {
   height: 100%;
}
</style>