<template>
   <div>
      <v-dialog
         v-model="dialogActivateUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card>
            <ActivateUserForm
               @onActivateUser="
                  closeActivateUserForm();
                  refreshRegulatorList();
               "
               :user="disableRegulator"
               userType="regulador"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="regulatorResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshRegulatorList();
               "
               :user="regulatorChangeTypeSelected"
               currentType="regulator"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogRegulatorFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeRegulatorForm" />

            <RegulatorForm
               @onClickCancel="closeRegulatorForm"
               @onCreatedRegulator="
                  closeRegulatorForm();
                  refreshRegulatorList();
               "
               @onUpdatedRegulator="
                  closeRegulatorForm();
                  refreshRegulatorList();
               "
               @onClickResetRegulator="onClickResetRegulator"
               @onClickRemoveRegulator="onClickRemoveRegulator"
               @onFindDisabledRegulator="onFindDisabledRegulator"
               @onClickChangeType="onClickChangeType"
               :regulator="regulatorSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <RegulatorList
         @onClickAddRegulator="onClickAddRegulator"
         @onClickViewRegulator="onClickViewRegulator"
         @onClickEditRegulator="onClickEditRegulator"
         @onClickRemoveRegulator="onClickRemoveRegulator"
         class="mt-12"
         ref="regulatorlist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import RegulatorList from "../../components/regulator/RegulatorList";
import RegulatorForm from "../../components/regulator/RegulatorForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import ActivateUserForm from "components/users/ActivateUserForm";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "Regulator",

   components: {
      Close,
      PageTitle,
      RegulatorList,
      RegulatorForm,
      ResetPasswordUsersForm,
      ActivateUserForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         regulatorSelected: {},
         regulatorResetPasswordSelected: {},
         regulatorChangeTypeSelected: {},
         disableRegulator: {},

         editMode: false,

         dialogRegulatorFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogActivateUserFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogRegulatorFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddRegulator(regulator) {
         this.regulatorSelected = Object.assign({}, regulator);
         this.openRegulatorForm();
      },

      onClickViewRegulator(regulator) {
         this.regulatorSelected = Object.assign({}, regulator);
         this.openRegulatorForm();
      },

      onClickEditRegulator() {
         this.editMode = true;
      },

      onClickChangeType(regulator) {
         this.regulatorChangeTypeSelected = Object.assign({}, regulator);
         this.closeRegulatorForm();
         this.openChangeTypeUserForm();
      },

      onClickResetRegulator(regulator) {
         this.regulatorResetPasswordSelected = Object.assign({}, regulator);
         this.openResetPasswordForm();
      },

      onClickRemoveRegulator(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, { active: false });

            this.closeRegulatorForm();
            this.refreshRegulatorList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O regulador foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse regulador?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledRegulator(item) {
         this.disableRegulator = Object.assign({}, item);

         this.closeRegulatorForm();
         this.openActivateUserForm();
      },

      openRegulatorForm() {
         this.dialogRegulatorFormVisibility = true;
      },

      closeRegulatorForm() {
         this.dialogRegulatorFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      openActivateUserForm() {
         this.dialogActivateUserFormVisibility = true;
      },

      closeActivateUserForm() {
         this.dialogActivateUserFormVisibility = false;
      },

      refreshRegulatorList() {
         this.$refs.regulatorlist.refreshAllRegulatorsByRef();
      }
   }
};
</script>

<style scoped></style>
