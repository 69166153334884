var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"500","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryOutcomeFormVisibility),callback:function ($$v) {_vm.dialogSurgeryOutcomeFormVisibility=$$v},expression:"dialogSurgeryOutcomeFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryOutcomeForm}}),_c('SurgeryOutcomeForm',{staticClass:"standard-padding",attrs:{"request":_vm.surgeryRequest},on:{"onClickCancel":_vm.closeSurgeryOutcomeForm,"onConfirmOutcome":function($event){_vm.closeSurgeryForm();
               _vm.closeSurgeryOutcomeForm();
               _vm.refreshSurgeryList();}}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"500","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryRejectRequestFormVisibility),callback:function ($$v) {_vm.dialogSurgeryRejectRequestFormVisibility=$$v},expression:"dialogSurgeryRejectRequestFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryRejectRequestForm}}),_c('SurgeryRejectRequestForm',{staticClass:"standard-padding",attrs:{"request":_vm.surgeryRequest},on:{"onClickCancel":_vm.closeSurgeryRejectRequestForm,"onCancelReservation":function($event){_vm.closeSurgeryForm();
               _vm.closeSurgeryRejectRequestForm();
               _vm.refreshSurgeryList();}}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"500","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryCancelFormVisibility),callback:function ($$v) {_vm.dialogSurgeryCancelFormVisibility=$$v},expression:"dialogSurgeryCancelFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryCancelForm}}),_c('SurgeryCancelForm',{staticClass:"standard-padding",attrs:{"request":_vm.surgeryRequest},on:{"onClickCancel":_vm.closeSurgeryCancelForm,"onCancelSurgery":function($event){_vm.closeSurgeryForm();
               _vm.closeSurgeryCancelForm();
               _vm.refreshSurgeryList();}}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"500","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryDoneFormVisibility),callback:function ($$v) {_vm.dialogSurgeryDoneFormVisibility=$$v},expression:"dialogSurgeryDoneFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryDoneForm}}),_c('SurgeryDoneForm',{staticClass:"standard-padding",attrs:{"surgery":_vm.surgerySelected},on:{"onClickCancel":_vm.closeSurgeryDoneForm,"onDoneSurgery":function($event){_vm.closeSurgeryForm();
               _vm.closeSurgeryDoneForm();
               _vm.refreshSurgeryList();}}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"1000","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryFormVisibility),callback:function ($$v) {_vm.dialogSurgeryFormVisibility=$$v},expression:"dialogSurgeryFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryForm}}),_c('v-tabs',{attrs:{"centered":"","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"id":"bed-dashboard-button"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-medical-bag ")]),_vm._v(" CIRURGIA ")],1),(_vm.surgeryRequest.id)?_c('v-tab',{attrs:{"id":"request-bed-dashboard-button"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-document ")]),_vm._v(" SOLICITAÇÃO ")],1):_vm._e()],1),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 0),expression:"activeTab === 0"}]},[_c('SurgeryForm',{staticClass:"standard-padding",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore,"surgery":_vm.surgerySelected,"editMode":_vm.editMode,"hideEditButton":_vm.hideEditButtonComputed,"hideRemoveButton":"","hideTitle":""},on:{"onClickCancel":_vm.closeSurgeryForm,"onUpdatedSurgery":function($event){_vm.closeSurgeryForm();
                     _vm.refreshSurgeryList();},"onClickCancelSurgery":_vm.onClickCancelSurgery,"onClickRemoveSurgery":_vm.onClickRemoveSurgery,"onClickDoneSurgery":_vm.onClickDoneSurgery,"onClickOutcomeSurgery":_vm.onClickOutcomeSurgery,"onSurgeryRequestLoad":_vm.onSurgeryRequestLoad}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 1 && _vm.surgeryRequest.id),expression:"activeTab === 1 && surgeryRequest.id"}]},[_c('SurgeryRequestOperatorForm',{staticClass:"standard-padding",attrs:{"request":_vm.surgeryRequest,"hideEditButton":"","hideRemoveButton":"","hideTitle":""}})],1)])],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"1000","transition":"slide-y-transition"},model:{value:(_vm.dialogBatchScheduleFormVisibility),callback:function ($$v) {_vm.dialogBatchScheduleFormVisibility=$$v},expression:"dialogBatchScheduleFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeBatchScheduleForm}}),_c('BatchSurgeryForm',{staticClass:"standard-padding",attrs:{"surgeries":_vm.selectedSurgeries},on:{"onScheduledSurgeries":function($event){_vm.closeBatchScheduleForm();
               _vm.refreshSurgeryList();
               _vm.resetSelectMode();},"onClickCancel":_vm.closeBatchScheduleForm}})],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('SurgeryStatistic',{ref:"surgerystatistic",attrs:{"statistics":_vm.generalStatistics,"healthUnit":_vm.operatorCurrentHealthUnitStore},on:{"onClickChangeHealthUnit":function($event){_vm.selectHealthUnitVisibilityStore = true}}}),_c('SurgeryList',{ref:"surgerylist",staticClass:"mt-12",attrs:{"healthUnit":_vm.operatorCurrentHealthUnitStore},on:{"onClickViewSurgerie":_vm.onClickViewSurgery,"onClickEditSurgerie":_vm.onClickEditSurgerie,"onClickSaveBatchSchedule":_vm.onClickSaveBatchSchedule}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }