import axiosService from "./axiosService";
import validationUtil from "utils/validationUtil";
import moment from "moment";

async function getHealthUnitRequestSummary(
   healthUnitId,
   mode,
   startDate,
   endDate,
   query
) {
   let modeTemp = mode ? mode : "target";
   let startDateTemp = validationUtil.dateValidation(startDate, "YYYY-MM-DD")
      ? startDate
      : moment().subtract(1, "Y");
   let endDateTemp = validationUtil.dateValidation(endDate, "YYYY-MM-DD")
      ? endDate
      : moment();

   let url = `/healthentities/${healthUnitId}/${modeTemp}/date/${startDateTemp}/${endDateTemp}/covidrequestsummary`;

   /* 2. Seta os parametros opcionais na URL. */
   if (query && query.filter && query.filter.length) {
      url = `${url}?`;

      query.filter.forEach((item, index) => {
         url += `${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }

         if (index + 1 < query.filter.length) url += `&`;
      });
   }
   return await axiosService.get(url);
}

async function getRequestSummary(startDate, endDate, query) {
   let startDateTemp = validationUtil.dateValidation(startDate, "YYYY-MM-DD")
      ? startDate
      : moment().subtract(1, "Y");
   let endDateTemp = validationUtil.dateValidation(endDate, "YYYY-MM-DD")
      ? endDate
      : moment();

   let url = `/date/${startDateTemp}/${endDateTemp}/covidrequestsummary`;

   /* 2. Seta os parametros opcionais na URL. */
   if (query && query.filter && query.filter.length) {
      url = `${url}?`;

      query.filter.forEach((item, index) => {
         url += `${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }

         if (index + 1 < query.filter.length) url += `&`;
      });
   }

   return await axiosService.get(url);
}

/* 
   query: Objeto com dados dos filtros:
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getHealthUnitSurgeryRequestSummary(
   healthUnitId,
   mode,
   startDate,
   endDate,
   query
) {
   /* 1. Seta os parametros obrigatórios na URL. */
   let modeTemp = mode ? mode : "target";
   let startDateTemp = validationUtil.dateValidation(startDate, "YYYY-MM-DD")
      ? startDate
      : moment().subtract(1, "Y");
   let endDateTemp = validationUtil.dateValidation(endDate, "YYYY-MM-DD")
      ? endDate
      : moment();

   let url = `/healthentities/${healthUnitId}/${modeTemp}/date/${startDateTemp}/${endDateTemp}/surgeryrequestsummary`;

   /* 2. Seta os parametros opcionais na URL. */
   if (query && query.filter && query.filter.length) {
      url = `${url}?`;

      query.filter.forEach((item, index) => {
         url += `${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }

         if (index + 1 < query.filter.length) url += `&`;
      });
   }

   return await axiosService.get(url);
}

async function getSurgeryRequestSummary(startDate, endDate, query) {
   /* 1. Seta os parametros obrigatórios na URL. */
   let startDateTemp = validationUtil.dateValidation(startDate, "YYYY-MM-DD")
      ? startDate
      : moment().subtract(1, "Y");
   let endDateTemp = validationUtil.dateValidation(endDate, "YYYY-MM-DD")
      ? endDate
      : moment();

   let url = `/date/${startDateTemp}/${endDateTemp}/surgeryrequestsummary`;

   /* 2. Seta os parametros opcionais na URL. */
   if (query && query.filter && query.filter.length) {
      url = `${url}?`;

      query.filter.forEach((item, index) => {
         url += `${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }

         if (index + 1 < query.filter.length) url += `&`;
      });
   }

   return await axiosService.get(url);
}

export default {
   getRequestSummary,
   getHealthUnitRequestSummary,
   getHealthUnitSurgeryRequestSummary,
   getSurgeryRequestSummary
};
