<template>
   <div>
      <v-card
         class="
            standard-padding-whopping
            card-border
            d-flex
            flex-column
            justify-center
            align-stretch
         "
         elevation="3"
      >
         <div class="d-flex justify-center align-center">
            <v-icon size="150">
               $reg-logo
            </v-icon>
         </div>

         <v-form ref="form">
            <v-text-field
               v-model="formData.email"
               @keyup.enter="auth(formData)"
               :rules="[
                  (value) => !!value || 'Email obrigatório.',
                  (value) => emailRules(value) || 'Email inválido.',
               ]"
               class="mt-9 body-1 label-position"
               id="email-auth-input"
               height="44"
               ><template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-email</v-icon>
               </template>

               <template v-slot:label>
                  <p class="body-1">Email</p>
               </template>
            </v-text-field>

            <v-text-field
               v-model="formData.password"
               @keyup.enter="auth(formData)"
               :type="showPassword ? 'text' : 'password'"
               :rules="[(value) => !!value || 'Senha obrigatória.']"
               class="mt-12 body-1 label-position"
               id="password-auth-input"
               height="44"
            >
               <template v-slot:prepend-inner>
                  <v-icon size="21" left>mdi-lock</v-icon>
               </template>

               <template v-slot:append>
                  <v-icon
                     @click="showPassword = !showPassword"
                     size="21"
                     id="visualization-password-auth-card-icon"
                     left
                     >{{ showPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                  >
               </template>

               <template v-slot:label>
                  <p class="body-1">Senha</p>
               </template>
            </v-text-field>
         </v-form>

         <div class="mt-10">
            <div v-if="nodeEnvComputed">
               <vue-recaptcha
                  ref="recaptcha"
                  id="recaptcha"
                  @verify="recaptchaVerify"
                  @render="recaptchaRender"
                  @expired="recaptchaExpired"
                  :sitekey="siteKey"
               ></vue-recaptcha>
               <p
                  v-if="recaptchaValidation"
                  class="ma-0 mb-n5 caption error--text"
               >
                  Preencha a confirmação para continuar.
               </p>
            </div>
         </div>

         <v-expand-transition>
            <v-alert
               v-if="invalidAuth"
               class="ma-0 mt-5 mb-n2 caption"
               type="error"
               border="left"
               dense
               text
               >Credenciais inválidas!</v-alert
            >
         </v-expand-transition>

         <v-expand-transition>
            <v-alert
               v-if="limitExceededUser"
               class="ma-0 mt-5 mb-n2 caption"
               type="warning"
               border="left"
               dense
               text
               >Limites de autenticação atingido com esse usuário, tente
               novamente mais tarde!</v-alert
            >
         </v-expand-transition>

         <v-btn
            @click="auth(formData)"
            :loading="loadingAuth"
            class="mt-7 py-6"
            color="primary"
            id="auth-button"
            large
            block
            >ENTRAR
         </v-btn>

         <div class="d-flex justify-center align-center mt-6">
            <router-link
               class="router-link-forgot-password"
               to="/esqueci-minha-senha"
               id="go-to-forgot-password-button"
            >
               <p class="caption primary--text label-forgot-password">
                  Esqueceu a senha?
               </p>
            </router-link>
         </div>
      </v-card>
   </div>
</template>

<script>
import validationUtil from "../../utils/validationUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import authService from "../../services/authService";
import VueRecaptcha from "vue-recaptcha";
import { NODE_ENV } from "src/utils/defaultUtil";

export default {
   name: "AuthCard",

   components: { VueRecaptcha },

   data: function () {
      return {
         formData: {},

         loadingAuth: false,
         invalidAuth: false,
         limitExceededUser: false,

         showPassword: false,

         recaptchaToken: null,
         recaptchaRendered: false,
         recaptchaValidation: false,
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },

      siteKey() {
         return (
            process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
            "VUE_APP_RECAPTCHA_SITE_KEY"
         );
      },

      nodeEnvComputed() {
         return NODE_ENV === "production" ? true : false;
      },
   },

   methods: {
      async auth(data) {
         const dataValidation = this.validateForm();

         let recaptchaValidation = true;
         if (this.nodeEnvComputed)
            recaptchaValidation = this.validateRecaptcha();

         if (dataValidation && recaptchaValidation) {
            this.loadingAuth = true;
            this.invalidAuth = false;
            this.limitExceededUser = false;

            try {
               const response = await authService.auth(
                  data.email,
                  data.password,
                  this.recaptchaToken
               );

               this.$emit("onAuth", response);
            } catch (error) {
               if (error.response && error.response.status === 401) {
                  this.invalidAuth = true;
                  this.resetRecaptcha();
               } else if (error.response && error.response.status === 400) {
                  this.limitExceededUser = true;
                  this.resetRecaptcha();
               } else snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingAuth = false;
            }
         }
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      validateForm() {
         return this.$refs.form.validate();
      },

      recaptchaRender() {
         this.recaptchaRendered = true;
      },

      recaptchaVerify(value) {
         value ? (this.recaptchaValidation = false) : null;
         this.recaptchaToken = value;
      },

      recaptchaExpired() {
         this.recaptchaVerify(null);
      },

      validateRecaptcha() {
         this.recaptchaValidation = !this.recaptchaToken;
         return !this.recaptchaValidation;
      },

      resetRecaptcha() {
         if (this.$refs.recaptcha) this.$refs.recaptcha.reset();
      },
   },
};
</script>

<style scoped>
.card-border {
   border-radius: 8px !important;
}

.label-forgot-password {
   cursor: pointer !important;
}

.router-link-forgot-password {
   text-decoration: none;
}

::v-deep .label-position > div > div > div > label {
   top: 4px !important;
}

::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}

#recaptcha {
   max-width: 1px !important;
   transform: scale(1);
   -webkit-transform: scale(1);
   transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
}

@media (max-width: 400px) {
   #recaptcha {
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      transform-origin: 100 5;
      -webkit-transform-origin: 100 5;
   }
}
</style>