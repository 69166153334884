import axiosService from "./axiosService";
import store from "../store/index";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/
async function getAllBeds(wardId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "created_at";

   let url = `/wards/${wardId}/beds?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach(item => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "active":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function createBed(wardId, bed) {
   return await axiosService.post(`/wards/${wardId}/beds`, bed);
}

async function updateBed(wardId, bedId, bed) {
   return await axiosService.patch(`/wards/${wardId}/beds/${bedId}`, bed);
}

async function updateStatusBed(wardId, bedId, bed) {
   return await axiosService.put(`/wards/${wardId}/beds/${bedId}/status`, bed);
}

async function transferBed(healthentity_id, request_id, request) {
   // Adiciona o type da solicitação no body.
   request.type = store.getters["request/getRequestType"];

   return await axiosService.put(
      `/healthentities/${healthentity_id}/bedrequests/${request_id}/bed`,
      request
   );
}

async function activeBed(bedId, active) {
   return await axiosService.put(`/beds/${bedId}/active`, {
      active
   });
}

export default {
   getAllBeds,
   createBed,
   updateBed,
   updateStatusBed,
   activeBed,
   transferBed
};
