<template>
   <div>
      <v-row class="mt-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.date"
               :rules="[(value) => dateRules(value) || 'Data inválida.']"
               v-maska="'##/##/####'"
               label="Data"
               id="date-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.leukocytes"
               label="Leucócitos"
               id="leukocytes-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.lymphocytes"
               label="Linfócitos"
               id="lymphocytes-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.hb"
               label="Hb"
               id="hb-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.ht"
               label="Ht"
               id="ht-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.pcr"
               label="PCR"
               id="pcr-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.lactate"
               label="Lactato"
               id="lactate-laboratory-form-input"
            ></v-text-field>
         </v-col>

         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.dhl"
               label="DHL"
               id="dhl-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.ferritin"
               label="Ferretina"
               id="ferritin-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mt-5">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.troponin"
               label="Troponina"
               id="troponin-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.cpk"
               label="CPK"
               id="cpk-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.d_dimer"
               label="D-dímero"
               id="d_dimer-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.urea"
               label="Ureia"
               id="urea-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.creatinine"
               label="Creatinina"
               id="creatinine-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.ap"
               label="AP"
               id="ap-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.tap"
               label="TAP"
               id="tap-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.inr"
               label="INR"
               id="inr-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>

      <v-row class="mb-0">
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.tgo"
               label="TGO"
               id="tgo-laboratory-form-input"
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
               v-model="laboratory.tgp"
               label="TGP"
               id="tgp-laboratory-form-input"
            ></v-text-field>
         </v-col>
      </v-row>
   </div>
</template>

<script>
import validationUtil from "../../../../utils/validationUtil";

export default {
   name: "LaboratoryForm",

   props: {
      laboratory: {
         type: Object,
         default: function () {
            return {};
         },
      },
   },

   methods: {
      ...validationUtil,

      dateRules(value) {
         if (value) return this.dateValidation(value);
         else return true;
      },
   },
};
</script>

<style>
</style>