<template>
   <div>
      <v-dialog
         v-model="dialogActivateUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card>
            <ActivateUserForm
               @onActivateUser="
                  closeActivateUserForm();
                  refreshManagerList();
               "
               :user="disableManager"
               userType="gestor"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogChangeTypeUserFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="700"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeChangeTypeUserForm" />

            <ChangeTypeUserForm
               @onClickCancel="closeChangeTypeUserForm"
               @onChangeType="
                  closeChangeTypeUserForm();
                  refreshManagerList();
               "
               :user="managerChangeTypeSelected"
               currentType="manager"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogResetPasswordFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="500"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeResetPasswordForm" />

            <ResetPasswordUsersForm
               @onClickCancel="closeResetPasswordForm"
               @onResetPassword="closeResetPasswordForm"
               :user="managerResetPasswordSelected"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogManagerFormVisibility"
         :fullscreen="isSmallerThan(599)"
         max-width="1000"
         transition="slide-y-transition"
      >
         <v-card :tile="isSmallerThan(599)" id="scrollTop">
            <Close @onClickClose="closeManagerForm" />

            <ManagerForm
               @onClickCancel="closeManagerForm"
               @onCreatedManager="
                  closeManagerForm();
                  refreshManagerList();
               "
               @onUpdatedManager="
                  closeManagerForm();
                  refreshManagerList();
               "
               @onClickChangeType="onClickChangeType"
               @onClickResetManager="onClickResetManager"
               @onClickRemoveManager="onClickRemoveManager"
               @onFindDisabledManager="onFindDisabledManager"
               :manager="managerSelected"
               :editMode="editMode"
               class="standard-padding"
            />
         </v-card>
      </v-dialog>

      <PageTitle :icon="pageTitle.icon" :title="pageTitle.text.toUpperCase()" />

      <ManagerList
         @onClickAddManager="onClickAddManager"
         @onClickViewManager="onClickViewManager"
         @onClickEditManager="onClickEditManager"
         @onClickRemoveManager="onClickRemoveManager"
         class="mt-12"
         ref="managerlist"
      />
   </div>
</template>

<script>
import PageTitle from "../../components/base/PageTitle";
import Close from "../../components/base/Close";
import ManagerList from "../../components/manager/ManagerList";
import ManagerForm from "../../components/manager/ManagerForm";
import ResetPasswordUsersForm from "components/users/ResetPasswordUsersForm";
import ActivateUserForm from "components/users/ActivateUserForm";
import ChangeTypeUserForm from "components/users/ChangeTypeUserForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import { PROTECTED_ROUTES } from "../../utils/defaultUtil";
import userService from "services/userService";

export default {
   name: "Manager",

   components: {
      Close,
      PageTitle,
      ManagerList,
      ManagerForm,
      ResetPasswordUsersForm,
      ActivateUserForm,
      ChangeTypeUserForm
   },

   data: function() {
      return {
         managerSelected: {},
         managerResetPasswordSelected: {},
         managerChangeTypeSelected: {},
         disableManager: {},

         editMode: false,

         dialogManagerFormVisibility: false,
         dialogResetPasswordFormVisibility: false,
         dialogChangeTypeUserFormVisibility: false,
         dialogActivateUserFormVisibility: false
      };
   },

   computed: {
      pageTitle() {
         return PROTECTED_ROUTES.find(item => item.link === this.$route.path);
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         }
      }
   },

   watch: {
      dialogManagerFormVisibility(val) {
         if (!val) this.editMode = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      }
   },

   methods: {
      ...responsiveUtil,

      onClickAddManager(manager) {
         this.managerSelected = Object.assign({}, manager);
         this.openManagerForm();
      },

      onClickViewManager(manager) {
         this.managerSelected = Object.assign({}, manager);
         this.openManagerForm();
      },

      onClickEditManager() {
         this.editMode = true;
      },

      onClickChangeType(manager) {
         this.managerChangeTypeSelected = Object.assign({}, manager);
         this.closeManagerForm();
         this.openChangeTypeUserForm();
      },

      onClickResetManager(manager) {
         this.managerResetPasswordSelected = Object.assign({}, manager);
         this.openResetPasswordForm();
      },

      onClickRemoveManager(item) {
         const methodDeleteItem = async () => {
            await userService.activateUser(item.id, { active: false });

            this.closeManagerForm();
            this.refreshManagerList();

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "O gestor foi excluído!"
            });
         };

         snackBarUtil.showCustomSnackBar({
            color: "error",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse gestor?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
               closeOnFinished: false
            }
         });
      },

      onFindDisabledManager(item) {
         this.disableManager = Object.assign({}, item);

         this.closeManagerForm();
         this.openActivateUserForm();
      },

      openManagerForm() {
         this.dialogManagerFormVisibility = true;
      },

      closeManagerForm() {
         this.dialogManagerFormVisibility = false;
      },

      openChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = true;
      },

      closeChangeTypeUserForm() {
         this.dialogChangeTypeUserFormVisibility = false;
      },

      openResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = true;
      },

      closeResetPasswordForm() {
         this.dialogResetPasswordFormVisibility = false;
      },

      openActivateUserForm() {
         this.dialogActivateUserFormVisibility = true;
      },

      closeActivateUserForm() {
         this.dialogActivateUserFormVisibility = false;
      },

      refreshManagerList() {
         this.$refs.managerlist.refreshAllManagersByRef();
      }
   }
};
</script>

<style scoped></style>
