var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.isSmallerThan(599),"max-width":"1000","transition":"slide-y-transition"},model:{value:(_vm.dialogSurgeryTypeFormVisibility),callback:function ($$v) {_vm.dialogSurgeryTypeFormVisibility=$$v},expression:"dialogSurgeryTypeFormVisibility"}},[_c('v-card',{attrs:{"tile":_vm.isSmallerThan(599),"id":"scrollTop"}},[_c('Close',{on:{"onClickClose":_vm.closeSurgeryTypeForm}}),_c('SurgeryTypeForm',{staticClass:"standard-padding",attrs:{"surgeryType":_vm.surgeryTypeSelected,"editMode":_vm.editMode,"healthUnit":_vm.healthUnitManagerCurrentHealthUnitStore,"hideEditButton":_vm.healthUnitManagerCurrentHealthUnitStore &&
                  _vm.healthUnitManagerCurrentHealthUnitStore.id &&
                  !_vm.healthUnitManagerCurrentHealthUnitStore.capabilities
                     .can_offer_surgeries,"hideRemoveButton":""},on:{"onClickCancel":_vm.closeSurgeryTypeForm,"onCreatedSurgeryType":function($event){_vm.closeSurgeryTypeForm();
               _vm.refreshSurgeryTypeList();},"onUpdatedSurgeryType":function($event){_vm.closeSurgeryTypeForm();
               _vm.refreshSurgeryTypeList();},"onClickRemoveSurgeryType":_vm.onClickRemoveSurgeryType,"onFindDisabledSurgeryType":_vm.onFindDisabledSurgeryType}})],1)],1),_c('PageTitle',{attrs:{"icon":_vm.pageTitle.icon,"title":_vm.pageTitle.text.toUpperCase()}}),_c('SurgeryTypeList',{ref:"surgeryTypelist",staticClass:"mt-12",attrs:{"healthUnit":_vm.healthUnitManagerCurrentHealthUnitStore,"hideAddButton":_vm.healthUnitManagerCurrentHealthUnitStore &&
            _vm.healthUnitManagerCurrentHealthUnitStore.id &&
            !_vm.healthUnitManagerCurrentHealthUnitStore.capabilities
               .can_offer_surgeries,"hideEditButton":_vm.healthUnitManagerCurrentHealthUnitStore &&
            _vm.healthUnitManagerCurrentHealthUnitStore.id &&
            !_vm.healthUnitManagerCurrentHealthUnitStore.capabilities
               .can_offer_surgeries,"hideRemoveButton":""},on:{"onClickAddSurgeryType":_vm.onClickAddSurgeryType,"onClickViewSurgeryType":_vm.onClickViewSurgeryType,"onClickEditSurgeryType":_vm.onClickEditSurgeryType,"onClickRemoveSurgeryType":_vm.onClickRemoveSurgeryType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }