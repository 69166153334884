<template>
   <v-snackbar
      v-model="snackBarVisibilityStore"
      :color="snackBarColorStore"
      :timeout="snackBarTimeoutStore"
      class="mt-5"
      multi-line
      top
   >
      <div
         class="d-flex justify-space-between align-center mr-n2 my-1"
         id="content-snackbar"
      >
         <v-icon x-large>{{ snackBarIconStore || generateIcon }}</v-icon>

         <div class="mx-4 flex-grow-1">
            <p class="body-2 white--text font-weight-bold" id="title-snackbar">
               {{ snackBarTitleStore }}
            </p>

            <p class="body-2 white--text" id="message-snackbar">
               {{ snackBarMessageStore }}
            </p>
         </div>

         <v-btn
            v-if="snackBarActionTextStore && snackBarActionMethodStore"
            @click="onActionClick"
            :loading="snackBarActionLoadingStore"
            class="ml-n1 mr-3"
            id="snackbar-action-button"
            small
            depressed
            >{{ snackBarActionTextStore }}</v-btn
         >

         <v-btn @click="closeSnackbar" icon x-small>
            <v-icon small>mdi-close</v-icon>
         </v-btn>
      </div>
   </v-snackbar>
</template>

<script>
import snackBarUtil from "../../utils/snackBarUtil";

export default {
   name: "SnackBar",

   computed: {
      snackBarVisibilityStore: {
         get() {
            return this.$store.getters["snackBar/getVisibility"];
         },
         set(value) {
            this.$store.commit("snackBar/setVisibility", value);
         },
      },

      snackBarColorStore: {
         get() {
            return this.$store.getters["snackBar/getColor"];
         },
         set(value) {
            this.$store.commit("snackBar/setColor", value);
         },
      },

      snackBarTitleStore: {
         get() {
            return this.$store.getters["snackBar/getTitle"];
         },
         set(value) {
            this.$store.commit("snackBar/setTitle", value);
         },
      },

      snackBarMessageStore: {
         get() {
            return this.$store.getters["snackBar/getMessage"];
         },
         set(value) {
            this.$store.commit("snackBar/setMessage", value);
         },
      },

      snackBarIconStore: {
         get() {
            return this.$store.getters["snackBar/getIcon"];
         },
         set(value) {
            this.$store.commit("snackBar/setIcon", value);
         },
      },

      snackBarTimeoutStore: {
         get() {
            return this.$store.getters["snackBar/getTimeout"];
         },
         set(value) {
            this.$store.commit("snackBar/setTimeout", value);
         },
      },

      snackBarActionTextStore: {
         get() {
            return this.$store.getters["snackBar/getActionText"];
         },
         set(value) {
            this.$store.commit("snackBar/setActionText", value);
         },
      },

      snackBarActionMethodStore: {
         get() {
            return this.$store.getters["snackBar/getActionMethod"];
         },
         set(value) {
            this.$store.commit("snackBar/setActionMethod", value);
         },
      },

      snackBarActionLoadingStore: {
         get() {
            return this.$store.getters["snackBar/getActionLoading"];
         },
         set(value) {
            this.$store.commit("snackBar/setActionLoading", value);
         },
      },

      snackBarActionCloseOnFinishedStore: {
         get() {
            return this.$store.getters["snackBar/getActionCloseOnFinished"];
         },
         set(value) {
            this.$store.commit("snackBar/setActionCloseOnFinished", value);
         },
      },

      generateIcon() {
         switch (this.snackBarColorStore) {
            case "success":
               return "mdi-check";
            case "error":
               return "mdi-close";
            case "warning":
               return "mdi-alert-outline";
            case "primary":
               return "mdi-information-outline";
            default:
               return "mdi-check";
         }
      },
   },

   methods: {
      closeSnackbar() {
         this.snackBarVisibilityStore = false;
      },

      async onActionClick() {
         this.snackBarActionLoadingStore = true;

         try {
            await this.snackBarActionMethodStore();
            if (this.snackBarActionCloseOnFinishedStore) this.closeSnackbar();
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.snackBarActionLoadingStore = false;
         }

         // this.snackBarActionMethodStore()
         //    .then(() => {
         //       if (this.snackBarActionCloseOnFinishedStore)
         //          this.closeSnackbar();
         //    })
         //    .catch(snackBarUtil.showErrorSnackBar)
         //    .finally(() => (this.snackBarActionLoadingStore = false));
      },
   },
};
</script>

<style scoped>
</style>