<template>
   <div>
      <FormTitle icon="mdi-home-plus" title="SECRETARIA DE SAÚDE" />

      <v-alert
         v-if="getMode() === 'read' && formHealthSecretarie.created_at"
         class="caption"
         color="primary"
         type="info"
         border="left"
         dense
         text
         >Secretaria de saúde cadastrada em
         {{
            formatDate(
               formHealthSecretarie.created_at,
               "DD/MM/YYYY [às] HH:mm"
            )
         }}.</v-alert
      >

      <v-form ref="form" :readonly="getMode() === 'read'">
         <v-card :disabled="getMode() === 'read'" flat>
            <p class="body-1 font-weight-medium mt-5">
               Dados da secretaria de saúde
            </p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     @change="onChangeHealthSecretarieName"
                     v-model="formHealthSecretarie.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     :loading="loadingHealthSecretarieByName"
                     id="name-healthsecretarie-form-input"
                     label="Nome*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthSecretarie.director"
                     :rules="[(value) => !!value || 'Diretor obrigatório.']"
                     id="director-healthsecretarie-form-input"
                     label="Diretor*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model.number="formHealthSecretarie.built_area"
                     id="built_area-healthsecretarie-form-input"
                     v-maska="'#*'"
                     label="Área construída (m²)"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="body-1 font-weight-medium mt-6">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formHealthSecretarie.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                     ]"
                     :loading="loadingSearchZipCode"
                     id="zip_code-healthsecretarie-form-input"
                     maxlength="8"
                     label="CEP*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthSecretarie.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     id="street-healthsecretarie-form-input"
                     label="Rua*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="formHealthSecretarie.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     id="number-healthsecretarie-form-input"
                     label="Número*"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="formHealthSecretarie.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     id="district-healthsecretarie-form-input"
                     label="Bairro*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="formHealthSecretarie.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     id="city-healthsecretarie-form-input"
                     label="Cidade*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-select
                     v-model="formHealthSecretarie.address.state"
                     :rules="[(value) => !!value || 'Estado obrigatório.']"
                     :items="statesItems"
                     id="state-healthsecretarie-form-input"
                     menu-props="auto"
                     label="Estado*"
                     return-object
                  >
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-5">
               <v-col cols="12">
                  <v-text-field
                     v-model="formHealthSecretarie.address.complement"
                     id="complement-healthsecretarie-form-input"
                     label="Complemento"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-5 mb-0">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formHealthSecretarie.address.latitude"
                     :rules="[(value) => !!value || 'Latitude obrigatório.']"
                     id="latitude-healthsecretarie-form-input"
                     label="Latitude*"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="formHealthSecretarie.address.longitude"
                     :rules="[(value) => !!value || 'Longitude obrigatória.']"
                     id="longitude-healthsecretarie-form-input"
                     label="Longitude*"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>

      <HealthSecretarieFormActions
         @onClickEdit="onClickEditHealthSecretarie"
         @onClickCancel="onClickCancel"
         @onClickSave="onClickSaveHealthSecretarie"
         :mode="getMode()"
         :hideEditButton="hideEditButton"
         :hideRemoveButton="hideRemoveButton"
         :loadingSave="loadingSaveHealthSecretarie"
         :validationErrors="validationErrors"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormTitle from "../../components/base/form/FormTitle";
import HealthSecretarieFormActions from "components/healthSecretarie/HealthSecretarieFormActions";
import formatUtil from "../../utils/formatUtil";
import validationUtil from "../../utils/validationUtil";
import healthSecretarieService from "../../services/healthSecretarieService";
import zipCodeService from "../../services/zipCodeService";
import snackBarUtil from "../../utils/snackBarUtil";
import util from "../../utils/util";

export default {
   name: "HealthSecretarieForm",

   components: { FormTitle, HealthSecretarieFormActions },

   props: {
      healthSecretarie: {
         type: Object,
      },

      municipality: {
         type: Object,
      },

      hideEditButton: {
         type: Boolean,
         default: false,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",

         formHealthSecretarie: {},

         loadingSaveHealthSecretarie: false,
         loadingSearchZipCode: false,
         loadingHealthSecretarieByName: false,

         statesItems: [
            "AC",
            "AL",
            "AM",
            "AP",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MG",
            "MS",
            "MT",
            "PA",
            "PB",
            "PE",
            "PI",
            "PR",
            "RJ",
            "RN",
            "RO",
            "RR",
            "RS",
            "SC",
            "SE",
            "SP",
            "TO",
         ],

         validationErrors: [],
      };
   },

   watch: {
      healthSecretarie: {
         immediate: true,
         handler(healthSecretarie) {
            if (healthSecretarie.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.validationErrors = [];

            this.formHealthSecretarie =
               this.formatObjectToView(healthSecretarie);

            this.resetValidation();
         },
      },

      "formHealthSecretarie.address.zip_code": async function handler(
         newZip,
         oldZip
      ) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  this.formHealthSecretarie.address.street =
                     response.data.logradouro;
                  this.formHealthSecretarie.address.district =
                     response.data.bairro;
                  this.formHealthSecretarie.address.city =
                     response.data.localidade;
                  this.formHealthSecretarie.address.state = response.data.uf;
                  if (response.data.complemento)
                     this.formHealthSecretarie.address.complement =
                        response.data.complemento;
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      formatObjectToView(healthSecretarie) {
         let healthSecretarieTemp = util.mergeObject(
            {
               address: {},
            },
            healthSecretarie
         );

         return healthSecretarieTemp;
      },

      formatObjectToSend(healthSecretarie, removeEmptyStrings) {
         let healthSecretarieTemp = util.removeBlankAttributes(
            healthSecretarie,
            {
               emptyStrings: removeEmptyStrings,
               // emptyArrays: removeEmptyStrings,
            }
         );

         return healthSecretarieTemp;
      },

      onClickEditHealthSecretarie() {
         this.setMode("save");
      },

      onClickCancel() {
         this.setMode("read");
         this.formHealthSecretarie = this.formatObjectToView(
            this.healthSecretarie
         );
         this.$emit("onClickCancel");
      },

      onClickSaveHealthSecretarie() {
         if (this.formHealthSecretarie.id)
            this.updateHealthSecretarie(
               this.municipality.id,
               this.formHealthSecretarie.id,
               this.formHealthSecretarie
            );
         else
            this.createHealthSecretarie(
               this.municipality.id,
               this.formHealthSecretarie
            );
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      executeValidation() {
         if (this.$refs.form) {
            this.validationErrors = this.$refs.form.inputs
               .filter((item) => item.hasError && item.label)
               .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.form.validate();
         } else return false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onChangeHealthSecretarieName() {
         this.getHealthSecretarieByName(
            this.municipality.id,
            this.formHealthSecretarie.name
         );
      },

      async getHealthSecretarieByName(municipalityId, name) {
         this.loadingHealthSecretarieByName = true;

         try {
            const response =
               await healthSecretarieService.getAllHealthSecretaries(
                  municipalityId,
                  {
                     page: 1,
                     limit: 1,
                     filter: [
                        {
                           attribute: "active",
                           query: false,
                        },
                        {
                           attribute: "name",
                           query: name,
                        },
                     ],
                  }
               );

            if (
               response.data &&
               response.data.length &&
               response.data[0].name.toUpperCase() ===
                  this.formHealthSecretarie.name.toUpperCase()
            )
               this.$emit("onFindDisabledHealthSecretarie", response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingHealthSecretarieByName = false;
         }
      },

      async createHealthSecretarie(municipalityId, healthSecretarie) {
         if (!this.executeValidation() || !municipalityId || !healthSecretarie)
            return;

         this.loadingSaveHealthSecretarie = true;

         try {
            await healthSecretarieService.createHealthSecretarie(
               municipalityId,
               this.formatObjectToSend(healthSecretarie)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A secretaria de saúde foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreatedHealthSecretarie");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveHealthSecretarie = false;
         }
      },

      async updateHealthSecretarie(
         municipalityId,
         healthSecretarieId,
         healthSecretarie
      ) {
         if (
            !this.executeValidation() ||
            !municipalityId ||
            !healthSecretarieId ||
            !healthSecretarie
         )
            return;

         this.loadingSaveHealthSecretarie = true;

         try {
            await healthSecretarieService.updateHealthSecretarie(
               municipalityId,
               healthSecretarieId,
               this.formatObjectToSend(healthSecretarie, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A secretaria de saúde foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdatedHealthSecretarie");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveHealthSecretarie = false;
         }
      },
   },
};
</script>

<style scoped>
.combobox-height {
   height: 100%;
}
</style>