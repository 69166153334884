<template>
   <div>
      <p class="px-6">Qual dos papeis deseja executar? Selecione-o.</p>

      <v-divider class="mt-5"></v-divider>

      <v-progress-linear
         :active="loadingToken"
         color="primary"
         indeterminate
         height="3"
      ></v-progress-linear>

      <v-list-item
         @click="selectType('executor')"
         class="px-5"
         id="executor-button"
         two-line
      >
         <v-icon class="mr-4" color="primary" large>mdi-bed</v-icon>

         <v-list-item-content>
            <v-list-item-title>Executante</v-list-item-title>

            <v-list-item-subtitle
               >Mantém o controle dos leitos.</v-list-item-subtitle
            >
         </v-list-item-content>

         <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>

      <v-list-item
         @click="selectType('solicitator')"
         class="px-5"
         id="solicitator-button"
         two-line
      >
         <v-icon class="mr-4" color="primary" large>mdi-file-document</v-icon>

         <v-list-item-content>
            <v-list-item-title>Solicitante</v-list-item-title>

            <v-list-item-subtitle
               >Cadastra solicitações de pacientes.</v-list-item-subtitle
            >
         </v-list-item-content>

         <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>
   </div>
</template>

<script>
import operatorService from "services/operatorService";
import snackBarUtil from "src/utils/snackBarUtil";
import jwtUtil from "src/utils/jwtUtil";

export default {
   name: "AuthSelectOperatorType",

   props: {
      token: {
         type: String,
      },
   },

   data: function () {
      return {
         loadingToken: false,
      };
   },

   methods: {
      async selectType(type) {
         if (this.token && type) {
            let operatorDecode = jwtUtil.decodeJwtToken(this.token);

            let adaptedType = "executor";
            switch (type) {
               case "solicitator":
                  adaptedType = "requester";
                  break;
            }

            this.authOperator(operatorDecode.sub, this.token, {
               role: adaptedType,
            });
         }
      },

      async authOperator(operatorId, token, role) {
         this.loadingToken = true;

         try {
            const response = await operatorService.getOperatorToken(
               operatorId,
               token,
               role
            );

            let type = "executor";
            switch (role.role) {
               case "requester":
                  type = "solicitator";
                  break;
            }

            this.$emit("onSelectType", {
               token: response.data.access_token,
               type,
            });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingToken = false;
         }
      },
   },
};
</script>

<style scoped>
</style>