
<template>
   <svg
      version="1.1"
      id="source-hospital"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="config"
   >
      <g>
         <path
            d="M4.6,22.8v-11C4.6,11.3,5,11,5.4,11h2.9V8.1h7.4V11h2.9c0.4,0,0.7,0.3,0.7,0.7v11h-5.9v-3.7h-2.9v3.7H4.6 M9.8,9.5V14h1.5
		v-1.5h1.5V14h1.5V9.5h-1.5V11h-1.5V9.5H9.8 M6.1,21.3h2.9v-2.2H6.1V21.3 M6.1,17.6h2.9v-2.2H6.1V17.6 M14.9,21.3h2.9v-2.2h-2.9
		V21.3 M14.9,17.6h2.9v-2.2h-2.9V17.6 M10.5,17.6h2.9v-2.2h-2.9V17.6z"
         />
         <path
            d="M19.4,3.9l-2.9-2.7v2H6.9c-0.2-0.3-0.6-0.6-1-0.6c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.4,0,0.8-0.2,1-0.6h9.5v2
		L19.4,3.9z"
         />
      </g>
      <g>
         <path
            d="M0,51.7h-2.5v-2.5h-6.3v2.5h-2.5c-0.3,0-0.6,0.3-0.6,0.6v9.5h5v-3.2h2.5v3.2h5v-9.5C0.6,52,0.3,51.7,0,51.7z M-8.2,60.6
		h-2.5v-1.9h2.5V60.6z M-8.2,57.4h-2.5v-1.9h2.5V57.4z M-4.4,57.4h-2.5v-1.9h2.5V57.4z M-3.8,54.3h-1.3V53h-1.3v1.3h-1.3v-3.8h1.3
		v1.3h1.3v-1.3h1.3V54.3z M-0.6,60.6h-2.5v-1.9h2.5V60.6z M-0.6,57.4h-2.5v-1.9h2.5V57.4z"
         />
         <path
            d="M2,40.5c-0.8,0-0.8-0.8-1.9-0.8c-0.6,0-1.1,0.2-1.5,0.3v-0.3c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4V45h-7.8v0
		c-0.2-0.3-0.5-0.5-0.9-0.5c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1c0.4,0,0.7-0.2,0.9-0.5v0h7.8h0.2h0.6v-2.7c0,0,0.4-0.8,1.5-0.8
		c0.8,0,0.8,0.8,1.9,0.8s1.5-0.8,1.5-0.8v-3.1C3.5,39.7,3.1,40.5,2,40.5z"
         />
      </g>
   </svg>
</template>

<script>
export default {
   name: "SourceHospital",
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>