<template>
   <div>
      <FormTitle icon="mdi-filter" title="FILTROS" />

      <v-form ref="healthunitform">
         <v-expansion-panels v-model="expansionPanelHealthUnit" flat>
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div
                     class="d-flex align-center"
                     id="health-unit-statistic-filter-expansion"
                  >
                     <v-icon color="primary" left>mdi-hospital-building</v-icon>
                     <p class="font-weight-medium primary--text">
                        Unidade de saúde
                     </p>

                     <v-card
                        v-if="healthUnit && healthUnit.id"
                        class="rounded-pill card-panel"
                        color="grey lighten-2"
                        flat
                     >
                        <p
                           class="caption subtext-panel"
                           id="health-unit-statistic-filter-text"
                        >
                           {{ healthUnit.name }}
                        </p>
                     </v-card>
                     <p
                        v-else
                        class="caption subtext-panel grey--text ml-3"
                        id="none-health-unit-statistic-filter-text"
                     >
                        Nenhuma unidade selecionada
                     </p>
                  </div>
               </v-expansion-panel-header>

               <v-expansion-panel-content>
                  <HealthUnitInput
                     @onSelectHealthUnit="onSelectHealthUnit"
                     ref="statistichealthunitinput"
                  />

                  <div v-if="healthUnit && healthUnit.id">
                     <p class="body-2">Origem da regulação</p>

                     <v-chip-group v-model="healthUnitMode" column>
                        <v-chip
                           :color="
                              healthUnitMode === 'target' ? 'primary' : 'normal'
                           "
                           value="target"
                           filter
                           small
                        >
                           Recebeu a regulação
                        </v-chip>
                        <v-chip
                           :color="
                              healthUnitMode === 'source' ? 'primary' : 'normal'
                           "
                           value="source"
                           filter
                           small
                        >
                           Enviou a regulação
                        </v-chip>
                     </v-chip-group>
                  </div>

                  <div class="mt-5 mb-3">
                     <v-btn
                        @click="resetHealthUnit"
                        class="d-none d-sm-flex"
                        color="error"
                        id="clear-statistic-filter-button"
                        depressed
                        small
                        >Limpar
                        <v-icon right x-small>mdi-filter-off</v-icon>
                     </v-btn>

                     <v-btn
                        @click="resetHealthUnit"
                        class="d-flex d-sm-none"
                        color="error"
                        id="clear_small-statistic-filter-button"
                        fab
                        depressed
                        x-small
                     >
                        <v-icon small>mdi-filter-off</v-icon>
                     </v-btn>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <!-- Filtro por município. -->
      <v-expansion-panels
         v-model="expansionPanelMunicipality"
         @change="(value) => openPanel(value, 'expansionPanelMunicipality')"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  class="d-flex align-center"
                  id="surgery-statistic-filter-expansion"
               >
                  <v-icon color="primary" left>mdi-map-marker</v-icon>
                  <p class="font-weight-medium primary--text mr-2">Município</p>

                  <div class="d-flex align-center flex-wrap ma-n1">
                     <v-card
                        v-for="item in municipality"
                        :key="item.id"
                        class="rounded-pill card-panel-municipalities ma-1"
                        color="grey lighten-2"
                        flat
                     >
                        <p
                           class="subtext-panel"
                           id="health-unit-statistic-filter-text"
                        >
                           {{ item.name }}
                        </p>
                     </v-card>
                  </div>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
               <SelectMunicipality
                  @onSelectMunicipality="onSelectMunicipality"
                  :loadMunicipalitiesOnCreated="false"
                  ref="selectmunicipalityinput"
                  class="mb-3"
                  emitEventOnSelectMunicipality
                  multiple
                  hideTitle
                  hideAlert
               />
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <v-form ref="rangedateform">
         <v-expansion-panels
            v-model="expansionPanelRangeDate"
            class="mt-2"
            flat
         >
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div
                     class="d-flex align-center"
                     id="temporal-statistic-filter-expansion"
                  >
                     <v-icon color="primary" left>mdi-calendar</v-icon>
                     <p class="font-weight-medium primary--text">Temporal</p>

                     <v-card
                        class="rounded-pill card-panel"
                        color="grey lighten-2"
                        flat
                     >
                        <p class="caption subtext-panel">
                           De {{ startDate }} até {{ endDate }}
                        </p>
                     </v-card>
                  </div>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-expand-transition>
                     <v-alert
                        v-if="endDateInvalid"
                        class="caption"
                        color="error"
                        border="left"
                        id="invalid-date-statistic-filter-text"
                        dense
                        dark
                     >
                        A data final deve ser <strong>POSTERIOR</strong> a data
                        inicial.
                     </v-alert>
                  </v-expand-transition>

                  <v-row class="my-0">
                     <!-- <v-col cols="12" md="6">
                        <v-text-field
                           v-model="startDate"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) => !!value || 'Data inicial obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           id="start-date-filter-input"
                           class="mb-2"
                           label="Data inicial*"
                        ></v-text-field>
                     </v-col>

                     <v-col cols="12" md="6">
                        <v-text-field
                           v-model="endDate"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) => !!value || 'Data final obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           id="end-date-filter-input"
                           class="mb-2"
                           label="Data final*"
                        ></v-text-field>
                     </v-col> -->

                     <v-col cols="12" md="6">
                        <v-date-picker
                           v-model="startDatePicker"
                           :allowed-dates="startDateAllowed"
                           color="primary"
                           locale="pt-br"
                           full-width
                        ></v-date-picker>
                     </v-col>

                     <v-col cols="12" md="6">
                        <v-date-picker
                           v-model="endDatePicker"
                           :allowed-dates="endDateAllowed"
                           color="primary"
                           locale="pt-br"
                           full-width
                        ></v-date-picker>
                     </v-col>
                  </v-row>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <!-- Filtro por status da solicitação. -->
      <v-expansion-panels
         v-model="expansionPanelRequestStatus"
         @change="(value) => openPanel(value, 'expansionPanelRequestStatus')"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  class="d-flex align-center"
                  id="status-statistic-filter-expansion"
               >
                  <v-icon color="primary" left>mdi-file-document</v-icon>
                  <p class="font-weight-medium primary--text">
                     Estado da solicitação
                  </p>

                  <v-card
                     v-if="requestStatus.length"
                     class="rounded-pill card-panel"
                     color="grey lighten-2"
                     flat
                  >
                     <p class="subtext-panel">
                        {{ requestStatus.length }} estado(s) selecionado(s).
                     </p>
                  </v-card>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
               <v-row no-gutters>
                  <v-col>
                     <p class="body-2">Status de solicitações abertas</p>

                     <div>
                        <v-chip-group
                           v-model="requestOpenStatus"
                           multiple
                           column
                           @change="resetClosedStatus"
                        >
                           <v-chip
                              v-for="(status, index) in avaliablesOpenStatus"
                              :key="index"
                              :value="status"
                              :color="
                                 requestOpenStatus.includes(status)
                                    ? generateState(status).color
                                    : 'normal'
                              "
                              :id="`status-${status}-chip`"
                              filter
                              small
                           >
                              {{ generateState(status).text }}
                           </v-chip>
                        </v-chip-group>
                     </div>
                  </v-col>

                  <v-divider class="mx-4" vertical></v-divider>

                  <v-col>
                     <p class="body-2">Status de solicitações fechadas</p>

                     <div>
                        <v-chip-group
                           v-model="requestClosedStatus"
                           multiple
                           column
                           @change="resetOpenStatus"
                        >
                           <v-chip
                              v-for="(status, index) in availablesStatus"
                              :key="index"
                              :value="status"
                              :color="
                                 requestClosedStatus.includes(status)
                                    ? generateState(status).color
                                    : 'normal'
                              "
                              :id="`status-${status}-chip`"
                              filter
                              small
                           >
                              {{ generateState(status).text }}
                           </v-chip>
                        </v-chip-group>
                     </div>
                  </v-col>
               </v-row>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <div class="d-flex justify-end align-center div-buttons-apply mt-6">
         <v-btn
            @click="apply"
            class="shadow-floating-primary-button d-none d-sm-flex ml-3"
            color="primary"
            id="apply-primary-filter-button"
            depressed
            >Aplicar
            <v-icon right small>mdi-filter</v-icon>
         </v-btn>

         <v-btn
            @click="apply"
            class="shadow-floating-primary-button d-flex d-sm-none ml-3"
            color="primary"
            id="apply-secondary-filter-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-filter</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle";
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import SelectMunicipality from "components/municipality/selectMunicipality/SelectMunicipality";
import validationUtil from "utils/validationUtil";
import moment from "moment";
import formatUtil from "utils/formatUtil";
import util from "utils/util";

export default {
   name: "StatisticFilter",

   components: { FormTitle, HealthUnitInput, SelectMunicipality },

   data: function () {
      return {
         DIFF_DAYS: -60,

         expansionPanelHealthUnit: null,
         expansionPanelRangeDate: null,
         expansionPanelRequestStatus: null,
         expansionPanelMunicipality: null,

         healthUnit: null,
         healthUnitMode: "target",
         startDate: null,
         endDate: null,
         statusOpenOrClosed: "",

         /* Filtro por municipios. */
         municipality: [],

         /* Filtro por status de solicitações abertas. */
         avaliablesOpenStatus: [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected_by_executor",
         ],

         /* Filtro por status da solicitação fechadas. */
         availablesStatus: ["rejected", "answered", "canceled"],

         requestOpenStatus: [],
         requestClosedStatus: [],
         requestStatus: [],
      };
   },

   computed: {
      startDatePicker: {
         get() {
            if (validationUtil.dateValidation(this.startDate, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.startDate,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.startDate = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },

      endDatePicker: {
         get() {
            if (validationUtil.dateValidation(this.endDate, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.endDate,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.endDate = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },

      endDateInvalid() {
         if (
            validationUtil.dateValidation(this.endDate, "DD/MM/YYYY") &&
            validationUtil.dateValidation(this.startDate, "DD/MM/YYYY")
         )
            return !(
               moment(this.startDate, "DD/MM/YYYY") <=
               moment(this.endDate, "DD/MM/YYYY")
            );
         else return false;
      },
   },

   methods: {
      ...validationUtil,
      ...util,

      onSelectHealthUnit(healthUnit) {
         this.healthUnit = healthUnit;
      },

      resetRangeDate() {
         const endDateOriginal = moment();
         this.endDate = formatUtil.formatDate(endDateOriginal);
         this.startDate = formatUtil.formatDate(
            moment(endDateOriginal).subtract(1, "days")
         );

         if (this.$refs.rangedateform)
            this.$refs.rangedateform.resetValidation();

         this.expansionPanelRangeDate = null;
      },

      resetHealthUnit() {
         if (this.$refs.statistichealthunitinput)
            this.$refs.statistichealthunitinput.reset();
         this.healthUnit = null;

         if (this.$refs.healthunitform)
            this.$refs.healthunitform.resetValidation();

         this.expansionPanelHealthUnit = null;
      },

      /* Verificar e limpar array das solicitações abertas
        caso uma opção das solicitações fechadas seja escolhida. */
      resetOpenStatus() {
         this.requestOpenStatus = [];
         this.requestStatus = this.requestClosedStatus;
      },

      /* Verificar e limpar array das solicitações fechadas
        caso uma opção das solicitações abertas seja escolhida. */
      resetClosedStatus() {
         this.requestClosedStatus = [];
         this.requestStatus = this.requestOpenStatus;
      },

      /* Filtro por status da solicitação. */
      resetStatus() {
         this.requestStatus = [];
         this.requestOpenStatus = [];
         this.requestClosedStatus = [];
         this.expansionPanelRequestStatus = null;
      },

      /* Filtro por município. */
      onSelectMunicipality(municipality) {
         this.municipality = municipality;
      },

      resetMunicipality() {
         if (this.$refs.selectmunicipalityinput)
            this.$refs.selectmunicipalityinput.reset();

         this.municipality = [];
         this.expansionPanelMunicipality = null;
      },

      executeRangeDateValidation() {
         return this.$refs.rangedateform.validate();
      },

      resetAll() {
         this.resetHealthUnit();
         this.resetRangeDate();
         this.resetMunicipality();
         this.resetStatus();

         this.emitReturn();
      },

      apply() {
         if (this.executeRangeDateValidation() && !this.endDateInvalid)
            this.emitReturn();
      },

      emitReturn() {
         if (this.requestOpenStatus.length) {
            this.requestStatus = this.requestOpenStatus;
            this.statusOpenOrClosed = "open";
         }

         if (this.requestClosedStatus.length) {
            this.requestStatus = this.requestClosedStatus;
            this.statusOpenOrClosed = "closed";
         }

         this.$emit("onApplyFilter", {
            healthUnitMode: this.healthUnitMode,
            healthUnit: this.healthUnit,
            municipality: this.municipality,
            rangeDate: {
               startDate: this.startDate,
               endDate: this.endDate,
            },
            requestStatus: this.requestStatus,
            statusOpenOrClosed: this.statusOpenOrClosed,
         });
      },

      /* Função para fechar outros panels caso estejam abertos, visando manter a melhor usabilidade. */
      openPanel(value, openedPanel) {
         if (value === 0) {
            if (openedPanel !== "expansionPanelHealthEntity")
               this.expansionPanelHealthEntity = null;
            if (openedPanel !== "expansionPanelDateRange")
               this.expansionPanelDateRange = null;
            if (openedPanel !== "expansionPanelRequestStatus")
               this.expansionPanelRequestStatus = null;
            if (openedPanel !== "expansionPanelMunicipality")
               this.expansionPanelMunicipality = null;
         }
      },

      startDateAllowed(date) {
         const admission = moment(this.endDate, "DD/MM/YYYY");
         const discharge = moment(date);
         const diffDays = discharge.diff(admission, "days");

         return diffDays >= this.DIFF_DAYS ? true : false;
      },

      endDateAllowed(date) {
         const admission = moment(this.startDate, "DD/MM/YYYY");
         const discharge = moment(date);
         const diffDays = admission.diff(discharge, "days");

         return diffDays >= this.DIFF_DAYS ? true : false;
      },
   },

   created() {
      this.resetRangeDate();
   },
};
</script>

<style scoped>
.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.15);
}

::v-deep .v-expansion-panel-header {
   padding: 16px 20px;
}

::v-deep .v-expansion-panel-content__wrap {
   padding: 0 20px 8px;
}

.subtext-panel {
   font-size: 0.73rem;
   line-height: 120%;
   text-align: center;
}

.div-buttons-apply {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

.card-panel {
   padding: 5px 10px;
   margin-left: 8px;
}

.card-panel-municipalities {
   padding: 5px 10px;
}
</style>