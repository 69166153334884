<template>
   <svg
      version="1.0"
      id="coracao-paraibano"
      xmlns="http://www.w3.org/2000/svg"
      width="436.000000pt"
      height="436.000000pt"
      viewBox="0 0 436.000000 436.000000"
      preserveAspectRatio="xMidYMid meet"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="config"
   >
      <g
         transform="translate(0.000000,436.000000) scale(0.100000,-0.100000)"
         stroke="none"
      >
         <path
            d="M3165 3846 c-214 -51 -410 -191 -573 -411 -109 -148 -298 -591 -387
-912 -20 -72 -39 -94 -60 -72 -7 8 -36 55 -65 104 -173 304 -400 474 -670 505
-235 27 -474 -60 -662 -239 -101 -95 -153 -175 -194 -296 -36 -107 -45 -321
-19 -460 75 -404 318 -729 745 -995 46 -29 233 -130 415 -225 474 -246 598
-327 780 -507 96 -95 101 -99 134 -93 47 9 113 44 188 98 101 75 101 74 -8
184 -167 167 -345 286 -729 485 -518 269 -678 374 -865 569 -222 232 -355 549
-355 846 0 182 47 358 111 419 28 27 35 29 107 28 192 -4 421 -125 564 -300
114 -141 166 -235 322 -584 119 -265 109 -257 241 -191 109 55 172 111 181
159 90 522 211 938 391 1342 85 192 123 258 177 308 46 42 62 47 136 43 98 -6
240 -120 313 -253 68 -123 117 -303 117 -432 0 -146 24 -166 199 -166 107 0
111 1 127 26 33 50 0 346 -62 554 -51 168 -140 323 -223 389 -99 79 -243 108
-376 77z"
         />
      </g>
   </svg>
</template>

<script>
export default {
   name: "CoracaoParaibano"
};
</script>

<style scoped>
.config {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>
