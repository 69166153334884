<template>
   <div>
      <FormTitle icon="mdi-filter" title="FILTROS" />

      <!-- Filtro por entidade de saúde. -->
      <v-form>
         <v-expansion-panels
            v-model="expansionPanelHealthEntity"
            @change="(value) => openPanel(value, 'expansionPanelHealthEntity')"
            class="mt-2"
            flat
         >
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div
                     class="d-flex align-center"
                     id="health-unit-statistic-filter-expansion"
                  >
                     <v-icon color="primary" left>mdi-hospital-building</v-icon>
                     <p class="font-weight-medium primary--text">
                        Entidade de saúde
                     </p>

                     <v-card
                        v-if="healthEntity && healthEntity.id"
                        class="rounded-pill card-panel"
                        color="grey lighten-2"
                        flat
                     >
                        <p
                           class="subtext-panel"
                           id="health-unit-statistic-filter-text"
                        >
                           {{ healthEntity.name }}
                        </p>
                     </v-card>
                  </div>
               </v-expansion-panel-header>

               <v-expansion-panel-content>
                  <div class="d-flex align-center mb-6">
                     <v-btn-toggle
                        v-model="entitiesTab"
                        class="flex-wrap"
                        dense
                     >
                        <v-btn
                           class="overline-button primary--text"
                           id="health-unit-button"
                        >
                           <v-icon class="mr-1" color="primary" small
                              >mdi-hospital-building</v-icon
                           >
                           UNIDADE DE SAÚDE
                        </v-btn>
                        <v-btn
                           class="overline-button error--text"
                           id="health-entity-button"
                        >
                           <v-icon class="mr-1" color="error" small
                              >mdi-home-plus</v-icon
                           >
                           SECRETARIA DE SAÚDE
                        </v-btn>
                     </v-btn-toggle>
                  </div>

                  <div v-if="entitiesTab === 0">
                     <HealthUnitInput
                        @onSelectHealthUnit="onSelectHealthEntity"
                        :required="false"
                        ref="statistichealthunitinput"
                     />
                  </div>
                  <div v-else>
                     <HealthSecretarieInput
                        @onSelectHealthSecretarie="onSelectHealthEntity"
                        :required="false"
                        ref="statistichealthsecretaryinput"
                     />
                  </div>

                  <div v-if="healthEntity && healthEntity.id">
                     <v-chip-group v-model="healthEntityMode" column>
                        <v-chip
                           :color="
                              healthEntityMode === 'target'
                                 ? 'primary'
                                 : 'normal'
                           "
                           value="target"
                           filter
                           small
                        >
                           Recebeu a regulação
                        </v-chip>
                        <v-chip
                           :color="
                              healthEntityMode === 'source'
                                 ? 'primary'
                                 : 'normal'
                           "
                           value="source"
                           filter
                           small
                        >
                           Enviou a regulação
                        </v-chip>
                     </v-chip-group>
                  </div>

                  <div class="mt-5 mb-3">
                     <v-btn
                        @click="resetHealthEntity"
                        class="d-none d-sm-flex"
                        color="error"
                        id="clear-statistic-filter-button"
                        depressed
                        small
                        >Limpar
                        <v-icon right x-small>mdi-filter-off</v-icon>
                     </v-btn>

                     <v-btn
                        @click="resetHealthEntity"
                        class="d-flex d-sm-none"
                        color="error"
                        id="clear_small-statistic-filter-button"
                        fab
                        depressed
                        x-small
                     >
                        <v-icon small>mdi-filter-off</v-icon>
                     </v-btn>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <!-- Filtro por município. -->
      <v-expansion-panels
         v-model="expansionPanelMunicipality"
         @change="(value) => openPanel(value, 'expansionPanelMunicipality')"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  class="d-flex align-center"
                  id="surgery-statistic-filter-expansion"
               >
                  <v-icon color="primary" left>mdi-map-marker</v-icon>
                  <p class="font-weight-medium primary--text mr-2">Município</p>

                  <div class="d-flex align-center flex-wrap ma-n1">
                     <v-card
                        v-for="item in municipality"
                        :key="item.id"
                        class="rounded-pill card-panel-municipalities ma-1"
                        color="grey lighten-2"
                        flat
                     >
                        <p
                           class="subtext-panel"
                           id="health-unit-statistic-filter-text"
                        >
                           {{ item.name }}
                        </p>
                     </v-card>
                  </div>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
               <SelectMunicipality
                  @onSelectMunicipality="onSelectMunicipality"
                  :loadMunicipalitiesOnCreated="false"
                  ref="selectmunicipalityinput"
                  class="mb-3"
                  emitEventOnSelectMunicipality
                  multiple
                  hideTitle
                  hideAlert
               />
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <!-- Filtro por limite entre duas datas. -->
      <v-form ref="rangedateform">
         <v-expansion-panels
            v-model="expansionPanelDateRange"
            @change="(value) => openPanel(value, 'expansionPanelDateRange')"
            class="mt-2"
            flat
         >
            <v-expansion-panel class="panels-border">
               <v-expansion-panel-header>
                  <div
                     class="d-flex align-center"
                     id="temporal-statistic-filter-expansion"
                  >
                     <v-icon color="primary" left>mdi-calendar</v-icon>
                     <p class="font-weight-medium primary--text">Temporal</p>

                     <v-card
                        class="rounded-pill card-panel"
                        color="grey lighten-2"
                        flat
                     >
                        <p class="subtext-panel">
                           De {{ startDate }} até {{ endDate }}
                        </p>
                     </v-card>
                  </div>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
                  <v-expand-transition>
                     <v-alert
                        v-if="endDateInvalid"
                        class="caption"
                        color="error"
                        border="left"
                        id="invalid-date-statistic-filter-text"
                        dense
                        dark
                     >
                        A data final deve ser <strong>POSTERIOR</strong> a data
                        inicial.
                     </v-alert>
                  </v-expand-transition>

                  <v-row class="my-0">
                     <!-- <v-col cols="12" md="6">
                        <v-text-field
                           v-model="startDate"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) => !!value || 'Data inicial obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           id="start-date-filter-input"
                           class="mb-2"
                           label="Data inicial*"
                        ></v-text-field>
                     </v-col>

                     <v-col cols="12" md="6">
                        <v-text-field
                           v-model="endDate"
                           v-maska="'##/##/####'"
                           :rules="[
                              (value) => !!value || 'Data final obrigatória.',
                              (value) =>
                                 dateValidation(value) || 'Data inválida.',
                           ]"
                           id="end-date-filter-input"
                           class="mb-2"
                           label="Data final*"
                        ></v-text-field>
                     </v-col> -->

                     <v-col cols="12" md="6">
                        <v-date-picker
                           v-model="startDatePicker"
                           :allowed-dates="startDateAllowed"
                           color="primary"
                           locale="pt-br"
                           full-width
                        ></v-date-picker>
                     </v-col>

                     <v-col cols="12" md="6">
                        <v-date-picker
                           v-model="endDatePicker"
                           :allowed-dates="endDateAllowed"
                           color="primary"
                           locale="pt-br"
                           full-width
                        ></v-date-picker>
                     </v-col>
                  </v-row>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-form>

      <!-- Filtro por cirurgias. -->
      <v-expansion-panels
         v-model="expansionPanelSurgeries"
         @change="(value) => openPanel(value, 'expansionPanelSurgeries')"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  class="d-flex align-center"
                  id="surgery-statistic-filter-expansion"
               >
                  <v-icon color="primary" left>mdi-medical-bag</v-icon>
                  <p class="font-weight-medium primary--text">Cirurgias</p>

                  <v-card
                     v-if="surgeriesSelected.length"
                     class="rounded-pill card-panel"
                     color="grey lighten-2"
                     flat
                  >
                     <p class="subtext-panel">
                        {{ surgeriesSelected.length }} cirurgia(s)
                        selecionada(s).
                     </p>
                  </v-card>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
               <div class="d-flex flex-column justify-center">
                  <SurgerieInput
                     @onSelectSurgerie="surgerySelected"
                     :required="false"
                     class="body-2"
                     ref="surgeryinput"
                  />

                  <NoData
                     v-if="!surgeriesSelected.length"
                     class="mt-2"
                     icon="mdi-medical-bag"
                     message="Selecione uma ou
                        mais cirurgias para filtrar."
                  />

                  <div v-else class="mt-5 mb-3">
                     <div class="d-flex align-center">
                        <v-icon left small>mdi-medical-bag</v-icon>

                        <p class="caption">Cirurgia(s) selecionada(s):</p>
                     </div>

                     <v-row class="mt-0 mx-n2 mb-n2">
                        <v-col
                           v-for="(item, index) in surgeriesSelected"
                           :key="index"
                           class="pa-2"
                           cols="6"
                           md="3"
                        >
                           <v-card
                              class="
                                 d-flex
                                 flex-column
                                 justify-center
                                 align-center
                                 standard-padding-x-small
                              "
                              height="100%"
                              outlined
                           >
                              <v-btn
                                 @click="removeSurgery(item)"
                                 color="error"
                                 height="26"
                                 width="26"
                                 class="delete-button"
                                 fab
                                 depressed
                                 x-small
                              >
                                 <v-icon small> mdi-delete </v-icon>
                              </v-btn>

                              <v-icon class="mb-2" color="primary">
                                 mdi-medical-bag
                              </v-icon>

                              <p class="caption text-center surgery-name">
                                 {{ item.name }}
                              </p>
                           </v-card>
                        </v-col>
                     </v-row>
                  </div>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <!-- Filtro por tipo das cirurgias, se são aceitas ou não aceitas. -->
      <v-card class="py-2 px-5 mt-3" outlined>
         <div class="d-flex align-center flex-wrap mx-n1">
            <div class="d-flex align-center mx-1">
               <v-icon color="primary" left>$box-cutter-check</v-icon>
               <p class="font-weight-medium primary--text">Cirurgias aceitas</p>
            </div>

            <v-chip-group
               v-model="acceptedSurgeries"
               class="mx-1"
               multiple
               column
            >
               <v-chip
                  :color="
                     acceptedSurgeries.includes(true) ? 'primary' : 'normal'
                  "
                  :value="true"
                  id="available-surgery-chip"
                  filter
                  small
               >
                  Cirurgias aceitas
               </v-chip>
               <v-chip
                  :color="
                     acceptedSurgeries.includes(false) ? 'primary' : 'normal'
                  "
                  :value="false"
                  id="unavailable-surgery-chip"
                  filter
                  small
               >
                  Cirurgias não aceitas
               </v-chip>
            </v-chip-group>
         </div>
      </v-card>

      <!-- Filtro por status da solicitação. -->
      <v-expansion-panels
         v-model="expansionPanelRequestStatus"
         @change="(value) => openPanel(value, 'expansionPanelRequestStatus')"
         class="mt-2"
         flat
      >
         <v-expansion-panel class="panels-border">
            <v-expansion-panel-header>
               <div
                  class="d-flex align-center"
                  id="status-statistic-filter-expansion"
               >
                  <v-icon color="primary" left>mdi-file-document</v-icon>
                  <p class="font-weight-medium primary--text">
                     Estado da solicitação
                  </p>

                  <v-card
                     v-if="requestStatus.length"
                     class="rounded-pill card-panel"
                     color="grey lighten-2"
                     flat
                  >
                     <p class="subtext-panel">
                        {{ requestStatus.length }} estado(s) selecionado(s).
                     </p>
                  </v-card>
               </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
               <v-row no-gutters>
                  <v-col>
                     <p class="body-2">Status de solicitações abertas</p>

                     <div>
                        <v-chip-group
                           v-model="requestOpenStatus"
                           multiple
                           column
                           @change="resetClosedStatus"
                        >
                           <v-chip
                              v-for="(status, index) in avaliablesOpenStatus"
                              :key="index"
                              :value="status"
                              :color="
                                 requestOpenStatus.includes(status)
                                    ? generateState(status).color
                                    : 'normal'
                              "
                              :id="`status-${status}-chip`"
                              filter
                              small
                           >
                              {{ generateState(status).text }}
                           </v-chip>
                        </v-chip-group>
                     </div>
                  </v-col>

                  <v-divider class="mx-4" vertical></v-divider>

                  <v-col>
                     <p class="body-2">Status de solicitações fechadas</p>

                     <div>
                        <v-chip-group
                           v-model="requestClosedStatus"
                           multiple
                           column
                           @change="resetOpenStatus"
                        >
                           <v-chip
                              v-for="(status, index) in availablesStatus"
                              :key="index"
                              :value="status"
                              :color="
                                 requestClosedStatus.includes(status)
                                    ? generateState(status).color
                                    : 'normal'
                              "
                              :id="`status-${status}-chip`"
                              filter
                              small
                           >
                              {{ generateState(status).text }}
                           </v-chip>
                        </v-chip-group>
                     </div>
                  </v-col>
               </v-row>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>

      <div class="d-flex justify-end align-center div-buttons-apply mt-6">
         <v-btn
            @click="apply"
            class="shadow-floating-primary-button d-none d-sm-flex ml-3"
            color="primary"
            id="apply-primary-filter-button"
            depressed
            >Aplicar
            <v-icon right small>mdi-filter</v-icon>
         </v-btn>

         <v-btn
            @click="apply"
            class="shadow-floating-primary-button d-flex d-sm-none ml-3"
            color="primary"
            id="apply-secondary-filter-button"
            fab
            depressed
            x-small
         >
            <v-icon small>mdi-filter</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
import FormTitle from "components/base/form/FormTitle";
import HealthUnitInput from "components/healthUnit/healthUnitInput/HealthUnitInput";
import HealthSecretarieInput from "components/healthSecretarie/healthSecretarieInput/HealthSecretarieInput";
import SelectMunicipality from "components/municipality/selectMunicipality/SelectMunicipality";
import validationUtil from "utils/validationUtil";
import util from "utils/util";
import moment from "moment";
import formatUtil from "utils/formatUtil";
import SurgerieInput from "components/surgerie/SurgerieInput";
import NoData from "components/base/NoData";

export default {
   name: "SurgeryStatisticFilter",

   components: {
      FormTitle,
      HealthUnitInput,
      HealthSecretarieInput,
      SurgerieInput,
      NoData,
      SelectMunicipality,
   },

   data: function () {
      return {
         DIFF_DAYS: -60,

         /* Variaveis pra abrir/fechar os paneis dos filtros */
         expansionPanelHealthEntity: null,
         expansionPanelDateRange: null,
         expansionPanelSurgeries: null,
         expansionPanelRequestStatus: null,
         expansionPanelMunicipality: null,

         /* Filtro por entidade de saúde. */
         entitiesTab: 0,
         healthEntity: null,
         healthEntityMode: "target",
         statusOpenOrClosed: '',

         /* Filtro por municipios. */
         municipality: [],

         /* Filtro por limite entre duas datas. */
         startDate: null,
         endDate: null,

         /* Filtro por cirurgias. */
         surgeriesSelected: [],

         /* Filtro por tipo das cirurgias, se são aceitas ou não aceitas. */
         acceptedSurgeries: [],

         /*
            Filtro por status de solicitações abertas.*/

         avaliablesOpenStatus: [
            "registered",
            "in_progress",
            "regulated",
            "answered",
            "rejected_by_executor",
         ],

         /* Filtro por status da solicitação fechadas. */
         availablesStatus: ["rejected", "answered", "canceled"],

         requestOpenStatus: [],
         requestClosedStatus: [],
         requestStatus: [],
      };
   },

   computed: {
      startDatePicker: {
         get() {
            if (validationUtil.dateValidation(this.startDate, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.startDate,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.startDate = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },

      endDatePicker: {
         get() {
            if (validationUtil.dateValidation(this.endDate, "DD/MM/YYYY"))
               return formatUtil.formatDate(
                  this.endDate,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.endDate = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },

      endDateInvalid() {
         if (
            validationUtil.dateValidation(this.endDate, "DD/MM/YYYY") &&
            validationUtil.dateValidation(this.startDate, "DD/MM/YYYY")
         )
            return !(
               moment(this.startDate, "DD/MM/YYYY") <=
               moment(this.endDate, "DD/MM/YYYY")
            );
         else return false;
      },
   },

   watch: {
      entitiesTab() {
         this.healthEntity = null;
      },
   },

   methods: {
      ...validationUtil,
      ...util,

      /* Filtro por entidade de saúde. */
      onSelectHealthEntity(healthEntity) {
         this.healthEntity = healthEntity;
      },

      resetHealthEntity() {
         if (this.$refs.statistichealthunitinput)
            this.$refs.statistichealthunitinput.reset();
         if (this.$refs.statistichealthsecretaryinput)
            this.$refs.statistichealthsecretaryinput.reset();

         this.healthEntity = null;
         this.expansionPanelHealthEntity = null;
      },

      /* Filtro por limite entre duas datas. */
      resetRangeDate() {
         const endDateOriginal = moment();
         this.endDate = formatUtil.formatDate(endDateOriginal);
         this.startDate = formatUtil.formatDate(
            moment(endDateOriginal).subtract(1, "days")
         );

         if (this.$refs.rangedateform)
            this.$refs.rangedateform.resetValidation();

         this.expansionPanelDateRange = null;
      },

      executeRangeDateValidation() {
         return this.$refs.rangedateform.validate();
      },

      /* Filtro por cirurgias. */
      surgerySelected(surgerie) {
         const surgerieIndex = this.surgeriesSelected.findIndex(
            (item) => item.name === surgerie.name
         );

         if (surgerieIndex === -1) this.surgeriesSelected.push(surgerie);

         setTimeout(() => {
            if (this.$refs.surgeryinput) this.$refs.surgeryinput.reset();
         }, 150);
      },

      removeSurgery(surgery) {
         const surgerieIndex = this.surgeriesSelected.findIndex(
            (item) => item.name === surgery.name
         );

         this.surgeriesSelected.splice(surgerieIndex, 1);
      },

      resetSurgery() {
         this.surgeriesSelected = [];
         this.expansionPanelSurgeries = null;
      },

      /* Filtro por tipo das cirurgias, se são aceitas ou não aceitas. */
      resetAcceptedSurgery() {
         this.acceptedSurgeries = [];
      },

      /* Verificar e limpar array das solicitações abertas
        caso uma opção das solicitações fechadas seja escolhida. */
      resetOpenStatus() {
         this.requestOpenStatus = [];
         this.requestStatus = this.requestClosedStatus;
      },

      /* Verificar e limpar array das solicitações fechadas
        caso uma opção das solicitações abertas seja escolhida. */
      resetClosedStatus() {
         this.requestClosedStatus = [];
         this.requestStatus = this.requestOpenStatus;
      },

      /* Filtro por status da solicitação. */
      resetStatus() {
         this.requestStatus = [];
         this.requestOpenStatus = [];
         this.requestClosedStatus = [];
         this.expansionPanelRequestStatus = null;
      },

      /* Filtro por município. */
      onSelectMunicipality(municipality) {
         this.municipality = municipality;
      },

      resetMunicipality() {
         if (this.$refs.selectmunicipalityinput)
            this.$refs.selectmunicipalityinput.reset();

         this.municipality = [];
         this.expansionPanelMunicipality = null;
      },

      /* Reseta todos os filtros. */
      resetAll() {
         this.resetHealthEntity();
         this.resetRangeDate();
         this.resetAcceptedSurgery();
         this.resetStatus();
         this.resetSurgery();
         this.resetMunicipality();

         this.emitReturn();
      },

      apply() {
         if (this.executeRangeDateValidation() && !this.endDateInvalid)
            this.emitReturn();
      },

      emitReturn() {
         if (this.requestOpenStatus.length) {
            this.requestStatus = this.requestOpenStatus;
            this.statusOpenOrClosed = 'open'
         }

         if (this.requestClosedStatus.length) {
            this.requestStatus = this.requestClosedStatus;
            this.statusOpenOrClosed = 'closed'
         }

         this.$emit("onApplyFilter", {
            healthEntityMode: this.healthEntityMode,
            healthEntity: this.healthEntity,
            municipality: this.municipality,
            rangeDate: {
               startDate: this.startDate,
               endDate: this.endDate,
            },
            surgeriesSelected: this.surgeriesSelected,
            acceptedSurgeries: this.acceptedSurgeries,
            requestStatus: this.requestStatus,
            statusOpenOrClosed: this.statusOpenOrClosed
         });
      },

      /* Função para fechar outros panels caso estejam abertos, visando manter a melhor usabilidade. */
      openPanel(value, openedPanel) {
         if (value === 0) {
            if (openedPanel !== "expansionPanelHealthEntity")
               this.expansionPanelHealthEntity = null;
            if (openedPanel !== "expansionPanelDateRange")
               this.expansionPanelDateRange = null;
            if (openedPanel !== "expansionPanelSurgeries")
               this.expansionPanelSurgeries = null;
            if (openedPanel !== "expansionPanelRequestStatus")
               this.expansionPanelRequestStatus = null;
            if (openedPanel !== "expansionPanelMunicipality")
               this.expansionPanelMunicipality = null;
         }
      },

      startDateAllowed(date) {
         const admission = moment(this.endDate, "DD/MM/YYYY");
         const discharge = moment(date);
         const diffDays = discharge.diff(admission, "days");

         return diffDays >= this.DIFF_DAYS ? true : false;
      },

      endDateAllowed(date) {
         const admission = moment(this.startDate, "DD/MM/YYYY");
         const discharge = moment(date);
         const diffDays = admission.diff(discharge, "days");

         return diffDays >= this.DIFF_DAYS ? true : false;
      },
   },

   created() {
      this.resetRangeDate();
   },
};
</script>

<style scoped>
.panels-border {
   margin-top: 6px;
   border: thin solid rgba(0, 0, 0, 0.15);
}

::v-deep .v-expansion-panel-header {
   padding: 16px 20px;
}

::v-deep .v-expansion-panel-content__wrap {
   padding: 0 20px 8px;
}

.subtext-panel {
   font-size: 0.73rem;
   line-height: 120%;
   text-align: center;
}

.div-buttons-apply {
   position: sticky;
   bottom: 24px;
   z-index: 999;
}

.radio-group {
   height: 100%;
   margin-bottom: -5px;
}

.overline-button {
   font-size: 0.65rem;
   letter-spacing: 0.1em;
}

.surgery-name {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   line-clamp: 4;
   -webkit-box-orient: vertical;
}

.card-panel {
   padding: 5px 10px;
   margin-left: 8px;
}

.card-panel-municipalities {
   padding: 5px 10px;
}

.delete-button {
   position: absolute;
   top: -10px;
   right: -10px;
}
</style>