<template>
   <v-card class="d-flex align-center" height="64px" flat>
      <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>

      <div class="skeleton-health-unit-description">
         <v-skeleton-loader
            class="mb-1 rounded-pill"
            type="image"
            width="90%"
            height="18px"
         ></v-skeleton-loader>
         <v-skeleton-loader
            class="rounded-pill"
            type="image"
            width="60%"
            height="15px"
         ></v-skeleton-loader>
      </div>
   </v-card>
</template>

<script>
export default {
   name: "OperatorHealthUnitsSkeleton",
};
</script>

<style scoped>
.skeleton-health-unit-description {
   width: calc(100% - 60px);
}
</style>