<template>
   <div>
      <p class="body-1 font-weight-medium mt-5">
         Data de realização da cirurgia
      </p>

      <v-form ref="form">
         <v-row class="mt-2">
            <v-col cols="12">
               <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                        v-model="formSurgery.execution_date"
                        v-maska="'##/##/####'"
                        :rules="[
                           (value) =>
                              !!value || 'Data de realização obrigatória.',
                           (value) => dateValidation(value) || 'Data inválida.',
                        ]"
                        append-icon="mdi-calendar"
                        id="birth_date-surgery-form-input"
                        label="Data de realização*"
                     >
                        <template v-slot:append>
                           <v-icon v-bind="attrs" v-on="on"
                              >mdi-calendar</v-icon
                           >
                        </template>
                     </v-text-field>
                  </template>

                  <v-card class="d-flex justify-center align-center">
                     <v-date-picker
                        v-model="datePicker"
                        locale="pt-br"
                        no-title
                     >
                     </v-date-picker>
                  </v-card>
               </v-menu>
            </v-col>
         </v-row>
      </v-form>

      <FormActions
         @onClickCancel="onClickCancel"
         @onClickSave="onClickCancelReservation"
         :loadingSave="loadingSaveSurgery"
         :mode="'save'"
         class="mt-6"
      />
   </div>
</template>

<script>
import FormActions from "components/base/form/FormActions";
import snackBarUtil from "utils/snackBarUtil";
import util from "utils/util";
import validationUtil from "utils/validationUtil";
import formatUtil from "utils/formatUtil";
import surgerieService from "services/surgerieService";
import moment from "moment";

export default {
   name: "SurgeryDoneForm",

   components: { FormActions },

   props: {
      surgery: {
         type: Object,
      },
   },

   data: function () {
      return {
         formSurgery: {},

         loadingSaveSurgery: false,
      };
   },

   computed: {
      datePicker: {
         get() {
            if (
               validationUtil.dateValidation(
                  this.formSurgery.execution_date,
                  "DD/MM/YYYY"
               )
            )
               return formatUtil.formatDate(
                  this.formSurgery.execution_date,
                  "YYYY-MM-DD",
                  "DD/MM/YYYY"
               );
            else return null;
         },

         set(value) {
            this.formSurgery.execution_date = formatUtil.formatDate(
               value,
               "DD/MM/YYYY",
               "YYYY-MM-DD"
            );
         },
      },
   },

   watch: {
      surgery: {
         immediate: true,
         handler() {
            this.formSurgery = {
               status: "done",
            };
            this.formSurgery.execution_date = formatUtil.formatDate(
               new Date(),
               "DD/MM/YYYY"
            );

            this.resetValidation();
         },
      },
   },

   methods: {
      ...util,
      ...formatUtil,
      ...validationUtil,

      allowedDates(val) {
         return moment().diff(val, "days") <= 0;
      },

      formatObjectToSend(justification) {
         let justificationTemp = util.removeBlankAttributes(justification);

         if (justificationTemp.execution_date)
            justificationTemp.execution_date = formatUtil.formatDate(
               justificationTemp.execution_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         return justificationTemp;
      },

      executeValidation() {
         return this.$refs.form ? this.$refs.form.validate() : false;
      },

      resetValidation() {
         if (this.$refs.form) this.$refs.form.resetValidation();
      },

      onClickCancel() {
         this.$emit("onClickCancel");
      },

      onClickCancelReservation() {
         if (this.surgery && this.surgery.id && this.surgery.healthunit_id)
            this.updateStatusHealthUnitSurgerie(
               this.surgery.healthunit_id,
               this.surgery.id,
               this.formSurgery
            );
      },

      async updateStatusHealthUnitSurgerie(healthUnitId, surgeryId, status) {
         if (
            !this.executeValidation() ||
            !healthUnitId ||
            !surgeryId ||
            !status
         )
            return;

         this.loadingSaveSurgery = true;

         try {
            await surgerieService.updateStatusHealthUnitSurgerie(
               healthUnitId,
               surgeryId,
               this.formatObjectToSend(status)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A cirurgia foi atualizada com sucesso!",
            });

            this.$emit("onDoneSurgery");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSaveSurgery = false;
         }
      },
   },
};
</script>

<style scoped>
.justification-height {
   height: 100%;
}
</style>